import React from 'react';
import { useASNQuery } from 'hooks';
import { ComponentPluginName } from 'components';
import { addSectionToInvestigation, isASN } from 'utils';
import { ALERT_TYPES, createGenericNotification, UserInvestigation } from 'model';
import Investigation from '../investigations/Investigation';
import { Redirect } from 'react-router-dom';

export default function ASReport({ assetValue }: { assetValue: string }) {
  const { asset } = useASNQuery(assetValue);
  const isValidASN = isASN(assetValue);
  const orgName = isValidASN ? asset?.org?.orgName : '';
  const assetName = `AS${assetValue}${orgName ? ` ${orgName}` : ''}`;
  const params = { assetValue, assetName };

  const newInvestigation: UserInvestigation = {
    title: `AS${assetValue}`,
    subtitle: orgName,
    description: '',
    sections: [],
  };

  if (!isValidASN) {
    createGenericNotification('invalid-asset', {
      alertType: ALERT_TYPES.error,
      message: `${assetValue} is not a valid ASN.`,
      title: 'Invalid ASN',
    });
    return <Redirect to="/" />;
  }

  addSectionToInvestigation(newInvestigation, ComponentPluginName.ASNAbout, params, 'About');
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.ASNRouting,
    params,
    'Routing Summary',
  );
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.ASNDetailsTable,
    params,
    'Detailed View of Assets',
  );

  return <Investigation investigation={newInvestigation} />;
}
