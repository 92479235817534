import React, { Fragment } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { AssetType } from 'model';
import IPReport from './reports/IPReport';
import PrefixReport from './reports/PrefixReport';
import ASReport from './reports/ASReport';
import OrgReport from './reports/OrgReport';
import RouterReport from './reports/RouterReport';
import CountryReport from './reports/CountryReport';
import FacilityReport from './reports/FacilityReport';
import IXReport from './reports/IXReport';
import { guessAssetType, parseASN } from 'utils';
import 'react-resizable/css/styles.css';
import DomainReport from './reports/DomainReport';
import HostnameReport from './reports/HostnameReport';

export function AssetReport({ location }: RouteComponentProps) {
  const searchParams = new URLSearchParams(location.search);
  const [assetType, assetValue] = getAssetQuery(searchParams);
  const nonCaseSensitiveTypes = [
    AssetType.Router,
    AssetType.Facility,
    AssetType.IX,
    AssetType.Domain,
  ];

  if (!assetType) {
    return <Redirect to="/" />;
  }

  // redirect to lowercase id for certain asset types
  if (nonCaseSensitiveTypes.includes(assetType) && assetValue !== assetValue.toLowerCase()) {
    const newUrl = `${window.location.pathname}?${assetType}=${assetValue.toLowerCase()}`;
    return <Redirect to={newUrl} />;
  }

  return <AssetReportContent assetType={assetType} assetValue={assetValue} />;
}

function getAssetQuery(searchParams: URLSearchParams): [AssetType | undefined, string] {
  for (const assetType of Object.values(AssetType)) {
    if (searchParams.has(assetType)) {
      let assetValue = searchParams.get(assetType) as string;
      return [assetType as AssetType, assetValue];
    }
  }
  return [undefined, ''];
}

export function AssetReportContent({
  assetType,
  assetValue,
}: { assetType?: AssetType; assetValue: string }) {
  if (!assetType) {
    assetType = guessAssetType(assetValue);
  }
  return (
    <Fragment>
      {assetType === AssetType.IP && <IPReport assetValue={assetValue} />}
      {assetType === AssetType.Prefix && <PrefixReport assetValue={assetValue} />}
      {assetType === AssetType.AS && <ASReport assetValue={parseASN(assetValue)} />}
      {assetType === AssetType.Domain && <DomainReport assetValue={assetValue} />}
      {assetType === AssetType.Org && <OrgReport assetValue={assetValue} />}
      {assetType === AssetType.Router && <RouterReport assetValue={assetValue} />}
      {assetType === AssetType.Country && <CountryReport assetValue={assetValue} />}
      {assetType === AssetType.Facility && <FacilityReport assetValue={assetValue} />}
      {assetType === AssetType.IX && <IXReport assetValue={assetValue} />}
      {assetType === AssetType.Hostname && <HostnameReport assetValue={assetValue} />}
    </Fragment>
  );
}
