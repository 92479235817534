import React from 'react';
import { ReactComponent as LER } from 'svg/network/network-external.svg';
import { ReactComponent as LSR } from 'svg/network/network-internal.svg';
import { ReactComponent as LR } from 'svg/network/network-all.svg';

export enum MPLSTypes {
  LER = 'Label edge router',
  LSR = 'Label switch router',
}
const mplsTypes = [MPLSTypes.LER, MPLSTypes.LSR];

export function MPLSIcon({ types, iconProps }: { types: string[]; iconProps?: any }) {
  return mplsTypes.every((type) => types?.includes(type)) ? (
    <LR {...iconProps} />
  ) : types?.includes(MPLSTypes.LER) ? (
    <LER {...iconProps} />
  ) : types?.includes(MPLSTypes.LSR) ? (
    <LSR {...iconProps} />
  ) : undefined;
}
