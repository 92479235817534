import { NewPaginationProps } from 'components';
import { useRef, useState, useEffect, useMemo } from 'react';
import { getNestedData } from 'utils';
import { useLazyComponentPluginQuery } from './gql';
import { isEqual } from 'lodash';
import { PageState } from './useDataTable';
import { AdvancedQuery } from 'model';
import { ApolloError } from '@apollo/client';

export const DEFAULT_PAGINATED_RESPONSE_SIZE = 10;

/**
 * A version of useAdvancedQuery() that supports pagination. This is similar to useDataTable, however excludes the need
 * to define extra parameters such as columns/tabs. Tables should continue to use useDataTable.
 * @returns {data: any[[]]}    The data that is returned is an array of pages. Each page element itself is an
 *                             array of results for that page in the pagination. Use data.flat() to remove the
 *                             nesting.
 */
export function usePaginatedAdvancedQuery(
  query: AdvancedQuery,
  queryVariables: any,
): [data: any[], loading: boolean, error: ApolloError, paginationProps: NewPaginationProps] {
  const [advancedQuery, setAdvancedQuery] = useState(query);
  const [loadTriggered, setLoadTriggered] = useState(false);
  const [pages, setPages] = useState<any>([]);
  const [runQuery, result] = useLazyComponentPluginQuery(advancedQuery);
  const runQueryRef = useRef(runQuery);
  const resetPages = useRef(false);
  const [{ currentPage, pageCursors }, setPageState] = useState<PageState>({
    currentPage: 1,
    pageCursors: {},
  });

  const pageData = useMemo(() => {
    if (!result?.data) return undefined;
    return getNestedData(result.data);
  }, [result]);

  const oldData = useRef(pageData);

  const returnData = useMemo(() => {
    return pages.map((page: any) => page.items);
  }, [pages]);

  const paginationProps: NewPaginationProps = useMemo(() => {
    function onLoadRows(pageSize: number, pageNumber: number) {
      setLoadTriggered(true);
      resetPages.current = false;
      const cursor = pageCursors[pageNumber];
      if (cursor || pageNumber === 1) {
        runQuery({ variables: { ...queryVariables, cursor: cursor, size: pageSize } }).then(() =>
          setLoadTriggered(false),
        );
        setPageState({ currentPage: pageNumber, pageCursors });
      }
    }
    return {
      currentPage,
      onLoadRows: onLoadRows,
      totalRows: pageData?.totalCount ?? oldData?.current?.totalCount,
      loading: loadTriggered,
      postFilterStatus: pageData?.pageFilterStatus,
    };
  }, [loadTriggered, runQuery, currentPage, pageCursors, pageData, queryVariables]);

  // When runQuery changes, update the ref so that we always have access to runQuery without causing a circular dependency in other useEffects
  useEffect(() => {
    runQueryRef.current = runQuery;
  }, [runQuery]);

  // This updates our query, which leads to updating our data, only if the changes made are not a viewConfig change.
  useEffect(() => {
    const newAQ = query;
    setAdvancedQuery((current) => {
      if (!isEqual({ ...current, viewConfig: undefined }, { ...newAQ, viewConfig: undefined })) {
        return newAQ;
      }
      return current;
    });
  }, [query]);

  // When data updates, reset data as first page, or append to existing data, depending on resetPages ref.
  useEffect(() => {
    if (pageData) {
      oldData.current = pageData;
      if (resetPages.current) {
        setPages([pageData]);
      } else {
        setPages((curr: any) => [...curr, pageData]);
      }
    }
  }, [pageData]);

  // When the query variables change, reset the page cursor tracking in preparation for a new query to be executed
  useEffect(() => {
    if (advancedQuery) {
      resetPages.current = true;
      if (queryVariables.size === 0) return;
      runQueryRef.current({
        variables: {
          ...queryVariables,
          size: queryVariables.size ?? DEFAULT_PAGINATED_RESPONSE_SIZE,
        },
      });
      setPageState({ currentPage: 1, pageCursors: {} });
    }
  }, [advancedQuery, queryVariables]);

  // Whenever the data, or cursors update, we should maintain our page cursor tracking
  useEffect(() => {
    if (pageData) {
      const nextPage = currentPage + 1;
      if (pageCursors[nextPage] !== pageData.nextCursor) {
        const cursors = { ...pageCursors };
        cursors[nextPage] = pageData.nextCursor;
        setPageState((curr) => ({ ...curr, pageCursors: cursors }));
      }
    }
  }, [pageData, currentPage, pageCursors]);

  return [returnData, result.loading, result.error, paginationProps];
}
