import {
  ApolloClient,
  DefaultOptions,
  InMemoryCache,
  ApolloLink,
  NormalizedCacheObject,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { persistCache, LocalForageWrapper } from 'apollo3-cache-persist';
import { createUploadLink } from 'apollo-upload-client';
import localforage from 'localforage';
import { hasAuthentication, authenticatedFetch } from 'utils';

export type GraphQlClient = ApolloClient<NormalizedCacheObject>;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export async function createGraphQLClient() {
  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_GQL_ENDPOINT,
    fetch: hasAuthentication() ? authenticatedFetch : undefined,
  });

  const retryLink = new RetryLink({
    delay: {
      jitter: false,
    },
    attempts: {
      max: 3,
      retryIf: (error) => !!error && !error.toString().includes('aborted'),
    },
  });

  const link = ApolloLink.from([retryLink, httpLink]);

  const cache = new InMemoryCache();

  if (process.env.REACT_APP_PERSIST_APOLLO_CACHE) {
    await persistCache({
      cache,
      storage: new LocalForageWrapper(localforage),
    });
  }

  const client = new ApolloClient({
    cache,
    link,
    defaultOptions,
  });

  (window as any).__APOLLO_CLIENT__ = client;
  return client;
}

export function getGraphQLClient() {
  return (window as any).__APOLLO_CLIENT__ as GraphQlClient;
}
