import { gql, useMutation } from '@apollo/client';
import { SharedContext, SidebarContext } from 'appContexts';
import { Banner } from 'components/banners';
import { RadioCard, RadioPosition } from 'components/RadioCard';
import { isNonDataPlugin } from 'components/componentPluginNames';
import { cloneDeep } from 'lodash';
import {
  ALERT_TYPES,
  AdvancedQuery,
  QueryFilterComparator,
  UserInvestigation,
  UserInvestigationSection,
  acknowledgeNotification,
  createGenericNotification,
  refreshRemoteUserDataSubscription,
} from 'model';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  createNewParamsAndSectionsForTemplate,
  prepareImagesForCopy,
  getConfigAdvancedQuery,
  getDefaultTitle,
  getQueryClauseAssetType,
  getTemplateAssetTypeFromId,
  getTemplateParamId,
  isAssetReport,
  isDatasetQuery,
  isOutputVariable,
  isSharedLink,
  lookupSectionTemplateParams,
  makeUniqueId,
  parseInvestigationSectionId,
  pushDatasetAlert,
  saveInvestigation,
  sectionHasMissingDataset,
  toInvestigationSectionId,
} from 'utils';

import { SidebarOptions } from './Sidebar';
import { Spinner } from 'components';

type SidebarCopyInvestigationProps = {
  investigation: UserInvestigation;
  selectOption: Function;
};

enum CopyType {
  Mosaic = 'mosaic',
  Template = 'template',
}

const copyMutation = gql`
  mutation($sharedKey: String!) {
    copySharedUserData(input:{sharedKey: $sharedKey}) {
      key
      value
    }
  }
`;

export function SidebarCopyInvestigation({
  investigation,
  selectOption,
}: SidebarCopyInvestigationProps) {
  const { localTemplateParams, setLocalTemplateParams, setFocusedTile } =
    useContext(SidebarContext);
  const { sharedKey } = useContext(SharedContext);
  const [copyNormalData, setCopyNormalData] = useState<UserInvestigation>();
  const [saveParamValues, setSaveParamValues] = useState<boolean>(true);
  const [goToMosaic, setGoToMosaic] = useState<boolean>(true);
  const [copyLoading, setCopyLoading] = useState<boolean>(false);
  const [curateTriggered, setCurateTriggered] = useState<boolean>(false);

  const [customTitleSet, setCustomTitleSet] = useState<boolean>(false);

  const history = useHistory();

  const [copyMutationMosaic, { data: copyMutationData, error: copyMutationError }] = useMutation(
    copyMutation,
    {
      onCompleted: () => {
        setCopyLoading(false);
      },
    },
  );
  const isSharedMosaic = isSharedLink();
  const isTemplate = investigation?.isTemplate;
  const [selectedType, setSelectedType] = useState<CopyType>(
    isTemplate ? CopyType.Template : CopyType.Mosaic,
  );

  const copyData = useMemo(() => {
    if (isSharedMosaic && !isTemplate && selectedType !== CopyType.Template)
      return copyMutationData;
    return copyNormalData;
  }, [copyMutationData, isTemplate, copyNormalData, isSharedMosaic, selectedType]);

  const copyError = useMemo(() => {
    if (isSharedMosaic && !isTemplate) return copyMutationError;
  }, [copyMutationError, isSharedMosaic, isTemplate]);

  const [title, setTitle] = useState(
    isAssetReport()
      ? `${investigation.title} (Copy)`
      : isSharedMosaic
        ? investigation.title
        : getDefaultTitle(isTemplate),
  );

  const undefinedParamsExist = useMemo(() => {
    if (isTemplate) {
      for (let i = 0; i < investigation?.sections?.length; i++) {
        if (!localTemplateParams) continue;
        const [plugin, params] = parseInvestigationSectionId(investigation?.sections[i].id);
        const advancedQuery: AdvancedQuery = getConfigAdvancedQuery(params);
        if (!advancedQuery && !isNonDataPlugin(plugin) && !params.assetValue) return true;
        const activeTemplateParams = lookupSectionTemplateParams(localTemplateParams, params);
        for (let j = 0; j < activeTemplateParams.length; j++) {
          if (!activeTemplateParams[j]?.value) {
            return true;
          }
        }
        // check for queries with datasets
        if (!!advancedQuery) {
          let messages = [];
          if (isDatasetQuery(advancedQuery)) {
            const datasetParam = activeTemplateParams.find(
              (param) => `$${getTemplateParamId(param)}` === advancedQuery?.selection?.value,
            );
            let datasetId;
            if (isOutputVariable(datasetParam)) {
              datasetId = datasetParam?.value?.dataId;
            } else {
              datasetId = datasetParam?.value;
            }
            if (!datasetId) return true;
            pushDatasetAlert(messages, datasetId);
          }
          for (let i = 0; i < advancedQuery.filterClauses.length; i++) {
            const clause = advancedQuery.filterClauses[i];
            if (
              clause.comparator === QueryFilterComparator.IsInDataset &&
              typeof clause.value === 'string'
            ) {
              const datasetId = activeTemplateParams.find(
                (param) => `$${getTemplateParamId(param)}` === clause?.value,
              )?.value;
              if (!datasetId) return true;
              pushDatasetAlert(messages, datasetId);
            }
          }
          if (messages.length > 0) {
            return true;
          }
        }
      }
    }
    return false;
  }, [isTemplate, investigation?.sections, localTemplateParams]);

  // Handle copy success or errors
  useEffect(() => {
    const copyingToTemplate = selectedType === CopyType.Template;
    if (copyError) {
      acknowledgeNotification('copy-error'); // Acknowledge previous copy error notifications
      createGenericNotification('copy-error', {
        alertType: ALERT_TYPES.error,
        message: 'Failed to copy the mosaic. Please try again.',
        title: 'Copy Failed',
        onClear: () => acknowledgeNotification('copy-error'),
      });
      // Cleanup notification on unmount
      return () => acknowledgeNotification('copy-error');
    } else if (copyData?.copySharedUserData?.key) {
      refreshRemoteUserDataSubscription();
      const copiedId = copyData?.copySharedUserData?.key.split(':')[1];
      const copiedTitle = copyData?.copySharedUserData?.value?.title;
      const copyingToTemplate = selectedType === CopyType.Template;
      acknowledgeNotification('copy-success'); // Acknowledge previous copy success notifications
      refreshRemoteUserDataSubscription();
      createGenericNotification('copy-success', {
        alertType: ALERT_TYPES.success,
        title: 'Copy Success',
        message: (
          <div>
            <i>{copiedTitle}</i> has been copied to your private space.
          </div>
        ),
      });
      if (goToMosaic || copyingToTemplate) {
        history.push(`/${copyingToTemplate ? 'template' : 'mosaic'}?id=${copiedId}`);
      }
    } else if (copyData && !curateTriggered) {
      setCurateTriggered(true);
      prepareImagesForCopy(
        { id: copyData.id, images: [] },
        !isTemplate && copyingToTemplate ? '' : undefined,
        sharedKey,
      ).then(() => {
        createGenericNotification('copy-success', {
          alertType: ALERT_TYPES.success,
          title: 'Copy Success',
          message: (
            <div>
              <i>{investigation.title}</i> has been copied to your private space as <i>{title}</i>.
            </div>
          ),
        });
        // Saving the investigation must happen synchronously with navigating. Before we were saving
        // it in a different place and causing additional renders and issues before we even
        // made it to the new investigation.
        saveInvestigation(copyData);
        if (goToMosaic || copyingToTemplate) {
          const shortID = (copyData.id as string).split(':')[1];
          history.push(`/${copyingToTemplate ? 'template' : 'mosaic'}?id=${shortID}`);
        } else {
          selectOption(undefined);
        }
        setCopyLoading(false);
        setCurateTriggered(false);
      });
    }
  }, [
    copyData,
    copyError,
    sharedKey,
    selectedType,
    history,
    isTemplate,
    goToMosaic,
    selectOption,
    title,
    investigation.title,
    setLocalTemplateParams,
    curateTriggered,
  ]);

  // Title only updates if the user did not interact with it on their own
  const selectType = useCallback(
    (copyType: CopyType) => {
      if (!copyType) return;
      setSelectedType(copyType);
      if (!customTitleSet && !isAssetReport() && !isSharedMosaic) {
        const newTitle = getDefaultTitle(copyType === CopyType.Template);
        setTitle(newTitle);
      }
    },
    [customTitleSet, isSharedMosaic],
  );

  const updateTitle = useCallback((newTitle: string) => {
    setTitle(newTitle);
    setCustomTitleSet(true);
  }, []);

  const getNewTemplateParams = useCallback(() => {
    const newTemplateParams = localTemplateParams
      ?.filter((param) => isOutputVariable(param))
      ?.map((param) => ({ ...param, label: undefined, value: undefined }));
    if (newTemplateParams.length > 0) {
      return newTemplateParams;
    }
    return undefined;
  }, [localTemplateParams]);

  // callback to copy from a mosaic to mosaic, or a template to a mosaic
  const copyToMosaic = useCallback(() => {
    // Do not use the copy mutation on templates. We cannot change what the server would return for
    // removeTemplateParamValues, but the user needs to be able to control that via the checkbox.
    if (isSharedMosaic && !isTemplate) {
      setCopyLoading(true);
      const hasErrorDataset = investigation.sections.some((section) => {
        return sectionHasMissingDataset(section);
      });
      if (hasErrorDataset) {
        createGenericNotification('copy-missing-dataset', {
          title: 'Unable to Copy Mosaic',
          alertType: ALERT_TYPES.error,
          message:
            'This mosaic references datasets which do not exist, so it cannot be safely copied. ' +
            'You can copy individual tiles, or contact the mosaic owner.',
        });
      } else {
        copyMutationMosaic({ variables: { sharedKey: investigation.sharing?.shared_key } });
      }
    } else {
      let newInvestigation: UserInvestigation = {
        id: makeUniqueId('investigation'),
        sections: cloneDeep(investigation.sections),
        title,
        subtitle: '',
        description: investigation.description,
        isTemplate: false,
        templateParams: getNewTemplateParams(),
        images: cloneDeep(investigation.images),
        pinTag: undefined,
        saved: new Date(),
        sharing: undefined,
      };
      if (isAssetReport()) {
        newInvestigation = {
          ...cloneDeep(investigation),
          id: makeUniqueId('investigation'),
          pinTag: undefined,
          saved: new Date(),
          sharing: undefined,
          title,
        };
      }
      if (isTemplate) {
        // If we are copying from a template to a mosaic, we
        // need to take all the locked template variables and replace the
        // variable references with their actual values.
        newInvestigation.sections.forEach((section) => {
          const [plugin, params] = parseInvestigationSectionId(section.id);
          if (!!params.advancedQuery) {
            // Advanced query case
            // We need to check for dataset variables in the selection,
            // and all variables in filter clauses
            const aq = getConfigAdvancedQuery(params);
            // fill in dataset and filter clause template expressions with actual values
            if (aq?.selection?.value?.startsWith('$dataset') && !params.isUnlocked) {
              const relevantParam = localTemplateParams
                ?.filter(
                  (param) => getTemplateAssetTypeFromId(getTemplateParamId(param)) === 'dataset',
                )
                ?.find((param) => `$${getTemplateParamId(param)}` === aq.selection.value);
              if (!isOutputVariable(relevantParam)) {
                // if the tile's param has an origin supplying its value, we don't have to change anything
                // since it will use an output variable in the mosaic. If not, update with the variable value
                aq.selection.label = relevantParam?.label;
                aq.selection.value = relevantParam?.value;
              }
            }
            for (let i = 0; aq.filterClauses && i < aq.filterClauses.length; i++) {
              const clause = aq.filterClauses[i];
              if (clause.isUnlocked) continue;
              const assetType = getQueryClauseAssetType(clause);
              clause.value = localTemplateParams
                ?.filter(
                  (param) => getTemplateAssetTypeFromId(getTemplateParamId(param)) === assetType,
                )
                ?.find((param) => `$${getTemplateParamId(param)}` === clause.value)?.value;
            }
          } else if (!params.isUnlocked) {
            // normal case (for asset page tiles)
            if (localTemplateParams) {
              params.assetName = localTemplateParams?.find(
                (variable) => `$${getTemplateParamId(variable)}` === params.assetValue,
              )?.label;
              params.assetValue = localTemplateParams?.find(
                (variable) => `$${getTemplateParamId(variable)}` === params.assetValue,
              )?.value;
            }
          }
          // since each section's uuid will be updated in the copied mosaic,
          // we need to update any origin fields for output variables, so all
          // the referenced tiles remain intact.
          const paramsToUpdate = [];
          newInvestigation.templateParams?.forEach((param) => {
            if (params.uuid === param.origin) {
              paramsToUpdate.push(param);
            }
          });
          params.uuid = makeUniqueId();
          if (paramsToUpdate.length > 0) {
            for (let i = 0; i < paramsToUpdate.length; i++) {
              paramsToUpdate[i].origin = params.uuid;
            }
          }
          section.id = toInvestigationSectionId(plugin, params);
        });
      }
      setCopyNormalData(newInvestigation);
    }
  }, [
    copyMutationMosaic,
    getNewTemplateParams,
    isSharedMosaic,
    isTemplate,
    investigation,
    localTemplateParams,
    title,
  ]);

  // callback to copy from a mosaic to template, or a template to a template
  const copyToTemplate = useCallback(() => {
    const newInvestigation = createNewParamsAndSectionsForTemplate({
      sections: cloneDeep(investigation?.sections)
        .filter(
          (section: UserInvestigationSection) =>
            isTemplate || !isNonDataPlugin(parseInvestigationSectionId(section.id)[0]),
        )
        .map((section: UserInvestigationSection) => ({
          ...section,
          title: isTemplate ? section.title : '',
          description: isTemplate ? section.description : '',
        })),
      title,
      id: makeUniqueId('investigation'),
      description: isTemplate ? investigation.description : '',
      subtitle: isTemplate ? investigation.subtitle : '',
      tags: isTemplate ? investigation.tags : undefined,
      isTemplate: true,
      images: isTemplate ? [...investigation.images] : [],
      templateParams: cloneDeep(
        saveParamValues ? localTemplateParams : investigation.templateParams,
      ),
      removeTemplateParamValues: !saveParamValues,
    });
    setCopyNormalData(newInvestigation);
    setFocusedTile(undefined);
  }, [isTemplate, localTemplateParams, investigation, title, saveParamValues, setFocusedTile]);

  const radioCardStyle = {
    width: '183px',
    paddingTop: '14px',
    paddingLeft: '16px',
  };

  const invalidTemplateMessage = (
    <Fragment>
      Tiles contain missing or invalid values. Fill in the missing values to create a mosaic{' '}
      <span
        onClick={() => {
          selectOption(SidebarOptions.TemplateVariables);
        }}
        style={{ color: '#4939AC', textDecoration: 'underline', cursor: 'pointer' }}
      >
        here
      </span>
    </Fragment>
  );

  return (
    <div style={{ padding: '0 16px 8px 16px' }}>
      <div className="input-label" style={{ marginBottom: '4px', paddingTop: '4px' }}>
        copy as
      </div>
      <div className="uk-flex" style={{ gap: '8px' }}>
        <RadioCard
          title="MOSAIC"
          description=""
          onSelect={() => selectType(CopyType.Mosaic)}
          radioPosition={RadioPosition.Left}
          style={radioCardStyle}
          selected={selectedType === CopyType.Mosaic}
        />
        <RadioCard
          title="TEMPLATE"
          description=""
          onSelect={() => selectType(CopyType.Template)}
          radioPosition={RadioPosition.Left}
          style={radioCardStyle}
          selected={selectedType === CopyType.Template}
        />
      </div>
      {undefinedParamsExist && selectedType === CopyType.Mosaic ? (
        <Banner
          title={'Invalid Template'}
          noClose={true}
          alertType={ALERT_TYPES.error}
          message={invalidTemplateMessage}
          style={{ margin: '8px 0 0 0' }}
        />
      ) : (
        <Fragment>
          {/*Title*/}
          <div className="uk-inline uk-width-1-1 uk-margin-large-bottom">
            <label className="input-label">Title</label>
            <input
              className={`uk-input uk-margin-bottom ${isSharedMosaic ? 'app-cursor-disabled' : ''}`}
              value={title}
              onChange={(event) => updateTitle(event.target.value)}
              disabled={isSharedMosaic}
            />
          </div>
          {/*Options and Create Button*/}
          <div className="uk-flex">
            {selectedType === CopyType.Template && (
              <label className="app-cursor-pointer uk-margin-top">
                <input
                  type="checkbox"
                  className="uk-checkbox uk-margin-right"
                  checked={saveParamValues}
                  onChange={() => setSaveParamValues((curr) => !curr)}
                />
                Include Variable Values
              </label>
            )}
            {selectedType === CopyType.Mosaic && (
              <label className="uk-margin-top app-cursor-pointer">
                <input
                  type="checkbox"
                  className="uk-checkbox uk-margin-right"
                  checked={goToMosaic}
                  onChange={() => setGoToMosaic((curr) => !curr)}
                />
                Go To Mosaic
              </label>
            )}
            <button
              type="button"
              disabled={copyLoading}
              className={'uk-button uk-modal-close uk-button-default uk-margin-auto-left'}
              onClick={() => {
                selectedType === CopyType.Mosaic ? copyToMosaic() : copyToTemplate();
              }}
            >
              {copyLoading && <Spinner ratio={0.55} style={{ padding: '0', marginRight: '8px' }} />}
              Create
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
}
