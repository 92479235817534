import React, { useMemo } from 'react';
import { abbreviatedCount, getFilterClauseOption, NewTableColumn } from 'utils';
import { components, StylesConfig } from 'react-select';
import { QueryFilterComparator } from 'model';
import Select from 'react-select';
import { reactSelectColumnOptionSelectStyle, DropdownIndicator, ClearIndicator } from 'components';
import { isArray } from 'lodash';

export function OptionSelectFilter({
  aggregation,
  column,
  onAddFilter,
}: {
  aggregation: any;
  onAddFilter: any;
  column: NewTableColumn;
}) {
  const resolutionOptions = Object.keys(aggregation?.data);
  const columnPieces =
    column.clauseType && getFilterClauseOption(column.clauseType).field.split(':');
  const index =
    columnPieces &&
    resolutionOptions.find((res) => columnPieces[0] && columnPieces[0].includes(res));

  let buckets: any;
  if (index != null) {
    buckets = aggregation?.data[index]?.aggregation;
    let id: string = `${column.aggField ?? (columnPieces && columnPieces.length >= 2 && columnPieces[1])}`;
    if (buckets) buckets = buckets[id]?.buckets;
  }
  const formattedBuckets = useMemo(() => {
    const formatFunction = column.formatFunction;
    if (!formatFunction || !buckets) return buckets;
    let formattedLabels = formatFunction(buckets.map((bucket: any) => bucket.key));
    return formattedLabels
      .map((key: string) => {
        let docCount = 0;
        buckets.forEach((bucket: any) => {
          const pieces = bucket.key.split(':');
          if (pieces[pieces.length - 1] === key) {
            docCount += bucket.docCount;
          }
        });
        return { docCount, key };
      })
      .sort((a: any, b: any) => b.docCount - a.docCount);
  }, [buckets, column]);

  const currentFilter = useMemo(() => {
    let retVal: any = undefined;
    if (column.columnFilterClauses && column.columnFilterClauses.length > 0) {
      let label = column.columnFilterClauses.find(
        (clause) => clause.comparator === (column.comparator ?? 'is'),
      )?.value;
      let count = undefined;
      let index = undefined;
      for (let i = 0; i < formattedBuckets?.length; i++) {
        if (formattedBuckets[i].key === column.columnFilterClauses[0]?.value) {
          count = formattedBuckets[i].docCount;
          index = i;
          break;
        }
      }
      if (count != null && index != null && label != null) {
        retVal = { label, count, index };
      }
    }
    if (Array.isArray(retVal)) retVal = retVal[0];
    return retVal;
  }, [column, formattedBuckets]);

  function clearOptionFilter() {
    if (!currentFilter) return;
    onAddFilter && onAddFilter(column?.clauseType, undefined);
  }

  function addOptionFilter(value: string) {
    const newFilterClause = {
      comparator: column.comparator ?? QueryFilterComparator.Is,
      type: column.clauseType,
      value,
    };
    onAddFilter && onAddFilter(column.clauseType, newFilterClause);
  }
  const Option = (props: any) => {
    const docStyle = getComputedStyle(document.documentElement);
    const secondaryColor = docStyle.getPropertyValue('--app-color-input-inner');
    const optionAggregation = props.selectProps?.buckets?.find(
      (option: any) => option.key === props.label,
    )?.docCount;
    return (
      <div>
        <components.Option {...props}>
          <div className="uk-flex app-align-center">
            <label className="option-select-option">{props.label}</label>
            {index && resolutionOptions && index === resolutionOptions[0] && (
              <div className="uk-float-right" style={{ marginLeft: 'auto', color: secondaryColor }}>
                {abbreviatedCount(optionAggregation)}
              </div>
            )}
          </div>
        </components.Option>
      </div>
    );
  };

  const customReactSelectProps = useMemo(() => {
    return { buckets: formattedBuckets };
  }, [formattedBuckets]);

  return (
    <div
      style={{ top: '0%', width: '100%' }}
      className="uk-position-relative uk-display-inline-block"
    >
      <Select
        {...customReactSelectProps}
        options={formattedBuckets?.map((bucket: any) => {
          return { label: bucket.key, value: bucket.key };
        })}
        placeholder={'Filter'}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={true}
        styles={reactSelectColumnOptionSelectStyle() as StylesConfig}
        components={{
          Option,
          DropdownIndicator,
          ClearIndicator,
        }}
        onChange={(ev: any) => {
          if (!ev) {
            clearOptionFilter();
          }
          if (isArray(ev) && ev.length > 0) {
            ev[ev.length - 1]?.value && addOptionFilter(ev[ev.length - 1]?.value);
          } else {
            ev?.value && addOptionFilter(ev?.value);
          }
        }}
        //allowSelectAll={true}
        value={
          currentFilter ? [{ value: currentFilter.label, label: currentFilter.label }] : undefined
        }
      />
    </div>
  );
}
