import { Dictionary, last, sortBy } from 'lodash';
import { hsl } from 'd3-color';
import { polygonHull } from 'd3-polygon';
import { smoothHull, PolyPoint } from './smoothHull';
import { GraphNode, GraphLink } from 'model';
import { traverseGraph, pruneGraphByEdgeWeight, removeNodesFromGraph } from 'utils';
import { D3Link, D3Node, SvgDatum } from './graphTypes';

export const primaryColor = '#b3b4b5';
export const highlightedColorLight = '#8e83d0';
export const highlightedColorDark = '#493aac';
export const arrowSize = 9;

const docStyle = getComputedStyle(document.documentElement);
const colors = [
  docStyle.getPropertyValue('--app-viz-color0'),
  docStyle.getPropertyValue('--app-viz-color1'),
  docStyle.getPropertyValue('--app-viz-color2'),
];

export function assignColorSelections(nodes: D3Node[], colorSelections?: Record<string, string[]>) {
  const colorIndexMap: Record<string, number> = {};
  if (colorSelections) {
    if (Array.isArray(colorSelections)) {
      colorSelections?.forEach((categoryValues: string[], i: number) => {
        categoryValues?.forEach((categoryValue: string) => {
          colorIndexMap[categoryValue] = i;
        });
      });
    } else {
      Object.entries(colorSelections).forEach(([key, categoryValues]) => {
        categoryValues?.forEach((categoryValue: string) => {
          colorIndexMap[categoryValue] = +key;
        });
      });
    }
  }

  nodes.forEach((node) => {
    node.data.state.colorIndex = node.data.categoryValue && colorIndexMap[node.data.categoryValue];
    if (node.data.state.colorIndex === undefined && node.data.state.collapsed) {
      for (const descendant of traverseGraph(node.data)) {
        const colorIndex = descendant.categoryValue && colorIndexMap[descendant.categoryValue];
        if (colorIndex !== undefined) {
          node.data.state.colorIndex = colorIndex;
          break;
        }
      }
    }
  });
}

export function getGroupNodeFillColor(node: D3Node, isHighlighted: boolean): string {
  let color;
  const taggedColor = colors[node.data.state.colorIndex];
  if (taggedColor) {
    color = isHighlighted ? hsl(taggedColor).darker(0.3).toString() : taggedColor;
  } else {
    color = isHighlighted ? '#e2dff8' : '#fff';
  }
  return color;
}

export function getLeafNodeFillColor(
  node: D3Node,
  isHighlighted: boolean,
  isSecondaryHighlighted: boolean,
): string {
  let color = primaryColor;
  const taggedColor = colors[node.data.state.colorIndex];
  if (taggedColor) {
    color = isHighlighted ? hsl(taggedColor).darker(0.3).toString() : taggedColor;
  } else {
    if (isHighlighted) {
      color = highlightedColorDark;
    } else if (isSecondaryHighlighted) {
      color = highlightedColorLight;
    }
  }
  return color;
}

export function getNodeBorderColor(
  node: D3Node,
  isHighlighted: boolean,
  isSecondaryHighlighted: boolean,
): string {
  let color = primaryColor;
  if (isHighlighted) {
    color = highlightedColorDark;
  } else if (isSecondaryHighlighted) {
    color = highlightedColorLight;
  }
  return color;
}

export function getLeafNodeLabelColor(node: D3Node): string {
  return colors[node.data.state.colorIndex] ? 'inherit' : '#fff';
}

export function pruneGraph(svgDatum: SvgDatum) {
  if (!svgDatum.viewConfig?.pruneValue) {
    svgDatum.prunedGraphRootNode = svgDatum.graphRootNode;
    svgDatum.prunedGraphLinks = svgDatum.graphLinks;
  } else if (svgDatum.graphLinks.length > 0) {
    const [prunedGraphRootNode, prunedGraphLinks] = pruneGraphByEdgeWeight(
      svgDatum.graphRootNode,
      svgDatum.graphLinks,
      svgDatum.viewConfig?.pruneValue,
    );
    svgDatum.prunedGraphRootNode = prunedGraphRootNode;
    svgDatum.prunedGraphLinks = prunedGraphLinks;
  }

  if (svgDatum.viewConfig?.removedNodes) {
    const [prunedGraphRootNode, prunedGraphLinks] = removeNodesFromGraph(
      svgDatum.prunedGraphRootNode,
      svgDatum.prunedGraphLinks,
      svgDatum.viewConfig?.removedNodes,
    );
    svgDatum.prunedGraphRootNode = prunedGraphRootNode;
    svgDatum.prunedGraphLinks = prunedGraphLinks;
  }
}

export function addToHighlightedNodes(node: D3Node, highlightedNodeIds: Set<string>) {
  highlightedNodeIds.add(node.data.id);
  node.children?.forEach((child) => addToHighlightedNodes(child, highlightedNodeIds));
}

export function shouldIncludeLink(linkPoints: D3Node[], selectedNode: D3Node) {
  function isNodeSelected(node: D3Node): boolean {
    return (
      node.data.id === selectedNode.data.id || (node.parent !== null && isNodeSelected(node.parent))
    );
  }
  return isNodeSelected(linkPoints[0]) || isNodeSelected(last(linkPoints) as D3Node);
}

export function constrainChildNode(node: D3Node) {
  const padding = arrowSize;
  const parent = node.parent as D3Node;
  const parentRadius = parent.r - padding;
  let { mag, vx, vy } = calcVector(node, parent);
  if (mag + node.r > parentRadius) {
    const newMag = parentRadius - node.r;
    const frac = newMag / mag;
    vx *= frac;
    vy *= frac;
    node.x = node.x > parent.x ? parent.x + vx : parent.x - vx;
    node.y = node.y > parent.y ? parent.y + vy : parent.y - vy;
  }
}

export function generateLinkPoints(link: D3Link) {
  const source = link.source as D3Node;
  const target = link.target as D3Node;
  const sourceEdge = calcSourceEdgePoint(source, target);
  const points: D3Node[] = [sourceEdge];
  const targetEdge = findClosestTargetEdgePoint(sourceEdge, target);
  const targetRadialVec = calcVector(targetEdge, target);
  const sourceToTargetVec = calcVector(sourceEdge, target);

  function addControlPoint(radialPos: number, includeTarget?: boolean) {
    let { mag, vx, vy } = targetRadialVec;
    const frac = (mag + radialPos) / mag;
    vx *= frac;
    vy *= frac;
    const x = targetEdge.x < target.x ? target.x - vx : target.x + vx;
    const y = targetEdge.y < target.y ? target.y - vy : target.y + vy;
    const node = { ...(includeTarget ? target : {}), x, y } as D3Node;
    points.push(node);
  }

  // Add control points that are radially positioned from the target edge point.
  const stepSize = 20;
  const radialPosMin = arrowSize * 3;
  let radialPos = sourceToTargetVec.mag / 2 - targetRadialVec.mag;
  while (radialPos > radialPosMin) {
    addControlPoint(radialPos);
    radialPos -= stepSize;
  }

  // Add final points used for rendering the target arrowhead.
  addControlPoint(arrowSize);
  addControlPoint(arrowSize / 2, true);

  // Update the best source edge point.
  points[0] = calcSourceEdgePoint(source, points[1]);

  // Store the target points and target key.
  link.targetPoints = points.slice(-2);
  link.targetKey = `${link.targetPoints[1].x.toFixed()}_${link.targetPoints[1].y.toFixed()}`;

  return points;
}

function calcSourceEdgePoint(source: D3Node, target: D3Node) {
  const minMag = arrowSize / 2;

  // Reduce the link's length to attach to the source node's outer edge.
  let { mag, vx, vy } = calcVector(source, target);
  let newMag = Math.max(mag - source.r - 1, minMag);
  let frac = newMag / mag;
  vx *= frac;
  vy *= frac;
  return {
    ...source,
    x: target.x > source.x ? target.x - vx : target.x + vx,
    y: target.y > source.y ? target.y - vy : target.y + vy,
  };
}

function findClosestTargetEdgePoint(source: D3Node, target: D3Node) {
  const nodeOuterPoints = getNodeOuterPoints(target);

  if (source.depth > 1 && source.depth >= target.depth && source.parent !== target.parent) {
    source = source.parent as D3Node;
  }

  let index = 0;
  let minDistance = Infinity;
  nodeOuterPoints.forEach((p, i) => {
    const distance = (p[0] - source.x) ** 2 + (p[1] - source.y) ** 2;
    if (distance < minDistance) {
      minDistance = distance;
      index = i;
    }
  });

  return { ...target, x: nodeOuterPoints[index][0], y: nodeOuterPoints[index][1] };
}

export function calcGroupNodeHullPath(node: D3Node) {
  const points: PolyPoint[] = calcGroupNodeHullPoints(node);
  const convexHull = points.length <= 2 ? points : polygonHull(points);
  if (convexHull) {
    setGroubNodeLabelPosition(node, convexHull);
    return smoothHull(convexHull, arrowSize / 2);
  } else {
    return '';
  }
}

export function calcSelectedGroupNodeHullPath(node: D3Node) {
  const points: PolyPoint[] = calcGroupNodeHullPoints(node, true);
  const convexHull = points.length <= 2 ? points : polygonHull(points);
  return convexHull ? smoothHull(convexHull, arrowSize / 2) : '';
}

function calcGroupNodeHullPoints(node: D3Node, padded?: boolean): PolyPoint[] {
  const children = node.children as D3Node[];

  if (children.length === 1) {
    const child = children[0];
    const radius = child.r + (padded ? 3 : 0);
    const minX = child.x - radius;
    const maxX = child.x + radius;
    const minY = child.y - radius;
    const maxY = child.y + radius;
    return [
      [minX, minY],
      [maxX, minY],
      [maxX, maxY],
      [minX, maxY],
    ];
  } else {
    const points: PolyPoint[] = [];
    children.forEach((node) => {
      points.push(...getNodeOuterPoints(node, padded));
    });
    return points;
  }
}

const cos45 = Math.cos(0.785398);
const sin45 = Math.cos(0.785398);

function getNodeOuterPoints(node: D3Node, padded?: boolean) {
  if (padded) {
    if (!node.paddedOuterPoints) {
      node.paddedOuterPoints = calcNodeOuterPoints(node, 5);
    }
    return node.paddedOuterPoints;
  } else {
    if (!node.outerPoints) {
      node.outerPoints = calcNodeOuterPoints(node);
    }
    return node.outerPoints;
  }
}

function calcNodeOuterPoints(node: D3Node, padding: number = 0) {
  const radius = node.r + padding;
  return [
    [node.x + radius, node.y] as PolyPoint,
    [node.x + radius * cos45, node.y - radius * sin45] as PolyPoint,
    [node.x, node.y - radius] as PolyPoint,
    [node.x - radius * cos45, node.y - radius * sin45] as PolyPoint,
    [node.x - radius, node.y] as PolyPoint,
    [node.x - radius * cos45, node.y + radius * sin45] as PolyPoint,
    [node.x, node.y + radius] as PolyPoint,
    [node.x + radius * cos45, node.y + radius * sin45] as PolyPoint,
  ];
}

function setGroubNodeLabelPosition(node: D3Node, convexHull: PolyPoint[]) {
  node.labelX =
    (Math.min(...convexHull.map((p) => p[0])) + Math.max(...convexHull.map((p) => p[0]))) / 2;
  if (convexHull.length === 4) {
    node.labelY = Math.min(...convexHull.map((p) => p[1])) - 10;
  } else {
    const points = sortBy(convexHull, 1);
    node.labelY = points[0][1] - 8;
  }
}

export function attachLinksToNodes(links: D3Link[], nodeMap: Dictionary<D3Node>) {
  links?.forEach((link) => {
    link.source = nodeMap[link.source as string];
    link.target = nodeMap[link.target as string];
  });
}

export function computeForceLinkDistance(maxDistance: number) {
  return (link: any) => Math.max(link.source.r + link.target.r + 10, maxDistance);
}

export function combineLinks(graphLinks: GraphLink[], { depth }: { depth?: number } = {}) {
  const linkMap: Dictionary<D3Link> = {};

  function getVisibleNode(node: GraphNode) {
    while (depth && node.depth > depth) {
      node = node.parent as GraphNode;
    }
    let visibleNode = node;
    while (node.parent) {
      node = node.parent;
      if (node.state.collapsed) {
        visibleNode = node;
      }
    }
    return visibleNode;
  }

  graphLinks.forEach((link: GraphLink) => {
    const sourceNode = getVisibleNode(link.source as GraphNode);
    const targetNode = getVisibleNode(link.target as GraphNode);
    if (sourceNode === targetNode) {
      return;
    }
    const key = `${sourceNode.id}_${targetNode.id}`;
    if (linkMap[key]) {
      linkMap[key].weight += link.weight;
    } else {
      linkMap[key] = { source: sourceNode.id, target: targetNode.id, weight: link.weight };
    }
  });

  const links = Object.values(linkMap);
  links.sort((a, b) => a.weight - b.weight);
  return links;
}

function calcVector(p1: any, p2: any) {
  const vx = Math.abs(p1.x - p2.x);
  const vy = Math.abs(p1.y - p2.y);
  const mag = Math.sqrt(vx * vx + vy * vy);
  return { mag, vx, vy };
}

export function truncateText(text: string) {
  if (!text) {
    return '';
  } else if (text.length > 15) {
    return text.substring(0, 15) + '...';
  }
  return text;
}
