import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { setEmbeddedUrl } from 'utils';
import { makeVar, useReactiveVar } from '@apollo/client';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { v4 as uuid4 } from 'uuid';
import { find, findIndex } from 'lodash';

const zIndex = 500;

type EmbeddedWindowInfo = {
  id: string;
  url?: string;
};

const embeddedWindowsVar = makeVar<EmbeddedWindowInfo[]>([{ id: uuid4() }]);
const topWindowVar = makeVar<string>('');

export function EmbeddedWindowLayer() {
  const assetReports = useReactiveVar(embeddedWindowsVar);

  return (
    <div className="embedded-window-layer">
      {assetReports.map((embedded) => (
        <EmbeddedWindow key={embedded.id} id={embedded.id} url={embedded.url} />
      ))}
    </div>
  );
}

export function EmbeddedWindow({ id, url }: { id: string; url?: string }) {
  const defaultSize = 500;
  const [offset] = useState(10 * findIndex(embeddedWindowsVar(), (embedded) => embedded.id === id));
  const docStyle = getComputedStyle(document.documentElement);
  const topOffset = offset + 10;
  const top = parseInt(docStyle.getPropertyValue('--app-header-height')) + topOffset;
  const topWindowId = useReactiveVar(topWindowVar);
  const left = Math.max(0, window.innerWidth - defaultSize - (offset + 20));
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const style: CSSProperties = {
    display: url ? 'block' : 'none',
    position: 'fixed',
    left,
    top,
    zIndex: topWindowId === id ? zIndex + 1 : zIndex,
  };

  useEffect(() => {
    if (url) {
      setEmbeddedUrl(id, url);
    }
  }, [id, url]);

  function onMouseDown() {
    topWindowVar(id);
  }

  function enableIFramePointerEvents(enabled: boolean) {
    if (iframeRef?.current) {
      iframeRef.current.style.pointerEvents = enabled ? 'auto' : 'none';
    }
  }

  function onClose() {
    const embeddeds = embeddedWindowsVar().filter((embedded) => embedded.id !== id);
    embeddedWindowsVar(embeddeds);
  }

  return (
    <Draggable
      handle=".app-draggable-header"
      bounds={{ top: -topOffset }}
      onStart={() => enableIFramePointerEvents(false)}
      onStop={() => enableIFramePointerEvents(true)}
    >
      <div className="app-glass-card" style={style}>
        <div className="app-draggable-header" style={{ height: 30 }} onMouseDown={onMouseDown}>
          <button
            className="uk-close uk-float-right"
            style={{ padding: 10 }}
            type="button"
            data-uk-close
            onClick={onClose}
          ></button>
        </div>
        <ResizableBox
          width={defaultSize}
          height={defaultSize}
          minConstraints={[100, 100]}
          onResizeStart={() => enableIFramePointerEvents(false)}
          onResizeStop={() => enableIFramePointerEvents(true)}
        >
          <div className="uk-position-cover" style={{ bottom: 20 }}>
            <iframe
              ref={iframeRef}
              id={id}
              title={id}
              className="embedded-window"
              src="/"
              width="100%"
              height="100%"
            />
          </div>
        </ResizableBox>
      </div>
    </Draggable>
  );
}

EmbeddedWindow.show = (url: string) => {
  const embedded = find(embeddedWindowsVar(), (embedded) => embedded.url === undefined);
  if (embedded) {
    embedded.url = url;
    embeddedWindowsVar([...embeddedWindowsVar(), { id: uuid4() }]);
    topWindowVar(embedded.id);
  }
};
