import { gql } from '@apollo/client';
import { useAssetQuery } from 'hooks';
import { AssetType } from 'model';

const asRelationshipQuery = gql`
  query ASRelationshipData($asnSet: [Int]!) {
    asData(asnSet: $asnSet) {
      geoSummary: ripA(filter: "[{'ipversion': 4}, {'must_not': {'country': 'XX'}}]") {
        asn {
          asn
          orgName
        }
        country
        continent
        origin {
          score
        }
      }
      ripA(filter: "{'ipversion': 4, 'global': True}") {
        upstreams {
          asn
          orgName
          score
        }
        downstreams {
          asn
          orgName
          score
        }
      }
    }
  }
`;

export function useASRelationshipQuery(assetValue: string) {
  const { asset, loading, error } = useAssetQuery(AssetType.AS, assetValue, asRelationshipQuery);
  return { asset, loading, error };
}
