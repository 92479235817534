import React, { useCallback, useContext } from 'react';
import { ComponentPluginContext } from 'appContexts';
import { ReactComponent as ToggleOn } from 'svg/selection/toggle-on.svg';
import { ReactComponent as ToggleOnDisabled } from 'svg/selection/toggle-on-disabled.svg';
import { ReactComponent as ToggleOff } from 'svg/selection/toggle-off.svg';
import { DatePicker } from 'components/DatePicker';
import { isSharedLink } from 'utils';

export default function HistoryConfig() {
  const { updatePluginConfig, getPluginConfig, configOptions } = useContext(ComponentPluginContext);
  const { historyEnabled, dateA, dateB } = getPluginConfig()?.history || {};
  const isShared = isSharedLink();

  const toggleHistoryMode = useCallback(() => {
    if (!isShared) {
      const usedConfig = getPluginConfig();
      const config = {
        ...usedConfig,
        history: {
          ...usedConfig?.history,
          historyEnabled: !usedConfig?.history?.historyEnabled,
        },
      };
      updatePluginConfig(config);
    }
  }, [getPluginConfig, isShared, updatePluginConfig]);

  const updateDateA = useCallback(
    (date: string | undefined) => {
      const pluginConfig = { ...getPluginConfig() };
      pluginConfig.history.dateA = date;
      updatePluginConfig(pluginConfig, true);
    },
    [getPluginConfig, updatePluginConfig],
  );

  const updateDateB = useCallback(
    (date: string | undefined) => {
      const pluginConfig = { ...getPluginConfig() };
      pluginConfig.history.dateB = date;
      updatePluginConfig(pluginConfig, true);
    },
    [getPluginConfig, updatePluginConfig],
  );

  return (
    <div className="config-option hide-in-pdf">
      <div className="uk-flex">
        <div>History</div>
        <div
          onClick={toggleHistoryMode}
          className="uk-margin-auto-left"
          data-testid="history-toggle"
        >
          {historyEnabled ? (
            isShared ? (
              <ToggleOnDisabled style={{ cursor: 'not-allowed' }} />
            ) : (
              <ToggleOn />
            )
          ) : (
            <ToggleOff />
          )}
        </div>
      </div>
      <div className="uk-text-muted">compare data between two timepoints</div>
      {historyEnabled && (
        <div className="uk-flex uk-flex-middle uk-margin-medium-top">
          <DatePicker
            selected={dateA}
            dates={configOptions?.reference || []}
            className="app-history-date-picker"
            onChangeDate={updateDateA}
          />
          <div className="uk-margin-medium-left uk-margin-medium-right">compare to</div>
          <DatePicker
            selected={dateB}
            dates={configOptions?.comparer || []}
            className="app-history-date-picker"
            onChangeDate={updateDateB}
          />
        </div>
      )}
    </div>
  );
}
