import React, { Fragment, useContext, useMemo } from 'react';
import ReportTitle from 'components/ReportTitle';
import { useFacilityQuery } from 'hooks';
import { ComponentPluginName, DisplayElement } from 'components';
import { AssetInfoContext } from 'appContexts';
import { UserInvestigationSection } from 'model';
import { toInvestigationSectionId } from 'utils';
import InvestigationSection from '../investigations/InvestigationSection';

export default function FacilityInfo({ assetValue }: { assetValue: string }) {
  const { asset } = useFacilityQuery(assetValue);
  const assetName = asset?.facility?.name;
  const { displayElements } = useContext(AssetInfoContext);
  const aboutSection: UserInvestigationSection = useMemo(
    () => ({
      id: toInvestigationSectionId(ComponentPluginName.FacilityAbout, { assetValue, assetName }),
      title: 'About',
      description: '',
    }),
    [assetValue, assetName],
  );
  const connectionsSection: UserInvestigationSection = useMemo(
    () => ({
      id: toInvestigationSectionId(ComponentPluginName.FacilityConnections, {
        assetValue,
        assetName,
      }),
      title: 'Connections',
      description: '',
    }),
    [assetValue, assetName],
  );

  return (
    <Fragment>
      {asset && (
        <Fragment>
          {displayElements?.includes(DisplayElement.Title) && (
            <ReportTitle
              title={assetName || 'Unknown Facility'}
              subtitle={asset?.peeringFacility?.org?.name}
              label="Facility"
            />
          )}
          {displayElements?.includes(DisplayElement.About) && (
            <InvestigationSection section={aboutSection} />
          )}
          {displayElements?.includes(DisplayElement.Details) && (
            <InvestigationSection section={connectionsSection} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
