import React from 'react';
import { useOrgQuery } from 'hooks';
import { ComponentPluginName } from 'components';
import { UserInvestigation } from 'model';
import { addSectionToInvestigation } from 'utils';
import Investigation from '../investigations/Investigation';

export default function OrgReport({ assetValue }: { assetValue: string }) {
  const { asset } = useOrgQuery(assetValue);

  const params = { assetValue };

  const newInvestigation: UserInvestigation = {
    title: assetValue,
    subtitle: '',
    description: '',
    sections: [],
  };

  if (!asset) return <Investigation investigation={newInvestigation} />;

  addSectionToInvestigation(newInvestigation, ComponentPluginName.OrgAbout, params, 'About');
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.OrgDetailsTable,
    params,
    'Detailed View of Assets',
  );

  return <Investigation investigation={newInvestigation} />;
}
