import React from 'react';
import AssetLink from 'components/AssetLink';
import {
  formatGeoLabel,
  formatReputationScore,
  getCountryName,
  getReputationClass,
  humanizeDateTime,
} from 'utils';
import {
  CellPrefix,
  CellSingleLinked,
  CellASN,
  CellRouter,
  CellBasic,
  CellIP,
  CellEdgeStats,
  CellIPOrRouter,
  CellRouterVendor,
  CellBasicPrefix,
  CellBasicRouter,
} from 'components/TableCell';
import { AssetType, TargetPrefix, UserTag } from 'model';
import { cloneDeep, flatten, uniq } from 'lodash';
import { AssetGeo, AssetList, SeeMore } from 'components/index';
import punycode from 'punycode/punycode.es6';
import { ReactComponent as CheckIcon } from '../svg/actions/checkmark-fill.svg';

export function formatProvidersLabel(label: string) {
  if (!isNaN(+label)) {
    return `AS${label}`;
  }
  return label;
}
function formatFacilityLabel(label: string) {
  if (label.length === 2) {
    const countryName = getCountryName(label);
    if (countryName !== 'Unknown') {
      return countryName;
    }
  }
  return label;
}

// Generics
export const asnItem = {
  name: 'ASN',
  selector: (item: any) => (item?.asn?.asn ? 'asn.asn' : 'asn'),
  cell: (item: any) => <CellASN queryObj={item} />,
};

export const countryItem = {
  name: 'Location',
  selector: 'country',
  cell: (item: any) =>
    item.country && (
      <CellSingleLinked
        value={item.country.length === 2 ? getCountryName(item.country) : item.country}
      />
    ),
};

export const geoItem = {
  name: 'Location',
  selector: 'country',
  minWidth: '200px',
  cell: (item: any) => <AssetGeo asset={item} />,
};

export const ipItem = {
  name: 'IP',
  selector: 'ip',
  sortable: true,
  minWidth: '200px',
  cell: (item: any) => (item?.ip?.ip ? <CellIP ip={item.ip} /> : <CellIP ip={item} />),
};

export const orgItem = {
  name: 'Organization',
  selector: 'orgName',
  minWidth: '240px',
  cell: (item: any) => <CellSingleLinked value={item.orgName} />,
};

export const prefixItem = {
  name: 'Prefix',
  selector: 'prefix',
  maxWidth: '280px',
  cell: (item: any) => <CellPrefix prefix={item} />,
};

export const basicPrefixItem = {
  name: 'Prefix',
  selector: 'prefix',
  maxWidth: '280px',
  cell: (item: any) => <CellBasicPrefix prefix={item} />,
};

export const routingOriginAsnItem = {
  name: 'Origin ASN',
  selector: (item: any) => (item.routingOrigin ? 'routingOrigin.origins[0].asn' : 'origins[0].asn'),

  sortable: true,
  cell: (item: any) =>
    item.routingOrigin || item.origins ? (
      <ul className="uk-list uk-margin-remove">
        {item?.routingOrigin
          ? item.routingOrigin.origins.map((origin: any) => (
              <li key={origin.asn}>
                <CellASN queryObj={origin} />
              </li>
            ))
          : item.origins.map((origin: any) => (
              <li key={origin.asn}>
                <CellASN queryObj={origin} />
              </li>
            ))}
      </ul>
    ) : null,
  minWidth: '200px',
};

export const scoreItem = {
  name: 'Percentage',
  selector: 'score',
  sortable: true,
  maxWidth: '240px',
};

// Domains
export const queriedItem = {
  name: 'Queried',
  selector: 'query',
  sortable: true,
  cell: (item: any) => (item?.query === 'random_landing' ? `*.${item?.domain}` : item?.query),
};

export const resolvesToItem = {
  name: 'Resolves To',
  selector: 'ip',
  sortable: true,
  minWidth: '200px',
  cell: (item: any) =>
    item?.cdn ? (
      <span style={{ textTransform: 'capitalize' }}>{item?.cdn}</span>
    ) : item?.ip?.ip ? (
      <CellIP ip={item.ip} />
    ) : (
      <CellIP ip={item} />
    ),
};

export const domainItem = {
  name: 'Registered Domain',
  selector: 'domain',
  minWidth: '12rem',
  cell: (item: any) => <AssetLink value={item.domain} />,
};

export const hostItem = {
  name: 'Hostname',
  selector: 'host',
  minWidth: '15rem',
  cell: (item: any) => (
    <AssetLink
      value={item.host ? punycode.toUnicode(item.host) : ''}
      forcedType={AssetType.Hostname}
    />
  ),
};

export const cnamesItem = {
  name: 'CNAMES',
  minWidth: '15rem',
  cell: (item: any) => {
    const cnames: string[] = item.answers
      ? uniq(flatten(item.answers.map((answer: any) => answer.cnames || []))).map((cname: any) =>
          punycode.toUnicode(cname.host),
        )
      : [];
    const cnamesLinks = cnames.map((cname: string) => <AssetLink value={cname} />);
    return <SeeMore items={cnamesLinks} topCount={3} slop={2} />;
  },
};

export const hostAddressesItem = {
  name: 'Addresses',
  minWidth: '15rem',
  cell: (item: any) => {
    const addresses = item?.answers?.map((answer: any) => {
      return answer.addresses?.map((address: any) => {
        return address?.prefix
          ? { ...address?.prefix, geo: { country: address?.country, anycast: address?.anycast } }
          : { ...address?.ip, geo: { country: address?.country, anycast: address?.anycast } };
      });
    });
    const flattenedAddresses: any[] = uniq(flatten(addresses));

    return (
      <SeeMore
        className="app-domain-addresses"
        items={flattenedAddresses.map((addr: any) =>
          addr?.ip ? <CellIP ip={addr} /> : <CellPrefix prefix={addr} />,
        )}
        topCount={3}
        slop={2}
      />
    );
  },
};

export const hostTypeItem = {
  name: 'Hostname Type',
  selector: 'hostTypes',
  width: '10rem',
  cell: (item: any) =>
    item?.hostTypes?.length > 0 ? (
      <ul className="uk-list uk-list-collapse uk-margin-remove">
        {item?.hostTypes.map((hostType: any) => (
          <li key={hostType}>{hostType}</li>
        ))}
      </ul>
    ) : (
      'host'
    ),
};

// Exchanges
export const ixItem = {
  name: 'Exchange',
  selector: 'name',
  sortable: true,
  width: '300px',
  cell: (item: any) => (
    <CellBasic
      title={<AssetLink value={item.name} linkValue={item.id} />}
      subtitle={item.nameLong}
    />
  ),
};

export const ixNetworksItem = {
  name: 'Number of Peers',
  selector: 'peerCount',
  sortable: true,
  minWidth: '120px',
  maxWidth: '240px',
};

export const rdnsItem = {
  name: 'Reverse DNS',
  selector: 'rdns',
  sortable: true,
  minWidth: '200px',
};

export const maltrailItem = {
  name: 'Maltrail Behavior',
  cell: (item: any) =>
    item.attributesMaltrail ? (
      <CellBasic
        title={item.attributesMaltrail.maltrailBehaviors?.join(', ')}
        subtitle={item.attributesMaltrail.maltrailMalwares?.join(', ')}
      />
    ) : null,
};

export const maltrailSourcesItem = {
  name: 'Behavior Source',
  cell: (item: any) =>
    item?.attributesMaltrail?.maltrailUpstreamSources ? (
      <CellBasic title={item?.attributesMaltrail?.maltrailUpstreamSources?.join(', ')} />
    ) : null,
};

export const blacklistItem = {
  name: 'IPsum Blacklists',
  selector: 'ipsumBlacklistCount',
};

// Facility
export const facilityItem = {
  name: 'Facility',
  selector: 'name',
  sortable: true,
  width: '300px',
  cell: (item: any) => (
    <CellBasic
      title={<AssetLink value={item.name} linkValue={item.id} />}
      subtitle={formatGeoLabel(item.geo)}
    />
  ),
};
export const basicFacilityItem = {
  name: 'Facility',
  selector: 'name',
  sortable: true,
  width: '300px',
  cell: (item: any) => <CellBasic title={<AssetLink value={item.name} linkValue={item.id} />} />,
};

export const facilityNetworksItem = {
  name: 'Number of Peers',
  selector: 'peerAsnCount',
  sortable: true,
  minWidth: '160px',
  width: '200px',
  maxWidth: '240px',
};

/***** Manager Table *****/
export const kebabItem = {
  accessor: 'id',
  disableSortBy: true,
  width: 32,
  minWidth: 32,
  maxWidth: 32,
  align: 'right',
};

export const mosaicTagsItem = {
  Header: 'Tags',
  accessor: 'tags',
  width: 250,
  disableSortBy: true,
  Cell: ({ cell }: any) => {
    return (
      <div className="app-tag-container">
        {cell?.value?.map((tag: UserTag) => (
          <div className="app-tag" key={tag.name}>
            {tag.name}
          </div>
        ))}
      </div>
    );
  },
};

export const modifiedItem = {
  Header: 'Modified',
  accessor: 'saved',
  sortDescFirst: true,
  align: 'right',
  width: 150,
  Cell: ({ cell }: any) => {
    return humanizeDateTime(cell?.value, true);
  },
};

export const mosaicPinItem = {
  accessor: 'pinTag',
  disableSortBy: true,
  width: 32,
  minWidth: 32,
  maxWidth: 32,
  disableRowClick: true, // Custom prop
};

export const mosaicTitleItem = {
  Header: 'Mosaic',
  accessor: 'title',
  width: 350,
  sortType: 'string',
};

export const templateTitleItem = {
  Header: 'Template',
  accessor: 'title',
  width: 350,
  sortType: 'string',
};

// Routers
export const routerIdItem = {
  name: 'Router',
  selector: 'routerId',
  grow: 1.5,
  cell: (item: any) => item.routerId && <CellRouter router={item} />,
};

export const basicRouterIdItem = {
  name: 'Router',
  selector: 'routerId',
  grow: 1.5,
  cell: (item: any) => item.routerId && <CellBasicRouter router={item} />,
};

export const routerDeviceItem = {
  name: 'Device',
  selector: 'vendor.name',
  minWidth: '10rem',
  grow: 1.5,
  cell: (item: any) => item.vendor && <CellRouterVendor router={item} />,
};

export const interfaceItem = {
  name: 'Aliased IPs',
  selector: 'ipCount',
  center: true,
  width: '100px',
};

export const queriedInterfaceItem = {
  name: 'Queried IPs',
  selector: 'responsiveIpCount',
  width: '100px',
  center: true,
};

// Services
export const servicesItem = {
  name: 'Service',
  selector: 'attributesServices.services[0].name',
  minWidth: '10rem',
  grow: 1.5,
  cell: (item: any) =>
    item?.attributesServices &&
    item.attributesServices.services.map((service: any) => {
      const { name, region } = service;
      return <CellBasic key={name} title={name} subtitle={region} />;
    }),
};

export const servicesTagsItem = {
  name: 'Service Tags',
  selector: 'attributesServices.services[0].tags[0]',
  minWidth: '10rem',
  grow: 1.5,
  cell: (item: any) => {
    const tags: any[] = [];
    item.attributesServices &&
      item.attributesServices.services.forEach((service: any) => {
        service.tags && tags.push(...service.tags);
      });
    return (
      <div className="app-tag-container">
        {uniq(tags)
          .sort()
          .map((tag: string) => {
            return (
              <div className="app-tag" key={tag}>
                {tag}
              </div>
            );
          })}
      </div>
    );
  },
};

// IP Edges
export const targetPrefixItem = {
  name: 'Target Prefix',
  selector: 'tpfx.prefix',
  cell: (item: any) => <CellPrefix prefix={getTpfxAsset(item) ?? {}} />,
  minWidth: '160px',
};

export const targetAsnItem = {
  name: 'Target Origin',
  selector: '',
  cell: (item: any) => item.tpfxAsn?.length > 0 && <CellASN queryObj={item.tpfxAsn[0]} />,
  minWidth: '160px',
};

export const srcItem = {
  name: 'Hop Src',
  selector: '',
  cell: (item: any) => <CellIPOrRouter asset={getIPOrRouterAsset(item, 1)} />,
  minWidth: '10rem',
};

export const srcAsn = {
  name: 'Hop Src Origin',
  selector: '',
  cell: (item: any) => item.asn1?.length > 0 && <CellASN queryObj={item.asn1[0]} />,
  minWidth: '160px',
};

export const destItem = {
  name: 'Hop Dst',
  selector: '',
  cell: (item: any) => <CellIPOrRouter asset={getIPOrRouterAsset(item, 2)} />,
  minWidth: '10rem',
};

export const destAsn = {
  name: 'Hop Dst Origin',
  selector: '',
  cell: (item: any) => item.asn2?.length > 0 && <CellASN queryObj={item.asn2[0]} />,
  minWidth: '160px',
};

export const edgeSummaryItem = {
  name: 'Traces/Week',
  selector: 'edgeTpfxTraceCount',
  cell: (item: any) => <CellEdgeStats edge={item} />,
  maxWidth: '8rem',
};

export const savedDateItem = {
  name: 'Modified',
  selector: 'saved',
  right: true,
  grow: 2,
  sortable: true,
  cell: (item: any) => {
    return humanizeDateTime(item.saved);
  },
};

// Attributes Reputation
export const reputationScoreItem = {
  name: 'Suspiciousness',
  selector: 'reputationScore',
  cell: (item: any) => {
    const reputation = formatReputationScore(item?.attributesReputation?.reputationScore || 0);
    return (
      <div className={`app-attribute-pill ${getReputationClass(reputation)}`}>{reputation}</div>
    );
  },
};

export const badIPCountItem = {
  name: 'Suspicious IP Count',
  selector: 'reputationBadIpCount',
  cell: (item: any) => {
    return item?.attributesReputation?.reputationBadIpCount || 0;
  },
};

// ******************* PCAP table items *******************
export const timestampItem = {
  Header: 'Timestamp',
  active: true,
  accessor: 'timestamp',
  filterType: 'histogram',
  clauseType: 'PcapData:Time',
  width: 200,
  minWidth: 160,
};

export const srcIpItem = {
  Header: 'Src IP',
  active: true,
  accessor: 'layers.ip.src',
  filterType: 'string',
  clauseType: 'PcapData:SrcIp',
  width: 150,
};

export const dstIpItem = {
  Header: 'Dst IP',
  active: true,
  accessor: 'layers.ip.dst',
  filterType: 'string',
  clauseType: 'PcapData:DstIp',
  width: 150,
};

export const protocolItem = {
  Header: 'Protocols',
  accessor: 'layers.frame.terminal_protocol',
  aggField: 'layers.frame.protocols.raw',
  active: true,
  filterType: 'optionSelect',
  clauseType: 'PcapData:Protocol',
  width: 175,
  comparator: 'ends with',
};

export const srcCountryItem = {
  Header: 'Src Country',
  accessor: 'srcIpInfo.geo.country',
  filterType: 'string',
  clauseType: 'PcapData:SrcCountry',
  active: false,
  width: 180,
};

export const dstCountryItem = {
  Header: 'Dst Country',
  accessor: 'dstIpInfo.geo.country',
  filterType: 'string',
  clauseType: 'PcapData:DstCountry',
  active: false,
  width: 180,
};

export const srcOrgItem = {
  Header: 'Src Organization',
  clauseType: 'PcapData:SrcOrg',
  accessor: 'srcIpInfo.orgName',
  filterType: 'string',
  active: false,
  minWidth: 160,
};

export const dstOrgItem = {
  Header: 'Dst Organization',
  clauseType: 'PcapData:DstOrg',
  accessor: 'dstIpInfo.orgName',
  filterType: 'string',
  active: false,
  minWidth: 160,
};

export const srcOriginItem = {
  Header: 'Src ASN',
  accessor: 'srcIpInfo.asn',
  filterType: 'string',
  clauseType: 'PcapData:SrcASN',
  active: false,
  width: 150,
};

export const dstOriginItem = {
  Header: 'Dst ASN',
  accessor: 'dstIpInfo.asn',
  filterType: 'string',
  clauseType: 'PcapData:DstASN',
  active: false,
  width: 150,
};

export const srcRDNSItem = {
  Header: 'Src rDNS',
  accessor: 'srcIpInfo.rdns',
  filterType: 'string',
  clauseType: 'PcapData:SrcRDNS',
  active: false,
  width: 150,
};

export const dstRDNSItem = {
  Header: 'Dst rDNS',
  accessor: 'dstIpInfo.rdns',
  filterType: 'string',
  clauseType: 'PcapData:DstRDNS',
  active: false,
  width: 150,
};

export const srcRouterItem = {
  Header: 'Src Router',
  accessor: 'srcIpInfo.routerId',
  filterType: 'optionSelect',
  active: false,
  clauseType: 'PcapData:SrcRouter',
  width: 150,
};

export const dstRouterItem = {
  Header: 'Dst Router',
  accessor: 'dstIpInfo.routerId',
  filterType: 'optionSelect',
  active: false,
  clauseType: 'PcapData:DstRouter',
  width: 150,
};

export const srcReputationItem = {
  Header: 'Src Reputation',
  accessor: 'srcIpInfo.attributesMaltrail.maltrailBehavior',
  filterType: 'optionSelect',
  clauseType: 'PcapData:SrcReputation',
  active: false,
  minWidth: 160,
};

export const dstReputationItem = {
  Header: 'Dst Reputation',
  accessor: 'dstIpInfo.attributesMaltrail.maltrailBehavior',
  filterType: 'optionSelect',
  clauseType: 'PcapData:DstReputation',
  active: false,
  minWidth: 160,
};

// COLUMN OPTIONS FOR ASSET PAGE TABLES
export const certsOptions = [
  {
    name: 'Hostnames',
    selector: 'hosts',
    cell: (item: any) =>
      item.hosts.length <= 3 ? (
        <AssetList assets={item.hosts} forcedTyped={AssetType.Hostname} />
      ) : (
        item.hosts.length
      ),
  },
  {
    name: 'Valid From',
    selector: 'notBefore',
    cell: (item: any) => humanizeDateTime(new Date(item.notBefore)),
  },
  {
    name: 'Valid Until',
    selector: 'notAfter',
    cell: (item: any) => humanizeDateTime(new Date(item.notAfter)),
  },
  {
    name: 'Issuer',
    selector: 'issuerOrg',
    active: false,
    cell: (item: any) => (
      <CellBasic
        title={item.issuerOrg}
        subtitle={item.issuerCountry && getCountryName(item.issuerCountry)}
      />
    ),
  },
  {
    name: 'Subject',
    selector: 'subjectOrg',
    active: false,
    cell: (item: any) => (
      <CellBasic
        title={item.subjectOrg}
        subtitle={item.subjectCountry && getCountryName(item.subjectCountry)}
      />
    ),
  },
];

export const dnsHostsOptions = [
  {
    Header: 'Hostname',
    filterType: 'string',
    accessor: 'host',
    Cell: (item: any) => <AssetLink value={item.value} forcedType={AssetType.Hostname} />,
    partialMatchFilter: true,
    active: true,
    minWidth: 150,
  },
  {
    Header: 'Hostname Types',
    filterType: 'string',
    accessor: 'hostTypes',
    Cell: (item: any) =>
      item.value?.length > 0 && (
        <ul className="uk-list uk-list-collapse uk-margin-remove">
          {item.value.map((hostType: string) => (
            <li key={hostType}>{hostType}</li>
          ))}
        </ul>
      ),
    partialMatchFilter: true,
    active: true,
    minWidth: 150,
  },
  {
    Header: 'Organization',
    filterType: 'string',
    accessor: 'orgs',
    active: true,
    minWidth: 150,
  },
  {
    Header: 'Countries',
    filterType: 'string',
    accessor: 'countries',
    active: true,
    minWidth: 180,
  },
  {
    Header: 'CNAME',
    accessor: 'cnames',
    Cell: (item: any) => (
      <SeeMore
        items={item.value?.map((cname: string) => (
          <AssetLink value={cname} forcedType={AssetType.Hostname} />
        ))}
        topCount={5}
      />
    ),
  },
];

export const hostnameSharedHostsOptions = [hostItem, hostTypeItem, cnamesItem];

export const asnIpV4UpstreamsOptions = [
  {
    ...asnItem,
    name: 'Upstream',
    filterType: 'string',
    subAccessor: 'orgName',
    formatLabel: formatProvidersLabel,
  },
  { ...scoreItem, filterType: 'histogram', aggAccessor: 'score' },
];

export const asnIpV4DownstreamsOptions = [
  {
    ...asnItem,
    name: 'Downstream',
    filterType: 'string',
    subAccessor: 'orgName',
    formatLabel: formatProvidersLabel,
  },
  { ...scoreItem, filterType: 'histogram', aggAccessor: 'score' },
];

export const asnV4PrefixOptions = [
  { ...basicPrefixItem, clauseType: 'prefix.raw', filterType: 'string' },
  {
    ...geoItem,
    filterType: 'string',
    clauseType: 'OriginatedPrefix:Country',
    formatLabel: getCountryName,
  },
  orgItem,
  reputationScoreItem,
  badIPCountItem,
  servicesItem,
  servicesTagsItem,
];

export const asnV6PrefixOptions = [
  { ...basicPrefixItem, clauseType: 'prefix.raw', filterType: 'string' },
  {
    ...geoItem,
    filterType: 'string',
    clauseType: 'OriginatedPrefix:Country',
    formatLabel: getCountryName,
  },
  orgItem,
  { ...servicesItem, active: false },
  { ...servicesTagsItem, active: false },
];

export const asnRouterOptions = [
  { ...basicRouterIdItem, filterType: 'string', clauseType: 'routerId' },
  { ...geoItem, filterType: 'string', clauseType: 'Router:Country', formatLabel: getCountryName },
  { ...routerDeviceItem, filterType: 'string', clauseType: 'Router:Vendor', showCount: true },
  interfaceItem,
  queriedInterfaceItem,
  asnItem,
];

export const countryRouterOptions = [
  { ...basicRouterIdItem, filterType: 'string', clauseType: 'routerId' },
  asnItem,
  { ...routerDeviceItem, filterType: 'string', clauseType: 'Router:Vendor', showCount: true },
  interfaceItem,
  queriedInterfaceItem,
  geoItem,
];

export const orgRouterOptions = [
  { ...routerIdItem, filterType: 'string' },
  {
    ...asnItem,
    filterType: 'string',
    subAccessor: 'asn.orgName',
    showCount: true,
    formatLabel: formatProvidersLabel,
  },
  { ...routerDeviceItem, filterType: 'string', showCount: true },
  interfaceItem,
  queriedInterfaceItem,
  { ...countryItem, filterType: 'string', showCount: true },
];

export const routerOptions = [
  { ...basicRouterIdItem, filterType: 'string', clauseType: 'routerId' },
  { ...routerDeviceItem, filterType: 'string', clauseType: 'Router:Vendor' },
  interfaceItem,
  queriedInterfaceItem,
  geoItem,
  asnItem,
];

export const providersOptions = [
  {
    ...asnItem,
    name: 'Provider',
    filterType: 'string',
    subAccessor: 'asn.orgName',
    formatLabel: formatProvidersLabel,
  },
  { ...scoreItem, filterType: 'histogram', aggAccessor: 'score' },
  {
    name: 'State Investment',
    selector: 'asn.attributesStateownedases.stateownedasesCountryOfOwnership',
  },
];

export const exchangeOptions = [ixItem, ixNetworksItem];

export const facilityExchangeOptions = [
  { ...ixItem, filterType: 'string' },
  { ...ixNetworksItem, filterType: 'histogram', aggAccessor: 'peerCount' },
];

export const facilityOptions = [facilityItem, facilityNetworksItem];

export const routerInterfacesOptions = [
  {
    name: 'IP',
    selector: 'ip',
    cell: (item: any) => (item.ip ? <CellSingleLinked value={item.ip} /> : null),
    filterType: 'string',
  },
  {
    ...routingOriginAsnItem,
    filterType: 'string',
    subAccessor: (item: any) =>
      item.routingOrigin ? 'routingOrigin.origins[0].orgName' : 'origins[0].orgName',
    formatLabel: formatProvidersLabel,
  },
  {
    name: 'rDNS',
    selector: 'rdns',
    filterType: 'string',
    partialMatchFilter: true,
  },
  {
    name: 'Queried',
    selector: 'responsive',
    cell: (item: any) =>
      item.responsive ? (
        <span className="app-icon-primary">
          <CheckIcon />
        </span>
      ) : null,
  },
  {
    name: 'Exchange',
    selector: 'exchange.id',
    cell: (item: any) =>
      item.exchange ? (
        <CellSingleLinked linkValue={item.exchange.id} value={item.exchange.name} />
      ) : null,
  },
];

export const peersOptions = [
  {
    ...asnItem,
    name: 'Peer',
    filterType: 'string',
    subAccessor: 'asn.orgName',
    formatLabel: formatProvidersLabel,
  },
  {
    name: 'IPv4',
    selector: 'ipv4',
    minWidth: '160px',
    filterType: 'string',
    cell: (item: any) => <CellIP ip={item.ipaddr4} />,
  },
  {
    name: 'IPv6',
    selector: 'ipv6',
    minWidth: '210px',
    filterType: 'string',
    cell: (item: any) => <CellIP ip={item.ipaddr6} />,
  },
  {
    name: 'Speed',
    selector: 'speed',
    minWidth: '104px',
    cell: (item: any) =>
      item.speed && (item.speed <= 100 ? `${item.speed}M` : `${item.speed / 1000}G`),
  },
  {
    name: 'Policy',
    selector: 'peeringPolicy',
    minWidth: '160px',
    filterType: 'string',
  },
];

export const facilityPeersOptions = [
  {
    ...asnItem,
    name: 'Peer',
    filterType: 'string',
    subAccessor: 'orgName',
    formatLabel: formatProvidersLabel,
  },
];

export const exchangeFacilitiesOptions = [
  {
    ...facilityItem,
    filterType: 'string',
    subAccessor: 'geo.country',
    formatLabel: formatFacilityLabel,
  },
  { ...facilityNetworksItem, filterType: 'histogram', aggAccessor: 'peerAsnCount' },
];

export const ipDomainHostsOptions = [domainItem, hostItem, hostTypeItem, cnamesItem];

export const ipTracerouteEdgesOptions = [
  targetPrefixItem,
  srcItem,
  destItem,
  edgeSummaryItem,
  targetAsnItem,
  srcAsn,
  destAsn,
];

export const orgASNOptions = [
  { ...asnItem, filterType: 'string', subAccessor: 'orgName', formatLabel: formatProvidersLabel },
  {
    name: 'v4 Prefixes',
    selector: 'v4Count',
    aggAccessor: 'v4Count',
  },
  {
    name: 'v6 Prefixes',
    selector: 'v6Count',
    aggAccessor: 'v6Count',
  },
];

export const orgPrefixOptions = [
  { ...basicPrefixItem, filterType: 'string' },
  { ...geoItem, filterType: 'string', showCount: true, formatLabel: formatGeoLabel },
  {
    ...routingOriginAsnItem,
    filterType: 'string',
    selector: 'routingOrigin.origins[0].asn',
    subAccessor: 'routingOrigin.origins[0].orgName',
    formatLabel: formatProvidersLabel,
    showCount: true,
  },
  {
    ...reputationScoreItem,
    selector: 'suspiciousness',
    filterType: 'string',
    showCount: true,
    cell: (item: any) => (
      <div className={`app-attribute-pill ${getReputationClass(item?.suspiciousness)}`}>
        {item?.suspiciousness}
      </div>
    ),
  },
  badIPCountItem,
  {
    ...servicesItem,
    filterType: 'string',
    subAccessor: 'attributesServices.services[0].region',
  },
  servicesTagsItem,
];

export const prefixReputationOption = [
  { ...ipItem, filterType: 'string', name: 'Suspicious IP' },
  {
    ...maltrailItem,
    filterType: 'string',
    selector: 'behavior',
    subAccessor: 'malware',
    minWidth: '260px',
  },
  { ...maltrailSourcesItem, selector: 'sources' },
  blacklistItem,
];

function getIPOrRouterAsset(item: any, i: number) {
  const asset = { ...(item[`ip${i}`] || item[`router${i}`]) };
  const country = item[`country${i}`];
  const asn = item[`asn${i}`];

  if (!asset.geo) {
    asset.geo = { country };
  } else {
    asset.geo = { country, ...asset.geo };
  }

  if (asset.ip) {
    if (!asset.routingOrigin) {
      asset.routingOrigin = { origins: asn };
    }
  } else {
    asset.asn = asn?.length > 0 ? asn[0] : null;
  }
  return asset;
}

function getTpfxAsset(item: any) {
  if (item?.tpfx == null) return;
  const retVal: TargetPrefix = cloneDeep(item.tpfx);
  if (retVal.geo != null) {
    retVal.geo.country = item.tpfxCountry;
  } else {
    retVal.geo = { country: item.tpfxCountry };
  }
  return retVal;
}
