import { getReputationColor } from 'utils';

export function reactSelectStyle() {
  return {
    ...defaultReactSelectStyles(),
  };
}

export function reactSelectSearchStyle(docStyle?: any) {
  //Re-define control need because cursor changed to 'text' on hover
  return {
    ...defaultReactSelectStyles(),
    dropdownIndicator: (base: Record<string, any>) => ({ ...base, display: 'none' }),
    control: (base: Record<string, any>, { isDisabled }) => ({
      ...base,
      ...defaultReactSelectStyles().control(base, { isDisabled }),
    }),
  };
}

export function reactSelectSearchErrorStyle(docStyle?: any) {
  return {
    ...reactSelectSearchStyle(),
    control: (base: Record<string, any>) => ({
      ...base,
      ...searchControlStyles,
      border: '1px solid #762F00',
    }),
  };
}

export function reactSelectColumnFilterStyle(docStyle?: any) {
  return {
    ...reactSelectSearchStyle(),
    input: (base: Record<string, any>) => ({
      ...base,
      color: primaryColor,
    }),
    singleValue: (base: Record<string, any>) => ({
      ...base,
      color: primaryColor,
    }),
  };
}

export function reactSelectColumnOptionSelectStyle() {
  return {
    ...defaultReactSelectStyles(),
    input: (base: Record<string, any>) => ({
      ...base,
      color: primaryColor,
    }),
    singleValue: (base: Record<string, any>) => ({
      ...base,
      color: primaryColor,
    }),
  };
}

export function reactSelectDropdownStyle() {
  return {
    ...defaultReactSelectStyles(),
  };
}

export function reactSelectDropdownStyleBorderless(
  keepTop: boolean = false,
  keepRight: boolean = false,
  keepBottom: boolean = false,
  keepLeft: boolean = false,
  topLeftRadius?: number,
  topRightRadius?: number,
  bottomRightRadius?: number,
  bottomLeftRadius?: number,
) {
  if (keepTop && keepRight && keepBottom && keepLeft) {
    return {
      ...reactSelectDropdownStyle(),
    };
  } else {
    let borderControl = {};
    if (!keepTop) {
      borderControl['borderTop'] = 'none';
    }
    if (!keepRight) {
      borderControl['borderRight'] = 'none';
    }
    if (!keepBottom) {
      borderControl['borderBottom'] = 'none';
    }
    if (!keepLeft) {
      borderControl['borderLeft'] = 'none';
    }
    borderControl['borderTopLeftRadius'] = topLeftRadius ? `${topLeftRadius}px` : '0';
    borderControl['borderTopRightRadius'] = topRightRadius ? `${topRightRadius}px` : '0';
    borderControl['borderBottomRightRadius'] = bottomRightRadius ? `${bottomRightRadius}px` : '0';
    borderControl['borderBottomLeftRadius'] = bottomLeftRadius ? `${bottomLeftRadius}px` : '0';

    return {
      ...defaultReactSelectStyles(),
      control: (base: Record<string, any>, { isDisabled }) => ({
        ...reactSelectDropdownStyle().control(base, { isDisabled }),
        ...borderControl,
        '&:focus-within': { borderColor: borderColor },
      }),
      container: (base: Record<string, any>) => ({
        ...reactSelectDropdownStyle().container(base),
        ...borderControl,
      }),
    };
  }
}

export function reactSelectNoWrapStyle() {
  return {
    ...defaultReactSelectStyles(),
    singleValue: (base: Record<string, any>) => ({
      ...base,
      whiteSpace: 'noWrap',
      overflow: 'auto',
      padding: '6px 2px',
    }),
  };
}

export function reactSelectKeystoneAssetStyle() {
  return {
    ...reactSelectDropdownStyle(),
    control: (base: Record<string, any>, { isDisabled }) => ({
      ...reactSelectDropdownStyle().control(base, { isDisabled }),
      '&:focus-within': { border: `1px solid ${primaryColor}` },
      borderBottomRightRadius: '0',
      borderTopRightRadius: '0',
      borderRight: 'none',
    }),
  };
}

export function reactSelectKeystoneInputStyle() {
  return {
    ...reactSelectDropdownStyle(),
    control: (base: Record<string, any>, { isDisabled }) => ({
      ...reactSelectDropdownStyle().control(base, { isDisabled }),
      borderBottomLeftRadius: '0',
      borderTopLeftRadius: '0',
    }),
    singleValue: (base: Record<string, any>) => ({
      ...reactSelectDropdownStyle().singleValue(base),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
  };
}

export function reactSelectKeystoneSearchStyle() {
  return {
    ...reactSelectSearchStyle(),
    control: (base: Record<string, any>, { isDisabled }) => ({
      ...reactSelectSearchStyle().control(base, { isDisabled }),
      borderBottomLeftRadius: '0',
      borderTopLeftRadius: '0',
    }),
    singleValue: (base: Record<string, any>) => ({
      ...reactSelectSearchStyle().singleValue(base),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
  };
}

// Note that in main.scss, we have a selector for [class$="-indicatorContainer"] that is adjusting the padding on a class controlled by react-select that isn't configurable here, as react-select does not let us update the styling for that component, only indicatorsContainer (plural).
export function reactTableHeaderSelectDropdownStyle() {
  const tableHeader = docStyle.getPropertyValue('--app-color-table-header');
  return {
    ...defaultReactSelectStyles(),
    control: (base: Record<string, any>) => ({
      ...base,
      ...searchControlStyles,
      border: 'none',
      '&:hover': { cursor: 'pointer' },
      paddingRight: 0,
      backgroundColor: '#FFFFFF',
    }),
    container: (base: Record<string, any>) => ({
      ...base,
      display: 'flex',
      justifyContent: 'flex-end',
    }),
    singleValue: (base: Record<string, any>) => ({
      ...base,
      color: `${tableHeader}`,
    }),
    menu: (base: Record<string, any>) => ({
      ...base,
      width: 'auto',
      marginTop: '0',
    }),
    valueContainer: (base: Record<string, any>) => ({
      ...base,
      minWidth: '60px',
      padding: '0',
    }),
  };
}

export function reactDisabledTableHeaderSelectDropdownStyle() {
  return {
    ...reactTableHeaderSelectDropdownStyle(),
    indicatorsContainer: (base: Record<string, any>) => ({
      display: 'none',
    }),
  };
}

// Inherits reactSelectSearchStyle and defaultReactSelectStyles
export function platformSearchStyle(docStyle?: any) {
  docStyle = docStyle || getComputedStyle(document.documentElement);

  return {
    ...reactSelectSearchStyle(),
    control: (base: Record<string, any>) => ({
      ...base,
      ...searchControlStyles,
      height: '40px',
      minHeight: '40px',
    }),
    indicatorsContainer: (base: Record<string, any>) => ({
      ...base,
      paddingRight: '80px',
    }),
    indicatorContainer: (base: Record<string, any>) => ({
      ...base,
      color: borderColor,
      '&:hover': { color: borderColor, cursor: 'pointer' },
      paddingRight: '80px',
    }),
    valueContainer: (base: Record<string, any>) => ({
      ...base,
      padding: '0px 0px 0px 4px',
    }),
    option: (base: Record<string, any>) => ({
      ...base,
      ...reactSelectSearchStyle().option(base),
      wordBreak: 'break-word',
    }),
  };
}

// Inherits platformSearchStyle, reactSelectSearchStyle, and defaultReactSelectStyles
export function platformSearchHeaderStyle() {
  return {
    ...platformSearchStyle(),
    control: (base: Record<string, any>) => ({
      ...base,
      ...searchControlStyles,
    }),
    indicatorsContainer: (base: Record<string, any>) => ({
      ...base,
      paddingRight: '0px',
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  };
}

export function tagsDropdownStyle() {
  const docStyle = getComputedStyle(document.documentElement);
  const primaryColor = docStyle.getPropertyValue('--app-primary-color');
  const innerColor = docStyle.getPropertyValue('--app-color-input-inner');
  const secondaryColor = docStyle.getPropertyValue('--app-secondary-color');
  const iconHoverColor = docStyle.getPropertyValue('--app-color-input-icon-hover');

  return {
    ...reactSelectDropdownStyle(),
    singleValue: (base: Record<string, any>, { isDisabled }: any) => ({
      ...base,
      borderRadius: '12px',
      backgroundColor: isDisabled ? innerColor : secondaryColor,
      color: isDisabled ? 'white' : primaryColor,
      padding: `0px 8px`,
      width: 'fit-content',
    }),
    multiValueLabel: (base: Record<string, any>, { isDisabled }: any) => {
      return {
        ...base,
        color: isDisabled ? 'white' : primaryColor,
        textTransform: 'lowercase',
      };
    },
    clearIndicator: (base: Record<string, any>) => ({
      width: '20px',
      height: '20px',
      color: innerColor,
      '&:hover': { color: iconHoverColor },
      transition: 'color 150ms ease 0s',
    }),
  };
}

export function tagsSearchStyle() {
  const docStyle = getComputedStyle(document.documentElement);
  const primaryColor = docStyle.getPropertyValue('--app-primary-color');
  const innerColor = docStyle.getPropertyValue('--app-color-input-inner');
  const secondaryColor = docStyle.getPropertyValue('--app-secondary-color');

  return {
    ...reactSelectSearchStyle(),
    singleValue: (base: Record<string, any>, { isDisabled }: any) => ({
      ...base,
      borderRadius: '12px',
      backgroundColor: isDisabled ? innerColor : secondaryColor,
      color: isDisabled ? 'white' : primaryColor,
      fontSize: '12px',
      alignItems: 'center',
      height: '20px',
      display: 'flex',
      padding: `0px 8px`,
      width: 'fit-content',
    }),
    multiValueLabel: (base: Record<string, any>, { isDisabled }: any) => {
      return {
        ...base,
        color: isDisabled ? 'white' : primaryColor,
        textTransform: 'lowercase',
      };
    },
    control: (base: Record<string, any>, { isDisabled }) => ({
      ...reactSelectSearchStyle().control(base, { isDisabled }),
      borderBottomLeftRadius: '0',
      borderTopLeftRadius: '0',
    }),
  };
}

function defaultReactSelectStyles(docStyle?: any) {
  docStyle = docStyle || getComputedStyle(document.documentElement);

  const borderColor = docStyle.getPropertyValue('--app-color-input-border');
  const innerColor = docStyle.getPropertyValue('--app-color-input-inner');
  const iconHoverColor = docStyle.getPropertyValue('--app-color-input-icon-hover');
  const primaryColor = docStyle.getPropertyValue('--app-primary-color');
  const secondaryColor = docStyle.getPropertyValue('--app-secondary-color');
  const controlPaddingWidth = docStyle.getPropertyValue('--app-control-padding-width');

  return {
    clearIndicator: (base: Record<string, any>) => ({
      ...base,
      color: innerColor,
      '&:hover': { color: iconHoverColor, cursor: 'pointer' },
      padding: '2px 0px 2px 2px',
      height: '20px',
      width: '20px',
    }),
    dropdownIndicator: (base: Record<string, any>, state) => ({
      ...base,
      color: innerColor,
      '&:hover': { color: state.selectProps.isDisabled ? innerColor : iconHoverColor },
      padding: '4px 2px',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    }),
    group: (base: Record<string, any>) => ({
      ...base,
      padding: '0px',
    }),
    groupHeading: (base: Record<string, any>) => ({
      ...base,
      padding: `8px ${controlPaddingWidth} 0px ${controlPaddingWidth}`,
      marginBottom: '0px',
    }),
    input: (base: Record<string, any>) => ({
      ...base,
      alignSelf: 'flex-start',
      padding: '4px 0',
    }),
    option: (base: Record<string, any>) => ({
      ...base,
      color: '#333333',
      backgroundColor: 'none',
      padding: `8px ${controlPaddingWidth}`,
      '&:hover': { backgroundColor: secondaryColor, color: primaryColor },
    }),
    control: (base: Record<string, any>, { isDisabled }) => {
      return {
        ...base,
        ...searchControlStyles,
        '&:hover': { borderColor: borderColor, cursor: isDisabled ? 'not-allowed' : 'pointer' },
      };
    },
    valueContainer: (base: Record<string, any>) => ({
      ...base,
      padding: '0px',
      overflow: 'auto',
      maxHeight: '95px', // around 3.5x the height of a multi value item with padding.
      // This obviates the ability to scroll down when there are many multi-values
    }),
    container: (base: Record<string, any>) => ({
      ...base,
      width: '100%',
      pointerEvents: 'auto',
    }),
    singleValue: (base: Record<string, any>) => ({
      ...base,
      whiteSpace: 'unset',
      overflow: 'auto',
      padding: '6px 2px',
      overflowWrap: 'anywhere',
    }),
    indicatorSeparator: (base: Record<string, any>) => ({ ...base, display: 'none' }),

    multiValue: (base: Record<string, any>, { isDisabled }: any) => ({
      ...base,
      borderRadius: '12px',
      backgroundColor: isDisabled ? innerColor : secondaryColor,
      lineHeight: '18px',
    }),
    multiValueLabel: (base: Record<string, any>, { isDisabled }: any) => {
      return {
        ...base,
        color: isDisabled ? 'white' : primaryColor,
      };
    },
    multiValueRemove: (base: Record<string, any>, { isDisabled }: any) => ({
      ...base,
      borderRadius: '12px',
      backgroundColor: isDisabled ? innerColor : secondaryColor,
      height: '24px',
      width: '24px',
      svg: {
        height: '12px',
        width: '12px',
        minHeight: '12px',
        minWidth: '12px',
      },
      path: {
        fill: isDisabled ? 'black' : primaryColor,
        opacity: '35%',
      },
      alignSelf: 'center',
      '&:hover': {
        backgroundColor: isDisabled ? innerColor : secondaryColor,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      },
    }),
    placeholder: (base: Record<string, any>) => ({
      ...base,
      color: innerColor,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    noOptionsMessage: (base: Record<string, any>) => ({ ...base, color: innerColor }),
    menu: (base: Record<string, any>) => ({
      ...base,
      margin: '0px 0px 8px 0px',
      boxShadow: '0px 4px 4px rgba(51,51,51,0.4), 0px 14px 24px rgba(51,51,51,0.12)',
      borderRadius: '0px',
    }),
    menuList: (base: Record<string, any>) => ({
      ...base,
      lineHeight: '16px',
      padding: '8px 0px',
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 970 }),
  };
}

export function suspiciousnessDropdownStyle(docStyle?: any) {
  docStyle = docStyle || getComputedStyle(document.documentElement);
  const innerColor = docStyle.getPropertyValue('--app-color-input-inner');

  return {
    ...reactSelectDropdownStyle(),
    multiValue: (base: Record<string, any>, { data, isDisabled }: any) => ({
      ...base,
      borderRadius: '12px',
      backgroundColor: isDisabled ? innerColor : getReputationColor(data.value).backgroundColor,
    }),
    multiValueLabel: (base: Record<string, any>, { data, isDisabled }: any) => {
      return {
        ...base,
        color: isDisabled ? 'white' : getReputationColor(data.value).color,
        textTransform: 'lowercase',
      };
    },
    multiValueRemove: (base: Record<string, any>, { isDisabled, data }: any) => {
      const { backgroundColor, color } = getReputationColor(data.value);
      return {
        ...base,
        borderRadius: '12px',
        backgroundColor: isDisabled ? innerColor : backgroundColor,
        svg: {
          fill: isDisabled ? 'black' : color,
        },
        '&:hover': {
          backgroundColor: isDisabled ? innerColor : backgroundColor,
          cursor: 'pointer',
        },
      };
    },
  };
}

// Shared style for Platform Search control
const docStyle = getComputedStyle(document.documentElement);
const borderColor = docStyle.getPropertyValue('--app-color-input-border');
const primaryColor = docStyle.getPropertyValue('--app-primary-color');
const searchControlStyles = {
  '&:hover': { borderColor: borderColor, cursor: 'text' },
  '&:focus-within': { borderColor: primaryColor },
  border: `1px solid ${borderColor}`,
  boxShadow: 'none',
  padding: `0px 8px`,
  minHeight: '32px',
  lineHeight: '1.2rem',
  borderRadius: '2px',
};
