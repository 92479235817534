import { DocumentNode, QueryHookOptions, useQuery } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { addMetadataToPluginContext, addQueryToPluginContext } from 'utils';
import { ComponentPluginContext, DataSlicesContext } from 'appContexts';

export function useComponentPluginQuery(
  query: DocumentNode,
  options?: QueryHookOptions,
  avoidPluginContextAppend?: boolean,
) {
  const context = useContext(ComponentPluginContext);
  const { metadata } = useContext(DataSlicesContext);

  useEffect(() => {
    !avoidPluginContextAppend && addQueryToPluginContext(context, query, options?.variables);
  }, [context, query, options, avoidPluginContextAppend]);

  const result = useQuery(query, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  useEffect(() => {
    addMetadataToPluginContext(context, result, metadata, query);
  }, [context, metadata, query, result]);

  return result;
}
