import React, { Fragment, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
//import { createRoot, Root } from 'react-dom/client';
import UIkit from 'uikit';

export type ContextMenuItem = {
  label: string;
  onClick: (context: Record<string, any>) => void;
};

type ContextMenuProps = {
  items: ContextMenuItem[];
  context?: Record<string, any>;
};

export function ContextMenu({ items, context }: ContextMenuProps) {
  const dropdownRef = useRef(null);

  useEffect(() => {
    UIkit.dropdown(dropdownRef.current).show();
  }, [dropdownRef]);

  function onClick(item: ContextMenuItem) {
    UIkit.dropdown(dropdownRef.current).hide(false);
    item.onClick(context || {});
  }

  return (
    <Fragment>
      <button hidden />
      <ul ref={dropdownRef} className="uk-nav uk-dropdown-nav">
        {items.map((item) => (
          <li key={item.label}>
            <a href="#" onClick={() => onClick(item)}>
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </Fragment>
  );
}

let menu: HTMLElement | undefined = undefined;
//let root: Root | undefined = undefined;

ContextMenu.show = (items: ContextMenuItem[], event: MouseEvent, context?: Record<string, any>) => {
  event.preventDefault();
  if (!menu) {
    menu = document.createElement('div');
    menu.style.position = 'absolute';
    document.body.appendChild(menu);
    //root = createRoot(menu);
  }
  ReactDOM.unmountComponentAtNode(menu);
  //root.unmount();
  menu.style.left = `${event.pageX}px`;
  menu.style.top = `${event.pageY}px`;
  const component = <ContextMenu items={items} context={context} />;
  ReactDOM.render(component as any, menu);
  //root.render(component as any);
};
