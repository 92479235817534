import React, { ErrorInfo as ReactErrorInfo } from 'react';
import { Modal } from 'components';
import { ErrorInfo } from 'components';

export class ErrorBoundary extends React.Component<{}, { error?: Error }> {
  constructor(props: any) {
    super(props);
    this.state = {};
    window.onunhandledrejection = (event: PromiseRejectionEvent) =>
      this.onUnhandledError(event.reason);
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, _: ReactErrorInfo) {
    Modal.hide();
    window.setTimeout(
      () =>
        Modal.show({
          title: 'Unexpected Error',
          body: <ErrorMessage error={error} />,
          onClose: () => {
            this.setState({ error: undefined });
            window.location.href = `${window.location.origin}/${process.env.PUBLIC_URL}`;
          },
        }),
      500,
    );
  }

  render() {
    return this.state.error ? null : (this.props as any).children;
  }

  onUnhandledError(error: any) {
    this.setState(ErrorBoundary.getDerivedStateFromError(error));
    this.componentDidCatch(error, {} as ReactErrorInfo);
  }
}

function ErrorMessage({ error }: { error: Error }) {
  if (!error) {
    return null;
  }

  return (
    <div>
      <p>{error.message}</p>
      <ErrorInfo error={error} />
    </div>
  );
}
