import { gql } from '@apollo/client';
import { Images, UserInvestigation } from 'model';
import {
  saveInvestigation,
  isViewOnly,
  asyncSleep,
  getCurrentInvestigation,
  makeUniqueId,
  preventNavigationInterrupt,
} from 'utils';
import { getGraphQLClient } from 'gql';
import {
  acknowledgeNotification,
  ALERT_TYPES,
  createGenericNotification,
  Image as MosaicImage,
} from 'model';

export const MAX_IMAGE_SIZE = 1024 * 1024 * 2; // 2MB

export const getImageData = gql`
query($imageIds: [String], $investigationId: String, $sharedKey: String) {
  imageData(imageIds:$imageIds, investigationId:$investigationId, sharedKey:$sharedKey) {
    dataObjects {
      data
      id
    }
  }
}
`;

export const getImageIds = gql`
query($imageIds: [String], $investigationId: String, $sharedKey: String) {
  imageData(imageIds:$imageIds, investigationId:$investigationId, sharedKey:$sharedKey) {
    dataObjects {
      id
    }
  }
}
`;

export const saveImageData = gql`
mutation($investigationId: String!, $dataType: String!, $imageId: String!, $data: String, $sharedKey: String) {
  uploadImageData(input: {investigationId: $investigationId, dataType: $dataType, imageId: $imageId, data: $data, sharedKey: $sharedKey}) {
    investigationId
  }
}
`;

export const deleteImageData = gql`
mutation($investigationId: String!, $filter: String) {
  removeImagesFromInvestigation(input: {investigationId: $investigationId, filter: $filter}) {
    deletedCount
    investigationId
  }
}
`;

const UNMATCHED_IMAGE_DATA_ERROR = 'the provided data does not match';

export function onSelectImage(
  file: File,
  investigation: UserInvestigation,
  newId: string,
  newName: string,
  imageType = 'image/jpeg',
) {
  let reader = new FileReader();
  reader.onloadend = function () {
    const img = new Image();
    img.onload = () => {
      const preventImageUploadInterrupt = (e: BeforeUnloadEvent) => preventNavigationInterrupt(e);
      window.addEventListener('beforeunload', preventImageUploadInterrupt);
      // save the image to the backend
      getGraphQLClient()
        .mutate({
          mutation: saveImageData,
          variables: {
            investigationId: investigation.id,
            data: img.src,
            dataType: 'image',
            imageId: newId,
          },
        })
        .then((result) => {
          // save the image to the investigation
          saveInvestigation({
            ...investigation,
            images: [
              ...(investigation.images ?? []),
              { id: newId, name: newName, type: imageType },
            ],
          });
        })
        .catch((e) => {
          console.error('There was an error saving images.', e);
        })
        .finally(() => {
          window.removeEventListener('beforeunload', preventImageUploadInterrupt);
        });
      img.onerror = () => {
        console.error('Error uploading image');
      };
    };
    img.src = reader.result as string;
  };
  reader.readAsDataURL(file);
}

// Function to update image id and replace it in markdown
function updateImageId(investigation: UserInvestigation, imageIndex: number, image: MosaicImage) {
  const newId = makeUniqueId('image');
  const oldId = image.id;
  investigation.images[imageIndex] = { ...image, id: newId };
  if (investigation.description) {
    investigation.description = investigation.description?.replace(oldId, newId);
  }
  if (investigation.sections) {
    for (let j = 0; j < investigation.sections.length; j++) {
      const section = investigation.sections[j];
      if (section.description) {
        section.description = section.description.replace(oldId, newId);
      }
    }
  }
}

// This function is fired when an investigation is first loaded in. It has two jobs
// First of all, any images inside the investigation object which are no longer
// referenced in its markdown must be removed from the investigation and deleted from
// the backend.
// Second, if any of the images still have a "data" field with image data in it,
// use this opportunity to migrate all of that data to the image index.
export async function handleImagesOnFirstLoad(
  investigation: UserInvestigation,
  setLoading: Function,
  beforeUnload: EventListenerOrEventListenerObject,
) {
  if (!investigation || isViewOnly()) return;
  window.addEventListener('beforeunload', beforeUnload);
  const sections = investigation.sections;
  const images = investigation.images ?? [];
  const client = getGraphQLClient();
  const imageResult = await client.query({
    query: getImageIds,
    variables: { investigationId: investigation.id },
  });
  if (imageResult.error) {
    setLoading(false);
    console.error(`There was an issue with getting lightweight image ids for this investigation. 
    The server returned the following error: ${imageResult.error}`);
  }
  const backendImageIds =
    imageResult?.data?.imageData?.dataObjects?.map((object: any) => object.id) ?? [];
  // filter the new images by what appears in markdown descriptions
  const newImages = images.filter((image) => {
    const id = image.id;
    const name = image.name;
    if (investigation.description.includes(`${id}/${name}`)) {
      return true;
    }
    let foundInSections = false;
    sections.forEach((section) => {
      if (foundInSections) return;
      const description = section.description;
      if (description.includes(`${id}/${name}`)) {
        foundInSections = true;
        return;
      }
    });
    return foundInSections;
  });

  let newInvestigation: UserInvestigation;
  const updateNewInvestigation = (updatedInvestigation: UserInvestigation) => {
    newInvestigation = updatedInvestigation;
  };
  const getNewInvestigation = () => {
    return newInvestigation;
  };
  // Compare the frontend image ids to the backend image ids
  const needUpdateBackend =
    backendImageIds.some((id: string) => !newImages.find((image) => image.id === id)) ||
    newImages.some(
      (image) => image.data && !backendImageIds?.find((id: string) => image.id === id),
    );
  // Backend data should be overwritten by the frontend if there are any differences.
  if (needUpdateBackend) {
    let processedCount = 0; // Increment this count each time an image is processed
    const incrementProcessedCount = (num?: number) => {
      if (num != null) {
        processedCount += num;
      } else {
        processedCount++;
      }
    };
    for (let i = 0; i < newImages.length; i++) {
      const image = newImages[i];
      const oldId = image.id;
      if (image.data) {
        getGraphQLClient()
          .mutate({
            mutation: saveImageData,
            variables: {
              imageId: oldId,
              dataType: 'image',
              data: image.data,
              investigationId: investigation.id,
            },
          })
          .then((result) => {
            if (result.errors) {
              const error = result.errors[0].message;
              if (error.includes(UNMATCHED_IMAGE_DATA_ERROR)) {
                // This is a rare error, because image ids are universal, we should only
                // hit this if someone has manually hit the API to associate different image
                // data with your id
                // Replace the existing image id with a new one.
                let workingInvestigation: UserInvestigation = getNewInvestigation() ?? {
                  ...investigation,
                  images: newImages,
                };
                updateImageId(workingInvestigation, i, image);
                updateNewInvestigation(workingInvestigation);
              } else {
                createGenericNotification('image-migration-error', {
                  alertType: ALERT_TYPES.error,
                  message: 'Some image data was not successfully migrated.',
                  title: 'Unexpected data migration error',
                  onClear: () => acknowledgeNotification('image-migration-error'),
                });
              }
            } else {
              let workingInvestigation: UserInvestigation = getNewInvestigation() ?? {
                ...investigation,
                images: newImages,
              };
              workingInvestigation.images[i] = { ...image, data: undefined };
              updateNewInvestigation(workingInvestigation);
            }
          })
          .finally(() => incrementProcessedCount());
      } else {
        incrementProcessedCount();
      }
    }
    const toDeleteFromBackend = backendImageIds.filter(
      (id: string) => !newImages.find((image) => image.id === id),
    );
    if (toDeleteFromBackend.length) {
      incrementProcessedCount(backendImageIds.length - toDeleteFromBackend.length);
      getGraphQLClient()
        .mutate({
          mutation: deleteImageData,
          variables: {
            investigationId: investigation.id,
            filter: `[{'should': {'_id': [${toDeleteFromBackend.map((id: string) => `'${id}'`)}] }}]`,
          },
        })
        .then((result) => {
          if (result.errors) {
            console.error(
              'Warning! Some dangling unreferenced images might not have been deleted.',
            );
          }
          incrementProcessedCount(toDeleteFromBackend.length);
        });
    } else {
      incrementProcessedCount(backendImageIds.length);
    }
    while (processedCount < newImages.length + backendImageIds.length) {
      await asyncSleep(100); // Wait until all image data is processed
    }
  }
  if (images.length !== newImages.length || images.some((image) => image.data)) {
    if (newInvestigation) {
      // Don't update an investigation's saved time for an image migration.
      saveInvestigation(newInvestigation, true);
    } else {
      saveInvestigation(
        {
          ...investigation,
          images: newImages.map((image) => ({ ...image, data: undefined })),
        },
        true,
      );
    }
  }
  setLoading(false);
  window.removeEventListener('beforeunload', beforeUnload);
}

export async function handleCrossInstanceImages(crossInstanceParams: any) {
  // TODO: We need to validate this as a UserInvestigation type, any is unsafe
  if (crossInstanceParams?.images?.length) {
    const newCIParams = { ...crossInstanceParams };
    // loop through images.
    // Upload each to the image data index and delete the data from the investigation object
    let faultyImageIndices = [];
    for (let i = 0; i < crossInstanceParams.images.length; i++) {
      const image: MosaicImage = crossInstanceParams.images[i];
      if (image.data) {
        const imgFile = new File([image.data], image.name);
        if (imgFile.size > MAX_IMAGE_SIZE) {
          if (faultyImageIndices.length === 0) {
            createGenericNotification('invalid-image', {
              alertType: ALERT_TYPES.error,
              message: 'Some images found in this JSON could not be processed.',
              title: 'Invalid Image',
              onClear: () => acknowledgeNotification('invalid-image'),
            });
          }
          faultyImageIndices.push(i);
          continue;
        }
        const imgObject = new Image();
        const CIImagePromise = new Promise((resolve, reject) => {
          imgObject.onload = async () => {
            const mutationResult = await getGraphQLClient().mutate({
              mutation: saveImageData,
              variables: {
                investigationId: crossInstanceParams.id,
                sharedKey: crossInstanceParams.sharing?.shared_key,
                data: imgObject.src,
                dataType: 'image',
                imageId: image.id,
              },
            });
            if (mutationResult.errors) {
              const error = mutationResult.errors[0].message;
              if (error.includes(UNMATCHED_IMAGE_DATA_ERROR)) {
                // This is a rare error, because image ids are universal, we should only
                // hit this if someone has manually edited a JSON file.
                // Replace the existing image id with a new one.
                updateImageId(newCIParams, i, image);
              } else {
                createGenericNotification('image-mutation-error', {
                  alertType: ALERT_TYPES.error,
                  message: 'Some images found in this JSON could not be processed.',
                  title: 'Unexpected data upload error',
                  onClear: () => acknowledgeNotification('image-mutation-error'),
                });
                reject(imgObject);
              }
            }
            if (newCIParams.images[i].id === image.id) {
              newCIParams.images[i] = { ...image };
              delete newCIParams.images[i].data;
            }
            resolve(imgObject);
          };
          imgObject.onerror = () => {
            reject();
            console.error(
              'There was an error when uploading the following image to the server: ',
              image,
            );
          };
          imgObject.src = image.data;
        });
        await CIImagePromise;
      }
    }
    if (faultyImageIndices.length > 0) {
      newCIParams.images = newCIParams?.images?.filter((image: MosaicImage, i: number) => {
        return !faultyImageIndices.includes(i);
      });
    }
    return newCIParams;
  } else {
    return crossInstanceParams;
  }
}

// This function will prepare an array of images from the current investigation that we want to copy
// over to the new one.
export async function prepareImagesForCopy(
  copyTarget: UserInvestigation | { id: string; images: Images },
  combinedDescriptions?: string,
  sharedKey?: string,
) {
  // clone any images without data so that data doesn't get added to the original
  const currentImages = getCurrentInvestigation()?.images;
  const referencedImages = currentImages
    ? currentImages.filter((image) => {
        // Don't transfer any images that already exist in the mosaic we're copying to
        if (copyTarget?.images.find((img) => img.id === image.id)) return false;
        if (combinedDescriptions == null) return true;
        return combinedDescriptions?.includes(`${image.id}/${image.name}`);
      })
    : [];
  // update the backend image references linking the copy target to the new images
  let processedCount = 0;
  const incrementProcessedCount = () => processedCount++;
  for (let i = 0; i < referencedImages.length; i++) {
    const image = referencedImages[i];
    const variables = { investigationId: copyTarget.id, dataType: 'image', imageId: image.id };
    if (sharedKey) {
      variables['sharedKey'] = sharedKey;
    }
    getGraphQLClient()
      .mutate({
        mutation: saveImageData,
        variables,
      })
      .then((result) => {
        if (result.errors) {
          console.error('Unable to copy some image data to new investigation.');
        }
      })
      .finally(() => incrementProcessedCount());
  }
  while (processedCount < referencedImages.length) {
    await asyncSleep(100); // Wait for all the images to be processed
  }
  return referencedImages;
}
