import { DocumentNode } from '@apollo/client';
import { ComponentPluginContextValue } from 'appContexts';
import { FieldNode, OperationDefinitionNode, print } from 'graphql';
import { isEqual } from 'lodash';

const dynamicMetadataQueries = [
  'ASAboutData',
  'HostnameAboutData',
  'IPAboutData',
  'PrefixAboutData',
  'RouterAboutData',
  'IPRouterCheckData',
];

const noMetadataQueries = [
  'CountryAreaData', // Needed to calculate map zoom, but not relevant to tile data
];

export const NON_PLAYGROUND_QUERIES = [
  'CountryAreaData',
  'DomainRegistryDatesData',
  'DNSRecordsDatesData',
  'IPInfrastructureDatesData',
];

export function addQueryToPluginContext(
  pluginContext: ComponentPluginContextValue,
  query: DocumentNode,
  variables?: any,
) {
  const queryName = (query as any).definitions[0].name.value;
  variables = variables || {};
  // replace double quotes in filter string with single quotes
  const filter: string = variables?.filter;
  if (filter) {
    variables.filter = filter.replaceAll('"', "'");
  }
  // If the query already exists in the plugin context, and it's exactly the same,
  // don't update the plugin context. If we do we can cause some infinite render issues
  // since we're setting graphqlqueries to be a completely new object.
  if (
    pluginContext.graphQLQueries &&
    pluginContext.graphQLQueries[queryName] &&
    isEqual(pluginContext.graphQLQueries[queryName].variables, variables) &&
    isEqual(
      pluginContext.graphQLQueries[queryName].query
        ? print(pluginContext.graphQLQueries[queryName].query)
        : undefined,
      query ? print(query) : undefined,
    )
  ) {
    return;
  }
  pluginContext.setGraphQLQueries &&
    pluginContext.setGraphQLQueries((curr) => ({ ...curr, [queryName]: { query, variables } }));
}

export function addMetadataToPluginContext(
  pluginContext: ComponentPluginContextValue,
  result: any,
  metadata: any,
  query: DocumentNode,
) {
  const queryName = getQueryName(query);
  const shouldParseMetadata = !noMetadataQueries.includes(queryName);

  if (shouldParseMetadata) {
    const isStaticMetadataQuery = !dynamicMetadataQueries.includes(queryName);

    // Fetch the possible "keys" from the raw query or results
    // A key has two sections: parent and child. This indicates each unique key of the query/result with it's immediate parent key.
    // Key: facility.geo
    // Parent: facility
    // Child: geo
    // We need to check if the facility index has a subfield like geo so we don't mistakenly add the geo index as a metadata dependency
    let keys;
    if (isStaticMetadataQuery) {
      keys = extractKeysFromGraphQLQuery(query);
    } else if (!result.loading) {
      keys = extractKeys(result.data);
    }

    keys &&
      keys.forEach((key: string) => {
        const [parent, child] = key.split('.');

        const shouldAddChild =
          metadata.hasOwnProperty(child) && !metadata?.[parent]?.fields?.includes(child);
        const shouldAddParent = !child && metadata.hasOwnProperty(parent);

        if (shouldAddChild || shouldAddParent) {
          const { label, timeWindow, indexName, lastUpdated } = shouldAddChild
            ? metadata[child]
            : metadata[parent];
          const newValue = { timeWindow, indexName, lastUpdated };

          pluginContext?.setMetadata &&
            pluginContext.setMetadata((current) => {
              if (!isEqual(current?.[label], newValue) || !(label in current)) {
                return {
                  ...current,
                  [label]: newValue,
                };
              }
              // Return the previous state to avoid re-render
              return current;
            });
        }
      });
  }
}

// Extract keys from GQL results (dynamic tiles)
function extractKeys(input: any, parentKey?: string): string[] {
  let keys: string[] = [];

  if (Array.isArray(input)) {
    for (const item of input) {
      keys = keys.concat(extractKeys(item, parentKey));
    }
  } else if (typeof input === 'object' && input !== null) {
    for (let key in input) {
      const value = input[key];
      const isEmptyField =
        value === null || (Array.isArray(value) && value.length === 0) || value?.totalCount === 0;
      if (!isEmptyField) {
        key = key.replace('Paged', '');
        const prefixedKey = parentKey ? `${parentKey}.${key}` : key;
        keys.push(prefixedKey);
      }
      keys = keys.concat(extractKeys(input[key], key));
    }
  }

  return Array.from(new Set(keys)); // Return unique keys.
}

// Extract keys from raw query (static tiles)
export function extractKeysFromGraphQLQuery(query: DocumentNode): string[] {
  const keys: string[] = [];

  function traverseFields(nodes: readonly FieldNode[], currentPath: string[] = []): void {
    for (const node of nodes) {
      if (node.name && node.name.value) {
        // Remove intermediate "items"
        // Ex: routerAliasesPaged.items.country
        const newPath = [...currentPath, node.name.value].filter(
          (node: string) => node !== 'items',
        );
        keys.push(newPath.slice(-2).join('.').replace('Paged', ''));
        if (node.selectionSet) {
          traverseFields(node.selectionSet.selections as FieldNode[], newPath);
        }
      }
    }
  }

  if (query.definitions) {
    for (const definition of query.definitions) {
      if (definition.kind === 'OperationDefinition' && definition.selectionSet) {
        traverseFields(definition.selectionSet.selections as FieldNode[]);
      }
    }
  }

  return keys;
}

function getQueryName(documentNode: DocumentNode): string | null {
  const queryDefinition = documentNode?.definitions?.find(
    (definition): definition is OperationDefinitionNode =>
      definition.kind === 'OperationDefinition' && definition.operation === 'query',
  );

  return queryDefinition && queryDefinition.name ? queryDefinition.name.value : null;
}
