import { UserNotification, ALERT_TYPES } from 'model';
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as CheckIcon } from 'svg/actions/checkmark-fill.svg';
import { ReactComponent as ErrorIcon } from 'svg/actions/exclamation-fill.svg';
import { ReactComponent as DiamondIcon } from 'svg/mosaic/tile-outline.svg';
import { ReactComponent as DefaultIcon } from 'svg/actions/gear.svg';

export type BannerProps = UserNotification;

const iconMap: Record<ALERT_TYPES, JSX.Element> = {
  [ALERT_TYPES.primary]: (
    <DefaultIcon style={{ height: '20px', width: '20px' }} className="linear-spin" />
  ),
  [ALERT_TYPES.warning]: <ErrorIcon style={{ height: '20px', width: '20px', color: '#F18C17' }} />,
  [ALERT_TYPES.success]: <CheckIcon />,
  [ALERT_TYPES.error]: <ErrorIcon style={{ height: '20px', width: '20px', color: '#FD6F6F' }} />,
  [ALERT_TYPES.placeholder]: <DiamondIcon style={{ color: '#9F9D9D' }} />,
};

export function Banner({
  style,
  alertType,
  message,
  title,
  onClear,
  icon,
  contentClass,
  noClose,
}: BannerProps) {
  const [isVisible, setIsVisible] = useState(true);
  // Reset banner visibility when children change
  useEffect(() => {
    setIsVisible(true);
  }, [title, message]);

  const alertClass = useMemo(() => {
    if (alertType) {
      return `uk-alert-${alertType}`;
    }
    return '';
  }, [alertType]);

  return isVisible ? (
    <div style={style} className={`uk-alert uk-flex ${alertClass}`}>
      <div className={`uk-flex uk-margin-auto-right ${contentClass ?? ''}`}>
        {icon !== undefined ? (
          <div className="uk-text-center" style={{ width: '24px' }}>
            {icon}
          </div>
        ) : alertType ? (
          iconMap[alertType]
        ) : (
          iconMap[ALERT_TYPES.placeholder]
        )}
        <div className="uk-margin-medium-left">
          {title && (
            <div className="uk-text-bold" style={{ lineHeight: '24px' }}>
              {title}
            </div>
          )}
          <div
            style={{ whiteSpace: 'normal', overflowWrap: 'anywhere' }}
            className="app-banner-description"
          >
            {message}
          </div>
        </div>
      </div>
      {!noClose && (
        <button
          style={{ marginBottom: 'auto' }}
          onClick={() => {
            setIsVisible(false);
            onClear && onClear();
          }}
          data-uk-icon="icon: close"
        />
      )}
    </div>
  ) : null;
}
