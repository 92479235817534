import React, { Fragment } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { getGraphQLClient } from 'gql';
import { keycloak, keycloakInitOptions, KeycloakProvider } from './keycloak';
import { Main } from 'components';
import { Helmet } from 'react-helmet';

UIkit.use(Icons);

export default function App() {
  return (
    <Fragment>
      <Helmet>
        <link rel="icon" href={process.env.REACT_APP_LOGO} />
        <link rel="apple-touch-icon" href={process.env.REACT_APP_LOGO} />
        <title>{process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
      </Helmet>
      <KeycloakProvider authClient={keycloak} initOptions={keycloakInitOptions()}>
        <BrowserRouter>
          <ApolloProvider client={getGraphQLClient()}>
            <div className="app-background">
              <Main />
            </div>
          </ApolloProvider>
        </BrowserRouter>
      </KeycloakProvider>
    </Fragment>
  );
}
