import React from 'react';
import { ReactComponent as ImageIcon } from 'svg/actions/image.svg';

export function ImagePlaceholder() {
  return (
    <div className="image-placeholder-container">
      <div className="image-placeholder">
        <div className="uk-align-center placeholder-svg-wrapper">
          <ImageIcon />
        </div>
      </div>
    </div>
  );
}
