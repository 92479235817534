import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SidebarContext } from 'appContexts';
import { humanizeDateTime, isViewOnly } from 'utils';
import { DownloadArchivePrompt, DeleteArchivePrompt, AddArchivePrompt } from 'components';
import { ReactComponent as IndeterminateCheck } from 'svg/selection/checkbox-indeterminate.svg';
import { ReactComponent as Unchecked } from 'svg/selection/checkbox-unchecked.svg';
import { ReactComponent as Checked } from 'svg/selection/checkbox-checked.svg';
import { ReactComponent as Download } from 'svg/system/file-download.svg';
import { ReactComponent as Delete } from 'svg/actions/trash.svg';
import { ReactComponent as Add } from 'svg/actions/plus-outline.svg';
import { ReactComponent as Dot } from 'svg/experimental/dot.svg';

enum ArchiveToolbarTypes {
  Download = 'download',
  Delete = 'delete',
  Add = 'add',
}

enum CheckState {
  Checked = 'checked',
  Unchecked = 'unchecked',
  Indeterminate = 'indeterminate',
}

export function SidebarArchive({ maxHeightPx }) {
  const { archiveData } = useContext(SidebarContext);
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [selectedPrompt, setSelectedPrompt] = useState<ArchiveToolbarTypes | undefined>();
  const [promptHeight, setPromptHeight] = useState<number>(0);

  const promptRef = useRef(null);
  const viewOnly = isViewOnly();

  const checkAllState = useMemo(() => {
    if (selected.size === 0) return CheckState.Unchecked;
    if (selected.size === archiveData?.length) return CheckState.Checked;
    return CheckState.Indeterminate;
  }, [selected, archiveData]);

  const maxHeightWithPrompt = useMemo(() => {
    if (!selectedPrompt) return maxHeightPx;
    return maxHeightPx - promptHeight;
  }, [selectedPrompt, maxHeightPx, promptHeight]);

  const toggleAllArchives = useCallback(() => {
    if ([CheckState.Unchecked, CheckState.Indeterminate].includes(checkAllState)) {
      setSelected(new Set(archiveData?.map((archive) => archive.timestamp)));
    } else {
      setSelected(new Set());
    }
  }, [archiveData, setSelected, checkAllState]);

  const toggleArchive = useCallback(
    (ts: string) => {
      setSelected((current) => {
        let newSelected = new Set(current);
        if (current.has(ts)) {
          newSelected.delete(ts);
        } else {
          newSelected.add(ts);
        }
        return newSelected;
      });
    },
    [setSelected],
  );

  const updateSelectedPrompt = useCallback(
    (newPrompt: ArchiveToolbarTypes) => {
      if (newPrompt === selectedPrompt) {
        setPromptHeight(0);
        setTimeout(() => {
          setSelectedPrompt(undefined);
        }, 300); // to match the transition speed defined in the scss file
      } else {
        setSelectedPrompt(newPrompt);
      }
    },
    [selectedPrompt],
  );

  useEffect(() => {
    setPromptHeight(selectedPrompt ? promptRef.current?.clientHeight : 0);
  }, [selectedPrompt, promptRef.current?.clientHeight, selected]);

  return (
    <div>
      <div className="tiles-list-header uk-margin-small-bottom">
        Versions
        <button className="uk-margin-auto-left" onClick={toggleAllArchives}>
          {checkAllState === CheckState.Indeterminate ? (
            <IndeterminateCheck />
          ) : checkAllState === CheckState.Checked ? (
            <Checked />
          ) : (
            <Unchecked />
          )}
        </button>
      </div>
      <div
        className="archive-wrapper edit-tiles-list sidebar-menu-content"
        style={{ maxHeight: `${maxHeightWithPrompt}px`, marginTop: '0' }}
      >
        <ul className="uk-grid uk-margin-remove">
          {archiveData?.map((archive) => {
            const ts = archive.timestamp;
            const timeString = ts ? humanizeDateTime(new Date(ts)) : 'Unknown';
            return (
              <li
                className="uk-padding-remove selectable-list-item"
                key={ts}
                data-id={ts}
                onClick={() => toggleArchive(ts)}
              >
                {timeString}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleArchive(ts);
                  }}
                >
                  {selected.has(ts) ? <Checked /> : <Unchecked />}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="sidebar-menu-toolbar">
        <div className="uk-flex">
          {`${archiveData?.length ?? 0} Version${archiveData?.length === 1 ? '' : 's'}`}{' '}
          {selected?.size > 0 ? (
            <div className="uk-flex">
              <Dot style={{ height: '12px', marginTop: '5px', color: '#D7D8D8' }} />
              <div style={{ color: '#6659C0' }}>{selected.size} Selected</div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="uk-flex sidebar-menu-prompt-buttons" id={'archive-prompt-buttons'}>
          {!viewOnly && (
            <Fragment>
              <button
                onClick={() => updateSelectedPrompt(ArchiveToolbarTypes.Add)}
                className={
                  selectedPrompt === ArchiveToolbarTypes.Add
                    ? 'selected-sidebar-menu-button'
                    : 'sidebar-menu-button'
                }
                data-testid="add-archive-button"
              >
                <Add />
              </button>
              <button
                onClick={() => updateSelectedPrompt(ArchiveToolbarTypes.Delete)}
                className={
                  selectedPrompt === ArchiveToolbarTypes.Delete
                    ? 'selected-sidebar-menu-button'
                    : 'sidebar-menu-button'
                }
                data-testid="delete-archive-button"
              >
                <Delete />
              </button>
            </Fragment>
          )}
          <button
            onClick={() => updateSelectedPrompt(ArchiveToolbarTypes.Download)}
            className={
              selectedPrompt === ArchiveToolbarTypes.Download
                ? 'selected-sidebar-menu-button'
                : 'sidebar-menu-button'
            }
            data-testid="download-archive-button"
          >
            <Download />
          </button>
        </div>
      </div>
      <div
        className={`sidebar-menu-prompt ${selectedPrompt ? 'prompt-selected' : ''}`}
        style={{ maxHeight: selectedPrompt ? `${promptHeight}px` : 0 }}
      >
        <div ref={promptRef}>
          {selectedPrompt === ArchiveToolbarTypes.Download ? (
            <DownloadArchivePrompt selected={selected} />
          ) : selectedPrompt === ArchiveToolbarTypes.Delete ? (
            <DeleteArchivePrompt
              selected={selected}
              setSelected={setSelected}
              setSelectedPrompt={updateSelectedPrompt}
            />
          ) : selectedPrompt === ArchiveToolbarTypes.Add ? (
            <AddArchivePrompt setSelected={setSelected} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
