import { SidebarContext } from 'appContexts';
import { RadioCard } from 'components/RadioCard';
import Spinner from 'components/Spinner';
import React, { useCallback, useContext, useState } from 'react';
import { generatePdf } from 'utils';

enum CreateOptions {
  Create = 'create',
  Update = 'update',
}

export function AddArchivePrompt({ setSelected }) {
  const [createOption, setCreateOption] = useState(CreateOptions.Create);
  const { investigation, setArchiveData, archiveData, setDownloading, pdfGenerating } =
    useContext(SidebarContext);
  const keepLatest = createOption === CreateOptions.Create;
  const discardLatest = !keepLatest && !!archiveData?.length;

  const onCreate = useCallback(() => {
    function setDataCallback(data: any) {
      if (!investigation) return;
      setArchiveData(data);
      setSelected(new Set());
    }
    if (investigation) {
      generatePdf({
        investigation,
        setDownloading,
        setArchiveData: setDataCallback,
        discardLatest,
      });
    }
  }, [investigation, discardLatest, setArchiveData, setDownloading, setSelected]);

  return (
    <div className="archive-create-menu">
      <div className="uk-margin-medium-bottom">
        Create a new version or update the latest archive
      </div>
      <div className="uk-flex-between uk-flex uk-margin-medium-bottom">
        <RadioCard
          style={{ width: '48%' }}
          title={'CREATE'}
          selected={createOption === CreateOptions.Create}
          description={'Create new archive'}
          onSelect={() => setCreateOption(CreateOptions.Create)}
        />
        <RadioCard
          style={{ width: '48%' }}
          title={'UPDATE'}
          selected={createOption === CreateOptions.Update}
          description={'Update last archive'}
          onSelect={() => setCreateOption(CreateOptions.Update)}
        />
      </div>
      <button
        onClick={onCreate}
        disabled={pdfGenerating}
        className="uk-button-default uk-button"
        data-testid="create-update-archive-prompt-button"
      >
        {pdfGenerating ? (
          <Spinner ratio={0.55} style={{ margin: '-8px auto 0 auto' }} />
        ) : createOption === CreateOptions.Update ? (
          'Update'
        ) : (
          'Create'
        )}
      </button>
    </div>
  );
}
