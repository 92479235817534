import { useContext } from 'react';
import { DocumentNode } from 'graphql';
import { ComponentPluginContext } from 'appContexts';
import { useComponentPluginQuery } from 'hooks';

export function usePluginHistoryData(query: DocumentNode, assetType: string, assetValue: string) {
  const { getPluginConfig } = useContext(ComponentPluginContext);
  const { dateA, dateB, historyEnabled } = getPluginConfig()?.history || {};

  // Get latest or dateB registry data depending on whether history enabled
  const { data: bRawData, loading: bLoading } = useComponentPluginQuery(query, {
    variables: {
      filter: JSON.stringify([
        {
          [assetType]: assetValue,
          ...(historyEnabled &&
            (dateB === 'latest'
              ? { latestData: 'true' }
              : { firstSeenTime: new Date(dateB).getTime() / 1000 })),
        },
      ]),
      ...(historyEnabled ? { historical: true } : {}),
    },
  });

  // Get dateA registry data
  const { data: aRawData, loading: aLoading } = useComponentPluginQuery(
    query,
    {
      variables: {
        filter: JSON.stringify([
          {
            [assetType]: assetValue,
            ...(dateA &&
              (dateA === 'latest'
                ? { latestData: 'true' }
                : { firstSeenTime: new Date(dateA).getTime() / 1000 })),
          },
        ]),
        historical: true,
      },
    },
    true,
  );

  return { aRawData, aLoading, bRawData, bLoading };
}
