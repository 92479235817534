import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { hierarchy, partition } from 'd3';
import {
  abbreviatedCount,
  decadColors as colors,
  findExistingSectionIndex,
  getConfigAdvancedQuery,
  getCurrentInvestigation,
  getOutputVariableIndex,
  getOutputVariable,
  getPatternASNLinks,
  getTemplateParamId,
  isAdvancedSearch,
  isViewOnly,
  saveInvestigation,
  toInvestigationSectionId,
} from 'utils';
import { uniq, flatten, shuffle, isEqual } from 'lodash';
import drawIcicle from './drawIcicle';
import { AssetLink } from 'components';
import { ComponentPluginContext, SidebarContext } from 'appContexts';
import { TemplateParams } from 'model';

// Define Viz Sizing
export const margin = {
  top: 65,
  right: 500,
  bottom: 0,
  left: 50,
};

const padding = 2;
export const segmentSize = 35;
const vizHeight = 550;
const height = vizHeight + margin.top + margin.bottom;
// const colors = ['#39a7d0', '#36ada4', '#32b166', '#97a431', '#ce9032', '#f77189', '#f561dd', '#a48cf4'];

export default function Icicle({ ipFilter, patternData }: { ipFilter: string; patternData: any }) {
  const icicleRef = useRef<HTMLDivElement | null>(null);
  const [root, setRoot] = useState<any>();
  const rootRef = useRef();
  const { params, templateParams, pluginName } = useContext(ComponentPluginContext);
  const [width, setWidth] = useState(0);
  const [sequence, setSequence] = useState([]);
  const [clicked, setClicked] = useState(params?.config?.sequence?.length > 0);
  const [filterMatch, setFilterMatch] = useState([]);
  const [colorBank, setColorBank] = useState({});
  const { setLocalTemplateParams } = useContext(SidebarContext);
  const dataParam = useMemo(() => {
    return getOutputVariable(templateParams, params.uuid);
  }, [templateParams, params.uuid]);
  const dataIndex = useMemo(() => {
    return getOutputVariableIndex(templateParams, params.uuid);
  }, [templateParams, params.uuid]);

  const querySelection = useMemo(() => {
    const selectionVal = getConfigAdvancedQuery(params)?.selection?.value;
    if (selectionVal?.startsWith('$')) {
      return templateParams?.find((param) => `$${getTemplateParamId(param)}` === selectionVal)
        ?.value;
    }
    return selectionVal;
  }, [params, templateParams]);

  const filterClauses = useMemo(() => {
    return getConfigAdvancedQuery(params)?.filterClauses;
  }, [params]);

  const initialSequence = useRef(params?.config?.sequence ?? []);

  const serializeSequence = useCallback((sequence: any[]) => {
    return sequence.map((node) => node?.data?.name);
  }, []);

  const reconstructSequence = useCallback(
    (sequence: any[]) => {
      let reconstructedSequence = [];
      sequence?.forEach((node, index) => {
        if (index === 0) {
          const candidate = root && root.descendants().find((n: any) => isEqual(n.data.name, node));
          if (!candidate) return;
          reconstructedSequence.push(candidate);
        } else {
          const parent = reconstructedSequence[reconstructedSequence.length - 1];
          const candidate =
            parent && parent.descendants().find((n: any) => isEqual(n.data.name, node));
          if (!candidate) return;
          reconstructedSequence.push(candidate);
        }
      });
      return reconstructedSequence;
    },
    [root],
  );

  useEffect(() => {
    if (!icicleRef.current) {
      return;
    }

    root &&
      drawIcicle({
        container: icicleRef.current,
        root,
        setSequence: (sequence) => {
          if (sequence.length === 0) {
            initialSequence.current = sequence;
          }
          setSequence(sequence);
        },
        filterMatch,
        colors: colorBank,
        icicleSizing: { height, width },
        setClicked,
        initialSequence: reconstructSequence(initialSequence.current),
      });
  }, [icicleRef, root, filterMatch, colorBank, width, reconstructSequence]);

  // Disables pattern selection when data changes
  useEffect(() => {
    setSequence([]);
  }, [patternData]);

  useEffect(() => {
    // Build Node D3 Structure
    const data = hierarchy(patternData.tree)
      .sum((d) => {
        return d.children
          ? d.value -
              d.children.map((child: any) => child.value).reduce((a: number, b: number) => a + b)
          : d.value;
      })
      .sort((a: any, b: any) => b.value - a.value);

    // Determine Viz Width
    const depths = data.descendants().map((node: any) => node.depth);
    const layerCount = Math.max.apply(null, depths);
    const vizWidth = layerCount * segmentSize;
    setWidth(vizWidth + margin.left + margin.right);

    // Set D3 Root
    setRoot(partition().padding(padding).size([vizHeight, vizWidth])(data));
  }, [patternData, setRoot]);

  //Determine Legend Colors
  useEffect(() => {
    if (root) {
      const newColorBank: any = {};
      patternData.counts.slice(0, colors.length).forEach((patternTup: any, i: number) => {
        const [field, name, value] = patternTup;
        // Handle fields that might share same name
        // ex: country|Hong Kong vs. city|Hong Kong
        const patternKey = `${field}|${name}`;
        newColorBank[patternKey] = {
          color: colors[i],
          value: Math.floor((value / root.value) * 100),
        };
      });
      setColorBank(newColorBank);
    }
  }, [patternData.counts, root]);

  // Handle Search Filter
  useEffect(() => {
    const targetNode =
      root && root.descendants().find((node: any) => node.data.keys.includes(ipFilter));
    if (targetNode) {
      const newSequence = targetNode.ancestors().reverse().slice(1);
      setFilterMatch(newSequence);
    } else if (filterMatch.length !== 0) {
      setFilterMatch([]);
      setSequence([]);
    }
  }, [filterMatch.length, ipFilter, root]);

  // On sequence change, update the template params, and maybe the view config too
  useEffect(() => {
    function updateSelection(newTemplateParams: TemplateParams, avoidUpdate?: boolean) {
      const investigation = getCurrentInvestigation();
      setLocalTemplateParams && setLocalTemplateParams(newTemplateParams);
      rootRef.current = root;
      if (avoidUpdate || isViewOnly() || isAdvancedSearch()) return;
      const i = findExistingSectionIndex(investigation, params.uuid);
      const newSections = [...investigation.sections];
      let newParams = {
        ...params,
        config: { ...params.config, sequence: serializeSequence(sequence) },
      };
      newSections[i].id = toInvestigationSectionId(pluginName, newParams);
      saveInvestigation({ ...investigation, sections: newSections });
    }
    const filteredSequence: any[] = filterMatch.length > 0 ? filterMatch : sequence;
    const parsedDataParam = dataParam?.value && dataParam.value;
    if (
      filteredSequence.length === 0 &&
      initialSequence.current.length === 0 &&
      root?.value != null
    ) {
      const childIps = root.descendants().map((node: any) => node.data.keys);
      const ipSet = filterClauses?.length ? uniq(flatten(childIps)).sort() : undefined;
      const newDataset = { ips: ipSet, dataId: querySelection };
      let newTemplateParams = templateParams;
      const rootRefValue: any = rootRef?.current;
      if (
        dataIndex >= 0 &&
        (!isEqual(parsedDataParam?.ips?.sort(), ipSet) ||
          parsedDataParam?.dataId !== querySelection ||
          root.value !== rootRefValue?.value)
      ) {
        newTemplateParams = [...templateParams];
        newTemplateParams[dataIndex] = {
          ...dataParam,
          value: newDataset,
          label: `${root?.value} IP${root?.value !== 1 ? 's' : ''}`,
        };
      }
      updateSelection(newTemplateParams, params?.config?.sequence?.length === 0);
    } else if (filteredSequence.length > 0 && clicked) {
      const [hoveredNode] = filteredSequence.slice(-1);
      const childIps = hoveredNode.descendants().map((node: any) => node.data.keys);
      const ipSet = uniq(flatten(childIps)).sort();
      const newDataset = { ips: ipSet, dataId: querySelection };
      let newTemplateParams = templateParams;
      if (
        dataIndex >= 0 &&
        (!isEqual(parsedDataParam?.ips?.sort(), ipSet) ||
          parsedDataParam?.dataId !== querySelection)
      ) {
        newTemplateParams = [...templateParams];
        newTemplateParams[dataIndex] = {
          ...dataParam,
          value: newDataset,
          label: `${ipSet.length} IP${ipSet.length !== 1 ? 's' : ''}`,
        };
      }
      updateSelection(
        newTemplateParams,
        isEqual(serializeSequence(filteredSequence), params?.config?.sequence),
      );
    }
  }, [
    filterMatch,
    clicked,
    dataParam,
    dataIndex,
    templateParams,
    setLocalTemplateParams,
    sequence,
    querySelection,
    root,
    params,
    pluginName,
    serializeSequence,
    reconstructSequence,
    filterClauses,
  ]);

  // Setting position: relative in the parent and child fixes a PDF printing page issue.
  // https://stackoverflow.com/questions/7706504/page-break-inside-doesnt-work-in-chrome
  return (
    <div className="uk-position-relative" style={{ height }}>
      <div className="uk-position-relative" ref={icicleRef}>
        {root && (
          <Tooltip
            sequence={filterMatch.length > 0 ? filterMatch : sequence}
            totalCount={root.value}
          />
        )}
      </div>
    </div>
  );
}

function Tooltip({ sequence, totalCount }: { sequence: any; totalCount: any }) {
  if (sequence.length > 0) {
    // Figure out position of tooltip
    const [hoveredNode] = sequence.slice(-1);
    const leftPos = hoveredNode.y1 - segmentSize + margin.left + 30;
    const bottomPos = height - (hoveredNode.x1 + hoveredNode.x0) / 2 - margin.top;
    const topPos = (hoveredNode.x1 + hoveredNode.x0) / 2;
    const tooltipStyle: { left: string; top?: string; bottom?: string } = { left: leftPos + 'px' };
    if ((hoveredNode.x1 + hoveredNode.x0) / 2 > height / 2.4) {
      tooltipStyle.bottom = `${bottomPos}px`;
    } else {
      tooltipStyle.top = `${topPos}px`;
    }

    const childIps = hoveredNode.descendants().map((node: any) => node.data.keys);
    const representativeIps = shuffle(uniq(flatten(childIps))).slice(0, 5);
    const linkableItems = ['prefix', 'country', 'org', 'ip', 'router'];

    return (
      <div className="icicle-tooltip" style={tooltipStyle}>
        <table className="uk-table uk-margin-small-bottom">
          <thead>
            <tr>
              <th>Depth</th>
              <th>Label</th>
              <th>Item</th>
              <th className="uk-text-right">Freq</th>
              <th className="uk-text-right">Perc</th>
            </tr>
          </thead>
          <tbody>
            <tr key="root">
              <td>{0}</td>
              <td className="uk-text-nowrap">IP Addresses</td>
              <td>Root</td>
              <td className="uk-text-right">{abbreviatedCount(totalCount)}</td>
              <td className="uk-text-right">100</td>
            </tr>
            {sequence.map((node: any) => {
              const percentage = Math.floor((100 * node.value) / totalCount);
              const { name } = node.data;
              const [itemName, itemValue] = name;
              const isLinkable = linkableItems.includes(itemName);
              let itemContent;
              if (itemName === 'origin') {
                const linkVal = getPatternASNLinks(itemValue);
                if (!linkVal) {
                  itemContent = itemValue;
                } else {
                  itemContent = <AssetLink value={itemValue} url={linkVal} />;
                }
              } else {
                itemContent = isLinkable ? <AssetLink value={itemValue} /> : itemValue;
              }
              return (
                <tr key={`${name}${node.depth}`}>
                  <td>{node.depth}</td>
                  <td>{itemName}</td>
                  <td>{itemContent}</td>
                  <td className="uk-text-right">{abbreviatedCount(node.value)}</td>
                  <td className="uk-text-right">{percentage < 1 ? '<1' : percentage}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <span className="uk-text-muted">REPRESENTATIVE MEMBERS</span>
          <ul className="uk-list uk-list-collapse uk-margin-remove">
            {representativeIps.map((ip: any) => {
              return (
                <li key={ip}>
                  <AssetLink value={ip} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
  return null;
}
