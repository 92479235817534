import { checkForMisappropriatedIP } from 'utils';

export function isRouterId(id: string) {
  const lower = id?.toLowerCase();
  return lower?.startsWith('urn:rtr:') || lower?.startsWith('urn:router:');
}

export function getRouterIPs(router: any) {
  const { routerAlias } = router || {};
  const allIps = [...(routerAlias?.responsiveIps || []), ...(routerAlias?.unresponsiveIps || [])];
  const unresponsiveIps = routerAlias?.unresponsiveIps?.map((asset: any) => asset.ip) || [];
  const country = routerAlias.country;
  const routerId = router.routerId;
  return allIps.map((asset: any) => {
    const retVal = { ...asset };
    const { ip, routingOrigin } = asset;
    retVal.geo = { country: country };
    retVal.responsive = !unresponsiveIps.includes(ip);
    retVal.routerId = routerId;
    const isMisappropriated = checkForMisappropriatedIP(ip);
    retVal.originSearchField =
      routingOrigin &&
      !isMisappropriated &&
      routingOrigin.origins
        .map((originObj: any) => `${originObj.asn} ${originObj.orgName}`)
        .toString();

    // Don't show routing origin or rdns for misappropriated IPs
    if (isMisappropriated) {
      delete retVal.routingOrigin;
      delete retVal.rdns;
    }
    return retVal;
  });
}
