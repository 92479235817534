import React, { useEffect, useMemo, useState } from 'react';
import CheckboxTree, { Node } from 'react-checkbox-tree';
import { ReactComponent as ExpandClose } from 'uikit/src/images/icons/chevron-right.svg';
import { ReactComponent as ExpandOpen } from 'uikit/src/images/icons/chevron-down.svg';
import Select, { OptionsOrGroups, StylesConfig } from 'react-select';
import { reactSelectStyle, DropdownIndicator, ClearIndicator } from 'components';
import { intersection, without } from 'lodash';

export type TreeNode = Node;

type TreeProps = {
  nodes: Node[];
  checkedNodes?: string[];
  onCheckedNodes?: (checked: string[]) => void;
};

const noCheckedNodes: string[] = [];

export function Tree({ nodes, checkedNodes, onCheckedNodes }: TreeProps) {
  const [checked, setChecked] = useState<string[]>(noCheckedNodes);
  const [expanded, setExpanded] = useState<string[]>(noCheckedNodes);
  const [filter, setFilter] = useState<string>();
  const allChecked = useMemo(
    () => (checkedNodes ? toCheckModel(nodes, checkedNodes) : noCheckedNodes),
    [nodes, checkedNodes],
  );
  const filteredNodes = useMemo(
    () => (filter ? getFilteredNodes(nodes, filter) : nodes),
    [nodes, filter],
  );
  const allFilteredNodeValues = useMemo(() => getAllLeafNodeValues(filteredNodes), [filteredNodes]);

  const iconProps = {
    width: 16,
    height: 16,
  };

  useEffect(() => {
    setChecked(filter ? intersection(allChecked, allFilteredNodeValues) : allChecked);
  }, [filter, allChecked, allFilteredNodeValues]);

  function onCheck(checked: string[]) {
    let newChecked = checked;
    if (filter) {
      newChecked = without(allChecked, ...allFilteredNodeValues);
      newChecked.push(...checked);
    }
    if (onCheckedNodes) {
      onCheckedNodes(fromCheckModel(nodes, newChecked));
    } else {
      setChecked(newChecked);
    }
  }

  function onSelectAll(event: React.FormEvent<HTMLInputElement>) {
    onCheck(event.currentTarget.checked ? allFilteredNodeValues : []);
  }

  function onFilter(selection: any) {
    setFilter(selection?.value);
  }

  const indeterminate = checked.length > 0 && checked.length < allFilteredNodeValues.length;

  return (
    <div style={{ fontSize: '90%' }}>
      <div className="uk-flex uk-flex-between	uk-flex-bottom">
        <TreeSearch nodes={nodes} onFilter={onFilter} />
        <label className="input-label uk-margin-small-left" style={{ marginBottom: '8px' }}>
          <input
            type="checkbox"
            className="uk-checkbox"
            onChange={onSelectAll}
            checked={checked.length > 0}
            ref={(input) => {
              if (input) input.indeterminate = indeterminate;
            }}
          />
          <span style={{ marginLeft: 5 }}>Select/Unselect All</span>
        </label>
      </div>
      <div
        className="app-medium-border"
        style={{ height: '10rem', maxWidth: '30rem', overflow: 'auto', boxSizing: 'border-box' }}
      >
        <CheckboxTree
          nodes={filteredNodes}
          checked={checked}
          expanded={expanded}
          onCheck={onCheck}
          onExpand={setExpanded}
          nativeCheckboxes={true}
          icons={{
            expandClose: <ExpandClose className="rct-icon rct-icon-expand-close" {...iconProps} />,
            expandOpen: <ExpandOpen className="rct-icon rct-icon-expand-open" {...iconProps} />,
          }}
        />
      </div>
    </div>
  );
}

const baseStyle = reactSelectStyle();
const customStyle = {
  menuList: (base: Record<string, any>) => ({
    ...base,
    height: '10.5rem',
  }),
};

function TreeSearch({ nodes, onFilter }: { nodes: TreeNode[]; onFilter: any }) {
  const options = useMemo(() => getOptions(nodes), [nodes]);
  return (
    <div className="uk-inline" style={{ width: '15rem', marginBottom: '8px' }}>
      <Select
        placeholder="Search"
        onChange={onFilter}
        options={options}
        isClearable={true}
        styles={
          {
            ...baseStyle,
            ...customStyle,
          } as StylesConfig
        }
        menuPortalTarget={document.body}
        components={{ DropdownIndicator, ClearIndicator }}
      />
    </div>
  );
}

export function getOptions(nodes: TreeNode[]): OptionsOrGroups<any, any> {
  const optionsMaps: Record<string, any>[] = [];

  function addToOptions(nodes: TreeNode[], depth: number = 0) {
    let optionsMap = optionsMaps[depth];
    if (!optionsMap) {
      optionsMap = {};
      optionsMaps.push(optionsMap);
    }
    nodes.forEach((node) => {
      const label = removeCount(node.label as string);
      optionsMap[label] = { value: label, label };
      if (node.children) {
        addToOptions(node.children, depth + 1);
      }
    });
  }

  addToOptions(nodes);
  const options: any[] = [];
  optionsMaps.forEach((optionsMap) => options.push(...Object.values(optionsMap)));
  return options;
}

function getFilteredNodes(nodes: TreeNode[], filter: string) {
  function pruneToFiltered(nodes: TreeNode[]) {
    const filteredNodes: TreeNode[] = [];
    nodes.forEach((node) => {
      if (nodeMatchesFilter(node, filter)) {
        filteredNodes.push({
          ...node,
          className: 'filter-selection',
        });
      } else if (node.children) {
        const matchIndex = node.children.findIndex((child) => nodeMatchesFilter(child, filter));
        if (matchIndex >= 0) {
          const children = [...node.children];
          children[matchIndex] = { ...children[matchIndex], className: 'filter-selection' };
          filteredNodes.push({
            ...node,
            children,
          });
        } else {
          const filteredChildren = pruneToFiltered(node.children);
          if (filteredChildren.length > 0) {
            filteredNodes.push({
              ...node,
              children: filteredChildren,
            });
          }
        }
      }
    });
    return filteredNodes;
  }
  return pruneToFiltered(nodes);
}

function nodeMatchesFilter(node: TreeNode, filter: string) {
  const label = node.label as string;
  if (label) {
    return removeCount(label) === filter;
  } else {
    return false;
  }
}

function removeCount(label: string) {
  return label.replace(/^\(\d+\) /, '');
}

function getAllLeafNodeValues(nodes: Node[]) {
  const all: string[] = [];
  nodes.forEach((node) => {
    const descendants = node.children ? getAllLeafNodeValues(node.children) : undefined;
    if (descendants) {
      all.push(...descendants);
    } else {
      all.push(node.value);
    }
  });
  return all;
}

function toCheckModel(nodes: Node[], checked: string[]) {
  const nodeValueSet = new Set(checked);

  function getCheckedValues(nodes: Node[]) {
    const descendants: string[] = [];
    nodes.forEach((node) => {
      if (nodeValueSet.has(node.value)) {
        if (node.children) {
          descendants.push(...getAllLeafNodeValues(node.children));
        } else {
          descendants.push(...descendants, node.value);
        }
      } else if (node.children) {
        descendants.push(...getCheckedValues(node.children));
      }
    });
    return descendants;
  }

  return getCheckedValues(nodes);
}

function fromCheckModel(nodes: Node[], checked: string[]) {
  const nodeValueSet = new Set(checked);

  function getCheckedValues(nodes: Node[]) {
    const descendants: string[] = [];
    nodes.forEach((node) => {
      if (node.children) {
        const childValueSet = new Set(node.children.map((child) => child.value));
        const checkedDescendentValues = getCheckedValues(node.children);
        const checkedChildren = checkedDescendentValues.filter((descendantValue) =>
          childValueSet.has(descendantValue),
        );
        if (checkedChildren.length === node.children.length) {
          descendants.push(node.value);
        } else {
          descendants.push(...checkedDescendentValues);
        }
      } else if (nodeValueSet.has(node.value)) {
        descendants.push(node.value);
      }
    });
    return descendants;
  }

  return getCheckedValues(nodes);
}
