import { gql, makeVar, ReactiveVar } from '@apollo/client';
import { getGraphQLClient } from 'gql';
import {
  UserAdvancedQueries,
  UserDataSection,
  UserDatasets,
  UserInvestigations,
} from './userDataTypes';
import { parseDates } from './userDataRemote';

const sharedUserDataQuery = gql`
  query ($sharedKey: String!) {
    sharedUserData(sharedKey: $sharedKey) {
      objects {
        section
        key
        value
      }
    }
  }
`;

export const userSharedAdvancedQueriesVar = makeVar<UserAdvancedQueries>({});
export const userSharedDatasetsVar = makeVar<UserDatasets>({});
export const userSharedInvestigationsVar = makeVar<UserInvestigations>({});

const reactiveVarsBySection: Record<string, ReactiveVar<any>> = {};
reactiveVarsBySection[UserDataSection.AdvancedQueries] = userSharedAdvancedQueriesVar;
reactiveVarsBySection[UserDataSection.Datasets] = userSharedDatasetsVar;
reactiveVarsBySection[UserDataSection.Investigations] = userSharedInvestigationsVar;

export async function getSharedUserData(sharedKey: string) {
  try {
    const { data, errors } = await getGraphQLClient().query({
      query: sharedUserDataQuery,
      variables: { sharedKey },
    });

    if (errors) {
      throw new Error(errors[0].message);
    }

    const objects = data.sharedUserData?.objects || [];
    const userData: Record<string, any> = {};

    objects.forEach((object: any) => {
      const value = object.value;
      if (Array.isArray(value)) {
        userData[object.section] = value;
      } else {
        parseDates(value);
        let sectionMap = userData[object.section];
        if (!sectionMap) {
          sectionMap = userData[object.section] = {};
        }
        if (object.deleted) {
          value.deleted = true;
        }
        sectionMap[object.key] = value;
      }
    });

    Object.entries(userData).forEach(([section, updates]) => {
      reactiveVarsBySection[section]({ ...reactiveVarsBySection[section](), ...updates });
    });

    return objects.length > 0 ? objects[0].key : undefined;
  } catch (e) {
    console.error(`Error fetching shared user data: ${e}`);
    return undefined;
  }
}
