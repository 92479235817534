export const TAB_QUERIES_BY_PLUGIN: Record<string, Record<string, string>> = {
  'country/CountryDetailsTable': {
    Routers: 'CountryRouterData',
    Facilities: 'CountryFacilityData',
    Exchanges: 'CountryExchangeData',
    Providers: 'CountryProvidersData',
  },
  'country/CountrySummary': {
    providers: 'CountryProvidersData',
    'border-providers': 'CountryFlowsData',
    'border-countries': 'CountryFlowsData',
  },
  'exchange/ExchangeConnections': {
    peers: 'ExchangeConnectionsData',
    facilities: 'ExchangeConnectionsData',
    Routers: 'ExchangeRoutersData',
  },
  'asn/ASNDetailsTable': {
    'IPv4 Upstreams': 'ASRelationshipData',
    'IPv4 Downstreams': 'ASRelationshipData',
    'IPv4 Prefixes': 'ASIPv4PrefixesData',
    'IPv6 Prefixes': 'ASIPv6PrefixesData',
    Routers: 'ASRouterData',
  },
  'org/OrgDetailsTable': { ASNs: 'OrgASNData', prefixes: 'OrgPrefixData', routers: 'OrgASNData' },
  'facility/FacilityConnections': {
    peers: 'FacilityConnectionsData',
    exchanges: 'FacilityConnectionsData',
    Routers: 'FacilityRoutersData',
  },
};
