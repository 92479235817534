import React from 'react';
import { useExchangeQuery } from 'hooks';
import { ComponentPluginName } from 'components';
import { ALERT_TYPES, createGenericNotification, UserInvestigation } from 'model';
import { addSectionToInvestigation } from 'utils';
import Investigation from '../investigations/Investigation';
import { Redirect } from 'react-router-dom';

export default function IXReport({ assetValue }: { assetValue: string }) {
  const { asset } = useExchangeQuery(assetValue);
  const assetName = asset?.exchange?.name;
  const params = { assetValue, assetName };

  const newInvestigation: UserInvestigation = {
    title: assetName,
    description: '',
    subtitle: asset?.exchange?.nameLong ?? '',
    sections: [],
  };
  if (!assetValue.toLowerCase().startsWith('urn:ix:')) {
    createGenericNotification('invalid-asset', {
      alertType: ALERT_TYPES.error,
      message: `${assetValue} is not a valid exchange id.`,
      title: 'Invalid Exchange',
    });
    return <Redirect to="/" />;
  }

  if (!asset) return <Investigation investigation={newInvestigation} />;

  addSectionToInvestigation(newInvestigation, ComponentPluginName.ExchangeAbout, params, 'About');
  if (asset?.exchange?.prefixesV4 || asset?.exchange?.prefixesV6) {
    addSectionToInvestigation(
      newInvestigation,
      ComponentPluginName.ExchangePrefixes,
      params,
      'Prefixes',
    );
  }
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.ExchangeFacilities,
    params,
    'Facilities',
  );
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.ExchangeConnections,
    params,
    'Connections',
  );

  return <Investigation investigation={newInvestigation} />;
}
