import { ReactNode } from 'react';

export enum AssetType {
  IP = 'ip',
  Prefix = 'prefix',
  AS = 'asn',
  Org = 'org',
  Router = 'router',
  Country = 'country',
  Facility = 'facility',
  IX = 'ix',
  Domain = 'domain',
  Hostname = 'hostname',
}

export enum NonPageAssetTypes {
  HostType = 'hostname-type',
  Suffix = 'suffix',
  CertSubjectKey = 'cert-subject-key',
  HostCDN = 'hostname-cdn',
  Reputation = 'maltrail-behavior',
  Malware = 'maltrail-malware',
  PortsCollectorCountry = 'ports-collector-country',
  PrefixReputation = 'prefix-reputation',
  Time = 'timestamp',
  RouterVendor = 'router-vendor',
  RouterOs = 'router-os',
  RouterOsVersion = 'router-os-version',
  ServiceName = 'service-name',
  ServiceTags = 'service-tags',
  ServiceRegion = 'service-region',
  Port = 'port',
  ApplicationType = 'application-type',
  TransportProtocol = 'transport-protocol',
  ProbeData = 'probe-data',
  TLSCipherSuite = 'tls-cipher-suite',
  TLSCurveId = 'tls-curve-id',
  TLSJA3S = 'tls-ja3s',
  TLSVersion = 'tls-version',
  DNSRecursionAvailable = 'dns-recursion-available',
  HTTPSoftware = 'http-software',
  SSHSoftware = 'ssh-software',
  HASSH = 'ssh-hassh',
  IPVersion = 'ip-version',
  TracerouteEdge = 'traceroute-edge',
  Owner = 'owner',
  Hash = 'hash', // either md5 or mmh3
  CPE = 'cpe',
  TTLToTarget = 'ttl-to-target',
  MPLS = 'mpls-type',
  JARM = 'jarm-fingeprint',
}

// !!!REMEMBER TO UPDATE THIS ARRAY IF NECESSARY WHENEVER ADDING A NEW ASSET TYPE!!!
// This array includes any asset types which may possibly contain commas in their values.
// Any items in this array are not allowed to do the special comma separated parsing for splitting
// up terms in an is one of filter.
export const NonCommaSeparatedTypes = [
  AssetType.Org,
  NonPageAssetTypes.RouterVendor,
  NonPageAssetTypes.RouterOs,
  NonPageAssetTypes.Malware,
  NonPageAssetTypes.ServiceName,
  NonPageAssetTypes.ServiceTags,
  NonPageAssetTypes.ProbeData,
  NonPageAssetTypes.HTTPSoftware,
  NonPageAssetTypes.SSHSoftware,
  NonPageAssetTypes.CPE,
];

export type TemplateAssetType = AssetType | NonPageAssetTypes;

export const TemplateAssetTypes = { ...NonPageAssetTypes, ...AssetType };

export type Asset = Record<string, any> & {
  type: AssetType;
};

export type RoutingOrigins = {
  asn: number;
  orgName: string;
};

export type TargetPrefix = {
  prefix?: string;
  geo?: {
    city?: string;
    country?: string;
    countryDivision?: string;
    anycast?: string;
  };
  routingOrigin?: {
    origins: RoutingOrigins[] | [];
  };
};

export type EdgeIP = {
  ip: string;
  routerId: string;
  exchange: {
    id: string;
    name: string;
  };
  geo: {
    city: string;
    country: string;
    countryDivision: string;
    anycast?: boolean;
  };
  routingOrigin: {
    origins: RoutingOrigins[] | [];
  };
};

export type AssetLinkProps = {
  value: string | number;
  linkValue?: string | number;
  url?: string;
  forcedType?: AssetType;
  children?: ReactNode;
  style?: any;
};

export type ASN = {
  asn: number;
  orgName?: string;
};
