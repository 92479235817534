import React, { ReactNode, Fragment } from 'react';
import ReactDOM from 'react-dom';
//import { createRoot, Root } from 'react-dom/client';
import UIkit from 'uikit';

type ModalActionProps = {
  label: string;
  onClick: () => void;
  className?: string;
  cancelable?: boolean;
};

type ModalProps = {
  component?: ReactNode;
  title?: ReactNode | string;
  content?: ReactNode;
  body?: ReactNode | string;
  footer?: ReactNode | string;
  action?: ModalActionProps;
  onClose?: () => void;
  background?: string;
};

export function Modal({ title, content, body, footer, action, onClose }: ModalProps) {
  return (
    <div className="uk-modal-dialog" data-testid="modal-dialog">
      {(!action || action.cancelable) && (
        <button
          type="button"
          className="uk-modal-close-default"
          data-uk-close
          onClick={onClose}
        ></button>
      )}
      {title && (
        <div className="uk-modal-header uk-padding-remove-bottom">
          <div className="uk-modal-title uk-text-bold">{title}</div>
        </div>
      )}
      {content && content}
      {!content && (
        <Fragment>
          <div className="uk-modal-body uk-padding-remove-top">
            {typeof body === 'string' ? <p>{body}</p> : body}
          </div>
          <div className="uk-modal-footer uk-text-right uk-padding-remove-top">
            {action || footer ? (
              action ? (
                <ModalAction {...action} />
              ) : (
                footer
              )
            ) : (
              <button
                type="button"
                className="uk-button uk-button-default uk-modal-close"
                data-testid="modal-close-button"
                onClick={onClose}
              >
                Close
              </button>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export function ModalAction({ label, onClick, className, cancelable }: ModalActionProps) {
  return (
    <Fragment>
      {cancelable && (
        <button
          type="button"
          className="uk-button uk-modal-close"
          data-testid="modal-cancel-button"
        >
          Cancel
        </button>
      )}
      <button
        type="button"
        className={`uk-button uk-modal-close uk-margin-small-left ${className || 'uk-button-default'}`}
        onClick={onClick}
        data-testid="modal-action-button"
      >
        {label}
      </button>
    </Fragment>
  );
}

let modalElement: HTMLElement | undefined = undefined;
//let root: Root | undefined = undefined;

Modal.show = (props: ModalProps) => {
  if (Modal.isShowing()) {
    return;
  }

  modalElement = document.createElement('DIV');
  modalElement.setAttribute('uk-modal', 'true');
  if (props.background) {
    modalElement.style.background = props.background;
  }
  //root = createRoot(modalElement);

  UIkit.util.once(modalElement, 'hide', (event: Event) => {
    const elem = event.target;
    (elem as any).__hiding = true;
  });

  UIkit.util.once(modalElement, 'hidden', onHidden);

  const component = props.component || <Modal {...props} />;
  ReactDOM.render(component as any, modalElement);
  //root.render(component as any);
  UIkit.modal(modalElement, { bgClose: false }).show();
};

Modal.hide = () => {
  if (Modal.isShowing()) {
    UIkit.modal(modalElement).hide();
  }
};

Modal.isShowing = () => {
  return modalElement && !(modalElement as any).__hiding;
};

function onHidden(event: Event) {
  const elem = event.target as HTMLElement;
  if (elem.getAttribute('uk-modal')) {
    ReactDOM.unmountComponentAtNode(elem);
    //root.unmount();
    elem.remove();
    if (elem === modalElement) {
      modalElement = undefined;
      //root = undefined;
    }
  } else {
    UIkit.util.once(modalElement, 'hidden', onHidden);
  }
}
