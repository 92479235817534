import {
  capitalizeFirstLetter,
  comparatorSupportsOutputVariables,
  createUniqueInvestigationSectionId,
  getAnchorLinkId,
  getConfigAdvancedQuery,
  getCountryName,
  getCountryOptions,
  getCurrentInvestigation,
  getFilterClauseOption,
  getFilterClauseSelections,
  getImageData,
  getUserDatasetsOptions,
  getVersion,
  isAdvancedSearch,
  isDatasetQuery,
  isMultiSelectComparator,
  isTemplate,
  isViewOnly,
  loadASValueOptions,
  loadExchangeValueOptions,
  loadFacilityValueOptions,
  loadRouterOSValueOptions,
  loadRouterVendorValueOptions,
  makeUniqueId,
  normalizeDateLabel,
  normalizeDateValue,
  normalizeDomain,
  normalizeDomainLabel,
  normalizeFilterClauseValue,
  parseASN,
  parseInvestigationSectionId,
  saveInvestigation,
  sectionContainsDatasetParam,
  sectionContainsDatasetReference,
  toInvestigationSectionId,
} from 'utils';
import {
  AdvancedQuery,
  AssetType,
  Images,
  NonPageAssetTypes,
  QueryFilterClause,
  QueryFilterComparator,
  TemplateParam,
  TemplateParams,
  UserDatasets,
  userDatasetsVar,
  UserInvestigation,
  UserInvestigationSection,
  userInvestigationsVar,
  userSharedDatasetsVar,
} from 'model';
import { ComponentPluginName, isNonDataPlugin } from 'components';
import { cloneDeep, escapeRegExp, isEqual, parseInt, orderBy } from 'lodash';
import { LocalAnnotations } from 'appContexts';
import { DocumentNode, gql } from '@apollo/client';
import { getGraphQLClient } from 'gql';

export type TileProps = {
  newParams: any;
  title?: string;
  description?: string;
  investigation: UserInvestigation;
  pluginName?: ComponentPluginName;
};

export type FindAndReplaceTerm = {
  find: string;
  replace: string;
};

export enum InvestigationTypes {
  Template = 'Template',
  Mosaic = 'Mosaic',
}

export const SHARE_MUTATION = gql`
mutation($key: String!) {
  shareUserData(input: {key: $key}) {
    sharedKey
  }
}
`;

export const PUBLISH_MUTATION = gql`
mutation($id: String!, $roles: [String]) {
  publishUserData(input: {key: $id, roles: $roles}) {
    publishedKey
  }
}
`;

export const UNPUBLISH_MUTATION = gql`
  mutation($publishedKey: String!) {
    unpublishUserData(input: {publishedKey: $publishedKey}) {
      removedObjectCount
    }
  }
`;

export const DEBOUNCE_TIMEOUT = 500; //ms

export function saveTileState(props: TileProps) {
  const { newParams, title, description, investigation, pluginName } = props;
  if (isViewOnly() || isAdvancedSearch()) return;
  const existingSection: UserInvestigationSection | undefined = findExistingSection(
    investigation,
    newParams.uuid,
  );
  let existingPlugin, existingParams, existingTitle, existingDescription;
  if (existingSection) {
    [existingPlugin, existingParams] = parseInvestigationSectionId(existingSection.id);
    existingTitle = existingSection?.title;
    existingDescription = existingSection?.description;
  }

  if (!existingSection && (!pluginName || !newParams)) return;

  const newID = toInvestigationSectionId(pluginName ?? existingPlugin, newParams ?? existingParams);
  const newSection: UserInvestigationSection = {
    id: newID,
    title: title ?? existingTitle ?? '',
    description: description ?? existingDescription ?? '',
    saved: new Date(),
  };

  if (existingSection) {
    const i = investigation.sections.indexOf(existingSection);
    investigation.sections[i] = newSection;
  }
  saveInvestigation(investigation);
}

export function findExistingSection(investigation: UserInvestigation, uuid: string) {
  return investigation?.sections?.find((section: UserInvestigationSection) => {
    return parseInvestigationSectionId(section.id)[1].uuid === uuid;
  });
}

export function findExistingSectionIndex(investigation: UserInvestigation, uuid: string) {
  return investigation?.sections?.findIndex((section: UserInvestigationSection) => {
    return parseInvestigationSectionId(section.id)[1].uuid === uuid;
  });
}

export function normalizeLabelByAssetType(value: string, assetType: string) {
  if (assetType.endsWith('-set')) assetType = assetType.split('-set')[0];
  const normalizerLookup: any = {
    country: (cc: string | undefined) => (cc?.length === 2 ? getCountryName(cc) : cc),
    time: normalizeDateLabel,
    domain: normalizeDomainLabel,
  };
  if (normalizerLookup[assetType]) {
    return normalizerLookup[assetType](value);
  }
  if (assetType.includes('dataset')) {
    const datasets = { ...userDatasetsVar(), ...userSharedDatasetsVar() };
    return datasets[value]?.name ?? value;
  }
  return value;
}

export function normalizeValueByAssetType(value: string, assetType: string) {
  if (assetType.endsWith('-set')) assetType = assetType.split('-set')[0];
  const normalizerLookup: any = {
    time: normalizeDateValue,
    domain: normalizeDomain,
    asn: parseASN,
  };
  if (normalizerLookup[assetType]) {
    return normalizerLookup[assetType](value);
  }
  return value;
}

export function getAssetOptions(asset: string, clauseType?: string) {
  if (asset.endsWith('-set')) asset = asset.split('-set')[0];
  const optionsLookup: Record<string, any> = {
    country: getCountryOptions(),
  };
  const clauseOption = clauseType ? getFilterClauseOption(clauseType) : undefined;
  if (clauseOption) {
    return clauseOption.valueOptions;
  }
  return Object.keys(optionsLookup).includes(asset.toLowerCase())
    ? optionsLookup[asset.toLowerCase()]
    : [];
}

export function getAssetOptionsLoader(asset: string, clauseType?: string) {
  const optionsLookup: Record<string, any> = {
    asn: loadASValueOptions,
    ix: loadExchangeValueOptions,
    facility: loadFacilityValueOptions,
    'router-os': loadRouterOSValueOptions,
    'router-vendor': loadRouterVendorValueOptions,
  };
  const clauseOption = clauseType ? getFilterClauseOption(clauseType) : undefined;
  if (clauseOption) {
    // For simplicity, datasets have special value options in templates. Don't use the
    // clause options loader.
    if (clauseOption.comparatorOptions.includes(QueryFilterComparator.IsInDataset))
      return undefined;
    return clauseOption.loadValueOptions ?? clauseOption.loadFixedValueOptions;
  }
  const assetHasLoader = Object.keys(optionsLookup).includes(asset.toLowerCase());
  return assetHasLoader && optionsLookup[asset.toLowerCase()];
}

export async function loadTemplateParamLabel(
  id: string,
  value: string | string[],
  clauseType?: string,
) {
  const assetType = getTemplateAssetTypeFromId(id);
  const loadValueOptions = getAssetOptionsLoader(assetType, clauseType);
  if (!value) return undefined;
  if (loadValueOptions) {
    const selections = await getFilterClauseSelections(value, loadValueOptions, false);
    return selections.label;
  }
  const userDatasets = userDatasetsVar();
  const valueOptions = id?.includes('dataset')
    ? getUserDatasetsOptions(userDatasets, true, `$${id}`)
    : getAssetOptions(assetType, clauseType);
  if (valueOptions) {
    const label = valueOptions.find((option) => option?.value === value)?.label;
    if (label) return label;
  }
  return value;
}

export function getQueryClauseAssetType(clause: QueryFilterClause) {
  const clauseOption = getFilterClauseOption(clause);
  const isSet = isMultiSelectComparator(clause?.comparator);
  const isInDataset = clause.comparator === QueryFilterComparator.IsInDataset;
  if (isInDataset) return 'dataset';
  const baseAssetType = clauseOption?.assetType ?? clause?.type?.split(':')[1].toLowerCase();
  return `${baseAssetType}${isSet ? '-set' : ''}`;
}

export enum resolutionToAssetType {
  routers = AssetType.Router,
  tracerouteEdges = NonPageAssetTypes.TracerouteEdge,
  ips = AssetType.IP,
  dnsHosts = AssetType.Hostname,
  facilities = AssetType.Facility,
  peeringExchanges = AssetType.IX,
}

export function getAdvancedQueryOutputName(advancedQuery: AdvancedQuery) {
  if (!advancedQuery) {
    return;
  }
  // This is exception handling to maintain backwards compatability with user uploaded data. This also forbids ip-sets from being created for use as filtering, but allows for datasets of ips to be used as the dataset.
  if (advancedQuery.resolution === 'ips') {
    return 'dataset';
  }
  const assetType = resolutionToAssetType[advancedQuery.resolution];
  if (!assetType) {
    return;
  }
  return getOutputAssetTypeName(assetType);
}

export function getOutputAssetTypeName(assetType: string) {
  if (assetType === 'ip') {
    return 'dataset';
  }
  return `${assetType}-set`;
}

export function getOutputVariableIndex(templateParams: TemplateParams, UUID: string) {
  return templateParams?.findIndex((param) => param.origin === UUID);
}

export function getOutputVariable(templateParams: TemplateParams, UUID: string) {
  return templateParams?.find((param) => param.origin === UUID);
}

export function isOutputVariable(param: TemplateParam): Boolean {
  return !!(param && param.origin);
}

export function getTemplateAssetTypeFromId(id: string) {
  return id?.replace(/[0-9]/g, '');
}

export function lookupSectionTemplateParams(templateParams: TemplateParams, params: any) {
  if (!params.advancedQuery) {
    if (!params.assetValue) return [];
    const matchingParam = templateParams?.find(
      (param) => `$${getTemplateParamId(param)}` === params.assetValue,
    );
    return matchingParam ? [matchingParam] : [];
  } else {
    const aq: AdvancedQuery = getConfigAdvancedQuery(params);
    let matchingParams = [];
    // first handle the dataset param if applicable
    if (typeof aq?.selection?.value === 'string' && aq?.selection?.value?.startsWith('$dataset')) {
      const matchingDataset = templateParams?.find(
        (param) => `$${getTemplateParamId(param)}` === aq.selection.value,
      );
      matchingDataset && matchingParams.push(matchingDataset);
    }
    for (let i = 0; i < (aq.filterClauses?.length ?? 0); i++) {
      const filter = aq.filterClauses && aq.filterClauses[i];
      if (!filter) continue;
      // If the filter values accepts an array of values (e.g. includesOneOf), one of the values could be a template param that needs to be expanded later
      if (Array.isArray(filter.value)) {
        for (let j = 0; j < filter.value.length; j++) {
          const filterValue = filter.value[j];
          const matchingParam = templateParams?.find(
            (param) => `$${getTemplateParamId(param)}` === filterValue,
          );
          matchingParam && matchingParams.push(matchingParam);
        }
      } else {
        const matchingParam = templateParams?.find(
          (param) => `$${getTemplateParamId(param)}` === filter.value,
        );
        matchingParam && matchingParams.push(matchingParam);
      }
    }
    return matchingParams;
  }
}

export function getClauseFromAssetType(investigation: UserInvestigation, assetType: string) {
  // loop through investigation sections. If we find a filter clause that would produce the asset type
  // return that clause.
  let clauseType = undefined;
  investigation.sections.forEach((section) => {
    if (!!clauseType) return;
    const [, params] = parseInvestigationSectionId(section.id);
    const aq: AdvancedQuery = getConfigAdvancedQuery(params);
    if (!aq) return;
    aq.filterClauses?.forEach((clause) => {
      const clauseAssetType = getQueryClauseAssetType(clause);
      if (clauseAssetType === assetType) {
        clauseType = clause.type;
        return;
      }
    });
  });
  return clauseType;
}

export function getTemplateSectionTitle(pluginName: ComponentPluginName) {
  if (pluginName === ComponentPluginName.UserMarkdown) return 'Markdown Tile';
  if (pluginName === ComponentPluginName.PrimitiveTile) return 'Primitive Tile';
  if (!pluginName.includes('/')) return '';
  const splitTitle = pluginName.split('/');
  const [assetType, pluginType] = splitTitle;

  function processCamelCase(str: string) {
    for (let i = 0; i < str.length; i++) {
      if (i + 1 < str.length && str[i + 1] === str[i + 1].toUpperCase()) {
        if (i + 2 < str.length && str[i + 2] !== str[i + 2].toUpperCase()) {
          str = str.slice(0, i + 1) + ' ' + str.slice(i + 1);
          i++;
        }
      }
    }
    return str;
  }

  if (assetType === 'advancedquery') {
    return processCamelCase(pluginType.replace('QueryResults', '')).replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }
  return processCamelCase(pluginType)?.replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export function getTemplateParamId(param: TemplateParam) {
  return param?.id ?? param?.expression?.split('$')[1];
}

type TemplateContextProps = {
  existingTemplateParams: TemplateParams;
  newTemplateParams: TemplateParams;
  paramCountByAssetType: Record<string, number>;
  params: any;
  oldToNewIdMapping: Record<string, string>;
};

function updateOriginAndIdMapping(existingParam: TemplateParam, oldToNewIdMapping: any) {
  const uuid = existingParam.origin;
  if (Object.values(oldToNewIdMapping).includes(uuid)) {
    // The param's origin has already been updated to a new Id, we don't have to do anything
  } else if (oldToNewIdMapping[uuid]) {
    // The section id was updated at some point, but this param hasn't updated yet
    // Do it here.
    existingParam.origin = oldToNewIdMapping[uuid];
  } else {
    // The section id hasn't been updated yet, we need to make a new id and update
    // the param's origin, and the mapping
    const newId = makeUniqueId();
    existingParam.origin = newId;
    oldToNewIdMapping[uuid] = newId;
  }
}

function accumulateTemplateParamsForAQSelection(
  contextProps: TemplateContextProps,
  selection: any,
) {
  const { paramCountByAssetType, newTemplateParams, params } = contextProps;
  if (selection?.value?.startsWith('$')) {
    const updateReferences = (newId: string, newName: string) => {
      selection.value = `$${newId}`;
      selection.label = newName;
    };
    addExistingParam(
      contextProps,
      selection.value,
      'dataset',
      () => (params.isUnlocked = true),
      updateReferences,
    );
  } else if (selection?.value?.startsWith('dataset') && !params.isUnlocked) {
    const paramCount = paramCountByAssetType['dataset'] ?? 1;
    const existingValue = newTemplateParams
      ?.filter((param) => 'dataset' === getTemplateAssetTypeFromId(getTemplateParamId(param)))
      ?.find((param) => isEqual(param.value, selection.value));
    const newId = `dataset${paramCount}`;
    const newName = `dataset${paramCount}`;
    if (!existingValue) {
      newTemplateParams.push({
        name: newName,
        id: newId,
        value: selection.value,
        label: undefined,
      });
      selection.label = newName;
      selection.value = `$${newId}`;
      incrementAssetTypeParamCount(contextProps, 'dataset');
    } else {
      selection.label = existingValue.name;
      selection.value = `$${existingValue.id}`;
    }
  }
}

function accumulateTemplateParamsForAQFilters(
  contextProps: TemplateContextProps,
  filters: QueryFilterClause[],
) {
  const { paramCountByAssetType, newTemplateParams } = contextProps;
  if (filters && filters.length > 0) {
    for (let i = 0; i < filters.length; i++) {
      const clause = filters[i];
      // Skip checking the clause if it has no value, since we are trying to handle the value of the clause specifically in this function
      if (clause.value === undefined) {
        continue;
      }
      const filterAssetType = getQueryClauseAssetType(clause);
      const unlockClause = () => (clause.isUnlocked = true);
      if (!Array.isArray(clause.value) && clause.value.startsWith('$')) {
        // check for variable references
        const updateReferences = (newId: string) => {
          clause.value = `$${newId}`;
        };
        addExistingParam(
          contextProps,
          clause.value,
          filterAssetType,
          unlockClause,
          updateReferences,
        );
      } else if (
        Array.isArray(clause.value) &&
        comparatorSupportsOutputVariables(clause.comparator)
      ) {
        // check for is one of outputs
        for (let i = 0; i < clause.value.length; i++) {
          let clauseValue = clause.value[i];
          if (clauseValue.startsWith('$')) {
            // if we find an output, unlock the clause and quit the search
            unlockClause();
            break;
          }
        }
      }
      // Skip any existing template variables, these should already be taken care of.
      // Unlocked clauses in a template will also be skipped here. Locked is the default option (isUnlocked false)
      // For anything else, create new template variables where necessary
      if (clause.isUnlocked || (!Array.isArray(clause.value) && clause.value.startsWith('$'))) {
        continue;
      }
      const existingValue = newTemplateParams
        ?.filter((variable) => getTemplateAssetTypeFromId(variable.id) === filterAssetType)
        ?.find((variable) => isEqual(variable.value, clause.value));
      // If we've already seen this value, just update the section's aq
      if (existingValue) {
        clause.value = `$${existingValue.id}`;
      } else if (clause.comparator !== QueryFilterComparator.Exists) {
        const paramCount = paramCountByAssetType[filterAssetType] ?? 1;
        const newId = `${filterAssetType}${paramCount}`;
        const clauseOption = getFilterClauseOption(clause);
        newTemplateParams.push({
          name: `${getDefaultAssetTypeLabel(filterAssetType)}${paramCount}`,
          id: newId,
          value:
            filterAssetType === 'dataset'
              ? clause.value
              : normalizeFilterClauseValue(clauseOption, clause.value),
          label: undefined,
          clauseType: clause.type,
        });
        clause.value = `$${newId}`;
        incrementAssetTypeParamCount(contextProps, filterAssetType);
      }
    }
  }
}

function accumulateTemplateParamsForAssetPageTile(
  contextProps: TemplateContextProps,
  assetType: string,
) {
  const { paramCountByAssetType, newTemplateParams, params } = contextProps;
  if (params.assetValue?.startsWith('$')) {
    const updateReferences = (newId: string, newName: string) => {
      params.assetValue = `$${newId}`;
      params.assetName = newName;
    };
    addExistingParam(
      contextProps,
      params.assetValue,
      assetType,
      () => (params.isUnlocked = true),
      updateReferences,
    );
  } else if (!params.isUnlocked) {
    const existingValue = newTemplateParams
      ?.filter((variable) => getTemplateAssetTypeFromId(getTemplateParamId(variable)) === assetType)
      ?.find((variable) => variable.value === params.assetValue);
    // If we've already seen this value, just update the section's params
    if (existingValue) {
      params.assetValue = `$${getTemplateParamId(existingValue)}`;
      params.assetName = existingValue.name;
    } else {
      // Otherwise, we need to add a new template var
      const paramCount = paramCountByAssetType[assetType] ?? 1;
      const newId = `${assetType}${paramCount}`;
      const newName = `${getDefaultAssetTypeLabel(assetType)}${paramCount}`;
      newTemplateParams.push({
        name: newName,
        id: newId,
        value: params.assetValue,
        label: undefined,
      });
      params.assetValue = `$${newId}`;
      params.assetName = newName;
      incrementAssetTypeParamCount(contextProps, assetType);
    }
  }
}

function deleteViewConfigForTemplate(contextProps: TemplateContextProps) {
  const { params } = contextProps;
  if (params.config?.viewport) delete params.config.viewport;
  if (params.config?.history)
    params.config.history = { historyEnabled: params.config.history.historyEnabled };
  params.config = { ...params.config, keystone: { ...params?.config?.keystone, expanded: true } };
  if (params.config[params.advancedQuery?.view]?.selected) {
    params.config[params.advancedQuery.view].selected = [];
  }
  if (params.config[params.advancedQuery?.view]?.hideUnselected) {
    params.config[params.advancedQuery.view].hideUnselected = false;
  }
  const viewConfig = params.config?.advancedQuery?.viewConfig;
  if (viewConfig)
    params.config.advancedQuery.viewConfig = {
      ...(viewConfig.columnConfig ? { columnConfig: viewConfig.columnConfig } : undefined),
    };
}

function addExistingParam(
  contextProps: TemplateContextProps,
  searchValue: any,
  assetType: string,
  unlockCallback: Function,
  updateReferences: Function,
) {
  const { existingTemplateParams, paramCountByAssetType, newTemplateParams, oldToNewIdMapping } =
    contextProps;
  let existingParam: TemplateParam & { newlyAddedId?: string };
  if (existingTemplateParams) {
    existingParam = existingTemplateParams.find(
      (param) => `$${getTemplateParamId(param)}` === searchValue,
    );
    if (existingParam.newlyAddedId) {
      // if the param exists in the old template and has already been added to the new template,
      // simply call the update references callback
      updateReferences(existingParam.newlyAddedId, existingParam.newlyAddedId);
    } else {
      // otherwise, we'll have to add the param
      const paramCount = paramCountByAssetType[assetType] ?? 1;
      const newId = `${assetType}${paramCount}`;
      const newName = `${assetType}${paramCount}`;
      newTemplateParams.push({
        ...existingParam,
        id: newId,
        name: newName,
      });
      incrementAssetTypeParamCount(contextProps, assetType);
      updateReferences(newId, newName);
      existingParam.newlyAddedId = newId;
    }
  } else {
    existingParam = newTemplateParams.find(
      (param) => `$${getTemplateParamId(param)}` === searchValue,
    );
  }
  if (isOutputVariable(existingParam)) {
    // For sections referencing output variables, update the existing template param origin
    updateOriginAndIdMapping(existingParam, oldToNewIdMapping);
    unlockCallback();
  }
}

function incrementAssetTypeParamCount(contextProps: TemplateContextProps, assetType: string) {
  const { paramCountByAssetType } = contextProps;
  if (!paramCountByAssetType[assetType]) {
    paramCountByAssetType[assetType] = 2;
  } else {
    paramCountByAssetType[assetType]++;
  }
}

export function createNewParamsAndSectionsForTemplate(
  investigation: UserInvestigation,
  startIndex?: number,
  existingParams?: any,
) {
  let newParams = investigation.templateParams ?? [];
  const removeTemplateParamValues = investigation.removeTemplateParamValues;
  const paramCountByAssetType: Record<string, number> = {};
  // Count the template params for each asset type.
  newParams.forEach((param) => {
    const assetType = getTemplateAssetTypeFromId(getTemplateParamId(param));
    if (paramCountByAssetType[assetType]) {
      paramCountByAssetType[assetType]++;
    } else {
      paramCountByAssetType[assetType] = 2;
    }
  });
  // Create a mapping of old section uuids to new section uuids, so that we
  // can update the origin field in any output variables.
  const oldToNewIdMapping = {};

  // Loop through the sections. For each, add new template params if necessary.
  investigation.sections.forEach((section, index) => {
    if (startIndex != null && index < startIndex) return;
    const [pluginName, params] = parseInvestigationSectionId(section.id);
    const contextProps: TemplateContextProps = {
      existingTemplateParams: existingParams,
      paramCountByAssetType,
      newTemplateParams: newParams,
      oldToNewIdMapping,
      params,
    };

    if (!section.title) section.title = getTemplateSectionTitle(pluginName);
    const assetType = getAssetTypeFromPlugin(pluginName);
    const aq: AdvancedQuery = getConfigAdvancedQuery(params);

    // ADVANCED QUERY CASE
    if (assetType === 'advancedquery' && !!aq) {
      const selection = aq.selection;
      const filters = aq.filterClauses;
      // HANDLE DATASET SELECTIONS
      accumulateTemplateParamsForAQSelection(contextProps, selection);
      // HANDLE FILTER CLAUSES
      accumulateTemplateParamsForAQFilters(contextProps, filters);
      // HANDLE NORMAL ASSET PAGE TILE
    } else if (!isNonDataPlugin(pluginName)) {
      // exclude markdown tiles and primitive tiles
      accumulateTemplateParamsForAssetPageTile(contextProps, assetType);
    }
    deleteViewConfigForTemplate(contextProps);
    // Update this section's uuid if one of its references has already updated it
    // otherwise create a new id now, so that if we encounter any references
    // later on, we'll know what origin to update those to.
    let oldUUID = cloneDeep(params.uuid);
    if (oldToNewIdMapping[params.uuid]) {
      params.uuid = oldToNewIdMapping[params.uuid];
    } else {
      const newId = makeUniqueId();
      oldToNewIdMapping[params.uuid] = newId;
      params.uuid = newId;
    }
    // Check for the old uuid in the template params object. Update where necessary
    const relevantParamIndex = newParams.findIndex((param) => param.origin === oldUUID);
    if (relevantParamIndex >= 0) {
      newParams[relevantParamIndex].origin = params.uuid;
    }
    section.id = toInvestigationSectionId(pluginName, params);
  });
  // Now that all the vars have been initialized, strip the values to give the template a blank slate.
  newParams.forEach((param) => {
    if (isOutputVariable(param) || removeTemplateParamValues) {
      param.value = undefined;
      param.label = undefined;
    }
  });
  investigation.templateParams = newParams;
  return investigation;
}

export function getAssetTypeFromPlugin(plugin: string) {
  if (plugin.startsWith('exchange')) return 'ix';
  return plugin.split('/')[0];
}

export function getAssetTypeLabel(type) {
  let newLabel = getDefaultAssetTypeLabel(type);
  if (['ip', 'asn'].includes(newLabel)) return newLabel.toUpperCase();
  if (newLabel === ComponentPluginName.UserMarkdown) return 'Markdown Tile';
  if (newLabel.includes('advancedquery')) return 'Advanced Search Tile';
  return capitalizeFirstLetter(newLabel);
}

export function getDefaultAssetTypeLabel(assetType: string) {
  let newAssetType = assetType;
  if (assetType === AssetType.IX) newAssetType = 'exchange';
  return newAssetType;
}

export function addSectionToInvestigation(
  investigation: UserInvestigation,
  plugin: ComponentPluginName,
  params: any,
  title: string,
  description?: string,
  saved?: Date,
) {
  investigation.sections.push({
    id: createUniqueInvestigationSectionId(plugin, params),
    title,
    description: description ?? '',
    saved,
  });
}

export function renameTemplateParam(props: any) {
  const { index, name, templateParams, investigation, setLocalTemplateParams, uuid } = props;
  if (!investigation) return;
  if (index < 0 || !templateParams[index]) {
    // check for existing dataset template params
    templateParams.push({
      id: `${name}`,
      origin: uuid,
      value: undefined,
      name,
      label: undefined,
    } as TemplateParam);
  } else {
    templateParams[index] = { ...templateParams[index], name };
    if (!templateParams[index].id && templateParams[index].expression) {
      templateParams[index].id = getTemplateParamId(templateParams[index]);
      templateParams[index].expression = undefined;
    }
  }
  const newIndex = index < 0 ? templateParams.length - 1 : index;
  investigation.sections = propagateTemplateVarUpdate(
    investigation.sections,
    templateParams[newIndex],
    { value: `$${getTemplateParamId(templateParams[newIndex])}`, label: name },
  );
  updateParams(setLocalTemplateParams, templateParams, investigation);
}

export function propagateTemplateVarUpdate(
  sections: UserInvestigationSection[],
  param: any,
  update: any,
) {
  const newSections = cloneDeep(sections);
  const templateParamId = `$${getTemplateParamId(param)}`;
  // every section that shared the template variable now needs to update its asset value and asset name
  for (let i = 0; i < sections.length; i++) {
    const [pluginName, oldParams] = parseInvestigationSectionId(sections[i].id);
    let newParams = { ...oldParams };
    const aq = getConfigAdvancedQuery(newParams);
    if (!!aq) {
      if (isDatasetQuery(aq) && aq.selection && templateParamId === aq.selection?.value) {
        aq.selection.value = update.value;
        aq.selection.label = update.label;
      }
      for (let i = 0; i < aq.filterClauses.length; i++) {
        const clause = aq.filterClauses[i];
        if (
          Array.isArray(clause.value) &&
          clause.value.includes(templateParamId) &&
          !update.value &&
          !update.label
        ) {
          clause.value.splice(clause.value.indexOf(templateParamId), 1);
        } else if (templateParamId === clause.value) {
          clause.value = update.value;
        }
      }
      newParams.advancedQuery = aq;
    } else if ((oldParams.assetValue ?? '') === `$${getTemplateParamId(param)}`) {
      newParams.assetValue = update.value;
      newParams.assetName = update.label;
    }
    newSections[i] = { ...newSections[i], id: toInvestigationSectionId(pluginName, newParams) };
  }
  return newSections;
}

export function hasDatasetQuery(investigation: UserInvestigation) {
  if (!investigation) return false;
  // For a template, first check to see if there are any datasets used in the params.
  // If not, loop through the sections looking for a dataset query with an unlocked dataset.
  if (investigation.isTemplate) {
    if (investigation.removeTemplateParamValues) return false;
    if (
      investigation.templateParams.some((param) => getTemplateParamId(param).startsWith('dataset'))
    ) {
      return true;
    }
    return investigation.sections.some((section) => {
      const [, params] = parseInvestigationSectionId(section.id);
      const aq: AdvancedQuery = getConfigAdvancedQuery(params);
      return aq && sectionContainsDatasetReference(params, investigation.isTemplate);
    });
  }
  return investigation.sections.some((section) => {
    const [, params] = parseInvestigationSectionId(section.id);
    const aq = getConfigAdvancedQuery(params);
    return aq && sectionContainsDatasetReference(params);
  });
}

// Updates the params being used. If the investigation is passed in, it will save those changes. Investigation will not be passed in situations like a shared template, where updates to the params should never be saved, but still can apply to the view (until refresh)
export function updateParams(
  setLocalTemplateParams,
  templateParams: TemplateParams,
  newInvestigation?: UserInvestigation,
) {
  setLocalTemplateParams && setLocalTemplateParams([...templateParams]);
  if (newInvestigation) {
    const newParams = cloneDeep(templateParams);
    // strip var values before saving investigation
    newParams.forEach((param) => {
      if (isOutputVariable(param) || newInvestigation.removeTemplateParamValues) {
        param.value = undefined;
      }
      param.label = undefined;
    });
    newInvestigation.templateParams = newParams;
    saveInvestigation(newInvestigation);
  }
}

export function sortInvestigationByDate(investigations, a, b) {
  const investigationA = investigations[a.value as string];
  const investigationB = investigations[b.value as string];
  const dateA = (investigationA.saved as Date).getTime();
  const dateB = (investigationB.saved as Date).getTime();
  return dateB - dateA;
}

export function findLargestIdNumber(params: TemplateParams, assetType: string) {
  // We need to make sure we don't duplicate param names. Look for the largest number in the format
  // {ASSETTYPE}{NUMBER} and make the new variable {ASSETTYPE}{NUMBER + 1}
  let number = 1;
  params.forEach((param) => {
    const splitName = getTemplateParamId(param)?.split(assetType);
    if (!splitName || splitName.length === 1 || isNaN(parseInt(splitName[1]))) return;
    if (parseInt(splitName[1]) >= number) {
      number = parseInt(splitName[1]) + 1;
    }
  });
  return number;
}

export function getDefaultSectionTitle(title: string, params: Record<string, any>) {
  const assetName = params.assetName || params.assetValue;
  if (assetName && !title.includes(assetName)) {
    return `${title}: ${assetName}`;
  } else {
    return title;
  }
}

export function getDefaultTitle(isTemplate?: boolean) {
  let count = 0;
  Object.values(userInvestigationsVar()).forEach((investigation) => {
    if ((isTemplate && investigation.isTemplate) || (!isTemplate && !investigation.isTemplate)) {
      count++;
    }
  });
  return `${isTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic} ${count + 1}`;
}
// Function to find the number of markdown occurrences of a string
export function countTermInInvestigation(
  term: string,
  replaceInMain: boolean,
  investigation: UserInvestigation,
  selected: Set<string>,
) {
  if (!term) return 0;
  let foundCount = 0;
  const regex = new RegExp(escapeRegExp(term), 'ig');
  if (replaceInMain) {
    foundCount += investigation.description.match(regex)?.length ?? 0;
    foundCount += investigation.title.match(regex)?.length ?? 0;
  }
  for (let i = 0; i < investigation.sections.length; i++) {
    const section = investigation.sections[i];
    const [, params] = parseInvestigationSectionId(section.id);
    if (!selected.has(params.uuid)) continue;
    let sectionDescription = section.description;
    let sectionTitle = section.title;
    foundCount += sectionDescription.match(regex)?.length ?? 0;
    foundCount += sectionTitle.match(regex)?.length ?? 0;
  }
  return foundCount;
}

// Function to increment a heading index with the number of times a heading appears in a MD description
export function addAllHeadingReferencesInDescription(
  text: string,
  description: string,
  headingIndex: number,
) {
  const lines = description.split('\n');
  const regex = new RegExp(/^#{1,6} (.+)$/);
  for (let l = 0; l < lines.length; l++) {
    const nextLine = lines[l].trim();
    const capturingGroup = nextLine.match(regex);
    if (capturingGroup && getAnchorLinkId(capturingGroup[1]) === getAnchorLinkId(text)) {
      headingIndex++;
    }
  }
  return headingIndex;
}

export function focusTile(
  section: UserInvestigationSection,
  focusedTile: UserInvestigationSection,
  setLocalAnnotations: Function,
  setFocusedTile: Function,
  setIsMainFocused: Function,
) {
  const [, params] = parseInvestigationSectionId(section.id);
  if (focusedTile) {
    const [, focusedParams] = parseInvestigationSectionId(focusedTile.id);
    if (focusedParams.uuid === params.uuid) {
      setFocusedTile(undefined);
      return;
    }
  }
  setIsMainFocused(false);
  setLocalAnnotations((curr) => ({
    ...curr,
    focusedTitle: section.title,
    focusedDesc: section.description,
  }));
  setFocusedTile(section);
}

export function toggleMainIsFocused(
  isMainFocused: boolean,
  setIsMainFocused: Function,
  setFocusedTile: Function,
  setLocalAnnotations: Function,
  investigationObj: UserInvestigation,
) {
  if (!isMainFocused) {
    // toggling on
    setLocalAnnotations((curr) => ({
      ...curr,
      mainDesc: investigationObj?.description ?? '',
      mainTitle: investigationObj?.title ?? '',
    }));
  }
  setIsMainFocused((curr) => !curr);
  setFocusedTile(undefined);
}

export function findAndReplaceBySection(
  term: FindAndReplaceTerm,
  investigation: UserInvestigation,
  selected: Set<string>,
  setLocalAnnotations: Function,
  replaceInMain: boolean,
  focusedId: string,
) {
  // Loop through the sections, replace instances of find terms with replace terms.
  const newInvestigation = {
    ...investigation,
    description: cloneDeep(investigation.description),
    sections: cloneDeep(investigation.sections),
  };
  const newLocalAnnotations: LocalAnnotations = {};
  if (!term.find) return;
  const regex = new RegExp(escapeRegExp(term.find), 'ig');
  if (replaceInMain) {
    let newDescription = newInvestigation.description;
    let newTitle = newInvestigation.title;
    newInvestigation.description = newDescription.replaceAll(regex, term.replace);
    newInvestigation.title = newTitle.replaceAll(regex, term.replace);
    newLocalAnnotations.mainDesc = newInvestigation.description;
    newLocalAnnotations.mainTitle = newInvestigation.title;
  }
  newLocalAnnotations.mainDesc = newInvestigation.description;
  newLocalAnnotations.mainTitle = newInvestigation.title;
  for (let i = 0; i < newInvestigation.sections.length; i++) {
    const newSection = newInvestigation.sections[i];
    const [, params] = parseInvestigationSectionId(newSection.id);
    if (!selected.has(params.uuid)) continue;
    let sectionDescription = newSection.description;
    let sectionTitle = newSection.title;
    newSection.description = sectionDescription.replaceAll(regex, term.replace);
    newSection.title = sectionTitle.replaceAll(regex, term.replace);
    if (params.uuid === focusedId) {
      newLocalAnnotations.focusedDesc = newSection.description;
      newLocalAnnotations.focusedTitle = newSection.title;
    }
  }
  setLocalAnnotations(newLocalAnnotations);
  saveInvestigation(newInvestigation);
}

export async function createCrossInstanceParams(
  investigation: UserInvestigation,
  datasets: UserDatasets,
  setProgress?: Function,
  section?: UserInvestigationSection,
  metadata?: any,
) {
  // Don't add all the metadata for a single advanced search section, since it will be converted to a base64 link. We won't warn about it here
  // Possible improvement: just add metadata for the section's specific query.
  if (setProgress) {
    setProgress('0');
  }
  if (section) {
    // If the section has a dataset, we need to query that dataset's members, and add them here.
    const [, params] = parseInvestigationSectionId(section.id);
    const queriedDatasets = await mapIPDatasetToMemberCountInSection(params, datasets);
    const datasetMembers = await findAllDatasetMembersInSection({
      params,
      datasets,
      setProgress,
      queriedDatasets,
    });
    const returnParams = { ...params, metadata: { platformVersion: getVersion() } };
    if (datasetMembers) {
      returnParams.datasets = datasetMembers;
    }
    return returnParams;
  }
  const prunedMetadata = cloneDeep(metadata);
  Object.keys(metadata).forEach((key) => {
    prunedMetadata[key] = { lastUpdated: prunedMetadata[key].lastUpdated };
  });
  const returnParams: any = {
    ...investigation,
    metadata: { platformVersion: getVersion(), dataSlices: prunedMetadata },
    saved: new Date(),
  };

  delete returnParams.pinTag;

  const client = getGraphQLClient();
  if (investigation?.images?.length) {
    // pull down all image data in the investigation
    const imageResult = await client.query({
      query: getImageData,
      variables: { imageIds: investigation.images.map((image) => image.id) },
    });
    if (imageResult.error) {
      setProgress('Warning! We were unable to add some image data to the JSON file.');
    } else if (imageResult?.data?.imageData?.dataObjects?.length) {
      imageResult.data.imageData.dataObjects.forEach((img: any) => {
        if (img.id) {
          const index = investigation.images.findIndex((lightImage) => lightImage.id === img.id);
          if (index >= 0) {
            returnParams.images[index].data = img.data;
          }
        }
      });
    }
  }

  if (hasDatasetQuery(investigation)) {
    let queriedDatasets = {};
    // loop through and add initial counts of each dataset's members to mapping;
    for (let i = 0; i < investigation.sections.length; i++) {
      const section = investigation.sections[i];
      const [, params] = parseInvestigationSectionId(section.id);
      const newMapping = await mapIPDatasetToMemberCountInSection(
        params,
        datasets,
        investigation?.sharing?.shared_key,
        investigation?.templateParams,
      );
      queriedDatasets = { ...queriedDatasets, ...newMapping };
    }
    // Loop through all the sections. If we find a dataset, query for the members and add it to the final cross instance params.
    let datasetMembers = {};
    for (let i = 0; i < investigation.sections.length; i++) {
      const section = investigation.sections[i];
      const [plugin, params] = parseInvestigationSectionId(section.id);
      const newMembers = await findAllDatasetMembersInSection({
        params,
        datasets,
        setProgress,
        sharedKey: investigation?.sharing?.shared_key,
        templateParams: investigation?.templateParams,
        queriedDatasets,
      });
      datasetMembers = { ...datasetMembers, ...newMembers };
      unlinkNonexistentDatasets(params, datasets);
      section.id = toInvestigationSectionId(plugin, params);
      if (newMembers === undefined) break; // break out of the loop on an error so no more progress is set
    }
    if (Object.values(datasetMembers).length) {
      returnParams.datasets = datasetMembers;
    }
  }
  return returnParams;
}

async function findAllDatasetMembersInSection({
  params,
  datasets,
  setProgress,
  sharedKey,
  templateParams,
  queriedDatasets,
}: {
  params: any;
  datasets: UserDatasets;
  setProgress?: Function;
  queriedDatasets?: Record<string, any>;
  templateParams?: TemplateParams;
  sharedKey?: string;
}) {
  let datasetMembers = {};
  const aq: AdvancedQuery = getConfigAdvancedQuery(params);
  if (
    sectionContainsDatasetReference(params) ||
    sectionContainsDatasetParam(params, templateParams)
  ) {
    let activeDatasets = Object.values(datasets).filter((dataset) => {
      if (
        aq?.selection?.value === dataset.id ||
        templateParams?.some(
          (templateParam) =>
            `$${getTemplateParamId(templateParam)}` === aq?.selection?.value &&
            templateParam.value === dataset.id,
        )
      ) {
        return true;
      }
      return aq?.filterClauses?.some(
        (clause) =>
          clause.comparator === QueryFilterComparator.IsInDataset &&
          (clause.value === dataset.id ||
            templateParams?.some(
              (templateParam) =>
                templateParam.value === dataset.id &&
                `$${getTemplateParamId(templateParam)}` === clause.value,
            )),
      );
    });
    for (let i = 0; i < activeDatasets.length; i++) {
      const dataset = cloneDeep(activeDatasets[i]);
      // As we expand to other surfaces and data types, we'll need a mapping of typename to callback or query
      // For now, just check for ip datasets and make sure we haven't already looked at it yet
      const dataId = dataset?.system?.ipsDataId;
      if (
        dataId &&
        dataset.typename === 'ips' &&
        !Object.keys(datasetMembers).includes(dataset.id) &&
        !queriedDatasets[dataset.id]?.seen
      ) {
        const client = getGraphQLClient();
        let runningIps = [];
        const combinedDatasetSize = Object.values(queriedDatasets)
          .map((dataset) => dataset?.totalCount ?? 0)
          ?.reduce((prev, curr) => prev + curr);
        const relativeSize = queriedDatasets[dataset.id]?.totalCount / combinedDatasetSize;
        let cursor = undefined;
        let firstQuery = true;
        let totalCount = 0;
        const ipsDataId = dataset?.system?.ipsDataId;
        const ipMemberQuery = gql`query IpsQuery($sharedKey: String, $size: Int,$cursor: String) {
            ips(sharedKey: $sharedKey, dataId: "${ipsDataId}",size: $size,cursor: $cursor) {
              totalCount
              nextCursor
              items {
                ip
              }
            }
          }`;
        while (runningIps.length < totalCount || firstQuery) {
          const memberQueryResult = await client.query({
            query: ipMemberQuery as DocumentNode,
            variables: { size: 10000, sharedKey, cursor },
            // do not cache enormous sets of ips, apollo will cause the DOM to choke when fetching them all from local storage,
            // and we won't be able to give the user any feedback
            fetchPolicy: totalCount < 500000 ? 'cache-first' : 'no-cache',
          });
          const items = memberQueryResult?.data?.ips?.items ?? [];
          runningIps = runningIps.concat(items);
          if (firstQuery) firstQuery = false;
          if (!totalCount) totalCount = memberQueryResult?.data?.ips?.totalCount;
          cursor = memberQueryResult?.data?.ips?.nextCursor;
          if (memberQueryResult.errors) {
            setProgress(
              'Warning! Some datasets were not properly added to the JSON. This is an unexpected error, please try again.',
            );
            return undefined;
          }
          const additionalPercent = items?.length / totalCount;
          setProgress((curr) => `${parseFloat(curr) + additionalPercent * relativeSize * 100}`);
        }
        datasetMembers[dataset.id] = { ...dataset, items: runningIps.map((item) => item.ip) };
        delete datasetMembers[dataset.id]?.system;
        delete datasetMembers[dataset.id]?.saved;
        delete datasetMembers[dataset.id]?.dataId;
        delete datasetMembers[dataset.id]?.id;
      } else if (dataset.typename === 'pcap') {
        // We can't currently handle pcap files, just strip them
        if (aq?.selection?.value === dataset.id) {
          aq.selection.value = undefined;
          aq.selection.label = undefined;
        }
        for (let i = 0; i < aq.filterClauses.length; i++) {
          const clause = aq.filterClauses[i];
          if (
            clause.comparator === QueryFilterComparator.IsInDataset &&
            clause.value === dataset.id
          ) {
            clause.value = undefined;
          }
        }
      }
      if (queriedDatasets)
        queriedDatasets[dataset.id] = { ...queriedDatasets[dataset.id], seen: true };
    }
  }
  return datasetMembers;
}

function unlinkNonexistentDatasets(params: any, datasets: UserDatasets) {
  const aq = getConfigAdvancedQuery(params);
  const mainDataset = aq?.selection?.value;
  // if the selection is not a locked template id, and the dataset doesn't exist, it's our responsibility
  // to unlink the dataset
  if (mainDataset && mainDataset.startsWith('dataset') && !datasets[mainDataset]) {
    aq.selection.value = undefined;
    aq.selection.label = undefined;
  }
  for (let i = 0; i < aq?.filterClauses?.length ?? 0; i++) {
    const clause = aq.filterClauses[i];
    if (
      clause.comparator === QueryFilterComparator.IsInDataset &&
      !!clause.value &&
      clause.value.startsWith('dataset') &&
      !datasets[clause.value]
    ) {
      clause.value = undefined;
    }
  }
}

export function findAndReplaceDatasetInCrossInstanceParams(
  oldDatasetId: string,
  newDatasetId: string,
  params: any,
) {
  // check if the params are in the form of sections or a single section
  // If multiple sections, loop over all sections, otherwise find and replace datasets in that section
  if (params.sections) {
    for (let i = 0; i < params.sections.length; i++) {
      let section = params.sections[i];
      const [plugin, sectionParams] = parseInvestigationSectionId(section.id);
      params.sections[i].id = toInvestigationSectionId(
        plugin,
        findAndReplaceDatasetInSection(oldDatasetId, newDatasetId, sectionParams),
      );
    }
  } else {
    params = findAndReplaceDatasetInSection(oldDatasetId, newDatasetId, params);
  }
  // Finally delete the old dataset id from the datasets collection.
  if (params.datasets[oldDatasetId]) {
    params.datasets[newDatasetId] = cloneDeep(params.datasets[oldDatasetId]);
    delete params.datasets[oldDatasetId];
  }
  return params;
}

function findAndReplaceDatasetInSection(oldDatasetId: string, newDatasetId: string, params: any) {
  // First check for selection
  const aq: AdvancedQuery = getConfigAdvancedQuery(params);
  if (aq?.selection?.value === oldDatasetId) {
    aq.selection.value = newDatasetId;
    aq.selection.label = undefined;
  }
  // Now check for is in dataset filter clauses
  for (let i = 0; i < aq?.filterClauses.length; i++) {
    const fc = aq.filterClauses[i];
    if (fc.comparator === QueryFilterComparator.IsInDataset && fc.value === oldDatasetId) {
      fc.value = newDatasetId;
    }
  }
  return params;
}

async function mapIPDatasetToMemberCountInSection(
  params: any,
  datasets: UserDatasets,
  sharedKey?: string,
  templateParams?: TemplateParams,
) {
  const aq: AdvancedQuery = getConfigAdvancedQuery(params);
  let activeDatasets = Object.values(datasets).filter((dataset) => {
    if (aq?.selection?.value === dataset.id) return true;
    if (
      templateParams &&
      templateParams.some((templateParam) => templateParam.value === dataset.id)
    )
      return true;
    return aq?.filterClauses?.some(
      (clause) =>
        clause.comparator === QueryFilterComparator.IsInDataset && clause.value === dataset.id,
    );
  });
  let mapping: any = {};
  for (let i = 0; i < activeDatasets.length; i++) {
    const dataset = cloneDeep(activeDatasets[i]);
    if (!dataset?.system?.ipsDataId && dataset.typename === 'ips') continue;
    const dataId = dataset?.system?.ipsDataId;
    const ipMemberQuery = gql`query IpsQuery($sharedKey: String, $size: Int, $cursor: String) {
            ips(sharedKey: $sharedKey, dataId: "${dataId}",size: $size,cursor: $cursor) {
              totalCount
            }
          }`;
    const client = getGraphQLClient();
    const memberQueryResult = await client.query({
      query: ipMemberQuery as DocumentNode,
      variables: { sharedKey },
    });
    const totalCount = memberQueryResult?.data?.ips?.totalCount;
    if (memberQueryResult.errors) {
      return undefined;
    }
    mapping[dataset.id] = { totalCount };
  }
  return mapping;
}

export function copyTilesToMosaic(
  copiedSections: UserInvestigationSection[],
  toCopyMosaic: any,
  newMosaicTitle: string,
  newImages: Images,
  id?: string,
) {
  if (!toCopyMosaic) return;
  const existingTemplateParams = cloneDeep(getCurrentInvestigation()?.templateParams);
  const investigations = userInvestigationsVar();
  const outputVariablesToKeep = [];

  // Create a new unique id for the copied sections
  for (let i = 0; i < copiedSections.length; i++) {
    const [pluginName, params] = parseInvestigationSectionId(copiedSections[i].id);
    // Check for shared datasets in the params and remove them.
    if (getConfigAdvancedQuery(params) && isViewOnly()) {
      const aq = getConfigAdvancedQuery(params);
      if (aq?.selection?.value?.startsWith('dataset')) {
        aq.selection.value = undefined;
        aq.selection.label = undefined;
      }
      for (let i = 0; i < aq?.filterClauses?.length ?? 0; i++) {
        const filter: QueryFilterClause = aq.filterClauses[i];
        if (
          filter.comparator === QueryFilterComparator.IsInDataset &&
          typeof filter?.value === 'string' &&
          filter?.value.startsWith('dataset')
        ) {
          filter.value = undefined;
        }
      }
    }
    const sectionOutputVariable = existingTemplateParams?.find((etp) => {
      return etp.origin === params.uuid;
    });
    if (!isTemplate()) {
      // Only create a unique id for the section if we are copying to a mosaic.
      // uuid creation is handled in createNewParamsAndSectionsForTemplates otherwise
      copiedSections[i].id = createUniqueInvestigationSectionId(pluginName, params);
      // since UUID creation is being done here, we need to do the origin reassignment on output variables immediately before the oldUUID -> newUUID relation is lost

      if (sectionOutputVariable) {
        const [, newParams] = parseInvestigationSectionId(copiedSections[i].id);
        sectionOutputVariable.origin = newParams.uuid;
        outputVariablesToKeep.push(sectionOutputVariable);
      }
    } else if (sectionOutputVariable) {
      outputVariablesToKeep.push(sectionOutputVariable);
    }
  }

  // Once we know what output variables we want to keep, we need to prune the rest from the copied sections. The scenario we wish to avoid that calls for performing this pruning is for when a copied tile using an output variable has the same ID of another output variable that already exists on the target mosaic, which would link together two tiles that never should have been linked.
  const ovtkIds = outputVariablesToKeep.map((ovtk: TemplateParam) => {
    return `$${ovtk.id}`;
  });
  for (let i = 0; i < copiedSections.length; i++) {
    const [pluginName, params] = parseInvestigationSectionId(copiedSections[i].id);
    const aq = getConfigAdvancedQuery(params);
    for (let j = 0; j < aq?.filterClauses?.length ?? 0; j++) {
      const filterValues = aq.filterClauses[j].value;
      if (Array.isArray(filterValues)) {
        aq.filterClauses[j].value = filterValues.filter((value: string) => {
          if (value.startsWith('$')) {
            return ovtkIds.includes(value);
          }
          return true;
        });
      } else {
        // if filterValue.value is not an array, do nothing as output variables never are used in a non-array
      }
      // if the length changed, we know something got filtered out, which means we need to update the section id
      // note: we need to guard against filterValues being possibly undefined due to exists comparator
      if (filterValues && filterValues.length > aq.filterClauses[j].value?.length) {
        params.advancedQuery = aq;
        copiedSections[i] = {
          ...copiedSections[i],
          id: toInvestigationSectionId(pluginName, params),
        };
      }
    }
  }

  let newInvestigation: UserInvestigation | undefined;

  if (toCopyMosaic.value === 'newMosaic') {
    newInvestigation = {
      id,
      sections: copiedSections,
      title: newMosaicTitle,
      subtitle: '',
      description: '',
      isTemplate: isTemplate(),
      images: newImages,
      templateParams: outputVariablesToKeep,
    };
    // create template variables from copied sections.
    if (isTemplate())
      newInvestigation = createNewParamsAndSectionsForTemplate(
        newInvestigation,
        undefined,
        existingTemplateParams,
      );
  } else {
    newInvestigation = investigations[toCopyMosaic.value];
    let newInvestigationTemplateParams = newInvestigation.templateParams;
    // if we have output variables to persist, we need to ensure that the ids do not clash with existing ones on the target mosaic, since the template params IDs follow a convention of "{assetType}{number}", where the number is incremented, and not unique like UUIDv4
    if (outputVariablesToKeep.length > 0) {
      outputVariablesToKeep.forEach((ovtk: TemplateParam) => {
        const oldId = ovtk.id;
        const assetType = oldId.split('-set')[0] + '-set';
        const newId = `${assetType}${findLargestIdNumber(newInvestigationTemplateParams, assetType)}`;
        ovtk.id = newId;
        ovtk.name = ovtk.name + ' (copy)'; // follow mosaic copy renaming standard
        // update references if the ID changed due to clashing
        if (newId !== oldId) {
          for (let i = 0; i < copiedSections.length; i++) {
            const [pluginName, sectionParams] = parseInvestigationSectionId(copiedSections[i].id);
            const aq = getConfigAdvancedQuery(sectionParams);
            let updateSectionId = false;
            for (let j = 0; j < aq.filterClauses.length; j++) {
              const filterClauseValue = aq.filterClauses[j].value;
              if (Array.isArray(filterClauseValue)) {
                const index = filterClauseValue.findIndex((fcv: string) => {
                  return fcv === `$${oldId}`;
                });
                if (index > -1) {
                  updateSectionId = true;
                  aq.filterClauses[j].value[index] = `$${newId}`;
                }
              }
            }
            // if we did end up updating a reference, we need to update the section id
            if (updateSectionId) {
              sectionParams.aq = aq;
              copiedSections[i] = {
                ...copiedSections[i],
                id: toInvestigationSectionId(pluginName, sectionParams),
              };
            }
          }
        }
        // finally, add the updated output variable to the template params
        newInvestigationTemplateParams.push(ovtk);
      });
    }
    const startIndex = newInvestigation.sections.length; // the original section length of the target investigation so we know which index of the concatenated investigation sections to check to add template params for
    newInvestigation = {
      ...newInvestigation,
      images: [...(newInvestigation.images ?? []), ...newImages],
      sections: [...newInvestigation.sections].concat(copiedSections),
    };
    // if it's a template, we need to create new template params for the copied sections
    if (isTemplate())
      newInvestigation = createNewParamsAndSectionsForTemplate(
        newInvestigation,
        startIndex,
        existingTemplateParams,
      );
  }
  saveInvestigation(newInvestigation);
  return newInvestigation;
}

// Determine if the section references any datasets which do not exist
export function sectionHasMissingDataset(section: UserInvestigationSection) {
  const userDatasets = userDatasetsVar();
  const userSharedDatasets = userSharedDatasetsVar();
  const datasets = { ...userDatasets, ...userSharedDatasets };
  const [, params] = parseInvestigationSectionId(section.id);
  const aq: AdvancedQuery | undefined = getConfigAdvancedQuery(params);
  if (aq?.selection?.value?.startsWith('dataset')) {
    if (!datasets[aq?.selection?.value]) {
      return true;
    }
  }
  return aq?.filterClauses?.some((clause) => {
    if (
      clause.comparator === QueryFilterComparator.IsInDataset &&
      typeof clause.value === 'string' &&
      clause.value.startsWith('dataset')
    ) {
      return !datasets[clause.value];
    }
    return false;
  });
}

export function loadMosaicTableOptions(
  filterString: string,
  tagOptions: any,
  investigations: UserInvestigation[],
  collectionString: InvestigationTypes,
  isGallery: boolean,
) {
  if (!filterString) return [];
  const newOptions = [];
  const tags = orderBy(
    tagOptions?.filter((option) => option?.value?.toLowerCase()?.includes(filterString)),
    'count',
    'desc',
  );
  if (tags.length > 0) newOptions.push({ label: 'Tags', options: tags });
  let investigationOptions = [];
  investigations.forEach((investigation) => {
    const { title, description, sections, isTemplate } = investigation;
    const investigationType = isTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic;
    if (collectionString !== investigationType) {
      return;
    }
    const definedTitle = title ?? '';
    const definedDescription = description ?? '';
    let fullSearchString = `${definedTitle} ${definedDescription}`;
    sections?.forEach((section) => {
      fullSearchString += ` ${section.title} ${section.description}`;
    });
    if (fullSearchString.toLowerCase().includes(filterString.toLowerCase())) {
      investigationOptions.push({
        label: investigation.title,
        value: investigation.title.toLowerCase(),
        searchString: filterString.toLowerCase(),
        url: isGallery
          ? investigation?.sharing?.shared_key
            ? `/shared?key=${investigation?.sharing?.shared_key}`
            : undefined
          : `/${investigation.isTemplate ? 'template' : 'mosaic'}?id=${investigation.id}`,
      });
    }
  });
  investigationOptions.sort((a, b) => {
    if (a?.value?.includes(a?.searchString)) return -1;
    if (b?.value?.includes(b?.searchString)) return 1;
    return 0;
  });
  if (investigationOptions.length > 0)
    newOptions.push({ label: `${collectionString}s`, options: investigationOptions });
  return newOptions;
}

export function curateMosaicTableData(
  filterOption: any,
  investigations: any,
  collection: string,
  useValue?: boolean,
) {
  if (filterOption?.value) {
    return Object.values(investigations)
      .filter((data: any) => {
        const investigation = useValue ? data.value : data;
        const filterValue = filterOption?.value.toLowerCase();
        const isTagFilter = filterOption?.count;
        const isTagMatch = investigation?.tags?.find((tag) => tag.name === filterValue);
        const { title, description, sections } = investigation;
        const definedTitle = title ?? '';
        const definedDescription = description ?? '';
        let fullSearchString = `${definedTitle} ${definedDescription}`;
        sections?.forEach((section) => {
          fullSearchString += ` ${section.title} ${section.description}`;
        });
        const isStringMatch = fullSearchString?.toLowerCase().includes(filterValue);
        return isTagFilter ? isTagMatch : isTagMatch || isStringMatch;
      })
      .filter((data: any) => {
        const investigation = useValue ? data.value : data;
        return collection === 'mosaics' ? !investigation?.isTemplate : investigation?.isTemplate;
      });
  }
  return Object.values(investigations).filter((data: any) => {
    const investigation = useValue ? data.value : data;
    return collection === 'mosaics' ? !investigation?.isTemplate : investigation?.isTemplate;
  });
}
