import React, { useRef, useCallback } from 'react';
import { formatDate } from 'utils';
import UIkit from 'uikit';
import { ReactComponent as ChevronSouth } from 'svg/direction/chevron-south.svg';

export type DatePickerDate = {
  date: string;
  magnitude?: number;
};

type DatePickerProps = {
  selected: string;
  dates: DatePickerDate[];
  className?: string;
  onChangeDate: Function;
  icon?: any;
};

export function DatePicker({ selected, dates, className, onChangeDate, icon }: DatePickerProps) {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const changeDateCallback = useCallback(
    (date: string) => {
      onChangeDate(date);
      UIkit.dropdown(dropdownRef.current).hide(false);
    },
    [onChangeDate],
  );

  return (
    <div className={className ?? 'default-date-picker'}>
      <button>
        {icon}
        {formatDate(selected)}
        <ChevronSouth className="uk-margin-auto-left" />
      </button>
      <div
        ref={dropdownRef}
        data-uk-dropdown="mode: click; pos: bottom-left"
        className="tile-menu app-date-picker-dropdown uk-margin-remove-top"
      >
        <ul className="uk-nav uk-dropdown-nav">
          {dates.map((dateObj: Record<string, any>) => {
            const { date, magnitude } = dateObj;
            return (
              <li key={date} className="tile-menu-item" onClick={() => changeDateCallback(date)}>
                {formatDate(date)}
                {magnitude !== undefined && (
                  <span style={{ paddingLeft: 4 }} className="uk-margin-auto-left">
                    {magnitude}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
