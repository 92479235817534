import { useContext, useEffect, useRef, useState } from 'react';
import { DocumentNode } from 'graphql';
import { QueryHookOptions } from '@apollo/client';
import { ComponentPluginContext } from 'appContexts';
import { useComponentPluginQuery } from 'hooks';
import { isEqual, omit } from 'lodash';

export function usePluginHistory(
  query: DocumentNode,
  options?: QueryHookOptions,
  addMissing?: boolean,
) {
  const { getPluginConfig, updatePluginConfig, setConfigOptions } =
    useContext(ComponentPluginContext);
  const { historyEnabled, dateA, dateB } = getPluginConfig()?.history || {};
  const datesRef = useRef<string[] | undefined>();
  const [dates, setDates] = useState<string[] | undefined>();

  // Initialize or reset history config when history is toggled or dates changes (likely due to assetValue changing)
  useEffect(() => {
    if (dates) {
      if (
        historyEnabled &&
        ((!dateA && !dateB) || (datesRef.current && datesRef.current !== dates))
      ) {
        const newConfig = {
          ...getPluginConfig(),
          history: {
            ...getPluginConfig()?.history,
            dateA: getPluginConfig()?.dateA || dates[1] || dates[0],
            dateB: getPluginConfig()?.dateB || dates[0],
          },
        };
        !isEqual(newConfig, getPluginConfig()) && updatePluginConfig(newConfig);
      } else if (!historyEnabled) {
        const myConfig = { ...getPluginConfig() };
        delete myConfig?.history?.dateA;
        delete myConfig?.history?.dateB;
        delete myConfig?.history?.historyEnabled;
        !isEqual(myConfig, getPluginConfig()) && updatePluginConfig(myConfig);
      }
      datesRef.current = dates;
    }
  }, [dateA, dateB, dates, getPluginConfig, historyEnabled, updatePluginConfig]);

  // Fetch available dates
  const { data: datesData, loading } = useComponentPluginQuery(query, options);

  // Compiles array of dates for date picker
  useEffect(() => {
    if (datesData && !loading) {
      const newDates: Set<string> = new Set([]);
      const omittedFields = ['firstSeenTime', 'lastLookupTime', 'latestData'];
      const dateItems = datesData?.dates?.items;
      dateItems?.forEach((item: any, index: number) => {
        item?.firstSeenTime && newDates.add(item?.firstSeenTime);
        const nextDate = dateItems[index + 1];
        if (nextDate && addMissing) {
          const currentDateOmit = omit(item, omittedFields);
          const nextDateOmit = omit(nextDate, omittedFields);
          const missingDate = new Date(nextDate?.lastLookupTime);
          missingDate.setDate(missingDate.getDate() + 1);
          isEqual(currentDateOmit, nextDateOmit) && newDates.add(missingDate.toString());
        }
      });

      const newDatesArray = [...newDates];
      const firstDateItems = dateItems.filter(
        (item: any) => item?.firstSeenTime === newDatesArray[0],
      );
      // Latest stale
      if (firstDateItems.length === 1 && firstDateItems[0].latestData === false) {
        if (addMissing) {
          const staleDate = new Date(firstDateItems[0]?.lastLookupTime);
          staleDate.setDate(staleDate.getDate() + 1);
          // Infer date when data disappeared
          newDatesArray.unshift(staleDate.toString());
        } else {
          newDatesArray.unshift('latest');
        }
      }

      setDates((current) => {
        if (!isEqual(current, newDatesArray)) {
          return newDatesArray;
        }
        return current;
      });
    }
  }, [addMissing, datesData, loading, setDates]);

  useEffect(() => {
    const datesA = dates?.map((date) => ({ date }));
    const datesB = dates?.map((date) => ({ date }));

    const newOptions = {
      reference: datesA,
      comparer: datesB,
    };

    setConfigOptions((current) => {
      if (!isEqual(current, newOptions)) {
        return newOptions;
      }
      return current;
    });
  }, [dates, dateA, dateB, setConfigOptions]);
}
