import { getItemColor, getItemOpacity, HistogramItem, HistogramSettings } from './Histogram';
import { select } from 'd3';
import { scaleBand, scaleLinear } from 'd3-scale';
import { abbreviatedCount } from 'utils';

export function drawHistogram(
  container: HTMLElement,
  items: HistogramItem[],
  settings: HistogramSettings,
  appWidth: number,
) {
  const containerSel = select(container);
  const activeMetrics = settings?.activeMetrics;
  const margins = settings?.margin;
  const valueMarginX = settings?.valueMarginX;
  const valueMarginY = settings?.valueMarginY;
  const itemHeight = settings?.itemHeight;
  const width = getHistoWidth(settings, appWidth);
  const height = itemHeight * items.length;

  containerSel.select('svg').remove();

  const svg = containerSel
    .append('svg')
    .attr('width', width + margins.left + margins.right)
    .attr('height', height + margins.top + margins.bottom)
    .style('overflow', 'visible')
    .style('height', height + margins.top + margins.bottom)
    .append('g')
    .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')')
    .attr('class', 'histogram');

  let domainMax;
  if (isPercentData(activeMetrics)) {
    domainMax = 100;
  } else {
    domainMax = Math.max(...items.map((d) => Object.values(d.values)[0]));
  }
  const xScale = scaleLinear().domain([0, domainMax]).range([0, width]);

  // Add Y axis
  const yScale = scaleBand<number>()
    .range([valueMarginY, height + valueMarginY])
    .domain(items.map((d, index) => index));

  const yAxis = svg.append('g').attr('class', 'y-axis uk-text-small');

  yAxis.selectAll('.domain').remove();

  // Data Column Headers
  svg
    .append('g')
    .selectAll('text')
    .data(activeMetrics)
    .join('text')
    .attr('text-anchor', 'end')
    .attr('x', (d) => {
      const i = activeMetrics?.indexOf(d);
      return i != null ? width - valueMarginX * i : null;
    })
    .attr('y', 0)
    .attr('dy', '0.35em')
    .attr('class', 'histogram-bar-value')
    .text((d) => d.toLocaleUpperCase());

  const barTopMargin = 12;
  const barHeight = 5;
  const shadowHeight = 3;
  const barRoundCornerRadius = 3;

  // Bar Numeric Values
  settings?.activeMetrics.forEach((metric, index) => {
    svg
      .append('g')
      .selectAll('text')
      .data(items)
      .join('text')
      .style('opacity', (d, i) => getItemOpacity(d, settings, i))
      .attr('class', 'uk-text-muted histogram-bar-value')
      .attr('text-anchor', 'end')
      .attr('x', width - valueMarginX * index)
      .attr('y', (d, i) => yScale(i) as number)
      .attr('dy', '0.35em')
      .text((d) => {
        return d?.valuesFormatted[metric] ?? abbreviatedCount(d.values[metric]);
      });
  });

  // Shadows
  svg
    .selectAll('bar')
    .append('g')
    .data(items)
    .enter()
    .append('rect')
    .style('fill', '#bcbec0')
    .style('opacity', (d, i) => getItemOpacity(d, settings, i) * 0.5)
    .attr('rx', barRoundCornerRadius)
    .attr('ry', barRoundCornerRadius)
    .attr('opacity', 0.5)
    .attr('x', 0)
    .attr('y', (d, i) => (yScale(i) as number) + barTopMargin + (barHeight - shadowHeight) / 2)
    .attr('height', shadowHeight)
    .attr('width', width);

  // Bars
  svg
    .selectAll('bar')
    .append('g')
    .data(items)
    .enter()
    .append('rect')
    .style('fill', (d, i) => getItemColor(d, settings, i))
    .style('opacity', (d, i) => getItemOpacity(d, settings, i))
    .attr('rx', barRoundCornerRadius)
    .attr('ry', barRoundCornerRadius)
    .attr('x', 0)
    .attr('y', (d, i) => (yScale(i) as number) + barTopMargin)
    .attr('height', barHeight)
    .attr('width', (d) => {
      return isPercentData(activeMetrics)
        ? xScale(d.values.pct ?? d.values.percent)
        : xScale(Object.values(d.values)[0]);
    });
}

function isPercentData(activeMetrics: string[]) {
  // TODO: This matching could be more robust (neglects capitalization)
  return activeMetrics?.includes('pct') || activeMetrics?.includes('percent');
}

export function getHistoWidth(settings: HistogramSettings, appWidth: number) {
  return Math.min(settings?.width - settings?.margin?.left - settings?.margin?.right, appWidth);
}
