import React, { Fragment, useContext, useMemo } from 'react';
import { getCountryCode, getCountryName, getCountryRegion, toInvestigationSectionId } from 'utils';
import { useCountryQuery } from 'hooks';
import { ComponentPluginName, DisplayElement } from 'components';
import ReportTitle from 'components/ReportTitle';
import { AssetInfoContext } from 'appContexts';
import { UserInvestigationSection } from '../../model';
import InvestigationSection from '../investigations/InvestigationSection';

export default function CountryInfo({ assetValue }: { assetValue: string }) {
  assetValue = getCountryCode(assetValue);
  const { asset } = useCountryQuery(assetValue);
  const assetName = getCountryName(assetValue);
  const { displayElements } = useContext(AssetInfoContext);
  const section: UserInvestigationSection = useMemo(
    () => ({
      id: toInvestigationSectionId(ComponentPluginName.CountryMetrics, { assetValue }),
      title: 'Rankings',
      description: '',
    }),
    [assetValue],
  );

  return (
    <Fragment>
      {displayElements?.includes(DisplayElement.Title) && (
        <ReportTitle title={assetName} subtitle={getCountryRegion(assetValue)} label="Country" />
      )}
      {asset && (
        <Fragment>
          {displayElements?.includes(DisplayElement.About) && (
            <InvestigationSection section={section} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
