import { makeVar, ReactiveVar } from '@apollo/client';
import { UserDataSection, UserDatasets } from './userDataTypes';
import { userDatasetsVar } from './userData';
import { getImportSuccessfulMessage, preventNavigationInterrupt, USER_DATASET_STATUS } from 'utils';

export type UserNotification = {
  style?: Record<string, string>;
  title?: string;
  contentClass?: string;
  message: string | JSX.Element;
  alertType?: ALERT_TYPES;
  onClear?: any;
  icon?: string | JSX.Element;
  noClose?: boolean;
};

export enum ALERT_TYPES {
  primary = 'primary',
  warning = 'warning',
  error = 'danger',
  success = 'success',
  placeholder = 'placeholder',
}

export type UserNotifications = Record<string, UserNotification>;
export const userNotifications = makeVar<UserNotifications>({});
export const NotificationHandlers: Record<UserDataSection, any> = {
  [UserDataSection.AdvancedQueries]: undefined,
  [UserDataSection.Datasets]: handleDatasetsChange,
  [UserDataSection.Tags]: undefined,
  [UserDataSection.Investigations]: undefined,
  [UserDataSection.SearchHistory]: undefined,
};

let initialized = false;

export function initializeUserNotifications() {
  if (initialized) {
    return;
  }
  initialized = true;

  initNotifications<UserDatasets>(UserDataSection.Datasets, userDatasetsVar);
}

function initNotifications<T>(section: UserDataSection, reactiveVar: ReactiveVar<T>) {
  const notificationHandler = NotificationHandlers[section];
  if (!notificationHandler) return;
  function onVarChange() {
    notificationHandler();
    reactiveVar.onNextChange(onVarChange);
  }
  notificationHandler();
  reactiveVar.onNextChange(onVarChange);
}

export function createGenericNotification(
  notificationName: string,
  notification: UserNotification,
) {
  const clearableNotification = notification;
  if (!clearableNotification.onClear) {
    clearableNotification.onClear = () => acknowledgeNotification(notificationName);
  }
  userNotifications({
    [notificationName]: clearableNotification,
    ...userNotifications(),
  });
}

export function acknowledgeNotification(notificationName: string) {
  const newNotifications = { ...userNotifications() };
  delete newNotifications[notificationName];
  userNotifications(newNotifications);
}

export function markDatasetRead(id: string) {
  const datasets = userDatasetsVar();
  const dataset = datasets[id];
  if (!dataset) {
    return;
  }
  const status = dataset.system?.status;
  const newDataset = { ...dataset, system: { ...dataset.system, lastStatusRead: status } };
  const newDatasets = { ...datasets, [id]: newDataset };
  userDatasetsVar(newDatasets);
  acknowledgeNotification(id);
}

export function handleDatasetsChange() {
  const datasets = userDatasetsVar();
  let notifications: UserNotifications = { ...userNotifications() };
  let showUnloadWarning = false;
  const preventFileUploadInterrupt = (e: BeforeUnloadEvent) => preventNavigationInterrupt(e);
  Object.values(datasets).forEach((dataset) => {
    if (dataset.id == null) return;
    const id: string = dataset.id;
    const lastStatusRead = dataset?.system?.lastStatusRead;
    const status = dataset?.system?.status;
    let datasetCreatedInSession = window.sessionStorage.getItem(dataset.id) != null;
    const isFromBulkImport =
      window.sessionStorage.getItem(dataset.id) &&
      window.sessionStorage.getItem(dataset.id).endsWith('-bulk');
    if (status === lastStatusRead) return;
    if (status === USER_DATASET_STATUS.FileImported) {
      const newDatasets = {
        ...datasets,
        [id]: { ...dataset, system: { ...dataset.system, hidden: false } },
      };
      userDatasetsVar(newDatasets);
      if (!isFromBulkImport) {
        notifications[dataset.id] = {
          alertType: ALERT_TYPES.success,
          message: getImportSuccessfulMessage(dataset),
          title: 'Data upload complete',
          onClear: () => markDatasetRead(id),
        };
      }
    } else if (status === USER_DATASET_STATUS.Error) {
      notifications[dataset.id] = {
        alertType: ALERT_TYPES.error,
        message: `An error occurred while uploading ${dataset?.filename}`,
        title: 'Data Upload Failed',
        onClear: () => markDatasetRead(id),
      };
    } else if (
      status === USER_DATASET_STATUS.FileUploading &&
      !showUnloadWarning &&
      datasetCreatedInSession
    ) {
      window.addEventListener('beforeunload', preventFileUploadInterrupt);
      showUnloadWarning = true;
    }
    if (isFromBulkImport) {
      markDatasetRead(id);
    }
  });
  if (!showUnloadWarning) {
    window.removeEventListener('beforeunload', preventFileUploadInterrupt);
  }
  userNotifications(notifications);
}
