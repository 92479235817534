import { select } from 'd3';
import { scaleLinear } from 'd3-scale';
import { drag } from 'd3-drag';

export type FilterSelection = {
  upperBound: number | undefined;
  lowerBound: number | undefined;
  tempUpperBoundRef: any;
  tempLowerBoundRef: any;
  setUpperBound: any;
  setLowerBound: any;
};

export function drawHistogramFilter(
  container: HTMLElement,
  bins: any[],
  interval: string,
  width: number,
  height: number,
  margins: any,
  selection: FilterSelection,
  intervalFormatFunction?: any,
  id?: string,
) {
  const docStyle = getComputedStyle(document.documentElement);
  const activeBarColor = docStyle.getPropertyValue('--app-primary-color');
  const inactiveBarColor = docStyle.getPropertyValue('--app-secondary-color');

  const {
    upperBound,
    lowerBound,
    setUpperBound,
    setLowerBound,
    tempLowerBoundRef, // temp bound refs are for real time dragging events like updating color and size
    tempUpperBoundRef, // setting them does not execute a filter change.
  } = selection;
  const lowerBoundWithBins = getLowerBoundFromBins();
  const upperBoundWithBins = getUpperBoundFromBins();
  const containerSel = select(container);
  const sliderBarSpacing = 12;
  const sliderSpaceAllocation = 25;
  const slidersY = height - sliderSpaceAllocation;

  containerSel.select('svg').remove();
  const svg = containerSel
    .append('svg')
    .style('overflow', 'visible')
    .attr('height', height + margins.top)
    .attr('width', width + margins.left)
    .append('g')
    .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

  const x = scaleLinear()
    .domain([bins[0]?.x0 ?? 0, bins[bins.length - 1]?.x1 ?? 100])
    .range([0, width]);

  const minX = lowerBound ? x(lowerBoundWithBins) : 0;
  const maxX = upperBound ? x(upperBoundWithBins) : width;

  // Add Y axis
  const y = scaleLinear()
    .domain([0, Math.max(...bins.map((bin) => bin.length))])
    .range([slidersY - sliderBarSpacing, 0]);

  let includedBins: any[] = [];

  const isOuterBin = (bin: any) =>
    (lowerBound && bin.x0 < lowerBound) || (upperBound && bin.x1 > upperBound);

  // Distinguish between bins included in the filter range
  bins.forEach((bin) => {
    if (bin.x0 != null && bin.x1 != null) {
      if (!isOuterBin(bin)) {
        includedBins.push(bin);
      }
    }
  });

  svg
    .append('g')
    .selectAll('rect')
    .data(bins)
    .join('rect')
    .attr('fill', (d) => (isOuterBin(d) ? inactiveBarColor : activeBarColor))
    .attr('class', (d, i) => `bar-${i}`)
    .attr('x', (d) => {
      return x(d.x0 ?? 0) + 1;
    })
    .attr('width', (d) => Math.max(0, x(d.x1 ?? 0) - x(d.x0 ?? 0) - 1))
    .attr('y', (d) => y(d.length))
    .attr('height', (d) => y(0) - y(d.length));

  // Filter slider bar
  svg
    .append('rect')
    .attr('fill', inactiveBarColor)
    .attr('x', 0)
    .attr('width', width)
    .attr('y', slidersY - (2 * sliderBarSpacing) / 3)
    .attr('height', 5);

  svg
    .append('rect')
    .attr('class', 'active-slider')
    .attr('fill', activeBarColor)
    .attr('x', includedBins.length > 0 ? x(includedBins[0].x0) : 0)
    .attr(
      'width',
      includedBins.length > 0
        ? x(includedBins[includedBins.length - 1].x1) - x(includedBins[0].x0)
        : 0,
    )
    .attr('y', slidersY - (2 * sliderBarSpacing) / 3)
    .attr('height', 5);

  appendFilterSlider(lowerBoundWithBins, 'lower');
  appendFilterSlider(upperBoundWithBins, 'upper');

  // Slider drag handlers
  let lowerDrag = drag()
    .on('drag', function (ev: any) {
      onDrag(false, this, ev.x);
    })
    .on('end', function (ev: any) {
      onDragEnd(false, this, ev.x);
    });

  let upperDrag = drag()
    .on('drag', function (ev: any) {
      onDrag(true, this, ev.x);
    })
    .on('end', function (ev: any) {
      onDragEnd(true, this, ev.x);
    });

  lowerDrag(svg.select('g.lower-filter'));
  upperDrag(svg.select('g.upper-filter'));

  function onDrag(isUpper: boolean, selector: any, xVal: number) {
    // The x value we're being dragged to, as long as it doesn't go outside of the viz bounds
    const newX = isUpper
      ? Math.max(Math.min(xVal, width), minX)
      : Math.max(Math.min(xVal, maxX), 0);
    // Update the position of the slider
    select(selector).attr('transform', 'translate(' + newX + ',' + slidersY + ')');
    // Find the bin our slider is closest to so we can appropriately set the slider label (tempBoundString)
    const closestBin = calculateNewBound(xVal, isUpper);
    const tempBoundString = intervalFormatFunction
      ? intervalFormatFunction(closestBin, interval)
      : `${closestBin}`;
    // Set the slider label and position
    select(`text.${isUpper ? 'upper' : 'lower'}-filter-text-${id}`)
      .text(tempBoundString)
      .attr('transform', calculateFilterTextTranslation(tempBoundString, isUpper));
    const currentBound = isUpper ? tempUpperBoundRef : tempLowerBoundRef;
    // Update the bar and slider coloring in real time
    if (closestBin != null && closestBin !== currentBound.current) {
      let includedBins: any[] = [];
      bins.forEach((bin, i) => {
        // Comparer constants to set the bounds
        const upperComparer = isUpper ? closestBin : tempUpperBoundRef.current;
        const lowerComparer = isUpper ? tempLowerBoundRef.current : closestBin;
        // If the bin values fall outside of these bounds, change the color accordingly
        const isOuter =
          (upperComparer && bin.x1 > upperComparer) || (lowerComparer && bin.x0 < lowerComparer);
        if (bin.x0 != null && bin.x1 != null && !isOuter) {
          includedBins.push(bin);
        }
        svg.select(`.bar-${i}`).attr('fill', isOuter ? inactiveBarColor : activeBarColor);
      });

      svg
        .select('.active-slider')
        .attr('x', includedBins.length > 0 ? x(includedBins[0].x0) : 0)
        .attr(
          'width',
          includedBins.length > 0
            ? x(includedBins[includedBins.length - 1].x1) - x(includedBins[0].x0)
            : 0,
        );

      currentBound.current = closestBin;
    }
  }

  function onDragEnd(isUpper: boolean, selector: any, xVal: number) {
    let newBound = calculateNewBound(xVal, isUpper);
    const currentBound = isUpper ? upperBound : lowerBound;
    const currentSetter = isUpper ? setUpperBound : setLowerBound;
    const endOfRange = isUpper ? bins[bins.length - 1].x1 : bins[0].x0;
    if (isUpper) {
      tempUpperBoundRef.current = upperBound;
    } else {
      tempLowerBoundRef.current = lowerBound;
    }
    if (newBound != null) {
      if (newBound === currentBound || (currentBound == null && newBound === endOfRange)) {
        select(selector).attr('transform', 'translate(' + x(newBound) + ',' + slidersY + ')');
      } else {
        currentSetter(newBound === endOfRange ? undefined : newBound);
      }
    }
  }

  function calculateNewBound(eventX: number, isUpperBound: boolean) {
    const binTarget = isUpperBound ? 'x1' : 'x0';
    let newBound = isUpperBound ? upperBoundWithBins : lowerBoundWithBins;
    const otherBound = isUpperBound ? lowerBoundWithBins : upperBoundWithBins;
    bins.forEach((bin) => {
      const candidate = bin[binTarget];
      if (candidate == null) return;
      if (isUpperBound && candidate <= otherBound) {
        return;
      } else if (!isUpperBound && candidate >= otherBound) {
        return;
      }
      const bestDiffYet = newBound == null ? undefined : Math.abs(x(newBound) - eventX);
      const currDiff = Math.abs(x(candidate) - eventX);
      if (bestDiffYet == null || currDiff < bestDiffYet) {
        newBound = candidate;
      }
    });
    return newBound;
  }

  function getLowerBoundFromBins() {
    const minBound = bins[0].x0 ?? 0;
    return lowerBound ? Math.max(lowerBound, minBound) : minBound;
  }

  function getUpperBoundFromBins() {
    const maxBound = bins[bins.length - 1].x1 ?? 0;
    return upperBound ? Math.min(upperBound, maxBound) : maxBound;
  }

  function appendFilterSlider(boundWithBins: number, upperOrLower: string) {
    const isUpper = upperOrLower === 'upper';
    const boundString = intervalFormatFunction
      ? intervalFormatFunction(boundWithBins, interval)
      : `${boundWithBins}`;
    // Slider Dimensions
    const boundStringWidth = boundString.length * 8;
    const sliderLength = 16;
    const detectionBoxHeight = sliderLength + 22;
    const detectionBoxWidth = Math.max(sliderLength, boundStringWidth);
    const circleY = 5;

    const filterSlider = svg
      .append('g')
      .attr('class', upperOrLower + '-filter slider')
      .attr('stroke', activeBarColor)
      .attr('transform', 'translate(' + (isUpper ? maxX : minX).toString() + ',' + slidersY + ')');

    filterSlider
      .append('rect')
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .attr('height', detectionBoxHeight)
      .attr('width', detectionBoxWidth)
      .attr('transform', 'translate(' + (isUpper ? 0 : -1 * sliderLength) + ', -16)');

    filterSlider
      .append('circle')
      .attr('class', 'slider-circle')
      .attr('fill', 'white')
      .attr('transform', 'translate(0, -' + circleY + ')')
      .attr('r', sliderLength / 2);

    filterSlider
      .append('line')
      .attr('stroke', 'black')
      .attr('fill', 'black')
      .attr('x1', -3)
      .attr('y1', -9)
      .attr('x2', -3)
      .attr('y2', -1);

    filterSlider
      .append('line')
      .attr('stroke', 'black')
      .attr('fill', 'black')
      .attr('x1', 0)
      .attr('y1', -9)
      .attr('x2', 0)
      .attr('y2', -1);

    filterSlider
      .append('line')
      .attr('stroke', 'black')
      .attr('fill', 'black')
      .attr('x1', 3)
      .attr('y1', -9)
      .attr('x2', 3)
      .attr('y2', -1);

    filterSlider
      .append('text')
      .attr('class', 'slider')
      .text(boundString)
      .attr('transform', calculateFilterTextTranslation(boundString, isUpper))
      .attr('class', upperOrLower + `-filter-text-${id} filter-text`);
  }
  function calculateFilterTextTranslation(boundString: string, isUpper: boolean) {
    return `translate(${isUpper ? 0 : -8 * boundString.length + 4}, 14)`;
  }
}
