import React, { useCallback, useMemo } from 'react';
import Select, { components, StylesConfig } from 'react-select';

import { reactSelectDropdownStyle, MultiValueRemove, DropdownIndicator } from 'components';
// import { PortsFilterClause } from 'model/datasets';
import { abbreviatedCount, isMultiSelectComparator } from 'utils';
import { ReactComponent as DeleteIcon } from 'svg/actions/x-default.svg';
import { ReactComponent as BullseyeIcon } from 'svg/experimental/bullseye.svg';
import { ReactComponent as EllipseIcon } from 'svg/misc/ellipse.svg';

type PortsFilterProps = {
  filter: any;
  availableFilterClauses: any;
  filterClauses: any;
  modifyFilter: any;
  deleteFilter: any;
  index: number;
  loading: boolean;
};

export function PortsFilter({
  filter,
  filterClauses,
  index,
  availableFilterClauses,
  modifyFilter,
  deleteFilter,
  loading,
}: PortsFilterProps) {
  const clauseOption = filterClauses.find((clauseOption: any) => clauseOption.type === filter.type);

  const typeOptions = useMemo(() => {
    return availableFilterClauses.map((clauseOption: any) => ({
      label: clauseOption.label,
      value: clauseOption.type,
    }));
  }, [availableFilterClauses]);

  const comparatorOptions = useMemo(() => {
    return clauseOption.comparatorOptions.map((comparator: string) => ({
      label: comparator,
      value: comparator,
    }));
  }, [clauseOption]);

  const isMultiSelect = useMemo(() => {
    return isMultiSelectComparator(filter?.comparator);
  }, [filter]);

  const selections = useMemo(() => {
    if (!filter.value) return [];
    const values = Array.isArray(filter.value) ? filter.value : [filter.value];
    return values.map((value: any) => ({ label: value, value }));
  }, [filter.value]);

  const onTypeChange = useCallback(
    (selection: any) => {
      const newClauseOption = filterClauses.find(
        (clauseOption: any) => clauseOption.type === selection.value,
      );
      const newComparator = newClauseOption?.comparatorOptions[0];

      let newValue = filter.value;
      if (Array.isArray(newValue) && !isMultiSelectComparator(newComparator)) {
        newValue = newValue[0];
      } else if (!Array.isArray(newValue) && isMultiSelectComparator(newComparator)) {
        newValue = newValue ? [newValue] : newValue;
      }

      const { valueOptions } = newClauseOption;
      if (Array.isArray(newValue)) {
        newValue = newValue.filter((v: string) =>
          valueOptions.map((o: any) => o.value).includes(v),
        );
      } else {
        const option = valueOptions.find((o: any) => o.value === newValue);
        newValue = option?.value;
      }

      const newFilter = {
        type: newClauseOption.type,
        comparator: newComparator,
        value: newValue,
      };

      modifyFilter(newFilter, index);
    },
    [filter.value, filterClauses, index, modifyFilter],
  );

  const onComparatorChange = useCallback(
    (selection: any) => {
      const newComparator = selection.value;

      let newValue = filter.value;
      if (Array.isArray(newValue) && !isMultiSelectComparator(newComparator)) {
        newValue = newValue[0];
      } else if (!Array.isArray(newValue) && isMultiSelectComparator(newComparator)) {
        newValue = newValue ? [newValue] : newValue;
      }

      const newFilter = {
        type: filter.type,
        comparator: newComparator,
        value: newValue,
      };

      modifyFilter(newFilter, index);
    },
    [filter.type, filter.value, index, modifyFilter],
  );

  const onValueChange = useCallback(
    (selection: any) => {
      const newFilter = {
        ...filter,
        value: Array.isArray(selection)
          ? selection.map((item: any) => item.value)
          : selection?.value,
      };

      modifyFilter(newFilter, index);
    },
    [filter, index, modifyFilter],
  );

  const isQueryResultClause = clauseOption?.type === 'Ports:QueryResult'; // Manual definition for QueryResult for import issues

  return (
    <div>
      <div className="uk-flex-inline uk-margin-bottom" style={{ width: '95%' }}>
        <div className="filter-category-select" style={{ width: '32%' }}>
          <Select
            options={typeOptions}
            value={{ label: clauseOption.label, value: filter.type }}
            onChange={onTypeChange}
            styles={reactSelectDropdownStyle() as StylesConfig}
            blurInputOnSelect={true}
            menuPortalTarget={document.body}
            components={{ DropdownIndicator }}
          />
        </div>
        <div className="uk-margin-left filter-comparator-select" style={{ width: '24%' }}>
          <Select
            options={comparatorOptions}
            value={{ value: filter.comparator, label: filter.comparator }}
            onChange={onComparatorChange}
            styles={reactSelectDropdownStyle() as StylesConfig}
            blurInputOnSelect={true}
            menuPortalTarget={document.body}
            components={{ DropdownIndicator }}
          />
        </div>
        <div
          data-testid="filter-value-select"
          className="uk-margin-left uk-position-relative"
          style={{ width: 'calc(44% - 16px)' }}
        >
          <Select
            options={clauseOption.valueOptions}
            isMulti={isMultiSelect}
            value={isMultiSelect ? selections : selections[0]}
            isClearable={false}
            isLoading={loading}
            placeholder="Select"
            onChange={onValueChange}
            styles={reactSelectDropdownStyle() as StylesConfig}
            components={{
              MultiValueRemove,
              DropdownIndicator,
              Option: isQueryResultClause ? QueryResultOption : Option,
            }}
            blurInputOnSelect={false}
            menuPortalTarget={document.body}
          />
        </div>
      </div>
      <button
        className="uk-button uk-icon-link uk-float-right uk-padding-remove hide-in-pdf"
        style={{ background: 'none' }}
        onClick={() => deleteFilter(index)}
      >
        <DeleteIcon width={24} height={24} />
      </button>
    </div>
  );
}

const Option = (props: any) => {
  const docStyle = getComputedStyle(document.documentElement);
  const neutralColor = docStyle.getPropertyValue('--app-neutral-color');

  return (
    <div className="app-tag-wrapper">
      <components.Option {...props}>
        <div className="uk-flex uk-flex-top">
          <label>{props.label}</label>
          {props?.data?.count && (
            <div className="uk-float-right" style={{ marginLeft: 'auto', color: neutralColor }}>
              {abbreviatedCount(props?.data?.count)}
            </div>
          )}
        </div>
      </components.Option>
    </div>
  );
};

// Custom option for query result categories
const QueryResultOption = (props: any) => {
  const docStyle = getComputedStyle(document.documentElement);
  const neutralColor = docStyle.getPropertyValue('--app-neutral-color');

  return (
    <components.Option {...props}>
      <div className="uk-flex uk-flex-middle">
        <span className="uk-margin-right">
          {props.value === 'success' ? (
            <BullseyeIcon className="app-icon-success" />
          ) : (
            <EllipseIcon />
          )}
        </span>
        <label>{props.label}</label>
        {props?.data?.count && (
          <div className="uk-float-right" style={{ marginLeft: 'auto', color: neutralColor }}>
            {abbreviatedCount(props?.data?.count)}
          </div>
        )}
      </div>
    </components.Option>
  );
};
