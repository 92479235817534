import { gql } from '@apollo/client';
import {
  AssetLink,
  AttributeAnalyzer,
  reactSelectDropdownStyle,
  Spinner,
  SummaryAttribute,
  DropdownIndicator,
  ClearIndicator,
} from 'components';
import { useAssetQuery, useCountryTopProvidersQuery } from 'hooks';
import { AssetType, ComponentProps, ReactSelectOption } from 'model';
import React, { useState, useEffect, Fragment, useMemo, useCallback, useContext } from 'react';
import { getCountryName } from 'utils';
import Select, { components, StylesConfig } from 'react-select';
import { uniq, flatten } from 'lodash';
import { ReactComponent as Search } from 'svg/system/magnifying-glass.svg';
import { ReactComponent as ToggleOn } from 'svg/selection/toggle-on.svg';
import { ReactComponent as ToggleOff } from 'svg/selection/toggle-off.svg';
import { ComponentPluginContext } from 'appContexts';

const ccFlowsQuery = gql`
  query CountryFlowsData($countrySet: [String]!) {
    countryData(countrySet: $countrySet) {
      id
      country
      countryOutboundFlows {
        collector
        collectorAsn
        tracerouteCount
        asEdges {
          asn1 {
            asn
            orgName
          }
          country
          weight
        }
      }
      countryInboundFlows(filter: "{'must_not': {'exists': 'target_asn'}}") {
        tracerouteCount
         asEdges {
          asn1 {
            asn
            orgName
          }
           country
           weight
         }
      }
    }
  }
`;

enum SummaryType {
  Providers = 'Top Providers',
  BorderProviders = 'Border Providers',
  BorderCountries = 'Border Countries',
}

// Ensure backwards compatibility
enum SummaryValue {
  Providers = 'providers',
  BorderProviders = 'border-providers',
  BorderCountries = 'border-countries',
}

const options = [
  { label: SummaryType.Providers, value: SummaryValue.Providers },
  { label: SummaryType.BorderProviders, value: SummaryValue.BorderProviders },
  { label: SummaryType.BorderCountries, value: SummaryValue.BorderCountries },
];

const borderCountryGroupTypes = [
  { label: 'ASN', value: 'asn' },
  { label: 'Organization', value: 'org' },
];

function getActiveTab(pluginConfig: Record<string, any>) {
  if (pluginConfig.tab) {
    const o = options.find((o: ReactSelectOption) => o.value === pluginConfig.tab);
    return o?.value ?? options[0].value;
  } else {
    return options[0].value;
  }
}

export default function CountrySummary({ params }: ComponentProps) {
  const { getPluginConfig, updatePluginConfig } = useContext(ComponentPluginContext);
  const [selected, setSelected] = useState<string>(getActiveTab(getPluginConfig()));

  const [providerItems, setProviderItems] = useState<any>([]);
  const [searchOption, setSearchOption] = useState<any>(null);
  const [borderProvidersData, setBorderProvidersData] = useState<any>();
  const [borderCountriesData, setBorderCountriesData] = useState<any>();
  const [groupOption, setGroupOption] = useState<any>();

  // Queries to fetch top providers and COF/CIF data
  const { asset: providerData, loading: providersFetching } = useCountryTopProvidersQuery(
    params.assetValue,
  );
  const { asset: ccFlowsData, loading: ccFlowsFetching } = useAssetQuery(
    AssetType.Country,
    params.assetValue,
    ccFlowsQuery,
  );

  // When the config updates the tab, set it here
  useEffect(() => {
    if (getActiveTab(getPluginConfig()) && getActiveTab(getPluginConfig()) !== selected) {
      setSelected(getActiveTab(getPluginConfig()));
    } else if (!getPluginConfig().tab) {
      // Set the current tab on the first render, if it isn't defined yet
      let newConfig = { ...getPluginConfig(), tab: options[0].value };
      updatePluginConfig(newConfig);
    }
  }, [getPluginConfig, selected, updatePluginConfig]);

  // React select option (providers, border providers, border countries)
  const selectedOption = useMemo(() => {
    return options.find((o: any) => o.value === selected) || options[0];
  }, [selected]);

  const changeSelectedOption = useCallback(
    (newOption: any) => {
      const newTab = newOption.value;
      if (newTab !== getActiveTab(getPluginConfig())) {
        setSelected(newTab);
        let newConfig = { ...getPluginConfig(), tab: newTab };
        updatePluginConfig(newConfig);
      }
    },
    [getPluginConfig, updatePluginConfig],
  );

  // Set default groupBy option when toggled on or clear when toggled off. Also clear search when toggled
  const onToggleGrouped = useCallback(() => {
    setGroupOption((current: any) => {
      if (current) {
        return undefined;
      } else if (selectedOption.label === SummaryType.BorderCountries) {
        return borderCountryGroupTypes[0];
      }
    });
    setSearchOption(null);
  }, [selectedOption.label]);

  // Set the react select group by option
  const onChangeGroupOption = useCallback((newGroupOption) => {
    setGroupOption(newGroupOption);
    setSearchOption(null);
  }, []);

  // Set the react select search option when attribute row is click
  const onRowClick = useCallback((newSearchOption) => {
    setSearchOption((current: { label: any; value: any }) => {
      if (current?.value === newSearchOption.value) {
        return null;
      }
      return { label: newSearchOption.value, value: newSearchOption.value };
    });
  }, []);

  // Build attributes for top providers (process providers aka ripA)
  useEffect(() => {
    if (!providersFetching) {
      const totalOriginScore = providerData?.countryRecords?.internetOriginsScore;
      const providers =
        providerData?.ripA
          ?.filter((asObj: any) => asObj?.origin?.score)
          ?.sort((a: any, b: any) => b.origin.score - a.origin.score) || [];

      const formattedProviderData = providers.map((asObj: any) => {
        const routingPercentage = (asObj.origin.score / totalOriginScore) * 100;
        const { asn, orgName } = asObj?.asn || {};
        return {
          value: `AS${asn} ${orgName}`,
          label: <ASNLabel asn={asn} orgName={orgName} />,
          metric: { pct: routingPercentage },
          metricFormatted: { pct: formatPercentage(routingPercentage) },
        };
      });

      setProviderItems(formattedProviderData);
    }
  }, [providerData, providersFetching]);

  // Build attributes for border providers an border countries (process CIF/COF)
  useEffect(() => {
    if (!ccFlowsFetching) {
      // Build COF Items
      const cof = ccFlowsData?.countryOutboundFlows;
      const newCofData = buildFlowsHistogram(cof);

      // Build CIF Items
      const cif = ccFlowsData?.countryInboundFlows;
      const newCifData = buildFlowsHistogram(cif);

      setBorderProvidersData([newCifData.borderProviders, newCofData.borderProviders]);

      // Border countries data can be grouped by asn or org
      const newBorderCountriesData = {
        default: [newCifData?.borderCountries, newCofData.borderCountries],
        asn: [newCifData?.borderCountriesGroupedAsn, newCofData?.borderCountriesGroupedAsn],
        org: [newCifData?.borderCountriesGroupedOrg, newCofData?.borderCountriesGroupedOrg],
      };
      setBorderCountriesData(newBorderCountriesData);
    }
  }, [ccFlowsData, ccFlowsFetching]);

  // Options to populate react select search depending on type (provider, border providers/countries) and groupBy selection
  const searchOptions = useMemo(() => {
    let newOptions: any[] = uniq(providerItems.map((item: any) => item.value)).sort();
    if (selectedOption.label === SummaryType.BorderProviders) {
      newOptions = uniq(flatten(borderProvidersData)?.map((item: any) => item.value)).sort();
    } else if (selectedOption.label === SummaryType.BorderCountries) {
      if (groupOption?.value) {
        const subValues = flatten(borderCountriesData?.[groupOption.value])?.map((item: any) => {
          return item.metric.map((subItem: any) => subItem.value);
        });
        newOptions = uniq(flatten(subValues))
          .filter((subValue: string) => !subValue.includes('Additional')) // Filter out cutoff category
          .sort();
      } else {
        newOptions = uniq(
          flatten(borderCountriesData?.default)?.map((item: any) => item.value),
        ).sort();
      }
    }
    return newOptions?.map((option: string) => {
      return { label: option, value: option };
    });
  }, [borderCountriesData, borderProvidersData, groupOption, providerItems, selectedOption.label]);

  // Reset group and search whenever summary type changes
  useEffect(() => {
    if (selectedOption.label !== SummaryType.BorderCountries) {
      setGroupOption(undefined);
    }
    setSearchOption(null);
  }, [selectedOption.label]);

  return (
    <div className="country-summary">
      {selectedOption.label === SummaryType.BorderCountries && (
        <div className="uk-flex-inline uk-margin-bottom" style={{ width: '100%' }}>
          <div className="uk-flex-inline" style={{ marginLeft: 'auto' }}>
            <span>Group</span>
            <button
              className="country-summary-group-toggle"
              onClick={onToggleGrouped}
              style={{ paddingRight: 0 }}
            >
              {groupOption ? <ToggleOn /> : <ToggleOff />}
            </button>
          </div>
        </div>
      )}

      <div className="country-summary-header">
        <Select
          options={options}
          value={selectedOption}
          onChange={changeSelectedOption}
          styles={tabDropdownStyle() as StylesConfig}
          blurInputOnSelect={true}
          components={{ DropdownIndicator }}
          isSearchable={false}
        />
        <Select
          options={searchOptions}
          isClearable={true}
          value={searchOption}
          placeholder={''}
          onChange={setSearchOption}
          styles={filterDropdownStyle() as StylesConfig}
          blurInputOnSelect={true}
          components={{ Control, DropdownIndicator, ClearIndicator }}
        />
      </div>
      {groupOption && (
        <div className="attribute-analyzer-group-selector">
          <div className="input-label">Group By</div>
          <div className="attribute-analyzer-group-selector-dropdown ib-section-width-4">
            <Select
              value={groupOption || null}
              placeholder="None"
              options={borderCountryGroupTypes}
              onChange={onChangeGroupOption}
              styles={reactSelectDropdownStyle() as StylesConfig}
              blurInputOnSelect={true}
              menuPortalTarget={document.body}
              components={{ DropdownIndicator }}
            />
          </div>
        </div>
      )}
      <div className="attribute-analyzer-wrapper ">
        {selectedOption.label === SummaryType.Providers ? (
          <SingleAttributeAnalyzer
            attributes={providerItems}
            loading={providersFetching}
            filter={searchOption?.value}
            onRowClick={onRowClick}
          />
        ) : (
          <MultiAttributeAnalyzer
            loading={ccFlowsFetching}
            attributeName={
              selectedOption.label === SummaryType.BorderProviders ? 'Provider' : 'Country'
            }
            attributes={
              selectedOption.label === SummaryType.BorderProviders
                ? borderProvidersData
                : borderCountriesData?.[groupOption?.value || 'default']
            }
            filter={searchOption?.value}
            onRowClick={onRowClick}
          />
        )}
      </div>
      {(selectedOption.label === SummaryType.BorderProviders ||
        (selectedOption.label === SummaryType.BorderCountries && groupOption)) && (
        <div className="uk-text-muted uk-margin-top uk-text-right">
          * Indicates collector service providers
        </div>
      )}
    </div>
  );
}

type SingleAttributeAnalyzerProps = {
  loading: boolean;
  attributes: SummaryAttribute[];
  filter?: string | undefined;
  onRowClick: any;
};

type MultiAttributeAnalyzerProps = {
  loading: boolean;
  attributeName: string;
  attributes: SummaryAttribute[][];
  onRowClick: any;
  filter?: string | undefined;
};

function SingleAttributeAnalyzer({
  loading,
  attributes,
  filter,
  onRowClick,
}: SingleAttributeAnalyzerProps) {
  const dataFormatOptions = [{ value: 'Percent', label: 'Trace %' }];
  const [dataFormat, setDataFormat] = useState(dataFormatOptions[0]);

  if (loading) {
    return (
      <div className="uk-flex">
        <Spinner ratio={3} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
      </div>
    );
  }

  return (
    <AttributeAnalyzer
      attributes={attributes}
      attributeName="Provider"
      metricName={{ name: 'Routing %', accessor: 'pct' }}
      filter={filter}
      onRowClick={onRowClick}
      dataFormat={dataFormat}
      dataFormatOptions={dataFormatOptions}
      onDataFormatChange={setDataFormat}
    />
  );
}

function MultiAttributeAnalyzer({
  loading,
  attributeName,
  attributes,
  filter,
  onRowClick,
}: MultiAttributeAnalyzerProps) {
  const [hovered, setHovered] = useState();
  const dataFormatOptions = [{ value: 'Percent', label: 'Trace %' }];
  const [dataFormat, setDataFormat] = useState(dataFormatOptions[0]);

  return (
    <div
      className={`multi-attribute-analyzer ${loading ? 'multi-attribute-analyzer-loading' : ''}`}
    >
      {loading ? (
        <Spinner ratio={3} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
      ) : (
        <Fragment>
          <AttributeAnalyzer
            attributes={attributes?.[0]}
            attributeName={`Ingress ${attributeName}`}
            metricName={{ name: 'Trace %', accessor: 'pct' }}
            filter={filter}
            onRowClick={onRowClick}
            hovered={hovered}
            onRowHover={setHovered}
            dataFormat={dataFormat}
            dataFormatOptions={dataFormatOptions}
            onDataFormatChange={setDataFormat}
          />
          <AttributeAnalyzer
            attributes={attributes?.[1]}
            attributeName={`Egress ${attributeName}`}
            metricName={{ name: 'Trace %', accessor: 'pct' }}
            filter={filter}
            onRowClick={onRowClick}
            hovered={hovered}
            onRowHover={setHovered}
            dataFormat={dataFormat}
            dataFormatOptions={dataFormatOptions}
            onDataFormatChange={setDataFormat}
          />
        </Fragment>
      )}
    </div>
  );
}

// Process CIF/COF data
function buildFlowsHistogram(ccFlows: any) {
  const asnMap: Record<string, number> = {};
  const countryMap: Record<string, number> = {};
  const countryGroupedMap: Record<string, any> = {};
  const orgLookup: Record<string, string> = {};
  const totalTracerouteCount =
    ccFlows?.length > 0
      ? ccFlows
          .map((flow: any) => flow.tracerouteCount)
          .reduce((sum: number, count: number) => sum + count)
      : 0;

  const collectorAsns = uniq(ccFlows?.map((flow: any) => flow?.collectorAsn?.toString()));
  const collectorOrgNames = new Set();

  // Process each collector to get the percentage of traces per ASN and Country
  ccFlows?.forEach((flow: any) => {
    const countFraction = flow.tracerouteCount / totalTracerouteCount;
    flow.asEdges.forEach((asEdge: any) => {
      const { asn, orgName } = asEdge.asn1[0];
      const nextCountry = asEdge.country;
      asnMap[asn] = (asnMap[asn] || 0) + asEdge.weight * countFraction;
      orgLookup[asn] = orgName;
      collectorAsns.includes(asn?.toString()) && collectorOrgNames.add(orgName);
      countryMap[nextCountry] = (countryMap[nextCountry] || 0) + asEdge.weight * countFraction;
      if (!countryGroupedMap.hasOwnProperty(nextCountry)) {
        countryGroupedMap[nextCountry] = { asns: {}, orgs: {} };
      }
      countryGroupedMap[nextCountry]['asns'][asn] =
        (countryGroupedMap[nextCountry]['asns'][asn] || 0) + asEdge.weight * countFraction;
      countryGroupedMap[nextCountry]['orgs'][orgName] =
        (countryGroupedMap[nextCountry]['orgs'][orgName] || 0) + asEdge.weight * countFraction;
    });
  });

  // Attribute-ify border providers
  const borderProviders = Object.keys(asnMap)
    .map((asn: string) => {
      return {
        value: `AS${asn} ${orgLookup[asn]}`,
        label: <ASNLabel asn={asn} orgName={orgLookup[asn]} noted={collectorAsns.includes(asn)} />,
        metric: { pct: asnMap[asn] },
        metricFormatted: { pct: formatPercentage(asnMap[asn]) },
      };
    })
    .sort((a, b) => b.metric.pct - a.metric.pct);

  // Attribute-ify border countries
  const borderCountries = Object.keys(countryMap)
    .map((cc: string) => {
      return {
        value: getCountryName(cc),
        label: getCountryName(cc),
        metric: { pct: countryMap[cc] },
        metricFormatted: { pct: formatPercentage(countryMap[cc]) },
      };
    })
    .sort((a, b) => b.metric.pct - a.metric.pct);

  const sortedCountries = Object.keys(countryMap)
    .map((cc: string) => {
      return { cc: cc, metric: countryMap[cc] };
    })
    .sort((a, b) => b.metric - a.metric)
    .map((ccObj) => ccObj.cc);

  // Attribute-ify border countries grouped by ASN
  const borderCountriesGroupedAsn = sortedCountries.map((cc: string) => {
    const groupedASNs = Object.keys(countryGroupedMap[cc]['asns'])
      .map((asn: string) => {
        return {
          value: `AS${asn} ${orgLookup[asn]}`,
          label: (
            <ASNLabel asn={asn} orgName={orgLookup[asn]} noted={collectorAsns.includes(asn)} />
          ),
          metric: { pct: countryGroupedMap[cc]['asns'][asn] },
          metricFormatted: { pct: formatPercentage(countryGroupedMap[cc]['asns'][asn]) },
        };
      })
      .sort((a, b) => b.metric.pct - a.metric.pct);

    return {
      value: getCountryName(cc),
      label: getCountryName(cc),
      metric: formatNestedAttributes(groupedASNs, 'ASNs'),
      metricFormatted: { pct: formatPercentage(countryMap[cc]) },
    };
  });

  // Attribute-ify border countries grouped by organization
  const borderCountriesGroupedOrg = sortedCountries.map((cc: string) => {
    const groupedOrgs = Object.keys(countryGroupedMap[cc]['orgs'])
      .map((orgName: string) => {
        return {
          value: orgName,
          label: (
            <AssetLink
              value={`${orgName}${collectorOrgNames.has(orgName) ? '*' : ''}`}
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                display: 'block',
              }}
            />
          ),
          metric: { pct: countryGroupedMap[cc]['orgs'][orgName] },
          metricFormatted: { pct: formatPercentage(countryGroupedMap[cc]['orgs'][orgName]) },
        };
      })
      .sort((a, b) => b.metric.pct - a.metric.pct);
    return {
      value: getCountryName(cc),
      label: getCountryName(cc),
      metric: formatNestedAttributes(groupedOrgs, 'Organizations'),
      metricFormatted: { pct: formatPercentage(countryMap[cc]) },
    };
  });

  return { borderProviders, borderCountries, borderCountriesGroupedAsn, borderCountriesGroupedOrg };
}

// Custom react select styles for tab/search selection. Mainly border radius and border styling
const docStyle = getComputedStyle(document.documentElement);
const borderColor = docStyle.getPropertyValue('--app-color-input-border');
const searchControlStyles = {
  '&:hover': { borderColor: borderColor, cursor: 'pointer' },
  '&:focus-within': { borderColor: borderColor },
  border: `1px solid ${borderColor}`,
  boxShadow: 'none',
  padding: `0px 8px 0px 8px`,
  minHeight: '32px',
  lineHeight: '1.2rem',
  borderRadius: '0px',
};

function tabDropdownStyle() {
  return {
    ...reactSelectDropdownStyle(),
    control: (base: Record<string, any>) => ({
      ...base,
      ...searchControlStyles,
      backgroundColor: '#F9FAFB',
      borderRadius: '2px 0px 0px 0px',
    }),
  };
}

function filterDropdownStyle() {
  return {
    ...reactSelectDropdownStyle(),
    control: (base: Record<string, any>) => ({
      ...base,
      ...searchControlStyles,
      borderRadius: '0px 2px 0px 0px',
      borderLeftWidth: '0px',
    }),
    singleValue: (base: Record<string, any>) => ({
      ...base,
      whiteSpace: 'nowrap',
    }),
  };
}

// Custom component for search react select to include search icon
function Control({ children, ...props }: any) {
  return (
    <components.Control {...props}>
      <Search className="search-icon" />
      {children}
    </components.Control>
  );
}

// When grouped only take the top 5 attributes and bucket the rest
function formatNestedAttributes(nestedAttributes: any, attributeType: string) {
  if (nestedAttributes.length <= 5) {
    return nestedAttributes;
  }
  const top5Attributes = nestedAttributes.slice(0, 5);
  const bucketedAttributes = nestedAttributes.slice(5);
  const bucketedAttributesMetric = bucketedAttributes
    .map((obj: any) => obj.metric.pct)
    .reduce((sum: number, count: number) => sum + count);
  return [
    ...top5Attributes,
    {
      value: `Additional ${attributeType} (${bucketedAttributes.length})`,
      label: `Additional ${attributeType} (${bucketedAttributes.length})`,
      metric: { pct: bucketedAttributesMetric },
      metricFormatted: { pct: formatPercentage(bucketedAttributesMetric) },
    },
  ];
}

export function formatPercentage(num: number) {
  return num < 0.1 ? '<0.1' : num.toFixed(1);
}

export function ASNLabel({
  asn,
  orgName,
  noted,
}: { asn: string | number; orgName: string; noted?: boolean }) {
  return (
    <div className="uk-flex uk-flex-middle" title={`AS${asn}`}>
      <div className="uk-flex-top" style={{ alignSelf: 'flex-start' }}>
        <AssetLink value={`AS${asn}`} linkValue={asn} />
      </div>
      <div className="asn-org-label" title={orgName}>
        {orgName}
        {noted ? '*' : ''}
      </div>
    </div>
  );
}
