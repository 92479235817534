import {
  authenticatedFetch,
  getCurrentInvestigation,
  getImageData,
  getInvestigation,
  getSearchParams,
  isSharedLink,
} from 'utils';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SharedContext } from 'appContexts';
import { useReactiveVar } from '@apollo/client';
import { userInvestigationsVar, userSharedInvestigationsVar } from 'model';
import { getGraphQLClient } from 'gql';
import { ImagePlaceholder } from 'components';

export function AuthenticatedImage({ src, alt, style }: { src: string; alt: string; style: any }) {
  const client = getGraphQLClient();
  const isDocsUrl = useMemo(() => src?.includes(process.env.REACT_APP_DOCS_HOST), [src]);
  const { sharedUserDataId, sharedKey } = useContext(SharedContext);
  const sharedInvestigations = useReactiveVar(userSharedInvestigationsVar);
  const investigations = useReactiveVar(userInvestigationsVar);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const investigation = useMemo(() => {
    if (isSharedLink()) {
      return getInvestigation(sharedUserDataId, sharedInvestigations);
    } else {
      return (
        getInvestigation(getSearchParams().get('id') || undefined, investigations) ??
        getCurrentInvestigation()
      );
    }
  }, [sharedInvestigations, sharedUserDataId, investigations]);

  const localImage = useMemo(() => {
    return investigation?.images?.find(
      (image) => src === `${image.id}/${image.name}` && image.data,
    );
  }, [investigation, src]);

  const remoteImage = useMemo(() => {
    return investigation?.images?.find(
      (image) => src === `${image.id}/${image.name}` && !image.data,
    );
  }, [investigation, src]);

  const [imageUrl, setImageUrl] = useState<string | undefined>(
    isDocsUrl || remoteImage ? undefined : src,
  );

  useEffect(() => {
    async function fetchImageData() {
      try {
        if (remoteImage) {
          setImageLoading(true);
          const queryVariables = { imageIds: [remoteImage.id] };
          if (sharedKey) {
            queryVariables['sharedKey'] = sharedKey;
          }
          const imageResult = await client.query({
            query: getImageData,
            variables: queryVariables,
            fetchPolicy: 'cache-first',
          });
          if (imageResult?.data?.imageData?.dataObjects?.length) {
            setImageUrl(imageResult?.data?.imageData?.dataObjects[0].data);
            setImageLoading(false);
          } else {
            console.error('image fetch failed');
            setImageLoading(false);
          }
          return;
        }
        if (!src || !isDocsUrl) return;
        setImageLoading(true);
        const result = await authenticatedFetch(src);
        if (!result.ok) {
          console.log(`image fetch failed with status ${result.status}`);
          return;
        }
        const newBlob = await result.blob();
        if (!newBlob) {
          console.log(`Failed to convert image to blob`);
          return;
        }
        setImageUrl(window.URL.createObjectURL(newBlob));
        setImageLoading(false);
      } catch (e) {
        console.log('image fetch failed', e);
      }
    }
    fetchImageData();
  }, [isDocsUrl, src, remoteImage, client, sharedKey]);

  if (imageLoading) return <ImagePlaceholder />;

  if (localImage) {
    return <img src={localImage.data} alt={localImage.name} style={style} />;
  }

  if (remoteImage) {
    return <img src={imageUrl} alt={remoteImage.name} style={style} />;
  }

  return imageUrl ? <img src={imageUrl} alt={alt} style={style} /> : null;
}
