import React, { useState, useRef, ReactNode } from 'react';
import { defer } from 'lodash';

export default function SeeMore({
  items,
  topCount,
  slop,
  className,
}: { items: ReactNode[]; topCount: number; slop?: number; className?: string }) {
  const [allItems, setAllItems] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  let viewedItems = items;
  const computedTopCount = slop
    ? items.length - topCount > slop
      ? topCount
      : items.length
    : topCount;

  if (!allItems) {
    viewedItems = viewedItems?.slice(0, computedTopCount);
  }

  function onToggleSeeMore() {
    const scrollTop = document.documentElement.scrollTop;
    setAllItems(!allItems);
    if (!allItems) {
      defer(() => (document.documentElement.scrollTop = scrollTop));
    }
  }

  return (
    <div ref={ref} className={className}>
      {viewedItems.map((item, i) => (
        <div key={i}>{item}</div>
      ))}
      {items.length > computedTopCount && (
        <button className="uk-button uk-button-text" onClick={onToggleSeeMore}>
          {allItems ? 'See Less' : 'See More'}
        </button>
      )}
    </div>
  );
}
