import React, { Fragment, useContext, useMemo } from 'react';
import ReportTitle from 'components/ReportTitle';
import { useExchangeQuery } from 'hooks';
import { ComponentPluginName, DisplayElement } from 'components';
import { AssetInfoContext } from 'appContexts';
import { UserInvestigationSection } from 'model';
import { toInvestigationSectionId } from 'utils';
import InvestigationSection from '../investigations/InvestigationSection';

export default function IXInfo({ assetValue }: { assetValue: string }) {
  const { asset } = useExchangeQuery(assetValue);
  const assetName = asset?.exchange?.name;
  const { displayElements } = useContext(AssetInfoContext);

  const aboutSection: UserInvestigationSection = useMemo(
    () => ({
      id: toInvestigationSectionId(ComponentPluginName.ExchangeAbout, { assetValue, assetName }),
      title: 'About',
      description: '',
    }),
    [assetValue, assetName],
  );

  const prefixesSection: UserInvestigationSection = useMemo(
    () => ({
      id: toInvestigationSectionId(ComponentPluginName.ExchangePrefixes, { assetValue, assetName }),
      title: 'Prefixes',
      description: '',
    }),
    [assetValue, assetName],
  );

  const connectionsSection: UserInvestigationSection = useMemo(
    () => ({
      id: toInvestigationSectionId(ComponentPluginName.ExchangeConnections, {
        assetValue,
        assetName,
      }),
      title: 'Connections',
      description: '',
    }),
    [assetValue, assetName],
  );

  return (
    <Fragment>
      {asset && (
        <Fragment>
          {displayElements?.includes(DisplayElement.Title) && (
            <ReportTitle
              title={assetName}
              subtitle={asset?.peeringExchange?.nameLong}
              label="Internet Exchange"
            />
          )}
          {displayElements?.includes(DisplayElement.About) && (
            <InvestigationSection section={aboutSection} />
          )}
          {displayElements?.includes(DisplayElement.Details) && (
            <Fragment>
              {(asset.peeringExchange.prefixesV4 || asset.peeringExchange.prefixesV6) && (
                <InvestigationSection section={prefixesSection} />
              )}
              <InvestigationSection section={connectionsSection} />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
