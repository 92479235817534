import React from 'react';

export enum RadioPosition {
  Left = 'left',
  Right = 'right',
}

type RadioCardProps = {
  title: string;
  description: string;
  selected?: boolean;
  onSelect: Function;
  style?: any;
  radioPosition?: RadioPosition;
};

export function RadioCard({
  title,
  description,
  onSelect,
  selected,
  style,
  radioPosition,
}: RadioCardProps) {
  return (
    <div
      style={{ ...style }}
      className={`radio-card radio-card-${selected ? 'selected' : 'normal'} cursor-pointer`}
      onClick={() => onSelect()}
    >
      {radioPosition === RadioPosition.Left ? (
        <div className="uk-position-relative">
          <input
            className="uk-radio radio-card-left-radio"
            type="radio"
            checked={selected}
            onChange={() => onSelect()}
          />
          <div className="uk-margin-large-left">
            <div className="radio-card-title uk-text-uppercase">{title}</div>
            <div className="radio-card-description">{description}</div>
          </div>
        </div>
      ) : (
        <div className="uk-position-relative">
          <input
            className="uk-radio radio-card-right-radio"
            type="radio"
            checked={selected}
            onChange={() => onSelect()}
          />
          <div>
            <div className="radio-card-title uk-text-uppercase">{title}</div>
            <div className="radio-card-description">{description}</div>
          </div>
        </div>
      )}
    </div>
  );
}
