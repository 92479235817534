import React, {
  DragEvent,
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ALERT_TYPES, ReactSelectOption, validateIpFile } from 'model';
import Select, { StylesConfig } from 'react-select';
import {
  Banner,
  BannerProps,
  ClearIndicator,
  DropdownIndicator,
  MultiValueRemove,
  RadioCard,
  RadioPosition,
  Spinner,
  reactSelectDropdownStyle,
  AuthorizedElement,
  ON_DEMAND_ROLE,
} from 'components';
import {
  createInvalidIpsWarning,
  DEBOUNCE_TIMEOUT,
  exportJsonToFile,
  getPortOptions,
  humanizeFileSize,
  validateIPSet,
  validateFileExtensions,
  getDefaultFileName,
} from 'utils';
import { ReactComponent as Attachment } from 'svg/system/document.svg';
import { ReactComponent as Close } from 'svg/actions/x-default.svg';
import { ReactComponent as Checkmark } from 'svg/actions/checkmark-default.svg';
import { debounce, range } from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { Address4, Address6 } from 'ip-address';

enum ScanScope {
  Global = 'world',
  Domestic = 'cn',
}

enum OnDemandUploadState {
  Initial = 'initial',
  File = 'file',
  Manual = 'manual',
}

const ALL_OPTION = { label: 'all', value: 'all', data: undefined };
const s3Path = process.env.REACT_APP_ON_DEMAND_ENDPOINT;

const MAX_IP_EXPORT_COUNT = 10000;
const MAX_NOISE_IP_COUNT = 250;
const MIN_NOISE_IP_COUNT = 50;

const EXPORT_FILE_NAME_SALT = 'on-demand';
const EXPORT_FILE_EXTENSION = '.json';

const maxIpCountError: BannerProps = {
  alertType: ALERT_TYPES.error,
  message: 'Maximum number of IPs (10,000, inclusive of noise) exceeded per on-demand job.',
};

const reservedOrgsQuery = gql`
query {
  orgs(filter: "[{'isReserved': 'true'}]", size: 1000) {
    totalCount
    items {
      prefix {
        prefix
      }
      ipversion
    }
  }
}`;

export function OnDemand() {
  const { data: privateOrgData } = useQuery(reservedOrgsQuery);

  // common state
  const [scanScope, setScanScope] = useState<ScanScope>(ScanScope.Global);
  const [ports, setPorts] = useState<ReactSelectOption[]>([ALL_OPTION]);
  const [fileName, setFileName] = useState<string>(
    getDefaultFileName(EXPORT_FILE_EXTENSION, EXPORT_FILE_NAME_SALT),
  );
  const [ipsWarning, setIpsWarning] = useState<BannerProps>();
  const [ipArray, setIpArray] = useState<string[]>([]);
  const [onDemandUploadState, setOnDemandUploadState] = useState<OnDemandUploadState>(
    OnDemandUploadState.Initial,
  );
  const [addNoise, setAddNoise] = useState<boolean>(true);
  const [command, setCommand] = useState<string>(undefined);
  const [showCopySuccess, setShowCopySuccess] = useState<boolean>(false);

  // text input state
  const [currentText, setCurrentText] = useState<string>('');

  // file upload state
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File>(undefined);

  // description, which is a function of whether the user has successfully created an export
  const description = useMemo(() => {
    if (command) {
      return `The file ${fileName} with ${ipArray.length} IP address${ipArray.length > 1 ? 'es' : ''} has been successfully exported. Copy the command below to upload the file for on-demand scanning.`;
    } else {
      return 'Create a JSON file for on-demand port-scanning below. Enter a set of newline delimited IP addresses or provide a text file with the same format below. Use the “add noise” checkbox to include a random set of IP addresses in the export file.';
    }
  }, [command, fileName, ipArray.length]);

  // ip noise related memoized variables
  const noiseCount = useMemo(() => {
    return Math.floor(
      Math.random() * (MAX_NOISE_IP_COUNT - MIN_NOISE_IP_COUNT + 1) + MIN_NOISE_IP_COUNT,
    );
  }, []);

  const [privateV4Subnets, privateV6Subnets]: [Address4[], Address6[]] = useMemo(() => {
    let privateV4Subnets: Address4[] = [];
    let privateV6Subnets: Address6[] = [];
    if (privateOrgData?.orgs?.items) {
      const orgs = privateOrgData.orgs.items;
      orgs.forEach((org) => {
        if (org.prefix?.prefix) {
          if (org.ipversion === 4) {
            privateV4Subnets.push(new Address4(org.prefix.prefix));
          } else if (org.ipversion === 6) {
            privateV6Subnets.push(new Address6(org.prefix.prefix));
          }
        }
      });
    }
    return [privateV4Subnets, privateV6Subnets];
  }, [privateOrgData]);

  const noisyIps = useMemo(() => {
    function isInPrivateIpV4Subnets(ip: string) {
      return privateV4Subnets.some((subnet) => subnet.isInSubnet(new Address4(ip)));
    }

    function isInPrivateIpV6Subnets(ip: string) {
      return privateV6Subnets.some((subnet) => subnet.isInSubnet(new Address6(ip)));
    }

    function getRandomPublicIpV4() {
      const randomOctet = () => Math.ceil(Math.random() * 255);
      while (true) {
        const ip = Array.from({ length: 4 }, randomOctet).join('.');
        if (!isInPrivateIpV4Subnets(ip)) {
          return ip;
        }
      }
    }

    function generateRandomIPv6(): string {
      const prefixes = [
        '2401',
        '2402',
        '2001',
        '2a02',
        '2a01',
        '2804',
        '2003',
        '2600',
        '2604',
        '2605',
        '2800',
        '2a0d',
        '2a0f',
        '2806',
        '2406',
        '2a09',
        '2606',
        '2607',
        '2400',
        '2a00',
        '2a10',
        '2a12',
        '2a0b',
        '2a03',
        '2a06',
      ];

      const interfaces = [
        '0000',
        '0001',
        '0002',
        '0003',
        '0004',
        '0005',
        '0006',
        '0007',
        '0008',
        '0009',
        '0010',
      ];

      function getRandomHexSegment(): string {
        return Math.floor(Math.random() * 0x10000)
          .toString(16)
          .padStart(4, '0');
      }

      function getRandomZeroSequence() {
        const parts = [];
        // Generate between 1 and 3, 0000 sequences
        const sequenceLength = Math.floor(Math.random() * 3) + 1;
        for (let i = 0; i < sequenceLength; i++) {
          parts.push('0000');
        }
        if (sequenceLength === 3) {
          // Set final part
          if (Math.random() < 0.4) {
            const randomInterface = interfaces[Math.floor(Math.random() * interfaces.length)];
            parts.push(randomInterface);
          } else {
            parts.push(getRandomHexSegment());
          }
        } else {
          // Set remaining parts at random
          for (let i = 0; i < 4 - sequenceLength; i++) {
            parts.push(getRandomHexSegment());
          }
        }

        return parts;
      }

      // Select Random Prefix
      const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];
      let parts = [prefix];

      // Part 2 and 3
      parts.push(getRandomHexSegment(), getRandomHexSegment());

      if (Math.random() < 0.2) {
        // Part 4 has 20% chance to be 0000
        parts.push('0000');
        // Parts 5 - 8
        parts = [...parts, ...getRandomZeroSequence()];
      } else {
        parts.push(getRandomHexSegment()); // Otherwise Part 4 is random hex
        if (Math.random() < 0.5) {
          // Parts 5 - 8 has 50% to have 0000 sequence
          parts = [...parts, ...getRandomZeroSequence()];
        } else {
          // Otherwise 5 - 8 are random
          parts.push(
            getRandomHexSegment(),
            getRandomHexSegment(),
            getRandomHexSegment(),
            getRandomHexSegment(),
          );
        }
      }

      // Compress the address
      let compressedV6 = parts.map((part) => part.replace(/\b0+/g, '') || '0').join(':');

      // Search for all occurrences of continuous '0' octets
      let zeros = [...compressedV6.matchAll(/\b:?(?:0+:?){2,}/g)];

      //If there are occurences, see which is the longest one and replace it with '::'
      if (zeros.length > 0) {
        let max = '';
        zeros.forEach((item) => {
          if (item[0].replaceAll(':', '').length > max.replaceAll(':', '').length) {
            max = item[0];
          }
        });
        compressedV6 = compressedV6.replace(max, '::');
      }

      return compressedV6;
    }

    function getRandomPublicIpV6() {
      while (true) {
        const ip = generateRandomIPv6();

        if (!isInPrivateIpV6Subnets(ip)) {
          return ip;
        }
      }
    }

    let noisyIps = [];
    if (privateV4Subnets.length > 0 && privateV6Subnets.length > 0) {
      const v4v6Partition = Math.random() * (0.8 - 0.2) + 0.2; // random value from 0.2 to 0.8
      while (noisyIps.length < noiseCount) {
        const ipVersionIs4 = Math.random() < v4v6Partition;
        if (ipVersionIs4) {
          noisyIps.push(getRandomPublicIpV4());
        } else {
          noisyIps.push(getRandomPublicIpV6());
        }
      }
    }

    return noisyIps;
  }, [noiseCount, privateV4Subnets, privateV6Subnets]);

  // common functions
  const updateCurrentFileName = useCallback((e) => {
    const newFileName = e.target.value.trim();
    // regex allows for alphanumeric characters, '-', '_', and ' ' (space).
    const fileNameRegex = /^[0-9a-zA-Z\-_ ]+$/;
    if (!validateFileExtensions(newFileName, ['.json'])) {
      setIpsWarning({
        alertType: ALERT_TYPES.error,
        message: "Export file must end in '.json'",
        title: 'Invalid File Extension',
      });
    } else if (!fileNameRegex.test(newFileName.replace(/\.[^/.]+$/, ''))) {
      setIpsWarning({
        alertType: ALERT_TYPES.error,
        message:
          'File name must consist of alphanumeric characters, underscores, hyphens, and or spaces',
        title: 'Invalid File Name',
      });
    } else {
      setIpsWarning(undefined);
    }
    setFileName(newFileName);
  }, []);

  // Selection here is a little funky, so this function does the following
  // if ALL was previously selected, and a new value is selected, then the new value being the latter choice will remove the ALL
  // if a non-ALL value was previously selected, and ALL is selected, then we remove everything except the ALL
  // the selection argument passed in is ORDERED, so we can tell when a user selects ALL relative to other selections
  const updatePorts = useCallback((selection) => {
    // we need to determine what to prune (if anything) if length > 1
    if (selection.length > 1) {
      if (selection[selection.length - 1].value === ALL_OPTION.value) {
        // if ALL was the most recent selection
        setPorts([ALL_OPTION]);
      } else {
        // prune ALL from the selection since it wasn't the latest option
        const noAllSelection = selection.filter((option) => {
          return option.value !== ALL_OPTION.value;
        });
        setPorts(noAllSelection);
      }
    } else if (selection.length === 1) {
      setPorts([selection[0]]);
    } else {
      setPorts([]);
    }
  }, []);

  const onExport = useCallback(() => {
    function shuffle(array: string[]) {
      let currentIndex = array.length;

      while (currentIndex !== 0) {
        // Pick a remaining element...
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
      }
      return array;
    }
    const portValues =
      ports.length === 1 && ports[0].value === ALL_OPTION.value
        ? ALL_OPTION.value
        : ports.map((port) => {
            return port.value;
          });
    const ips = addNoise ? ipArray.concat(noisyIps) : ipArray;
    const exportObject = {
      name: fileName.split('.')[0], // we restrict the use of periods in the file name to only be available to delimit the extension, so this should be safe
      fleet: scanScope,
      ports: portValues,
      ips: shuffle([...new Set(ips)]), // unique the results, then shuffle
    };
    exportJsonToFile(exportObject, fileName, undefined, 2);
    setCommand(`aws s3 cp ${fileName} ${s3Path}`);
  }, [addNoise, fileName, ipArray, noisyIps, ports, scanScope]);

  const copyCommand = useCallback(() => {
    navigator.clipboard.writeText(command);
  }, [command]);

  // text input functions
  const updateCurrentText = useCallback((newText: string) => {
    setCurrentText(newText);
  }, []);

  const debounceCurrentText = useRef(
    debounce((newString: string) => {
      updateCurrentText(newString);
    }, DEBOUNCE_TIMEOUT),
  );

  // file upload functions
  async function onFileSelect(file: File | undefined) {
    if (file) {
      // Perform some file validation
      let customError = undefined;
      if (!file.name.endsWith('.txt')) {
        customError = 'Upload a .txt file with one IP addresses on each line';
      }
      const { error, warning, newFile } = await validateIpFile(file, customError, undefined, true);
      if (error || warning) {
        setIpsWarning({
          alertType: error ? ALERT_TYPES.error : ALERT_TYPES.warning,
          message: error ?? warning,
          title: 'Invalid IPs',
        });
      } else if (newFile.length + (addNoise ? noiseCount : 0) > MAX_IP_EXPORT_COUNT) {
        setIpsWarning(maxIpCountError);
      } else {
        setIpsWarning(undefined);
      }
      setIpArray(newFile);
      setUploadedFile(file);
      setOnDemandUploadState(OnDemandUploadState.File);
      setCurrentText('');
    }
  }

  // does not reset selected ports, scope, or noise state, as those are desirable to keep when resetting
  const resetState = useCallback((uploadState?: OnDemandUploadState) => {
    setUploadedFile(undefined);
    setIpArray([]);
    setIpsWarning(undefined);
    setOnDemandUploadState(uploadState ?? OnDemandUploadState.Initial);
    setCurrentText('');
  }, []);

  // useEffects
  useEffect(() => {
    if (onDemandUploadState === OnDemandUploadState.Manual) {
      const { invalidIps, duplicateIps, seenIps, validIps } = validateIPSet(currentText);
      if (invalidIps.size > 0 || duplicateIps.size > 0) {
        const warning = createInvalidIpsWarning(invalidIps, duplicateIps, seenIps.size);
        setIpsWarning({ alertType: ALERT_TYPES.warning, message: warning, title: 'Invalid IPs' });
      } else if (validIps.size + (addNoise ? noiseCount : 0) > MAX_IP_EXPORT_COUNT) {
        setIpsWarning(maxIpCountError);
      } else {
        setIpsWarning(undefined);
      }
      setIpArray(Array.from(validIps));
    }
  }, [addNoise, currentText, onDemandUploadState, noiseCount]);

  // misc
  const radioCardStyle = {
    padding: '16px',
    flex: 1,
  };

  if (!s3Path) {
    console.log('on demand s3 path is not configured');
    return; // if the user has no available s3 path, then that missing configuration will need to hide this feature. we are choosing to fail rather ungracefully, however, this failure looks exactly like any other page a user may have navigated to that doesn't exist, so it won't feel any different than any other invalid page
    // TODO: work on 404 pages to then tackle this scenario, GL issue #294
  }

  return (
    <AuthorizedElement roles={[ON_DEMAND_ROLE]}>
      <div className="uk-align-center on-demand">
        {command && <Banner message="" title={'Success'} alertType={ALERT_TYPES.success} />}
        <div>On-Demand</div>
        <div className="uk-text-muted">{description}</div>
        {command ? (
          <div className="uk-position-relative uk-margin-large-top">
            <div className="on-demand-command">{command}</div>
            <div className="uk-flex uk-flex-right uk-margin-medium-top">
              <button
                className="uk-button uk-margin-right"
                onClick={() => {
                  resetState();
                  setCommand(undefined);
                  setFileName(getDefaultFileName(EXPORT_FILE_EXTENSION, EXPORT_FILE_NAME_SALT));
                }}
              >
                New
              </button>
              <button
                className="uk-button uk-button-default"
                style={{ width: '141px', lineHeight: '30px' }} // ensures button doesn't shrink on copy feedback loop
                onClick={() => {
                  setShowCopySuccess(true);
                  copyCommand();
                  setTimeout(() => {
                    setShowCopySuccess(false);
                  }, 2000);
                }}
              >
                {showCopySuccess ? (
                  <Fragment>
                    <Checkmark />
                    Copied
                  </Fragment>
                ) : (
                  'Copy Command'
                )}
              </button>
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="subsection-label uk-margin-medium-top">scope</div>
            <div className="uk-flex" style={{ gap: '16px' }}>
              <RadioCard
                title="GLOBAL"
                description="Measure from locations throughout the world"
                onSelect={() => setScanScope(ScanScope.Global)}
                radioPosition={RadioPosition.Left}
                style={radioCardStyle}
                selected={scanScope === ScanScope.Global}
              />
              <RadioCard
                title="IN-COUNTRY"
                description="Measure from locations only in-country"
                onSelect={() => setScanScope(ScanScope.Domestic)}
                radioPosition={RadioPosition.Left}
                style={radioCardStyle}
                selected={scanScope === ScanScope.Domestic}
              />
            </div>
            <div className="subsection-label uk-margin-medium-top">ports</div>
            <Select
              options={[ALL_OPTION, ...getPortOptions()]}
              styles={reactSelectDropdownStyle() as StylesConfig}
              isMulti
              components={{
                DropdownIndicator,
                MultiValueRemove,
                ClearIndicator,
              }}
              onChange={updatePorts}
              value={ports}
            />
            <div className="subsection-label uk-margin-medium-top">ip addresses</div>
            {onDemandUploadState === OnDemandUploadState.Initial ? (
              <OnDemandDragAndDrop
                onFileSelect={(file) => {
                  setLoading(true);
                  onFileSelect(file).then(() => {
                    setLoading(false);
                  });
                  setOnDemandUploadState(OnDemandUploadState.File);
                }}
                onInputChange={() => setOnDemandUploadState(OnDemandUploadState.Manual)}
              />
            ) : onDemandUploadState === OnDemandUploadState.File ? (
              loading ? (
                <Spinner />
              ) : (
                <div className="uk-margin-small-top uk-padding-small file-selection">
                  <Attachment />
                  <div>
                    <div className="uk-margin-left">{uploadedFile.name}</div>
                    <div className="uk-margin-left file-size">
                      {humanizeFileSize(uploadedFile.size)}
                    </div>
                  </div>
                  <Close
                    className="uk-margin-auto-left app-cursor-pointer"
                    onClick={() => resetState()}
                  />
                </div>
              )
            ) : (
              <textarea
                className="uk-textarea"
                id="ip-address-input"
                onChange={(newText) => {
                  debounceCurrentText.current(newText.target.value);
                }}
              />
            )}
            {onDemandUploadState === OnDemandUploadState.File ? (
              <div className="ip-input-footer">
                <div
                  className="ip-input-option"
                  onClick={() => {
                    resetState(OnDemandUploadState.Manual);
                  }}
                >
                  Input manually
                </div>
              </div>
            ) : (
              onDemandUploadState === OnDemandUploadState.Manual && (
                <div className="ip-input-footer">
                  <label className="ip-input-option">
                    <input
                      type="file"
                      multiple={false}
                      value={undefined}
                      title=" "
                      onClick={(event: any) => (event.target.value = '')}
                      onChange={(ev) => {
                        onFileSelect(ev.target.files[0]);
                      }}
                      style={inputStyle}
                      accept="text/plain"
                    />
                    Upload a file
                  </label>
                </div>
              )
            )}
            {ipsWarning && <Banner {...ipsWarning} noClose={true} />}
            <div className="subsection-label uk-margin-medium-top">file name</div>
            <input className="uk-input" value={fileName} onChange={updateCurrentFileName} />
            <div className="uk-flex uk-margin-medium-top">
              <div style={{ height: '32px', lineHeight: '32px' }}>
                <label className="app-cursor-pointer">
                  <input
                    className="uk-margin-right uk-checkbox"
                    type="checkbox"
                    onChange={() => setAddNoise((curr) => !curr)}
                    checked={addNoise}
                  />
                  Add noise
                </label>
              </div>
              <div className="uk-flex uk-margin-auto-left">
                <button
                  disabled={
                    ipArray.length === 0 ||
                    ports.length === 0 ||
                    (ipsWarning && ipsWarning.alertType === ALERT_TYPES.error)
                  }
                  className="uk-button uk-button-default uk-margin-medium-left"
                  onClick={onExport}
                >
                  Export
                </button>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </AuthorizedElement>
  );
}

type OnDemandDragAndDropProps = {
  onFileSelect: (file: File) => void;
  onInputChange: Function;
};

const inputStyle = {
  width: '0.1px',
  height: '0.1px',
  zIndex: -1,
  opacity: 0,
};

const OnDemandDragAndDrop: FunctionComponent<OnDemandDragAndDropProps> = ({
  onFileSelect,
  onInputChange,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  function toFilesArray(files: FileList) {
    return files ? range(files.length).map((i: number) => files[i]) : [];
  }

  function onDragOver(event: DragEvent<HTMLDivElement>) {
    containerRef?.current?.classList.add('uk-dragover');
    if (event.dataTransfer?.files.length > 0) {
      event.dataTransfer.dropEffect = 'copy';
    }
    event.preventDefault();
  }

  function onDragLeave(event: DragEvent<HTMLDivElement>) {
    containerRef?.current?.classList.remove('uk-dragover');
    event.preventDefault();
  }

  function onDrop(event: DragEvent<HTMLDivElement>) {
    const files = toFilesArray(event.dataTransfer?.files);
    if (files.length > 0) {
      onFileSelect(files[0]);
    }
    containerRef?.current?.classList.remove('uk-dragover');
    event.preventDefault();
  }

  const dragAndDropProps = { onDragOver, onDragLeave, onDrop };

  return (
    <div
      ref={containerRef}
      data-uk-form-custom="target: dummy"
      {...dragAndDropProps}
      style={{ minWidth: '100%' }}
    >
      <div className="uk-text-center uk-display-inline-block file-selector">
        <label className="uk-button uk-button-default uk-flex-inline uk-flex-middle">
          <input
            type="file"
            multiple={false}
            value={undefined}
            title=" "
            onClick={(event: any) => (event.target.value = '')}
            onChange={(ev) => {
              onFileSelect(ev.target.files[0]);
            }}
            style={inputStyle}
            accept="text/plain"
          />
          Upload a file
        </label>
        <div className="strike-text">
          <span>OR</span>
        </div>
        <button
          className="uk-button uk-button-secondary uk-flex-inline uk-flex-middle"
          onClick={() => onInputChange()}
        >
          Input manually
        </button>
      </div>
    </div>
  );
};
