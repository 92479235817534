import { UserDataset } from 'model';
import { findAndReplaceDatasetInCrossInstanceParams, saveUserDataset } from 'utils';

export function exportJsonToFile(
  data: any,
  filename: string,
  replacer?: (this: any, key: string, value: any) => any,
  space?: number,
) {
  const dataStr = JSON.stringify(data, replacer, space);
  let blob = new Blob([dataStr], { type: 'application/json' });
  let url = URL.createObjectURL(blob);
  if (!filename.endsWith('.json')) {
    filename += '.json';
  }
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export async function uploadCrossInstanceDatasets(crossInstanceParams: any) {
  if (crossInstanceParams.datasets) {
    // We need to loop through all the datasets referenced in the investigation
    // One by one create a file object from each and upload as a new dataset.
    // Replace the new dataset ID in each place it appears in the investigation.
    for (let oldDatasetId of Object.keys(crossInstanceParams.datasets)) {
      const crossInstanceDataset: UserDataset & { items: string[] } =
        crossInstanceParams.datasets[oldDatasetId];
      const file = new File(
        [crossInstanceDataset?.items?.join('\n')],
        crossInstanceDataset?.filename ?? crossInstanceDataset?.name,
      );
      delete crossInstanceDataset.items;
      const dataset = await saveUserDataset(crossInstanceDataset, file, true);
      findAndReplaceDatasetInCrossInstanceParams(oldDatasetId, dataset.id, crossInstanceParams);
    }
  }
}

export function validateFileExtensions(fileName: string, validExtensions: string[]) {
  // remove any periods so comparisons can be apples to apples
  const cleanedExtensions = validExtensions.map((extension) => {
    return extension.replace('.', '');
  });
  // if extensions are provided, check to see if the file name does not match any of them
  if (cleanedExtensions && cleanedExtensions.length > 0) {
    const fileNameExt = fileName.split('.').pop();
    return cleanedExtensions.includes(fileNameExt);
  }
  return false;
}

export function getDefaultFileName(extension: string, salt?: string) {
  const datedName = new Date().toISOString().split('.')[0].replaceAll(':', '_');
  const cleanedSalt = salt ? `-${salt}` : '';
  const cleanedExtension = extension.startsWith('.') ? extension : `.${extension}`;
  return `${datedName}${cleanedSalt}${cleanedExtension}`;
}
