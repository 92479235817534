import { flatten } from 'lodash';
import { AssetType, Attribute, QueryFilterComparator } from 'model';
import { hex2bin } from './miscUtils';
import { humanizeDateTime } from './numberUtils';
import { MAX_ASN } from './asnUtils';
import { pjlCodeMap } from './pjl';

const PORTS_RESOLUTION = 'ports'; // Hardcode necessary for import

const defaultPortsSearch = {
  selection: { label: 'Ports', typename: PORTS_RESOLUTION, value: PORTS_RESOLUTION },
  resolution: PORTS_RESOLUTION,
  sort: 'ip',
  view: 'portsViewer',
};

function attributeToString(attribute: any) {
  return attribute !== undefined ? attribute?.toString() : undefined;
}

function pruneEmptyAttributes(attributes: Attribute[]) {
  return attributes?.filter(
    (obj) =>
      obj.value !== undefined &&
      obj.value !== '' &&
      obj.value !== null &&
      !(Array.isArray(obj.value) && obj.value.length === 0),
  );
}

export function getApplicationTypeOptions() {
  const applicationTypes = [
    'BANNER',
    'BGP',
    'BITTORRENT',
    'COAP',
    'DNS',
    'FTP',
    'GTPV1-C',
    'GTPV1-U',
    'GTPV2-C',
    'GTPV2-U',
    'HTTP',
    'HTTPS',
    'IMAP',
    'IMAP over TLS/SSL',
    'ISAKMP',
    'JARM',
    'L2TP',
    'LDAP',
    'MDNS',
    'MS-SQL',
    'MYSQL',
    'NTLM',
    'NTP',
    'NetBIOS',
    'OPENVPN',
    'PJL',
    'POP3',
    'POP3 over TLS/SSL',
    'POSTGRES',
    'PPTP',
    'QUIC',
    'RDP',
    'REDIS',
    'RTSP',
    'SIP',
    'SMB',
    'SMTP over TLS/SSL',
    'SNMP-VERSION-1',
    'SNMPV2C',
    'SNMPV3',
    'SSDP',
    'SSH',
    'TELNET',
    'TLS',
    'WINBOX',
  ];

  return applicationTypes.map((type: string) => ({ label: type, value: type }));
}

export function getPortOptions() {
  const ports = [
    '21',
    '22',
    '23',
    '26',
    '53',
    '80',
    '81',
    '82',
    '83',
    '84',
    '88',
    '110',
    '119',
    '123',
    '137',
    '139',
    '143',
    '161',
    '179',
    '389',
    '443',
    '444',
    '465',
    '500',
    '554',
    '587',
    '636',
    '992',
    '993',
    '995',
    '1000',
    '1024',
    '1025',
    '1194',
    '1234',
    '1433',
    '1701',
    '1723',
    '1900',
    '2077',
    '2082',
    '2083',
    '2086',
    '2087',
    '2121',
    '2123',
    '2152',
    '2222',
    '2323',
    '3000',
    '3001',
    '3128',
    '3306',
    '3333',
    '3389',
    '4000',
    '4321',
    '4430',
    '4443',
    '4444',
    '4500',
    '4567',
    '5000',
    '5001',
    '5060',
    '5222',
    '5353',
    '5357',
    '5432',
    '5555',
    '5601',
    '5683',
    '5985',
    '5986',
    '6000',
    '6001',
    '6379',
    '6443',
    '6666',
    '6881',
    '7001',
    '7002',
    '7170',
    '7443',
    '7547',
    '7777',
    '8000',
    '8001',
    '8008',
    '8009',
    '8010',
    '8080',
    '8081',
    '8083',
    '8085',
    '8086',
    '8087',
    '8088',
    '8089',
    '8090',
    '8099',
    '8112',
    '8123',
    '8181',
    '8291',
    '8443',
    '8554',
    '8800',
    '8880',
    '8888',
    '8889',
    '9000',
    '9001',
    '9002',
    '9009',
    '9051',
    '9080',
    '9090',
    '9091',
    '9092',
    '9100',
    '9191',
    '9200',
    '9443',
    '9600',
    '9761',
    '9944',
    '9998',
    '9999',
    '10000',
    '10001',
    '10011',
    '10022',
    '10080',
    '10250',
    '10443',
    '10554',
    '12345',
    '20000',
    '33389',
    '37777',
    '49152',
    '50000',
    '60443',
  ];

  return ports.map((type: string) => ({ label: type, value: type }));
}

export const ipVersionValueOptions = [
  { label: 'IPv4', value: '4' },
  { label: 'IPv6', value: '6' },
];

const protocolSummaryAttributeMap = {
  ssh: getSSHSummaryAttributes,
  http: getHTTPSummaryAttributes,
  ftp: getFTPSummaryAttributes,
  ntp: getNTPSummaryAttributes,
  quic: getQUICSummaryAttributes,
  mysql: getMYSQLSummaryAttributes,
  isakmp: getISAKMPSummaryAttributes,
  banner: getBannerSummaryAttributes,
  telnet: getBannerSummaryAttributes,
  dns: getDNSSummaryAttributes,
  snmp: getSNMPSummaryAttributes,
  mssql: getMSSQLSummaryAttributes,
  redis: getRedisSummaryAttributes,
  tls: () => [],
  pjl: getPJLSummaryAttributes,
  pop3: getPOP3SummaryAttributes,
  coap: getCOAPSummaryAttributes,
  nbns: getNBNSSummaryAttributes,
  openvpn: getOpenVPNSummaryAttributes,
  mdns: getDNSSummaryAttributes,
  imap: getIMAPSummaryAttributes,
  postgres: getPostgresSummaryAttributes,
  smtp: getSMTPSummaryAttributes,
  sip: getSIPSummaryAttributes,
  ssdp: getSSDPSummaryAttributes,
  smb: getSMBSummaryAttributes,
  bittorrent: getBittorrentSummaryAttributes,
  gtp_c: getGTPSummaryAttributes,
  gtp_u: getGTPSummaryAttributes,
  pptp: getPPTPSummaryAttributes,
  rdp: getRDPSummaryAttributes,
  ntlm: getNTLMSummaryAttributes,
  l2tp: getL2TPSummaryAttributes,
  winbox: getWinboxSummaryAttributes,
  bgp: getBGPSummaryAttributes,
  ldap: getLDAPSummaryAttributes,
  jarm: () => [],
  rtsp: getRTSPSummaryAttributes,
};

export function getPortAttributes(applicationData: any) {
  const { protocol, result, tls, parameters } = applicationData || {};
  const { domain } = parameters || {};
  const jarm = result?.['jarm']?.fingerprint;

  const resultData = result?.[protocol];

  let attributes = [];
  if (resultData) {
    const summaryFunc = protocolSummaryAttributeMap[protocol];
    if (summaryFunc) {
      attributes = summaryFunc(resultData, domain);
    }
  }

  const hasTLS = tls || jarm;
  const tlsAttributes = hasTLS && getTLSSummaryAttributes(tls, domain, jarm);

  return {
    attributes: pruneEmptyAttributes(attributes),
    tlsAttributes: pruneEmptyAttributes(tlsAttributes),
  };
}

function getSSHSummaryAttributes(result: any) {
  const { server_id, server_key_exchange, key_exchange } = result;
  const {
    kex_algorithms,
    host_key_algorithms,
    server_to_client_ciphers,
    server_to_client_macs,
    hassh,
  } = server_key_exchange || {};
  const hostKeyFingerprint = key_exchange?.server_host_key?.fingerprint_sha256;
  const serverIdStr =
    server_id && `SSH ${server_id.version} ${server_id.software.replaceAll('_', ' ')}`;

  const hasshAQ = {
    ...defaultPortsSearch,
    filterClauses: [
      {
        type: 'Ports:HASSH', // Hardcode necessary for import
        value: hassh,
        comparator: QueryFilterComparator.Is,
      },
    ],
  };

  return [
    { label: 'Software', value: serverIdStr },
    { label: 'Host Key Fingerprint', value: hostKeyFingerprint },
    { label: 'HASSH', value: hassh, advancedQuery: hasshAQ },
    { label: 'Kex Algorithms', value: kex_algorithms },
    { label: 'Host Key Algorithms', value: host_key_algorithms },
    { label: 'Server Ciphers', value: server_to_client_ciphers },
    { label: 'Server MACs', value: server_to_client_macs },
  ];
}
function extractTitle(html: string): string | null {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const titleElement = doc.querySelector('title');
  return titleElement ? titleElement.textContent : null;
}

function getHTTPSummaryAttributes(result: any, hostname?: string) {
  const { headers, protocol, status_line, body_sha256, body, favicon } = result?.response || {};
  const title = extractTitle(body);

  return [
    { label: 'Software', value: headers?.server },
    { label: 'Request', value: hostname, forcedType: AssetType.Hostname },
    { label: 'Protocol', value: protocol?.name },
    { label: 'Status', value: status_line },
    { label: 'Body Hash', value: body_sha256 },
    { label: 'Favicon Hash', value: favicon?.md5_hash },
    { label: 'HTML Title', value: title },
    { label: 'Response Body', value: body },
  ];
}

function getFTPSummaryAttributes(result: any) {
  const { banner, auth_tls, auth_ssl } = result;

  const statusCode = banner && parseInt(banner.split(' ')[0]);

  const commonSoftware = [
    'Pure-FTPd',
    'bftpd',
    'Microsoft FTP Service',
    'ProFTPD',
    'MikroTik',
    'GNU inetutils',
    'Multicraft',
    'Serv-U ftpd',
    'vsFTPd',
  ];

  let software;
  const matchedSoftware = commonSoftware.filter((software: string) =>
    banner.toLowerCase().includes(software.toLowerCase()),
  );
  if (matchedSoftware.length === 1) {
    software = matchedSoftware[0];
  }

  const statusMeaningMap = {
    110: 'Restart marker reply. In this case, the text is exact and not left to the particular implementation; it must read: MARK yyyy = mmmm Where yyyy is User-process data stream marker, and mmmm server\'s equivalent marker (note the spaces between markers and "=").',
    120: 'Service ready in nnn minutes.',
    125: 'Data connection already open; transfer starting.',
    150: 'File status okay; about to open data connection.',
    200: 'Command okay.',
    202: 'Command not implemented, superfluous at this site.',
    211: 'System status, or system help reply.',
    212: 'Directory status.',
    213: 'File status.',
    214: 'Help message. On how to use the server or the meaning of a particular non-standard command.  This reply is useful only to the human user.',
    215: 'NAME system type. Where NAME is an official system name from the list in the Assigned Numbers document.',
    220: 'Service ready for new user.',
    221: 'Service closing control connection. Logged out if appropriate.',
    225: 'Data connection open; no transfer in progress.',
    226: 'Closing data connection. Requested file action successful (for example, file transfer or file abort).',
    227: 'Entering Passive Mode (h1,h2,h3,h4,p1,p2).',
    230: 'User logged in, proceed.',
    250: 'Requested file action okay, completed.',
    257: '"PATHNAME" created.',
    331: 'User name okay, need password.',
    332: 'Need account for login.',
    350: 'Requested file action pending further information.',
    421: 'Service not available, closing control connection. This may be a reply to any command if the service knows it must shut down.',
    425: "Can't open data connection.",
    426: 'Connection closed; transfer aborted.',
    450: 'Requested file action not taken. File unavailable (e.g., file busy).',
    451: 'Requested action aborted: local error in processing.',
    452: 'Requested action not taken. Insufficient storage space in system.',
    500: 'Syntax error, command unrecognized. This may include errors such as command line too long.',
    501: 'Syntax error in parameters or arguments.',
    502: 'Command not implemented.',
    503: 'Bad sequence of commands.',
    504: 'Command not implemented for that parameter.',
    530: 'Not logged in.',
    532: 'Need account for storing files.',
    550: 'Requested action not taken. File unavailable (e.g., file not found, no access).',
    551: 'Requested action aborted: page type unknown.',
    552: 'Requested file action aborted. Exceeded storage allocation (for current directory or dataset).',
    553: 'Requested action not taken. File name not allowed.',
  };

  const statusMeaning = statusMeaningMap?.[statusCode];

  return [
    { label: 'Software', value: software },
    { label: 'Banner', value: banner },
    { label: 'Auth TLS Response', value: auth_tls },
    { label: 'Auth SSL Response', value: auth_ssl },
    { label: 'Status Code', value: statusCode },
    { label: 'Status Meaning', value: statusMeaning },
  ];
}

function getNTPSummaryAttributes(result: any) {
  const { mode, ppoll, readvar } = result;
  const { processor, system } = readvar || {};

  const version = readvar?.version || result?.version;
  const stratum = readvar?.stratum || result?.stratum;
  const precision = readvar?.precision || result?.precision;
  const refid = readvar?.refid || result?.refid;

  const refidCodeMap = {
    GOES: 'Geosynchronous Orbit Environment Satellite',
    GPS: 'Global Position System',
    GAL: 'Galileo Positioning System',
    PPS: 'Generic pulse-per-second',
    IRIG: 'Inter-Range Instrumentation Group',
    WWVB: 'LF Radio WWVB Ft. Collins, CO 60 kHz',
    DCF: 'LF Radio DCF77 Mainflingen, DE 77.5 kHz',
    HBG: 'LF Radio HBG Prangins, HB 75 kHz',
    MSF: 'LF Radio MSF Anthorn, UK 60 kHz',
    JJY: 'LF Radio JJY Fukushima, JP 40 kHz, Saga, JP 60 kHz',
    LORC: 'MF Radio LORAN C station, 100 kHz',
    TDF: 'MF Radio Allouis, FR 162 kHz',
    CHU: 'HF Radio CHU Ottawa, Ontario',
    WWV: 'HF Radio WWV Ft. Collins, CO',
    WWVH: 'HF Radio WWVH Kauai, HI',
    NIST: 'NIST telephone modem',
    ACTS: 'NIST telephone modem',
    USNO: 'USNO telephone modem',
    PTB: 'European telephone modem',
    ACST: 'The association belongs to a unicast server',
    AUTH: 'Server authentication failed',
    AUTO: 'Autokey sequence failed',
    BCST: 'The association belongs to a broadcast server',
    CRYP: 'Cryptographic authentication or identification failed',
    DENY: 'Access denied by remote server',
    DROP: 'Lost peer in symmetric mode',
    RSTR: 'Access denied due to local policy',
    INIT: 'The association has not yet synchronized for the first time',
    MCST: 'The association belongs to a dynamically discovered server',
    NKEY: 'No key found. Either the key was never installed or is not trusted',
    NTSN: 'Network Time Security (NTS) negative-acknowledgment (NAK)',
    RATE: 'Rate exceeded. The server has temporarily denied access because the client exceeded the rate threshold',
    RMOT: 'Alteration of association from a remote host running ntpdc.',
    STEP: 'A step change in system time has occurred, but the association has not yet resynchronized',
    ATOM: 'with ATOM PPS',
    DCFA: 'DCF77 with amplitude modulation',
    DCFP: 'DCF77 with phase modulation)/pseudo random phase modulation',
    GPSS: 'GPS (with shared memory access - Meinberg)',
    GPSI: 'GPS (with interrupt based access - Meinberg)',
    GLNS: 'GPS/GLONASS (with shared memory access - Meinberg)',
    GLNI: 'GPS/GLONASS (with interrupt based access - Meinberg)',
    LCL: 'Undisciplined local clock',
    LOCL: 'Undisciplined local clock',
  };

  let refidFormatted = refid;
  // Only format non-readvar refid
  if (!readvar?.refid) {
    if ((stratum === 0 || stratum === 1) && refid !== '00:00:00:00') {
      const refidCode = refid
        ?.split(':')
        .map((hex: string) => {
          let str = '';
          for (let n = 0; n < hex.length; n += 2) {
            str += String.fromCharCode(parseInt(hex.substring(n, 2), 16));
          }
          return str;
        })
        .join('');
      const refidCodeDetails = refidCodeMap[refidCode.toUpperCase()];
      refidFormatted = `${refidCode}${refidCodeDetails ? ` (${refidCodeDetails})` : ''}`;
    } else if (stratum >= 2) {
      refidFormatted = refid
        ?.split(':')
        .map((hex: string) => parseInt(hex, 16))
        .join('.');
    }
  }

  return [
    { label: 'Version', value: version },
    { label: 'System', value: system },
    { label: 'Processor', value: processor === 'unknown' ? 'unreported' : processor },
    { label: 'Mode', value: mode },
    { label: 'Stratum', value: stratum },
    { label: 'Poll', value: ppoll },
    { label: 'Precision', value: precision },
    { label: 'Reference ID', value: refidFormatted },
  ];
}

const quicVersionControllerMap = {
  IETF: [
    RegExp('0000000[0-1]'),
    RegExp('6b3343cf'),
    RegExp('709a50c4'),
    RegExp('[a-f0-9]a[a-f0-9]a[a-f0-9]a[a-f0-9]a'),
    RegExp('ff[a-f0-9]{6}$'),
  ],
  Google: [
    RegExp('5130303[1-9]'),
    RegExp('5130313[0-9]'),
    RegExp('5130323[0-9]'),
    RegExp('5130333[0-9]'),
    RegExp('5130343[0-9]'),
    RegExp('5130353[0-9]'),
    RegExp('51303939'),
    RegExp('5430343[8-9]'),
    RegExp('5430353[0-9]'),
    RegExp('54303939'),
    RegExp('50524f58'),
  ],
  NetApp: [RegExp('454747[a-f0-9]{2}$')],
  'Private Octopus': [RegExp('50435130')],
  Anapaya: [RegExp('5c10000[0-f]')],
  'quic-go': [RegExp('51474f([0-1]?[0-9]?[0-9]?|2[0-4][0-9]|25[0-5])$')], // 51474f[0-255]
  quicly: [RegExp('91c170([0-1]?[0-9]?[0-9]?|2[0-4][0-9]|25[0-5])$')], // 91c170[0-255]
  Microsoft: [RegExp('abcd000[a-f0-9]')],
  Mozilla: [RegExp('f123f0c[a-f0-9]')],
  Facebook: [RegExp('faceb00[a-f0-9]')],
  'ETH Zurich': [RegExp('f0f0f0f[a-f0-9]')],
  'Telecom Italia': [RegExp('f0f0f1f[a-f0-9]')],
  'quinn-noise': [RegExp('f0f0f2f[a-f0-9]')],
  Tencent: [RegExp('0700700[a-f0-9]')],
};

function getQUICSummaryAttributes(result: any) {
  const { flags, srcid, dstid, versions } = result;

  const formattedVersions = versions.map((version: string) => {
    let versionController;
    Object.keys(quicVersionControllerMap).some((controller: string) => {
      const isMatch = quicVersionControllerMap[controller].some((re) => re.test(version));
      if (isMatch) {
        versionController = controller;
        return true;
      }
      return false;
    });

    return `${version}${versionController ? ` (${versionController})` : ''}`;
  });

  return [
    { label: 'Flags', value: flags },
    { label: 'Source ID', value: srcid },
    { label: 'Destination ID', value: dstid },
    { label: 'Versions', value: formattedVersions },
  ];
}

function getMYSQLSummaryAttributes(result: any) {
  const { protocol_version, server_version, error_code, error_id, error_message } = result;

  return [
    { label: 'Protocol Version', value: protocol_version },
    { label: 'Server Version', value: server_version },
    { label: 'Error Code', value: error_code },
    { label: 'Error ID', value: error_id },
    { label: 'Error Message', value: error_message },
  ];
}

function getISAKMPSummaryAttributes(result: any) {
  const { ispi, rspi, nextpayload, mjver, mnver, exchangetype, messageid, length } = result;

  let { flag_a, flag_c, flag_e, flag_i, flag_r, flag_v } = result;

  return [
    { label: 'Initiator SPI', value: ispi },
    { label: 'Responder SPI', value: rspi },
    {
      label: 'Next Payload',
      value: flatten([nextpayload]).map((payload) => parseISAKMPNextPayload(payload)),
    },
    {
      label: 'Version',
      value: mjver && mnver ? `${parseInt(mjver, 16)}.${parseInt(mnver, 16)}` : undefined,
    },
    { label: 'Exchange Type', value: parseISAKMPExchangeType(exchangetype) },
    { label: 'Encryption Flag', value: attributeToString(flag_e) },
    { label: 'Commit Flag', value: attributeToString(flag_c) },
    { label: 'Authentication Flag', value: attributeToString(flag_a) },
    { label: 'Invalid Flag', value: attributeToString(flag_i) },
    { label: 'R Flag', value: attributeToString(flag_r) },
    { label: 'V Flag', value: attributeToString(flag_v) },
    { label: 'Message ID', value: messageid },
    { label: 'Length', value: length },
  ];
}

function parseISAKMPNextPayload(nextpayload: number) {
  if (nextpayload >= 14 && nextpayload <= 127) {
    return 'RESERVED';
  } else if (nextpayload >= 128 && nextpayload <= 255) {
    return 'Private USE';
  }
  const nextPayloadTypes = {
    0: 'NONE',
    1: 'Security Association (SA)',
    2: 'Proposal (P)',
    3: 'Transform (T)',
    4: 'Key Exchange (KE)',
    5: 'Identification (ID)',
    6: 'Certificate (CERT)',
    7: 'Certificate Request (CR)',
    8: 'Hash (HASH)',
    9: 'Signature (SIG)',
    10: 'Nonce (NONCE)',
    11: 'Notification (N)',
    12: 'Delete (D)',
    13: 'Vendor ID (VID)',
  };

  return nextPayloadTypes[nextpayload];
}

function parseISAKMPExchangeType(exchangeType) {
  if (exchangeType >= 6 && exchangeType <= 31) {
    return 'ISAKMP Future Use';
  } else if (exchangeType >= 32 && exchangeType <= 239) {
    return 'DOI Specific Use';
  } else if (exchangeType >= 240 && exchangeType <= 255) {
    return 'Private Use';
  }
  const exchangeTypes = {
    0: 'NONE',
    1: 'Base',
    2: 'Identity Protection',
    3: 'Authentication Only',
    4: 'Aggressive',
    5: 'Informational',
  };

  return exchangeTypes[exchangeType];
}

function getBannerSummaryAttributes(result: any) {
  const { banner } = result;

  return [{ label: 'Banner', value: banner }];
}

function getDNSSummaryAttributes(result: any) {
  const { flags_rcode, flags_recavail } = result;

  return [
    { label: 'Recursion Available', value: attributeToString(flags_recavail) },
    { label: 'R Code', value: parseDNSRCode(flags_rcode) },
  ];
}

function parseDNSRCode(rcode) {
  const rcodeTypes = {
    0: 'No Error',
    1: 'Format Error',
    2: 'Server Failure',
    3: 'Non-Existent Domain',
    4: 'Not Implemented',
    5: 'Query Refused',
    6: 'Name Exists when it should not',
    7: 'RR Set Exists when it should not',
    8: 'RR Set that should exist does not',
    9: 'Server Not Authoritative for zone',
    10: 'Name not contained in zone',
    16: 'Bad OPT Version',
    17: 'Key not recognized',
    18: 'Signature out of time window',
    19: 'Bad TKEY Mode',
    20: 'Duplicate key name',
    21: 'Algorithm not supported',
  };

  let rcodeString = rcodeTypes[rcode] || rcode;

  if (rcode >= 3841 && rcode <= 4095) {
    rcodeString = 'Private Use';
  }

  return rcodeString;
}

function getSNMPSummaryAttributes(result: any) {
  const { msgVersion, version } = result;

  let software = result['var-bind_str']?.split(' #')[0];

  if (msgVersion === 'snmpv3') {
    const {
      engineid_enterprise,
      enterprise_name,
      msgAuthoritativeEngineID,
      engineid_format,
      engineid_ipv4,
      engineid_ipv6,
      engineid_mac,
      engineid_text,
    } = result;

    const enterpriseID =
      enterprise_name || engineid_enterprise
        ? enterprise_name ?? `Unknown (${engineid_enterprise})`
        : undefined;

    const engineidFormatMap = {
      0: 'Reserved',
      1: 'IPv4 Address',
      2: 'IPv6 Address',
      3: 'MAC Address',
      4: 'Text',
      5: 'Octets',
    };
    let engineidFormatStr = engineidFormatMap[engineid_format];

    if (engineid_format >= 6 && engineid_format <= 127) {
      engineidFormatStr = 'reserved';
    } else if (engineid_format >= 128 && engineid_format <= 255) {
      engineidFormatStr = 'defined by the enterprise';
    }

    return [
      { label: 'Enterprise ID', value: enterpriseID },
      { label: 'Authoritative Engine ID', value: msgAuthoritativeEngineID },
      { label: 'Engine ID Format', value: engineidFormatStr },
      { label: 'Engine ID IPv4', value: engineid_ipv4 },
      { label: 'Engine ID IPv6', value: engineid_ipv6 },
      { label: 'Engine ID MAC', value: engineid_mac },
      { label: 'Engine ID Text', value: engineid_text },
    ];
  }

  return [
    { label: 'Version', value: version === 'version-1' ? version : undefined },
    { label: 'Software', value: software },
  ];
}

function getMSSQLSummaryAttributes(result: any) {
  const { version, instance_name, encrypt_mode } = result;

  return [
    { label: 'Software', value: version ? `Microsoft SQL Server ${version}` : undefined },
    { label: 'Version', value: version },
    { label: 'Instance Name', value: instance_name },
    { label: 'Encrypt Mode', value: encrypt_mode },
  ];
}

function getRedisSummaryAttributes(result: any) {
  const { version, os, mode, ping_response, info_response, nonexistent_response, quit_response } =
    result;

  return [
    { label: 'Software', value: version ? `Redis ${version}` : undefined },
    { label: 'Operating System', value: os },
    { label: 'Mode', value: mode },
    { label: 'Ping Response', value: ping_response },
    { label: 'Info Response', value: info_response },
    { label: 'Nonexistent Response', value: nonexistent_response },
    { label: 'Quit Response', value: quit_response },
  ];
}

function getTLSSummaryAttributes(result: any, sni?: string, jarm?: string) {
  const { version, cipherSuite, curveId, ja3s } = result || {};

  const ja3sAQ = {
    ...defaultPortsSearch,
    filterClauses: [
      {
        type: 'Ports:JA3S', // Hardcode necessary for import
        value: ja3s,
        comparator: QueryFilterComparator.Is,
      },
    ],
  };

  const jarmAQ = {
    ...defaultPortsSearch,
    filterClauses: [
      {
        type: 'Ports:JARM', // Hardcode necessary for import
        value: jarm,
        comparator: QueryFilterComparator.Is,
      },
    ],
  };

  return [
    { label: 'Version', value: version },
    { label: 'SNI', value: sni, forcedType: AssetType.Hostname },
    { label: 'Cipher Suite', value: cipherSuite },
    { label: 'Curve ID', value: curveId },
    { label: 'JA3S', value: ja3s, advancedQuery: ja3sAQ },
    { label: 'JARM', value: jarm, advancedQuery: jarmAQ },
  ];
}

function getPOP3SummaryAttributes(result: any) {
  const { banner, greeting, preTLS, postTLS } = result;

  return [
    // {label: 'Software', value: software},
    { label: 'Banner', value: banner },
    { label: 'Greeting', value: greeting },
    { label: 'Pre TLS', value: preTLS },
    { label: 'Post TLS', value: postTLS },
  ];
}

function getCOAPSummaryAttributes(result: any) {
  const { version, type, code, mid, payload, token } = result;

  const messageTypeMap = {
    0: 'Confirmable',
    1: 'Non-confirmable',
    2: 'Acknowledgement',
    3: 'Reset',
  };

  const responseClassMap = {
    2: 'Success',
    4: 'Client Error',
    5: 'Server Error',
  };

  const responseCodeBits = code && Number(code).toString(2).slice(0, 3);
  const responseClassCode = responseCodeBits && parseInt(responseCodeBits, 2);
  const responseClass = responseClassMap?.[responseClassCode];

  return [
    { label: 'Response Class', value: responseClass },
    { label: 'Version', value: version },
    { label: 'Message Type', value: messageTypeMap[type] },
    { label: 'Code', value: code },
    { label: 'Message ID', value: mid },
    { label: 'Token', value: token },
    { label: 'Payload', value: payload },
  ];
}

function getNBNSSummaryAttributes(result: any) {
  const {
    unit_id,
    netbios_name,
    flags_opcode,
    flags_authoritative,
    flags_truncated,
    flags_recavail,
    flags_rcode,
  } = result;

  const operationCodeMap = {
    0: 'query',
    5: 'registration',
    6: 'release',
    7: 'WACK',
    8: 'refresh',
  };

  const responseCodeMap = {
    0: 'request',
    1: 'response',
  };

  return [
    { label: 'MAC Address', value: unit_id },
    { label: 'Names', value: netbios_name },
    { label: 'Operation Code', value: operationCodeMap[flags_opcode] || flags_opcode },
    { label: 'Response Code', value: responseCodeMap[flags_rcode] || flags_rcode },
    { label: 'Recursion Available', value: attributeToString(flags_recavail) },
    { label: 'Truncation', value: attributeToString(flags_truncated) },
    { label: 'Authoritative Answer', value: attributeToString(flags_authoritative) },
  ];
}

function getOpenVPNSummaryAttributes(result: any) {
  const { hmac, opcode } = result;

  const opcodeMap = {
    '0x01': 'P_CONTROL_HARD_RESET_CLIENT_V1',
    '0x02': 'P_CONTROL_HARD_RESET_SERVER_V1',
    '0x03': 'P_CONTROL_SOFT_RESET_V1',
    '0x04': 'P_CONTROL_V1',
    '0x05': 'P_ACK_V1',
    '0x06': 'P_DATA_V1',
    '0x07': 'P_CONTROL_HARD_RESET_CLIENT_V2',
    '0x08': 'P_CONTROL_HARD_RESET_SERVER_V2',
    '0x09': 'P_DATA_V2',
    '0x0a': 'P_CONTROL_HARD_RESET_CLIENT_V3',
  };

  return [
    { label: 'opcode', value: opcodeMap[opcode] || opcode },
    { label: 'HMAC', value: hmac },
  ];
}

function getIMAPSummaryAttributes(result: any) {
  const { greeting, Capabilities, preTLS, postTLS } = result;

  return [
    { label: 'Greeting', value: greeting },
    { label: 'Capabilities', value: Capabilities },
    { label: 'Pre TLS', value: preTLS },
    { label: 'Post TLS', value: postTLS },
  ];
}

function getPostgresSummaryAttributes(result: any) {
  const { supported_versions, is_ssl } = result;

  return [
    { label: 'Software', value: 'PostgreSQL' },
    { label: 'Supported Versions', value: supported_versions },
    { label: 'SSL Enabled', value: attributeToString(is_ssl) },
  ];
}

function getSMTPSummaryAttributes(result: any) {
  const { capabilities, help, trace } = result;

  const banner = trace[1].replace('S: ', '');

  return [
    { label: 'Banner', value: banner },
    { label: 'Capabilities', value: capabilities.split('\n') },
    { label: 'Help', value: help.split('\n') },
  ];
}

function getSIPSummaryAttributes(result: any) {
  const { Server: server } = result;

  const userAgent = result?.['User-Agent'];
  const via = result?.['Via']?.split(' ')[0];

  return [
    { label: 'Via', value: via },
    { label: 'User Agent', value: userAgent },
    { label: 'Server', value: server },
  ];
}

function getSSDPSummaryAttributes(result: any) {
  const { server, location } = result;

  const attributes = [
    { label: 'Server', value: server },
    { label: 'Upnp URL', value: location },
  ];

  return attributes;
}

function getSMBSummaryAttributes(result: any) {
  const { smb_version, smbv1_support, smb_capabilities, negotiation_log, has_ntlm } = result;
  const { version_string } = smb_version || {};
  const { smb_dfs_support, smb_leasing_support, smb_multicredit_support } = smb_capabilities || {};
  const {
    security_mode,
    dialect_revision,
    server_guid,
    capabilities,
    system_time,
    server_start_time,
    authentication_types,
  } = negotiation_log || {};

  const attributes = [
    { label: 'Version', value: version_string },
    { label: 'SMBv1 Support', value: attributeToString(smbv1_support) },
    { label: 'Dfs Support', value: attributeToString(smb_dfs_support) },
    { label: 'Multicredit Support', value: attributeToString(smb_multicredit_support) },
    { label: 'NTLM Support', value: attributeToString(has_ntlm) },
    { label: 'Leasing Support', value: attributeToString(smb_leasing_support) },
    { label: 'Security Mode', value: security_mode },
    { label: 'Dialect Revision', value: dialect_revision },
    { label: 'Server GUID', value: server_guid },
    { label: 'Capabilities', value: capabilities },
    { label: 'System Time', value: system_time },
    {
      label: 'Server Start Time',
      value: server_start_time ? humanizeDateTime(new Date(server_start_time * 1000)) : undefined,
    },
    { label: 'Authentication Types', value: authentication_types },
  ];

  return attributes;
}

function getBittorrentSummaryAttributes(result: any) {
  const { nodes, ip, ip6 } = result;

  const attributes = [
    { label: 'IP', value: ip || ip6 },
    { label: 'Nodes', value: nodes?.map((node: any) => node?.ip) },
  ];

  return attributes;
}

function getGTPSummaryAttributes(result: any) {
  const { flags } = result;

  const binary = hex2bin(flags).substring(0, 3); // only look at first 3 digits

  const versionMap = {
    '000': 'GTP release 97/98 version (0)',
    '001': 'GTP release 99 version (1)',
    '010': '2',
  };

  const version = versionMap[binary];

  const attributes = [{ label: 'Version', value: version }];

  return attributes;
}

function getPPTPSummaryAttributes(result: any) {
  const { firmware, host, vendor } = result;

  const attributes = [
    { label: 'Firmware Revision', value: firmware },
    { label: 'Host Name', value: host },
    { label: 'Vendor Name', value: vendor },
  ];

  return attributes;
}

function getRDPSummaryAttributes(result: any) {
  const { supported_encryption, supported_levels, supported_protocols } = result;

  return [
    { label: 'Supported Encryption', value: supported_encryption },
    { label: 'Supported Levels', value: supported_levels },
    { label: 'Supported Protocols', value: supported_protocols },
  ];
}

function getNTLMSummaryAttributes(result: any) {
  const { OS_version } = result;

  return [{ label: 'Operating System', value: OS_version }];
}

function getL2TPSummaryAttributes(result: any) {
  const {
    avp_protocol_version,
    avp_protocol_revision,
    avp_firmware_revision,
    avp_host_name,
    avp_vendor_name,
  } = result;

  const protocolVersion =
    avp_protocol_version !== undefined && avp_protocol_revision !== undefined
      ? `${avp_protocol_version}.${avp_protocol_revision}`
      : undefined;

  return [
    { label: 'Protocol Version', value: protocolVersion },
    { label: 'Firmware Revision', value: avp_firmware_revision },
    { label: 'Host Name', value: avp_host_name },
    { label: 'Vendor Name', value: avp_vendor_name },
  ];
}

function getWinboxSummaryAttributes(result: any) {
  const { vendor, OS, version } = result?.platform || {};
  const operatingSystem = OS && version && `${OS} ${version}`;

  return [
    { label: 'Vendor', value: vendor },
    { label: 'Operating System', value: operatingSystem },
  ];
}

function getBGPSummaryAttributes(result: any) {
  const { OPENMessage, NOTIFICATIONMessage } = result?.response || {};
  const { open_identifier, open_myas, cap_4as } = OPENMessage || {};
  const { notify_major_error, notify_minor_error_cease } = NOTIFICATIONMessage || {};

  const majorErrorMap = {
    0: 'Reserved',
    1: 'Message Header Error',
    2: 'OPEN Message Error',
    3: 'UPDATE Message Error',
    4: 'Hold Timer Expired',
    5: 'Finite State Machine Error',
    6: 'Cease',
    7: 'ROUTE-REFRESH Message Error',
  };

  const minorErrorCeaseMap = {
    0: 'Reserved',
    1: 'Maximum Number of Prefixes Reached',
    2: 'Administrative Shutdown',
    3: 'Peer De-configured',
    4: 'Administrative Reset',
    5: 'Connection Rejected',
    6: 'Other Configuration Change',
    7: 'Connection Collision Resolution',
    8: 'Out of Resources',
    9: 'Hard Reset',
    10: 'BFD Down',
  };

  const is4Byte = open_myas === '23456';
  const asn = !is4Byte || (is4Byte && !cap_4as) ? parseInt(open_myas) : parseInt(cap_4as);

  let asnInfo;
  if (asn === 23456) {
    asnInfo = '4-byte AS not present';
  } else if ((asn >= 64512 && asn <= 1310712) || (asn >= 4200000000 && asn <= MAX_ASN)) {
    asnInfo = 'Private';
  } else if (
    (asn >= 64496 && asn <= 64511) ||
    asn === 65535 ||
    (asn >= 64512 && asn <= 65534) ||
    asn === 4294967295
  ) {
    asnInfo = 'Reserved';
  }

  return [
    { label: 'Open Identifier', value: open_identifier },
    { label: 'AS', value: asn ? `${asn}${asnInfo ? ` (${asnInfo})` : ''}` : undefined },
    {
      label: 'Notification Error Code',
      value: majorErrorMap[notify_major_error] || notify_major_error,
    },
    {
      label: 'Notification Cease Subcode',
      value: minorErrorCeaseMap[notify_minor_error_cease] || notify_minor_error_cease,
    },
  ];
}

function getLDAPSummaryAttributes(result: any) {
  const {
    serverName,
    dnsHostName,
    supportedLDAPVersion,
    supportedSASLMechanisms,
    enabledSSLCipherSuites,
    enabledSSLCiphers,
    supportedSSLCipherSuites,
    supportedSSLCiphers,
    supportedTLSCiphers,
  } = result;

  return [
    { label: 'Server Name', value: serverName },
    { label: 'Hostname', value: dnsHostName },
    { label: 'Supported Version', value: supportedLDAPVersion },
    { label: 'Enabled SSL Cipher Suites', value: enabledSSLCipherSuites },
    { label: 'Enabled SSL Ciphers', value: enabledSSLCiphers },
    { label: 'Supported SASL Mechanisms', value: supportedSASLMechanisms },
    { label: 'Supported SSL Cipher Suites', value: supportedSSLCipherSuites },
    { label: 'Supported SSL Ciphers', value: supportedSSLCiphers || result?.['ibm-sslciphers'] },
    { label: 'Supported TLS Ciphers', value: supportedTLSCiphers || result?.['ibm-tlsciphers'] },
  ];
}

const undefinedPJLString = '?';
function getPJLSummaryAttributes(result: any) {
  const { status, id, prodinfo } = result;
  const code = status
    ?.find((item) => item.includes('CODE'))
    ?.split('=')?.[1]
    ?.trim();
  let formattedCode;
  if (code) {
    formattedCode = pjlCodeMap[code] || code;
    if (code.startsWith('11')) {
      formattedCode = 'Background paper-loading';
    } else if (code.startsWith('12')) {
      formattedCode = 'Background paper-tray status';
    } else if (code.startsWith('15')) {
      formattedCode = 'Output-bin status';
    } else if (code.startsWith('41')) {
      formattedCode = 'Foreground paper-loading';
    } else if (code.startsWith('42')) {
      formattedCode = 'Jam (LaserJet 5Si/5SiMx only)';
    } else if (code.startsWith('43')) {
      formattedCode = 'Optional (external) paper-handling device';
    } else if (code.startsWith('44')) {
      formattedCode = 'LaserJet 4000 / 5000 series jam';
    }
  }

  const product = id[1] !== undefinedPJLString ? id[1] : undefined;
  let firmwareDate = prodinfo
    .find((item) => item.includes('FirmwareDateCode'))
    ?.split('=')?.[1]
    ?.trim();
  const language = prodinfo
    .find((item) => item.includes('DeviceLang'))
    ?.split('=')?.[1]
    ?.trim();

  if (firmwareDate) {
    const year = parseInt(firmwareDate.substring(0, 4), 10);
    const month = parseInt(firmwareDate.substring(4, 6), 10) - 1; // Subtract 1 for zero-indexed months
    const day = parseInt(firmwareDate.substring(6, 8), 10);
    firmwareDate = humanizeDateTime(new Date(year, month, day), true);
  }

  return [
    { label: 'Product', value: product },
    { label: 'Language', value: language },
    { label: 'Firmware Date', value: firmwareDate },
    { label: 'Code', value: formattedCode },
  ];
}

function getRTSPSummaryAttributes(result: any) {
  const { response, text } = result;

  const server = text
    ?.find((item) => item.startsWith('Server:'))
    ?.split(':')?.[1]
    ?.trim();
  const wwwAuthenticate = text
    ?.filter((item) => item.startsWith('WWW-Authenticate:'))
    .map((item) => item?.split(':')?.[1]?.trim());
  const publicMethods = text
    ?.find((item) => item.startsWith('Public:'))
    ?.split(':')?.[1]
    ?.trim();
  const userAgent = text
    ?.find((item) => item.startsWith('User-Agent:'))
    ?.split(':')?.[1]
    ?.trim();
  const supportedAuth = text
    ?.find((item) => item.startsWith('SupportAuth:'))
    ?.split(':')?.[1]
    ?.trim();

  return [
    { label: 'Response', value: response },
    { label: 'Server', value: server },
    { label: 'User Agent', value: userAgent },
    { label: 'WWW-Authenticate', value: wwwAuthenticate },
    { label: 'Public', value: publicMethods },
    { label: 'Supported Authentication', value: supportedAuth },
  ];
}
