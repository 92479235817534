import React from 'react';

type TooltipAttribute = {
  label: string;
  description: string;
};

export function TooltipAttributes({ attributes }: { attributes: TooltipAttribute[] }) {
  return (
    <ul className="uk-list uk-margin-remove-bottom uk-margin-remove-top uk-text-left">
      {attributes.map((attribute) => {
        return attribute.description != null ? (
          <li className="app-section-description uk-text-muted" key={attribute.label}>
            <span>{attribute.label}</span>: {attribute?.description}
          </li>
        ) : null;
      })}
    </ul>
  );
}
