import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Select, { OptionsOrGroups, StylesConfig } from 'react-select';
import { reactSelectDropdownStyle, Tree, TreeNode, DropdownIndicator } from 'components/index';
import { AdvancedQuery, advancedQueryResultsVar, GraphNode } from 'model';
import {
  calcNodeDescendantCounts,
  DEBOUNCE_TIMEOUT,
  getAllNodeIds,
  getGraphSelectOptions,
  getNodeForId,
  getViewOption,
  isViewOnly,
  pruneGraphByEdgeWeight,
} from 'utils';
import { ReactComponent as DragIcon } from 'svg/actions/drag-default.svg';
import { ReactComponent as DeleteIcon } from 'svg/actions/x-default.svg';
import { ReactComponent as AddIcon } from 'svg/actions/plus-outline.svg';
import UIkit from 'uikit';
import QueryBuilderStep from 'components/advancedquery/QueryBuilderStep';
import { debounce, isEmpty } from 'lodash';
import { useModelVar } from 'hooks';
import { QueryViewConfigProps } from 'components/advancedquery/QueryViewConfig';

export default function QueryViewConfigGraph({
  configParams,
}: { configParams?: QueryViewConfigProps }) {
  const { advancedQuery, onUpdateAdvancedQuery } = configParams;
  return (
    <div style={{ boxSizing: 'content-box' }} className="config-option">
      <GraphHierarchy advancedQuery={advancedQuery} onUpdate={onUpdateAdvancedQuery} />
      <Fragment>
        <QueryBuilderStep title="Graph Node Global Search">
          <GraphColoring advancedQuery={advancedQuery} onUpdate={onUpdateAdvancedQuery} />
        </QueryBuilderStep>
        <QueryBuilderStep title="Graph Filtering">
          <GraphEdgeFiltering advancedQuery={advancedQuery} onUpdate={onUpdateAdvancedQuery} />
          <GraphNodeFiltering advancedQuery={advancedQuery} onUpdate={onUpdateAdvancedQuery} />
        </QueryBuilderStep>
        {/*<GraphViewConfigReset advancedQuery={advancedQuery} />*/}
      </Fragment>
    </div>
  );
}

function GraphHierarchy({
  advancedQuery,
  onUpdate,
}: { advancedQuery: AdvancedQuery; onUpdate: any }) {
  const viewOption = useMemo(() => getViewOption(advancedQuery), [advancedQuery]);
  const graphCategories = useMemo(() => viewOption?.configOptions?.graphCategories, [viewOption]);
  const sortableRef = useRef<HTMLUListElement>(null);
  const graphHierarchy = useMemo(() => advancedQuery.viewConfig?.graphHierarchy, [advancedQuery]);
  const selectedLevels =
    graphHierarchy?.map((value: string) =>
      graphCategories.find((category: any) => category.value === value),
    ) || [];
  const viewOnly = isViewOnly();

  const updateGraphHierarchy = useCallback(
    (graphHierarchy: any) => {
      const viewConfig = { ...(advancedQuery.viewConfig || {}), graphHierarchy };
      resetViewConfig(viewConfig);
      onUpdate({ ...advancedQuery, viewConfig });
    },
    [advancedQuery, onUpdate],
  );

  useEffect(() => {
    if (!graphHierarchy) {
      const viewConfig = {
        ...(advancedQuery.viewConfig || {}),
        graphHierarchy: graphCategories
          .filter((category: any) => category.default)
          .map((category: any) => category.value),
      };
      onUpdate({ ...advancedQuery, viewConfig });
    }
  }, [graphHierarchy, advancedQuery, graphCategories, onUpdate]);

  useEffect(() => {
    function OnLevelReorder() {
      const graphHierarchy = [];
      const children = sortableRef.current?.children || [];
      for (let i = 0; i < children.length; i++) {
        graphHierarchy.push(children[i].getAttribute('data-category'));
      }
      updateGraphHierarchy(graphHierarchy);
    }

    if (sortableRef.current) {
      return UIkit.util.on(sortableRef.current, 'moved', OnLevelReorder);
    }
  }, [sortableRef, advancedQuery, updateGraphHierarchy]);

  function onLevelChange(index: number, newSelection: any) {
    const oldSelection = selectedLevels[index];
    const graphHierarchy = selectedLevels.map((category: any, i: number) => {
      if (i === index) {
        return newSelection.value;
      } else if (category.value === newSelection.value) {
        return oldSelection.value;
      } else {
        return category.value;
      }
    });
    updateGraphHierarchy(graphHierarchy);
  }

  function onLevelDelete(index: number) {
    const graphHierarchy = selectedLevels
      .filter((_: any, i: number) => i !== index)
      .map((category: any) => category.value);
    updateGraphHierarchy(graphHierarchy);
  }

  function onLevelAdd() {
    const graphHierarchy = selectedLevels.map((category: any) => category.value);
    for (let i = 0; i < graphCategories.length; i++) {
      if (!graphHierarchy.includes(graphCategories[i].value)) {
        graphHierarchy.push(graphCategories[i].value);
        break;
      }
    }
    updateGraphHierarchy(graphHierarchy);
  }

  return (
    <Fragment>
      {selectedLevels.length > 0 && (
        <ul
          ref={sortableRef}
          className="uk-list uk-margin-small-top"
          data-uk-sortable="handle: .uk-sortable-handle"
        >
          {selectedLevels.map((category: any, index: number) => (
            <li
              key={category.value}
              className="uk-flex uk-flex-middle"
              data-category={category.value}
            >
              <DragIcon
                className="uk-sortable-handle"
                width={24}
                height={24}
                style={{ marginLeft: 10 * index, visibility: viewOnly ? 'hidden' : undefined }}
              />
              <div style={{ width: '14rem' }}>
                <Select
                  options={graphCategories}
                  value={category}
                  onChange={(value: any) => onLevelChange(index, value)}
                  styles={reactSelectDropdownStyle() as StylesConfig}
                  menuPortalTarget={document.body}
                  isDisabled={viewOnly}
                  components={{ DropdownIndicator }}
                />
              </div>
              <button
                className="uk-button uk-icon-link uk-padding-remove"
                style={{
                  background: 'none',
                  marginLeft: 'auto',
                  visibility: viewOnly ? 'hidden' : undefined,
                }}
                onClick={() => onLevelDelete(index)}
              >
                <DeleteIcon width={20} height={20} />
              </button>
            </li>
          ))}
        </ul>
      )}
      {selectedLevels.length < graphCategories?.length && <AddButton onNew={onLevelAdd} />}
    </Fragment>
  );
}

function GraphColoring({
  advancedQuery,
  onUpdate,
}: { advancedQuery: AdvancedQuery; onUpdate: Function }) {
  const viewOption = useMemo(() => getViewOption(advancedQuery), [advancedQuery]);
  const graphCategories = useMemo(() => viewOption?.configOptions?.graphCategories, [viewOption]);
  const results = useModelVar(advancedQueryResultsVar);
  const options = useMemo(
    () => getGraphSelectOptions(results?.rootNode, graphCategories),
    [graphCategories, results],
  );

  if (!results) {
    return null;
  }

  return (
    <div className="uk-margin-small-top">
      <GraphColorSelector
        advancedQuery={advancedQuery}
        index={0}
        options={options}
        onUpdate={onUpdate}
      />
    </div>
  );
}

function GraphColorSelector({
  advancedQuery,
  index,
  options,
  onUpdate,
}: {
  advancedQuery: AdvancedQuery;
  index: number;
  options: OptionsOrGroups<any, any>;
  onUpdate: Function;
}) {
  const color = getComputedStyle(document.documentElement).getPropertyValue('--app-viz-color0');
  const currentSelection = useMemo(() => {
    const colorSelections = advancedQuery?.viewConfig?.colorSelections || {};
    return getSelectedOptions(colorSelections[index] || [], options);
  }, [advancedQuery, index, options]);

  function onValueSelect(selection: any) {
    let colorSelections = advancedQuery?.viewConfig?.colorSelections;
    if (Array.isArray(colorSelections)) {
      colorSelections = { [index]: colorSelections[index] };
    }
    colorSelections = { ...(colorSelections || {}) };
    if (selection) {
      colorSelections[index] = selection?.map((item: any) => item.value);
    } else {
      delete colorSelections[index];
    }
    const viewConfig = { ...(advancedQuery?.viewConfig || {}) };
    if (!isEmpty(colorSelections)) {
      viewConfig.colorSelections = colorSelections;
    } else {
      delete viewConfig.colorSelections;
    }
    const newAdvancedQuery = { ...advancedQuery, viewConfig };
    onUpdate(newAdvancedQuery);
  }

  const dotStyle = {
    width: 22,
    height: 22,
    borderRadius: '50%',
    borderWidth: 1,
    borderStyle: 'solid',
    marginRight: 10,
  };

  const selectStyle = {
    ...reactSelectDropdownStyle(),
    multiValue: (base: Record<string, any>) => ({
      ...base,
      backgroundColor: color,
    }),
    multiValueLabel: (base: Record<string, any>) => ({
      ...base,
      color: 'inherit',
    }),
    multiValueRemove: (base: Record<string, any>) => ({
      ...base,
      color: 'inherit',
      ':hover': {
        backgroundColor: '#e5e5e5',
      },
    }),
  };

  return (
    <div className="uk-margin-small-top uk-flex uk-flex-middle">
      <div className={`app-viz-color${index}`} style={dotStyle} />
      <div style={{ flexGrow: 3 }}>
        <Select
          options={options}
          isMulti={true}
          value={currentSelection}
          placeholder="Select"
          onChange={onValueSelect}
          styles={selectStyle as StylesConfig}
          menuPortalTarget={document.body}
          components={{ DropdownIndicator }}
        />
      </div>
    </div>
  );
}

function AddButton({ onNew }: { onNew?: () => void }) {
  return (
    <div className="uk-flex-inline uk-margin-small-top">
      <button className="cursor-pointer" onClick={onNew}>
        <AddIcon />
      </button>
      <span className="add-filter-text">Add Level</span>
    </div>
  );
}

function GraphEdgeFiltering({
  advancedQuery,
  onUpdate,
}: { advancedQuery: AdvancedQuery; onUpdate: any }) {
  const [pruneValue, setPruneValue] = useState<number>(advancedQuery?.viewConfig?.pruneValue);
  const debouncedPruneUpdate = useRef(
    debounce((event) => {
      const newPruneVal = +event?.target?.value;
      setPruneValue(newPruneVal);
      const viewConfig = { ...(advancedQuery.viewConfig || {}) };
      if (newPruneVal > 0) {
        viewConfig.pruneValue = newPruneVal;
      } else {
        delete viewConfig.pruneValue;
      }
      const newAdvancedQuery = { ...advancedQuery, viewConfig };
      onUpdate(newAdvancedQuery);
    }, DEBOUNCE_TIMEOUT),
  );

  useEffect(() => {
    setPruneValue(advancedQuery?.viewConfig?.pruneValue);
  }, [advancedQuery]);

  function onPrune(event: ChangeEvent<HTMLInputElement>) {
    event.persist();
    debouncedPruneUpdate.current.cancel();
    debouncedPruneUpdate.current(event);
  }

  return (
    <div className="uk-margin-small-top">
      <div className="input-label">Remove Nodes by Edge Weight</div>
      <input
        className="uk-range"
        type="range"
        defaultValue={pruneValue || 0}
        min="0"
        max="100"
        step="1"
        onChange={onPrune}
      />
      <div className="uk-text-small">
        Off <span className="uk-float-right">Max</span>
      </div>
    </div>
  );
}

export function GraphNodeFiltering({
  advancedQuery,
  topNodeId,
  onUpdate,
}: { advancedQuery: AdvancedQuery; topNodeId?: string; onUpdate: Function }) {
  const results = useModelVar(advancedQueryResultsVar);
  const removedNodes = useMemo(
    () => advancedQuery?.viewConfig?.removedNodes as string[],
    [advancedQuery],
  );

  const topNode = useMemo(() => {
    return topNodeId && results?.rootNode ? getNodeForId(results?.rootNode, topNodeId) : undefined;
  }, [topNodeId, results]);

  const topNodeDescendantIds = useMemo(() => {
    return topNode ? new Set(getAllNodeIds(topNode)) : undefined;
  }, [topNode]);

  const otherRemovedNodes = useMemo(() => {
    if (topNodeDescendantIds && removedNodes) {
      return removedNodes.filter((id) => !topNodeDescendantIds.has(id));
    }
    return [];
  }, [removedNodes, topNodeDescendantIds]);

  const treeNodes = useMemo(() => {
    let rootNode = topNode || results?.rootNode;
    if (!rootNode) {
      return [];
    }
    if (advancedQuery?.viewConfig?.pruneValue) {
      [rootNode] = pruneGraphByEdgeWeight(
        rootNode,
        results?.links,
        advancedQuery?.viewConfig?.pruneValue,
      );
    }
    calcNodeDescendantCounts(rootNode);
    return graphToTreeNodes(rootNode.children || rootNode.state.children || []);
  }, [results, advancedQuery, topNode]);

  function onCheckedNodes(checked: string[]) {
    const viewConfig = { ...(advancedQuery?.viewConfig || {}) };
    viewConfig.removedNodes = [...checked, ...otherRemovedNodes];

    if (viewConfig.removedNodes?.length === 0) {
      delete viewConfig.removedNodes;
    }
    const newAdvancedQuery = {
      ...advancedQuery,
      viewConfig,
    };
    onUpdate(newAdvancedQuery);
  }

  return (
    <Fragment>
      <div className="uk-margin-small-top input-label">Remove Nodes</div>
      <Tree nodes={treeNodes} checkedNodes={removedNodes} onCheckedNodes={onCheckedNodes} />
    </Fragment>
  );
}

function graphToTreeNodes(graphNodes: GraphNode[]): TreeNode[] {
  const treeNodes = graphNodes.map((node) => {
    const nodeChildArray = node.children || node.state.children;
    const children = nodeChildArray?.length > 0 ? graphToTreeNodes(nodeChildArray) : undefined;
    return {
      value: node.id,
      label: children ? `(${node.state.descendantCount}) ${node.label}` : node.label,
      children,
      descendantCount: node.state.descendantCount,
    };
  });
  treeNodes.sort((a, b) => b.descendantCount - a.descendantCount);
  return treeNodes;
}

function getSelectedOptions(selections: string[], options: OptionsOrGroups<any, any>) {
  return selections.map((selection) => {
    for (const category of options) {
      for (const opt of category.options) {
        if (opt.value === selection) {
          return opt;
        }
      }
    }
    return undefined;
  });
}

const resettableConfigs = ['pruneValue', 'removedNodes', 'colorSelections', 'expandedNodes'];

// function GraphViewConfigReset({ advancedQuery }: { advancedQuery: AdvancedQuery }) {
//   const [showButton, setShowButton] = useState(false);
//
//   useEffect(() => {
//     const viewConfig = advancedQuery.viewConfig || {};
//     const resettables = intersection(Object.keys(viewConfig), resettableConfigs);
//     setShowButton(resettables.length > 0);
//   }, [advancedQuery]);
//
//   function onReset() {
//     const viewConfig = { ...(advancedQuery.viewConfig || {}) };
//     resetViewConfig(viewConfig);
//     const newAdvancedQuery = { ...advancedQuery, viewConfig };
//     advancedQueryPrototypeVar(newAdvancedQuery);
//     advancedQueryVar(newAdvancedQuery);
//   }
//
//   if (showButton) {
//     return <button className="uk-button uk-button-default uk-margin-top uk-margin-small-bottom uk-float-right" onClick={onReset}>Reset</button>
//   } else {
//     return null;
//   }
// }

function resetViewConfig(viewConfig: Record<string, any>) {
  resettableConfigs.forEach((resettable) => delete viewConfig[resettable]);
}
