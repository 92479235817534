import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
  Banner,
  reactSelectSearchStyle,
  platformSearchHeaderStyle,
  AuthorizedElement,
  ShortCutTooltip,
  API_ROLE,
  ON_DEMAND_ROLE,
} from 'components';
import { Link, useHistory } from 'react-router-dom';
import { KnowledgeSearch } from './startpage/KnowledgeSearch';
import { useUserProfile } from 'hooks';
import { useKeycloak } from '@react-keycloak/web';
import { ReactComponent as Logo } from 'svg/brand/logomark-hard-negative.svg';
import { ReactComponent as SearchIcon } from 'svg/system/magnifying-glass.svg';
import { ReactComponent as ImportIcon } from 'svg/system/transfer-up.svg';
import { useReactiveVar } from '@apollo/client';
import { ManagerTypes, userNotifications } from 'model';
import { ReactComponent as SignoutIcon } from 'svg/system/authentication-logout.svg';
import useResizeObserver from 'use-resize-observer';
import { getVersion } from 'utils';

export function Header() {
  const brandingEnabled = process.env.REACT_APP_BRANDED?.toLowerCase() === 'true';
  const notifications = useReactiveVar(userNotifications);
  const history = useHistory();
  const [knowledgeSearchExpanded, setKnowledgeSearchExpanded] = useState(false);
  const [shortCutsVisible, setShortCutsVisible] = useState(false);
  const currentBanner = useMemo(() => {
    return Object.entries(notifications).filter(
      (notification) => notification[0] !== 'invalid-asset',
    )[0];
  }, [notifications]);
  const { ref, width } = useResizeObserver<HTMLDivElement>();

  const docStyle = getComputedStyle(document.documentElement);
  const height = docStyle.getPropertyValue('--app-header-height');

  /* ***********************KEYBOARD SHORTCUTS****************************** */
  // keyup shortcuts
  useEffect(() => {
    function handleHotKey(e: any) {
      if (e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA') return;
      if (e.target.isContentEditable) return;
      const key = e.key;
      if (key === '/') {
        // focus knowledge search box
        if (!knowledgeSearchExpanded) {
          setKnowledgeSearchExpanded(true);
        } else {
          document.querySelector('.quick-find').querySelector('input').focus();
        }
      }
    }
    document.addEventListener('keyup', handleHotKey);
    return () => {
      document.removeEventListener('keyup', handleHotKey);
    };
  }, [knowledgeSearchExpanded]);

  // keydown shortcuts
  useEffect(() => {
    function handleHotKey(e: any) {
      if (e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA') return;
      if (e.target.isContentEditable) return;
      const key = e.key;
      if (key === '?') {
        // toggle shortcuts tooltip
        setShortCutsVisible((curr) => !curr);
      } else if (key === 'a') {
        // navigate to advanced search page
        history.push('/advanced-search');
      } else if (key === 'Escape' && shortCutsVisible) {
        setShortCutsVisible(false);
      }
    }
    document.addEventListener('keydown', handleHotKey);
    return () => {
      document.removeEventListener('keydown', handleHotKey);
    };
  }, [history, shortCutsVisible]);

  const selectProps = {
    placeholder: (
      <div className="uk-flex uk-flex-between">
        <div>Quick Find</div>
        <div className="hotkey search-box-hotkey">/</div>
      </div>
    ),
    autoFocus: true,
    styles: {
      ...reactSelectSearchStyle(),
      ...platformSearchHeaderStyle(),
    },
  };
  function SearchButton({ style, className }: { style?: any; className?: string }) {
    return (
      <button
        style={style}
        onClick={(ev) => {
          ev.preventDefault();
          setKnowledgeSearchExpanded((curr) => !curr);
        }}
        className={className}
      >
        <SearchIcon />
      </button>
    );
  }

  return (
    <Fragment>
      <header ref={ref} className="uk-navbar-container uk-sticky uk-sticky-fixed">
        <div className="uk-container uk-container-expand">
          <nav className="uk-navbar">
            <div className="uk-navbar-left">
              <Link to="/" className="uk-navbar-item">
                {brandingEnabled ? (
                  <Logo className="header-logo" />
                ) : (
                  <img src={process.env.REACT_APP_LOGO} alt="" className="header-logo" />
                )}
              </Link>
              <Link to={`/${ManagerTypes.Gallery}`} className="uk-navbar-item">
                Gallery
              </Link>
              <Link to={`/${ManagerTypes.Collections}`} className="uk-navbar-item">
                Collections
              </Link>
              <Link to={`/${ManagerTypes.Surfaces}`} className="uk-navbar-item">
                Surfaces
              </Link>
            </div>
            <div className="app-navbar uk-navbar-right">
              {width && width > 800 && (
                <div className="uk-flex" style={{ margin: 0, background: 'none' }}>
                  <div className="quick-find">
                    <KnowledgeSearch
                      selectProps={selectProps}
                      hidden={!knowledgeSearchExpanded}
                      defaultMenuIsOpen={true}
                    />
                  </div>
                  <SearchButton className="header-icon" />
                </div>
              )}
              <Link className="header-icon" to={`/${ManagerTypes.Import}`}>
                <ImportIcon />
              </Link>
              <UserButton setShortCutsVisible={setShortCutsVisible} />
            </div>
          </nav>
        </div>
      </header>
      <div className="uk-sticky-placeholder" style={{ height, margin: 0 }} />
      {currentBanner && currentBanner[1] && (
        <div uk-sticky={`offset: ${height}`} className="header-banner">
          <Banner
            {...currentBanner[1]}
            style={{ ...currentBanner[1]?.style, marginBottom: '0' }}
            contentClass="uk-align-center uk-margin-remove-bottom header-banner-content"
          />
        </div>
      )}
      {shortCutsVisible && <ShortCutTooltip onClose={() => setShortCutsVisible(false)} />}
    </Fragment>
  );
}

function UserButton({ setShortCutsVisible }: { setShortCutsVisible: Function }) {
  const history = useHistory();
  const dropdownRef = useRef(null);
  const { keycloak } = useKeycloak();
  const userProfile = useUserProfile();
  const firstInitial = userProfile?.firstName?.slice(0, 1) || '';
  const lastInitial = userProfile?.lastName?.slice(0, 1) || '';

  return (
    <Fragment>
      <button
        className="uk-button uk-text-middle uk-margin-medium-right"
        style={{
          marginLeft: 'auto',
          padding: 0,
          border: 'none',
          background: 'none',
          height: '40px',
          width: '40px',
        }}
      >
        <div className="uk-flex-center uk-flex-middle user-button">
          <div>
            {firstInitial}
            {lastInitial}
          </div>
        </div>
      </button>
      <div
        ref={dropdownRef}
        data-uk-toggle={'true'}
        data-uk-dropdown="mode: click; pos: bottom-right"
        className="user-button-menu"
      >
        <ul className="uk-nav uk-dropdown-nav" style={{ cursor: 'default' }}>
          <div
            style={{ height: '32px', lineHeight: '32px', fontSize: '14px' }}
            className="tile-menu-title uk-flex uk-flex-between uk-padding-remove-bottom"
          >
            <div className="uk-text-capitalize">Version</div>
            <div>{getVersion()}</div>
          </div>
          <AuthorizedElement roles={[API_ROLE]}>
            <div className="header-divider" />
            <li className="tile-menu-title uk-padding-remove-bottom uk-margin-top">API</li>
            <li className="uk-margin-bottom">
              <a
                onClick={() => {
                  history.push('/gql');
                  dropdownRef.current.setAttribute('data-uk-toggle', 'false');
                }}
              >
                Playground
              </a>
            </li>
          </AuthorizedElement>
          <div className="header-divider" />
          <li className="tile-menu-title uk-padding-remove-bottom uk-margin-top">HELP</li>
          <li className="uk-margin-bottom">
            <a
              onClick={() => {
                setShortCutsVisible(true);
              }}
            >
              Shortcuts
            </a>
          </li>
          <AuthorizedElement roles={[ON_DEMAND_ROLE]}>
            <div className="header-divider" />
            <li className="tile-menu-title uk-padding-remove-bottom uk-margin-top">UTILITIES</li>
            <li className="uk-margin-bottom">
              <a
                onClick={() => {
                  history.push('/on-demand');
                  dropdownRef.current.setAttribute('data-uk-toggle', 'false');
                }}
              >
                On-demand
              </a>
            </li>
          </AuthorizedElement>
          <div className="header-divider" />
          <li>
            <a
              href="#"
              style={{ height: '32px', lineHeight: '32px' }}
              onClick={() => keycloak.logout()}
            >
              <SignoutIcon style={{ height: '18px', width: '18px' }} />
              <span className="uk-margin-left">Sign out</span>
            </a>
          </li>
        </ul>
      </div>
    </Fragment>
  );
}

export default Header;
