import React from 'react';
import { components } from 'react-select';
import { ReactComponent as CloseIcon } from 'svg/actions/x-fill.svg';

export function MultiValueRemove(props: any) {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon />
    </components.MultiValueRemove>
  );
}
