import { uniq, flatten } from 'lodash';

export function flattenServicesData(services: any[]) {
  const serviceTags = uniq(flatten(services?.map((service: any) => service.tags)))
    .sort()
    .filter((tag: any) => tag);
  const serviceRegions = uniq(services?.map((service: any) => service.region))
    .sort()
    .filter((region: any) => region);
  const serviceTypes = uniq(services?.map((service: any) => service.type))
    .sort()
    .filter((type: any) => type);
  const serviceNames = services?.map((service: any) => service.name).sort();

  return { serviceTags, serviceRegions, serviceTypes, serviceNames };
}
