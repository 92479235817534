import { Spinner } from 'components';
import { abbreviatedCount } from 'utils';
import React from 'react';

export type SeeMorePaginationProps = {
  rowsLoaded: number;
  rowsToLoad: number;
  rowCount: number;
  onLoadRows: (rowsPerPage: number) => void;
  loading?: boolean;
  postFilterStatus?: string;
};

export function SeeMorePagination({
  rowCount,
  rowsLoaded,
  onLoadRows,
  rowsToLoad,
  loading,
  postFilterStatus,
}: SeeMorePaginationProps) {
  if (!rowCount || !rowsLoaded || rowsLoaded >= rowCount) {
    return null;
  }

  const additionalRowCount = abbreviatedCount(rowCount - rowsLoaded);

  return (
    <button
      onClick={(event) => {
        onLoadRows(rowsToLoad);
      }}
      className="see-more-button uk-align-center"
    >
      {loading ? (
        <Spinner ratio={1} style={{ marginLeft: '30px', color: 'black' }} />
      ) : (
        <span>
          <div style={{ transform: 'translateY(-2px)' }} uk-icon="icon: arrow-down" />
          {postFilterStatus === 'WORKING'
            ? `More than  ${additionalRowCount} additional rows`
            : postFilterStatus === 'LIMITED'
              ? `Results capped at 100k. ${additionalRowCount}additional rows`
              : postFilterStatus === 'TIMEOUT'
                ? `Search timed out at ${rowCount} rows. ${additionalRowCount} additional rows`
                : `${additionalRowCount} additional rows`}
        </span>
      )}
    </button>
  );
}
