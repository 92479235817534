import React, { Fragment, useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { UserDataset, userDatasetsVar, userInvestigationsVar } from 'model';
import { deleteAllDatasets, ManagerTable, ManagerTableFilter, Spinner } from 'components';
import { getUserDatasetReferences, loadingDatasetStatuses } from 'utils';
import { DatasetEditor } from './DatasetEditor';
import { ReactComponent as Error } from 'svg/actions/exclamation-fill.svg';
import { isEqual } from 'lodash';
import { kebabItem, modifiedItem } from 'components/TableItems';
import { ReactComponent as DatasetIcon } from 'svg/misc/datasets.svg';
import { useHistory, useLocation } from 'react-router-dom';

export function SurfaceManager() {
  const datasets = useReactiveVar(userDatasetsVar);
  const [filterText, setFilterText] = useState<any>(null);
  const [focusedRow, setFocusedRow] = useState<any>(undefined);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [forcePageReset, setForcePageReset] = useState(false);
  const focusedRowSetRef = useRef<boolean>(false);
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
  const searchParamPage = searchParams.get('page');
  const [pageNumber, setPageNumber] = useState<number>(Number(searchParamPage));

  // Read search params from url
  useEffect(() => {
    setPageNumber(searchParamPage != null ? Number(searchParamPage) : 1);
  }, [searchParamPage]);

  // If an external update is made to the dataset while edit mode is open, update the view
  useEffect(() => {
    if (focusedRow?.id != null && !isEqual(focusedRow, datasets[focusedRow.id])) {
      setFocusedRow(datasets[focusedRow.id]);
      focusedRowSetRef.current = true;
    }
  }, [datasets, focusedRow]);

  const formatRowCallback = useCallback(
    (row: any) => {
      const classNames = [];

      loadingDatasetStatuses.includes(row.original.system.status) &&
        classNames.push('row-disabled');
      row.original.system.status === 'error' && classNames.push('row-error');
      row.original.id === focusedRow?.id && classNames.push('row-selected');
      return {
        className: classNames.length > 0 ? classNames.join(' ') : '',
      };
    },
    [focusedRow],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 100,
        maxWidth: 260,
        sortType: 'string',
        Cell: ({ row }: any) => {
          const item = row?.original;
          if (item?.system?.status === 'error') {
            return (
              <span className="uk-text-truncate">
                {item?.name} &nbsp;&nbsp;
                <Error />
              </span>
            );
          } else if (loadingDatasetStatuses.includes(item?.system?.status)) {
            return (
              <span className="uk-text-truncate">
                {item?.name} <Spinner ratio={0.5} style={{ padding: '2px' }} />
              </span>
            );
          }
          return <span className="uk-text-truncate">{item?.name}</span>;
        },
      },
      {
        ...modifiedItem,
        sortType: (rowA, rowB, id, desc) => {
          if (rowA.values[id] > rowB.values[id]) return 1;
          if (rowB.values[id] > rowA.values[id]) return -1;
          return 0;
        },
      },
      {
        ...kebabItem,
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        Cell: ({ cell, row }: any) => <div className="uk-invisible" />,
      },
    ],
    [],
  );

  const data = useMemo(() => {
    return Object.values(datasets)
      .filter((dataset: UserDataset) => !dataset.system?.hidden)
      .filter(
        (dataset) =>
          !filterText || dataset.name.toLowerCase().includes(filterText?.label?.toLowerCase()),
      );
  }, [filterText, datasets]);

  const filterOptions = useMemo(() => {
    return Object.values(datasets)
      .filter((dataset: UserDataset) => !dataset.system?.hidden)
      .map((dataset) => ({ value: dataset.id, label: dataset.name }));
  }, [datasets]);

  const isDisabledCallback = useCallback((row: any) => {
    return loadingDatasetStatuses.includes(row?.original?.system?.status);
  }, []);

  const onUpdatePageURL = useCallback(
    (searchString: string) => {
      history.push(`surfaces?${searchString}`);
    },
    [history],
  );

  const sortBy = [{ id: 'saved', desc: true }];

  return (
    <Fragment>
      <div className="uk-align-center data-manager">
        <div>
          <div className="app-mosaic-table-header app-text-medium uk-flex uk-flex-middle">
            <DatasetIcon />
            <span className="uk-margin-left">Surfaces</span>
          </div>
          <div className="uk-margin-small-top uk-margin-medium-bottom uk-text-muted app-width-tablet">
            Sets of internet data
          </div>
        </div>
        <div className="uk-flex uk-flex-right uk-margin-medium-bottom">
          <button
            className="uk-button uk-button-default uk-margin-medium-right"
            onClick={() => {
              history.push('/import?type=file');
            }}
            disabled={focusedRow === 'import'}
          >
            New Surface
          </button>
          <ManagerTableFilter
            placeholder="Search for a surface"
            selected={filterText}
            options={filterOptions}
            onChange={(val) => {
              setForcePageReset(true);
              setFilterText(val);
            }}
          />
        </div>
        <div className="uk-flex app-width-full" style={{ minHeight: '416px' }}>
          <div
            className={`${focusedRow ? 'open-import-table' : 'closed-import-table'}${!focusedRowSetRef.current ? ' initial-import-table' : ''}`}
          >
            <ManagerTable
              data={data}
              columns={columns}
              sortBy={sortBy}
              getRowProps={formatRowCallback}
              toDeleteText={'Dataset(s)'}
              hasSelectableRows={true}
              isDisabled={isDisabledCallback}
              forcePageReset={forcePageReset}
              setForcePageReset={setForcePageReset}
              onDeleteAll={(ids: string[]) => {
                setFocusedRow(undefined);
                let referencedMosaics: any = new Set<string>();
                ids.forEach((id) => {
                  const mosaics = getUserDatasetReferences(datasets[id]);
                  referencedMosaics.add(mosaics.map((mosaic) => mosaic.id));
                });
                referencedMosaics = Array.from(referencedMosaics)
                  .flat()
                  .map((id: any) => {
                    return userInvestigationsVar()[id];
                  });
                deleteAllDatasets(
                  Object.values(datasets).filter((dataset) => ids.includes(dataset.id)),
                  false,
                  undefined,
                  referencedMosaics,
                );
                setIsEditMode(false);
              }}
              minWidth={384}
              onRowClick={(row) => {
                setIsEditMode(false);
                setFocusedRow(row.original.id === focusedRow?.id ? undefined : row.original);
                focusedRowSetRef.current = true;
              }}
              pageNumber={pageNumber}
              onUpdatePageURL={onUpdatePageURL}
            />
          </div>
          <div className={`${focusedRow ? 'open-dataset-editor' : 'closed-dataset-editor'}`}>
            <DatasetEditor
              dataset={focusedRow}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
