import React, { Suspense, lazy, useContext, useRef, useCallback, useMemo } from 'react';
import { Spinner } from 'components';
import { ComponentPluginContext, SidebarContext } from 'appContexts';
import {
  DEBOUNCE_TIMEOUT,
  findExistingSection,
  getCurrentInvestigation,
  saveInvestigation,
} from 'utils';
import { debounce } from 'lodash';

const MarkdownEditor = lazy(() => import('../markdown/MarkdownEditor'));

export function DescriptionEditor({ id }: { id: string }) {
  const { params, description } = useContext(ComponentPluginContext);
  const { localAnnotations, setLocalAnnotations, focusedTile } = useContext(SidebarContext);

  const isFocused = useMemo(() => focusedTile.id === id, [focusedTile.id, id]);
  const localDescription = useMemo(() => {
    if (isFocused) {
      return localAnnotations?.focusedDesc ?? description;
    } else {
      return description;
    }
  }, [localAnnotations, description, isFocused]);
  const onUpdateDescription = useCallback(
    (newDescription: string) => {
      const investigation = getCurrentInvestigation();
      if (!investigation) {
        return;
      }
      const existingSection = findExistingSection(investigation, params.uuid);
      if (existingSection) {
        const i = investigation.sections.indexOf(existingSection);
        investigation.sections[i] = {
          ...investigation.sections[i],
          description: newDescription ?? '',
          saved: new Date(),
        };
      }
      saveInvestigation(investigation);
    },
    [params.uuid],
  );

  const debounceDescription = useRef(
    debounce((newDescription: string) => onUpdateDescription(newDescription), DEBOUNCE_TIMEOUT),
  );
  return (
    <div className="uk-margin-medium-bottom app-width-tablet">
      <span className="input-label uk-flex uk-margin-remove-bottom">
        {' '}
        <label>Description</label>
        <label className="input-sublabel uk-margin-auto-left"> *optional</label>
      </span>
      <Suspense fallback={<Spinner ratio={2} />}>
        <MarkdownEditor
          value={localDescription || description}
          allowImageUpload={true}
          onChange={(newDescription) => {
            if (isFocused) {
              setLocalAnnotations((curr) => ({ ...curr, focusedDesc: newDescription }));
            }
            debounceDescription.current(newDescription);
          }}
        />
      </Suspense>
    </div>
  );
}
