import React, { useCallback, useContext, useState } from 'react';
import { ComponentPluginContext } from 'appContexts';
import { isSharedLink } from 'utils';
import { ReactComponent as ToggleOn } from 'svg/selection/toggle-on.svg';
import { ReactComponent as ToggleOnDisabled } from 'svg/selection/toggle-on-disabled.svg';
import { ReactComponent as ToggleOff } from 'svg/selection/toggle-off.svg';

export default function PortsConfig() {
  const { getPluginConfig, updatePluginConfig } = useContext(ComponentPluginContext);
  const [isSummary, setIsSummary] = useState(getPluginConfig()?.isSummary ?? false);

  const toggleSummaryView = useCallback(
    (current: boolean) => {
      setIsSummary(!current);
      const newConfig = { ...getPluginConfig(), isSummary: !current };
      updatePluginConfig(newConfig);
    },
    [getPluginConfig, updatePluginConfig],
  );

  return (
    <div className={`config-option hide-in-pdf ${isSharedLink() ? 'view-only' : ''}`}>
      <div className="uk-flex">
        <div>Expanded View</div>
        <div
          onClick={() => !isSharedLink() && toggleSummaryView(isSummary)}
          className="uk-margin-auto-left"
          data-testid="ports-summary-toggle"
        >
          {isSummary ? isSharedLink() ? <ToggleOnDisabled /> : <ToggleOn /> : <ToggleOff />}
        </div>
      </div>
    </div>
  );
}
