import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { gql } from '@apollo/client';
import { ComponentPluginContext } from 'appContexts';
import L from 'leaflet';
import { TileLayer, Marker, GeoJSON } from 'react-leaflet';
import * as topojson from 'topojson-client';
import { useAssetQuery } from 'hooks';
import { AssetType } from 'model';
import { ReactComponent as PinIcon } from 'svg/experimental/location-filled.svg';
import { GeoMapContainer } from './GeoMapContainer';
import { Spinner } from 'components';

const docStyle = getComputedStyle(document.documentElement);
const highlightColor = docStyle.getPropertyValue('--app-primary-color');

const areaQuery = gql`
  query CountryAreaData($countrySet: [String]!) {
    countryData(countrySet: $countrySet) {
      id
      countryRecords {
        id
        areaTotal
      }
    }
  }
`;

// Custom marker icon that is same as react marker cluster
const CustomIcon = L.divIcon({
  html: renderToStaticMarkup(<PinIcon style={{ color: '#4939AC' }} />),
  className: 'marker-pin',
  iconSize: [40, 40],
  iconAnchor: [20, 40],
});

let topoCountries: any;

async function getTopoCountries() {
  if (!topoCountries) {
    topoCountries = await import('data/countries-10m.json');
  }
  return topoCountries;
}

L.Marker.prototype.options.icon = CustomIcon;

function getDefaultZoom(area: number) {
  let zoom = 6;
  if (area) {
    if (area > 10000000) {
      zoom = 2;
    } else if (area > 5000000) {
      zoom = 3;
    } else if (area > 1000000) {
      zoom = 4;
    } else if (area > 50000) {
      zoom = 5;
    }
  }
  return zoom;
}

export function GeoMap({ geo, small }: { geo: any; small?: boolean }) {
  const { getPluginConfig, updatePluginConfig, isPrinterFriendly } =
    useContext(ComponentPluginContext);
  const latLon = geo?.latLon?.split(',')?.map((coordinate) => parseFloat(coordinate));

  const { asset: areaData, loading: areaLoading } = useAssetQuery(
    AssetType.Country,
    geo?.country,
    areaQuery,
  );

  const DEFAULT_VIEWPORT = useMemo(() => {
    return {
      zoom: geo?.city ? 5 : getDefaultZoom(areaData?.countryRecords?.areaTotal),
      center: latLon,
    };
  }, [geo, areaData, latLon]);

  const geoJSON = useCountryGeoJSON(geo);
  const tileUrl = process.env.REACT_APP_TILE_ENDPOINT;
  const isAnycast = geo?.anycast;

  const viewport = useCallback(
    () => (getPluginConfig()?.center ? getPluginConfig() : DEFAULT_VIEWPORT),
    [getPluginConfig, DEFAULT_VIEWPORT],
  );

  const onViewportChanged = useCallback(
    (view: any) => {
      updatePluginConfig({ ...getPluginConfig(), ...view });
    },
    [getPluginConfig, updatePluginConfig],
  );

  if (isAnycast || !latLon || !tileUrl) {
    return null;
  }

  return (
    <div style={{ marginTop: '24px' }}>
      {areaLoading ? (
        <Spinner ratio={3} />
      ) : (
        <div
          className={
            small
              ? 'geo-map-container-small'
              : `geo-map-container${isPrinterFriendly ? ' printable-geo-map-container' : ''}`
          }
        >
          <GeoMapContainer viewport={viewport()} minZoom={2} onViewportChanged={onViewportChanged}>
            {tileUrl && <TileLayer url={tileUrl} />}
            {geoJSON && (
              <GeoJSON
                data={geoJSON}
                style={{ fillColor: highlightColor, color: highlightColor, fillOpacity: 0.4 }}
              />
            )}
            {geo?.city && <Marker position={latLon} icon={CustomIcon} />}
          </GeoMapContainer>
        </div>
      )}
    </div>
  );
}

// Convet topojson to geojson and select country
function useCountryGeoJSON(geo: any) {
  const [geoJSON, setGeoJSON] = useState<any>();

  useEffect(() => {
    if (geo?.city) {
      return;
    }

    async function loadGeoJSON() {
      const topoCountries = await getTopoCountries();
      const newGeoJSON: any = topojson.feature(topoCountries, topoCountries.objects.countries);
      newGeoJSON.features = newGeoJSON.features.filter(
        (featureObj: any) => featureObj.properties.cc === geo?.country,
      );
      setGeoJSON(newGeoJSON);
    }

    loadGeoJSON();
  }, [geo]);

  return geoJSON;
}
