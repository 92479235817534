import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { hasAuthentication } from 'utils';

export type UserProfile = Keycloak.KeycloakProfile;

export function useUserProfile() {
  const { initialized, keycloak } = useKeycloak();
  const [profile, setProfile] = useState<UserProfile | undefined>(
    !hasAuthentication() ? {} : undefined,
  );

  useEffect(() => {
    if (initialized) {
      if (keycloak.authenticated) {
        setProfile(getUserProfile(keycloak));
      } else {
        keycloak.login();
      }
    }
  }, [initialized, keycloak]);

  return profile;
}

function getUserProfile(keycloak: Keycloak.KeycloakInstance): Keycloak.KeycloakProfile {
  const idInfo = keycloak.idTokenParsed as any;
  const tokenInfo = keycloak.tokenParsed as any;
  return {
    id: tokenInfo?.sub,
    username: idInfo?.preferred_username || tokenInfo?.preferred_username,
    firstName: idInfo?.given_name || tokenInfo?.given_name,
    lastName: idInfo?.family_name || tokenInfo?.family_name,
    emailVerified: idInfo?.email_verified || tokenInfo?.email_verified,
    email: idInfo?.email || tokenInfo?.email,
  };
}
