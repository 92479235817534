import { gql } from '@apollo/client';
import { useAssetQuery } from 'hooks';
import { AssetType } from 'model';

const assetQuery = gql`
  query FacilityAboutData($facilitySet: [String]!) {
    facilityData(facilitySet: $facilitySet) {
      facility {
        id 
        name
        peerAsnCount
        geo {
          address
          city
          country
          countryDivision
          latLon
        }
        ownerName
        url
      }
    }
  }
`;

export function useFacilityQuery(assetValue: string) {
  const { asset, loading, error } = useAssetQuery(AssetType.Facility, assetValue, assetQuery);
  return { asset, loading, error };
}
