import React, { Fragment } from 'react';
import { AdvancedSearchLink, formatDiffsAttributes } from 'components';
import { Attribute } from 'model';

export function FlexAttributes({ attributes }: { attributes: Attribute[] }) {
  attributes = formatDiffsAttributes(attributes);

  return (
    <div className="app-flex-attributes">
      {attributes.map((attribute, index) => (
        <Fragment
          key={`${index}-${attribute.label}-${attribute.sublabel}-${attribute.value?.props?.value || attribute.value}`}
        >
          <div className={`app-attribute-label ${attribute.rowClass || ''}`}>{attribute.label}</div>
          <div className={`app-attribute-sublabel ${attribute.rowClass || ''}`}>
            {attribute.sublabel}
          </div>
          <div className={`app-attribute-value ${attribute.rowClass || ''}`}>
            {attribute?.advancedQuery ? (
              <AdvancedSearchLink aq={attribute.advancedQuery} linkValue={attribute.value} />
            ) : (
              attribute.value
            )}
          </div>
        </Fragment>
      ))}
    </div>
  );
}
