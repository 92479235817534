import React, { useCallback } from 'react';
import { AdvancedQuery } from 'model';
import { navigateToQueryResults } from 'utils';

export function AdvancedSearchLink({ linkValue, aq }: { linkValue: string; aq: AdvancedQuery }) {
  const goToAQ = useCallback(() => {
    navigateToQueryResults(aq);
  }, [aq]);

  return (
    <div className="uk-link app-fit-content" onClick={goToAQ}>
      {linkValue}
    </div>
  );
}
