import React from 'react';
import { ReactComponent as DeleteIcon } from 'svg/actions/x-default.svg';

export function ShortCutTooltip({ onClose }: { onClose: Function }) {
  return (
    <div className="shortcut-overlay">
      <div className="shortcut-menu">
        <div className="shortcut-title uk-flex uk-flex-between">
          Shortcuts
          <button
            className="uk-button uk-icon-link uk-float-right uk-padding-remove hide-in-pdf"
            style={{ background: 'none' }}
            onClick={() => onClose()}
          >
            <DeleteIcon width={20} height={20} />
          </button>
        </div>
        <div className="shortcut-subtitle">SYSTEM</div>
        <ShortCutMenuItem
          title={'Shortcuts'}
          description={'view available shortcuts'}
          shortcut={'?'}
        />
        <ShortCutMenuItem
          title={'Search'}
          description={'search the knowledge graph'}
          shortcut={'/'}
        />
        <ShortCutMenuItem
          title={'Advanced Search'}
          excludeDivider={true}
          description={'navigate to advanced search'}
          shortcut={'a'}
        />
        <div className="shortcut-subtitle">MOSAICS & TEMPLATES</div>
        <ShortCutMenuItem title={'Tile List'} description={'see list of tiles'} shortcut={'e'} />
        <ShortCutMenuItem
          title={'Template Variables'}
          description={'see template variable definitions'}
          shortcut={'v'}
        />
        <ShortCutMenuItem title={'Share'} description={'open share menu'} shortcut={'s'} />
        <ShortCutMenuItem
          title={'Navigation'}
          description={'move up or down by tile'}
          shortcut={'j/k'}
        />
        <ShortCutMenuItem
          title={'Tile View'}
          description={'expand/collapse the focused tile'}
          shortcut={'Space'}
        />
        <ShortCutMenuItem
          title={'New Tile Below'}
          description={'insert a new tile below the focused tile'}
          shortcut={'Shift ↓'}
        />
        <ShortCutMenuItem
          title={'New Tile Above'}
          description={'insert a new tile above the focused tile'}
          excludeDivider={true}
          shortcut={'Shift ↑'}
        />
      </div>
    </div>
  );
}

function ShortCutMenuItem({
  title,
  description,
  shortcut,
  excludeDivider,
}: { title: string; description: string; shortcut: string; excludeDivider?: boolean }) {
  return (
    <div
      className={`uk-flex uk-flex-between shortcut-item ${!excludeDivider ? 'shortcut-divider' : ''}`}
    >
      <div>
        <div>{title}</div>
        <div className="shortcut-description">{description}</div>
      </div>
      <div className="hotkey uk-float-right">{shortcut}</div>
    </div>
  );
}
