export const EDGE_PROXY_ID_DELIMITER = '|';

// Traceroute cards are unique in the sense that it's a combination of a source and destination that forms the "edge", so the ID is a concatenation where order is important.
export function getIPOrRouterIdentifier(ipOrRouter: any) {
  const srcId = ipOrRouter.router1 ? ipOrRouter.router1.routerId : ipOrRouter.ip1?.ip;
  const dstId = ipOrRouter.router2 ? ipOrRouter.router2.routerId : ipOrRouter.ip2?.ip;
  return (
    srcId + EDGE_PROXY_ID_DELIMITER + dstId + EDGE_PROXY_ID_DELIMITER + ipOrRouter.tpfx?.prefix
  );
}

export function decodeIPOrRouterIdentifier(identifier: string) {
  return identifier.split(EDGE_PROXY_ID_DELIMITER);
}
