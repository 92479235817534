import React, { useCallback, useState, useRef } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { invokeSearch, querySuggestions } from 'utils';
import { userSearchHistoryVar } from 'model';
import { useModelVar } from 'hooks';
import { useInView } from 'react-intersection-observer';

let optionsPromise: Promise<any[]>;

export function KnowledgeSearch({
  selectProps,
  hidden,
  defaultMenuIsOpen,
  dataTestId = 'knowledge-search',
}: { selectProps?: any; hidden?: boolean; defaultMenuIsOpen?: boolean; dataTestId?: string }) {
  const { ref } = useInView();
  const userSearchHistory = useModelVar(userSearchHistoryVar);
  const getSearchHistoryOptions = useCallback(
    (input: string) => {
      return userSearchHistory
        ? [
            {
              label: 'History',
              options: input
                ? userSearchHistory.filter((option) =>
                    option.label.toLowerCase().includes(input.toLowerCase()),
                  )
                : userSearchHistory,
            },
          ]
        : [];
    },
    [userSearchHistory],
  );
  const [currentSuggestions, setCurrentSuggestions] = useState<any>(() =>
    getSearchHistoryOptions(''),
  );
  const [knowledgeSearchInput, setKnowledgeSearchInput] = useState<string>();
  const inputRef = useRef<string>();
  const onSelect = useCallback(
    async (selection) => {
      if (selection.__isNew__)
        selection = { value: knowledgeSearchInput, label: knowledgeSearchInput };
      if (selection) {
        const value = selection.value.trim();
        const label = selection.label.trim();
        inputRef.current = undefined;
        setKnowledgeSearchInput(null);
        await invokeSearch({ ...selection, value, label });
        return;
      }
    },
    [knowledgeSearchInput],
  );

  return (
    <div ref={ref} className="asset-search-select" data-testid={dataTestId}>
      {!hidden && (
        <AsyncCreatableSelect
          placeholder="Search"
          value={null}
          inputValue={knowledgeSearchInput}
          defaultMenuIsOpen={defaultMenuIsOpen}
          blurInputOnSelect={true}
          onChange={onSelect as any}
          openMenuOnFocus={defaultMenuIsOpen}
          onInputChange={(newInput, e) => {
            setKnowledgeSearchInput(newInput);
            if (newInput || e.action === 'input-change') {
              inputRef.current = newInput;
            }
            if (!newInput && e.action === 'input-change') {
              setCurrentSuggestions(getSearchHistoryOptions(''));
            }
          }}
          onMenuClose={() => {
            setKnowledgeSearchInput(inputRef.current);
          }}
          onMenuOpen={() => {
            if (!inputRef.current) {
              setCurrentSuggestions(getSearchHistoryOptions(''));
            }
          }}
          defaultOptions={currentSuggestions}
          loadOptions={async (name, callback) => {
            const newSuggestions = await loadSuggestOptions(name, callback);
            if (!newSuggestions.find((section) => section.label === 'History')) {
              newSuggestions.concat(getSearchHistoryOptions(name));
            }
            setCurrentSuggestions(newSuggestions);
          }}
          formatCreateLabel={(value: string) => value}
          noOptionsMessage={() => 'No suggestions or previous searches'}
          menuPortalTarget={document.body}
          {...selectProps}
        />
      )}
    </div>
  );
}

async function loadSuggestOptions(name: string, callback: (options: any[]) => void) {
  if (name && name.length > 2) {
    optionsPromise = querySuggestions(name);
    const options = await optionsPromise;
    callback(options);
    return options;
  } else {
    callback([]);
    return [];
  }
}
