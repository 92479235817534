import { ReactiveVar } from '@apollo/client';
import { useForceUpdate } from 'hooks';
import { useEffect } from 'react';

// Was getting the following React warning when switching between views on the start page:
// Warning: Can't perform a React state update on an unmounted component.
// (see also https://github.com/apollographql/apollo-client/issues/6209)
//
// This version of useReactiveVar() fixes the issue by ensuring there are no more
// updates after the component is unmounted.

export function useModelVar<T = any>(rv: ReactiveVar<T>) {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    let isMounted = true;
    let mute: () => void;

    function onChange() {
      if (isMounted) {
        forceUpdate();
        mute = rv.onNextChange(onChange);
      }
    }

    mute = rv.onNextChange(onChange);

    return () => {
      isMounted = false;
      mute();
    };
  }, [rv, forceUpdate]);

  return rv();
}
