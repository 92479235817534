import React, { lazy, useContext, useState, useRef, Suspense, useMemo } from 'react';
import { SidebarContext } from 'appContexts';
import { debounce } from 'lodash';
import { AssetType, UserInvestigation, UserTag } from 'model';
import {
  DEBOUNCE_TIMEOUT,
  InvestigationTypes,
  getCurrentInvestigation,
  humanizeDateTime,
  isAdvancedSearch,
  isViewOnly,
  saveInvestigation,
} from 'utils';
import { getMarkdownRenderers } from './ViewHeader';
import { UserTagsSelector, Spinner } from 'components';
import ReactMarkdown from 'react-markdown';
import { ReactComponent as MosaicIcon } from 'svg/mosaic/mosaic-default-filled.svg';
import { ReactComponent as StructureIcon } from 'svg/experimental/structure.svg';
import { ReactComponent as TemplateIcon } from 'svg/mosaic/mosaic-default-outline.svg';
import { ReactComponent as ASIcon } from 'svg/experimental/autonomous-system.svg';
import { ReactComponent as CountryIcon } from 'svg/experimental/country.svg';
import { ReactComponent as DomainIcon } from 'svg/experimental/globe.svg';
import { ReactComponent as FacilityIcon } from 'svg/experimental/building.svg';
import { ReactComponent as OrgIcon } from 'svg/experimental/organization.svg';
import { ReactComponent as PrefixIcon } from 'svg/experimental/prefix.svg';
import { ReactComponent as RouterIcon } from 'svg/experimental/router.svg';
import { ReactComponent as GenericIcon } from 'svg/mosaic/tile-outline.svg';

import gfm from 'remark-gfm';

const MarkdownEditor = lazy(() => import('../markdown/MarkdownEditor'));

type InvestigationHeaderProps = {
  isMainFocused: boolean;
  toggleMainIsFocused: Function;
};

const assetTypeToLabel: Record<string, string> = {
  [AssetType.IP]: 'IP Address',
  [AssetType.AS]: 'Autonomous System',
  [AssetType.IX]: 'Internet Exchange',
  [AssetType.Org]: 'Organization',
  [AssetType.Domain]: 'Domain',
  [AssetType.Prefix]: 'Prefix',
  [AssetType.Hostname]: 'Hostname',
  [AssetType.Facility]: 'Facility',
  [AssetType.Country]: 'Country',
  [AssetType.Router]: 'Router',
};

const assetTypeToIcon: Record<string, any> = {
  [AssetType.IP]: <PrefixIcon />,
  [AssetType.AS]: <ASIcon />,
  [AssetType.IX]: <FacilityIcon />,
  [AssetType.Org]: <OrgIcon />,
  [AssetType.Domain]: <DomainIcon />,
  [AssetType.Prefix]: <PrefixIcon />,
  [AssetType.Hostname]: <DomainIcon />,
  [AssetType.Facility]: <FacilityIcon />,
  [AssetType.Country]: <CountryIcon />,
  [AssetType.Router]: <RouterIcon />,
};

export function InvestigationHeader({
  isMainFocused,
  toggleMainIsFocused,
}: InvestigationHeaderProps) {
  const { investigation, localAnnotations, setLocalAnnotations } = useContext(SidebarContext);
  const [localTags, setLocalTags] = useState(investigation?.tags ?? []);
  const isTemplate = investigation?.isTemplate;
  const isSharedInvestigation = window.location.pathname.includes('shared');
  const isAssetReport = window.location.pathname.includes('assetreport');
  const urlAssetType = isAssetReport
    ? window.location?.search?.split('?')[1]?.split('=')[0]
    : undefined;
  const headingCountRef = useRef<Record<string, number[]>>({});
  const markdownComponents = useMemo(
    () => getMarkdownRenderers(headingCountRef),
    [headingCountRef],
  );

  const debounceTitle = useRef(
    debounce((newTitle: string, investigation: UserInvestigation) => {
      investigation && saveInvestigation({ ...investigation, title: newTitle });
    }, DEBOUNCE_TIMEOUT),
  ).current;

  const debounceDescription = useRef(
    debounce((newDescription: string) => {
      // Make sure we're using most recent investigation since MarkdownEditor onchange isn't updated after render
      const currentInvestigation = getCurrentInvestigation();
      currentInvestigation &&
        saveInvestigation({
          ...currentInvestigation,
          description: newDescription,
        });
    }, DEBOUNCE_TIMEOUT),
  ).current;

  const debounceTags = useRef(
    debounce((newTags, investigation: UserInvestigation) => {
      investigation && saveInvestigation({ ...investigation, tags: newTags });
    }, DEBOUNCE_TIMEOUT),
  ).current;

  return (
    <div className={`ib-header ${isMainFocused ? 'ib-section-focused' : ''}`}>
      <div className="uk-flex uk-flex-between">
        <div className="uk-text-uppercase uk-margin-top-remove report-title-label uk-flex uk-flex-middle">
          {isTemplate ? (
            <TemplateIcon />
          ) : isAssetReport ? (
            assetTypeToIcon[urlAssetType] ?? <GenericIcon />
          ) : isAdvancedSearch() ? (
            <StructureIcon />
          ) : (
            <MosaicIcon />
          )}
          {isSharedInvestigation
            ? `Gallery / ${isTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic}`
            : isAssetReport
              ? assetTypeToLabel[urlAssetType] ?? 'Report'
              : isAdvancedSearch()
                ? 'Advanced Search'
                : `Collections / ${isTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic}`}
        </div>
        {investigation.saved && (
          <div className="uk-text-muted uk-margin-top uk-margin-bottom hide-in-pdf">
            {humanizeDateTime(new Date(investigation.saved))}
          </div>
        )}
      </div>
      <div className="header-divider" />
      <div className="uk-position-relative">
        {!isAdvancedSearch() && isMainFocused && !isViewOnly() && (
          <div
            className="focused-tile-gutter"
            onClick={() => {
              toggleMainIsFocused();
            }}
          />
        )}
        <div className="uk-margin-medium-top" hidden={!isMainFocused || isViewOnly()}>
          <div className="input-label uk-margin-small-top">Title</div>
          {!isViewOnly() && (
            <input
              className="uk-input uk-margin-medium-bottom"
              type="text"
              value={localAnnotations?.mainTitle ?? ''}
              placeholder={'Enter a title'}
              onChange={(e) => {
                const val = e.target.value;
                setLocalAnnotations((curr) => ({ ...curr, mainTitle: val }));
                debounceTitle(val, investigation);
              }}
            />
          )}
          <div className="input-label uk-margin-small-bottom">Tags</div>
          <UserTagsSelector
            selectedTags={localTags}
            onSelect={(tags: UserTag[]) => {
              setLocalTags(tags);
              debounceTags(tags, investigation);
            }}
          />
          <div className="uk-flex">
            <div className="input-label uk-margin-medium-top uk-margin-small-bottom">
              Description
            </div>
          </div>
          <Suspense fallback={<Spinner ratio={2} />}>
            {localAnnotations && (
              <MarkdownEditor
                allowImageUpload={true}
                value={localAnnotations?.mainDesc ?? ''}
                onChange={(newDescription) => {
                  setLocalAnnotations((curr) => ({ ...curr, mainDesc: newDescription }));
                  debounceDescription(newDescription);
                }}
              />
            )}
          </Suspense>
        </div>
      </div>
      <div className="uk-position-relative">
        {!isAdvancedSearch() && (!isMainFocused || (isMainFocused && isViewOnly())) && (
          <div
            className="focused-tile-gutter"
            onClick={() => {
              toggleMainIsFocused();
            }}
          />
        )}
        <div className="uk-margin-medium-top" hidden={isMainFocused && !isViewOnly()}>
          <div className="uk-flex-inline uk-flex-middle" style={{ width: '100%' }}>
            {(investigation?.title || investigation?.subtitle) && (
              <div>
                {investigation?.title && (
                  <h1 className="app-asset-title uk-margin-remove">{investigation?.title}</h1>
                )}
                {investigation?.subtitle && (
                  <div
                    id="investigation-subtitle"
                    style={{ fontSize: '14px' }}
                    className="uk-text-muted uk-margin-remove ib-section-width-6"
                  >
                    {investigation.subtitle}
                  </div>
                )}
              </div>
            )}
          </div>
          {investigation?.tags && (
            <div className="app-tag-container mosaic-header-piece uk-width-large hide-in-pdf">
              {investigation?.tags.map((tag: UserTag) => (
                <div className="app-tag" key={tag.name}>
                  {tag.name}
                </div>
              ))}
            </div>
          )}
          <ReactMarkdown
            className="ib-header-notes"
            plugins={[gfm]}
            components={markdownComponents}
            children={investigation.description}
          />
        </div>
      </div>
    </div>
  );
}
