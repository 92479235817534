import React, {
  ChangeEvent,
  Fragment,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ALERT_TYPES,
  UserDataset,
  UserInvestigation,
  UserInvestigationSection,
  userInvestigationsVar,
  userNotifications,
} from 'model';
import {
  createUniqueInvestigationSectionId,
  deleteUserDataset,
  getDefaultTitle,
  getUserDatasetReferences,
  getViewOption,
  loadingDatasetStatuses,
  navigateToQueryResults,
  newAdvancedQuery,
  saveInvestigation,
  saveUserDataset,
  USER_DATASET_STATUS,
} from 'utils';
import { Banner, Kebab, KebabOption, Modal } from 'components';
import { useHistory } from 'react-router-dom';
import { ReactComponent as PrefixIcon } from 'svg/experimental/prefix.svg';
import { ReactComponent as PcapIcon } from 'svg/actions/table.svg';
import { ReactComponent as EditIcon } from 'svg/actions/edit-on.svg';
import { ReactComponent as NewSearchIcon } from 'svg/system/magnifying-glass.svg';
import { ReactComponent as TrashIcon } from 'svg/actions/trash.svg';
import { ReactComponent as NewMosaicIcon } from 'svg/mosaic/mosaic-new.svg';
import { useReactiveVar } from '@apollo/client';
import { ReferencesTable } from './ReferencesTable';

const resolutionToIcon: Record<string, any> = {
  ips: <PrefixIcon />,
  pcap: <PcapIcon />,
};

export function DatasetEditor({
  disableEditingOptions,
  dataset,
  isEditMode,
  setIsEditMode,
}: {
  disableEditingOptions?: boolean;
  dataset: UserDataset;
  isEditMode: boolean;
  setIsEditMode?: Function;
}) {
  const history = useHistory();
  const [updateDisabled, setUpdateDisabled] = useState<boolean>(true);
  const [name, setName] = useState<string>(dataset?.name ?? '');
  const [description, setDescription] = useState<string>(dataset?.description ?? '');
  const [validationError, setValidationError] = useState<string | undefined>();
  const notifications = useReactiveVar(userNotifications);
  const investigations = useReactiveVar(userInvestigationsVar);

  const isImporting = useMemo(() => {
    return loadingDatasetStatuses.includes(dataset?.system?.status);
  }, [dataset]);

  // Clear any existing notification for this dataset
  useEffect(() => {
    const currentNotification = dataset?.id ? notifications[dataset.id] : undefined;
    if (currentNotification) {
      currentNotification.onClear && currentNotification.onClear();
    }
  }, [dataset, notifications]);

  let message = '';
  let title = '';
  let icon = undefined;

  let alertType = ALERT_TYPES.primary;
  switch (dataset?.system?.status) {
    case USER_DATASET_STATUS.FileImporting: {
      title = 'Data Importing';
      message = `${dataset?.filename} is being imported to your profile.`;
      alertType = ALERT_TYPES.primary;
      break;
    }
    case USER_DATASET_STATUS.Error: {
      title = 'Data Upload Failed';
      message = `An error occurred while uploading ${dataset?.filename}`;
      alertType = ALERT_TYPES.error;
      break;
    }
    case USER_DATASET_STATUS.FileImported: {
      title = 'Data Is Ready!';
      message = `${dataset?.name} (${dataset?.filename}) is ready to view.`;
      alertType = ALERT_TYPES.success;
      break;
    }
    default: {
      title = 'Data Uploading';
      message = `${dataset?.filename} is being uploaded.`;
      icon = '😎';
    }
  }

  // set validation error if it shows up in the system status
  useEffect(() => {
    if (dataset?.system?.status === 'error') {
      setValidationError(dataset?.system?.error);
    } else {
      setValidationError(undefined);
    }
  }, [dataset]);

  useEffect(() => {
    function userDatasetInfoChanged() {
      if (name !== dataset?.name ?? '') {
        return true;
      }
      return description !== dataset?.description ?? '';
    }

    if (userDatasetInfoChanged()) {
      setUpdateDisabled(false);
    } else {
      setUpdateDisabled(true);
    }
  }, [dataset, name, description]);

  // Update name or description when dataset changes
  useEffect(() => {
    setName((curr) => (curr !== dataset?.name ? dataset?.name ?? '' : curr));
    setDescription((curr) => (curr !== dataset?.description ? dataset?.description ?? '' : curr));
  }, [dataset]);

  function handleClose(ev: MouseEvent<HTMLButtonElement>) {
    ev.preventDefault();
    setIsEditMode && setIsEditMode(false);
  }

  function handleUpdate() {
    if (dataset == null) {
      return;
    }
    const newDataset: any = {
      ...dataset,
      name,
      description,
    };
    setIsEditMode && setIsEditMode(false);
    saveUserDataset(newDataset);
  }

  const newAdvancedSearchCallback = useCallback(() => {
    const aq = newAdvancedQuery({
      value: dataset.id,
      label: dataset.name,
      typename: dataset.typename,
    });
    navigateToQueryResults(aq);
  }, [dataset]);

  const newMosaicCallback = useCallback(() => {
    const aq = newAdvancedQuery({
      value: dataset.id,
      label: dataset.name,
      typename: dataset.typename,
    });
    const investigation: UserInvestigation = {
      sections: [],
      title: getDefaultTitle(),
      subtitle: '',
      description: '',
    };

    const newSection: UserInvestigationSection = {
      id: createUniqueInvestigationSectionId(getViewOption(aq)?.componentPluginName, {
        advancedQuery: aq,
      }),
      title: 'Search',
      description: '',
    };
    investigation.sections.push(newSection);

    saveInvestigation(investigation);

    const shortID = (investigation.id as string).split(':')[1];
    history.push(`/mosaic?id=${shortID}`);
  }, [history, dataset]);

  function onNameChange(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function onDescriptionChange(newDesc: string) {
    setDescription(newDesc);
  }

  const referencedMosaics = useMemo(() => {
    if (!dataset) return [];
    return getUserDatasetReferences(dataset, investigations);
  }, [dataset, investigations]);

  const kebabOptions = useMemo(() => {
    let options: KebabOption[] = disableEditingOptions
      ? []
      : [
          {
            label: 'Edit',
            icon: <EditIcon />,
            callback: () => setIsEditMode && setIsEditMode(true),
          },
          {
            label: 'Delete',
            icon: <TrashIcon />,
            callback: () => {
              deleteCallback(dataset, false, () => setIsEditMode(false), referencedMosaics);
            },
          },
        ];
    if (alertType === ALERT_TYPES.success)
      options = options.concat([
        {
          label: 'New Search',
          icon: <NewSearchIcon className="search-icon" />,
          callback: newAdvancedSearchCallback,
        },
        {
          label: 'New Mosaic',
          icon: <NewMosaicIcon />,
          callback: newMosaicCallback,
        },
      ]);
    if (options.length === 0) return {};
    return { ACTIONS: options };
  }, [
    newAdvancedSearchCallback,
    newMosaicCallback,
    alertType,
    dataset,
    setIsEditMode,
    disableEditingOptions,
    referencedMosaics,
  ]);

  const kebabStyle = { transform: 'translateX(12px)', width: '24px' };

  return (
    <Fragment>
      {isEditMode && !isImporting ? (
        <div>
          <div className="uk-float-right">
            <Kebab options={kebabOptions} data={{}} style={kebabStyle} allDisabled={isImporting} />
          </div>
          <div className="uk-float-left app-width-full">
            <label className="input-label">Data Set Name</label>
            <input
              className="uk-input uk-margin-small-bottom"
              value={name}
              onChange={onNameChange}
              placeholder="Enter a name for this dataset"
            />
            <label className="input-label">Description</label>
            <label className="input-sublabel"> (optional)</label>
            <textarea
              className="uk-textarea"
              placeholder="Add a description for this Dataset."
              rows={16}
              defaultValue={description}
              onChange={(ev) => onDescriptionChange(ev.target.value)}
            />
            <div className="uk-text-right uk-margin-medium-top">
              <button type="button" className="uk-button" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="uk-button uk-button-default uk-float-right"
                disabled={updateDisabled}
                onClick={handleUpdate}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="import-panel-contents">
          <div className="uk-flex uk-flex-between">
            <div>
              <div className="report-title-label uk-flex uk-text-uppercase">
                {dataset?.typename && resolutionToIcon[dataset?.typename]}
                {`${dataset?.typename ?? 'Unknown'} Dataset`}
              </div>
              <div className="app-text-medium">{dataset?.name ?? ''}</div>
            </div>
            <Kebab options={kebabOptions} data={{}} style={kebabStyle} allDisabled={isImporting} />
          </div>
          {dataset?.description && (
            <div className="import-description-container">
              <div className="app-banner-description uk-margin-medium-right">
                {dataset?.description ?? ''}
              </div>
            </div>
          )}
          <div>
            {validationError && (
              <Banner
                message={validationError}
                alertType={ALERT_TYPES.error}
                title={'Data Upload Error'}
                noClose={true}
              />
            )}
            {dataset && !validationError && (
              <Banner
                title={title}
                message={message}
                alertType={alertType}
                icon={icon}
                noClose={true}
                style={{ margin: '24px 16px 24px 0' }}
              />
            )}
          </div>
          {dataset?.system?.status === USER_DATASET_STATUS.FileImported &&
            !disableEditingOptions && (
              <ReferencesTable referencedInvestigations={referencedMosaics} />
            )}
        </div>
      )}
    </Fragment>
  );
}

export function deleteCallback(
  dataset: UserDataset,
  preventModal?: boolean,
  callback?: Function,
  referencedMosaics?: UserInvestigation[],
) {
  if (!preventModal) {
    Modal.show({
      title: 'Delete Dataset?',
      body: (
        <DeleteModal datasets={[dataset]} isMulti={false} referencedMosaics={referencedMosaics} />
      ),
      action: {
        label: 'Delete',
        onClick: () => {
          callback && callback();
          deleteUserDataset(dataset);
        },
        cancelable: true,
      },
    });
  } else {
    callback && callback();
    deleteUserDataset(dataset);
  }
}

export function deleteAllDatasets(
  datasets: UserDataset[],
  preventModal?: boolean,
  callback?: Function,
  referencedMosaics?: UserInvestigation[],
) {
  if (!preventModal) {
    Modal.show({
      title: 'Delete Dataset?',
      body: (
        <DeleteModal datasets={datasets} referencedMosaics={referencedMosaics} isMulti={true} />
      ),
      action: {
        label: 'Delete',
        onClick: () => {
          callback && callback();
          datasets.forEach((dataset) => {
            deleteUserDataset(dataset);
          });
        },
        cancelable: true,
      },
    });
  } else {
    callback && callback();
    datasets.forEach((dataset) => {
      deleteUserDataset(dataset);
    });
  }
}

function DeleteModal({
  datasets,
  referencedMosaics,
  isMulti,
}: { datasets: UserDataset[]; isMulti: boolean; referencedMosaics?: UserInvestigation[] }) {
  if (datasets.length === 0) return;
  return (
    <span>
      Are you sure you want to delete
      {isMulti ? (
        <span>{` ${datasets.length} 'Dataset(s) from your account?'`}</span>
      ) : (
        <span className="uk-text-bold">{` ${datasets[0]?.name ?? 'this dataset'}?`}</span>
      )}
      {referencedMosaics?.length ? (
        <div className="uk-margin-top">
          {datasets.length === 1 ? 'It is' : 'These are'} referenced by
          {referencedMosaics.map((mosaic, i) => {
            if (i > 4) return null;
            const isLast = i === referencedMosaics.length - 1;
            const multiple = i > 1;
            if (i === 4 && referencedMosaics.length > 5)
              return ` and ${referencedMosaics.length - 5} other${referencedMosaics.length > 6 ? 's' : ''}.`;
            return (
              <span key={i}>
                {`${isLast && multiple ? ' and ' : ' '}`}
                <span className="uk-text-bold">{mosaic.title}</span>
                {`${!isLast ? ',' : '.'}`}
              </span>
            );
          })}
        </div>
      ) : null}
    </span>
  );
}
