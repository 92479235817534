import React, {
  Fragment,
  memo,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AddToInvestigationKebabMenu,
  AttributeTable,
  Banner,
  ComponentPluginName,
  DataExportForm,
  Kebab,
  KebabOption,
  legacyPluginNames,
  ShareLinkKebabMenu,
  Spinner,
  tooltipComponents,
} from 'components';

import { DescriptionEditor, ViewHeader } from '../investigations';
import {
  AssetInfoContext,
  ComponentPluginContext,
  ComponentPluginContextValue,
  ComponentPluginContextValueOnToggleLock,
} from 'appContexts';
import {
  formatMetadataRange,
  getAssetTypeFromPlugin,
  getConfigAdvancedQuery,
  getCurrentInvestigation,
  getOutputVariable,
  getDefaultSectionTitle,
  getQueryClauseAssetType,
  getTemplateAssetTypeFromId,
  getTemplateParamId,
  getViewOption,
  guessAssetType,
  isAdvancedSearch,
  isDatasetQuery,
  isViewOnly,
  lookupSectionTemplateParams,
  MetadataPeriod,
  navigateToQueryResults,
  parseInvestigationSectionId,
  pruneEmptyFilters,
  saveTileState,
  WIDE_PRINT_COMPONENTS,
  evaluateQueryVariables,
  isAuthorized,
  NON_PLAYGROUND_QUERIES,
  linkableComponentPlugins,
  isOutputVariable,
} from 'utils';
import { cloneDeep, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

// Feature Icons
import { ReactComponent as CopyIcon } from 'svg/mosaic/mosaic-copy.svg';
import { ReactComponent as DownloadIcon } from 'svg/system/transfer-down.svg';
import { ReactComponent as ExpandOpen } from 'uikit/src/images/icons/chevron-down.svg';
import { ReactComponent as ExpandClose } from 'uikit/src/images/icons/chevron-up.svg';
import { ReactComponent as EndCap } from 'svg/misc/gutter-cap.svg';
import { ReactComponent as ShareIcon } from 'svg/actions/share.svg';
import { ReactComponent as APIIcon } from 'svg/experimental/diamond.svg';
import { ReactComponent as OpenTileIcon } from 'svg/mosaic/tile-go-to-default.svg';
import { ReactComponent as InfoFilled } from 'svg/actions/info-filled.svg';
import { ReactComponent as Infinity } from 'svg/app/infinity.svg';
import { ReactComponent as Pause } from 'svg/actions/pause.svg';

import {
  advancedQueryPageVar,
  ALERT_TYPES,
  Attribute,
  loadComponentPlugin,
  QueryFilterClause,
  TemplateParam,
  TemplateParams,
  UserInvestigationSection,
} from 'model';
import { useHistory } from 'react-router-dom';
import { useQueryValidation, useUserProfile } from 'hooks';
import OutputTile from '../plugins/OutputTile';
import { apiPlaygroundVar } from 'model/apiTypes';
import { useKeycloak } from '@react-keycloak/web';
import { TAB_QUERIES_BY_PLUGIN } from 'utils/tabUtils';

const linkableResolutions = ['ips', 'routers', 'dnsHosts', 'facilities', 'peeringExchanges'];

type InvestigationSectionProps = {
  section: UserInvestigationSection;
  isFocused?: boolean;
  onTileFocus?: any;
  templateParams?: TemplateParams;
  isTemplate?: boolean;
  setLocalTemplateParams?: Function;
  anchorLinkId?: string;
};

export default memo(InvestigationSection, (prevProps, nextProps) => {
  let areEqual = true;
  if (
    nextProps.section !== prevProps.section ||
    nextProps.anchorLinkId !== prevProps.anchorLinkId ||
    nextProps.templateParams !== prevProps.templateParams ||
    nextProps.isFocused !== prevProps.isFocused
  ) {
    areEqual = false;
  }
  return areEqual;
});

// Component for a given section of investigation which includes title, notes, and component itself
function InvestigationSection({
  section,
  isFocused,
  onTileFocus,
  templateParams,
  isTemplate,
  anchorLinkId,
}: InvestigationSectionProps) {
  const [pluginName, params] = useMemo(() => {
    return parseInvestigationSectionId(section.id);
  }, [section.id]);

  const { title, description } = section;
  const [tooltip, setTooltip] = useState();
  const [kebabID] = useState<string>(() => uuidv4());
  // const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const viewOnly = isViewOnly();
  const isSharedInvestigation = window.location.pathname.includes('shared');
  const isAssetReport = window.location.pathname.includes('assetreport');
  const isShareable = isSharedInvestigation || isAssetReport || isAdvancedSearch();

  const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const isPrinterFriendly = useMemo(() => {
    let retVal = searchParams.get('print');
    return retVal === 'true';
  }, [searchParams]);

  const userProfile = useUserProfile();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const [configOptions, setConfigOptions] = useState();
  const [metadata, setMetadata] = useState<Record<string, any> | undefined>();
  const [graphQLQueries, setGraphQLQueries] = useState<Record<string, any> | undefined>();
  const [tileInfoVisible, setTileInfoVisible] = useState(false);

  // Vars to manage editable state of title, description, component config
  const [kebabOptions, setKebabOptions] = useState<Record<any, KebabOption[]>>({});
  // const [keystoneOptions, setKeystoneOptions] = useState<Record<any, KebabOption[]>>({});

  // state for keeping track of what the query looks like when it is actually rendered
  const [renderedQuery, setRenderedQuery] = useState(cloneDeep(getConfigAdvancedQuery(params)));
  // Only used in templates and view only mosaics, since we don't want to save all pieces of config in them
  const [localConfig, setLocalConfig] = useState<Record<string, any> | undefined>(
    cloneDeep(params.config),
  );
  const shouldUseLocalConfig = useMemo(
    () => isTemplate || isViewOnly() || isAdvancedSearch(),
    [isTemplate],
  );
  const usedConfig = useMemo(
    () => (shouldUseLocalConfig ? localConfig : cloneDeep(params.config)),
    [shouldUseLocalConfig, localConfig, params],
  );
  const keystoneExpanded = useMemo(() => usedConfig?.keystone?.expanded, [usedConfig]);
  const isAdvancedQuery = useMemo(() => !!params.advancedQuery, [params.advancedQuery]);
  const isMarkdownTile = pluginName === 'UserMarkdown';
  const isPrimitiveTile = pluginName === 'PrimitiveTile';
  const isNonDataTile = isMarkdownTile || isPrimitiveTile;
  const isSharedDatasetTile = useMemo(() => {
    return isAdvancedQuery && isViewOnly() && isDatasetQuery(getConfigAdvancedQuery(params));
  }, [isAdvancedQuery, params]);
  const { infoMode } = useContext(AssetInfoContext);

  const outputParam = useMemo(() => {
    return getOutputVariable(templateParams, params.uuid);
  }, [templateParams, params]);

  const aqTooltip = useMemo(() => {
    if (params.advancedQuery) {
      const tooltipName = getViewOption(params.advancedQuery)?.tooltipComponent;
      return tooltipComponents[tooltipName];
    }
  }, [params.advancedQuery]);

  const Component = useMemo(() => {
    if (pluginName) {
      return React.lazy(async () => {
        try {
          // Account for legacy plugins.
          const mod = await loadComponentPlugin(legacyPluginNames[pluginName] || pluginName);
          setTooltip(aqTooltip || mod?.TitleTooltip);
          return mod;
        } catch {
          return await loadComponentPlugin('ErrorPlugin');
        }
      });
    }
  }, [aqTooltip, pluginName]);

  const Keystone = useMemo(() => {
    return React.lazy(async () => {
      try {
        return await loadComponentPlugin(
          isAdvancedQuery
            ? ComponentPluginName.QueryKeystoneTile
            : ComponentPluginName.KeystoneTile,
        );
      } catch {
        return await loadComponentPlugin('ErrorPlugin');
      }
    });
  }, [isAdvancedQuery]);

  const menuOpenRef = useRef<boolean>(false);

  const updatePluginConfig = useCallback(
    (config: Record<string, any>) => {
      let componentPluginName = pluginName;
      const newAdvancedQuery = cloneDeep(config?.advancedQuery);
      const newParams = {
        ...params,
        ...(newAdvancedQuery ? { advancedQuery: newAdvancedQuery } : undefined),
        config: isTemplate
          ? // In a template, there are certain types of config we want to keep ephemeral
            {
              ...config,
              ...(config?.viewport ? { viewport: undefined } : undefined),
              ...(config?.history
                ? { history: { historyEnabled: config?.history?.historyEnabled } }
                : undefined), // do not save dateA and dateB
              ...(config[params?.advancedQuery?.view]
                ? {
                    [params.advancedQuery.view]: {
                      ...config[params.advancedQuery.view],
                      selected: [],
                      hideUnselected: false,
                    },
                  }
                : undefined), // do not save selected
            }
          : config,
      };
      // Deprecate old advanced query save location
      if (newAdvancedQuery && newParams?.config?.advancedQuery) {
        delete newParams.config.advancedQuery;
      }
      if (shouldUseLocalConfig) {
        setLocalConfig(config);
      }
      if (newAdvancedQuery) {
        // updatePluginConfig is a callback made from the component itself e.g. QueryResultsDataTableView/QueryResultsGraphView
        // We therefore need to set the rendered query to make its state match
        setRenderedQuery(newAdvancedQuery);
        // if the view changed, set the new plugin
        const viewOption = getViewOption(newAdvancedQuery);
        componentPluginName =
          viewOption?.componentPluginName || ComponentPluginName.QueryResultsDataTableView;
      }
      // In the case of the advanced search page, we don't want to save tile state like in a normal mosaic,
      // advanced searches are ephemeral. Instead, update the keystone reactive var with any new advanced query
      if (isAdvancedSearch()) {
        advancedQueryPageVar(newParams);
        return;
      }
      if (isViewOnly() || !getCurrentInvestigation()) return;
      if (!isEqual(newParams, params)) {
        saveTileState({
          newParams,
          pluginName: componentPluginName,
          investigation: {
            ...getCurrentInvestigation(),
            sections: [...getCurrentInvestigation().sections],
          },
        });
      }
    },
    [params, shouldUseLocalConfig, isTemplate, pluginName],
  );

  const updateTileDefault = useCallback(
    (propParams?: any) => {
      if (!getCurrentInvestigation() || isViewOnly() || isAdvancedSearch()) {
        return;
      }
      const newParams = typeof propParams === 'object' ? propParams : params;
      saveTileState({
        newParams,
        investigation: {
          ...getCurrentInvestigation(),
          sections: [...getCurrentInvestigation().sections],
        },
      });
    },
    [params],
  );

  const onToggleLock = useCallback<ComponentPluginContextValueOnToggleLock>(
    (toggleLockClauseArgs) => {
      function getRelevantTemplateParam(
        isUnlocked: boolean,
        compareValue: string | string[] | undefined,
        assetType: string | undefined,
      ) {
        // If we're unlocking the filter, match the param id to compare value. Otherwise, find the first
        // param whose *value* matches the compare value
        return templateParams?.find((val) => {
          if (isUnlocked || isOutputVariable(val)) {
            return `$${getTemplateParamId(val)}` === compareValue;
          }
          return (
            assetType === getTemplateAssetTypeFromId(getTemplateParamId(val)) &&
            isEqual(val?.value, compareValue)
          );
        });
      }

      // Advanced Query Case
      if (getConfigAdvancedQuery(params)) {
        const newQuery = getConfigAdvancedQuery(params);
        // If we're toggling a filter clause, we may need to add its asset type to the template
        // params object.
        if (!!toggleLockClauseArgs) {
          const { clause, index } = toggleLockClauseArgs;
          const findClause: QueryFilterClause | undefined = newQuery.filterClauses.find(
            (c: QueryFilterClause, i: number) =>
              c.type === clause.type && (i === index || index === undefined),
          );
          if (!findClause) return;
          findClause.isUnlocked = !findClause.isUnlocked;
          const templateParam: TemplateParam = getRelevantTemplateParam(
            findClause.isUnlocked,
            clause.value,
            getQueryClauseAssetType(clause),
          );
          // If we're unlocking, replace the clause value with the relevant template param value. Otherwise, replace it with
          // the new expression. For selection-datasets, we may need to JSON parse the value to get the dataId
          findClause.value = findClause.isUnlocked
            ? templateParam?.value
            : templateParam
              ? `$${getTemplateParamId(templateParam)}`
              : undefined;
          updateTileDefault(params);
          // If We're just toggling a dataset selection, update the selection value
        } else if (isDatasetQuery(newQuery)) {
          params.isUnlocked = !params.isUnlocked;
          const templateParam: TemplateParam = getRelevantTemplateParam(
            params.isUnlocked,
            newQuery?.selection?.value,
            'dataset',
          );
          // if we're locking an output dataset, clear the value
          if (!templateParam || (isOutputVariable(templateParam) && !params.isUnlocked)) {
            newQuery.selection.value = undefined;
            newQuery.selection.label = undefined;
          } else {
            newQuery.selection.value = params.isUnlocked
              ? templateParam?.value
              : `$${getTemplateParamId(templateParam)}`;
            newQuery.selection.label = params.isUnlocked
              ? templateParam?.label ?? templateParam?.value
              : templateParam?.name;
          }
          updateTileDefault(params);
        }
        setRenderedQuery(newQuery);
      } else {
        params.isUnlocked = !params.isUnlocked;
        const templateParam: TemplateParam = getRelevantTemplateParam(
          params.isUnlocked,
          params.assetValue,
          getAssetTypeFromPlugin(pluginName),
        );
        // If we're unlocking, replace the assetValue value with the relevant template param value. Otherwise, replace it with
        // the new expression.
        params.assetValue = params.isUnlocked
          ? templateParam?.value
          : `$${getTemplateParamId(templateParam)}`;
        params.assetName = params.isUnlocked
          ? templateParam?.label ?? templateParam?.value
          : templateParam?.name;
        updateTileDefault(params);
      }
    },
    [params, updateTileDefault, templateParams, pluginName],
  );

  const metadataAttributes: Attribute[] | undefined = useMemo(() => {
    if (metadata) {
      const metadataCount = Object.keys(metadata)?.length;
      return Object.keys(metadata)
        .map((label: string) => {
          const { indexName, lastUpdated } = metadata[label];
          const [metadataRange, updateFrequency] = formatMetadataRange(indexName, lastUpdated);

          // Class to handle soft failure for missing metadata frequency
          const pillVisibilityClass =
            !updateFrequency && metadataCount > 1
              ? 'uk-invisible'
              : !updateFrequency && metadataCount === 1
                ? 'uk-hidden'
                : '';

          return {
            label,
            value: (
              <Fragment>
                <div
                  className={`app-metadata-frequency app-attribute-pill app-pill-neutral ${pillVisibilityClass}`}
                >
                  {updateFrequency === MetadataPeriod.Continuous ? (
                    <Infinity width={16} height={16} />
                  ) : updateFrequency === MetadataPeriod.Static ? (
                    <Pause width={16} height={16} />
                  ) : (
                    updateFrequency
                  )}
                </div>
                {metadataRange}
              </Fragment>
            ),
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    }
  }, [metadata]);

  // Returns the array of template params which are actively used by this tile
  const activeTemplateParams = useMemo(() => {
    if (isNonDataTile || !templateParams) return [];
    return lookupSectionTemplateParams(templateParams, { ...params, advancedQuery: renderedQuery });
  }, [params, isNonDataTile, templateParams, renderedQuery]);

  // In order to prevent nonsense empty filters from propagating when a new filter is added,
  // prune any empty filters. Also, process template expression lookups here
  const processedQuery = useMemo(() => {
    let aq = cloneDeep(
      renderedQuery
        ? {
            ...renderedQuery,
            viewConfig:
              isViewOnly() || isAdvancedSearch()
                ? renderedQuery?.viewConfig
                : getConfigAdvancedQuery(params)?.viewConfig,
          }
        : undefined,
    );
    if (activeTemplateParams.length > 0 && aq) {
      aq = evaluateQueryVariables(aq, activeTemplateParams, params.isUnlocked);
    }
    return aq && pruneEmptyFilters(aq);
  }, [params, activeTemplateParams, renderedQuery]);
  // Require update from user if the query's plugin differs from the actual encoded plugin
  const pluginChanged =
    getViewOption(processedQuery) &&
    getViewOption(processedQuery).componentPluginName !==
      (legacyPluginNames[pluginName] ?? pluginName) &&
    !isAdvancedSearch();
  // Set query validation error messages
  const messages = useQueryValidation(
    pluginChanged ? undefined : processedQuery,
    pluginName,
    params.isUnlocked,
  );
  const processedParams = useMemo(() => {
    // These params are the normal params, but removed undefined filters on the query.
    // We'll pass these into the reactive tile.
    // If it's a template, assetValues need to be looked up based on the expression
    // and config needs to be replaced with local config
    let assetValue = params.assetValue;
    if (assetValue?.startsWith('$')) {
      assetValue = activeTemplateParams?.find(
        (val) => `$${getTemplateParamId(val)}` === assetValue,
      )?.value;
    }

    const newParams = { ...cloneDeep(params), config: usedConfig };
    if (newParams?.config?.advancedQuery) {
      newParams.config.advancedQuery = processedQuery;
    }

    if (processedQuery) {
      return {
        ...newParams,
        assetValue,
        advancedQuery: processedQuery,
      };
    }
    return { ...newParams, assetValue };
  }, [params, processedQuery, activeTemplateParams, usedConfig]);

  const hasUnfilledTemplateParams = useMemo(() => {
    if (!isTemplate) {
      return (
        (!isAdvancedQuery && !params.assetValue) ||
        (isAdvancedQuery && !getConfigAdvancedQuery(params)?.resolution)
      );
    }
    if (isNonDataTile || !templateParams || (params.isUnlocked && params.assetValue)) return false;
    if (isAdvancedQuery && !processedQuery) return false;
    if (!!processedQuery) {
      // if it's a dataset query, and that dataset param is undefined, display banner.
      return (
        isDatasetQuery(processedQuery) && !processedQuery.selection.value && !params.isUnlocked
      );
    }
    if (activeTemplateParams.length === 0) return true;
    // loop through template params. If any param matching this section isn't defined, return true.
    return templateParams.some((param) => {
      return (
        activeTemplateParams.find((val) => getTemplateParamId(val) === getTemplateParamId(param)) &&
        !param.value
      );
    });
  }, [
    activeTemplateParams,
    isTemplate,
    isNonDataTile,
    templateParams,
    params,
    processedQuery,
    isAdvancedQuery,
  ]);

  // Show metadata info button when asset page tile has assetValue or advanced search has rendered view, assetValue/query selection
  // Currently don't show metadata for userdataset tiles
  const canViewMetadata = useMemo(() => {
    const isExecutedAdvancedSearch =
      renderedQuery && (params?.assetValue || params?.advancedQuery?.selection?.value);
    const hasAssetValue = !isAdvancedQuery && params?.assetValue;

    return (hasAssetValue || isExecutedAdvancedSearch) && !isDatasetQuery(params?.advancedQuery);
  }, [isAdvancedQuery, params?.advancedQuery, params?.assetValue, renderedQuery]);

  const contextValue = useMemo<ComponentPluginContextValue>(
    () => ({
      pluginName,
      title,
      description,
      params,
      configOptions,
      getPluginConfig: () => usedConfig || {},
      updatePluginConfig,
      setMetadata,
      setConfigOptions,
      setGraphQLQueries,
      graphQLQueries,
      isTemplate,
      templateParams,
      isPrinterFriendly,
    }),
    [
      pluginName,
      title,
      description,
      params,
      configOptions,
      updatePluginConfig,
      graphQLQueries,
      isTemplate,
      templateParams,
      isPrinterFriendly,
      usedConfig,
    ],
  );

  const keystoneContext = useMemo<ComponentPluginContextValue>(
    () => ({
      pluginName: isAdvancedQuery ? ComponentPluginName.QueryKeystoneTile : pluginName,
      title,
      description,
      params,
      configOptions,
      getPluginConfig: () => usedConfig || {},
      updatePluginConfig,
      setGraphQLQueries,
      graphQLQueries,
      isPrinterFriendly,
      isTemplate,
      templateParams,
      onToggleLock,
      menuOpenRef,
      setLocalConfig,
      setMetadata,
    }),
    [
      isAdvancedQuery,
      pluginName,
      title,
      description,
      params,
      configOptions,
      updatePluginConfig,
      graphQLQueries,
      isPrinterFriendly,
      isTemplate,
      templateParams,
      onToggleLock,
      usedConfig,
    ],
  );

  const toggleKeystone = useCallback(() => {
    updatePluginConfig({
      ...usedConfig,
      keystone: { ...usedConfig?.keystone, expanded: !usedConfig?.keystone?.expanded },
    });
  }, [usedConfig, updatePluginConfig]);

  /* ***********************KEYBOARD SHORTCUTS****************************** */
  useEffect(() => {
    function handleHotKey(e: any) {
      if (e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA') return;
      if (e.target.isContentEditable) return;
      const key = e.key;
      if (key === ' ') {
        // toggle keystone
        e.preventDefault();
        if (isFocused) {
          toggleKeystone();
        }
      }
    }
    if (!isAdvancedSearch()) {
      document.addEventListener('keydown', handleHotKey);
    }
    return () => {
      if (!isAdvancedSearch()) {
        document.removeEventListener('keydown', handleHotKey);
      }
    };
  }, [onTileFocus, isFocused, toggleKeystone]);

  const { historyEnabled } = usedConfig?.history || {};

  const goToSourcePage = useCallback(() => {
    if (pluginName.startsWith('advancedquery/')) {
      navigateToQueryResults(getConfigAdvancedQuery(params));
    } else {
      const assetType = guessAssetType(params.assetValue);
      const link = assetType
        ? `/assetreport?${assetType}=${params.assetValue}`
        : `/assets?org=${params.assetValue}`;
      history.push(link);
    }
  }, [pluginName, params, history]);

  // Set view options
  useEffect(() => {
    const newOptions = [];
    const isSourcePage = isAssetReport || isAdvancedSearch();
    const hasSourcePage = !isNonDataTile && !isTemplate;
    const canGoToSourcePage = !isSourcePage && hasSourcePage && !isSharedDatasetTile;

    newOptions.push({
      label: 'Copy',
      icon: <CopyIcon />,
      expandComponent: (
        <AddToInvestigationKebabMenu
          title={title}
          description={description}
          pluginName={pluginName}
          params={{ ...params, config: usedConfig }}
        />
      ),
    });

    canGoToSourcePage &&
      newOptions.push({
        label: 'View Source Page',
        icon: <OpenTileIcon />,
        callback: goToSourcePage,
      });

    !isTemplate &&
      isShareable &&
      newOptions.push({
        label: 'Share',
        icon: <ShareIcon />,
        expandComponent: <ShareLinkKebabMenu data={{ section }} backButton />,
      });

    !isNonDataTile &&
      !isTemplate &&
      newOptions.push({
        label: 'Download',
        icon: <DownloadIcon />,
        expandComponent: (
          <DataExportForm
            pluginContexts={{ [section.id]: contextValue }}
            defaultFilename={getDefaultSectionTitle(title, params).replace(':', '')}
            userProfile={userProfile}
          />
        ),
      });
    !isNonDataTile &&
      !isTemplate &&
      isAuthorized(keycloak, ['platform-api']) &&
      newOptions.push({
        label: 'API Query',
        icon: <APIIcon />,
        callback: () => {
          const queries = { ...contextValue.graphQLQueries };
          const firstQuery = Object.values(queries ?? {})[0];
          if (firstQuery) {
            if (processedParams?.config?.tab && TAB_QUERIES_BY_PLUGIN[pluginName]) {
              apiPlaygroundVar(
                queries[TAB_QUERIES_BY_PLUGIN[pluginName][processedParams?.config?.tab]],
              );
            } else {
              // delete any supplementary queries from the plugin context
              for (const key of Object.keys(queries)) {
                if (NON_PLAYGROUND_QUERIES.includes(key)) {
                  delete queries[key];
                }
              }
              apiPlaygroundVar(Object.values(queries)[0]);
            }
          }
          history.push('/gql');
        },
      });

    const newKebabOptions = {
      ...(newOptions.length > 0 && { Actions: newOptions }),
    };

    setKebabOptions((current) => {
      if (!isEqual(current, newKebabOptions)) {
        return newKebabOptions;
      }
      return current;
    });
  }, [
    pluginName,
    title,
    historyEnabled,
    isSharedDatasetTile,
    params,
    processedParams,
    viewOnly,
    isMarkdownTile,
    goToSourcePage,
    isTemplate,
    description,
    isSharedInvestigation,
    isAssetReport,
    usedConfig,
    contextValue,
    userProfile,
    isShareable,
    section,
    isNonDataTile,
    history,
    keycloak,
  ]);

  // Pattern discovery tooltip enabled even though there's no metadata allowed
  const canViewPatternTooltip = useMemo(() => {
    return !messages && params?.advancedQuery?.resolution === 'ips' && tooltip;
  }, [messages, params?.advancedQuery?.resolution, tooltip]);

  const isEditMode = useMemo(() => {
    return isFocused && !isViewOnly();
  }, [isFocused]);

  // Reset tile info visibility when info icon isn't visible
  useEffect(() => {
    if (!canViewMetadata || !canViewPatternTooltip) {
      setTileInfoVisible(false);
    }
  }, [canViewMetadata, canViewPatternTooltip]);

  const displayKeystoneSection =
    !isPrimitiveTile && (keystoneExpanded || description || isEditMode);

  const focusedSectionClass = isFocused ? 'ib-section-focused' : '';
  const linkable =
    linkableComponentPlugins.includes(pluginName) &&
    linkableResolutions.includes(getConfigAdvancedQuery(params)?.resolution) &&
    !messages;

  return (
    <div
      id={anchorLinkId}
      data-tile-id={section.id}
      className={`app-anchor ${infoMode ? 'ib-section-info-mode' : 'ib-section'} ${focusedSectionClass} uuid-${params.uuid}`}
      style={{ marginBottom: linkable && keystoneExpanded ? '48px' : '24px' }}
    >
      <div style={{ width: '100%' }}>
        {!isAdvancedSearch() && !infoMode && (
          <div
            style={isPrimitiveTile ? { top: '0px', height: '100%' } : undefined}
            className="focused-tile-gutter"
            onClick={() => {
              onTileFocus(section);
            }}
          />
        )}
        {!isPrimitiveTile && !infoMode && (
          <div className="uk-display-inline-block" style={{ width: '100%' }}>
            <div
              className={`uk-flex expandable-shift ${isEditMode ? 'uk-flex-middle' : 'uk-flex-top'}`}
              style={{ left: '-55px' }}
            >
              <button
                className={`app-expand-button hide-in-pdf uk-margin-small-bottom ${isEditMode ? 'uk-margin-medium-top' : ''}`}
                style={{ zIndex: isMarkdownTile ? 0 : 12 }}
                onClick={toggleKeystone}
              >
                {isMarkdownTile ? null : keystoneExpanded ? <ExpandClose /> : <ExpandOpen />}
              </button>
              <Kebab
                options={kebabOptions}
                includeTitle={true}
                editMode={isEditMode}
                className={`investigation-button ib-section-header uk-margin-small-bottom ${isEditMode ? 'uk-flex-middle uk-margin-medium-top' : 'uk-flex-top'}`}
                data={{ title, section, id: kebabID }}
              />
              {(canViewMetadata || canViewPatternTooltip) &&
                !isEditMode &&
                !isMarkdownTile &&
                !isTemplate && (
                  <div className="app-tile-info-button uk-margin-small-bottom uk-margin-small-top">
                    <InfoFilled
                      height={14}
                      width={14}
                      onClick={() => setTileInfoVisible((current) => !current)}
                    />
                  </div>
                )}
            </div>
            {tileInfoVisible && (
              <div className="app-kebab-info uk-flex app-tile-metadata">
                <div className="linked-tile-connector">
                  <EndCap className={`${!description ? 'markdown-endcap' : 'terminal-endcap'}`} />
                </div>
                <div className="uk-flex uk-flex-column uk-margin-medium-bottom">
                  {canViewMetadata && metadataAttributes && (
                    <div className={`${tooltip ? 'uk-margin-medium-bottom' : ''}`}>
                      <AttributeTable attributes={metadataAttributes} />
                    </div>
                  )}
                  {tooltip}
                </div>
              </div>
            )}
            <div className="uk-flex" hidden={!displayKeystoneSection}>
              <div
                className={`linked-tile-connector hide-in-pdf ${!displayKeystoneSection ? 'uk-invisible' : ''}`}
              >
                {isMarkdownTile && <EndCap className="markdown-endcap" />}
              </div>
              <div
                className={`ib-section-keystone ${tileInfoVisible && !description ? 'uk-margin-medium-top' : ''} ${isMarkdownTile ? 'pdf-dimension-wrapper' : ''}`}
              >
                <ComponentPluginContext.Provider value={keystoneContext}>
                  <div className={isEditMode ? 'uk-margin-medium-top' : ''}>
                    {isEditMode ? (
                      <DescriptionEditor id={section.id} />
                    ) : (
                      <ViewHeader section={section} />
                    )}
                  </div>
                  <div
                    className="uk-margin-medium-bottom"
                    style={{
                      display:
                        keystoneExpanded && !isNonDataTile && !infoMode && Keystone
                          ? undefined
                          : 'none',
                    }}
                  >
                    <Keystone
                      params={params}
                      setRenderedQuery={setRenderedQuery}
                      renderedQuery={renderedQuery}
                    />
                  </div>
                </ComponentPluginContext.Provider>
              </div>
            </div>
          </div>
        )}
        {!isMarkdownTile && (
          <div id="component-plugin" style={{ width: '100%', breakInside: 'avoid' }}>
            <div className="uk-flex">
              {!isPrimitiveTile && !infoMode && (
                <div className="uk-flex linked-tile-connector hide-in-pdf">
                  {displayKeystoneSection && <EndCap className="initial-endcap" />}
                  <EndCap
                    style={{
                      transform: `translate(${displayKeystoneSection ? '-14px' : '-5px'}, 1px)`,
                    }}
                    className="terminal-endcap"
                  />
                </div>
              )}
              <div
                className={`uk-display-block pdf-dimension-wrapper uk-margin-medium-top uk-margin-medium-bottom ${isPrinterFriendly && WIDE_PRINT_COMPONENTS.includes(pluginName) ? 'printer-friendly-wide-tile-wrapper' : 'app-width-full'}`}
                style={{ marginTop: `${!title ? '-8px' : '0'}}` }}
              >
                <ComponentPluginContext.Provider value={contextValue}>
                  <Suspense fallback={<Spinner ratio={2} position="center" />}>
                    {Component &&
                      (hasUnfilledTemplateParams && !isNonDataTile ? (
                        <Banner
                          style={{ width: '576px', marginBottom: '0px', boxSizing: 'border-box' }}
                          title={'View Placeholder'}
                          alertType={ALERT_TYPES.placeholder}
                          noClose={true}
                          message={
                            activeTemplateParams.length === 0
                              ? 'This tile has not been assigned a value.'
                              : 'This tile cannot be displayed as certain required inputs have not been defined.'
                          }
                        />
                      ) : messages ? (
                        <Banner
                          style={{ width: '576px', marginBottom: '0px', boxSizing: 'border-box' }}
                          title={'Query Alert'}
                          alertType={ALERT_TYPES.error}
                          message={messages[0]}
                        />
                      ) : (
                        <Component params={processedParams} title={title} />
                      ))}
                  </Suspense>
                </ComponentPluginContext.Provider>
              </div>
            </div>
          </div>
        )}
        {linkable && (
          <div
            className="uk-flex"
            style={{ height: outputParam ? 'unset' : 0 }}
            hidden={!displayKeystoneSection}
          >
            <div
              className={`linked-tile-connector hide-in-pdf ${!displayKeystoneSection || !outputParam ? 'uk-invisible' : ''}`}
            >
              <EndCap style={{ transform: 'translate(-5px, 1px)' }} className="terminal-endcap" />
            </div>
            <div className={`ib-section-keystone ${!outputParam ? 'uk-margin-remove' : ''}`}>
              <ComponentPluginContext.Provider value={keystoneContext}>
                <div style={{ display: keystoneExpanded && OutputTile ? undefined : 'none' }}>
                  <OutputTile params={params} renderedQuery={renderedQuery} />
                </div>
              </ComponentPluginContext.Provider>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
