import React, { useState, Fragment, useRef } from 'react';
import Icicle from 'components/viz/Icicle';
import { debounce } from 'lodash';
import { DEBOUNCE_TIMEOUT } from 'utils';

export function PatternDiscovery({ patternData }: { patternData: any }) {
  return <Fragment>{patternData?.tree && <VizSection patternData={patternData} />}</Fragment>;
}

function VizSection({ patternData }: { patternData: any }) {
  const [ipFilter, setIpFilter] = useState('');

  const debounceFilter = useRef(
    debounce((nextFilter: string) => setIpFilter(nextFilter), DEBOUNCE_TIMEOUT),
  ).current;
  return (
    <div className="uk-margin-medium-bottom uk-margin-medium-top">
      <div className="uk-margin-small-bottom uk-margin-top-remove">
        <div className="uk-search uk-search-default uk-margin-medium-left ib-section-width-4 hide-in-pdf">
          <form onSubmit={(e) => e.preventDefault()} onReset={() => setIpFilter('')}>
            {ipFilter !== '' && (
              <button
                type="reset"
                className="uk-form-icon uk-form-icon-flip"
                uk-icon="icon: close"
              />
            )}
            <input
              className="uk-search-input"
              type="search"
              placeholder="Search for an IP address in the tree"
              onInput={(e) => debounceFilter(e.currentTarget.value)}
            />
          </form>
        </div>
      </div>
      <Icicle ipFilter={ipFilter} patternData={patternData} />
    </div>
  );
}
