import React, { Fragment } from 'react';
import { CardProps, cellRouterId, AssetLink, MPLSIcon } from 'components';
import { abbreviatedCount, formatGeoLabel, isViewOnlyNonTemplate } from 'utils';
import { ReactComponent as LocationIcon } from 'svg/experimental/location-filled.svg';
import { ReactComponent as RouterIcon } from 'svg/experimental/router.svg';
import { ReactComponent as Dot } from 'svg/experimental/dot.svg';
import { ReactComponent as Unchecked } from 'svg/selection/checkbox-unchecked.svg';
import { ReactComponent as Checked } from 'svg/selection/checkbox-checked.svg';

const iconProps = { className: 'property-svg' };

export function RouterCard({ card, selected, onSelect }: CardProps) {
  const asn = card.asn?.hasOwnProperty('asn') ? card.asn.asn : card.asn;
  const routerId = cellRouterId(card.routerId);
  const orgName = card.asn?.orgName ?? card.orgName ?? 'Ownership Pending';
  const aliases = abbreviatedCount(card.ipCount);
  const queries = abbreviatedCount(card.responsiveIpCount);
  const location = formatGeoLabel(card.geo);
  const cardSelected = selected?.includes(card.routerId) ?? false;
  const viewOnly = isViewOnlyNonTemplate();
  const os = card.vendor?.os ?? undefined;
  const osVersion = card.vendor?.osVersion ?? undefined;
  const mplsTypes = card.behaviors?.find((behaviorObj) => behaviorObj.type === 'mpls')?.names;

  return (
    <div
      className={`card router ${cardSelected ? 'selected' : ''} ${viewOnly ? 'disabled' : ''}`}
      onClick={() => onSelect(card)}
    >
      <div className="uk-flex">
        {cardSelected ? <Checked className="checkbox" /> : <Unchecked className="checkbox" />}
        <div className="content-panel">
          <div className="title">
            <AssetLink linkValue={routerId} value={routerId} />
            <div className="card-location uk-flex uk-margin-auto-left uk-width-auto">
              <LocationIcon className="uk-margin-small-right" />
              <div>{location}</div>
            </div>
          </div>
          <div className="title">
            <AssetLink linkValue={asn} value={`AS${asn}`} />
            <div className="uk-margin-small-left">{orgName}</div>
          </div>
          <div className="uk-flex uk-margin-small-top">
            <div>
              <div className="property-title">Aliases</div>
              <div className="property uk-flex">
                <RouterIcon className="property-svg" />
                <div>{aliases}</div>
              </div>
            </div>
            <div className="dot-spacer">
              <Dot />
            </div>
            <div>
              <div className="property-title">Queried</div>
              <div className="property uk-flex">
                <RouterIcon className="property-svg" />
                <div>{queries}</div>
              </div>
            </div>
            {card.vendor?.name && (
              <Fragment>
                <div className="dot-spacer">
                  <Dot />
                </div>
                <div>
                  <div className="property-title">Vendor</div>
                  <div className="property uk-flex">
                    <div title={card.vendor?.name}>{card.vendor?.name}</div>
                  </div>
                </div>
              </Fragment>
            )}
            {os && (
              <Fragment>
                <div className="dot-spacer">
                  <Dot />
                </div>
                <div>
                  <div className="property-title">Software</div>
                  <div className="property uk-flex">
                    <div title={os}>{os}</div>
                  </div>
                </div>
                {osVersion && (
                  <Fragment>
                    <div className="dot-spacer">
                      <Dot />
                    </div>
                    <div>
                      <div className="property-title">Version</div>
                      <div className="property uk-flex">
                        <div title={osVersion}>{osVersion}</div>
                      </div>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
            {mplsTypes && (
              <Fragment>
                <div className="dot-spacer">
                  <Dot />
                </div>
                <div>
                  <div className="property-title">MPLS</div>
                  <div className="property uk-flex">
                    <MPLSIcon types={mplsTypes} iconProps={iconProps} />
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
