import { useEffect, useCallback } from 'react';
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client';
import { asnOrgNamesVar } from 'utils';

const orgNamesQuery = gql`
  query ASData($asnSet: [Int]!) {
    asData(asnSet: $asnSet) {
      asn
      orgName
    }
  }
`;

export function useAsnOrgNames(): Record<string, any> {
  const asnOrgNames = useReactiveVar(asnOrgNamesVar);
  const [fetchOrgNames, { data }] = useLazyQuery(orgNamesQuery);
  const addAsns = useCallback(
    (asns: number[]) => {
      const asnSet = new Set<number>(asns.filter((asn: number) => !asnOrgNames[asn]));
      if (asnSet.size > 0) {
        fetchOrgNames({ variables: { asnSet: Array.from(asnSet) } });
      }
    },
    [fetchOrgNames, asnOrgNames],
  );

  useEffect(() => {
    if (data) {
      const newOrgNames: Record<number, string> = {};
      data.asData.forEach((asset: any) => (newOrgNames[asset.asn] = asset.orgName));
      asnOrgNamesVar({ ...asnOrgNamesVar(), ...newOrgNames });
    }
  }, [data]);

  return { asnOrgNames, addAsns };
}
