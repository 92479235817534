import {
  QueryFilterComparator,
  QueryValueOption,
  TemplateParam,
  UserDatasets,
  userDatasetsVar,
} from 'model';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  findLargestIdNumber,
  getAssetOptions,
  getAssetOptionsLoader,
  getClauseFromAssetType,
  getDefaultAssetTypeLabel,
  getFilterClauseOption,
  getTemplateAssetTypeFromId,
  getTemplateParamId,
  getUserDatasetsOptions,
  isViewOnly,
  normalizeFilterClauseLabel,
  normalizeFilterClauseValue,
  propagateTemplateVarUpdate,
  renameTemplateParam,
  updateParams,
} from 'utils';
import { cloneDeep } from 'lodash';
import { ReactComponent as DeleteIcon } from 'svg/actions/x-default.svg';
import Select, { StylesConfig } from 'react-select';
import { reactSelectDropdownStyle, reactSelectSearchStyle } from 'components/reactSelectTheme';
import {
  ClearIndicator,
  DropdownIndicator,
  TemplateMultiValue,
  TemplateParameterNameOption,
  TemplateParameterNameSidebarSingleValue,
  TemplateParameterNameSingleValue,
  TemplateParameterValueContainer,
} from 'components/reactselect';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useModelVar } from 'hooks';
import { SidebarContext } from 'appContexts';

const templateReactSelectComponents = {
  SingleValue: TemplateParameterNameSidebarSingleValue,
  ValueContainer: TemplateParameterValueContainer,
  DropdownIndicator,
  ClearIndicator,
  MultiValue: TemplateMultiValue,
};

export function TemplateParamSection({
  param,
  index,
  assetTypeOptions,
}: { param: TemplateParam; index: number; assetTypeOptions: any[] }) {
  const [fixedValueOptions, setFixedValueOptions] = useState<QueryValueOption[] | undefined>();
  const { investigation, setLocalTemplateParams, localTemplateParams } = useContext(SidebarContext);
  const id = useMemo(() => getTemplateParamId(param), [param]);
  const assetType = getTemplateAssetTypeFromId(getTemplateParamId(param));
  const loadValueOptions = getAssetOptionsLoader(assetType, param.clauseType);
  const userDatasets = useModelVar<UserDatasets>(userDatasetsVar);
  const valueOptions = id?.includes('dataset')
    ? getUserDatasetsOptions(userDatasets, true, `$${id}`)
    : getAssetOptions(assetType, param.clauseType);

  const templateParams = useMemo(() => {
    if (!investigation) return undefined;
    return localTemplateParams ?? investigation.templateParams;
  }, [investigation, localTemplateParams]);

  const creatableRef = useRef<any>();

  const isMulti = useMemo(() => {
    return id?.includes('-set');
  }, [id]);
  let clauseOption = useMemo(
    () => (param.clauseType ? getFilterClauseOption(param.clauseType) : undefined),
    [param.clauseType],
  );
  const multiValue = useMemo(() => {
    if (!isMulti) return undefined;
    return param?.value?.map((value, i) => ({
      value,
      label: param?.label ? param.label[i] : value,
    }));
  }, [param, isMulti]);

  const selectValue = useMemo(() => {
    return isMulti
      ? multiValue
      : !!param.value
        ? { value: param.value, label: param.label ?? param.value }
        : null;
  }, [isMulti, multiValue, param]);

  const deleteParam = useCallback(
    (index: number) => {
      if (!investigation) return;
      if (!templateParams) return;
      const templateParam = templateParams[index];
      if (templateParam) {
        let update = { value: undefined, label: undefined };
        investigation.sections = propagateTemplateVarUpdate(
          investigation.sections,
          templateParam,
          update,
        );
        templateParams.splice(index, 1);
      }
      updateParams(setLocalTemplateParams, templateParams, investigation);
    },
    [templateParams, investigation, setLocalTemplateParams],
  );

  async function onUpdateValue(update: any) {
    const isArray = Array.isArray(update);
    let value = isArray ? update.map((item: any) => item.value) : update?.value;
    let label = isArray ? update.map((item: any) => item.label) : update?.label;
    if (value != null) {
      if (getTemplateAssetTypeFromId(id) === 'dataset') {
        clauseOption = getFilterClauseOption('DnsHost:IP');
      }
      if (clauseOption) {
        value = normalizeFilterClauseValue(clauseOption, value, true);
        if (isArray && value.length > label.length) {
          label[label.length - 1] = undefined;
          value = Array.from(new Set(value));
        }
      }
      label = await normalizeFilterClauseLabel(id, value, label, clauseOption);
    }
    if (!value) {
      value = undefined;
    } else if (isMulti && !Array.isArray(value) && !Array.isArray(label)) {
      value = [value];
      label = [label];
    } else if (!isMulti && Array.isArray(value) && Array.isArray(label)) {
      value = (value as string[])[0];
      label = (label as string[])[0];
    }
    // safe use of clone deep in a one-time callback
    const newVars = cloneDeep(templateParams);
    if (newVars && newVars[index]) {
      newVars[index] = { ...newVars[index], value, label };
    }
    if (!investigation.removeTemplateParamValues && !isViewOnly()) {
      updateParams(setLocalTemplateParams, newVars, investigation);
    } else {
      setLocalTemplateParams && setLocalTemplateParams(newVars);
    }
  }

  function onChangeParamAssetType(update: any, oldIndex: number) {
    const newAssetType = update.value;
    if (
      !templateParams ||
      !investigation ||
      newAssetType === getTemplateAssetTypeFromId(getTemplateParamId(templateParams[oldIndex]))
    )
      return;
    const newNumber = findLargestIdNumber(templateParams, newAssetType);
    templateParams[oldIndex] = {
      id: `${newAssetType}${newNumber}`,
      name: `${getDefaultAssetTypeLabel(newAssetType)}${newNumber}`,
      clauseType: getClauseFromAssetType(investigation, newAssetType),
    };
    updateParams(setLocalTemplateParams, templateParams, investigation);
  }

  const handleFocus = (element: any) => {
    if (param) {
      creatableRef.current.state.inputValue = (param as any).name;
    }
  };

  // Load any fixed options value options once; rerenders on clauseoption change
  // Done to prevent the use of defaultOptions prop in Select, since it does not
  // allow filtering with typing; AsyncSelect is unnecessary
  useEffect(() => {
    async function loadFixed() {
      const options = await clauseOption.loadFixedValueOptions('');
      setFixedValueOptions(options);
    }
    if (clauseOption?.loadFixedValueOptions) {
      loadFixed();
    }
  }, [clauseOption]);

  function formatOptionLabel({ label }) {
    return (
      <div className="keystone-input-value" title={label}>
        {label}
      </div>
    );
  }

  const hasValueOptions = valueOptions && valueOptions.length > 0;
  const useFixedOptionsAll =
    !clauseOption?.loadValueOptions &&
    !clauseOption?.comparatorOptions.includes(QueryFilterComparator.IsInDataset) &&
    !hasValueOptions;

  return (
    <div className="template-param-section">
      {!isViewOnly() && (
        <button className="uk-float-right uk-padding-remove" onClick={() => deleteParam(index)}>
          <DeleteIcon width={20} height={20} />
        </button>
      )}
      <div className={'uk-margin-large-top'}>
        <label className="input-label">type</label>
        <Select
          options={assetTypeOptions}
          value={{ value: assetType, label: getDefaultAssetTypeLabel(assetType) }}
          placeholder="Select"
          isDisabled={isViewOnly()}
          onChange={(val) => {
            onChangeParamAssetType(val, index);
          }}
          styles={reactSelectDropdownStyle() as StylesConfig}
          blurInputOnSelect={true}
          components={{ DropdownIndicator }}
          menuPortalTarget={document.body}
          menuPlacement="auto"
        />
        <label className="input-label">name</label>
        <CreatableSelect
          {...{ templateParams, hideParamValue: true }}
          ref={(ref) => {
            creatableRef.current = ref;
          }}
          isDisabled={isViewOnly()}
          value={{ value: `$${id}`, label: param.name ?? '' }}
          placeholder="Variable Name"
          onChange={(val: any) => {
            const retVal = {
              investigation,
              name: val.label,
              assetType,
              index,
              templateParams,
              setLocalTemplateParams,
            };
            renameTemplateParam(retVal);
          }}
          onFocus={handleFocus}
          styles={reactSelectSearchStyle() as StylesConfig}
          components={{
            Option: TemplateParameterNameOption,
            SingleValue: TemplateParameterNameSingleValue,
            ValueContainer: TemplateParameterValueContainer,
            DropdownIndicator,
          }}
          blurInputOnSelect={true}
          menuPortalTarget={document.body}
          menuPlacement="auto"
        />
      </div>
      <div data-testid="template-param-value">
        <label className="input-label">value</label>
        {clauseOption?.loadFixedValueOptions && useFixedOptionsAll ? (
          <Select
            options={fixedValueOptions ?? []}
            value={selectValue}
            isMulti={isMulti}
            placeholder="Select"
            onChange={onUpdateValue}
            styles={reactSelectDropdownStyle() as StylesConfig}
            components={templateReactSelectComponents}
            blurInputOnSelect={true}
            isClearable={true}
            menuPortalTarget={document.body}
            menuPlacement="auto"
          />
        ) : loadValueOptions ? (
          <AsyncCreatableSelect
            loadOptions={loadValueOptions}
            value={selectValue}
            isMulti={isMulti}
            placeholder={`Enter ${assetType}`}
            noOptionsMessage={() => `Search to Load Options`}
            onChange={onUpdateValue}
            styles={reactSelectSearchStyle() as StylesConfig}
            blurInputOnSelect={true}
            components={templateReactSelectComponents}
            formatOptionLabel={isMulti ? (formatOptionLabel as any) : null}
            isClearable={true}
            menuPortalTarget={document.body}
            menuPlacement="auto"
          />
        ) : valueOptions?.length && valueOptions?.length > 0 ? (
          <Select
            options={valueOptions}
            value={selectValue}
            isMulti={isMulti}
            placeholder="Select"
            onChange={onUpdateValue}
            styles={reactSelectDropdownStyle() as StylesConfig}
            components={templateReactSelectComponents}
            blurInputOnSelect={true}
            formatOptionLabel={isMulti ? (formatOptionLabel as any) : null}
            isClearable={true}
            menuPortalTarget={document.body}
            menuPlacement="auto"
          />
        ) : (
          <CreatableSelect
            value={selectValue}
            isMulti={isMulti}
            placeholder={`Enter ${assetType}`}
            noOptionsMessage={() => `No options`}
            onChange={onUpdateValue}
            isClearable={true}
            formatOptionLabel={isMulti ? (formatOptionLabel as any) : null}
            styles={reactSelectSearchStyle() as StylesConfig}
            components={templateReactSelectComponents}
            blurInputOnSelect={true}
            menuPortalTarget={document.body}
            menuPlacement="auto"
          />
        )}
      </div>
    </div>
  );
}
