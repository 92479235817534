import React from 'react';

type SpinnerProps = {
  ratio?: number;
  position?: string;
  light?: boolean;
  style?: Record<any, any>;
};

function Spinner({ ratio, position, light, style }: SpinnerProps = {}) {
  const options = `ratio: ${ratio || 1}`;
  const positionClass = position ? `uk-position-${position}` : '';
  const lightClass = light ? 'uk-light' : '';
  const classes = [lightClass, positionClass, 'uk-padding-small', 'app-spinner'].join(' ');
  return <span style={style} className={classes} data-uk-spinner={options} />;
}

export default Spinner;
