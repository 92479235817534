import React, { Fragment } from 'react';
import { ReactComponent as Dot } from 'svg/misc/dot.svg';

export function LoadingDots() {
  return (
    <Fragment>
      <Dot className="mosaic-action-load1" />
      <Dot className="mosaic-action-load2" />
      <Dot className="mosaic-action-load3" />
    </Fragment>
  );
}
