import React, { Fragment, useCallback, useContext, useMemo } from 'react';
import { ComponentPluginContext } from 'appContexts';
import { ReactComponent as AddIcon } from 'svg/actions/plus-outline.svg';
import { Spinner } from 'components';
import { PortsFilter } from './PortsFilter';

export default function PortsFilters() {
  const { getPluginConfig, updatePluginConfig, configOptions } = useContext(ComponentPluginContext);

  const { filterClauses, loading } = configOptions || {};
  const filters = useMemo(() => getPluginConfig()?.filters ?? [], [getPluginConfig]);

  const availableFilterClauses = useMemo(() => {
    const activeFilterTypes = filters?.map((filter: any) => filter.type);
    return filterClauses?.filter(
      (clauseOption: any) => !activeFilterTypes.includes(clauseOption.type),
    );
  }, [filterClauses, filters]);

  const addPortsFilter = useCallback(() => {
    const filterClauseOption = availableFilterClauses[0];
    const newFilters = [
      ...filters,
      {
        type: filterClauseOption.type,
        comparator: filterClauseOption?.comparatorOptions[0],
        value: undefined,
      },
    ];
    const newConfig = { ...getPluginConfig(), filters: newFilters };

    updatePluginConfig(newConfig);
  }, [availableFilterClauses, filters, getPluginConfig, updatePluginConfig]);

  const modifyPortsFilter = useCallback(
    (newFilter: any, index: number) => {
      const newFilters = [...filters];
      newFilters[index] = newFilter;
      const newConfig = { ...getPluginConfig(), filters: newFilters };

      updatePluginConfig(newConfig);
    },
    [filters, getPluginConfig, updatePluginConfig],
  );

  const deletePortsFilter = useCallback(
    (index: number) => {
      const newFilters = [...filters];
      newFilters.splice(index, 1);
      const newConfig = { ...getPluginConfig(), filters: newFilters };

      updatePluginConfig(newConfig);
    },
    [filters, getPluginConfig, updatePluginConfig],
  );

  return (
    <div className="uk-flex uk-flex-column">
      {!filterClauses ? (
        <Spinner />
      ) : (
        <Fragment>
          {filters.map((filter: any, index: number) => (
            <PortsFilter
              key={filter.type}
              filter={filter}
              availableFilterClauses={availableFilterClauses}
              filterClauses={filterClauses}
              modifyFilter={modifyPortsFilter}
              deleteFilter={deletePortsFilter}
              loading={loading}
              index={index}
            />
          ))}
          {availableFilterClauses?.length > 0 && (
            <div className="hide-in-pdf">
              <button
                className="uk-flex-inline add-filter-button uk-padding-remove-left"
                data-testid="ports-filter-button"
                onClick={addPortsFilter}
              >
                <AddIcon />
                <div className="uk-margin-small-left">Add</div>
              </button>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}
