import { History } from 'history';
import { ALERT_TYPES, UserInvestigationSection, UserNotification } from 'model';
import { addAllHeadingReferencesInDescription } from './mosaicUtils';

type NavigateInput = {
  url?: URL;
  pathname?: string;
  pathParams?: string[];
  searchParams?: Record<string, string> | URLSearchParams;
  state?: Record<string, any>;
  replace?: boolean;
};

let _history: History;

export function setHistory(history: History) {
  _history = history;
}

export function getAnchorLinkId(text: string, index?: number) {
  if (index) {
    return `${text.toLowerCase().replace(/\s/g, '')}-${index}`;
  }
  return text.toLowerCase().replace(/\s/g, '');
}

export function getFullAnchorLinkIds(
  sections: UserInvestigationSection[],
  mainDescription: string,
  index?: number,
) {
  if (index < 0) return;
  let newAnchorLinks = [];
  sections.forEach((section, i) => {
    if (index != null && i > index) {
      return;
    }
    let sameTitleCount = 0;
    const text = section.title;
    // Find all the heading references in the main description
    sameTitleCount = addAllHeadingReferencesInDescription(text, mainDescription, sameTitleCount);
    for (let j = 0; j < i; j++) {
      // stop comparing before we get to the current section
      const compareSection = sections[j];
      if (compareSection.title === section.title) {
        sameTitleCount++;
      }
      sameTitleCount = addAllHeadingReferencesInDescription(
        text,
        compareSection.description,
        sameTitleCount,
      );
    }
    newAnchorLinks.push(`${getAnchorLinkId(section.title, sameTitleCount)}`);
  });
  return index ? newAnchorLinks[index] : newAnchorLinks;
}

export function getHistory(): History {
  return _history;
}

export function getPublicUrl() {
  return process.env.PUBLIC_URL;
}

export function getPathParams() {
  const pathParams = getHistory().location.pathname.split('/') as string[];
  return pathParams.filter((param: string) => param.length > 0).map(decodeURIComponent);
}

export function getSearchParams() {
  const search = window.location.search;
  return new URLSearchParams(search);
}

export function getPluginConfig(pluginName: string): Record<string, any> | undefined {
  const params = getSearchParams().get(pluginName);
  return params ? JSON.parse(decodeURIComponent(atob(params))) : undefined;
}

export function navigate({
  url,
  pathname,
  pathParams,
  searchParams,
  state,
  replace,
}: NavigateInput) {
  if (searchParams && !(searchParams instanceof URLSearchParams)) {
    const searchParamsDict = searchParams;
    const urlSearchParams = new URLSearchParams();
    Object.entries(searchParamsDict).forEach(([key, value]) => urlSearchParams.set(key, value));
    searchParams = urlSearchParams;
  } else if (url) {
    searchParams = url.searchParams;
  }

  if (!pathname) {
    pathname = pathParams
      ? '/' + pathParams.map(encodeURIComponent).join('/')
      : url?.pathname ?? _history?.location.pathname;
  }
  const search = searchParams ? `?${searchParams.toString()}` : '';

  if (replace) {
    window.history.replaceState(null, '', `${window.location.origin}${pathname}${search}`);
  } else {
    _history.push(`${pathname}${search}`, state);
  }
}

export function preventNavigationInterrupt(e: BeforeUnloadEvent) {
  const confirmationMessage = 'dummy message';
  e.returnValue = confirmationMessage;
  return confirmationMessage;
}

export function copyShareLink(shareLink: string, setCopyBanner?: Function) {
  navigator.clipboard.writeText(shareLink);
  const newCopyBanner: UserNotification = {
    title: 'Copied',
    message: 'Link was copied to the clipboard.',
    alertType: ALERT_TYPES.success,
    onClear: () => {
      setCopyBanner(undefined);
    },
  };
  setCopyBanner && setCopyBanner(newCopyBanner);
}

export function isSharedLink() {
  return window.location.pathname.includes('shared');
}

export function isAssetReport() {
  return window.location.pathname.includes('assetreport');
}

export function isViewOnly() {
  return isSharedLink() || isAssetReport();
}

export function isAdvancedSearch() {
  return (
    window.location.pathname.includes('advanced-search') ||
    window.location.pathname.includes('queryresults')
  );
}

export function isTemplate() {
  return window.location.pathname.includes('template');
}
