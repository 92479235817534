import { isEqual, cloneDeep } from 'lodash';

export function humanizeCount(num: number, singleDigitsOnly = false): string {
  const ones = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];
  const tens = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  const numString = num.toString();

  if (num < 0) {
    throw new Error('Negative numbers are not supported.');
  } else if (num === 0) {
    return 'zero';
  } else if (singleDigitsOnly && num > 9) {
    return num.toString();
  }

  //the case of 1 - 20
  if (num < 20) {
    return ones[num];
  }

  if (numString.length === 2) {
    return tens[+numString[0]] + ' ' + ones[+numString[1]];
  }

  //100 and more
  if (numString.length === 3) {
    if (numString[1] === '0' && numString[2] === '0') return ones[+numString[0]] + ' hundred';
    else
      return ones[+numString[0]] + ' hundred and ' + humanizeCount(+(numString[1] + numString[2]));
  }

  if (numString.length === 4) {
    const end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return ones[+numString[0]] + ' thousand';
    if (end < 100) return ones[+numString[0]] + ' thousand and ' + humanizeCount(end);
    return ones[+numString[0]] + ' thousand ' + humanizeCount(end);
  }
  return numString;
}

export function abbreviatedCount(num: number) {
  const absNum = Math.abs(Number(num));
  if (absNum >= 1.0e15) {
    return `>999T`;
  }
  if (absNum >= 1.0e12) {
    return `${num < 0 ? '-' : ''}${(absNum / 1.0e12).toFixed(1)}T`;
  }
  if (absNum >= 1.0e9) {
    return `${num < 0 ? '-' : ''}${(absNum / 1.0e9).toFixed(1)}B`;
  } else if (absNum >= 1.0e6) {
    return `${num < 0 ? '-' : ''}${(absNum / 1.0e6).toFixed(1)}M`;
  } else if (absNum >= 1.0e3) {
    return `${num < 0 ? '-' : ''}${(absNum / 1.0e3).toFixed(1)}k`;
  }
  return roundTo2Decimals(absNum);
}

export function toOridinal(num: number) {
  if (!num) {
    return '';
  }
  var j = num % 10,
    k = num % 100;
  if (j === 1 && k !== 11) {
    return num + 'st';
  }
  if (j === 2 && k !== 12) {
    return num + 'nd';
  }
  if (j === 3 && k !== 13) {
    return num + 'rd';
  }
  return num + 'th';
}

export function humanizePercentage(num: number) {
  return num < 0.01 ? '<1' : Math.floor(num * 100);
}

export function humanizePercentageNoMultiply(num: number) {
  return num < 1 ? '<1' : Math.floor(num);
}

export function humanizeDateTime(date: Date, hideTime?: boolean) {
  const currentDate = new Date();
  const today = [currentDate.getDate(), currentDate.getMonth(), currentDate.getFullYear()];
  const yesterday = cloneDeep(today);
  yesterday[0] = currentDate.getDate() - 1;
  let month: string | number = date.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  let day: string | number = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  let dayStr: string = `${date.getFullYear()}-${month}-${day}`;
  let hour = date.getHours();
  let minute = date.getMinutes().toString();
  let ampm = 'AM';
  if (hour > 12) {
    hour -= 12;
    ampm = 'PM';
  } else if (hour === 0) {
    hour = 12;
    ampm = 'AM';
  } else if (hour === 12) {
    ampm = 'PM';
  }
  if (date.getMinutes() < 10) minute = `0${minute}`;
  let time: string = `${hour}:${minute} ${ampm}`;
  if (isEqual(today, [date.getDate(), date.getMonth(), date.getFullYear()])) {
    dayStr = 'Today';
  } else if (isEqual(yesterday, [date.getDate(), date.getMonth(), date.getFullYear()])) {
    dayStr = 'Yesterday';
  }
  if (!hideTime) {
    dayStr += ', ';
  }
  return `${dayStr}${!hideTime ? time : ''}`;
}

export function normalizeDateLabel(time: string) {
  if (!isNaN(+time)) {
    return new Date(+time).toISOString();
  }
  return time;
}

export function normalizeDateValue(time: string) {
  if (!isNaN(+time)) {
    return `${+time}`;
  }
  let retVal = time;
  if (!time.endsWith('Z')) retVal = retVal.concat('Z');
  const timeFromString = new Date(retVal).getTime();
  if (!isNaN(timeFromString)) return `${timeFromString}`;
  return timeFromString ? 'INVALID DATE' : undefined;
}

const timeScalingFactors: Record<string, number> = {
  ms: 0.001,
  s: 1,
  m: 60,
  h: 3600,
  d: 3600 * 24,
  w: 3600 * 24 * 7,
  M: 3600 * 24 * 7 * 4,
  y: 3600 * 24 * 7 * 52,
};

export function formatTimeIntervalString(interval: string) {
  const { unitString, number } = determineTimeStringUnit(interval);
  return number * timeScalingFactors[unitString];
}

export function transformRelativeTsToAbsolute(ts: number, referenceVal: number) {
  return ts * 1000 + referenceVal;
}

export function transformToRelativeTimestamp(ts: number, referenceVal: number) {
  return (ts - referenceVal) / 1000;
}

function determineTimeStringUnit(interval: string) {
  let unitString;
  let number;
  Object.keys(timeScalingFactors).some((unit) => {
    if (interval.endsWith(unit)) {
      unitString = unit;
      number = +interval.slice(0, interval.length - unit.length);
      return true;
    }
    return false;
  });
  if (!unitString || !number) {
    throw Error(`Histogram received bad time interval: ${interval}`);
  }
  return { unitString, number };
}

export function formatTimeStringFromUnit(timeInSeconds: number, interval: string) {
  let unitString: string = determineTimeStringUnit(interval).unitString;
  const conversionFactor = timeScalingFactors[unitString];
  if (!conversionFactor) throw new Error('Received unsupported time unit');

  let conversion = timeInSeconds / conversionFactor;
  if (unitString === 'ms' && conversion >= 1000) {
    unitString = 's';
    conversion = conversion / 1000 ?? 0;
  }
  return `${abbreviatedCount(conversion)}${unitString}`;
}

export function hexToRgb(hex: string) {
  let bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return r + ',' + g + ',' + b;
}

export function humanizeFileSize(sizeInBytes: number) {
  if (sizeInBytes < 1000) return `${roundTo2Decimals(sizeInBytes)} b`;
  if (sizeInBytes < 1000000) return `${roundTo2Decimals(sizeInBytes / 1000)} kB`;
  if (sizeInBytes < 1000000000) return `${roundTo2Decimals(sizeInBytes / 1000000)} MB`;
  return `${roundTo2Decimals(sizeInBytes / 1000000000)} GB`;
}

export function roundTo2Decimals(num: number) {
  return Math.round(num * 100) / 100;
}

export enum MetadataPeriod {
  Continuous = 'continuous',
  Static = 'static',
  OneWeek = '1w',
  TwoWeeks = '2w',
  OneMonth = '1M',
  NinetyDays = '3M',
}

const indexPeriodMap = {
  behaviors: MetadataPeriod.Continuous,
  certificates: MetadataPeriod.Continuous,
  'country-inbound-flows': MetadataPeriod.OneWeek,
  'country-outbound-flows': MetadataPeriod.OneWeek,
  country_records: MetadataPeriod.Continuous,
  'domain-hosts': MetadataPeriod.Continuous,
  'dns-info': MetadataPeriod.NinetyDays,
  exchanges: MetadataPeriod.Static,
  facilities: MetadataPeriod.Static,
  geo: MetadataPeriod.Continuous,
  org: MetadataPeriod.Continuous,
  origin: MetadataPeriod.OneWeek,
  ports: MetadataPeriod.TwoWeeks,
  rdns: MetadataPeriod.OneMonth,
  'rip-a': MetadataPeriod.OneWeek,
  'rip-p': MetadataPeriod.OneWeek,
  'router-alias': MetadataPeriod.Continuous,
  'rwedges-ipv4': MetadataPeriod.OneWeek,
  whois: MetadataPeriod.OneMonth, // Whois is ~1 month but we just call it 1 month for now
  'attributes-ipsum': MetadataPeriod.Continuous,
  'attributes-maltrail': MetadataPeriod.Continuous,
  'attributes-publicdns': MetadataPeriod.Continuous,
  'attributes-reputation': MetadataPeriod.Continuous,
  'attributes-services': MetadataPeriod.Continuous,
  'attributes-stateownedases': MetadataPeriod.Static,
};

// Function to convert yyyy-mm-dd string to Date object
const parseDate = (dateStr: string) => new Date(dateStr + 'T00:00:00');

// Function to format month as three letter abbreviation
const monthToMMM = (date: Date) => date.toLocaleString('default', { month: 'short' });

// Format date as dd mmm
const formatDDMMM = (date: Date) =>
  `${String(date.getDate()).padStart(2, '0')} ${monthToMMM(date)}`;

export function formatMetadataRange(indexName: string, lastUpdated: string) {
  const period = indexPeriodMap?.[indexName];
  const d2 = parseDate(lastUpdated);
  let formattedRange = `${formatDDMMM(d2)} ${d2.getFullYear()}`;

  if (period !== MetadataPeriod.Continuous && period !== MetadataPeriod.Static && period) {
    let d1;

    // Note we call 1 month -> 30 days. This is not exact with whois but a best effort for now
    // We subtract 1 day to take into account the inclusive date range
    if (period === MetadataPeriod.OneWeek) {
      d1 = new Date(d2.getTime() - 86400 * 6 * 1000);
    } else if (period === MetadataPeriod.TwoWeeks) {
      d1 = new Date(d2.getTime() - 86400 * 13 * 1000);
    } else if (period === MetadataPeriod.NinetyDays) {
      d1 = new Date(d2.getTime() - 86400 * 89 * 1000);
    } else if (period === MetadataPeriod.OneMonth) {
      d1 = new Date(d2.getTime() - 86400 * 29 * 1000);
    }

    if (d1.getFullYear() === d2.getFullYear()) {
      // Case 1: Both dates fall within the same year
      formattedRange = `${formatDDMMM(d1)} - ${formatDDMMM(d2)} ${d1.getFullYear()}`;
    } else {
      // Case 2: Dates span different years
      formattedRange = `${formatDDMMM(d1)} ${d1.getFullYear()} - ${formatDDMMM(d2)} ${d2.getFullYear()}`;
    }
  }

  return [formattedRange, period];
}
