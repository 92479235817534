import React from 'react';

export function TagContainer({ tags, uppercase }: { tags: string[]; uppercase?: boolean }) {
  return (
    <div className="app-tag-container">
      {tags?.map((tag: string) => (
        <div
          className={`app-tag uk-margin-remove-top uk-margin-small-right ${uppercase ? 'uk-text-uppercase' : ''}`}
          key={tag}
        >
          {tag}
        </div>
      ))}
    </div>
  );
}
