import React, { Fragment, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { GalleryContext, KebabContext } from 'appContexts';
import { ExpandableKebabComponent } from '../Kebab';
import { createGenericNotification, acknowledgeNotification, ALERT_TYPES } from 'model';
import { UNPUBLISH_MUTATION } from 'utils';

export function DeleteGalleryMosaicKebabMenu({ data }: ExpandableKebabComponent) {
  const { returnKebabMain, closeKebabDropdown } = useContext(KebabContext);
  const { refetchGallery } = useContext(GalleryContext);
  const [unpublishGalleryMosaic, { data: unpublishData, error: unpublishError }] =
    useMutation(UNPUBLISH_MUTATION);

  const mosaic = data?.value;
  const mosaicTitle = mosaic?.title;
  const publishedKey = mosaic?.sharing?.shared_key;
  const { removedObjectCount } = unpublishData?.unpublishUserData || {};

  // Close Kebab menu on unpublish and handle unpublish errors or failures
  useEffect(() => {
    if (removedObjectCount || unpublishError) {
      if (unpublishError || !removedObjectCount) {
        createGenericNotification('gallery-unpublish-error', {
          alertType: ALERT_TYPES.error,
          message: (
            <Fragment>
              Failed to delete <span className="uk-text-bold uk-text-danger">{mosaicTitle}</span>,
              from the gallery. Please try again.
            </Fragment>
          ),
          title: 'Delete Failed',
          onClear: () => acknowledgeNotification('gallery-unpublish-error'),
        });
      }

      refetchGallery();
      closeKebabDropdown();
    }
  }, [unpublishError, closeKebabDropdown, mosaicTitle, removedObjectCount, refetchGallery]);

  return (
    <div>
      <div className="uk-flex uk-flex-middle uk-margin-large-bottom">
        <button onClick={() => returnKebabMain()} className="tile-menu-back">
          <div uk-icon="icon: chevron-left;" />
          Menu
        </button>
        <span className="uk-text-bold menu-header">Delete Item</span>
      </div>
      <div className="uk-margin-medium-bottom">
        This action will permanently delete
        <span className="uk-text-bold"> {` ${mosaicTitle ?? 'mosaic'}`} </span>
        shared in the Community workspace.
      </div>
      <div className="uk-flex uk-flex-right">
        <button
          className="uk-button uk-button-default"
          onClick={() => unpublishGalleryMosaic({ variables: { publishedKey } })}
        >
          Delete
        </button>
      </div>
    </div>
  );
}
