import localforage from 'localforage';
import { keycloak } from 'keycloak';
import { asyncSleep, withAsyncLock } from 'utils';
import { reactiveVarsBySection, UserDataSection, UserDatasetData } from './userDataTypes';
import { disabledSectionChangeHandlerSet, processUserDataUpdates } from './userDataCommon';

// This key is for storing the last modified time of the locally-cached remote data.
// It's used to keep remote user data and the locally-stored cache in sync.
const CACHE_LAST_MODIFIED = 'cacheLastModified';

export async function getLocalUserData<T = any>(section: UserDataSection) {
  return await localforage.getItem<T>(getLocalStoreSectionKey(section));
}

export async function storeLocalUserData(section: UserDataSection, data: any) {
  await localforage.setItem(getLocalStoreSectionKey(section), data);
  await updateLocalTimestamp();
}

export async function saveUserDatasetData(id: string, data: UserDatasetData) {
  return await localforage.setItem<UserDatasetData>(id, data);
}

export async function getUserDatasetData(id: string) {
  return await localforage.getItem<UserDatasetData>(id);
}

export async function deleteUserDatasetData(id: string) {
  return await localforage.removeItem(id);
}

export function getLocalStoreSectionKey(section: string) {
  const userId = keycloak?.subject;
  return userId ? `${userId}:${section}` : section;
}

export async function getCacheLastModified() {
  return (await localforage.getItem<Date>(getCacheLastModifiedKey())) || undefined;
}

export async function setCacheLastModified(cacheLastModified: Date) {
  await localforage.setItem<Date>(getCacheLastModifiedKey(), cacheLastModified);
}

function getCacheLastModifiedKey() {
  const userId = keycloak?.subject;
  return userId ? `${userId}:${CACHE_LAST_MODIFIED}` : CACHE_LAST_MODIFIED;
}

// ----------------------------------------------------------------------------------------------------
// The following section implements more immediate syncing of user data between browser sessions.

// This key is for storing the latest time that the browser wrote user data to local storage.
// It's used to keep multiple sessions in the same browser in sync more immediately.
const LOCAL_TIMESTAMP = 'localTimestamp';

let myLocalTimestamp: Date | undefined = undefined;

// This function polls the local storage to see if it has been changed by another browser session.
// The polling interval is 1 sec.
export async function monitorLocalUserDataChanges() {
  let latestTimestamp = await getLocalTimestamp();

  if (!latestTimestamp) {
    updateLocalTimestamp();
  } else {
    myLocalTimestamp = latestTimestamp;
  }

  while (true) {
    await asyncSleep(1000);

    latestTimestamp = await getLocalTimestamp();
    if (latestTimestamp > myLocalTimestamp) {
      myLocalTimestamp = latestTimestamp;

      const userDataUpdates: Record<string, any> = {};
      for (const section in reactiveVarsBySection) {
        userDataUpdates[section] = await getLocalUserData(section as UserDataSection);
      }

      for (const [section, newData] of processUserDataUpdates(userDataUpdates)) {
        await withAsyncLock(section as UserDataSection, async () => {
          disabledSectionChangeHandlerSet.add(section);
          try {
            reactiveVarsBySection[section as UserDataSection](newData);
          } finally {
            disabledSectionChangeHandlerSet.delete(section);
          }
        });
      }
    }
  }
}

async function getLocalTimestamp() {
  return (await localforage.getItem<Date>(getLocalTimestampKey())) || undefined;
}

async function updateLocalTimestamp() {
  myLocalTimestamp = new Date();
  await localforage.setItem<Date>(getLocalTimestampKey(), myLocalTimestamp);
}

function getLocalTimestampKey() {
  const userId = keycloak?.subject;
  return userId ? `${userId}:${LOCAL_TIMESTAMP}` : LOCAL_TIMESTAMP;
}
