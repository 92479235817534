import { useEffect, useMemo, useRef, useState } from 'react';
import { DocumentNode } from '@apollo/client';
import { get, isEqual } from 'lodash';
import { getNestedData, NewTableColumn } from 'utils';
import { AdvancedQuery } from 'model';
import { useLazyComponentPluginQuery, useNewTableColumns } from 'hooks';
import { NewPaginationProps } from 'components';

export type PageState = {
  currentPage: number;
  pageCursors: Record<string, string>;
};

type TableDataProps = {
  queryOrAdvancedQuery: DocumentNode | AdvancedQuery;
  dataPath?: string;
  queryVariables: any;
  formatFunction?: any;
  columnOptions: NewTableColumn[];
  columnFilterVars?: any;
  tabId?: string;
  isAssetPageConfig?: boolean;
};

export function useDataTable({
  queryOrAdvancedQuery,
  dataPath,
  queryVariables,
  formatFunction,
  columnOptions,
  columnFilterVars,
  tabId,
  isAssetPageConfig,
}: TableDataProps) {
  const [pages, setPages] = useState<any>([]);
  const [query, setQuery] = useState(queryOrAdvancedQuery);

  const [runQuery, { data, error, loading }] = useLazyComponentPluginQuery(query);

  const filterlessQuery = useMemo(() => ({ ...query, filterClauses: undefined }), [query]);

  const [runColumnFiltersQuery, columnFilterQueryResponse] =
    useLazyComponentPluginQuery(filterlessQuery);

  const isAdvancedQuery = useMemo(() => {
    return !!(query as AdvancedQuery)?.selection;
  }, [query]);

  const { onUpdateColumnConfig, columns, corruptColumns } = useNewTableColumns(
    columnOptions,
    tabId,
    isAssetPageConfig,
  );

  const pageData = useMemo(() => {
    if (!data) return undefined;
    if (isAdvancedQuery) {
      return getNestedData(data);
    } else {
      let retVal = { ...get(data, dataPath ?? '') } ?? {};
      if (formatFunction && retVal.items) {
        retVal.items = formatFunction(retVal.items);
      }
      return retVal;
    }
  }, [isAdvancedQuery, data, dataPath, formatFunction]);

  const [loadTriggered, setLoadTriggered] = useState(false);
  const resetPages = useRef(false);
  const oldData = useRef(pageData);
  const [{ currentPage, pageCursors }, setPageState] = useState<PageState>({
    currentPage: 1,
    pageCursors: {},
  });

  // The runQuery callback is updated when the plugin context is updated, but we do not
  // want to trigger the runQuery useEffects below when the context updates, only when
  // the query or query variables update. So, we'll create references for the functions
  // and update them when the callbacks update.
  const runQueryRef = useRef(runQuery);
  const runColumnsQueryRef = useRef(runColumnFiltersQuery);

  useEffect(() => {
    runQueryRef.current = runQuery;
  }, [runQuery]);

  useEffect(() => {
    runColumnsQueryRef.current = runColumnFiltersQuery;
  }, [runColumnFiltersQuery]);

  useEffect(() => {
    if (query) {
      resetPages.current = true;
      if (queryVariables.size === 0) return;
      runQueryRef.current({ variables: { ...queryVariables, size: queryVariables.size ?? 25 } });
      setPageState({ currentPage: 1, pageCursors: {} });
    }
  }, [query, queryVariables]);

  // This updates our query only if the changes made are not a viewConfig change.
  useEffect(() => {
    const newQuery = queryOrAdvancedQuery;
    setQuery((current) => {
      if (!isEqual({ ...current, viewConfig: undefined }, { ...newQuery, viewConfig: undefined })) {
        return newQuery;
      }
      return current;
    });
  }, [queryOrAdvancedQuery]);

  // Run aggregation query for column filters
  useEffect(() => {
    if (query && columnFilterVars) {
      runColumnsQueryRef.current({ variables: { ...columnFilterVars } });
      setPageState({ currentPage: 1, pageCursors: {} });
    }
  }, [query, columnFilterVars]);

  // Whenever the data, or cursors update, we should maintain our page cursor tracking
  useEffect(() => {
    if (pageData) {
      const nextPage = currentPage + 1;
      if (pageCursors[nextPage] !== pageData.nextCursor) {
        const cursors = { ...pageCursors };
        cursors[nextPage] = pageData.nextCursor;
        setPageState((curr) => ({ ...curr, pageCursors: cursors }));
      }
    }
  }, [pageData, currentPage, pageCursors]);

  // When data updates, reset data as first page, or append to existing data, depending on resetPages ref
  useEffect(() => {
    if (pageData) {
      oldData.current = pageData;
      if (resetPages.current) {
        setPages([pageData]);
      } else {
        setPages((curr: any) => [...curr, pageData]);
      }
    }
  }, [pageData]);

  const paginationProps: NewPaginationProps = useMemo(() => {
    function onLoadPage(pageSize: number, pageNumber: number) {
      setLoadTriggered(true);
      resetPages.current = false;
      const cursor = pageCursors[pageNumber];
      if (cursor || pageNumber === 1) {
        runQuery({ variables: { ...queryVariables, cursor: cursor, size: pageSize } }).then(() =>
          setLoadTriggered(false),
        );
        setPageState({ currentPage: pageNumber, pageCursors });
      }
    }
    return {
      currentPage,
      onLoadRows: onLoadPage,
      totalRows: pageData?.totalCount ?? oldData?.current?.totalCount,
      loading: loadTriggered,
      postFilterStatus: pageData?.postFilterStatus,
    };
  }, [pageData, currentPage, pageCursors, runQuery, queryVariables, loadTriggered]);

  const returnData = useMemo(() => {
    return pages.map((page: any) => page.items);
  }, [pages]);

  return {
    data: returnData,
    columns,
    corruptColumns,
    aggregation: columnFilterQueryResponse,
    onUpdateColumnConfig,
    loading,
    paginationProps,
    error,
  };
}
