import React, { Fragment, useRef } from 'react';
import {
  NewPaginationProps,
  RouterCard,
  SeeMorePagination,
  Spinner,
  TracerouteEdgeCard,
} from 'components';
import { DEFAULT_PAGINATED_RESPONSE_SIZE } from 'hooks';

export type CardsProps = {
  cards: any;
  paginationProps: NewPaginationProps;
  loading: boolean;
  resolution: string;
  selected: string[];
  onSelect: Function;
};

export type CardProps = {
  card: any;
  selected: string[];
  onSelect: Function;
};

const cardResolutionMap = {
  tracerouteEdges: TracerouteEdgeCard,
  routers: RouterCard,
};

export function Cards({
  paginationProps,
  cards,
  loading,
  resolution,
  selected,
  onSelect,
}: CardsProps) {
  const tableRef = useRef<HTMLDivElement>(null);

  async function loadMoreData() {
    if (paginationProps?.onLoadRows) {
      paginationProps.onLoadRows(
        DEFAULT_PAGINATED_RESPONSE_SIZE,
        paginationProps.currentPage ? paginationProps.currentPage + 1 : undefined,
      );
    }
  }

  const CardComponent = cardResolutionMap[resolution];

  return (
    <Fragment>
      <div ref={tableRef} className="uk-flex uk-display-inline-block">
        <div className={`${loading && cards?.length === 0 ? ' loading-table' : ''}`}>
          <div style={{ display: 'inline-block', overflow: 'auto', width: '100%' }}>
            {loading && !paginationProps.loading && (
              <Spinner style={{ left: '20%' }} position={'absolute'} ratio={3} />
            )}
            <div className={`cards-container ${resolution}`}>
              {cards?.map((card, i) => {
                return (
                  <Fragment key={`card-${i}`}>
                    <CardComponent card={card} onSelect={onSelect} selected={selected} />
                  </Fragment>
                );
              })}
            </div>
            <SeeMorePagination
              rowsLoaded={DEFAULT_PAGINATED_RESPONSE_SIZE * paginationProps.currentPage}
              rowCount={paginationProps?.totalRows}
              onLoadRows={loadMoreData}
              rowsToLoad={paginationProps?.rowsToLoad ?? DEFAULT_PAGINATED_RESPONSE_SIZE}
              loading={paginationProps?.loading}
              postFilterStatus={paginationProps?.postFilterStatus}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
