import React from 'react';
import { useFacilityQuery } from 'hooks';
import { ComponentPluginName, Spinner } from 'components';
import { ALERT_TYPES, createGenericNotification, UserInvestigation } from 'model';
import { addSectionToInvestigation } from 'utils';
import Investigation from '../investigations/Investigation';
import { Redirect } from 'react-router-dom';

export default function FacilityReport({ assetValue }: { assetValue: string }) {
  const { asset, loading } = useFacilityQuery(assetValue);
  const assetName = asset?.facility?.name;

  const params = { assetValue, assetName };

  const newInvestigation: UserInvestigation = {
    title: assetName ?? 'Unknown Facility',
    subtitle: asset?.facility?.ownerName ?? '',
    description: '',
    sections: [],
  };
  if (!assetValue.toLowerCase().startsWith('urn:fac:')) {
    createGenericNotification('invalid-asset', {
      alertType: ALERT_TYPES.error,
      message: `${assetValue} is not a valid facility id.`,
      title: 'Invalid Facility',
    });
    return <Redirect to="/" />;
  }

  if (!asset) return loading ? <Spinner /> : <Investigation investigation={newInvestigation} />;

  addSectionToInvestigation(newInvestigation, ComponentPluginName.FacilityAbout, params, 'About');
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.FacilityConnections,
    params,
    'Connections',
  );

  return loading ? <Spinner /> : <Investigation investigation={newInvestigation} />;
}
