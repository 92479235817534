import React from 'react';
import { usePrefixQuery } from 'hooks';
import { ComponentPluginName } from 'components';
import { ALERT_TYPES, createGenericNotification, UserInvestigation } from 'model';
import Investigation from '../investigations/Investigation';
import { addSectionToInvestigation, isValidPrefix } from 'utils';
import { Redirect } from 'react-router-dom';

export default function PrefixReport({ assetValue }: { assetValue: string }) {
  const { asset } = usePrefixQuery(assetValue);

  const params = { assetValue };

  const newInvestigation: UserInvestigation = {
    title: assetValue,
    description: '',
    subtitle: asset?.org?.orgName ?? '',
    sections: [],
  };
  if (!isValidPrefix(assetValue)) {
    createGenericNotification('invalid-asset', {
      alertType: ALERT_TYPES.error,
      message: `${assetValue} is not a valid CIDR Block.`,
      title: 'Invalid Prefix',
    });
    return <Redirect to="/" />;
  }

  if (!asset) return <Investigation investigation={newInvestigation} />;

  addSectionToInvestigation(newInvestigation, ComponentPluginName.PrefixAbout, params, 'About');
  addSectionToInvestigation(newInvestigation, ComponentPluginName.PrefixRouting, params, 'Routing');
  if (asset?.attributesReputation?.reputationScore) {
    addSectionToInvestigation(
      newInvestigation,
      ComponentPluginName.PrefixReputation,
      params,
      'Reputation',
    );
  }
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.PrefixTracerouteEdges,
    params,
    'Traceroute Edges',
  );

  return <Investigation investigation={newInvestigation} />;
}
