import { useReactiveVar } from '@apollo/client';
import { SharedContext, SidebarContext } from 'appContexts';
import { Banner } from 'components/banners';
import { cloneDeep } from 'lodash';
import { ALERT_TYPES, userInvestigationsVar, UserNotification } from 'model';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Option } from 'react-select/src/filters';
import {
  copyTilesToMosaic,
  prepareImagesForCopy,
  getDefaultTitle,
  InvestigationTypes,
  makeUniqueId,
  parseInvestigationSectionId,
} from 'utils';
import { DropdownIndicator, reactSelectDropdownStyle } from 'components';
import { ReactComponent as WarningIcon } from 'svg/actions/exclamation-fill.svg';
import Select, { StylesConfig } from 'react-select';

export function CopyTilePrompt({ selected, setSelected, updatePromptHeight }) {
  const { investigation, isTemplate, setLocalTemplateParams } = useContext(SidebarContext);
  const { sharedKey } = useContext(SharedContext);
  const [toCopyMosaic, setToCopyMosaic] = useState<Option | undefined>();
  const investigations = useReactiveVar(userInvestigationsVar);
  const [newMosaicTitle, setNewMosaicTitle] = useState<string>(() => getDefaultTitle(isTemplate));
  const [goToMosaic, setGoToMosaic] = useState<boolean>(true);
  const [message, setMessage] = useState<UserNotification | undefined>();
  const history = useHistory();

  const newMosaic = useMemo(() => {
    return toCopyMosaic?.value === 'newMosaic';
  }, [toCopyMosaic?.value]);

  const copyTiles = useCallback(async () => {
    let copiedSections = cloneDeep(investigation.sections).filter((section) => {
      const [, params] = parseInvestigationSectionId(section.id);
      return selected.has(params.uuid);
    });
    const newInvestigationId = newMosaic ? makeUniqueId('investigation') : undefined;
    const combinedDescription = copiedSections.map((section) => section.description).join('');
    const newImages = await prepareImagesForCopy(
      newMosaic ? { id: newInvestigationId, images: [] } : investigations[toCopyMosaic?.value],
      combinedDescription,
      sharedKey,
    );
    const newInvestigation = copyTilesToMosaic(
      copiedSections,
      toCopyMosaic,
      newMosaicTitle,
      newImages,
      newInvestigationId,
    );
    if (goToMosaic) {
      setLocalTemplateParams(newInvestigation.templateParams);
      const shortID = (newInvestigation.id as string).split(':')[1];
      history.push(`/${isTemplate ? 'template' : 'mosaic'}?id=${shortID}`);
    } else {
      const newCopyBanner: UserNotification = {
        title: 'Success!',
        message: `Tiles were copied to ${newInvestigation.title}.`,
        alertType: ALERT_TYPES.success,
        onClear: () => {
          setMessage(undefined);
        },
        contentClass: 'uk-margin-auto-right',
      };
      setMessage(newCopyBanner);
    }
    setSelected(new Set());
  }, [
    goToMosaic,
    history,
    investigation.sections,
    newMosaicTitle,
    selected,
    setLocalTemplateParams,
    setSelected,
    toCopyMosaic,
    isTemplate,
    newMosaic,
    investigations,
    sharedKey,
  ]);

  useEffect(() => {
    updatePromptHeight();
  }, [toCopyMosaic, updatePromptHeight]);

  return !selected.size ? (
    message ? (
      <Banner {...message} style={{ marginBottom: '0' }} />
    ) : (
      <div className="uk-flex sidebar-menu-alert" style={{ height: '24px', padding: '24px 16px' }}>
        <WarningIcon className="uk-margin-right" />
        <div style={{ lineHeight: '24px' }}>Select content to be copied</div>
      </div>
    )
  ) : (
    <div>
      <div className="input-label uk-margin-small-bottom">Copy to</div>
      <Select
        value={toCopyMosaic}
        options={[
          {
            label: '',
            options: [
              {
                label: `Copy to New ${isTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic}`,
                value: 'newMosaic',
              },
            ],
          },
          {
            label: `${isTemplate ? 'Templates' : 'Mosaics'}`,
            options: Object.values(investigations)
              .sort((a, b) => {
                if (!a.saved && !b.saved) return 0;
                return (a.saved ?? 0) >= (b.saved ?? 0) ? -1 : 1;
              })
              .filter((mosaic) => (isTemplate ? mosaic.isTemplate : !mosaic.isTemplate))
              .map((mosaic) => ({ value: mosaic.id, label: mosaic.title })),
          },
        ]}
        onChange={(ev: any) => ev && setToCopyMosaic(ev)}
        blurInputOnSelect={true}
        components={{ DropdownIndicator }}
        styles={reactSelectDropdownStyle() as StylesConfig}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        data-testid="tile-copy-dropdown"
      />
      {newMosaic && (
        <div className="uk-margin-top">
          <div className="input-label uk-margin-small-bottom">{`${isTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic} Title`}</div>
          <input
            className="uk-input"
            value={newMosaicTitle}
            onChange={(ev) => setNewMosaicTitle(ev.target.value)}
          />
        </div>
      )}
      <div className="uk-flex uk-margin-top">
        {toCopyMosaic && toCopyMosaic.value !== investigation.id && (
          <label className="uk-margin-top app-cursor-pointer">
            <input
              type="checkbox"
              className="uk-checkbox uk-margin-right"
              onChange={() => setGoToMosaic((curr) => !curr)}
              checked={goToMosaic}
            />
            {`Go To ${isTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic}`}
          </label>
        )}
        <div style={{ marginLeft: 'auto' }}>
          <button
            className="uk-button uk-button-default"
            onClick={() => copyTiles()}
            data-testid="copy-tile-prompt-button"
          >
            Copy ({selected.size})
          </button>
        </div>
      </div>
    </div>
  );
}
