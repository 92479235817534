import React, { useMemo } from 'react';
import { UserTag } from 'model';
import CreatableSelect from 'react-select/creatable';
import { sortBy } from 'lodash';
import { MultiValueRemove, tagsDropdownStyle, DropdownIndicator, ClearIndicator } from 'components';
import { MultiValue, StylesConfig } from 'react-select';
import { getUserTags } from 'utils';

function userTagsToOptions(userTags: UserTag[]) {
  return userTags.map((tag: UserTag) => ({ value: tag.name, label: tag.name, tag }));
}

export function UserTagsSelector({
  selectedTags,
  onSelect,
  disabled,
}: { selectedTags: UserTag[]; onSelect: (tags: UserTag[]) => void; disabled?: boolean }) {
  const options = userTagsToOptions(getUserTags());

  const activeTags: any[] = useMemo(() => {
    return userTagsToOptions(selectedTags);
  }, [selectedTags]);

  function onChange(selection: MultiValue<any>) {
    const userTags = selection
      ? selection.map((opt: any) => (opt.tag ? opt.tag : { name: opt.value.toLowerCase() }))
      : [];
    onSelect(userTags);
  }

  return (
    <CreatableSelect
      options={sortBy(options, 'label')}
      isDisabled={disabled}
      isMulti={true}
      value={activeTags}
      onChange={onChange}
      styles={tagsDropdownStyle() as StylesConfig}
      components={{ MultiValueRemove, DropdownIndicator, ClearIndicator }}
    />
  );
}
