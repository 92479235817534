import { gql } from '@apollo/client';
import { useAssetQuery } from 'hooks';
import { AssetType } from 'model';

const assetQuery = gql`
  query ASAboutData($asnSet: [Int]!) {
    asData(asnSet: $asnSet) {
      asn
      org {
        orgName
        source
      }
      geo {
        city
        countryDivision
        country
        latLon
        source
      }
      routerAliasesPaged {
        totalCount
      }
      attributesStateownedases {
        stateownedasesCountryOfOwnership
      }
    }
  }
`;

export function useASNQuery(assetValue: string) {
  const { asset, loading, error } = useAssetQuery(AssetType.AS, assetValue, assetQuery);
  return { asset, loading, error };
}
