import { gql } from '@apollo/client';
import { useAssetQuery } from 'hooks';
import { AssetType } from 'model';

const assetQuery = gql`
  query RouterAboutData($routerIdSet: [String]!) {
    routerData(routerIdSet: $routerIdSet) {
      routerId
      routerAlias {
        asn {
          asn
        }
        behaviors {
          type
          names
          details
          tags
        }
        country
        geo {
          city
          country
          countryDivision
          latLon
          scope
        }
        vendor {
          name
          os
          osVersion
        }
        ixes {
          exchangeId
          exchange {
            name
          }
        }
        facs {
          facility {
            id
            name
          }
        }
        ipCount
        responsiveIpCount
        unresponsiveIpCount
      }
    }
  }
`;

export function useRouterQuery(assetValue: string) {
  const { asset, loading, error } = useAssetQuery(AssetType.Router, assetValue, assetQuery);
  return { asset, loading, error };
}
