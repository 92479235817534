import { components } from 'react-select';
import { ReactComponent as ExpandOpen } from 'svg/direction/chevron-south.svg';
import React from 'react';

export function DropdownIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <ExpandOpen />
    </components.DropdownIndicator>
  );
}
