import { gql } from '@apollo/client';
import { useAssetQuery } from 'hooks';
import { AssetType } from 'model';

const assetQuery = gql`
  query OrgAboutData($name: String!) {
    orgAssets(name: $name) {
      id
      orgName
      prefixCount
      asCount
      ases {
        id
        asn
        routerAliasesPaged {
          totalCount
        }
      }
    }
  }
`;

export function useOrgQuery(assetValue: string) {
  const { asset, loading, error } = useAssetQuery(AssetType.Org, assetValue, assetQuery);
  return { asset, loading, error };
}
