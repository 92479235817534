import React, { FunctionComponent, useRef, DragEvent, ReactNode } from 'react';
import { range } from 'lodash';

type FileSelectorProps = {
  onSelect: (file: File) => void;
  dropZone?: boolean;
  children?: ReactNode;
};

export const FileSelector: FunctionComponent<FileSelectorProps> = ({ onSelect, dropZone }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputStyle = {
    width: '0.1px',
    height: '0.1px',
    zIndex: -1,
  };

  function toFilesArray(files: FileList) {
    return files ? range(files.length).map((i: number) => files[i]) : [];
  }

  function onFileSelect(ev: any) {
    onSelect(ev.target.files[0]);
  }

  function onDragOver(event: DragEvent<HTMLDivElement>) {
    containerRef?.current?.classList.add('uk-dragover');
    if (event.dataTransfer?.files.length > 0) {
      event.dataTransfer.dropEffect = 'copy';
    }
    event.preventDefault();
  }

  function onDragLeave(event: DragEvent<HTMLDivElement>) {
    containerRef?.current?.classList.remove('uk-dragover');
    event.preventDefault();
  }

  function onDrop(event: DragEvent<HTMLDivElement>) {
    const files = toFilesArray(event.dataTransfer?.files);
    if (files.length > 0) {
      onSelect(files[0]);
    }
    containerRef?.current?.classList.remove('uk-dragover');
    event.preventDefault();
  }

  const dragAndDropProps = dropZone ? { onDragOver, onDragLeave, onDrop } : {};

  return (
    <div
      ref={containerRef}
      data-uk-form-custom="target: dummy"
      {...dragAndDropProps}
      style={{ minWidth: '100%' }}
    >
      <div className="uk-text-center uk-display-inline-block file-selector">
        <div className="uk-text-middle">Drag & Drop </div>
        <div className="strike-text">
          <span>OR</span>
        </div>
        <label className="uk-button uk-button-secondary uk-flex-inline uk-flex-middle">
          <input
            type="file"
            multiple={false}
            value={undefined}
            title=" "
            onClick={(event: any) => (event.target.value = '')}
            onChange={onFileSelect}
            style={inputStyle}
          />
          Browse Files
        </label>
      </div>
    </div>
  );
};
