import React, { useCallback, useContext, useState, useEffect } from 'react';
import { ReactComponent as ToggleOn } from 'svg/selection/toggle-on.svg';
import { ReactComponent as ToggleOnDisabled } from 'svg/selection/toggle-on-disabled.svg';
import { ReactComponent as ToggleOff } from 'svg/selection/toggle-off.svg';
import { ComponentPluginContext } from 'appContexts';
import { getConfigAdvancedQuery, isViewOnly } from 'utils';

export default function HideUnselected() {
  const { updatePluginConfig, getPluginConfig, params } = useContext(ComponentPluginContext);
  const view = getConfigAdvancedQuery(params)?.view;
  const viewOnly = isViewOnly();
  const currentConfig = getPluginConfig();
  const selected = currentConfig[view]?.selected;
  const [hideUnselected, setHideUnselected] = useState(
    currentConfig[view]?.hideUnselected ?? false,
  );
  const disabled = viewOnly || !selected || selected.length === 0;

  const onToggleHideSelectable = useCallback(() => {
    if (!disabled) {
      currentConfig[view] = { ...currentConfig[view], hideUnselected: !hideUnselected };
      updatePluginConfig(currentConfig);
      setHideUnselected(!hideUnselected);
    }
  }, [hideUnselected, updatePluginConfig, currentConfig, disabled, view]);

  // Views that use this component may need to reset hideUnselected, so this captures the change
  useEffect(() => {
    if (hideUnselected !== currentConfig[view]?.hideUnselected) {
      setHideUnselected(currentConfig[view]?.hideUnselected);
    }
  }, [currentConfig, view, hideUnselected]);

  return (
    <div className={`config-option uk-flex hide-in-pdf ${disabled ? 'view-only' : ''}`}>
      <div>Hide unselected results</div>
      <div onClick={onToggleHideSelectable} className="uk-margin-auto-left">
        {hideUnselected ? viewOnly ? <ToggleOnDisabled /> : <ToggleOn /> : <ToggleOff />}
      </div>
    </div>
  );
}
