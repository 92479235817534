import { components } from 'react-select';
import { ReactComponent as CloseIcon } from 'svg/actions/x-default.svg';
import React from 'react';

export function ClearIndicator(props: any) {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon />
    </components.ClearIndicator>
  );
}
