import React, { Fragment, useContext, useMemo } from 'react';
import { usePrefixQuery } from 'hooks';
import ReportTitle from 'components/ReportTitle';
import { ComponentPluginName, DisplayElement } from 'components';
import { AssetInfoContext } from 'appContexts';
import { UserInvestigationSection } from 'model';
import { toInvestigationSectionId } from 'utils';
import InvestigationSection from '../investigations/InvestigationSection';

export default function PrefixInfo({ assetValue }: { assetValue: string }) {
  const { asset } = usePrefixQuery(assetValue);
  const { displayElements } = useContext(AssetInfoContext);

  const aboutSection: UserInvestigationSection = useMemo(
    () => ({
      id: toInvestigationSectionId(ComponentPluginName.PrefixAbout, { assetValue }),
      title: 'About',
      description: '',
    }),
    [assetValue],
  );

  const routingSection: UserInvestigationSection = useMemo(
    () => ({
      id: toInvestigationSectionId(ComponentPluginName.PrefixRouting, { assetValue }),
      title: 'Routing',
      description: '',
    }),
    [assetValue],
  );

  return (
    <Fragment>
      {asset && (
        <Fragment>
          {displayElements?.includes(DisplayElement.Title) && (
            <ReportTitle title={assetValue} subtitle={asset.org?.orgName} label="Prefix" />
          )}
          {displayElements?.includes(DisplayElement.About) && (
            <InvestigationSection section={aboutSection} />
          )}
          {displayElements?.includes(DisplayElement.Details) && (
            <InvestigationSection section={routingSection} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
