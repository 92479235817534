import React, { useState, useRef, useEffect, useContext, useLayoutEffect } from 'react';
import UIkit from 'uikit';
import { v4 } from 'uuid';
import { ComponentPluginContext } from 'appContexts';
import { Spinner } from 'components';
import { getActiveTab } from 'utils';

export type TabbedContentOption = {
  name: string;
  label?: string;
  active?: boolean;
  content: any;
  loading?: boolean;
};

export default function TabbedContent({
  options,
  configUpdateDisabled,
}: {
  options: TabbedContentOption[];
  configUpdateDisabled?: boolean;
}) {
  const { getPluginConfig, updatePluginConfig } = useContext(ComponentPluginContext);
  const switcherRef = useRef<HTMLUListElement>(null);
  const tabsRef = useRef<HTMLUListElement>(null);
  const [selected, setSelected] = useState(
    getActiveTab(options, getPluginConfig(), configUpdateDisabled),
  );
  const [switcherID] = useState<string>(() => `tabbed-content-${v4()}`);
  const tabIndex = options.findIndex((option) => option.name === selected) || 0;

  useEffect(() => {
    if (!switcherRef.current) {
      return;
    }
    return UIkit.util.on(switcherRef.current, 'shown', (event: Event) => {
      const elem = event.target as HTMLElement;
      const newTab = elem.getAttribute('data-name') || '';
      if (newTab !== selected) {
        setSelected(newTab);
        let newConfig = { ...getPluginConfig(), tab: newTab };
        !configUpdateDisabled && updatePluginConfig(newConfig);
      }
    });
  }, [switcherRef, getPluginConfig, updatePluginConfig, configUpdateDisabled, selected]);

  useLayoutEffect(() => {
    if (tabsRef.current) {
      UIkit.switcher(tabsRef.current).show(tabIndex);
    }
  }, [tabIndex]);

  useEffect(() => {
    setSelected(getActiveTab(options, getPluginConfig(), configUpdateDisabled));
  }, [options, getPluginConfig, configUpdateDisabled]);

  return (
    <div className="uk-position-relative">
      <ul
        ref={tabsRef}
        className="uk-tab table-tab-options uk-margin-remove-top disabled-tabs"
        data-uk-switcher={`connect: #${switcherID}`}
        style={{ display: 'inline-flex' }}
      >
        {options.map((contentObj: any) => {
          return (
            <li key={contentObj.name}>
              <a href={window.location.href} className="uk-text-capitalize">
                {contentObj.label ?? contentObj.name}
              </a>
            </li>
          );
        })}
      </ul>
      <ul ref={switcherRef} className="uk-switcher" id={switcherID}>
        {options.map((contentObj: any) => {
          return (
            <li key={contentObj.name} data-name={contentObj.name}>
              {selected === contentObj.name &&
                (contentObj.loading ? <Spinner ratio={3} /> : contentObj.content)}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
