import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DropdownIndicator, ClearIndicator, reactSelectSearchStyle } from 'components';
import { components, StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ReactComponent as Search } from 'svg/system/magnifying-glass.svg';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useHistory } from 'react-router-dom';

export function ManagerTableFilter({
  placeholder,
  selected,
  options,
  onChange,
  loadOptions,
}: { placeholder?: string; selected?: any; options?: any; onChange: any; loadOptions?: any }) {
  const [creatableInputValue, setCreatableInputValue] = useState<string>();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [clearButtonPressed, setClearButtonPressed] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState();
  const selectRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    async function loadDefaultOptions() {
      if (!creatableInputValue || !loadOptions) {
        setDefaultOptions(options);
      } else {
        const newDefaultOptions = await loadOptions(creatableInputValue);
        setDefaultOptions(newDefaultOptions);
      }
    }
    loadDefaultOptions();
  }, [creatableInputValue, loadOptions, options]);

  const selectProps = useMemo(() => {
    const handleFocus = () => {
      if (!clearButtonPressed) {
        setMenuIsOpen(true);
      } else {
        selectRef.current.blur();
        setClearButtonPressed(false);
      }
      if (selected) {
        setCreatableInputValue((selected as any).label);
      }
    };
    return {
      onInputChange: (val, ev) => {
        if (ev?.action === 'input-change') {
          setCreatableInputValue(val);
          setMenuIsOpen(true);
        } else if (ev?.action === 'menu-close') {
          setMenuIsOpen(false);
        }
      },
      onMenuClose: () => {
        selectRef.current.blur();
      },
      onMenuOpen: () => {
        setMenuIsOpen(true);
      },
      openMenuOnClick: true,
      ref: selectRef,
      inputValue: creatableInputValue,
      placeholder: placeholder ?? 'Search for a construct or tag',
      closeMenuOnSelect: false,
      hideSelectedOptions: false,
      menuPortalTarget: document.body,
      isClearable: true,
      styles: customStyle() as StylesConfig,
      components: {
        Option,
        Control,
        SingleValue,
        DropdownIndicator,
        ClearIndicator,
      },
      value: menuIsOpen && !creatableInputValue ? null : (selected as any),
      menuIsOpen,
      tabSelectsValue: false,
      onKeyDown: (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onChange({ label: creatableInputValue, value: creatableInputValue });
          setMenuIsOpen(false);
        }
      },
      onChange: (ev, actionMeta) => {
        setCreatableInputValue('');
        onChange(ev);
        if (actionMeta.action === 'clear') {
          setClearButtonPressed(true);
          selectRef.current.blur();
        } else if (actionMeta.action === 'select-option' && ev.count == null && ev.url != null) {
          history.push(ev.url);
        }
      },
      blurInputOnSelect: true,
      formatCreateLabel: (userInput) => `Search "${userInput}"`,
      onFocus: handleFocus,
    };
  }, [
    creatableInputValue,
    menuIsOpen,
    onChange,
    placeholder,
    selected,
    clearButtonPressed,
    history,
  ]);

  return (
    <div className="manager-table-filter">
      {loadOptions ? (
        <AsyncCreatableSelect
          {...selectProps}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
        />
      ) : (
        <CreatableSelect {...selectProps} options={options} />
      )}
    </div>
  );
}

const Option = (props: any) => {
  const docStyle = getComputedStyle(document.documentElement);
  const neutralColor = docStyle.getPropertyValue('--app-neutral-color');
  let optionTextParts: string[];
  const count = props?.data?.count;
  const searchString = props?.data?.searchString;
  // Determine where in the option text we should bold based on
  // what the user currently has typed in the search box.
  if (searchString && props?.value?.includes(searchString)) {
    // Index that the search string starts in the option
    const start = props?.value?.search(searchString);
    // Index that the search string ends in the option
    const end = start + searchString.length;
    // All parts, max 3 (initial non-bold, bold, and final non-bold)
    optionTextParts = [];
    // Add initial non-bold part if necessary
    if (start > 0) {
      optionTextParts.push((props?.label as string).substring(0, start));
    }
    // Add bold part
    optionTextParts.push((props?.label as string).substring(start, end));
    // Add final non-bold part if necessary
    if (end < props?.label?.length) {
      optionTextParts.push((props?.label as string).substring(end));
    }
  }

  return (
    <div className="app-tag-wrapper">
      <components.Option {...props}>
        <div className="uk-flex uk-flex-middle">
          <span style={{ overflowWrap: 'anywhere' }} className={`${count ? 'app-tag' : ''}`}>
            {optionTextParts
              ? optionTextParts.map((part, i) => {
                  if (part.toLowerCase() === searchString) {
                    return (
                      <span className="uk-text-bold" key={i}>
                        {part}
                      </span>
                    );
                  }
                  return part;
                })
              : props.label}
          </span>
          <div className="uk-float-right" style={{ marginLeft: 'auto', color: neutralColor }}>
            {count}
          </div>
        </div>
      </components.Option>
    </div>
  );
};

function Control({ children, ...props }: any) {
  return (
    <components.Control {...props}>
      <Search className={'search-icon'} />
      {children}
    </components.Control>
  );
}

function SingleValue({ children, ...props }: any) {
  const isTag = props?.data?.count;
  return (
    <components.SingleValue {...props} className={`${isTag ? 'app-tag-search' : ''}`}>
      {children}
    </components.SingleValue>
  );
}

function customStyle() {
  return {
    ...reactSelectSearchStyle(),
    valueContainer: (base: Record<string, any>) => ({
      ...base,
      padding: '0px 0px 0px 4px',
    }),
    control: (base: Record<string, any>, { isDisabled }) => ({
      ...base,
      ...reactSelectSearchStyle().control(base, { isDisabled }),
      maxWidth: '288px',
    }),
  };
}
