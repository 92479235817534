import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useBlockLayout,
  useExpanded,
  usePagination,
  useResizeColumns,
  useTable,
} from 'react-table';
import { get, isEqual, orderBy } from 'lodash';
import {
  Banner,
  EmptyMessage,
  ExactMatchFilter,
  HistogramFilter,
  OptionSelectFilter,
  SeeMorePagination,
  Spinner,
} from 'components';
import { ALERT_TYPES, QueryFilterClause, QueryFilterComparator } from 'model';
import {
  APP_CONTENT_WIDTH,
  getAccessor,
  getColumnIdentifier,
  getCountryName,
  hideValueOptions,
  NewTableColumn,
  transformTableAccessorToLabel,
} from 'utils';
import { ReactComponent as ExpandClosed } from 'uikit/src/images/icons/chevron-right.svg';
import { ReactComponent as ExpandOpen } from 'uikit/src/images/icons/chevron-down.svg';
import { ComponentPluginContext } from 'appContexts';

export const StoredColumnFields = ['accessor', 'Header', 'width'];

enum PaginationStyles {
  seeMore = 'seeMore',
  traditional = 'traditional',
}

export type NewPaginationProps = {
  rowsToLoad?: number;
  totalRows: number;
  onLoadRows?: any;
  loading?: boolean;
  currentPage?: number;
  paginationStyle?: PaginationStyles;
  rowsVisible?: number;
  postFilterStatus?: string;
};

export type TableProps = {
  name: string;
  label?: string;
  columns: NewTableColumn[];
  corruptColumns?: string[];
  onUpdateColumnConfig: any;
  onAddFilter?: any;
  data: any;
  description?: any;
  style?: any;
  paginationProps: NewPaginationProps;
  expandableRowsComponent?: any;
  checkExpandable?: any;
  loading?: boolean;
  aggregation?: any;
};

export default function NewTable({ tableProps }: { tableProps: TableProps }) {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 135,
      width: 250,
      maxWidth: 400,
    }),
    [],
  );
  const { isPrinterFriendly } = useContext(ComponentPluginContext);

  const { checkExpandable, corruptColumns } = tableProps;

  const hasSubComponent = useMemo(() => {
    return !!tableProps.expandableRowsComponent;
  }, [tableProps.expandableRowsComponent]);

  const columns = useMemo(() => {
    return tableProps.columns;
  }, [tableProps.columns]);

  function getColumnFilterId(col: NewTableColumn, clause: QueryFilterClause) {
    let index = `${getColumnIdentifier(col)}`;
    if (clause.comparator === QueryFilterComparator.IsAfter) {
      index = index + '-lower';
    } else if (clause.comparator === QueryFilterComparator.IsBefore) {
      index = index + '-upper';
    }
    return index;
  }

  const initialLocalFilters = useMemo(() => {
    let retVal: any = {};
    columns.forEach((col) => {
      if (!col.active || !col.localFilter || !col.columnFilterClauses) {
        return;
      }
      const filterClauses = col.columnFilterClauses;
      filterClauses.forEach((clause) => {
        let index = getColumnFilterId(col, clause);
        retVal[index] = { val: clause.value, accessor: clause.type };
      });
    });
    return retVal;
  }, [columns]);

  // Filters handled locally by the table, for data we don't need to make additional backend requests for
  const [localFilters, setLocalFilters] = useState<any>(initialLocalFilters);

  const pages = useMemo(() => {
    return tableProps.data;
  }, [tableProps.data]);

  const onUpdateColumnConfig = useMemo(() => {
    return tableProps.onUpdateColumnConfig;
  }, [tableProps.onUpdateColumnConfig]);

  const activeColumns = useMemo(() => {
    return columns?.filter((col) => col.active);
  }, [columns]);

  const expanderColumn = useMemo(() => {
    return {
      // Build our expander column
      id: 'expander', // Make sure it has an ID
      Header: () => {
        return <div />;
      },
      Cell: ({ row }: { row: any }) => {
        const isRowExpandable = checkExpandable ? checkExpandable(row.original) : true;
        if (hasSubComponent && isRowExpandable) {
          return (
            <span
              {...row.getToggleRowExpandedProps({})}
              className="cursor-pointer"
              style={{ zIndex: '900', padding: '4px' }}
            >
              {row.isExpanded ? <ExpandOpen /> : <ExpandClosed />}
            </span>
          );
        }

        return <div style={{ padding: 0, width: 0 }} />;
      },
      maxWidth: hasSubComponent ? 28 : 0,
      padding: 0,
      disableResizing: true,
    };
  }, [hasSubComponent, checkExpandable]);

  const combinedCols = useMemo(() => {
    return [expanderColumn, ...activeColumns];
  }, [expanderColumn, activeColumns]);

  const data = useMemo(() => {
    return pages.reduce((flatArr: any, page: any) => {
      return flatArr.concat(page);
    }, []);
  }, [pages]);

  const filteredData = useMemo(() => {
    // Filter data based on local filters
    if (Object.keys(localFilters).length > 0) {
      return data.filter((row: any, i: number) => {
        let keepRow: boolean | undefined = undefined;
        // Loop through filters
        Object.entries(localFilters).forEach((entry) => {
          const filter = entry[0];
          let value = (entry[1] as any)?.val?.value as string;
          let category = (entry[1] as any)?.accessor?.toLowerCase();
          let strippedFilter = filter;
          if (filter.endsWith('upper') || filter.endsWith('lower')) {
            strippedFilter = filter.split('-')[0];
            value = (entry[1] as any)?.val as string;
          }
          // If the filter value is undefined, the filter has been cleared, so keep this row
          // if it hasn't already been filtered out.
          if (!value) {
            if (keepRow == null) keepRow = true;
            return;
          }
          // Find the active column for this filter
          const activeColumn = activeColumns.find((col) => {
            return getColumnIdentifier(col) === strippedFilter;
          });
          // If the column was removed, just return, we will no longer be taking that filter into account
          if (!activeColumn) return;

          const accessor = getAccessor(activeColumn, false, row, i);
          const subAccessor = getAccessor(activeColumn, true, row, i);
          const accessorLabel = transformTableAccessorToLabel(accessor ?? '');
          const subAccessorLabel = transformTableAccessorToLabel(subAccessor ?? '');
          if (activeColumn?.filterType === 'string') {
            // Function which determines if we should keep the row based on a filter
            const keepRowFunc = (accessor: string, label: string) => {
              // If the category (organization, asn, etc.) doesn't match this piece of data,
              // move on
              if (category != null && !label.toLowerCase().includes(category.toLowerCase())) {
                return false;
              }

              const accessorValue = get(row, accessor);
              const rowVals = Array.isArray(accessorValue) ? accessorValue : [accessorValue];
              // const rowVal = get(row, accessor)?.toString().toLowerCase();

              const results = rowVals.map((val: any) => {
                const rowVal = val?.toString().toLowerCase();
                // Return true the row value equals the filter value exactly,
                // unless partial matches are accepted
                if (activeColumn.partialMatchFilter) {
                  return rowVal?.includes(value?.toLowerCase() ?? '');
                }

                if (rowVal === value?.toLowerCase()) {
                  return true;
                }
                const splitVal = value?.toLowerCase()?.split(', ');
                if (splitVal?.length > 1) {
                  return rowVal === splitVal[0];
                }
                return false;
              });
              return results.includes(true);
            };

            // If the row has not already been filtered out, and keepRowFunc returns true for either the
            // accessor or subAccessor, we can keep the row.
            keepRow =
              (keepRow == null || keepRow) &&
              !!(
                keepRowFunc(accessor, accessorLabel) ||
                (subAccessor && keepRowFunc(subAccessor, subAccessorLabel))
              );
          } else if (activeColumn?.filterType === 'histogram') {
            const isUpper = filter.endsWith('upper');
            const rowEntry = +get(row, activeColumn?.aggAccessor || accessor);
            // For histograms, keep the row if it hasn't already been filtered and is within the bounds
            keepRow =
              (keepRow == null || keepRow) &&
              ((isUpper && rowEntry <= +value) || (!isUpper && rowEntry >= +value));
          }
        });
        return keepRow;
      });
    }
    return undefined;
  }, [data, activeColumns, localFilters]);

  const paginationProps = tableProps?.paginationProps;

  const rowsVisible = useMemo(() => {
    return (
      paginationProps?.rowsVisible ??
      pages.reduce((runningTotal: any, current: any) => {
        return runningTotal + current?.length ?? 0;
      }, 0)
    );
  }, [pages, paginationProps]);

  const tableRef = useRef<HTMLDivElement>(null);
  const [initialResizing] = useState<any>(() => initColumnSizing());

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    setPageSize,
    toggleAllRowsExpanded,
    state: { pageSize, columnResizing },
    prepareRow,
  } = useTable(
    {
      columns: combinedCols,
      data: filteredData ?? data,
      defaultColumn,
      initialState: { columnResizing: initialResizing },
    },
    useBlockLayout,
    useResizeColumns,
    useExpanded,
    usePagination,
  );

  const onAddFilter = useCallback(
    (clause: string, filter: QueryFilterClause | undefined) => {
      toggleAllRowsExpanded(false);
      tableProps.onAddFilter && tableProps.onAddFilter(clause, filter);
    },
    [tableProps, toggleAllRowsExpanded],
  );

  const onAddLocalFilter = useCallback(
    (val: string, accessor: string, column: NewTableColumn, isUpper?: boolean) => {
      let comparator = QueryFilterComparator.Is;
      if (isUpper != null) {
        comparator = isUpper ? QueryFilterComparator.IsBefore : QueryFilterComparator.IsAfter;
        setLocalFilters((curr: any) => {
          return {
            ...curr,
            [`${getColumnIdentifier(column)}-${isUpper ? 'upper' : 'lower'}`]: { val, accessor },
          };
        });
      } else {
        setLocalFilters((curr: any) => {
          return { ...curr, [getColumnIdentifier(column)]: { val, accessor } };
        });
      }
      const newColumns = activeColumns.map((col) => {
        if (getColumnIdentifier(col) === getColumnIdentifier(column)) {
          const newClause: QueryFilterClause = { type: accessor, value: val, comparator };
          const newFilterClauses =
            col.columnFilterClauses?.filter((clause) => clause.comparator !== comparator) ?? [];
          if (typeof col.accessor !== 'string') delete col.accessor;
          if (newClause.type === undefined) delete newClause.type;
          return { ...col, columnFilterClauses: [...newFilterClauses, newClause] };
        }
        if (typeof col.accessor !== 'string') delete col.accessor;
        return col;
      });
      onUpdateColumnConfig(newColumns);
    },
    [activeColumns, onUpdateColumnConfig],
  );

  async function loadMoreData(pageSize: number) {
    if (paginationProps?.onLoadRows) {
      paginationProps.onLoadRows(
        pageSize,
        paginationProps.currentPage ? paginationProps.currentPage + 1 : undefined,
      );
    }
  }

  function initColumnSizing() {
    const retVal: any = {
      columnResizing: {
        columnWidths: {},
        headerIdWidths: {},
      },
    };
    activeColumns.forEach((col) => {
      if (col.width) {
        retVal.columnResizing.columnWidths[getColumnIdentifier(col) as string] = col.width;
      }
    });
    return retVal.columnResizing;
  }

  // When the number of visible rows changes, update the table's page size
  useEffect(() => {
    setPageSize(rowsVisible === 0 ? 25 : rowsVisible);
  }, [rowsVisible, pageSize, setPageSize]);

  // update the column resizing
  useEffect(() => {
    function updateResize() {
      const columnWidths = columnResizing.columnWidths;
      if (Object.keys(columnWidths).length > 0) {
        const newColumns = activeColumns.map((col) => {
          const id = getColumnIdentifier(col) as string;
          const newWidth = columnWidths[id];
          if (col.width !== newWidth && newWidth != null) {
            const minVal = Math.max(col.minWidth ?? defaultColumn.minWidth, newWidth);
            const maxVal = Math.min(col.maxWidth ?? defaultColumn.maxWidth, newWidth);
            return { ...col, width: minVal > newWidth ? minVal : maxVal };
          }
          return col;
        });
        if (!isEqual(newColumns, activeColumns)) {
          onUpdateColumnConfig(newColumns);
        }
      }
    }

    if (!columnResizing.isResizingColumn) {
      updateResize();
    }
  }, [
    columnResizing,
    activeColumns,
    onUpdateColumnConfig,
    defaultColumn,
    initialResizing.columnWidths,
  ]);

  // For local filters in asset pages, we require at least 20 rows to display the filters.
  const tableMeetsRowReqs = useCallback(
    (col: NewTableColumn) => {
      return !col.localFilter || col.filterEnabled || data.length >= 20;
    },
    [data.length],
  );

  const isEmpty = page.length === 0 && !tableProps.loading;
  const hasFilters = useMemo(() => {
    return activeColumns.some((col) => !!col.filterType && tableMeetsRowReqs(col));
  }, [activeColumns, tableMeetsRowReqs]);

  const corruptColumnsMessage = `
    A previously selected column is no longer available.
    Please select a replacement column or remove the empty column field to update your table.
    Affected Columns: ${corruptColumns?.join(', ')}`;

  return (
    <Fragment>
      {corruptColumns && corruptColumns.length > 0 && (
        <Banner
          title={'Table View Warning'}
          message={corruptColumnsMessage}
          alertType={ALERT_TYPES.warning}
        />
      )}
      <div ref={tableRef} className="uk-flex uk-display-inline-block">
        {activeColumns.length > 0 ? (
          <div
            {...getTableProps()}
            className={`marginal-table${isPrinterFriendly ? ' printer-friendly-table' : ''}${tableProps.loading && tableProps.data.length === 0 ? ' loading-table' : ''}`}
          >
            <div id="table-header">
              <TableHeader />
              {hasFilters && <FilterRow />}
            </div>
            <div
              {...getTableBodyProps()}
              style={{
                display: 'inline-block',
                overflow: 'auto',
                width: isEmpty ? '100%' : getTableBodyProps().style?.width,
              }}
            >
              {tableProps?.loading && (
                <Spinner style={{ left: '20%' }} position={'absolute'} ratio={3} />
              )}
              {isEmpty ? (
                <div style={{ display: 'table' }} className="uk-margin-medium-top uk-align-center">
                  <EmptyMessage />
                </div>
              ) : (
                <TableBody />
              )}
              <SeeMorePagination
                rowsLoaded={pageSize}
                rowCount={filteredData ? filteredData.length : paginationProps?.totalRows}
                onLoadRows={loadMoreData}
                rowsToLoad={paginationProps?.rowsToLoad ?? 50}
                loading={paginationProps?.loading}
                postFilterStatus={paginationProps?.postFilterStatus}
              />
            </div>
          </div>
        ) : (
          <div style={{ height: '200px' }} className="uk-align-center">
            <span className="app-text-medium">No Columns Active</span>
          </div>
        )}
      </div>
    </Fragment>
  );

  function TableHeader() {
    return (
      <Fragment>
        {headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps()} className="tr uk-margin-top">
            {headerGroup.headers.map((column) => (
              <div
                {...column.getHeaderProps()}
                id={`column-${column.id}`}
                className="th app-subtitle column-header"
              >
                <span>{column.render('Header')}</span>
                {!column.disableResizing && (
                  <button
                    {...column.getResizerProps()}
                    className={`column-resizer ${column.isResizing ? 'isResizing' : ''}`}
                  >
                    <div data-uk-icon="icon: triangle-left; ratio: 0.75" />
                    <div data-uk-icon="icon: triangle-right; ratio: 0.75" />
                  </button>
                )}
              </div>
            ))}
          </div>
        ))}
      </Fragment>
    );
  }

  function formatOptionsLabel(value: string, accessor: string, subValue?: string) {
    let retVal = value;
    if (accessor.endsWith('asn')) {
      retVal = value && `AS${value}, ${subValue}`;
    } else if (accessor.includes('countr') && value?.length === 2) {
      retVal = getCountryName(value);
    } else if (accessor.includes('speed')) {
      const num = +value;
      retVal = num ? (num < 1000 ? `${num}M` : `${num / 1000}G`) : '0';
    }
    return retVal;
  }

  function FilterRow() {
    return (
      <Fragment>
        {headerGroups.map((headerGroup) => {
          return (
            <div {...headerGroup.getHeaderGroupProps()} className="tr uk-margin-medium-bottom">
              {tableProps?.aggregation?.loading ? (
                <Spinner style={{ marginLeft: 'auto', marginRight: 'auto' }} ratio={1} />
              ) : (
                headerGroup.headers.map((column, i) => {
                  // The i === 0 header is the expander column, which is not in the active columns array
                  if (i === 0) {
                    return hasSubComponent ? (
                      <div {...column.getHeaderProps()} className="th" />
                    ) : null;
                  }
                  // Setting up Filter clauses
                  const activeColumn = activeColumns[i - 1];
                  const filterType = activeColumn?.filterType;
                  const filterClauses = activeColumn?.columnFilterClauses;
                  const firstClause = filterClauses ? filterClauses[0] : undefined; // Passed to filter

                  // Setting up value options
                  let uniqueOptions = new Set<string>();
                  let uniqueSubOptions = new Set<string>();
                  let valueOptions: any[] = [];
                  let subValueOptions: any[] = [];
                  let allValueOptions = []; // This will be passed to filter

                  // Setting up accessors
                  const accessor =
                    data.length > 0 ? getAccessor(activeColumn, false, data[0], 0) : undefined;
                  const subAccessor =
                    data.length > 0 ? getAccessor(activeColumn, true, data[0], 0) : undefined;
                  const hideAccessorOptions = hideValueOptions(accessor);
                  const hideSubAccessorOptions = hideValueOptions(subAccessor);
                  const accessorLabel = transformTableAccessorToLabel(accessor ?? '');
                  const subAccessorLabel = transformTableAccessorToLabel(subAccessor ?? '');
                  // Placeholders
                  let filterPlaceholder = !!accessorLabel
                    ? `Filter on ${accessorLabel}`
                    : 'No Data';
                  if (!!subAccessor && !!accessorLabel)
                    filterPlaceholder += ` or ${subAccessorLabel}`;

                  // For local filters, create the list of value options for the dropdown based on the data
                  if (
                    activeColumn.localFilter &&
                    activeColumn.filterType &&
                    !(hideAccessorOptions && hideSubAccessorOptions)
                  ) {
                    data.forEach((item: any) => {
                      const accessorValue = get(item, accessor); //?.toString();
                      const subValue = subAccessor ? get(item, subAccessor) : undefined;
                      const values = Array.isArray(accessorValue) ? accessorValue : [accessorValue];
                      if (values) {
                        values.forEach((val: any) => {
                          const value = val?.toString();
                          // Value options should be unique
                          if (!uniqueOptions.has(value)) {
                            const label = formatOptionsLabel(value, accessor, subValue);
                            valueOptions.push({ value, label, count: 1 });
                            uniqueOptions.add(value); // Add to the unique options set
                          } else {
                            valueOptions.find((options) => options.value === value).count += 1;
                          }
                          // Add the subValue option as well
                          if (subValue && subAccessor) {
                            if (!uniqueSubOptions.has(subValue)) {
                              const subLabel = formatOptionsLabel(subValue, subAccessor);
                              subValueOptions.push({ value: subValue, label: subLabel, count: 1 });
                              uniqueSubOptions.add(subValue);
                            } else {
                              subValueOptions.find((options) => options.value === subValue).count +=
                                1;
                            }
                          }
                        });
                      }
                    });

                    const sortAccessor = activeColumn.showCount ? 'count' : 'label';
                    const sortOrder = activeColumn.showCount ? 'desc' : 'asc';

                    if (typeof accessor === 'string' && !hideAccessorOptions) {
                      allValueOptions.push({
                        label: accessorLabel.toUpperCase(),
                        options: orderBy(valueOptions, sortAccessor, sortOrder),
                      });
                    }
                    if (typeof subAccessor === 'string' && !hideSubAccessorOptions) {
                      allValueOptions.push({
                        label: subAccessorLabel.toUpperCase(),
                        options: orderBy(subValueOptions, sortAccessor, sortOrder),
                      });
                    }
                  }

                  return (
                    <div {...column.getHeaderProps()} className="th">
                      {filterType === 'string' && tableMeetsRowReqs && (
                        <ExactMatchFilter
                          filterClause={
                            activeColumn.localFilter
                              ? localFilters[getColumnIdentifier(activeColumn)]?.val
                              : firstClause
                          }
                          column={activeColumn}
                          onAddFilter={activeColumn.localFilter ? onAddLocalFilter : onAddFilter}
                          valueOptions={activeColumn.localFilter ? allValueOptions : undefined}
                          placeholder={filterPlaceholder}
                          showCount={activeColumn.showCount}
                        />
                      )}
                      {tableProps.aggregation &&
                        filterType === 'histogram' &&
                        tableMeetsRowReqs && (
                          <HistogramFilter
                            localFilters={
                              activeColumn.localFilter
                                ? [
                                    get(localFilters, `${getColumnIdentifier(activeColumn)}-lower`)
                                      ?.val,
                                    get(localFilters, `${getColumnIdentifier(activeColumn)}-upper`)
                                      ?.val,
                                  ]
                                : undefined
                            }
                            column={activeColumn}
                            aggregation={tableProps.aggregation}
                            onAddFilter={activeColumn.localFilter ? onAddLocalFilter : onAddFilter}
                          />
                        )}
                      {tableProps.aggregation &&
                        activeColumn.clauseType &&
                        filterType === 'optionSelect' &&
                        tableMeetsRowReqs && (
                          <OptionSelectFilter
                            column={activeColumn}
                            aggregation={tableProps.aggregation}
                            onAddFilter={onAddFilter}
                          />
                        )}
                    </div>
                  );
                })
              )}
            </div>
          );
        })}
      </Fragment>
    );
  }

  function TableBody() {
    const minExpandedComponentWidth = 700;
    return (
      <Fragment>
        {page.map((row, i) => {
          prepareRow(row);
          let rowWidth = row.getRowProps().style?.width;
          if (typeof rowWidth === 'string') {
            rowWidth = +rowWidth.substring(0, rowWidth.length - 2);
          } else {
            rowWidth = undefined;
          }
          const expanded = row.isExpanded;
          return (
            <Fragment key={`page-${i}`}>
              <div
                {...row.getRowProps()}
                style={{
                  ...row.getRowProps().style,
                  width:
                    rowWidth && rowWidth < minExpandedComponentWidth && expanded
                      ? `${minExpandedComponentWidth}px`
                      : row.getRowProps().style?.width,
                }}
                className={`tr table-row ${expanded ? 'table-row-expanded' : ''}`}
              >
                {row.cells.map((cell) => {
                  return (
                    <div {...cell.getCellProps([{ className: 'td table-cell' }])}>
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
              {expanded && (
                <div
                  style={{
                    width: `${Math.max(+(rowWidth ?? 0), minExpandedComponentWidth)}px`,
                    minWidth: `${APP_CONTENT_WIDTH}px`,
                    maxHeight: '600px',
                    overflow: 'auto',
                    marginLeft: '36px',
                  }}
                  key={`${row.getRowProps().key}-expanded`}
                >
                  {hasSubComponent && tableProps.expandableRowsComponent({ data: row.original })}
                </div>
              )}
            </Fragment>
          );
        })}
      </Fragment>
    );
  }
}
