import {
  QueryFilterClause,
  QueryFilterClauseOption,
  QueryFilterComparator,
  QueryValueOption,
} from 'model';
import CreatableSelect from 'react-select/creatable';
import Select, { components, StylesConfig } from 'react-select';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { reactSelectColumnFilterStyle, DropdownIndicator, ClearIndicator } from 'components';
import {
  abbreviatedCount,
  getAssetTypeFromPlugin,
  getFilterClauseOption,
  getFilterClauseValueOptions,
  NewTableColumn,
} from 'utils';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { ComponentPluginContext } from 'appContexts';

export function ExactMatchFilter({
  filterClause,
  column,
  onAddFilter,
  valueOptions,
  placeholder,
  showCount,
}: {
  filterClause: QueryFilterClause | string | undefined;
  column: NewTableColumn;
  onAddFilter: any;
  valueOptions?: any[];
  placeholder?: string;
  showCount?: boolean;
}) {
  const clauseOption: QueryFilterClauseOption | undefined = column.clauseType
    ? getFilterClauseOption(column.clauseType)
    : undefined;
  const allValueOptions: any[] = useMemo(() => {
    return valueOptions ?? (clauseOption ? getFilterClauseValueOptions(clauseOption) : []);
  }, [valueOptions, clauseOption]);
  const value = useMemo(() => {
    return typeof filterClause === 'string' ? filterClause : filterClause?.value;
  }, [filterClause]);
  const [fixedValueOptions, setFixedValueOptions] = useState<QueryValueOption[] | undefined>();
  const [localTyping, setLocalTyping] = useState('');

  const { params, pluginName } = useContext(ComponentPluginContext);

  // A potentially reduced list of value options to prevent performance issues with
  // react select. Rather than showing all value options, just show the first 50, and
  // update as the user types in the input box (localTyping changes)
  const limitedValueOptions: any = useMemo(() => {
    if (!column.localFilter) return allValueOptions;
    if (localTyping) {
      return allValueOptions.map((option) => {
        return {
          label: option.label,
          options: option?.options
            ?.filter((value: QueryValueOption) => {
              return value?.label?.toLowerCase().includes(localTyping);
            })
            .slice(0, 50),
        };
      });
    }
    return allValueOptions.map((category) => ({
      label: category.label,
      options: category.options.filter((option: any) => !!option.label).slice(0, 50),
    }));
  }, [allValueOptions, localTyping, column.localFilter]);

  const label = useMemo(() => {
    let firstVal: string;
    if (Array.isArray(value) && value.length > 0) {
      firstVal = value[0];
    } else {
      firstVal = typeof value === 'string' ? value : '';
    }

    if (clauseOption?.normalizeLabel) {
      return clauseOption.normalizeLabel(firstVal);
    }
    if (column.formatLabel) {
      return column.formatLabel(firstVal);
    }
    return firstVal;
  }, [value, clauseOption, column]);

  function onFilterChange(val: any, triggeredAction: any) {
    if (triggeredAction.action === 'clear') {
      onAddFilter && !!filterClause && onAddFilter(column?.clauseType, undefined);
    } else {
      const newFilterClause = {
        comparator: QueryFilterComparator.Is,
        type: column?.clauseType,
        value: val?.value.replace(/^\s+|\s+$/g, '') ?? undefined,
      };
      onAddFilter && onAddFilter(column?.clauseType, newFilterClause);
    }
  }

  // Callback function specifically for local filters (filters which do not require
  // backend calls). We search the value options to find which section the value is associated
  // with (e.g. ASN, country, org etc.) and pass that on to onAddFilter
  function onLocalFilterChange(val: any) {
    let associatedSection = undefined;
    if (valueOptions && valueOptions?.length > 1 && val != null) {
      associatedSection = valueOptions.find((section) => {
        return !!section.options.some((option: any) => {
          return option?.value === val?.value;
        });
      })?.label;
    }
    onAddFilter(val, associatedSection, column);
  }

  function updateValueOptions(input: string) {
    setLocalTyping(input.toLowerCase());
  }

  // Load any fixed options value options once; rerenders on clauseoption change
  // Done to prevent the use of defaultOptions prop in Select, since it does not
  // allow filtering with typing; AsyncSelect is unnecessary
  useEffect(() => {
    async function loadFixed() {
      const options = await clauseOption.loadFixedValueOptions(
        '',
        undefined,
        params?.assetValue
          ? `{'${getAssetTypeFromPlugin(pluginName)}': '${params?.assetValue}'}`
          : undefined,
      );
      setFixedValueOptions(options);
    }
    if (clauseOption?.loadFixedValueOptions && !fixedValueOptions) {
      loadFixed();
    }
  }, [clauseOption, params, pluginName, fixedValueOptions]);

  return (
    <div
      style={{ top: '0%', width: '90%', maxWidth: '250px' }}
      className="uk-flex uk-position-relative"
    >
      <div style={{ width: '100%' }}>
        {clauseOption && clauseOption?.loadValueOptions ? (
          <AsyncCreatableSelect
            loadOptions={clauseOption.loadValueOptions}
            placeholder={placeholder}
            value={filterClause ? { label, value } : undefined}
            onChange={onFilterChange}
            isClearable={true}
            noOptionsMessage={(input) => placeholder ?? ''}
            styles={reactSelectColumnFilterStyle() as StylesConfig}
            components={{ DropdownIndicator, ClearIndicator }}
          />
        ) : clauseOption && clauseOption?.loadFixedValueOptions ? (
          <Select
            options={fixedValueOptions}
            placeholder={placeholder}
            value={filterClause ? { label, value } : undefined}
            onChange={onFilterChange}
            isClearable={true}
            noOptionsMessage={(input) => placeholder ?? ''}
            styles={reactSelectColumnFilterStyle() as StylesConfig}
            components={
              showCount
                ? { Option, DropdownIndicator, ClearIndicator }
                : { DropdownIndicator, ClearIndicator }
            }
          />
        ) : (
          <CreatableSelect
            placeholder={placeholder}
            options={limitedValueOptions}
            onInputChange={updateValueOptions}
            value={filterClause ? { label, value } : undefined}
            onChange={column.localFilter ? onLocalFilterChange : onFilterChange}
            components={
              showCount
                ? { Option, DropdownIndicator, ClearIndicator }
                : { DropdownIndicator, ClearIndicator }
            }
            isClearable={true}
            styles={reactSelectColumnFilterStyle() as StylesConfig}
            noOptionsMessage={(input) => placeholder ?? ''}
          />
        )}
      </div>
    </div>
  );
}

const Option = (props: any) => {
  const docStyle = getComputedStyle(document.documentElement);
  const neutralColor = docStyle.getPropertyValue('--app-neutral-color');

  return (
    <div className="app-tag-wrapper">
      <components.Option {...props}>
        <div className="uk-flex app-align-center">
          <label>{props.label}</label>
          {props?.data?.count && (
            <div
              className="uk-float-right"
              style={{ marginLeft: 'auto', color: neutralColor, paddingLeft: '8px' }}
            >
              {abbreviatedCount(props?.data?.count)}
            </div>
          )}
        </div>
      </components.Option>
    </div>
  );
};
