const docStyle = getComputedStyle(document.documentElement);

export const decadColors = [
  docStyle.getPropertyValue('--app-decad-color1'),
  docStyle.getPropertyValue('--app-decad-color2'),
  docStyle.getPropertyValue('--app-decad-color3'),
  docStyle.getPropertyValue('--app-decad-color4'),
  docStyle.getPropertyValue('--app-decad-color5'),
  docStyle.getPropertyValue('--app-decad-color6'),
  docStyle.getPropertyValue('--app-decad-color7'),
  docStyle.getPropertyValue('--app-decad-color8'),
  docStyle.getPropertyValue('--app-decad-color9'),
  docStyle.getPropertyValue('--app-decad-color10'),
];

// Change ordering for better contrast on asset histograms
export const histogramColors = [
  docStyle.getPropertyValue('--app-decad-color1'),
  docStyle.getPropertyValue('--app-decad-color3'),
  docStyle.getPropertyValue('--app-decad-color5'),
  docStyle.getPropertyValue('--app-decad-color7'),
  docStyle.getPropertyValue('--app-decad-color9'),
  docStyle.getPropertyValue('--app-decad-color2'),
  docStyle.getPropertyValue('--app-decad-color4'),
  docStyle.getPropertyValue('--app-decad-color6'),
  docStyle.getPropertyValue('--app-decad-color8'),
  docStyle.getPropertyValue('--app-decad-color10'),
];
