import React, { Fragment, useContext, useMemo } from 'react';
import { useASNQuery } from 'hooks';
import ReportTitle from 'components/ReportTitle';
import { ComponentPluginName, DisplayElement } from 'components';
import { AssetInfoContext } from 'appContexts';
import { UserInvestigationSection } from 'model';
import { toInvestigationSectionId } from 'utils';
import InvestigationSection from '../investigations/InvestigationSection';

export default function ASInfo({ assetValue }: { assetValue: string }) {
  const { asset } = useASNQuery(assetValue);
  const assetName = `AS${assetValue} ${asset?.org?.orgName}`;
  const { displayElements } = useContext(AssetInfoContext);
  const section: UserInvestigationSection = useMemo(
    () => ({
      id: toInvestigationSectionId(ComponentPluginName.ASNAbout, { assetValue, assetName }),
      title: 'About',
      description: '',
    }),
    [assetValue, assetName],
  );
  return (
    <Fragment>
      {displayElements?.includes(DisplayElement.Title) && (
        <ReportTitle
          title={`AS${assetValue}`}
          subtitle={asset?.org?.orgName}
          label="Autonomous System"
        />
      )}
      {asset && (
        <Fragment>
          {displayElements?.includes(DisplayElement.About) && (
            <InvestigationSection section={section} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
