import React from 'react';
import AssetLink from './AssetLink';

type PrefixProps = {
  asset: Record<string, any>;
};

export default function Prefix({ asset }: PrefixProps) {
  if (!asset) {
    return null;
  }

  const orgName = asset.orgName || asset.org?.orgName;
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <AssetLink value={asset.prefix}>{asset.prefix}</AssetLink>
      {orgName && <span className="uk-margin-small-left app-org-name">{orgName}</span>}
    </span>
  );
}
