import React, { Suspense, useMemo } from 'react';
import { Spinner, filterComponents } from 'components';

export function KeystoneFilters({
  onUpdate,
  filterComponent,
}: { onUpdate: any; filterComponent: string }) {
  const FilterComponent = useMemo(() => {
    if (filterComponent) {
      return filterComponents[filterComponent];
    } else {
      return undefined;
    }
  }, [filterComponent]);

  return (
    <div>
      {FilterComponent && (
        <Suspense fallback={<Spinner ratio={2} position="center" />}>
          <FilterComponent params={{ onUpdate }} />
        </Suspense>
      )}
    </div>
  );
}
