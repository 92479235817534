import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ReactComponent as TilesIcon } from 'svg/experimental/view-mode.svg';
import { ReactComponent as CopyIcon } from 'svg/mosaic/mosaic-copy.svg';
import { ReactComponent as ShareIcon } from 'svg/actions/share.svg';
import { ReactComponent as PdfIcon } from 'svg/experimental/pdf.svg';
import { ReactComponent as VariableIcon } from 'svg/experimental/hexagon-outline.svg';
import { ReactComponent as CloseIcon } from 'svg/actions/x-default.svg';
import { getCurrentInvestigation } from 'utils';
import { SidebarContext } from 'appContexts';
import {
  SidebarArchive,
  SidebarCopyInvestigation,
  SidebarShareMenu,
  SidebarTemplateParams,
  SidebarTileEditor,
} from 'components';

export enum SidebarOptions {
  TileList = 'Content',
  TemplateVariables = 'Template Variables',
  Copy = 'Copy',
  Share = 'Sharing',
  Archive = 'PDF',
}

export function Sidebar() {
  const { isTemplate, setActiveOption, activeOption } = useContext(SidebarContext);
  let options: SidebarOptionProps[] = [];
  // The conditions applied to each option here (e.g. !viewOnly) need to be replicated in Investigation.tsx to determine if the shortcut will work to open/close them.
  options.push({
    activeOption,
    Icon: TilesIcon,
    MenuComponent: SidebarTileEditor,
    option: SidebarOptions.TileList,
    selectOption: setActiveOption,
  });
  if (isTemplate) {
    options.push({
      activeOption,
      Icon: VariableIcon,
      MenuComponent: SidebarTemplateParams,
      option: SidebarOptions.TemplateVariables,
      selectOption: setActiveOption,
    });
  }
  options.push(
    {
      activeOption,
      Icon: CopyIcon,
      MenuComponent: SidebarCopyInvestigation,
      option: SidebarOptions.Copy,
      selectOption: setActiveOption,
    },
    {
      activeOption,
      Icon: ShareIcon,
      MenuComponent: SidebarShareMenu,
      option: SidebarOptions.Share,
      selectOption: setActiveOption,
    },
  );
  if (!isTemplate) {
    options.push({
      activeOption,
      Icon: PdfIcon,
      MenuComponent: SidebarArchive,
      option: SidebarOptions.Archive,
      selectOption: setActiveOption,
    });
  }

  // We can't rely solely SCSS to handle our breakpoint calculations for the max height of the menu content since the position of the menu's icon in the sidebar also determines the available height left, so we will calculate it manually
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getMaxHeightForIndex(index: number) {
    if (windowDimensions.height >= 875) {
      return 625 - index * 32;
    } else {
      return windowDimensions.height - 875 + 590 - index * 32;
    }
  }

  return (
    <div className="sidebar hide-in-pdf">
      {options.map((option, index) => {
        // insert breakpoint logic and index considerations here
        const maxHeightPx = getMaxHeightForIndex(index);
        return (
          <SidebarOption {...option} maxHeightPx={maxHeightPx} key={`sidebarOption-${index}`} />
        );
      })}
    </div>
  );
}

type SidebarOptionProps = {
  activeOption: SidebarOptions;
  Icon: any;
  MenuComponent: any;
  option: SidebarOptions;
  selectOption: Function;
  maxHeightPx?: number;
};

function SidebarOption({
  activeOption,
  Icon,
  MenuComponent,
  option,
  selectOption,
  maxHeightPx = 625,
}: SidebarOptionProps) {
  // isActive is used to determine any added classes to the sidebar-menu div to aid with animation. We also use isActive to mount/dismount.
  const isActive = activeOption === option;

  return (
    <div
      className={`sidebar-option ${isActive ? 'active' : ''}`}
      data-testid={`sidebar-option-${option}`}
    >
      <Icon
        className="sidebar-icon"
        onClick={() => {
          selectOption(option);
        }}
      />
      <div className={`sidebar-menu ${isActive ? 'visible' : 'hidden'}`}>
        {isActive && (
          <Fragment>
            <div className="sidebar-menu-header uk-text-bold">
              <div className="sidebar-menu-title">{option}</div>
              <CloseIcon
                className="sidebar-menu-close cursor-pointer"
                onClick={() => {
                  selectOption();
                }}
              />
            </div>
            <div style={{ marginTop: '32px' }}>
              <MenuComponent
                investigation={getCurrentInvestigation()}
                selectOption={selectOption}
                maxHeightPx={maxHeightPx}
              />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}
