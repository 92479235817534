import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DataSlicesContext, KebabContext, SidebarContext } from 'appContexts';
import { ReactComponent as ShareLinkIcon } from 'svg/misc/link.svg';
import {
  copyShareLink,
  createCrossInstanceParams,
  createUniqueInvestigationSectionId,
  exportJsonToFile,
  getDefaultTitle,
  getFullAnchorLinkIds,
  getVersion,
  isAdvancedSearch,
  isViewOnly,
  paramsToSearchParams,
  parseInvestigationSectionId,
  saveInvestigation,
  sectionContainsDatasetReference,
  SHARE_MUTATION,
} from 'utils';
import { ReactComponent as Checkmark } from 'svg/actions/checkmark-default.svg';
import {
  acknowledgeNotification,
  ALERT_TYPES,
  createGenericNotification,
  userDatasetsVar,
  UserInvestigation,
  UserInvestigationSection,
  userSharedDatasetsVar,
} from 'model';
import { Banner, ExpandableKebabComponent, RadioCard, Spinner } from 'components';
import { useHistory } from 'react-router-dom';
import { useMutation, useReactiveVar } from '@apollo/client';

export function ShareLinkKebabMenu({ data }: ExpandableKebabComponent) {
  const { returnKebabMain, closeKebabDropdown } = useContext(KebabContext);
  const { metadata } = useContext(DataSlicesContext);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const copyTimerId = useRef<number | undefined>();
  const [shareType, setShareType] = useState<string>('local');
  const section = data?.section ?? (isAdvancedSearch() ? data?.sections[0] : undefined);
  const [crossInstanceParams, setCrossInstanceParams] = useState(() => {
    if (!isAdvancedSearch() || !section) return undefined;
    const [, params] = parseInvestigationSectionId(section.id);
    if (sectionContainsDatasetReference(params)) return undefined;
    return { ...params, metadata: { platformVersion: getVersion() } };
  });
  const [crossInstanceProgress, setCrossInstanceProgress] = useState<string>('');
  const [crossInstanceLoading, setCrossInstanceLoading] = useState(false);
  const [shareMosaic, { data: shareData, error: shareError, loading: shareLoading }] =
    useMutation(SHARE_MUTATION);
  const sharedKey = shareData?.shareUserData?.sharedKey;
  const history = useHistory();
  const datasets = useReactiveVar(userDatasetsVar);
  const sharedDatasets = useReactiveVar(userSharedDatasetsVar);
  const { investigation } = useContext(SidebarContext);

  const investigationObj = useMemo(() => investigation ?? data, [investigation, data]);

  const crossInstanceWarningExists: boolean = useMemo(() => {
    return crossInstanceProgress.startsWith('Warning');
  }, [crossInstanceProgress]);

  const isShared = useMemo(() => {
    return !!investigationObj?.sharing?.shared_key || sharedKey;
  }, [investigationObj, sharedKey]);

  async function waitForCrossInstanceCreation() {
    setCrossInstanceLoading(true);
    setCrossInstanceProgress('');
    return await createCrossInstanceParams(
      investigationObj,
      { ...datasets, ...sharedDatasets },
      setCrossInstanceProgress,
      section,
      metadata,
    );
  }

  const shareLink = useMemo(() => {
    let anchorUrl: URL;
    if (isAdvancedSearch()) {
      anchorUrl = new URL(
        `${window.location.origin}/advanced-search?${paramsToSearchParams(crossInstanceParams)}`,
      );
    } else {
      const key = sharedKey ?? investigationObj?.sharing?.shared_key;
      anchorUrl = isViewOnly()
        ? new URL(window.location.href)
        : new URL(`${window.location.origin}/shared?key=${key}`);
      if (section) {
        const section_uuid = parseInvestigationSectionId(section.id)[1].uuid;
        anchorUrl.hash = getFullAnchorLinkIds(
          investigationObj?.sections,
          investigationObj?.description,
          investigationObj?.sections?.findIndex((s: UserInvestigationSection) => {
            const compare_uuid = parseInvestigationSectionId(s.id)[1].uuid;
            return compare_uuid === section_uuid;
          }),
        );
      }
    }
    return shareType === 'local'
      ? anchorUrl.href
      : btoa(encodeURIComponent(JSON.stringify(crossInstanceParams)));
  }, [section, crossInstanceParams, shareType, sharedKey, investigationObj]);

  // Revert copied styling after a couple of seconds
  useEffect(() => {
    if (isCopied) {
      copyTimerId.current = window.setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [isCopied]);

  // Handle share success or errors
  useEffect(() => {
    if (shareError) {
      const shareErrorNotificationName = 'share-mosaic-error';
      acknowledgeNotification(shareErrorNotificationName); // Acknowledge previous share error notifications
      createGenericNotification(shareErrorNotificationName, {
        alertType: ALERT_TYPES.error,
        message: (
          <Fragment>
            Failed to share the mosaic,{' '}
            <span className="uk-text-bold uk-text-danger">{investigationObj.title}</span>. Please
            try again.
          </Fragment>
        ),
        title: 'Share Failed',
        onClear: () => acknowledgeNotification(shareErrorNotificationName),
      });
    }
  }, [shareError, investigationObj.title]);

  const showAdvancedSearchBanner = useMemo(() => {
    if (!isAdvancedSearch() || !section) return false;
    const [, params] = parseInvestigationSectionId(section.id);
    return (
      shareLink.length > 2048 || sectionContainsDatasetReference(params) || !crossInstanceParams
    );
  }, [shareLink, section, crossInstanceParams]);

  const onCreateMosaicAndGo = useCallback(() => {
    // First, add a unique id to the section id
    const [pluginName, params] = parseInvestigationSectionId(section.id);
    const newId = createUniqueInvestigationSectionId(pluginName, params);
    const investigation: UserInvestigation = {
      sections: [{ ...section, id: newId }],
      title: getDefaultTitle(),
      subtitle: '',
      description: '',
    };
    closeKebabDropdown();
    // Copy search to a new mosaic
    saveInvestigation(investigation);
    const shortID = (investigation.id as string).split(':')[1];
    history.push(`/mosaic?id=${shortID}`);
  }, [section, history, closeKebabDropdown]);

  const isUnsharedPrivateMosaic =
    investigationObj && !isViewOnly() && !isShared && !isAdvancedSearch() && shareType === 'local';

  return (
    <Fragment>
      <div className="uk-flex uk-flex-middle uk-margin-medium-bottom">
        <button onClick={() => returnKebabMain()} className="tile-menu-back">
          <div uk-icon="icon: chevron-left;" />
          Tile Menu
        </button>
      </div>
      <div>
        {(isAdvancedSearch() || !section) && (
          <div className="uk-flex uk-margin-medium-bottom uk-margin-medium-top">
            <RadioCard
              style={{ marginRight: '16px', width: '50%' }}
              title={'LOCAL'}
              description={'Create a URL that can be shared between users of this instance'}
              onSelect={() => {
                if (shareType !== 'local') {
                  clearTimeout(copyTimerId.current);
                  setIsCopied(false);
                }
                setShareType('local');
              }}
              selected={shareType === 'local'}
            />
            <RadioCard
              style={{ width: '50%' }}
              title={'CROSS-INSTANCE'}
              description={'Download a file that can be shared across instances'}
              onSelect={() => {
                if (shareType !== 'cross-instance') {
                  clearTimeout(copyTimerId.current);
                  setIsCopied(false);
                }
                setShareType('cross-instance');
              }}
              selected={shareType === 'cross-instance'}
            />
          </div>
        )}
        {shareLoading ? (
          <Spinner ratio={1} />
        ) : isUnsharedPrivateMosaic ? (
          <button
            className="sidebar-button sharing-button uk-margin-remove-top"
            onClick={() => shareMosaic({ variables: { key: investigationObj.id } })}
          >
            {'Create Local Share Link'}
          </button>
        ) : shareType === 'local' ? (
          showAdvancedSearchBanner ? (
            <Banner
              title={'Unable to create quick link'}
              style={{ marginBottom: '0' }}
              message={
                <div className="uk-flex">
                  This search must be saved as a mosaic before it can be shared. Save search as
                  mosaic?
                  <button
                    style={{ minWidth: '60px' }}
                    onClick={onCreateMosaicAndGo}
                    className="uk-button uk-button-default uk-margin-left"
                  >
                    Go
                  </button>
                </div>
              }
              alertType={ALERT_TYPES.warning}
              noClose={true}
            />
          ) : (
            <div className="uk-flex">
              <input
                className={`uk-input ${isCopied ? 'copy-clipboard-input-copied' : 'copy-clipboard-input'}`}
                type="text"
                value={
                  showAdvancedSearchBanner
                    ? 'Unable to generate link. Create a mosaic first to share.'
                    : shareLink
                }
                disabled={showAdvancedSearchBanner}
                readOnly
              />
              <button
                className={`${isCopied ? 'copy-clipboard-button-copied' : 'copy-clipboard-button'}`}
                disabled={showAdvancedSearchBanner}
                onClick={() => {
                  copyShareLink(shareLink);
                  setIsCopied(true);
                }}
              >
                {isCopied ? <Checkmark style={{ border: 'none' }} /> : <ShareLinkIcon />}
              </button>
            </div>
          )
        ) : crossInstanceWarningExists ? (
          <Banner alertType={ALERT_TYPES.error} noClose={true} message={crossInstanceProgress} />
        ) : (
          <button
            className="sidebar-button sharing-button uk-margin-remove-top"
            disabled={crossInstanceLoading || crossInstanceWarningExists}
            onClick={() => {
              waitForCrossInstanceCreation().then((result) => {
                setCrossInstanceLoading(false);
                setCrossInstanceParams(result);
                exportJsonToFile(result, section?.title ?? investigationObj?.title);
              });
            }}
          >
            {crossInstanceLoading && !crossInstanceWarningExists ? (
              <div>
                {<Spinner ratio={0.55} style={{ padding: '0', marginRight: '8px' }} />}
                {Math.ceil(parseFloat(crossInstanceProgress))}%
              </div>
            ) : (
              'Download File'
            )}
          </button>
        )}
      </div>
    </Fragment>
  );
}
