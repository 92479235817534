import React, { MutableRefObject, ReactNode, forwardRef, useEffect, useState } from 'react';
import { MapContainer, useMap, useMapEvent, useMapEvents } from 'react-leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import { v4 } from 'uuid';
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

export type Viewport = {
  center?: [number, number];
  zoom?: number;
};

export type ViewportChangeHandler = (viewport: Viewport) => void;
export type Handler = () => void;

type GeoMapContainerProps = {
  viewport: Record<string, any>;
  onViewportChanged?: ViewportChangeHandler;
  onClick?: Handler;
  whenReady?: Handler;
  minZoom?: number;
  ref?: MutableRefObject<any>;
  children?: ReactNode;
};

export const GeoMapContainer = forwardRef<any, GeoMapContainerProps>((props, ref) => {
  const { viewport, onViewportChanged, minZoom, whenReady, onClick, children } = props;

  const [containerId] = useState(`map-container-${v4()}`);

  return (
    <MapContainer
      ref={ref}
      attributionControl={false}
      id={containerId}
      className="map"
      zoomControl={false}
      dragging={true}
      touchZoom={false}
      doubleClickZoom={false}
      center={viewport.center}
      zoom={viewport.zoom}
      minZoom={minZoom}
      whenReady={whenReady}
    >
      <SizeSetter containerId={containerId} />
      <GestureHandlingSetter />
      <ViewportHandler viewport={viewport} onViewportChanged={onViewportChanged} />
      {onClick ? <ClickEventHandler onClick={onClick} /> : null}
      {children}
    </MapContainer>
  );
});

// Invalidate size of map if width/height is 0. Mainly issue in mosaics where investigation-wrapper width is initially 0.
const SizeSetter = ({ containerId }: { containerId: string }) => {
  const map = useMap() as any;

  if (!map._sizeListenerInitialized) {
    const resizeObserver = new ResizeObserver(() => {
      // Only invalidate size if width or height is 0
      if (map?._size?.x === 0 || map?._size?.y === 0) {
        map.invalidateSize();
      }
    });
    const container = document.getElementById(containerId);
    if (container) {
      resizeObserver.observe(container);
    }

    map._sizeListenerInitialized = true;
  }
  return null;
};

function GestureHandlingSetter() {
  const map = useMap() as any;
  if (!map._gesterHandlingInitialized) {
    map.addHandler('gestureHandling', GestureHandling);
    map.gestureHandling.enable();
    map._gesterHandlingInitialized = true;
  }
  return null;
}

function ViewportHandler({
  viewport,
  onViewportChanged,
}: { viewport: Viewport; onViewportChanged?: ViewportChangeHandler }) {
  const map = useMap() as any;

  useEffect(() => {
    map.setView(viewport.center, viewport.zoom, { animate: false });
  }, [map, viewport]);

  function callOnViewportChanged() {
    const center = [map.getCenter().lat, map.getCenter().lng] as [number, number];
    onViewportChanged({ center, zoom: map.getZoom() });
  }

  useMapEvents({
    zoomend: callOnViewportChanged,
    moveend: callOnViewportChanged,
  });

  return null;
}

function ClickEventHandler({ onClick }: { onClick?: Handler }) {
  useMapEvent('click', onClick);
  return null;
}
