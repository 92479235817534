import { useReactiveVar } from '@apollo/client';
import { PinTag, UserInvestigation, userInvestigationsVar } from 'model';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  InvestigationTypes,
  deleteInvestigation,
  generatePdf,
  getDefaultTitle,
  havePdfService,
  saveInvestigation,
  loadMosaicTableOptions,
  curateMosaicTableData,
} from 'utils';
import {
  DeleteMosaicKebabMenu,
  Kebab,
  KebabOption,
  ManagerTableFilter,
  ManagerTable,
  Modal,
  Spinner,
  ShareLinkKebabMenu,
} from 'components';
import { countBy, flatten, orderBy } from 'lodash';
import { ReactComponent as CheckIcon } from 'svg/actions/checkmark-fill.svg';
import { ReactComponent as MosaicIcon } from 'svg/mosaic/mosaic-default-filled.svg';
import { ReactComponent as TemplateIcon } from 'svg/mosaic/mosaic-default-outline.svg';
import { ReactComponent as CollectionsIcon } from 'svg/experimental/museum-positive.svg';
import { ReactComponent as DownloadIcon } from 'svg/system/transfer-down.svg';
import { ReactComponent as TrashIcon } from 'svg/actions/trash.svg';
import { ReactComponent as EyeIcon } from 'svg/actions/eye-open.svg';
import { ReactComponent as ShareIcon } from 'svg/actions/share.svg';
import { ReactComponent as StarFilledIcon } from 'svg/misc/star-filled.svg';
import { ReactComponent as StarEmptyIcon } from 'svg/misc/star-empty.svg';
import { Row } from 'react-table';
import useResizeObserver from 'use-resize-observer';
import {
  kebabItem,
  modifiedItem,
  mosaicPinItem,
  mosaicTagsItem,
  mosaicTitleItem,
  templateTitleItem,
} from 'components/TableItems';
import { useMosaicSavedSort, useMosaicTitleSort } from 'hooks';

const collectionTypes: Record<string, any> = {
  mosaics: { label: 'Mosaics', icon: <MosaicIcon style={{ width: '20px', height: '20px' }} /> },
  templates: {
    label: 'Templates',
    icon: <TemplateIcon style={{ width: '20px', height: '20px' }} />,
  },
};

export function Collections() {
  const investigations = useReactiveVar(userInvestigationsVar);
  const history = useHistory();
  const [downloading, setDownloading] = useState<Set<string>>(new Set());
  const [filterOption, setFilterOption] = useState<any>();
  const { search } = useLocation();
  const searchParams = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
  const searchParamPage = searchParams.get('page');
  const searchParamType = searchParams.get('type');
  const [collection, setCollection] = useState(searchParamType ?? 'mosaics');
  const [pageNumber, setPageNumber] = useState<number>(Number(searchParamPage));
  const [forcePageReset, setForcePageReset] = useState(false);
  const clickTimeout = useRef<any>();
  const tabsRef = useRef<HTMLUListElement>(null);
  const [switcherID] = useState<string>(() => searchParamType ?? 'mosaics');
  const { ref, width } = useResizeObserver<HTMLDivElement>();
  const onMosaicTitleSort = useMosaicTitleSort();
  const onMosaicSavedSort = useMosaicSavedSort();

  // Update page number
  useEffect(() => {
    setPageNumber(searchParamPage != null ? Number(searchParamPage) : 1);
  }, [searchParamPage]);

  // Update type
  useEffect(() => {
    setCollection(searchParamType ?? 'mosaics');
  }, [searchParamType]);

  const collectionString: InvestigationTypes = useMemo(() => {
    return collection === 'mosaics' ? InvestigationTypes.Mosaic : InvestigationTypes.Template;
  }, [collection]);

  const viewCallback = useCallback(
    (id: string) => {
      const shortID = id.split(':')[1];
      history.push(`/${collection === 'mosaics' ? 'mosaic' : 'template'}?id=${shortID}`);
    },
    [history, collection],
  );

  const downloadCallback = useCallback(
    (id: string) => {
      const investigation = investigations[id];
      generatePdf({
        investigation,
        setDownloading: (isDownloading: boolean) =>
          setDownloading((current) => {
            const newDownloading = new Set(current);
            isDownloading ? newDownloading.add(id) : newDownloading.delete(id);
            return newDownloading;
          }),
      });
    },
    [investigations],
  );

  const createNewMosaic = useCallback(
    (isTemplate: boolean) => {
      const investigation: UserInvestigation = {
        sections: [],
        title: getDefaultTitle(isTemplate),
        subtitle: '',
        description: '',
        isTemplate,
        templateParams: [],
      };

      saveInvestigation(investigation);

      const shortID = (investigation.id as string).split(':')[1];
      history.push(`/${collection === 'mosaics' ? 'mosaic' : 'template'}?id=${shortID}`);
    },
    [history, collection],
  );

  const deleteCallback = useCallback(
    (id: string, preventModal?: boolean) => {
      const investigation = investigations[id];
      if (!preventModal) {
        Modal.show({
          title: `Delete ${collectionString}>`,
          body: (
            <span>
              Are you sure you want to delete
              <span className="uk-text-bold">
                {' '}
                {` ${investigation?.title ?? collectionString}`}
              </span>
              ?
            </span>
          ),
          action: {
            label: 'Delete',
            onClick: () => deleteInvestigation(investigation),
            cancelable: true,
          },
        });
      } else {
        deleteInvestigation(investigation);
      }
    },
    [investigations, collectionString],
  );

  const onPinClick = useCallback(
    (event, investigationId) => {
      const investigation = investigations[investigationId];

      // Clear click timeout
      if (clickTimeout) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }

      // Single Click - Pin or Un-Pin Mosaic
      if (event.detail === 1) {
        clickTimeout.current = window.setTimeout(() => {
          if (investigation.pinTag) {
            investigation.pinTag = null;
          } else {
            investigation.pinTag = PinTag.Pinned;
          }
          saveInvestigation(investigation, true);
        }, 250);
      }
      // Double Click - Priority Pin Mosaic
      if (event.detail % 2 === 0) {
        investigation.pinTag = PinTag.PriorityPinned;
        saveInvestigation(investigation, true);
      }
    },
    [investigations],
  );

  const columns: any = useMemo(() => {
    const ellipsisOptions: KebabOption[] = [
      { label: 'View & Edit', callback: viewCallback, icon: <EyeIcon /> },
      { label: 'Delete', icon: <TrashIcon />, expandComponent: <DeleteMosaicKebabMenu /> },
      { label: 'Share', icon: <ShareIcon />, expandComponent: <ShareLinkKebabMenu backButton /> },
    ];
    if (collection !== 'templates') {
      if (havePdfService()) {
        ellipsisOptions.push({
          label: 'Download PDF',
          icon: <DownloadIcon />,
          callback: downloadCallback,
        });
      }
    }

    return [
      {
        ...mosaicPinItem,
        Cell: ({ row }: any) => {
          const { pinTag } = row?.original;

          if (pinTag) {
            return (
              <span
                className="manager-table-pin"
                onClick={(event) => {
                  event.stopPropagation();
                  onPinClick(event, row?.original?.id);
                }}
              >
                <StarFilledIcon />
              </span>
            );
          }
          return (
            <span
              className="manager-table-pin manager-table-pin-empty"
              onClick={(event) => {
                event.stopPropagation();
                onPinClick(event, row?.original?.id);
              }}
            >
              <StarEmptyIcon />
            </span>
          );
        },
      },
      {
        ...(collection === 'mosaics' ? mosaicTitleItem : templateTitleItem),
        sortType: onMosaicTitleSort,
      },
      mosaicTagsItem,
      {
        Header: 'Shared',
        accessor: 'sharing',
        width: 100,
        align: 'right',
        disableSortBy: true,
        Cell: ({ cell }: any) => {
          return cell?.value?.shared_key ? (
            <span className="app-icon-primary">
              <CheckIcon height={17} />
            </span>
          ) : null;
        },
      },
      {
        ...modifiedItem,
        sortType: onMosaicSavedSort,
      },
      {
        ...kebabItem,
        Cell: ({ cell, row }: any) =>
          downloading.has(cell?.value) ? (
            <Spinner ratio={0.5} style={{ padding: '2px' }} />
          ) : (
            <Kebab
              options={{ Actions: ellipsisOptions }}
              data={row?.original}
              className="manager-table-button"
            />
          ),
      },
    ];
  }, [
    viewCallback,
    onMosaicTitleSort,
    onMosaicSavedSort,
    downloadCallback,
    onPinClick,
    downloading,
    collection,
  ]);

  const formatRowCallback = useCallback(
    (row: any) => {
      return {
        className: `cursor-pointer ${downloading.has(row.original.id) ? 'row-disabled' : ''}`,
      };
    },
    [downloading],
  );

  const onTabClick = useCallback(
    (newTab: string) => {
      if (!(collection === newTab)) {
        setCollection(newTab);
        setForcePageReset(true);
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set('type', newTab);
        urlSearchParams.set('page', '1');
        history.push(`collections?${urlSearchParams.toString()}`);
      }
    },
    [collection, history],
  );

  const filterOptions = useMemo(() => {
    const tags = flatten(
      Object.values(investigations)
        ?.filter((investigation: any) =>
          collection === 'mosaics' ? !investigation?.isTemplate : investigation?.isTemplate,
        )
        ?.map((investigation: UserInvestigation) => investigation.tags),
    ).filter((tag) => tag);
    const tagBuckets = countBy(tags, 'name');
    const options = Object.keys(tagBuckets).map((key: string) => {
      return { label: key, value: key, count: tagBuckets[key] };
    });
    const titleOptions = flatten(
      Object.values(investigations)
        ?.filter((investigation: UserInvestigation) =>
          collection === 'mosaics' ? !investigation.isTemplate : investigation.isTemplate,
        )
        ?.map((investigation: UserInvestigation) => investigation),
    )
      .filter((investigation) => investigation.title)
      .map((investigation) => {
        return {
          label: investigation.title,
          value: investigation.title.toLowerCase(),
          url: `/${investigation.isTemplate ? 'template' : 'mosaic'}?id=${investigation.id}`,
        };
      });

    return [
      { label: 'Tags', options: orderBy(options, 'count', 'desc') },
      { label: collectionString + 's', options: orderBy(titleOptions, 'value', 'asc') },
    ];
  }, [investigations, collectionString, collection]);

  const data = useMemo(() => {
    return curateMosaicTableData(filterOption, investigations, collection);
  }, [filterOption, investigations, collection]);

  const sortBy = [{ id: 'saved', desc: true }];

  const hiddenColumns = useMemo(() => {
    return width && width < 1024 ? ['tags'] : [];
  }, [width]);

  const tagOptions = useMemo(() => {
    const tags = flatten(
      Object.values(investigations)
        ?.filter((investigation: UserInvestigation) =>
          collection === 'mosaics' ? !investigation?.isTemplate : investigation?.isTemplate,
        )
        ?.map((investigation: UserInvestigation) => investigation?.tags),
    ).filter((tag) => tag);
    const tagBuckets = countBy(tags, 'name');
    return Object.keys(tagBuckets).map((key: string) => {
      return { label: key, value: key, count: tagBuckets[key] };
    });
  }, [investigations, collection]);

  const loadCollections = useCallback(
    async (newVal) => {
      return loadMosaicTableOptions(
        newVal,
        tagOptions,
        Object.values(investigations),
        collectionString,
        false,
      );
    },
    [tagOptions, investigations, collectionString],
  );

  const onUpdatePageURL = useCallback(
    (searchString: string) => {
      history.push(`collections?${searchString}`);
    },
    [history],
  );

  const tabOptions = Object.keys(collectionTypes).map((type) => ({
    icon: collectionTypes[type].icon,
    label: `My ${collectionTypes[type].label}`,
    value: type,
  }));

  return (
    <Fragment>
      <div ref={ref} className="uk-align-center data-manager">
        <div
          className="app-mosaic-table-header app-text-bold uk-flex uk-flex-middle"
          data-testid="collections-header"
        >
          <CollectionsIcon />
          <span className="uk-margin-left">Collections</span>
        </div>
        <div className="uk-margin-small-top uk-margin-medium-bottom uk-text-muted app-width-mobile">
          Private mosaics and templates
        </div>
        <div style={{ width: '100%' }} className="uk-margin-medium-bottom uk-flex uk-flex-between">
          <ul
            ref={tabsRef}
            data-uk-switcher={`connect: #${switcherID}`}
            className="uk-tab uk-margin-remove-bottom uk-margin-remove-top disabled-tabs"
            style={{ display: 'inline-flex', flexWrap: 'nowrap', justifyContent: 'space-evenly' }}
          >
            {tabOptions.map((contentObj: any) => {
              return (
                <li
                  key={contentObj.value}
                  className={contentObj.value === collection ? 'uk-active' : ''}
                >
                  <a onClick={() => onTabClick(contentObj.value)} className="uk-text-capitalize">
                    <div className="uk-flex">
                      {contentObj.icon}
                      <div className="uk-margin-small-left">{contentObj.label}</div>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="uk-float-right uk-flex">
            <button
              style={{ whiteSpace: 'nowrap' }}
              className="uk-button uk-button-default uk-margin-medium-right"
              onClick={() => createNewMosaic(collection !== 'mosaics')}
            >
              New{' '}
              {collection === 'mosaics' ? InvestigationTypes.Mosaic : InvestigationTypes.Template}
            </button>
            <ManagerTableFilter
              placeholder={`Search for a ${collectionString} or tag`}
              selected={filterOption}
              loadOptions={loadCollections}
              options={filterOptions}
              onChange={(val) => {
                setForcePageReset(true);
                setFilterOption(val);
              }}
            />
          </div>
        </div>
        <ManagerTable
          data={data}
          columns={columns}
          sortBy={sortBy}
          getRowProps={formatRowCallback}
          isDisabled={(row: Row<object>) => downloading.has(row.values.id)}
          onRowClick={(row: Row<object>) => viewCallback(row.values.id)}
          onDelete={deleteCallback}
          toDeleteText={collectionString + 's'}
          hasSelectableRows={true}
          forcePageReset={forcePageReset}
          setForcePageReset={setForcePageReset}
          hiddenColumns={hiddenColumns}
          pageNumber={pageNumber}
          onUpdatePageURL={onUpdatePageURL}
        />
      </div>
    </Fragment>
  );
}
