import { RouterCardsTooltip } from './RouterCardsTooltip';
import { TracerouteCardsTooltip } from './TracerouteCardsTooltip';

export enum Tooltips {
  RouterCards = 'RouterCards',
  TracerouteCards = 'TracerouteCards',
}

export const tooltipComponents = {
  [Tooltips.RouterCards]: RouterCardsTooltip,
  [Tooltips.TracerouteCards]: TracerouteCardsTooltip,
};
