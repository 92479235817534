import { keycloak } from 'keycloak';
import Keycloak from 'keycloak-js';

export function hasAuthentication() {
  return process.env.REACT_APP_AUTHENTICATION === 'enabled';
}

export async function authenticatedFetch(resource: string, init?: Record<string, any>) {
  if (init == null) init = {};
  if (hasAuthentication()) {
    let headers = init.headers || {};
    headers = { ...headers, ...getAuthHeaders() };
    init = { ...init, headers };
  }

  return await fetch(resource, init);
}

export function isAuthorized(keycloak: Keycloak.KeycloakInstance, roles: string[]) {
  if (keycloak && roles) {
    return roles.some((r: string) => {
      const realm = keycloak.hasRealmRole(r);
      const resource = keycloak.hasResourceRole(r);
      return realm || resource;
    });
  }
  return false;
}

function getAuthHeaders() {
  const accessToken = keycloak.token;
  return {
    Authorization: `Bearer ${accessToken}`,
  };
}
