import { gql } from '@apollo/client';
import { useComponentPluginQuery } from 'hooks';
import { parseHostname } from 'utils';

const hostnameQuery = gql`
  query HostnameAboutData($hostSet: [String]!, $filter: String) {
    hostData(hostSet: $hostSet) {
      domainLookup {
        domain
        hostTypes
        answers {
          cnames {
            host
          }
          addresses {
            service
          }
        }
      }
    }
    allCerts: certificateRecords(filter: $filter) {
      totalCount
    }
    certificateRecords(filter: $filter) {
      totalCount
    }
  }
`;

export function useHostnameQuery(assetValue: string) {
  const hostname = assetValue;
  const domain = parseHostname(hostname);

  const {
    data: asset,
    loading,
    error,
  } = useComponentPluginQuery(hostnameQuery, {
    variables: {
      hostSet: [hostname],
      filter: `[{'hosts.raw': ['*.${domain}','${hostname}']}]`,
    },
  });

  return { asset, loading, error };
}
