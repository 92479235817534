import React from 'react';
import { AssetType } from 'model';
import IPInfo from './assetinfo/IPInfo';
import PrefixInfo from './assetinfo/PrefixInfo';
import ASInfo from './assetinfo/ASInfo';
import OrgInfo from './assetinfo/OrgInfo';
import RouterInfo from './assetinfo/RouterInfo';
import CountryInfo from './assetinfo/CountryInfo';
import FacilityInfo from './assetinfo/FacilityInfo';
import IXInfo from './assetinfo/IXInfo';
import DomainInfo from './assetinfo/DomainInfo';
import { guessAssetType, parseASN } from 'utils';
import { AssetInfoContext } from 'appContexts';

const assetComponentMap: Record<AssetType, React.ComponentType<any>> = {
  [AssetType.IP]: IPInfo,
  [AssetType.Prefix]: PrefixInfo,
  [AssetType.AS]: ASInfo,
  [AssetType.Org]: OrgInfo,
  [AssetType.Router]: RouterInfo,
  [AssetType.Country]: CountryInfo,
  [AssetType.Facility]: FacilityInfo,
  [AssetType.IX]: IXInfo,
  [AssetType.Domain]: DomainInfo,
  [AssetType.Hostname]: DomainInfo,
};

export enum DisplayElement {
  Title = 'title',
  About = 'about',
  Details = 'details',
}

export function AssetInfo({
  assetType,
  assetValue,
  displayElements,
}: { assetType?: string; assetValue: string; displayElements: DisplayElement[] }) {
  if (!assetType) {
    assetType = guessAssetType(assetValue);
  }

  if (assetType === AssetType.AS) {
    assetValue = parseASN(assetValue);
  }

  const Component = assetType ? assetComponentMap[assetType as AssetType] : undefined;

  return (
    <AssetInfoContext.Provider value={{ infoMode: true, displayElements }}>
      {Component && <Component assetValue={assetValue} />}
    </AssetInfoContext.Provider>
  );
}
