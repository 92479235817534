import React, { useCallback, useContext, useMemo } from 'react';
import Select, { StylesConfig } from 'react-select';
import { ComponentPluginContext } from 'appContexts';
import { TabbedOptionsByPlugin, getActiveTab } from 'utils';
import { reactSelectDropdownStyle, DropdownIndicator } from 'components/index';

export default function TabbedContentConfig({ options }: { options?: any }) {
  const { updatePluginConfig, getPluginConfig, pluginName } = useContext(ComponentPluginContext);

  const tabOptions = useMemo(() => {
    return (
      options ??
      TabbedOptionsByPlugin[pluginName]?.map((option) => ({
        label: option.label,
        value: option.name,
      }))
    );
  }, [options, pluginName]);

  const currentTab = useMemo(() => {
    const value = getActiveTab(
      options
        ? options.map((option) => ({ label: option.label, name: option.value, content: undefined }))
        : TabbedOptionsByPlugin[pluginName] ?? [],
      getPluginConfig(),
      false,
    );
    return { value, label: tabOptions?.find((option) => option.value === value).label };
  }, [options, pluginName, getPluginConfig, tabOptions]);

  const onTabChange = useCallback(
    (newTab) => {
      if (newTab.value !== currentTab) {
        updatePluginConfig({ ...getPluginConfig(), tab: newTab.value });
      }
    },
    [currentTab, updatePluginConfig, getPluginConfig],
  );

  return (
    <div className="config-option hide-in-pdf">
      <div>Tab</div>
      <div className="ib-section-width-4">
        <Select
          options={tabOptions as any}
          styles={reactSelectDropdownStyle() as StylesConfig}
          value={currentTab}
          onChange={onTabChange}
          menuPortalTarget={document.body}
          components={{ DropdownIndicator }}
        />
      </div>
    </div>
  );
}
