import React from 'react';
import { components } from 'react-select';

export function TemplateMultiValue({ children, ...props }: any) {
  return (
    <components.MultiValue {...props} className="template-param-multi-value">
      {children}
    </components.MultiValue>
  );
}
