import IPAbout from 'components/plugins/ip/IPAbout';
import React, { useMemo } from 'react';
import {
  AttributeTable,
  ComponentPluginName,
  TabbedContent,
  TabbedContentOption,
} from 'components';
import { get } from 'lodash';

function IpSection({ ip, type }: { ip: string; type: string }) {
  return (
    <div className="uk-margin-medium-bottom uk-margin-large-right">
      <div style={{ color: '#B3B3B3' }} className="app-subtitle uk-flex uk-margin-medium-bottom">
        {type} IP
      </div>
      <IPAbout
        title={ip}
        pluginName={ComponentPluginName.IPAbout}
        params={{
          assetValue: ip,
          noMap: true,
          includeIp: true,
          splitGeo: true,
          includeReputation: true,
        }}
      />
    </div>
  );
}

function ProtocolSection({ section }: { section: any }) {
  return (
    <div className="uk-margin-large-bottom">
      <AttributeTable attributes={section} />
    </div>
  );
}

export function ExpandedRowSection(row: any) {
  const layers = row?.data?.layers;
  const src = layers?.ip?.src;
  const dst = layers?.ip?.dst;
  const protocols = layers?.frame?.protocols.split(':');

  const protocolSections = useMemo(() => {
    let uniqueProtocols = new Set();
    return protocols
      .filter((protocol: string) => {
        let protocolInfo = get(layers, protocol);
        if (Array.isArray(protocolInfo) && protocolInfo.length === 0) return false;
        if (
          protocolInfo != null &&
          protocol !== 'ip' &&
          Object.keys(protocolInfo).length > 0 &&
          !uniqueProtocols.has(protocol)
        ) {
          uniqueProtocols.add(protocol);
          return true;
        }
        return false;
      })
      .map((protocol: string, i: number) => {
        const attributes: any = [];
        let protocolInfo = get(layers, protocol);
        if (Array.isArray(protocolInfo)) protocolInfo = protocolInfo[0];
        if (protocolInfo) {
          Object.entries(protocolInfo).forEach((entry) => {
            const [k, v] = entry;
            if (!k.includes('flag') && typeof v === 'string' && v.length > 0) {
              attributes.push({ label: k.replace(RegExp(/_/g), ' '), value: v });
            }
          });
        }
        return {
          name: protocol.toUpperCase(),
          content:
            attributes.length > 0 ? <ProtocolSection key={i} section={attributes} /> : undefined,
        };
      });
  }, [protocols, layers]);

  const options = useMemo<TabbedContentOption[]>(() => {
    return [
      {
        name: 'IP INFO',
        content: (
          <div className="marginal-table-expanded-row uk-padding-small uk-flex uk-flex-first">
            <IpSection ip={src} type={'Src'} />
            <IpSection ip={dst} type={'Dst'} />
          </div>
        ),
      },
    ].concat(protocolSections.filter((section: any) => !!section.content));
  }, [src, dst, protocolSections]);

  return (
    <div className="uk-margin-top">
      <TabbedContent options={options} configUpdateDisabled={true} />
    </div>
  );
}
