import { SidebarContext } from 'appContexts';
import React, { useCallback, useContext } from 'react';
import { deletePdfArchive } from 'utils';
import { ReactComponent as Warning } from 'svg/actions/exclamation-fill.svg';

export function DeleteArchivePrompt({
  selected,
  setSelected,
  setSelectedPrompt,
}: { selected: Set<string>; setSelected: Function; setSelectedPrompt: Function }) {
  const { investigation, setArchiveData } = useContext(SidebarContext);

  const deleteArchives = useCallback(
    (selection) => {
      setSelected(new Set());
      setArchiveData((curr) => curr?.filter((archive) => !selection.includes(archive.timestamp)));
      deletePdfArchive(investigation.id, selection);
      setSelectedPrompt(undefined);
    },
    [investigation.id, setArchiveData, setSelected, setSelectedPrompt],
  );

  return (
    <div
      className={`uk-flex ${selected.size < 1 ? 'sidebar-menu-alert' : 'sidebar-menu-delete-warning'} uk-flex-between`}
    >
      <div className="uk-flex" style={{ height: '24px' }}>
        <Warning className="uk-margin-right" />
        <div style={{ lineHeight: '24px' }}>
          {selected.size < 1 ? 'Select archives to be deleted' : 'Delete the selected archives'}
        </div>
      </div>
      {selected.size > 0 && (
        <button
          className="uk-button"
          onClick={() => deleteArchives(Array.from(selected))}
          data-testid="delete-archive-prompt-button"
        >
          {`Delete (${selected.size})`}
        </button>
      )}
    </div>
  );
}
