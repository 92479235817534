import React, { FunctionComponent, Children, ReactNode, Fragment } from 'react';
import { ReactComponent as DeleteIcon } from 'svg/actions/x-default.svg';
import { ReactComponent as ToggleOn } from 'svg/selection/toggle-on.svg';
import { ReactComponent as ToggleOff } from 'svg/selection/toggle-off.svg';
import { ReactComponent as AddIcon } from 'svg/actions/plus-outline.svg';

export type QueryBuilderStepProps = {
  title: string;
  multiple?: boolean;
  onNew?: () => void;
  onDelete?: (index: number) => void;
  isToggled?: boolean;
  onToggle?: any;
  hideTitle?: boolean;
  children?: ReactNode;
};

const QueryBuilderStep: FunctionComponent<QueryBuilderStepProps> = ({
  title,
  multiple,
  onNew,
  onDelete,
  children,
  onToggle,
  isToggled,
  hideTitle,
}) => {
  const isCollapsible = isToggled || !onToggle;
  const hasChildren = Children.toArray(children).some((child) => child);

  if (!(hasChildren || multiple)) return null;

  return (
    <div
      className={`${!isToggled && onToggle ? 'keystone-subsection-hidden' : 'keystone-subsection'}`}
    >
      <div className="uk-flex-inline uk-margin-bottom" style={{ width: '100%' }}>
        {!hideTitle && (
          <span className={`search-step-title${!hasChildren ? ' hide-in-pdf' : ''}`}>{title}</span>
        )}
        {onToggle && (
          <div className="uk-flex-inline hide-in-pdf" style={{ marginLeft: 'auto' }}>
            <button onClick={() => onToggle()} style={{ paddingRight: '2px' }}>
              {isToggled ? <ToggleOn /> : <ToggleOff />}
            </button>
          </div>
        )}
      </div>
      {isCollapsible && (
        <div className="uk-flex uk-flex-column uk-margin-bottom">
          <Fragment>
            {Children.map(children, (child: any, i: number) => {
              const noDelete = child?.props['data-no-delete'] || child == null;
              return (
                <div>
                  {child}
                  {onDelete && !noDelete && (
                    <button
                      className="uk-button uk-icon-link uk-float-right uk-padding-remove hide-in-pdf"
                      style={{ background: 'none' }}
                      onClick={() => onDelete(i)}
                    >
                      <DeleteIcon width={24} height={24} />
                    </button>
                  )}
                </div>
              );
            })}
            {multiple && <AddButton onNew={onNew} />}
          </Fragment>
        </div>
      )}
    </div>
  );
};

function AddButton({ onNew }: { onNew?: () => void }) {
  return (
    <div className="hide-in-pdf">
      <button className="uk-flex-inline add-filter-button uk-padding-remove-left" onClick={onNew}>
        <AddIcon />
        <div className="uk-margin-small-left">Add</div>
      </button>
    </div>
  );
}

export default QueryBuilderStep;
