import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import {
  AssetReport,
  AssetSearch,
  Collections,
  EmbeddedWindowLayer,
  ErrorBoundary,
  Gallery,
  GraphiQLView,
  Header,
  ImportPage,
  Investigation,
  LoadingDots,
  QueryResults,
  Shared,
  Spinner,
  StartPage,
  SurfaceManager,
} from 'components';
import { useUserProfile } from 'hooks';
import { getSearchParams, hasAuthentication, isAuthorized, isEmbedded, setHistory } from 'utils';
import { useKeycloak } from '@react-keycloak/web';
import {
  ALERT_TYPES,
  createGenericNotification,
  initializeUserData,
  initializeUserNotifications,
  ManagerTypes,
} from 'model';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql.macro';
import { DataSlicesContext, DataSlicesContextValue } from 'appContexts';
import { ReactComponent as UpdateIcon } from 'svg/actions/update.svg';
import localforage from 'localforage';
import { OnDemand } from './OnDemand';

export function Main() {
  const userProfile = useUserProfile();
  const history = useHistory();
  const embedded = isEmbedded();
  const embeddedWindowsEnabled = false;

  setHistory(history);

  const { hash: anchor, pathname } = useLocation();
  const [anchorId, setAnchorId] = useState('');
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const isStartPage = pathname === '/';

  useEffect(() => {
    setAnchorId(anchor.substring(1).split('&state')[0]);
  }, [anchor]);

  useEffect(() => {
    async function loadFonts() {
      // Load each font manually
      document.fonts.forEach(async (obj) => {
        await obj.load();
      });
    }
    loadFonts().then(() => {
      setFontsLoaded(true);
    });
  }, []);

  useEffect(() => {
    let timeoutId = null;
    const observer = new MutationObserver(() => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const element = document.getElementById(anchorId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          observer.disconnect();
        }
      }, 500); // Wait for 500ms after the last mutation
    });

    observer.observe(document, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
      clearTimeout(timeoutId);
    };
  }, [anchorId]);

  if (!userProfile || !fontsLoaded) {
    return null;
  }

  return (
    <AuthorizedPlatform>
      <UserData>
        <DataSliceContext>
          {!embedded && <Header />}
          <div
            className={
              embedded ? 'app-embedded' : `app-main${isStartPage ? ' app-start-page' : ''}`
            }
          >
            <ErrorBoundary>
              <Suspense fallback={<Spinner ratio={3} position="center" />}>
                <Switch>
                  <Route exact path="/" component={StartPage} />
                  <Route path="/advanced-search" component={QueryResults} />
                  <Route path="/assetreport" component={AssetReport} />
                  <Route path="/assetsearch" component={AssetSearch} />
                  <Route path="/gql" component={GraphiQLView} />
                  <Route
                    path="/mosaic"
                    component={Investigation}
                    key={getSearchParams().get('id')}
                  />
                  <Route path="/on-demand" component={OnDemand} />
                  <Route path="/queryresults" component={QueryResults} />
                  <Route path="/shared" component={Shared} />
                  <Route
                    path="/template"
                    component={Investigation}
                    key={getSearchParams().get('id')}
                  />
                  <Route path={`/${ManagerTypes.Collections}`} component={Collections} />
                  <Route path={`/${ManagerTypes.Gallery}`} component={Gallery} />
                  <Route path={`/${ManagerTypes.Import}`} component={ImportPage} />
                  <Route path={`/${ManagerTypes.Surfaces}`} component={SurfaceManager} />
                </Switch>
              </Suspense>
              {embeddedWindowsEnabled && <EmbeddedWindowLayer />}
            </ErrorBoundary>
          </div>
        </DataSliceContext>
      </UserData>
    </AuthorizedPlatform>
  );
}

function AuthorizedPlatform({ children }: { children: any }) {
  const { keycloak } = useKeycloak();

  if (isAuthorized(keycloak, ['platform']) || !hasAuthentication()) {
    return children;
  }

  // If not authorized for platform access redirect to corporate site
  window.location.href = `https://${process.env.REACT_APP_CORPORATE_HOST}`;
  return null;
}

function UserData({ children }: { children: any }) {
  const [userDataInitialized, setUserDataInitialized] = useState<boolean>(false);

  useEffect(() => {
    async function initUserData() {
      await initializeUserData();
      initializeUserNotifications();
      setUserDataInitialized(true);
    }
    initUserData();
  }, []);

  return userDataInitialized ? (
    children
  ) : (
    <div style={{ height: '100vh', width: '100vw' }}>
      <div className="uk-position-center">
        Initializing User Data &nbsp;&nbsp;
        <LoadingDots />
      </div>
    </div>
  );
}

const dataSlicesQuery = gql`
query GetDataSlices {
  pipelineQuery {
    pipelineMaxCount
  }
  dataSlices
  dataSliceMetadata {
    indexName
    label
    timeWindow
    dataSlices
  }
}
`;

function DataSliceContext({ children }: { children: any }) {
  const { data, loading } = useQuery(dataSlicesQuery);

  const contextValue = useMemo<DataSlicesContextValue>(
    () => ({
      hasPorts: data?.dataSlices?.includes('ports'),
      hasPortRecords: data?.dataSlices?.includes('portRecords'),
      metadata: getMetadata(data),
      maxPipelineCount: data?.pipelineQuery?.pipelineMaxCount,
    }),
    [data],
  );

  // Check local forage for any metadata. Compare saved metadata to current metadata
  // If different, create a global notification. Update the localforage metadata
  useEffect(() => {
    async function compareMetadata() {
      const savedMetadata: Record<string, any> = await localforage.getItem('metadata');
      const currentMetadata = getMetadata(data);
      if (savedMetadata) {
        Object.keys({ ...savedMetadata, ...currentMetadata }).some((key) => {
          if (
            !currentMetadata[key] ||
            savedMetadata[key]?.lastUpdated !== currentMetadata[key].lastUpdated
          ) {
            console.log('Metadata Difference:', key, savedMetadata[key], currentMetadata[key]);
            createGenericNotification('metadata-difference', {
              title: 'Data Update',
              message: 'Some tiles may have different data',
              alertType: ALERT_TYPES.placeholder,
              icon: <UpdateIcon style={{ width: 20, height: 20 }} />,
              style: { background: '#F8F8ED' },
            });
            return true;
          }
          return false;
        });
      }
      await localforage.setItem('metadata', currentMetadata);
    }
    if (!loading) {
      compareMetadata();
    }
  }, [data, loading]);

  if (loading) {
    return null;
  }

  return <DataSlicesContext.Provider value={contextValue}>{children}</DataSlicesContext.Provider>;
}

function getMetadata(data: any) {
  const metadata = {};
  data?.dataSliceMetadata?.forEach((metadataObj: any) => {
    const { label, timeWindow, dataSlices, indexName } = metadataObj;
    dataSlices?.forEach((dataslice: any) => {
      metadata[dataslice?.data_name] = {
        indexName,
        label,
        lastUpdated: timeWindow[1],
        fields: dataslice?.fields,
      };
    });
  });

  // Add routerTraffic which is used for traceroute summary
  metadata['routerTraffic'] = { ...metadata['tracerouteEdges'] };

  return metadata;
}
