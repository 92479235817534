import React, { useEffect, useMemo, useState } from 'react';
import { useLazyComponentPluginQuery } from 'hooks';
import { Banner, Spinner } from 'components';
import { AdvancedQuery, ALERT_TYPES } from 'model';
import { abbreviatedCount, pruneEmptyFilters } from 'utils';

export function DatasetCappingBanner({
  advancedQuery,
  cap,
}: { advancedQuery: AdvancedQuery; cap: number }) {
  const modifiedAq = useMemo(() => {
    return pruneEmptyFilters({ ...advancedQuery, view: 'banner' });
  }, [advancedQuery]);
  const [dataLength, setDataLength] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [runQuery, queryResult] = useLazyComponentPluginQuery(modifiedAq, undefined, true);

  useEffect(() => {
    setLoading(true);
    runQuery({ variables: { size: 0 } }).then(() => {
      setLoading(false);
    });
  }, [runQuery]);

  useEffect(() => {
    if (queryResult.data) {
      setDataLength(
        queryResult.data[
          advancedQuery.resolution === 'routers' ? 'routerAliases' : advancedQuery.resolution
        ]?.totalCount,
      );
    }
  }, [queryResult, advancedQuery]);

  return dataLength > cap ? (
    <Banner
      alertType={ALERT_TYPES.warning}
      title="Size Exceeded"
      message={`The data size of this search exceeds the capacity of the selected view.
         The first ${abbreviatedCount(cap)} of ${abbreviatedCount(dataLength)} total results will be shown.`}
      style={{ marginTop: '10px' }}
    />
  ) : loading ? (
    <Spinner />
  ) : null;
}
