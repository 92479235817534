import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { SidebarContext } from 'appContexts';
import {
  findLargestIdNumber,
  getAssetTypeFromPlugin,
  getClauseFromAssetType,
  getConfigAdvancedQuery,
  getDefaultAssetTypeLabel,
  getQueryClauseAssetType,
  getTemplateParamId,
  isDatasetQuery,
  isOutputVariable,
  isViewOnly,
  parseInvestigationSectionId,
  saveInvestigation,
  updateParams,
} from 'utils';
import { AdvancedQuery, ALERT_TYPES, isInvestigationTemplate, TemplateParam } from 'model';
import { ReactComponent as AddButton } from 'svg/actions/plus-outline.svg';
import { Banner, ComponentPluginName } from 'components';
import { TemplateParamSection } from './TemplateParamSection';
import { ReactComponent as ToggleOn } from 'svg/selection/toggle-on.svg';
import { ReactComponent as ToggleOff } from 'svg/selection/toggle-off.svg';
import { cloneDeep } from 'lodash';

export function SidebarTemplateParams({ maxHeightPx }) {
  const { investigation, setLocalTemplateParams, localTemplateParams } = useContext(SidebarContext);
  const paramListEndRef = useRef(null);

  const templateParams = useMemo(() => {
    if (!investigation) return undefined;
    return localTemplateParams ?? investigation.templateParams;
  }, [investigation, localTemplateParams]);

  const nonOutputParams = useMemo(() => {
    return templateParams.filter((param) => !isOutputVariable(param));
  }, [templateParams]);

  const saveVariablesToggleable = !isViewOnly() && investigation.isTemplate;
  const hasTemplateParams = nonOutputParams.length > 0;

  // *** UTILS *****
  const assetTypeOptions = useMemo(() => {
    // loop through investigation sections and compile list of asset types.
    let optionsList = [];
    investigation.sections.forEach((section) => {
      const [plugin, params] = parseInvestigationSectionId(section.id);
      // Advanced Query case
      if (params.advancedQuery) {
        const aq: AdvancedQuery = getConfigAdvancedQuery(params);
        if (
          isDatasetQuery(aq) &&
          !params.isUnlocked &&
          !optionsList.map((option) => option.value).includes('dataset')
        ) {
          optionsList.push({ value: 'dataset', label: 'dataset' });
        }
        aq.filterClauses.forEach((clause) => {
          const assetType = getQueryClauseAssetType(clause);
          if (
            !clause.isUnlocked &&
            !optionsList.map((option) => option.value).includes(assetType)
          ) {
            optionsList.push({ value: assetType, label: getDefaultAssetTypeLabel(assetType) });
          }
        });
      } else {
        const assetType = getAssetTypeFromPlugin(plugin);
        if (
          !params.isUnlocked &&
          !optionsList.map((option) => option.value).includes(assetType) &&
          plugin !== ComponentPluginName.UserMarkdown
        ) {
          optionsList.push({ value: assetType, label: getDefaultAssetTypeLabel(assetType) });
        }
      }
    });
    return optionsList;
    // Include the entire investigation as a dependency, since the sections object does not always change.
  }, [investigation]);

  // *** CALLBACKS ***
  const addNewParam = useCallback(() => {
    if (!investigation || !templateParams || !assetTypeOptions || !(assetTypeOptions?.length > 0))
      return;
    const assetType = assetTypeOptions[0]?.value;
    let number = findLargestIdNumber(templateParams, assetType);
    templateParams.push({
      name: `${getDefaultAssetTypeLabel(assetType)}${number}`,
      id: `${assetType}${number}`,
      value: undefined,
      clauseType: getClauseFromAssetType(investigation, assetType),
    });
    updateParams(setLocalTemplateParams, templateParams, investigation);
    scrollToBottomOfParamList();
  }, [templateParams, investigation, assetTypeOptions, setLocalTemplateParams]);

  const scrollToBottomOfParamList = () => {
    setTimeout(function () {
      paramListEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100); // short delay to allow the new tile to mount before scrolling to it
  };

  const toggleSaveTemplateParamValues = useCallback(() => {
    // If we are going to make the investigation ephemeral, we need to take care to remove any hard coded
    // param values now.
    let newParams = cloneDeep(localTemplateParams ?? investigation.templateParams);

    const needToRemoveVariableValues = !investigation.removeTemplateParamValues; // currently saved and switching to not saving
    newParams.forEach((param) => {
      if (needToRemoveVariableValues || isOutputVariable(param)) {
        param.value = undefined;
      }
      param.label = undefined;
    });
    saveInvestigation(
      isInvestigationTemplate(investigation)
        ? {
            ...investigation,
            templateParams: newParams ?? localTemplateParams,
            removeTemplateParamValues: !investigation.removeTemplateParamValues,
          }
        : {
            ...investigation,
            templateParams: newParams ?? localTemplateParams,
            removeTemplateParamValues: undefined,
          },
    );
  }, [localTemplateParams, investigation]);

  return (
    <div>
      {(hasTemplateParams || saveVariablesToggleable) && (
        <div
          className="template-variable-list sidebar-menu-content"
          style={{ maxHeight: `${maxHeightPx}px`, marginTop: '0', padding: '16px 16px 0 16px' }}
        >
          {saveVariablesToggleable && (
            <div className="uk-margin-medium-bottom">
              <div>Values</div>
              <div className="uk-text-muted uk-flex">
                Persist variable values when shared or reloaded.
                <button
                  onClick={() => toggleSaveTemplateParamValues()}
                  style={{ padding: 0, height: '32px', width: '38px', marginLeft: 'auto' }}
                >
                  {investigation.removeTemplateParamValues ? <ToggleOff /> : <ToggleOn />}
                </button>
              </div>
            </div>
          )}
          {hasTemplateParams && saveVariablesToggleable && (
            <div className="header-divider uk-margin-medium-bottom" />
          )}
          {hasTemplateParams && (
            <div>
              <div>Configuration</div>
              <div className="uk-text-muted uk-margin-bottom">
                Edit variable types, names, and values
              </div>
              {templateParams.map((param: TemplateParam, index) => {
                return (
                  !isOutputVariable(param) && (
                    <TemplateParamSection
                      assetTypeOptions={assetTypeOptions}
                      key={getTemplateParamId(param)}
                      param={param}
                      index={index}
                    />
                  )
                );
              })}
              <div ref={paramListEndRef} />
            </div>
          )}
        </div>
      )}
      {templateParams.length === 0 && isViewOnly() && (
        <Banner
          title={'No Template Variables'}
          message={'Only the owner can add variables to this Template'}
          alertType={ALERT_TYPES.warning}
          noClose={true}
          style={{ margin: '0' }}
        />
      )}
      {templateParams && !isViewOnly() && assetTypeOptions && assetTypeOptions?.length > 0 && (
        <div className="uk-flex uk-flex-between sidebar-menu-toolbar">
          <div className="uk-flex">
            {`${nonOutputParams?.length ?? 0} Variable${nonOutputParams?.length === 1 ? '' : 's'}`}
          </div>
          <div className="uk-flex sidebar-menu-prompt-buttons">
            <button onClick={() => addNewParam()} className="sidebar-menu-button">
              <AddButton />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
