import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import EasyMDE from 'easymde';
import { ReactComponent as MarkdownIcon } from 'svg/misc/markdown.svg';
import { getCurrentInvestigation, makeUniqueId, MAX_IMAGE_SIZE, onSelectImage } from 'utils';
import { acknowledgeNotification, ALERT_TYPES, createGenericNotification } from 'model';
import {
  boldToolbarOption,
  codeBlockToolbarOption,
  heading1ToolbarOption,
  heading2ToolbarOption,
  italicToolbarOption,
  linkToolbarOption,
  orderedListToolbarOption,
  quoteToolbarOption,
  redoToolbarOption,
  undoToolbarOption,
  unorderedListToolbarOption,
  uploadImageToolbarOption,
} from './markdownToolbar';

export default function MarkdownEditor({
  value,
  onChange,
  style,
  customClass,
  allowImageUpload,
}: {
  value: string;
  onChange: any;
  style?: any;
  customClass?: string;
  allowImageUpload?: boolean;
}) {
  const editorRef = useRef<HTMLTextAreaElement>(null);
  const [editor, setEditor] = useState<any>();
  // Refresh EasyMDE editor after sidebar animation
  // Prevents cursor bug with line wrapping
  useEffect(() => {
    const sidebar = document.querySelector('.investigation-wrapper');

    const refreshCodeMirror = () => {
      if (editor) {
        editor.codemirror.refresh();
      }
    };

    if (!sidebar) return;

    sidebar.addEventListener('transitionend', refreshCodeMirror);

    return () => {
      sidebar.removeEventListener('transitionend', refreshCodeMirror);
    };
  }, [editor]);

  // From EasyMDE source code. Adds image syntax to editor
  function replaceSelection(cm, startEnd, url, alt) {
    if (cm.getWrapperElement().lastChild.classList.contains('editor-preview-active')) return;

    let text;
    let start = startEnd[0];
    let end = startEnd[1];
    const startPoint: any = {},
      endPoint: any = {};
    Object.assign(startPoint, cm.getCursor('start'));
    Object.assign(endPoint, cm.getCursor('end'));
    if (alt) {
      start = start.replace('[]', `[${alt}]`);
    }
    if (url) {
      start = start.replace('url', url); // url is in start for upload-image
    }
    text = cm.getSelection();
    cm.replaceSelection(start + text + end);

    startPoint.ch += start.length;
    if (startPoint !== endPoint) {
      endPoint.ch += start.length;
    }
    cm.setSelection(startPoint, endPoint);
    cm.focus();
  }

  const onImageUpload = useCallback((file: File, editor) => {
    // Ensure image is valid type
    // ensure max size 1024*1024*2 (2MB) or less
    if (
      file.size > MAX_IMAGE_SIZE ||
      !['image/png', 'image/jpeg', 'image/svg+xml'].includes(file.type)
    ) {
      createGenericNotification('invalid-image', {
        alertType: ALERT_TYPES.error,
        message: 'Image upload must be less than 2MB and be png, jpeg, or svg',
        title: 'Invalid Image',
        onClear: () => acknowledgeNotification('invalid-image'),
      });
      return;
    }
    // save image to the investigation.
    try {
      const newId = makeUniqueId('image');
      const newName = file.name.replaceAll(/\s/gu, '_');
      const imageId = `${newId}/${newName}`;
      if (!editor?.codemirror) {
        return;
      }
      const cm = editor.codemirror;
      const startEnd = editor?.options?.insertTexts?.image;
      replaceSelection(cm, startEnd, imageId, file.name);
      // select image a short while after the replacement has been done, to give us enough time to update the description
      setTimeout(() => {
        onSelectImage(file, getCurrentInvestigation(), newId, newName, file.type);
      }, 500);
    } catch (e) {
      createGenericNotification('failed-image-upload', {
        alertType: ALERT_TYPES.error,
        message: 'There was a problem with uploading the image. Please try again.',
        title: 'Failed to Upload Image',
        onClear: () => acknowledgeNotification('failed-image-upload'),
      });
    }
    return;
  }, []);

  const toolbar: any = useMemo(() => {
    let icons: any = [
      boldToolbarOption,
      italicToolbarOption,
      '|',
      heading1ToolbarOption,
      heading2ToolbarOption,
      '|',
      quoteToolbarOption,
      codeBlockToolbarOption,
      linkToolbarOption,
      '|',
      orderedListToolbarOption,
      unorderedListToolbarOption,
      '|',
      undoToolbarOption,
      redoToolbarOption,
    ];
    if (allowImageUpload) {
      icons.push('|');
      icons.push(uploadImageToolbarOption);
    }
    return icons;
  }, [allowImageUpload]);

  // Create EasyMDE editor on mount
  useEffect(() => {
    if (!editor) {
      const markdownEditor = new EasyMDE({
        autoRefresh: true,
        element: editorRef.current,
        forceSync: true,
        initialValue: value,
        insertTexts: {
          image: ['![](url', ')'],
          link: ['[', '](url)'],
        },
        spellChecker: false,
        status: false,
        toolbar,
        uploadImage: allowImageUpload,
        imageAccept: 'image/png, image/svg+xml, image/jpeg',
        imageUploadFunction: (file) => {
          onImageUpload(file, markdownEditor);
        },
      });

      markdownEditor.codemirror.on('change', function () {
        onChange(markdownEditor.value());
      });
      markdownEditor.codemirror.setOption('extraKeys', {
        'Ctrl-Z': function (cm) {
          if (!cm.isClean()) cm.undo();
        },
        'Cmd-Z': function (cm) {
          if (!cm.isClean()) cm.undo();
        },
      });

      markdownEditor.codemirror.markClean();

      setEditor(markdownEditor);
    }
  }, [value, editor, onChange, onImageUpload, toolbar, allowImageUpload]);

  return (
    <div className={`markdown-editor-wrapper ${customClass ?? ''}`} style={{ ...(style || {}) }}>
      <textarea ref={editorRef} className="uk-textarea" />
      <div className="markdown-editor-footer">
        <MarkdownIcon />
        <span>Styling with Markdown is supported.</span>
      </div>
    </div>
  );
}
