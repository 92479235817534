import { validatePcapFile } from './pcapParser';
import { createInvalidIpsWarning, validateIPSet } from 'utils';
import { DocumentNode, gql } from '@apollo/client';
import { getGraphQLClient } from 'gql';

export const USER_DATA_LIMIT = 256000000;

export async function validateFile(file: File) {
  const dataLimitQuery = gql`
  query {
    datasetDataStats {
      userId
      totalDataSize
      dataSizeLimit
      datasets {
        key
        dataSize
        data {
          dataId
          size
          docCount
        }
      }
    }
  }`;
  let typename = 'ips';
  let error = undefined;
  let warning = undefined;
  let newFile = undefined;
  if (!file) return { typename: undefined, error: undefined, warning: undefined };
  const pcapError = await validatePcapFile(file);
  if (file.name.endsWith('.pcap')) {
    typename = 'pcap';
    if (pcapError) {
      error = pcapError;
      return { typename, error };
    }
  } else if (!pcapError) {
    typename = 'pcap';
  } else {
    const IPValidationData = await validateIpFile(file, error, warning);
    error = IPValidationData?.error;
    warning = IPValidationData?.warning;
    newFile = IPValidationData?.newFile;
  }
  const client = getGraphQLClient();
  const dataLimits = await client.query({
    query: dataLimitQuery as DocumentNode,
    fetchPolicy: 'no-cache',
  });
  if (
    dataLimits?.data?.datasetDataStats?.totalDataSize + file.size >
    dataLimits?.data?.datasetDataStats?.dataSizeLimit
  ) {
    error =
      'Uploading this file would cause your account to exceed the dataset storage limit. ' +
      'Please delete any unused datasets and try again.';
  }

  if (file.size > USER_DATA_LIMIT) {
    error = 'File size may not exceed 256MB';
  }
  return { typename, error, warning, newFile };
}

export async function validateIpFile(
  file: File,
  error: string,
  warning: string,
  returnIps?: boolean,
) {
  const promise = new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsText(file);
  });
  let newFile = undefined;
  try {
    const ipsAsString = (await promise) as string;
    const { seenIps, duplicateIps, invalidIps, validIps } = validateIPSet(ipsAsString);
    const numValidIps = validIps.size;
    const validIpString = Array.from(validIps).join('\n');
    if (numValidIps === 0) {
      error = 'No new-line delimited IPs were found in the file.';
    } else if (invalidIps.size > 0 || duplicateIps.size > 0) {
      warning = createInvalidIpsWarning(invalidIps, duplicateIps, seenIps.size);
    }
    if (validIpString !== ipsAsString) {
      newFile = new File([validIpString], file.name, { type: 'application/octet-stream' });
    }
    return { error, warning, newFile: returnIps ? Array.from(validIps) : newFile };
  } catch (e) {
    error = `An error occurred while attempting to read ${file.name} as a list of IPs.`;
    return { error, warning, newFile };
  }
}

// async function processPcapFile(file: File, setProgress: any, setCancelToken?: any, setError?: any) {
//   let cancelled = false;
//   if (setCancelToken) {
//     setCancelToken({
//       cancel: () => {
//         cancelled = true;
//       }
//     });
//   }
//
//   const BYTES_PER_CHUNK = 1000000; // 1MB
//   const SIZE = file.size;
//   const NUM_CHUNKS = Math.ceil(file.size/BYTES_PER_CHUNK);
//   let leftoverBytes; // Partial bytes from previous chunk that weren't processed
//   let start = 0; // The file offset of the next chunk's first byte
//   let chunkNumber = 1; // The number of chunks processed so far
//
//   // pcap specific variables.
//   let uniqueIps = new Set<string>();
//   let linkType;
//   let littleEndian;
//   // Iterate over bytes in the file
//   while( start < SIZE && !cancelled ) {
//     let chunk: Blob|undefined = file.slice(start, start + BYTES_PER_CHUNK);
//     if (start === 0) {
//       const retVal = await parsePcapHeader(chunk);
//       chunk = retVal?.packet;
//       linkType = retVal?.linkType;
//       littleEndian = retVal?.littleEndian;
//       const error = retVal?.error;
//       if (chunk == null) {
//         setError(error ?? 'An unknown error occurred while parsing the file.')
//         return;
//       }
//     }
//     if (leftoverBytes) {
//       chunk = new Blob([leftoverBytes, chunk])
//     }
//     const chunkInfo: any = await parsePcapChunk(chunk, linkType ?? 1, littleEndian ?? true);
//     const ips = chunkInfo.ips;
//     const leftovers = chunkInfo.leftovers
//     ips.forEach((ip: string) => {
//       uniqueIps.add(ip);
//     })
//
//     leftoverBytes = leftovers;
//     // Update the progress every 10 chunks.
//     if (chunkNumber % 10 === 0 || chunkNumber === NUM_CHUNKS) {
//       setProgress(Math.floor(chunkNumber*100/NUM_CHUNKS));
//     }
//     start = start + BYTES_PER_CHUNK;
//     chunkNumber ++;
//   }
//   if (cancelled) {
//     setProgress(undefined);
//     return undefined;
//   }
//   let ipStr = '';
//   console.log(`Found ${uniqueIps.size} unique ips`);
//   uniqueIps.forEach(ip => {
//     ipStr += `${ip}\n`;
//   });
//   if (ipStr.length === 0) {
//     setError('No IPs were detected in the file. The file may include protocols which are not currently supported.');
//     return undefined;
//   }
//   return ipStr;
// }
