import React, { useEffect, useState } from 'react';
import { SharedContext } from 'appContexts';
import { getSearchParams } from 'utils';
import { getSharedUserData } from 'model';
import { Investigation } from 'components';

export function Shared() {
  const sharedKey = getSearchParams().get('key');
  const [sharedUserDataId, setSharedUserDataId] = useState<string>();

  useEffect(() => {
    async function fetchSharedUserData() {
      setSharedUserDataId(sharedKey ? await getSharedUserData(sharedKey) : undefined);
    }
    fetchSharedUserData();
  }, [sharedKey]);

  if (!sharedKey) {
    return null;
  }

  return (
    <SharedContext.Provider value={{ sharedKey, sharedUserDataId }}>
      {sharedUserDataId?.startsWith('investigation:') && <Investigation />}
    </SharedContext.Provider>
  );
}
