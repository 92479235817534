import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthorizedElement, Banner, PUBLISHER_ROLE, Spinner } from 'components';
import { DataSlicesContext, SidebarContext } from 'appContexts';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  ALERT_TYPES,
  acknowledgeNotification,
  createGenericNotification,
  userDatasetsVar,
  userSharedDatasetsVar,
} from 'model';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Link } from 'svg/misc/link.svg';
import { ReactComponent as Download } from 'svg/system/file-download.svg';
import { ReactComponent as Publish } from 'svg/experimental/mosaic-publish.svg';
import { ReactComponent as Unpublish } from 'svg/experimental/mosaic-unpublish.svg';
import { ReactComponent as Checkmark } from 'svg/actions/checkmark-default.svg';
import {
  PUBLISH_MUTATION,
  SHARE_MUTATION,
  UNPUBLISH_MUTATION,
  copyShareLink,
  createCrossInstanceParams,
  exportJsonToFile,
  isTemplate,
  isViewOnly,
} from 'utils';
import { SidebarOptions } from './Sidebar';

enum ShareTabs {
  Share = 'Share',
  Publish = 'Publish',
}

export function SidebarShareMenu() {
  const { investigation, setActiveOption } = useContext(SidebarContext);
  const [currentTab, setCurrentTab] = useState<ShareTabs>(ShareTabs.Share);

  return (
    <div>
      {!isViewOnly() && ( // shared mosaics and asset reports should not be able to be published to the gallery
        <AuthorizedElement roles={[PUBLISHER_ROLE]}>
          <div className="share-tabs-wrapper">
            <div
              className={`share-tab ${currentTab === ShareTabs.Share ? 'active-tab' : ''}`}
              onClick={() =>
                setCurrentTab((current) =>
                  ShareTabs.Share !== current ? ShareTabs.Share : current,
                )
              }
            >
              Share
            </div>
            <span className="tab-spacer" />
            <div
              className={`share-tab ${currentTab === ShareTabs.Publish ? 'active-tab' : ''}`}
              onClick={() =>
                setCurrentTab((current) =>
                  ShareTabs.Publish !== current ? ShareTabs.Publish : current,
                )
              }
            >
              Publish
            </div>
            <span className="tab-spacer" />
          </div>
        </AuthorizedElement>
      )}
      <div className="share-body-wrapper">
        {currentTab === ShareTabs.Share ? <SidebarShareOption /> : <SidebarPublishOption />}
        {!investigation?.removeTemplateParamValues && isTemplate() && !isViewOnly() && (
          <Banner
            title={'Shared Variable Values'}
            message={
              <div>
                Variable values are displayed to all viewers when shared. Configure variable
                settings within{' '}
                {
                  <a
                    className="uk-link"
                    style={{ color: 'rgb(241, 140, 23)' }}
                    onClick={() => setActiveOption(SidebarOptions.TemplateVariables)}
                  >
                    Variable tools
                  </a>
                }
                .
              </div>
            }
            alertType={ALERT_TYPES.warning}
            noClose={true}
            style={{ margin: '16px', marginTop: '0' }}
          />
        )}
      </div>
    </div>
  );
}

function SidebarShareOption() {
  const { investigation } = useContext(SidebarContext);
  const { metadata } = useContext(DataSlicesContext);

  const datasets = useReactiveVar(userDatasetsVar);
  const sharedDatasets = useReactiveVar(userSharedDatasetsVar);

  const [crossInstanceLoading, setCrossInstanceLoading] = useState<boolean>(false);
  const [crossInstanceProgress, setCrossInstanceProgress] = useState<string>('');
  const [showCopySuccess, setShowCopySuccess] = useState<boolean>(false);

  const [shareMosaic, { data: shareData, error: shareError }] = useMutation(SHARE_MUTATION);

  const sharedKey = useMemo(() => {
    return investigation?.sharing?.shared_key || shareData?.shareUserData?.sharedKey;
  }, [investigation?.sharing?.shared_key, shareData?.shareUserData?.sharedKey]);

  const shareLink = useMemo(() => {
    return new URL(
      isViewOnly() ? window.location.href : `${window.location.origin}/shared?key=${sharedKey}`,
    ).href;
  }, [sharedKey]);

  const crossInstanceWarningExists: boolean = useMemo(() => {
    return crossInstanceProgress.startsWith('Warning');
  }, [crossInstanceProgress]);

  const copyShareLinkToClipboard = useCallback(() => {
    if (!showCopySuccess) {
      if (!sharedKey && !isViewOnly()) {
        // we don't use copyShareLink as we need to make an API call first, and Safari requires us to define what will be copied (so we define an API response function) instead of allowing us to define what will be copied after the API response
        const newShareLink = new ClipboardItem({
          'text/plain': shareMosaic({ variables: { key: investigation.id } }).then((result) => {
            return new Blob(
              [`${window.location.origin}/shared?key=${result.data.shareUserData.sharedKey}`],
              { type: 'text/plain' },
            );
          }),
        });
        navigator.clipboard.write([newShareLink]);
      } else {
        copyShareLink(shareLink);
      }
      setShowCopySuccess(true);
      setTimeout(() => {
        setShowCopySuccess(false);
      }, 2000);
    }
  }, [investigation.id, shareLink, shareMosaic, sharedKey, showCopySuccess]);

  const downloadFile = useCallback(() => {
    async function waitForCrossInstanceCreation() {
      setCrossInstanceLoading(true);
      setCrossInstanceProgress('');
      return await createCrossInstanceParams(
        investigation,
        { ...datasets, ...sharedDatasets },
        setCrossInstanceProgress,
        undefined,
        metadata,
      );
    }
    waitForCrossInstanceCreation().then((result) => {
      setCrossInstanceLoading(false);
      exportJsonToFile(result, investigation?.title);
    });
  }, [datasets, investigation, metadata, sharedDatasets]);

  // Handle share success or errors
  useEffect(() => {
    if (shareError) {
      const shareErrorNotificationName = 'share-mosaic-error';
      acknowledgeNotification(shareErrorNotificationName); // Acknowledge previous share error notifications
      createGenericNotification(shareErrorNotificationName, {
        alertType: ALERT_TYPES.error,
        message: (
          <Fragment>
            Failed to share the mosaic,{' '}
            <span className="uk-text-bold uk-text-danger">{investigation.title}</span>. Please try
            again.
          </Fragment>
        ),
        title: 'Share Failed',
        onClear: () => acknowledgeNotification(shareErrorNotificationName),
      });
    }
  }, [shareError, investigation.title]);

  return (
    <div className="share-content">
      <div className="share-instructions">
        Copy the URL below to share between users of this instance or download this analysis as a
        JSON file to share between users of different instances
      </div>
      <div className="share-actions">
        <button className="uk-button-secondary uk-button" onClick={copyShareLinkToClipboard}>
          {showCopySuccess ? (
            <Fragment>
              <Checkmark />
              Copied
            </Fragment>
          ) : (
            <Fragment>
              <Link />
              Copy Link
            </Fragment>
          )}
        </button>
        <button className="uk-button-secondary uk-button" onClick={downloadFile}>
          {crossInstanceLoading && !crossInstanceWarningExists ? (
            <Fragment>
              <Spinner ratio={0.55} style={{ padding: '0', marginRight: '8px' }} />
              {Math.ceil(parseFloat(crossInstanceProgress))}%
            </Fragment>
          ) : (
            <Fragment>
              <Download />
              JSON
            </Fragment>
          )}
        </button>
      </div>
    </div>
  );
}

function SidebarPublishOption() {
  const { investigation } = useContext(SidebarContext);
  const [isPublished, setIsPublished] = useState<boolean>(!!investigation?.sharing?.published_key);
  const [isUpdate, setIsUpdate] = useState<boolean>(isPublished);
  const [isLoadingPublish, setIsLoadingPublish] = useState<boolean>(false);
  const [isLoadingUnpublish, setIsLoadingUnpublish] = useState<boolean>(false);

  const [publishGalleryMosaic, { data: publishData, error: publishError }] =
    useMutation(PUBLISH_MUTATION);
  const [unpublishGalleryMosaic, { data: unpublishData, error: unpublishError }] =
    useMutation(UNPUBLISH_MUTATION);

  const history = useHistory();
  const publishErrorNotificationName = 'gallery-publish-error';
  const publishSuccessNotificationName = 'gallery-publish-success';
  const unpublishErrorNotificationName = 'gallery-unpublish-error';
  const unpublishSuccessNotificationName = 'gallery-unpublish-success';

  function acknowledgeAllNotifications() {
    acknowledgeNotification(publishErrorNotificationName);
    acknowledgeNotification(publishSuccessNotificationName);
    acknowledgeNotification(unpublishErrorNotificationName);
    acknowledgeNotification(unpublishSuccessNotificationName);
  }

  const publishedKey = useMemo(() => {
    return investigation?.sharing?.published_key || publishData?.publishUserData?.publishedKey;
  }, [investigation, publishData]);

  const removedObjectCount = useMemo(() => {
    return unpublishData?.unpublishUserData?.removedObjectCount;
  }, [unpublishData]);

  const publishCallback = useCallback(() => {
    if (!investigation || isLoadingPublish || isLoadingUnpublish) return;
    setIsUpdate(isPublished);
    setIsLoadingPublish(true);
    const publishVars: Record<string, any> = {
      id: investigation.id,
      roles: null,
    };
    publishGalleryMosaic({ variables: publishVars });
  }, [publishGalleryMosaic, investigation, isLoadingPublish, isLoadingUnpublish, isPublished]);

  const unpublishCallback = useCallback(() => {
    if (isLoadingPublish || isLoadingUnpublish) return;
    setIsLoadingUnpublish(true);
    unpublishGalleryMosaic({ variables: { publishedKey } });
  }, [isLoadingPublish, isLoadingUnpublish, publishedKey, unpublishGalleryMosaic]);

  // Delete publish success banner when clicking view link
  const viewPublishedMosaic = useCallback(() => {
    try {
      acknowledgeNotification('gallery-publish-success');
    } finally {
      history.push(`/shared?key=${publishedKey}`);
    }
  }, [history, publishedKey]);

  // Handle publish success or errors
  useEffect(() => {
    if (publishError) {
      setIsLoadingPublish(false);
      acknowledgeAllNotifications();
      createGenericNotification(publishErrorNotificationName, {
        alertType: ALERT_TYPES.error,
        message: (
          <Fragment>
            Failed to publish the mosaic,{' '}
            <span className="uk-text-bold uk-text-danger">{investigation.title}</span>. Please try
            again.
          </Fragment>
        ),
        title: 'Publish Failed',
      });
    } else if (investigation?.sharing?.published_key && isLoadingPublish) {
      setIsPublished(true);
      setIsLoadingPublish(false);
      acknowledgeAllNotifications();
      createGenericNotification(publishSuccessNotificationName, {
        alertType: ALERT_TYPES.success,
        message: (
          <Fragment>
            The mosaic, <span className="uk-text-bold uk-text-success">{investigation.title}</span>,
            has been {isUpdate ? 'updated in' : 'published to'} the Gallery. Click{' '}
            <a href="#" onClick={() => viewPublishedMosaic()}>
              here
            </a>{' '}
            to view it.
          </Fragment>
        ),
        title: 'Published',
      });
    }
  }, [
    publishError,
    investigation?.sharing?.published_key,
    investigation.title,
    isLoadingPublish,
    isUpdate,
    viewPublishedMosaic,
  ]);

  //  Handle unpublish success or errors
  useEffect(() => {
    if (unpublishError) {
      setIsLoadingUnpublish(false);
      acknowledgeAllNotifications();
      createGenericNotification(unpublishErrorNotificationName, {
        alertType: ALERT_TYPES.error,
        message: (
          <Fragment>
            Failed to delete{' '}
            <span className="uk-text-bold uk-text-danger">{investigation.title}</span>, from the
            gallery. Please try again.
          </Fragment>
        ),
        title: 'Delete Failed',
      });
    } else if (removedObjectCount && isLoadingUnpublish) {
      setIsPublished(false);
      setIsLoadingUnpublish(false);
      acknowledgeAllNotifications();
      createGenericNotification(unpublishSuccessNotificationName, {
        alertType: ALERT_TYPES.success,
        message: (
          <Fragment>
            Successfully removed{' '}
            <span className="uk-text-bold uk-text-success">{investigation.title}</span>
            &nbsp;from the Gallery.
          </Fragment>
        ),
        title: 'Unpublished',
      });
    }
  }, [unpublishError, investigation.title, isLoadingUnpublish, removedObjectCount]);

  return (
    <div className="share-content">
      <div className="share-instructions">Publishing will create a new copy in the Gallery</div>
      <div className="share-actions">
        {isPublished ? (
          <Fragment>
            <button className="uk-button-secondary uk-button" onClick={unpublishCallback}>
              {isLoadingUnpublish ? (
                <Spinner ratio={0.55} style={{ padding: '0', marginRight: '8px' }} />
              ) : (
                <Fragment>
                  <Unpublish />
                  Unpublish
                </Fragment>
              )}
            </button>
            <button className="uk-button-secondary uk-button" onClick={publishCallback}>
              {isLoadingPublish ? (
                <Spinner ratio={0.55} style={{ padding: '0', marginRight: '8px' }} />
              ) : (
                <Fragment>
                  <Publish />
                  Update
                </Fragment>
              )}
            </button>
          </Fragment>
        ) : (
          <button className="uk-button-secondary uk-button" onClick={publishCallback}>
            {isLoadingPublish ? (
              <Spinner ratio={0.55} style={{ padding: '0', marginRight: '8px' }} />
            ) : (
              <Fragment>
                <Publish />
                Publish
              </Fragment>
            )}
          </button>
        )}
      </div>
    </div>
  );
}
