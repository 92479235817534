import React, { FunctionComponent, MouseEvent, useContext, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { guessAssetType, isEmbedded, isValidDomain, isValidHostname } from 'utils';
import { AssetLinkProps, AssetType } from 'model';
import { assetInfoPopupVar, EmbeddedWindow } from 'components';
import punycode from 'punycode/punycode.es6';
import { ComponentPluginContext } from 'appContexts';

let handlingMouseClick = false;

const AssetLink: FunctionComponent<AssetLinkProps> = ({
  value,
  linkValue,
  url,
  children,
  forcedType,
  style,
}) => {
  const history = useHistory();
  const { isPrinterFriendly } = useContext(ComponentPluginContext);
  let assetValue = (linkValue || value)?.toString();
  const assetType = forcedType || guessAssetType(assetValue);
  const isDomain = assetType === AssetType.Domain;
  const isHostname = assetType === AssetType.Hostname;
  if ((isDomain || isHostname) && assetValue) {
    assetValue = punycode.toUnicode(assetValue);
  }

  useEffect(() => {
    if (!handlingMouseClick) {
      handleMouseClick();
    }
    return () => {
      if (assetInfoPopupVar().assetValue) {
        assetInfoPopupVar({});
      }
    };
  }, [assetValue]);

  function to() {
    if (url) return url;
    // Handle org names like AT&T
    const encodedAssetValue =
      assetType === 'org' || !assetType ? encodeURIComponent(assetValue) : assetValue;
    return assetType
      ? `/assetreport?${assetType}=${encodedAssetValue}`
      : `/assets?org=${encodedAssetValue}`;
  }

  function onHover(event: MouseEvent) {
    // Make a copy since React reuses the same event object.
    event = { ...event };
    if (event.type === 'mouseenter') {
      assetInfoPopupVar({ assetValue, event });
    } else {
      if (!isCursorInsidePopup(event)) {
        assetInfoPopupVar({});
      }
    }
  }

  function onClick(event: MouseEvent<HTMLAnchorElement>) {
    event.stopPropagation();
    if (event.metaKey && event.shiftKey) {
      if (isEmbedded()) {
        history.push(to());
      } else {
        EmbeddedWindow.show(to());
        assetInfoPopupVar({});
        event.preventDefault();
      }
    }
  }

  const linkString = useMemo(() => {
    return (
      children ??
      ((isDomain || isHostname) && typeof value === 'string' ? punycode.toUnicode(value) : value)
    );
  }, [children, isDomain, isHostname, value]);

  if (!value && !children) {
    return <span>Unspecified</span>;
  }

  const isInvalidDomain = typeof value === 'string' && isDomain && !isValidDomain(value);
  const isInvalidHostname = typeof value === 'string' && isHostname && !isValidHostname(value);
  return isInvalidDomain || isInvalidHostname || isPrinterFriendly ? (
    <span>{linkString}</span>
  ) : (
    <Link
      title={linkString}
      style={style}
      className="uk-link"
      to={to}
      onMouseEnter={onHover}
      onMouseOut={onHover}
      onClick={onClick}
    >
      {linkString}
    </Link>
  );
};

function isCursorInsidePopup(event: MouseEvent) {
  const popup = document.getElementById('app-popup');
  if (!popup) {
    return false;
  }
  const bbox = popup.getBoundingClientRect();
  if (event.clientX < bbox.x || event.clientX > bbox.x + bbox.width) {
    return false;
  } else if (event.clientY < bbox.y || event.clientY > bbox.y + bbox.height) {
    return false;
  } else {
    return true;
  }
}

function handleMouseClick() {
  document.addEventListener('click', () => assetInfoPopupVar({}));
  handlingMouseClick = true;
}

export default AssetLink;
