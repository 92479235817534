import React from 'react';
import { ComponentPluginName, Spinner } from 'components';
import { useAssetQuery } from 'hooks';
import { ALERT_TYPES, AssetType, createGenericNotification, UserInvestigation } from 'model';
import { gql } from '@apollo/client';
import Investigation from '../investigations/Investigation';
import { addSectionToInvestigation, isRouterId } from 'utils';
import { Redirect } from 'react-router-dom';

const assetQuery = gql`
  query RouterTitleData($routerIdSet: [String]!) {
    routerData(routerIdSet: $routerIdSet) {
      routerId
      routerAlias {
        asn {
          asn
          orgName
        }
        vendor {
          name
          os
          osVersion
        }
      }
    }
  }
`;

export default function RouterReport({ assetValue }: { assetValue: string }) {
  const { asset, loading } = useAssetQuery(AssetType.Router, assetValue, assetQuery);
  const assetTitle = asset?.routerAlias?.asn?.orgName;
  const assetName = asset?.routerId ?? 'Router';

  const params = { assetValue, assetName };

  const newInvestigation: UserInvestigation = {
    title: assetTitle ?? 'Ownership Pending',
    description: '',
    subtitle: asset?.routerId ?? '',
    sections: [],
  };

  if (!isRouterId(assetValue)) {
    createGenericNotification('invalid-asset', {
      alertType: ALERT_TYPES.error,
      message: `${assetValue} is not a valid router id.`,
      title: 'Invalid Router',
    });
    return <Redirect to="/" />;
  }

  if (!asset) return loading ? <Spinner /> : <Investigation investigation={newInvestigation} />;

  addSectionToInvestigation(newInvestigation, ComponentPluginName.RouterAbout, params, 'About');
  if (asset?.routerAlias?.vendor) {
    addSectionToInvestigation(
      newInvestigation,
      ComponentPluginName.RouterDeviceInformation,
      params,
      'Device Information',
    );
  }
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.RouterTracerouteSummary,
    params,
    'Traceroute Summary',
  );
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.RouterIPInterfaces,
    params,
    'Aliased IPs',
  );

  return loading ? <Spinner /> : <Investigation investigation={newInvestigation} />;
}
