import React, { Fragment } from 'react';
import {
  abbreviatedCount,
  getCountryName,
  getIPOrRouterIdentifier,
  isViewOnlyNonTemplate,
} from 'utils';
import { CardProps, AssetLink, MPLSIcon } from 'components';
import { formatPercentage } from '../plugins/country/CountrySummary';

// Icons
import { ReactComponent as RouterIcon } from 'svg/experimental/router.svg';
import { ReactComponent as Head } from 'svg/direction/tracerouteEdge/head.svg';
import { ReactComponent as Tail } from 'svg/direction/tracerouteEdge/tail.svg';
import { ReactComponent as Line } from 'svg/direction/tracerouteEdge/extendable-line.svg';
import { ReactComponent as Arrowhead } from 'svg/direction/chevron-east.svg';
import { ReactComponent as RouteCount } from 'svg/experimental/flow.svg';
import { ReactComponent as Country } from 'svg/experimental/country.svg';
import { ReactComponent as PrefixIcon } from 'svg/experimental/prefix.svg';
import { ReactComponent as Unchecked } from 'svg/selection/checkbox-unchecked.svg';
import { ReactComponent as Checked } from 'svg/selection/checkbox-checked.svg';
import { ReactComponent as Dot } from 'svg/experimental/dot.svg';

const iconProps = {
  className: 'uk-margin-small-right property-svg asset-link',
  style: { width: '16px' },
};

export function TracerouteEdgeCard({ card, selected, onSelect }: CardProps) {
  if (!card) {
    return;
  }
  const {
    router1,
    router2,
    ip1,
    ip2,
    asn1,
    asn2,
    collectorDistribution,
    edgeTpfxTraceCount,
    ttlToTarget,
  } = card;

  const srcIPOrRouterCountry = getCountryName(card.country1);
  const dstIPOrRouterCountry = getCountryName(card.country2);
  const srcAsset = router1 ? router1?.routerId : ip1?.ip;
  const dstAsset = router2 ? router2?.routerId : ip2?.ip;
  const srcOrgName = router1 ? asn1?.[0]?.orgName : ip1?.orgName;
  const dstOrgName = router2 ? asn2?.[0]?.orgName : ip2?.orgName;
  const srcMPLS = router1?.routerAlias?.behaviors?.find(
    (behaviorObj) => behaviorObj.type === 'mpls',
  )?.names;
  const dstMPLS = router2?.routerAlias?.behaviors?.find(
    (behaviorObj) => behaviorObj.type === 'mpls',
  )?.names;
  const dstPrefix = card.tpfx?.prefix;
  const srcCountry = getCountryName(
    collectorDistribution?.length > 0 && collectorDistribution[0]?.country,
  );
  const dstCountry = getCountryName(card.tpfxCountry);
  const traceCount = abbreviatedCount(edgeTpfxTraceCount);
  const countryCount = card.countryCount + (card.countryCount === 1 ? ' country' : ' countries');
  const cardSelected = selected?.includes(getIPOrRouterIdentifier(card)) ?? false;
  const tracePercentage = formatPercentage((edgeTpfxTraceCount / card.tpfxTraceCount) * 100);
  const viewOnly = isViewOnlyNonTemplate();

  return (
    <div
      className={`card traceroute ${cardSelected ? 'selected' : ''} ${viewOnly ? 'disabled' : ''}`}
      onClick={() => {
        onSelect(card);
      }}
    >
      <div className="uk-flex">
        <div className="side-panel">
          <div className="uk-text-right">
            <div className="property-title">Src</div>
            <div className="uk-flex uk-flex-right hop-asset">
              {cardSelected ? <Checked className="checkbox" /> : <Unchecked className="checkbox" />}
              {srcMPLS ? (
                <MPLSIcon types={srcMPLS} iconProps={iconProps} />
              ) : (
                router1?.routerId && <RouterIcon {...iconProps} />
              )}
              <AssetLink value={srcAsset} linkValue={srcAsset} />
            </div>
          </div>
        </div>
        <div className="uk-flex uk-margin-medium-top uk-position-relative middle-panel">
          <Head />
          <Line style={{ transform: 'scaleX(1.11) translateX(6px)' }} />
          <Arrowhead style={{ transform: 'translateX(3px)', width: '22px' }} />
          <Tail />
          <div className="uk-position-absolute uk-position-center">
            <div className="head-to-tail-properties" title="Edge Trace Count">
              <RouteCount />
              {traceCount}
            </div>
            <div className="head-to-tail-properties">{tracePercentage}%</div>
          </div>
        </div>
        <div className="side-panel">
          <div className="uk-flex">
            <div>
              <div className="property-title">Dst</div>
              <div className="uk-flex hop-asset">
                {dstMPLS ? (
                  <MPLSIcon types={dstMPLS} iconProps={iconProps} />
                ) : (
                  router2?.routerId && <RouterIcon {...iconProps} />
                )}
                <AssetLink value={dstAsset} linkValue={dstAsset} />
              </div>
            </div>
            <div>
              <div className="property-title uk-margin-medium-left">TTL</div>
              <div className="uk-flex">
                <div className="dot-spacer">
                  <Dot />
                </div>
                {ttlToTarget}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-flex uk-flex-between">
        <div className="side-panel uk-text-right">{srcOrgName}</div>
        <div className="side-panel">{dstOrgName}</div>
      </div>
      <div className="uk-flex uk-flex-between">
        <div className="side-panel uk-text-right">{srcIPOrRouterCountry}</div>
        <div className="side-panel">{dstIPOrRouterCountry}</div>
      </div>
      <div className="uk-flex uk-flex-between">
        <div className="side-panel">
          {srcCountry && (
            <Fragment>
              <div className="property-title uk-text-right">From</div>
              <div className="uk-text-right property-svg">
                <Country />
                {srcCountry}
              </div>
              <div className="uk-text-right">{countryCount}</div>
            </Fragment>
          )}
        </div>
        <div className="side-panel">
          <div className="property-title">To</div>
          <div className="property-svg asset-link">
            <PrefixIcon />
            <AssetLink value={dstPrefix} linkValue={dstPrefix} />
          </div>
          <div>{dstCountry}</div>
        </div>
      </div>
    </div>
  );
}
