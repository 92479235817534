import React, { Fragment, useState } from 'react';
import { humanizePercentage } from 'utils';
import AssetLink from './AssetLink';
import { ReactComponent as VisibilityIcon } from 'svg/actions/eye-open.svg';
import { ReactComponent as StabilityIcon } from 'svg/experimental/activity.svg';

type ASProps = {
  asset: Record<string, any>;
};

export default function AS({ asset }: ASProps) {
  if (!asset) {
    return null;
  }
  const orgName = asset.orgName || asset.org?.orgName;
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <AssetLink linkValue={asset.asn} value={`AS${asset.asn}`} />
      {orgName && <span style={{ marginLeft: 5 }}>{orgName}</span>}
    </span>
  );
}

export function ASMetric({ asset, metric }: { asset: Record<string, any>; metric: number }) {
  if (!asset) {
    return null;
  }
  const orgName = asset.orgName || asset.org?.orgName;

  return (
    <Fragment>
      {
        <Fragment>
          <td>{humanizePercentage(metric)}</td>
        </Fragment>
      }
      <td>
        <div className="uk-flex uk-flex-middle uk-text-nowrap">
          <AssetLink linkValue={asset.asn} value={`AS${asset.asn}`} />
          {orgName && (
            <Fragment>
              <span className="app-origin-dot uk-margin-small-left uk-margin-small-right">
                &#8729;
              </span>
              <div className="uk-text-muted">{orgName}</div>
            </Fragment>
          )}
        </div>
      </td>
    </Fragment>
  );
}

const LOW_CUTOFF = 0.5;

function OriginMetric({
  origin,
  visibility,
  uptime,
}: { origin: Record<string, any>; visibility: number; uptime: number }) {
  const [expanded, setExpanded] = useState(false);

  if (!origin) {
    return null;
  }

  const orgName = origin.orgName || origin.org?.orgName;

  // Style AS Metric Circle
  const visibilityClass = visibility < LOW_CUTOFF ? 'app-origin-metric-empty' : '';
  const uptimeClass = uptime < LOW_CUTOFF ? 'app-origin-metric-empty' : '';
  const wrapperClass = expanded ? 'app-origin app-origin-expanded' : 'app-origin';

  return (
    <Fragment>
      <div>
        <div className={wrapperClass} onClick={() => setExpanded((current) => !current)}>
          <div className="app-origin-metric">
            <div className="app-origin-metric-ring" />
            <div className={`app-origin-metric-half app-origin-metric-left ${visibilityClass}`} />
            <div className={`app-origin-metric-half app-origin-metric-right ${uptimeClass}`} />
          </div>
          {expanded && (
            <Fragment>
              <div className="uk-flex uk-flex-middle">
                <VisibilityIcon className="uk-margin-xsmall-right" width={10} height={10} />
                {humanizePercentage(visibility)}
              </div>
              <div className="app-origin-divider" />
              <div className="uk-flex uk-flex-middle">
                <StabilityIcon className="uk-margin-xsmall-right" width={10} height={10} />
                {humanizePercentage(uptime)}
              </div>
            </Fragment>
          )}
        </div>
      </div>
      <div className="uk-text-nowrap">
        <AssetLink linkValue={origin.asn} value={`AS${origin.asn}`} />
      </div>
      {orgName && (
        <Fragment>
          <span className="app-origin-dot">&#8729;</span>
          <div className="uk-text-nowrap uk-text-muted">{orgName}</div>
        </Fragment>
      )}
    </Fragment>
  );
}

type ASOrigin = {
  asn: number;
  orgName: string | undefined;
  peerVisibility: number;
  uptime: number;
};

export function ASOrigins({ origins }: { origins: ASOrigin[] }) {
  return (
    <div>
      {origins.map((origin: ASOrigin) => (
        <div className="app-origin-wrapper" key={origin.asn}>
          <OriginMetric origin={origin} visibility={origin.peerVisibility} uptime={origin.uptime} />
        </div>
      ))}
    </div>
  );
}
