import { initFilterString, NewTableColumn } from 'utils';
import { useContext, useEffect, useState } from 'react';
import { ComponentPluginContext } from 'appContexts';

export function useServerAssetFiltering(
  existing: string,
  tabId: string | undefined,
  columnOptions: NewTableColumn[],
) {
  const { getPluginConfig } = useContext(ComponentPluginContext);

  const [filter, setFilter] = useState(() =>
    initFilterString(existing, tabId ? getPluginConfig()[tabId] : getPluginConfig(), columnOptions),
  );

  // Update filters whenever plugin config changes
  useEffect(() => {
    setFilter(
      initFilterString(
        existing,
        tabId ? getPluginConfig()[tabId] : getPluginConfig(),
        columnOptions,
      ),
    );
  }, [getPluginConfig, tabId, existing, columnOptions]);

  return { filter, setFilter };
}
