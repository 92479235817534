import AsyncLock from 'async-lock';

const asyncLock = new AsyncLock();

export function wrapWithAsyncLock(asyncLockKey: string, func: () => any) {
  return async () => await asyncLock.acquire(asyncLockKey, func);
}

export async function withAsyncLock(asyncLockKey: string, func: () => any) {
  return await asyncLock.acquire(asyncLockKey, func);
}

export function asyncSleep(ms: number) {
  return new Promise((resolve) => window.setTimeout(resolve, ms));
}
