import { useEffect, useState } from 'react';
import { validateQuery } from 'utils';
import { AdvancedQuery } from 'model';
import { ComponentPluginName } from 'components';

export function useQueryValidation(query: AdvancedQuery, plugin: string, isUnlocked?: boolean) {
  const [messages, setMessages] = useState<string[] | undefined>();

  useEffect(() => {
    let active = true;
    if (plugin.startsWith('advancedquery') && plugin !== ComponentPluginName.QueryKeystoneTile) {
      setNewMessages();
    } else {
      setMessages(undefined);
    }
    return () => {
      active = false;
    };

    async function setNewMessages() {
      const newMessages = await validateQuery(query, isUnlocked);
      if (!active) {
        return;
      }
      setMessages(newMessages);
    }
  }, [query, plugin, isUnlocked]);

  return messages;
}
