import React, { MouseEvent } from 'react';
import { AssetInfo, DisplayElement, Popup } from 'components';
import { makeVar, useReactiveVar } from '@apollo/client';

export type AssetInfoPopupSelection = {
  assetValue?: string;
  event?: MouseEvent;
};

export const assetInfoPopupVar = makeVar<AssetInfoPopupSelection>({});

export function AssetInfoPopup() {
  const { assetValue, event } = useReactiveVar(assetInfoPopupVar);

  function onMouseOut() {
    assetInfoPopupVar({});
  }

  return (
    <Popup event={event}>
      {assetValue && (
        <div onMouseOut={onMouseOut}>
          <AssetInfo
            assetValue={assetValue}
            displayElements={[DisplayElement.Title, DisplayElement.About]}
          />
        </div>
      )}
    </Popup>
  );
}
