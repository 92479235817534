import { gql } from '@apollo/client';
import { useAssetQuery } from 'hooks';
import { AssetType } from 'model';

const assetQuery = gql`
  query PrefixReportData($prefixSet: [String]!) {
    prefixData(prefixSet: $prefixSet) {
      prefix
      org {
        orgName
        source
      }
      geo {
        city
        countryDivision
        country
        anycast
        latLon
        source
      }
      attributesReputation {
        prefix
        reputationScore
      }
      attributesServices {
        prefix 
        services {
          name
          region
          type
          tags
          publishedPrefix
        }
      }   
    }
  }
`;

export function usePrefixQuery(assetValue: string) {
  const { asset, loading, error } = useAssetQuery(AssetType.Prefix, assetValue, assetQuery);
  return { asset, loading, error };
}
