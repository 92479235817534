import Select, { StylesConfig } from 'react-select';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  reactTableHeaderSelectDropdownStyle,
  reactDisabledTableHeaderSelectDropdownStyle,
} from 'components';
import { ReactComponent as IndeterminateCheck } from 'svg/selection/checkbox-indeterminate.svg';
import { ReactComponent as Dot } from 'svg/experimental/dot.svg';
import { ReactComponent as Close } from 'svg/actions/x-fill.svg';
import { ReactComponent as Unchecked } from 'svg/selection/checkbox-unchecked.svg';
import { ReactComponent as Checked } from 'svg/selection/checkbox-checked.svg';
import { isViewOnlyNonTemplate } from 'utils';
import { DropdownIndicator } from 'components';

type AttributeAnalyzerProps = {
  attributes: SummaryAttribute[];
  attributeName: string;
  metricName: Record<string, string>;
  filter?: string;
  onRowClick?: any;
  hovered?: string;
  onRowHover?: any;
  dataFormat?: any;
  dataFormatOptions?: any;
  onDataFormatChange?: Function;
  advancedSettings?: boolean; // this should only be true for non MultiAttributeAnalyzer instances
  selected?: string[];
  toggleAllAttributes?: Function;
};

export type SummaryAttribute = {
  value: string;
  label: any;
  selectable: boolean;
  pillLabel?: string;
  metric: {
    pct: number;
    count: string | number;
  };
  metricFormatted: {
    pct: string;
  };
};

export function AttributeAnalyzer({
  attributes,
  attributeName,
  metricName,
  filter,
  onRowClick,
  hovered,
  onRowHover,
  dataFormat,
  dataFormatOptions,
  onDataFormatChange,
  advancedSettings = false,
  selected,
  toggleAllAttributes,
}: AttributeAnalyzerProps) {
  const tableRef = useRef<HTMLDivElement>(null);
  const dataFormatRef = useRef<HTMLTableCellElement>(null);
  const [paddingRight, setPaddingRight] = useState<string>('0px');
  const highestPercentage = attributes
    ? Math.max(
        ...attributes?.map((attribute) => parseFloat(attribute?.metric[metricName?.accessor])),
      )
    : 100;
  const viewOnly = isViewOnlyNonTemplate();

  // if toggleAllAttributes is defined, then all attributes should have a truthy value for selectable
  const selectableAttributes = attributes?.filter((attribute) => {
    return attribute.selectable;
  });

  // On filter change scroll matching rows into view
  useEffect(() => {
    if (tableRef.current && filter) {
      const row = tableRef.current.querySelector(`#${CSS.escape(filter)}`);
      row && row.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  }, [filter]);

  // Adjusts the styling of the table header to re-align the column headers with the column body if the table body shows a scrollbar
  useEffect(() => {
    const tableBody = tableRef.current.querySelector('tbody');
    const scrollbarWidth = tableBody.offsetWidth - tableBody.scrollWidth;
    setPaddingRight(scrollbarWidth > 0 ? scrollbarWidth + 'px' : '0');
  }, [attributes]);

  const rowHoverCallback = useCallback(
    (value?: string) => {
      onRowHover && onRowHover(value);
    },
    [onRowHover],
  );

  const dataFormatCallback = useCallback(
    (value?: any) => {
      onDataFormatChange && onDataFormatChange(value);
    },
    [onDataFormatChange],
  );

  return (
    <div ref={tableRef} className="attribute-analyzer">
      <table>
        <thead>
          <tr>
            {advancedSettings && attributes && (
              <th
                onClick={() => {
                  toggleAllAttributes();
                }}
              >
                {selected?.length > 0 && selected?.length < selectableAttributes.length ? (
                  <IndeterminateCheck className="checkbox" />
                ) : selected?.length && selected.length === selectableAttributes.length ? (
                  <Checked className="checkbox" />
                ) : (
                  <Unchecked className="checkbox" />
                )}
              </th>
            )}
            <th key={attributeName} className="uk-text-left">
              {attributeName}
            </th>
            <th
              ref={dataFormatRef}
              key={metricName.name}
              className="uk-text-right"
              style={{ paddingRight }}
            >
              {dataFormatOptions.length === 1 ? (
                metricName.name
              ) : (
                <Select
                  value={dataFormat}
                  options={dataFormatOptions}
                  onChange={(selection: any) => dataFormatCallback(selection)}
                  styles={
                    (viewOnly
                      ? reactDisabledTableHeaderSelectDropdownStyle()
                      : reactTableHeaderSelectDropdownStyle()) as StylesConfig
                  }
                  blurInputOnSelect={true}
                  // menuIsOpen={true} // this setting is useful for debugging to force show the dropdown
                  isSearchable={false}
                  isDisabled={viewOnly}
                  components={{ DropdownIndicator }}
                />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {attributes &&
            attributes.map((attribute: SummaryAttribute) => {
              const { value, label, metric, metricFormatted } = attribute;
              const metricVal = metric[metricName.accessor];
              const metricFormattedVal = metricFormatted[metricName.accessor];
              const rowHighlighted = filter === value || (!filter && hovered === value);
              const rowSelected = selected?.includes(value) ?? false;
              const hasNestedAttributes = Array.isArray(metric);
              const style = {
                '--fill-percentage': `${parseFloat(metricVal) ? (parseFloat(metricVal) / highestPercentage) * 100 : 0}%`,
              } as React.CSSProperties;
              // If attribute is nested (aka grouped) format and return nested rows
              if (hasNestedAttributes) {
                const rows = [
                  <tr id={value} key={value} className="attribute-analyzer-group-header">
                    <td key={value}>{label}</td>
                    <td className="uk-text-right" key={metricFormattedVal}>
                      {metricFormattedVal}
                    </td>
                  </tr>,
                ];
                metric.forEach((childAttribute: SummaryAttribute) => {
                  const { value: childValue, label: childLabel } = childAttribute;
                  const childMetricFormatted = childAttribute.metricFormatted[metricName.accessor];
                  const childRowHighlighted =
                    filter === childValue || (!filter && hovered === childValue);
                  const isBucketedRow = !childValue.startsWith('Additional');
                  rows.push(
                    <tr
                      id={childValue}
                      key={`${value}-${childValue}`}
                      className={childRowHighlighted ? 'attribute-analyzer-row-highlight' : ''}
                      onClick={() => {
                        onRowClick &&
                          isBucketedRow &&
                          onRowClick({ label: childValue, value: childValue });
                      }}
                      onMouseEnter={() => rowHoverCallback(childValue)}
                      onMouseLeave={() => rowHoverCallback()}
                    >
                      <td key={childValue}>{childLabel}</td>
                      <td className="uk-text-right" key={childMetricFormatted}>
                        {childMetricFormatted}
                      </td>
                    </tr>,
                  );
                });
                return rows;
              }

              return (
                <tr
                  id={value}
                  key={value}
                  // highlight > selected > default fill
                  className={`${viewOnly && selected ? 'app-cursor-disabled' : 'app-cursor-pointer'} ${rowHighlighted ? 'attribute-analyzer-row-highlight' : rowSelected ? 'attribute-analyzer-row-selected' : 'attribute-analyzer-row-fill'} ${onRowHover ? '' : 'attribute-analyzer-row-hover'}`}
                  onClick={() => {
                    onRowClick && onRowClick(attribute);
                  }}
                  onMouseEnter={() => rowHoverCallback(value)}
                  onMouseLeave={() => rowHoverCallback()}
                  style={style}
                >
                  {advancedSettings && (
                    <td>
                      {rowSelected ? (
                        <Checked
                          className={`checkbox ${viewOnly ? 'view-only' : ''} ${!attribute.selectable ? 'unselectable' : ''}`}
                        />
                      ) : (
                        <Unchecked
                          className={`checkbox ${viewOnly ? 'view-only' : ''} ${!attribute.selectable ? 'unselectable' : ''}`}
                        />
                      )}
                    </td>
                  )}
                  <td
                    key={label}
                    title={typeof label === 'string' ? label : undefined}
                    style={{ overflow: 'hidden' }}
                  >
                    {label}
                  </td>
                  {dataFormat?.label === 'Count' ? (
                    <td className="uk-text-right" key="count">
                      {metric.count}
                    </td>
                  ) : (
                    <td className="uk-text-right" key={metricFormattedVal}>
                      {metricFormattedVal}
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="attribute-analyzer-footer">
        <ResultsCounter
          attributesCount={attributes?.length || 0}
          selectedCount={selected?.length || 0}
        />
      </div>
      {selected?.length > 0 && (
        <div className="selected-footer">
          {selected
            .filter((value) => {
              return attributes?.find((attribute) => attribute.value === value);
            })
            .map((value) => {
              const attribute = attributes?.find((attribute) => attribute.value === value);
              const { pillLabel, label } = attribute;
              return (
                <div
                  key={value}
                  className={`selected-pill ${viewOnly ? 'view-only' : ''}`}
                  onClick={() => onRowClick(attribute)}
                  title={pillLabel ?? label}
                >
                  <div className="selected-pill-label">{pillLabel ?? label}</div>
                  {!viewOnly && <Close />}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );

  function ResultsCounter({ attributesCount, selectedCount }) {
    const resultText = `${attributesCount} result${attributesCount > 1 || !attributesCount ? 's' : ''}`;
    const selectedText = `${selectedCount || 0} selected`;
    return (
      <div className="uk-flex uk-flex-middle">
        {resultText}
        {selectedCount > 0 && (
          <div className="uk-flex uk-flex-middle">
            <div className="uk-flex uk-flex-middle dot-spacer">
              <Dot />
            </div>
            <div className="selected-text">{selectedText}</div>
          </div>
        )}
      </div>
    );
  }
}
