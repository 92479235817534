import TabbedContentConfig from './TabbedContentConfig';
import TableColumnConfig from './TableColumnConfig';
import QueryViewConfigGraph from './QueryViewConfigGraph';
import HideUnselected from './HideUnselected';
import HistoryConfig from './HistoryConfig';
import PortsConfig from './PortsConfig';

export enum ViewConfigName {
  TabbedContentConfig = 'TabbedContentConfig',
  TableColumnConfig = 'TableColumnConfig',
  QueryViewConfigGraph = 'QueryViewConfigGraph',
  HideUnselected = 'HideUnselected',
  HistoryConfig = 'HistoryConfig',
  PortsConfig = 'PortsConfig',
}

export const viewConfigComponents = {
  [ViewConfigName.TabbedContentConfig]: TabbedContentConfig,
  [ViewConfigName.TableColumnConfig]: TableColumnConfig,
  [ViewConfigName.QueryViewConfigGraph]: QueryViewConfigGraph,
  [ViewConfigName.HideUnselected]: HideUnselected,
  [ViewConfigName.HistoryConfig]: HistoryConfig,
  [ViewConfigName.PortsConfig]: PortsConfig,
};
