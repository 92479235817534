import localforage from 'localforage';

function initializeLocalForage() {
  localforage.config({
    name: 'platform',
    driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
  });
}

export default initializeLocalForage;
