import React, { Suspense, useMemo } from 'react';
import { Spinner, viewConfigComponents } from 'components';

export function KeystoneViewConfig({
  onUpdate,
  configComponent,
}: { onUpdate: any; configComponent: string }) {
  const ConfigComponent = useMemo(() => {
    if (configComponent) {
      return viewConfigComponents[configComponent];
    } else {
      return undefined;
    }
  }, [configComponent]);

  return (
    <div className="hide-in-pdf">
      {ConfigComponent && (
        <Suspense fallback={<Spinner ratio={2} position="center" />}>
          <div className="subsection-label">options</div>
          <ConfigComponent params={{ onUpdate }} />
        </Suspense>
      )}
    </div>
  );
}
