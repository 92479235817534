import { DocumentNode } from '@apollo/client';
import { useComponentPluginQuery } from 'hooks';
import { Asset, AssetType } from 'model';

const assetQueryArgs: Record<AssetType, string> = {
  [AssetType.IP]: 'ipSet',
  [AssetType.AS]: 'asnSet',
  [AssetType.Prefix]: 'prefixSet',
  [AssetType.IX]: 'exchangeSet',
  [AssetType.Facility]: 'facilitySet',
  [AssetType.Country]: 'countrySet',
  [AssetType.Router]: 'routerIdSet',
  [AssetType.Org]: 'name',
  [AssetType.Domain]: 'domainSet',
  [AssetType.Hostname]: 'hostSet',
};

export function useAssetQuery(assetType: AssetType, assetValue: string, query: DocumentNode) {
  const { loading, data, error } = useComponentPluginQuery(query, {
    variables: {
      [assetQueryArgs[assetType]]: assetType === AssetType.Org ? assetValue : [assetValue],
    },
    skip: !assetValue,
  });

  const results = data && Object.values(data as Record<string, any>)[0];
  const asset = assetType === AssetType.Org ? results : results ? (results[0] as Asset) : undefined;
  return { asset, loading, error };
}
