import React, {
  Fragment,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  APP_CONTENT_WIDTH,
  capitalizeFirstLetter,
  constructPrunedColumnConfig,
  getColumnIdentifier,
  NewTableColumn,
  repopulateColumnConfig,
  TableColumnConfigOptionsByPlugin,
} from 'utils';
import UIkit from 'uikit';
import { cloneDeep } from 'lodash';
import { ReactComponent as DragIcon } from 'svg/actions/drag-default.svg';
import { ComponentPluginContext } from 'appContexts';
import TabbedContentConfig from './TabbedContentConfig';
import { QueryViewConfigProps } from 'components/advancedquery/QueryViewConfig';

export default function TableColumnConfig({
  configParams,
}: { configParams?: QueryViewConfigProps }) {
  const sortableRef = useRef<HTMLUListElement>(null);
  const [width, setWidth] = useState(0);
  const parent = useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const { pluginName, updatePluginConfig, getPluginConfig } = useContext(ComponentPluginContext);

  const { onUpdateAdvancedQuery, configOptions, advancedQuery } = configParams ?? {};

  const tabOptions = useMemo(() => {
    if (!TableColumnConfigOptionsByPlugin[pluginName]) return [];
    return Object.keys(TableColumnConfigOptionsByPlugin[pluginName]);
  }, [pluginName]);

  const defaultTabLabel = tabOptions[0];

  const tabLabel = useMemo(() => {
    return getPluginConfig()?.tab ?? defaultTabLabel ?? '';
  }, [getPluginConfig, defaultTabLabel]);

  const tabId = useMemo(() => {
    if (!TableColumnConfigOptionsByPlugin[pluginName]) return;
    return TableColumnConfigOptionsByPlugin[pluginName][tabLabel]?.tabId;
  }, [tabLabel, pluginName]);

  const columnOptions = useMemo(() => {
    if (configOptions?.tableColumns) return configOptions?.tableColumns;
    if (!TableColumnConfigOptionsByPlugin[pluginName]) return [];
    return TableColumnConfigOptionsByPlugin[pluginName][tabLabel]?.columns;
  }, [pluginName, tabLabel, configOptions]);

  const columnsObj = useMemo(() => {
    if (!!advancedQuery) {
      return repopulateColumnConfig(
        advancedQuery?.viewConfig?.columnConfig?.columns,
        columnOptions,
      );
    }
    if (!tabId) {
      return getPluginConfig()?.columns
        ? repopulateColumnConfig(getPluginConfig()?.columns, columnOptions)
        : columnOptions;
    }
    return getPluginConfig()[tabId]?.columns
      ? repopulateColumnConfig(getPluginConfig()[tabId]?.columns, columnOptions)
      : columnOptions;
  }, [columnOptions, tabId, getPluginConfig, advancedQuery]);

  const activeColumns = useMemo(() => columnsObj?.filter((col) => col.active), [columnsObj]);
  const inactiveColumns = useMemo(() => columnsObj?.filter((col) => !col.active), [columnsObj]);

  const onUpdateColumnConfig = useCallback(
    (newColumns) => {
      if (onUpdateAdvancedQuery) {
        onUpdateAdvancedQuery({
          ...advancedQuery,
          viewConfig: {
            ...advancedQuery?.viewConfig,
            columnConfig: { columns: constructPrunedColumnConfig(newColumns, false) },
          },
        });
      } else {
        let columnConfig = constructPrunedColumnConfig(newColumns, true);
        let newConfig;
        if (tabId) {
          newConfig = { ...getPluginConfig(), [tabId]: { columns: columnConfig } };
        } else {
          newConfig = { ...getPluginConfig(), columns: columnConfig };
        }
        updatePluginConfig(newConfig, true);
      }
    },
    [onUpdateAdvancedQuery, updatePluginConfig, getPluginConfig, advancedQuery, tabId],
  );

  useEffect(() => {
    function onColumnDragReorder() {
      const children = sortableRef.current?.children || [];
      const newActiveColumns: any = [];
      for (let i = 0; i < children.length; i++) {
        const columnName = children[i].getAttribute('data-id');
        if (columnName) {
          newActiveColumns.push(
            columnsObj.find((col) => `${getColumnIdentifier(col)}` === columnName),
          );
        }
      }
      const newColumnConfig = [...newActiveColumns, ...inactiveColumns];
      onUpdateColumnConfig(newColumnConfig);
    }

    if (sortableRef.current) {
      return UIkit.util.on(sortableRef.current, 'moved', onColumnDragReorder);
    }
  }, [sortableRef, onUpdateColumnConfig, inactiveColumns, columnsObj]);

  function onChangeColumnStatus(column: NewTableColumn) {
    const index = columnsObj.findIndex(
      (col) => `${getColumnIdentifier(col)}` === `${getColumnIdentifier(column)}`,
    );
    const newColumns = cloneDeep(columnsObj);
    const active = !columnsObj[index].active;
    newColumns[index] = { ...columnsObj[index], active };
    onUpdateColumnConfig(newColumns);
  }

  return (
    <div className="hide-in-pdf">
      {tabOptions.length > 1 && (
        <div className="uk-margin-bottom">
          <TabbedContentConfig
            options={tabOptions.map((option) => ({
              label: capitalizeFirstLetter(option),
              value: option,
            }))}
          />
        </div>
      )}
      <div ref={parent} className="config-option">
        {activeColumns?.length > 0 && (
          <Fragment>
            <div className="uk-margin-remove-bottom">Columns</div>
            <ul
              ref={sortableRef}
              className={`uk-grid ${width >= APP_CONTENT_WIDTH ? 'uk-child-width-1-4' : 'uk-child-width-auto'}`}
              uk-sortable="cls-custom: ib-section-dragging uk-box-shadow-small; handle: .uk-sortable-handle"
            >
              {activeColumns.map((column: NewTableColumn) => {
                const columnKey = `${getColumnIdentifier(column)}`;
                return (
                  <li key={columnKey} data-id={columnKey}>
                    <div className="uk-card column-tile">
                      <DragIcon className="column-tile-handle uk-sortable-handle uk-text-center" />
                      <input
                        onChange={(ev) => onChangeColumnStatus(column)}
                        className="uk-checkbox uk-margin-small-left uk-margin-medium-right"
                        style={{ marginTop: 0 }}
                        type="checkbox"
                        checked
                      />
                      {column.Header as ReactElement}
                    </div>
                  </li>
                );
              })}
            </ul>
          </Fragment>
        )}
        {inactiveColumns?.length > 0 && (
          <Fragment>
            <div className="uk-margin-remove-bottom">Additional Fields</div>
            <ul
              className={`uk-grid ${width >= APP_CONTENT_WIDTH ? 'uk-child-width-1-4' : 'uk-child-width-auto'}`}
            >
              {inactiveColumns.map((column: NewTableColumn) => (
                <li key={`${getColumnIdentifier(column)}`}>
                  <span className="column-tile">
                    <input
                      onChange={(ev) => onChangeColumnStatus(column)}
                      className="uk-checkbox uk-margin-small-left uk-margin-medium-right"
                      type="checkbox"
                    />
                    {column.Header as ReactElement}
                  </span>
                </li>
              ))}
            </ul>
          </Fragment>
        )}
      </div>
    </div>
  );
}
