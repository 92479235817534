import React, { Fragment } from 'react';
import { ReactComponent as LER } from 'svg/network/network-external.svg';
import { ReactComponent as LSR } from 'svg/network/network-internal.svg';
import { ReactComponent as LR } from 'svg/network/network-all.svg';

export function RouterCardsTooltip() {
  return (
    <Fragment>
      <p className="uk-margin-remove-top uk-margin-remove-bottom uk-text-muted">
        When available, device information and MPLS type are shown. MPLS type is indicated by the
        following icons.
      </p>
      <div className="uk-flex uk-flex-middle uk-text-muted uk-margin-top">
        <LER width={14} height={14} />
        <div className="uk-margin-left">Label Edge Router (LER)</div>
      </div>
      <div className="uk-flex uk-flex-middle uk-text-muted">
        <LSR width={14} height={14} />
        <div className="uk-margin-left">Label Switch Router (LSR)</div>
      </div>
      <div className="uk-flex uk-flex-middle uk-text-muted">
        <LR width={14} height={14} />
        <div className="uk-margin-left">LER and LSR</div>
      </div>
    </Fragment>
  );
}
