export function getVersion() {
  return process.env.REACT_APP_VERSION;
}

export function getMajorVersionNumber() {
  return getVersion().split('.')[0];
}

export function getMinorVersionNumber() {
  return getVersion().split('.')[1];
}

export function getPatchVersionNumber() {
  return getVersion().split('.')[2];
}

export function hasMajorVersionDifference(version: string) {
  if (!version) return true;
  return getMajorVersionNumber() !== version.split('.')[0];
}

export function hasMinorVersionDifference(version: string) {
  if (!version) return true;
  return hasMajorVersionDifference(version) || getMinorVersionNumber() !== version.split('.')[1];
}
