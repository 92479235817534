import React from 'react';
import { addSectionToInvestigation, getCountryCode, getCountryName } from 'utils';
import { useCountryQuery } from 'hooks';
import { ComponentPluginName } from 'components';
import { ALERT_TYPES, createGenericNotification, UserInvestigation } from 'model';
import Investigation from '../investigations/Investigation';
import { Redirect } from 'react-router-dom';

export default function CountryReport({ assetValue }: { assetValue: string }) {
  const countryCode = getCountryCode(assetValue);
  const { asset } = useCountryQuery(countryCode);
  const assetName = getCountryName(countryCode);

  const params = { assetValue: countryCode, assetName };

  const newInvestigation: UserInvestigation = {
    title: assetName,
    subtitle: '',
    description: '',
    sections: [],
  };

  if (!countryCode) {
    createGenericNotification('invalid-asset', {
      alertType: ALERT_TYPES.error,
      message: `${assetValue} is not a valid country or country code.`,
      title: 'Invalid Country',
    });
    return <Redirect to="/" />;
  }

  if (!asset) return <Investigation investigation={newInvestigation} />;

  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.CountryMetrics,
    params,
    'Rankings',
  );
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.CountrySummary,
    params,
    'Summary',
  );
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.CountryDetailsTable,
    params,
    'Assets',
  );

  return <Investigation investigation={newInvestigation} />;
}
