import { ALERT_TYPES, UserInvestigation } from 'model';
import { authenticatedFetch, makeUniqueId } from 'utils';
import { acknowledgeNotification, createGenericNotification } from 'model';
import { gql } from '@apollo/client';
import { getGraphQLClient } from '../gql';

const archivePdfMutation = gql`
mutation($investigationId: String!, $dataType: String!, $data: String!, $keepExisting: Boolean) {
  saveSupportingData(input: {investigationId: $investigationId, dataType: $dataType, data: $data, keepExisting: $keepExisting}) {
    investigationId
    timestamp
  }
}
`;

export const getArchivedPdfQuery = gql`
query($investigationId: String!) {
  supportingData(investigationId: $investigationId) {
    dataObjects {
      data
      dataType
      timestamp
    }
  }
}
`;

export const deleteArchivedPdfMutation = gql`
mutation($investigationId: String!, $dataType: String!, $filter: String!) {
  deleteSupportingData(input: { investigationId: $investigationId, dataType: $dataType, filter: $filter }) {
    investigationId
    deletedCount
  }
}
`;

export function havePdfService() {
  return process.env.REACT_APP_PDF_ENDPOINT !== undefined;
}

export function generatePdf({
  investigation,
  setDownloading,
  setArchiveData,
  pdfData,
  discardLatest,
}: {
  investigation: UserInvestigation;
  setDownloading: Function;
  setArchiveData?: Function;
  pdfData?: string;
  discardLatest?: boolean;
}) {
  const id = investigation?.id;
  const pdfServiceEndpoint = process.env.REACT_APP_PDF_ENDPOINT;

  let origin = window.location.origin;
  let url = `${window.location.href}&print=true`;
  if (origin.includes('localhost') && !pdfServiceEndpoint?.includes('localhost')) {
    url = url.replace(origin, process.env.REACT_APP_PLATFORM_ENDPOINT);
  }
  setDownloading && setDownloading(true);

  if (pdfData) {
    // Case for a specific archive. We've been given some pdf data, just generate it as a doc
    downloadSpecificArchive();
  } else {
    // Case for a new, immediate, pdf generation
    authenticatedFetch(
      `${pdfServiceEndpoint}/${id ?? makeUniqueId()}?url=${encodeURIComponent(url)}`,
    )
      .then((result: any) => {
        if (!result.ok || !pdfServiceEndpoint) {
          throw new Error(`${result.status}`);
        }
        return result.blob();
      })
      .then((data: any) => {
        if (setArchiveData) {
          // Case for archiving the newly created pdf
          generateNewPdfArchive(data);
        } else {
          // Case for immediately downloading the newly created pdf
          downloadNewPdf(data);
        }
      })
      .catch((err: any) => {
        console.error(err);
        setDownloading && setDownloading(false);
        createGenericNotification('pdf-download-service-error', {
          alertType: ALERT_TYPES.error,
          message: 'Unable to connect to pdf service. Please try again later',
          title: 'PDF Service Unavailable',
          onClear: () => acknowledgeNotification('pdf-download-service-error'),
        });
      });
  }

  function downloadSpecificArchive() {
    try {
      let newPdfData = convertBase64ToBlob(pdfData);
      const objUrl = window.URL.createObjectURL(newPdfData);
      let link = document.createElement('a');
      link.href = objUrl;
      setDownloading && setDownloading(false);
      link.download = investigation?.title ?? 'Mosaic.pdf';
      link.click();
      // For Firefox it is necessary to delay revoking the ObjectURL.
      window.setTimeout(() => {
        window.URL.revokeObjectURL(objUrl);
      }, 250);
    } catch (e) {
      setDownloading && setDownloading(false);
      console.error(`Error retrieving archived pdf data: ${e}`);
      createGenericNotification('pdf-data-query-error', {
        alertType: ALERT_TYPES.error,
        message: 'Unable to retrieve data for archived mosaic',
        title: 'Archived Mosaic Error',
        onClear: () => acknowledgeNotification('pdf-download-service-error'),
      });
    }
  }

  function generateNewPdfArchive(data: any) {
    let reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = async function () {
      let base64data = reader.result;
      try {
        const getArchivesResult = await getPdfArchives(investigation.id);
        const existingArchives = getArchivesResult.data?.supportingData?.dataObjects;
        const deleteTimestamp = discardLatest ? existingArchives[0]?.timestamp : undefined;
        const mutationResult = await getGraphQLClient().mutate({
          mutation: archivePdfMutation,
          variables: {
            investigationId: investigation.id,
            data: base64data,
            dataType: 'pdf',
            keepExisting: true,
          },
        });
        if (mutationResult.errors) {
          throw new Error(mutationResult.errors[0].message);
        }
        if (discardLatest) {
          await deletePdfArchive(investigation.id, deleteTimestamp ? [deleteTimestamp] : []);
        }
        const timestamp = mutationResult?.data?.saveSupportingData?.timestamp ?? new Date();
        setArchiveData([
          ...existingArchives.filter((archive) => archive?.timestamp !== deleteTimestamp),
          { timestamp, dataType: 'pdf', data: base64data },
        ]);
        setDownloading && setDownloading(false);
      } catch (e) {
        setDownloading && setDownloading(false);
        createGenericNotification('pdf-data-mutation-error', {
          alertType: ALERT_TYPES.error,
          message: 'There was an error archiving the mosaic. Please try again later.',
          title: 'Archived Mosaic Error',
          onClear: () => acknowledgeNotification('pdf-data-mutation-error'),
        });
        console.error(`Error freezing mosaic as pdf: ${e}`);
      }
    };
  }

  function downloadNewPdf(data: any) {
    const newBlob = new Blob([data], { type: 'application/pdf' });
    const objUrl = window.URL.createObjectURL(newBlob);
    let link = document.createElement('a');
    link.href = objUrl;
    setDownloading && setDownloading(false);
    link.download = `${investigation?.title}.pdf` ?? 'Mosaic.pdf';
    link.click();
    // For Firefox it is necessary to delay revoking the ObjectURL.
    window.setTimeout(() => {
      window.URL.revokeObjectURL(objUrl);
    }, 250);
  }
}

export async function deletePdfArchive(investigationId: string, timestamps: string[]) {
  const timestampFilterString = timestamps
    .map(
      (ts, index) =>
        `'${new Date(ts).getTime() / 1000}'${index === timestamps.length - 1 ? '' : ','}`,
    )
    .join(' ');
  await getGraphQLClient().mutate({
    mutation: deleteArchivedPdfMutation,
    variables: {
      investigationId,
      dataType: 'pdf',
      filter: `[{'should': [{'timestamp': [${timestampFilterString}]}]}]`,
    },
  });
}

export async function getPdfArchives(investigationId: string) {
  return await getGraphQLClient().query({
    query: getArchivedPdfQuery,
    variables: { investigationId },
  });
}

export function convertBase64ToBlob(base64: string) {
  const byteCharacters = atob(base64.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: 'application/pdf' });
}
