import React, { useContext, useMemo, useState, useCallback, useEffect, ReactElement } from 'react';
import L, { latLng, Map } from 'leaflet';
import { GeoPoint } from 'model';
import { TileLayer } from 'react-leaflet';
import { ComponentPluginContext } from 'appContexts';
import { debounce, isEqual } from 'lodash';
import MarkerClusterGroup from 'components/MarkerClusterGroup';
import { AssetLink } from 'components';
import { isEqualLatLng, calculateMaxWidth, DEBOUNCE_TIMEOUT } from 'utils';
import { GeoMapContainer } from './GeoMapContainer';

type ClusterBreakpoints = {
  medium: number;
  large: number;
};

type ClusterMapProps = {
  geoPoints: GeoPoint[] | undefined;
  breakpoints: ClusterBreakpoints;
  dataname: string;
  datanamePlural: string;
};

export function ClusterMap({ geoPoints, breakpoints, dataname, datanamePlural }: ClusterMapProps) {
  const [reactiveWidth, setReactiveWidth] = useState(() => calculateMaxWidth());
  const tileUrl = process.env.REACT_APP_TILE_ENDPOINT;
  const { getPluginConfig, updatePluginConfig, isPrinterFriendly } =
    useContext(ComponentPluginContext);
  const [map, setMap] = useState<Map | undefined>(undefined);

  const DEFAULT_VIEWPORT = useMemo(() => {
    let center = undefined;
    let zoom = undefined;
    if (geoPoints?.length === 0) {
      zoom = 2;
      center = latLng([0, 0]);
    } else if (geoPoints) {
      const bounds = new L.LatLngBounds(geoPoints.map((geoPoint: GeoPoint) => geoPoint.latLon));
      center = bounds?.getCenter();
      zoom = getBoundsZoomLevel(bounds, { height: 400, width: window.innerWidth - 200 });
    }
    return {
      zoom,
      center,
    };
  }, [geoPoints]);

  const viewport = useCallback(
    () => getPluginConfig().viewport ?? DEFAULT_VIEWPORT,
    [getPluginConfig, DEFAULT_VIEWPORT],
  );

  const setSelectedMarker = useCallback(
    (selectedMarker: any) => {
      map.fire('markerSelected', { selectedMarker });
      highlightCluster(map, selectedMarker);
    },
    [map],
  );

  const onViewportChanged = useCallback(
    (view: any) => {
      updatePluginConfig({ ...getPluginConfig(), viewport: view });
      const isZoomChange = viewport().zoom !== view.zoom;
      isZoomChange && setSelectedMarker(undefined);
    },
    [getPluginConfig, updatePluginConfig, viewport, setSelectedMarker],
  );

  const onClusterClick = useCallback(
    (cluster: any) => {
      const { layer, target } = cluster;
      let bottomCluster = layer;
      while (bottomCluster._childClusters.length === 1) {
        bottomCluster = bottomCluster._childClusters[0];
      }

      const isSingleParent =
        bottomCluster._zoom === target._maxZoom && bottomCluster._childCount === layer._childCount;
      if (!isSingleParent) {
        layer.zoomToBounds();
      } else {
        setSelectedMarker(layer);
      }
    },
    [setSelectedMarker],
  );

  const onMarkerClicked = useCallback(
    (e: any) => {
      setSelectedMarker(e.target);
    },
    [setSelectedMarker],
  );

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setReactiveWidth(calculateMaxWidth());
    }, DEBOUNCE_TIMEOUT);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  if (!geoPoints || !tileUrl) {
    return null;
  }

  return (
    <div
      className={`cluster-map-container${isPrinterFriendly ? ' printable-geo-map-container' : ''}`}
      style={{ width: reactiveWidth }}
    >
      <ClusterMapSidebar
        map={map}
        geoPoints={geoPoints}
        breakpoints={breakpoints}
        dataname={dataname}
        datanamePlural={datanamePlural}
      />
      <GeoMapContainer
        ref={setMap}
        viewport={viewport()}
        onViewportChanged={onViewportChanged}
        onClick={() => setSelectedMarker(undefined)}
      >
        <TileLayer url={tileUrl} />
        <MarkerClusterGroup
          geoPoints={geoPoints}
          onClusterClick={onClusterClick}
          onMarkerClicked={onMarkerClicked}
          breakpoints={breakpoints}
        />
      </GeoMapContainer>
    </div>
  );
}

function ClusterMapSidebar({
  map,
  geoPoints,
  dataname,
  datanamePlural,
}: ClusterMapProps & { map: Map }) {
  const [sidebarItems, setSidebarItems] = useState<any>([]);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<any | undefined>();

  const updateSidebarItems = useCallback(() => {
    if (!map) {
      return;
    }
    const newMapBounds = map.getBounds();
    const visiblePoints = geoPoints?.filter((item: any) => newMapBounds.contains(item.COORDINATES));
    // Only updates if current sidebar items not equal to currently visible points
    setSidebarItems((current: any) => (!isEqual(current, visiblePoints) ? visiblePoints : current));
  }, [geoPoints, map]);

  // Sidebar items that are actually rendered. Visible items filtered to include highlighted point (if applicable)
  const sidebarContent = useMemo(() => {
    const selectedPosition = selectedMarker?._latlng;
    return selectedPosition
      ? geoPoints?.filter((item: any) => isEqualLatLng(item.COORDINATES, selectedPosition))
      : sidebarItems;
  }, [sidebarItems, selectedMarker, geoPoints]);

  useEffect(() => {
    if (map) {
      map.on('zoomend, moveend', updateSidebarItems);
      map.on('markerSelected', (event: any) => {
        event.selectedMarker && setSidebarVisible(true);
        setSelectedMarker(event.selectedMarker);
      });
      map.on('zoomend', () => highlightCluster(map, undefined));
      updateSidebarItems();
    }
  }, [map, updateSidebarItems]);

  return (
    <div className="cluster-map-sidebar">
      {sidebarVisible ? (
        <div>
          <div className="cluster-map-items-header">
            {`${sidebarContent?.length} ${sidebarContent?.length === 1 ? dataname : datanamePlural}`}
            <button
              style={{ float: 'right' }}
              type="button"
              data-uk-icon="close"
              onClick={() => setSidebarVisible(false)}
            />
          </div>
          <div className="cluster-map-items">
            {sidebarContent?.map((item: GeoPoint) => (
              <div key={item.key} className="cluster-map-item">
                {item.info as ReactElement}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <button
          className="cluster-map-sidebar-button"
          onClick={() => setSidebarVisible(true)}
          data-uk-icon="chevron-right"
          type="button"
        />
      )}
    </div>
  );
}

// Suggest zoom level based on cluster bounds
function getBoundsZoomLevel(bounds: any, mapDim: any) {
  var WORLD_DIM = { height: 256, width: 256 };
  var ZOOM_MAX = 21;

  function latRad(lat: number) {
    var sin = Math.sin((lat * Math.PI) / 180);
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx: number, worldPx: number, fraction: number) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  var latFraction = latRad(bounds.getNorth() - bounds.getSouth()) / Math.PI;

  var lngDiff = bounds.getEast() - bounds.getWest();
  var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.max(Math.min(latZoom, lngZoom, ZOOM_MAX) - 1, 2);
}

function highlightCluster(map: Map, selectedMarker: any) {
  map.eachLayer((layer: any) => {
    const icon = layer._icon;
    if (icon) {
      if (!selectedMarker || layer === selectedMarker) {
        icon.classList.remove('cluster-faded');
      } else {
        icon.classList.add('cluster-faded');
      }
    }
  });
}

export function formatIPMapData(items: any[]) {
  return items
    .map((ipObj: any) => {
      const { ip, orgName, geo } = ipObj;
      if (geo?.latLon && !geo?.anycast && ip) {
        const latLon = geo?.latLon.split(',');
        const lat = parseFloat(latLon[0]);
        const lon = parseFloat(latLon[1]);
        const { city, country, countryDivision } = geo || {};
        const formattedCity = city ? `${city},` : '';
        const formattedState = country === 'US' && countryDivision ? `${countryDivision}, ` : '';
        const location = geo ? `${formattedCity} ${formattedState} ${country}` : null;

        return {
          key: ip,
          label: ip,
          COORDINATES: [lat, lon],
          latLon: [lat, lon],
          info: (
            <div className="cluster-map-item" key={ip}>
              <div>
                <AssetLink value={ip} />
              </div>
              <div style={{ fontSize: '12px' }}>{orgName}</div>
              <div className="uk-text-muted" style={{ fontSize: '12px' }}>
                {location}
              </div>
            </div>
          ),
        };
      }
      return null;
    })
    .filter((ipObj: any) => ipObj)
    .sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export function formatFacilityMapData(items: any[]) {
  return (
    items
      .filter((facObj: any) => facObj?.geo?.latLon !== 'None,None')
      .map((facObj: any) => {
        if (facObj) {
          const { name, geo, id } = facObj;
          const { city, country, countryDivision } = geo || {};
          const location = geo
            ? `${city}, ${country === 'US' && countryDivision ? countryDivision : ''} ${country}`
            : null;

          const latLon = geo?.latLon.split(',');
          if (!latLon) return {};
          const lat = parseFloat(latLon[0]);
          const lon = parseFloat(latLon[1]);
          return {
            key: id,
            label: name,
            COORDINATES: [lat, lon],
            latLon: geo?.latLon.split(','),
            info: (
              <div>
                <div>
                  <AssetLink value={name} linkValue={id} />
                </div>
                <div className="uk-text-muted" style={{ fontSize: '12px' }}>
                  {location}
                </div>
              </div>
            ),
          };
        }
        return undefined;
      })
      .filter((facObj: any) => facObj)
      .sort((a: any, b: any) => a.label.localeCompare(b.label)) || []
  );
}

export function formatRouterMapData(items: any[]) {
  return (
    items
      .filter((routerObj: any) => routerObj?.geo?.latLon !== 'None,None' && routerObj?.geo?.latLon)
      .map((routerObj: any) => {
        const { asn, geo, routerId } = routerObj;
        const name = asn?.orgName;
        const latLon = geo?.latLon.split(',');
        const lat = parseFloat(latLon[0]);
        const lon = parseFloat(latLon[1]);

        return {
          key: routerId,
          label: name || 'Ownership Pending',
          COORDINATES: [lat, lon],
          latLon: geo?.latLon.split(','),
          info: (
            <div>
              <div>
                <AssetLink value={name} linkValue={routerId} />
              </div>
              <div className="uk-text-muted" style={{ fontSize: '12px' }}>
                {routerId}
              </div>
            </div>
          ),
        };
      })
      .filter((routerObj: any) => routerObj)
      .sort((a: any, b: any) => a.label.localeCompare(b.label)) || []
  );
}
