export enum ReputationCategories {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  None = 'None',
}

export function formatReputationScore(score: number) {
  if (score > 0.1) {
    return ReputationCategories.High;
  } else if (score > 0.01) {
    return ReputationCategories.Medium;
  } else if (score > 0) {
    return ReputationCategories.Low;
  } else {
    return ReputationCategories.None;
  }
}

export function getReputationClass(reputation: string) {
  if (reputation === ReputationCategories.None) {
    return 'uk-alert-success';
  } else if (reputation === ReputationCategories.Low) {
    return 'app-tag-yellow';
  } else if (reputation === ReputationCategories.Medium) {
    return 'uk-alert-warning';
  } else if (reputation === ReputationCategories.High) {
    return 'uk-alert-danger';
  }
  return '';
}

export function getReputationOptions() {
  return [
    { label: ReputationCategories.High, value: ReputationCategories.High },
    { label: ReputationCategories.Medium, value: ReputationCategories.Medium },
    { label: ReputationCategories.Low, value: ReputationCategories.Low },
    { label: ReputationCategories.None, value: ReputationCategories.None },
  ];
}

export function getReputationColor(reputation: string) {
  const docStyle = getComputedStyle(document.documentElement);
  if (reputation === ReputationCategories.None) {
    return {
      color: docStyle.getPropertyValue('--app-success-color'),
      backgroundColor: docStyle.getPropertyValue('--app-success-background'),
    };
  } else if (reputation === ReputationCategories.Low) {
    // Colors not shared with UI-kit which is why definitions are explicit
    return {
      color: '#E1B209', //Galiano
      backgroundColor: '#FFF8E2', // Solitaire
    };
  } else if (reputation === ReputationCategories.Medium) {
    return {
      color: docStyle.getPropertyValue('--app-warning-color'),
      backgroundColor: docStyle.getPropertyValue('--app-warning-background'),
    };
  } else if (reputation === ReputationCategories.High) {
    return {
      color: docStyle.getPropertyValue('--app-danger-color'),
      backgroundColor: docStyle.getPropertyValue('--app-danger-background'),
    };
  }
  return {};
}
