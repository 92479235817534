import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { SidebarContext } from 'appContexts';
import { debounce } from 'lodash';
import {
  DEBOUNCE_TIMEOUT,
  findExistingSectionIndex,
  getCurrentInvestigation,
  parseInvestigationSectionId,
  saveInvestigation,
} from 'utils';
import { UserInvestigationSection } from 'model';

export function TitleInput({ section }: { section: UserInvestigationSection }) {
  const { localAnnotations, setLocalAnnotations, focusedTile } = useContext(SidebarContext);
  const isFocused = useMemo(() => {
    const [, params] = parseInvestigationSectionId(section.id);
    const [, focusedParams] = parseInvestigationSectionId(focusedTile.id);
    return focusedParams.uuid === params.uuid;
  }, [focusedTile.id, section.id]);
  const localTitle = useMemo(() => {
    if (isFocused) {
      return localAnnotations?.focusedTitle ?? section.title;
    } else {
      return section.title;
    }
  }, [localAnnotations?.focusedTitle, section.title, isFocused]);

  const debounceTitle = useRef(
    debounce((newTitle: string) => onUpdateTitle(newTitle), DEBOUNCE_TIMEOUT),
  ).current;
  const onUpdateTitle = useCallback(
    (newTitle: string) => {
      const investigation = getCurrentInvestigation();
      if (!investigation || !section) {
        return;
      }
      const [, params] = parseInvestigationSectionId(section.id);

      const i = findExistingSectionIndex(investigation, params.uuid);
      investigation.sections[i] = {
        ...investigation.sections[i],
        title: newTitle,
        saved: new Date(),
      };
      saveInvestigation(investigation);
    },
    [section],
  );

  return (
    <div className="ib-section-title-input">
      <label className="input-label">Title</label>
      <input
        className="uk-input uk-margin-bottom"
        placeholder="Title"
        value={localTitle ?? section.title}
        onChange={(e) => {
          const newTitle = e.target.value;
          if (isFocused) {
            setLocalAnnotations((curr) => ({ ...curr, focusedTitle: newTitle }));
          }
          debounceTitle(newTitle);
        }}
      />
    </div>
  );
}
