import { sortBy, sumBy } from 'lodash';
import { GraphLink, GraphNode, GraphNodeState } from 'model';
import { OptionsOrGroups } from 'react-select';

export type GraphNodeInput = {
  id: string;
  label?: string;
  parent?: GraphNode;
  children?: GraphNode[];
  depth?: number;
  value?: number;
  category?: string;
  categoryValue?: string;
  state?: GraphNodeState;
};

export function newGraphNode({
  id,
  label,
  depth,
  value,
  parent,
  children,
  category,
  categoryValue,
  state,
}: GraphNodeInput): GraphNode {
  if (label === undefined) {
    label = id;
  }

  if (depth === undefined) {
    depth = 0;
  }

  if (value === undefined) {
    value = 1;
  }

  if (children === undefined) {
    children = [];
  }

  if (state === undefined) {
    state = {};
  }

  return {
    id,
    label,
    depth,
    value,
    parent,
    children,
    category,
    categoryValue,
    state,
  };
}

export function calcNodeDescendantCounts(node: GraphNode) {
  node.state.descendantCount = 0;
  const children: GraphNode[] = node.children || node.state.children || [];
  children.forEach((child) => {
    node.state.descendantCount += 1 + calcNodeDescendantCounts(child);
  });
  return node.state.descendantCount;
}

export function setNodeCollapsed(node: GraphNode, collapsed: boolean) {
  if (isLeafNode(node)) {
    return;
  }

  function sum(node: GraphNode): number {
    return 1 + sumBy(node.state.children || node.children, sum);
  }
  node.state.collapsed = collapsed;
  node.value = sum(node);
  if (!node.state.children) {
    node.state.children = node.children;
  }
  node.children = collapsed ? undefined : node.state.children;
}

export function setAllNodesCollapsed(node: GraphNode, exemptedNodeIds?: string[]) {
  node.children?.forEach((n: GraphNode) => setAllNodesCollapsed(n, exemptedNodeIds));
  if (node.depth > 0) {
    setNodeCollapsed(node, exemptedNodeIds?.includes(node.id) ? false : true);
  }
}

export function isLeafNode(node: GraphNode) {
  return !node.children && !node.state.children;
}

export function getNodeForId(rootNode: GraphNode, nodeId: string) {
  for (const node of traverseGraph(rootNode)) {
    if (node.id === nodeId) {
      return node;
    }
  }
  return undefined;
}

export function getAllNodeIds(node: GraphNode) {
  const all: string[] = [node.id];
  const children: GraphNode[] = node.children || node.state.children || [];
  children.forEach((child) => {
    all.push(...getAllNodeIds(child));
  });
  return all;
}

export function* traverseGraph(
  node: GraphNode,
  collapsedOnly: boolean = false,
): Generator<GraphNode> {
  if (node) {
    yield node;
    const children = collapsedOnly ? node.children : node.children || node.state.children;
    if (children) {
      for (const child of children) {
        yield* traverseGraph(child, collapsedOnly);
      }
    }
  }
}

export function getGraphSelectOptions(
  rootNode: GraphNode,
  graphCategories: Record<string, any>[],
): OptionsOrGroups<any, any> {
  const options: Record<string, any> = {};
  for (const node of traverseGraph(rootNode)) {
    const category = node.category;
    if (category) {
      let categoryOptionsMap = options[category];
      if (!categoryOptionsMap) {
        categoryOptionsMap = options[category] = {};
      }
      const value = node.categoryValue as string;
      if (!categoryOptionsMap[value]) {
        categoryOptionsMap[value] = { value, label: node.label };
      }
    }
  }
  Object.keys(options).forEach(
    (category) =>
      (options[category] = sortBy(Object.values(options[category]), (option: any) =>
        option.label.toLowerCase(),
      )),
  );

  return graphCategories
    .map((category: any) => ({
      label: category.label,
      options: options[category.value],
    }))
    .filter((category) => !!category.options);
}

export function pruneGraphByEdgeWeight(
  graphRootNode: GraphNode,
  graphLinks: GraphLink[],
  pruneValue: number,
): [GraphNode, GraphLink[]] {
  const unprunedNodeMap: Record<string, GraphNode> = {};

  function addUnprunedNode(node: GraphNode) {
    let unprunedNode = unprunedNodeMap[node.id];
    if (!unprunedNode) {
      const unprunedParent = node.parent && addUnprunedNode(node.parent);
      unprunedNode = unprunedNodeMap[node.id] = {
        ...node,
        parent: unprunedParent,
        children: [],
        state: { coords: node.state.coords },
      };
      unprunedParent?.children?.push(unprunedNode);
    }
    return unprunedNode;
  }

  const prunedGraphRootNode = addUnprunedNode(graphRootNode);
  const prunedGraphLinks: GraphLink[] = [];

  let prunedIndex = Math.floor(((graphLinks.length - 1) * pruneValue) / 100);
  const prunedLinkWeight = graphLinks[prunedIndex].weight;
  graphLinks.forEach((link) => {
    if (link.weight >= prunedLinkWeight) {
      link = {
        ...link,
        source: addUnprunedNode(link.source),
        target: addUnprunedNode(link.target),
      };
      prunedGraphLinks.push(link);
    }
  });

  return [prunedGraphRootNode, prunedGraphLinks];
}

export function getGraphNodeWeightMap(links: GraphLink[]) {
  const weightMap: Record<string, any> = {};

  function addWeight(node: GraphNode, weight: number) {
    weightMap[node.id] = (weightMap[node.id] || 0) + weight;
  }

  function addWeightToBranch(node: GraphNode, weight: number) {
    addWeight(node, weight);
    while (node.parent) {
      node = node.parent;
      addWeight(node, weight);
    }
  }

  links.forEach((link) => {
    addWeightToBranch(link.source, link.weight);
    addWeightToBranch(link.target, link.weight);
  });

  return weightMap;
}

export function removeNodesFromGraph(
  graphRootNode: GraphNode,
  graphLinks: GraphLink[],
  removedNodes: string[],
): [GraphNode, GraphLink[]] {
  const removedNodeIdSet = new Set(removedNodes);
  const unprunedNodeMap: Record<string, GraphNode> = {};

  function addUnprunedNodes(node: GraphNode, parent?: GraphNode) {
    const unprunedNode = (unprunedNodeMap[node.id] = {
      ...node,
      parent,
      children: undefined,
      state: { coords: node.state.coords },
    });

    const children = (node.children || node.state.children) as GraphNode[];
    if (children) {
      (unprunedNode.children as any) = [];
      children.forEach((child) => {
        if (!removedNodeIdSet.has(child.id)) {
          (unprunedNode.children as any).push(addUnprunedNodes(child, unprunedNode));
        }
      });
    }

    return unprunedNode;
  }

  const prunedGraphRootNode = addUnprunedNodes(graphRootNode) as GraphNode;
  const prunedGraphLinks: GraphLink[] = [];

  graphLinks.forEach((link) => {
    const source = unprunedNodeMap[link.source.id];
    const target = unprunedNodeMap[link.target.id];
    if (source && target) {
      link = {
        ...link,
        source,
        target,
      };
      prunedGraphLinks.push(link);
    }
  });

  return [prunedGraphRootNode, prunedGraphLinks];
}
