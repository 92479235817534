import { gql } from '@apollo/client';
import { getGraphQLClient } from 'gql';
import { History } from 'history';
import { cloneDeep, flatten, isEmpty, isFunction, isNil, uniq } from 'lodash';
import {
  acknowledgeNotification,
  AdvancedQuery,
  advancedQueryPageVar,
  advancedQueryTemplateRegistry,
  ALERT_TYPES,
  AssetType,
  createGenericNotification,
  filterClauseOptionRegistry,
  NonCommaSeparatedTypes,
  NonPageAssetTypes,
  QueryFilterClause,
  QueryFilterClauseOption,
  QueryFilterComparator,
  QueryResolutionOption,
  QuerySelection,
  QueryValueOption,
  QueryViewField,
  QueryViewOption,
  TemplateParams,
  TemplateParam,
  UserDataset,
  UserDatasets,
  userDatasetsVar,
  userSharedDatasetsVar,
  userSharedInvestigationsVar,
} from 'model';
import {
  decodeIPOrRouterIdentifier,
  getHistory,
  getQueryClauseAssetType,
  getSearchParams,
  getTemplateAssetTypeFromId,
  getTemplateParamId,
  guessAssetType,
  hasMinorVersionDifference,
  isOutputVariable,
  isTemplate,
  isViewOnly,
  loadTemplateParamLabel,
  normalizeDomain,
  normalizeDomainLabel,
  normalizeLabelByAssetType,
  normalizeValueByAssetType,
} from 'utils';
import { getPlatformVersionNotificationMessage, legacyPluginViews } from 'components';

export const IMPORT_OPTIONS = [
  { value: 'ips', label: 'IP Data' },
  { value: 'pcap', label: 'PCAP Data' },
];

const COVERING_PREFIX_PIPELINE_CAP = 10000;
export const CAPPED_QUERIES = ['PortsQuery'];
export const MAX_EXPORT_QUERY_SIZE = 1000;
export const DEFAULT_PLAYGROUND_QUERY_SIZE = 10;

export const ERROR_CODES = {
  timeout: 'REQUEST_TIMED_OUT',
};

export function getAdvancedQueryTemplate(value: AdvancedQuery | QuerySelection | string) {
  const advancedQueryTemplate = tryGetAdvancedQueryTemplate(value);
  if (!advancedQueryTemplate) {
    throw new Error('Advanced query template not found');
  }
  return advancedQueryTemplate;
}

export function tryGetAdvancedQueryTemplate(value: AdvancedQuery | QuerySelection | string) {
  if (typeof value === 'string' || value instanceof String) {
    return advancedQueryTemplateRegistry[value as string];
  }

  const selection = ((value as AdvancedQuery)?.selection || value) as QuerySelection;
  let advancedQueryTemplate;

  if (selection) {
    if (selection.typename) {
      advancedQueryTemplate = advancedQueryTemplateRegistry[selection.typename];
    } else {
      const assetType = guessAssetType(selection.value);
      if (assetType) {
        advancedQueryTemplate = advancedQueryTemplateRegistry[assetType];
      }
    }
  }

  return advancedQueryTemplate;
}

export function newAdvancedQuery(
  selection: QuerySelection,
  resolution?: string,
  view?: string,
): AdvancedQuery {
  const advancedQueryTemplate = getAdvancedQueryTemplate(selection);
  if (advancedQueryTemplate.newAdvancedQuery) {
    return advancedQueryTemplate.newAdvancedQuery(selection, resolution);
  } else {
    if (!resolution) {
      resolution = advancedQueryTemplate.resolutionOptions[0].value;
    }
    return {
      selection,
      resolution,
      filterClauses: [],
      view: view ?? advancedQueryTemplate.viewOptions[resolution][0].value,
    };
  }
}

export function getResolutionOption(advancedQuery: AdvancedQuery) {
  if (!advancedQuery || !advancedQuery.resolution) {
    return undefined;
  }
  return getAllResolutionOptions(advancedQuery)?.find(
    (opt: QueryResolutionOption) => opt.value === advancedQuery.resolution,
  );
}

export function getOtherResolutionOptions(advancedQuery: AdvancedQuery) {
  if (!advancedQuery || !advancedQuery.resolution) {
    return undefined;
  }
  return getAllResolutionOptions(advancedQuery)?.filter(
    (option) => option.value !== advancedQuery?.selection?.typename,
  );
}

export function getAllResolutionOptions(advancedQuery: AdvancedQuery) {
  if (!advancedQuery || !advancedQuery) {
    return undefined;
  }
  return getAdvancedQueryTemplate(advancedQuery).resolutionOptions;
}

export function getFilterClauseOption(filterClauseOrType: QueryFilterClause | string) {
  const type = ((filterClauseOrType as QueryFilterClauseOption)?.type ||
    filterClauseOrType) as string;
  return filterClauseOptionRegistry[type];
}

export function getFilterClauseValueOptions(
  filterClauseOption: QueryFilterClauseOption,
  comparator?: QueryFilterComparator,
): QueryValueOption[] {
  const valueOptions = filterClauseOption.valueOptions;
  if (isFunction(valueOptions)) {
    return valueOptions(comparator);
  } else {
    return valueOptions || [];
  }
}

export type NewFilterClauseSettings = {
  comparator?: QueryFilterComparator;
  value?: string | string[];
  isUnlocked?: boolean;
};

export function newFilterClause(
  filterClauseOption: QueryFilterClauseOption,
  { comparator, value, isUnlocked }: NewFilterClauseSettings = {},
): QueryFilterClause {
  return {
    type: filterClauseOption?.type,
    comparator: comparator || filterClauseOption?.comparatorOptions[0],
    value,
    isUnlocked,
  };
}

export function getViewOption(advancedQuery: AdvancedQuery) {
  if (!advancedQuery) {
    return undefined;
  }

  // Logic to support mosaics containing a legacy plugin, and convert it to the new one
  return getAllViewOptions(advancedQuery)?.find(
    (opt: QueryViewOption) =>
      opt.value === (legacyPluginViews[advancedQuery.view] || advancedQuery.view),
  );
}

export function getAllViewOptions(advancedQuery: AdvancedQuery) {
  if (!advancedQuery || !advancedQuery.resolution) {
    return undefined;
  }
  return getAdvancedQueryTemplate(advancedQuery).viewOptions[advancedQuery.resolution];
}

export function formatFilter(clause: QueryFilterClause) {
  if (isNil(clause.value) && clause.comparator !== QueryFilterComparator.Exists) {
    return undefined;
  }
  const filterClauseOption = getFilterClauseOption(clause);
  let filter;
  const field = filterClauseOption ? filterClauseOption.field.split(':').slice(-1) : clause.type;
  const value = formatFilterClauseValue(clause.value);

  if (clause.comparator === QueryFilterComparator.Exists) {
    filter = `'exists': '${field}'`;
  } else if (clause.comparator === QueryFilterComparator.Includes) {
    filter = `'match_phrase': {'${field}': ${value}}`;
  } else if (clause.comparator === QueryFilterComparator.IncludesAllOf) {
    const valueArray = Array.isArray(clause.value) ? clause.value : [clause.value];
    filter = `'must': [${valueArray?.map((val: string) => `{'${field}': ${formatFilterClauseValue(val)}}`)}]`;
  } else if (
    clause.comparator === QueryFilterComparator.IsBefore ||
    clause.comparator === QueryFilterComparator.LTE
  ) {
    filter = `'${field}': {'lte': ${value}}`;
  } else if (
    clause.comparator === QueryFilterComparator.IsAfter ||
    clause.comparator === QueryFilterComparator.GTE
  ) {
    filter = `'${field}': {'gte': ${value}}`;
  } else if (clause.comparator === QueryFilterComparator.GT) {
    filter = `'${field}': {'gt': ${value}}`;
  } else if (clause.comparator === QueryFilterComparator.LT) {
    filter = `'${field}': {'lt': ${value}}`;
  } else if (clause.comparator === QueryFilterComparator.EndsWith) {
    filter = `'regexp': {'${field}': '(.*:)?${value.substring(1, value.length - 1)}'}`;
  } else {
    filter = `'${field}': ${value}`;
  }

  if (clause.comparator === QueryFilterComparator.IsNot) {
    filter = `'must_not': {${filter}}`;
  }

  return filter;
}

export function formatMultiFieldFilter(clause: QueryFilterClause, fields: string[]) {
  let filterType;

  if (
    clause.comparator === QueryFilterComparator.Is ||
    clause.comparator === QueryFilterComparator.IsOneOf
  ) {
    filterType = 'should';
  } else if (clause.comparator === QueryFilterComparator.IsNot) {
    filterType = 'must_not';
  }

  const formattedValue = formatFilterClauseValue(clause.value);
  const fieldFilters = fields.map((field: string) => `{'${field}': ${formattedValue}}`);
  return filterType && clause.value ? `'${filterType}': [${fieldFilters.join(',')}]` : undefined;
}

export function formatEdgeFieldFilter(clause: QueryFilterClause, field1: string, field2: string) {
  let filterType;
  if (
    clause.comparator === QueryFilterComparator.Includes ||
    clause.comparator === QueryFilterComparator.IncludesBothOf ||
    clause.comparator === QueryFilterComparator.IncludesOneOf ||
    clause.comparator === QueryFilterComparator.CrossBoundaryOf
  ) {
    filterType = 'should';
  } else if (clause.comparator === QueryFilterComparator.DoesNotInclude) {
    filterType = 'must_not';
  } else if (clause.comparator === QueryFilterComparator.AreBoth) {
    filterType = 'must';
  }

  let formattedValue = formatFilterClauseValue(clause.value);

  const fields = [field1, field2];
  let fieldFilters = fields.map((field: string) => `{'${field}': ${formattedValue}}`);

  // Specially format 'boundary' filters
  if (clause.comparator === QueryFilterComparator.CrossBoundaryOf) {
    const boundary1 = `{'must': [{'${field1}': ${formattedValue}}, {'must_not': {'${field2}': ${formattedValue}}}]}`;
    const boundary2 = `{'must': [{'${field2}': ${formattedValue}}, {'must_not': {'${field1}': ${formattedValue}}}]}`;
    fieldFilters = [boundary1, boundary2];
  }

  // Specially format 'includes both of' filters
  if (clause.comparator === QueryFilterComparator.IncludesBothOf) {
    const formattedValue1 = formatFilterClauseValue(clause.value?.[0]);
    const formattedValue2 = formatFilterClauseValue(clause.value?.[1]);
    const boundary1 = `{'must': [{'${field1}': ${formattedValue1}}, {'must': {'${field2}': ${formattedValue2}}}]}`;
    const boundary2 = `{'must': [{'${field1}': ${formattedValue2}}, {'must': {'${field2}': ${formattedValue1}}}]}`;
    fieldFilters = [boundary1, boundary2];
  }

  return filterType && clause.value ? `'${filterType}': [${fieldFilters.join(',')}]` : undefined;
}

export function formatProxyEdgeIDFilter(clause: QueryFilterClause) {
  const proxyIds = clause.value;

  let proxyIDFilters = [];
  for (let i = 0; i < proxyIds.length; i++) {
    // Extract each part of the proxy ID, identify it, then format the specific filter for that edge.
    // Order of src, dst, and prefix determined by QueryResultsCardsView.getIPOrRouterIdentifier().
    const [src, dst, prefix] = decodeIPOrRouterIdentifier(proxyIds[i]);
    const srcKey = formatFilterClauseValue((guessAssetType(src) + '1').toString());
    const dstKey = formatFilterClauseValue((guessAssetType(dst) + '2').toString());
    const prefixValue = formatFilterClauseValue(prefix);
    const filter = `{'must': [{${srcKey} : '${src}'}, {${dstKey}: '${dst}'}, {'tpfx.raw': ${prefixValue}}]}`;
    proxyIDFilters = [...proxyIDFilters, filter];
  }

  return proxyIDFilters.length > 0 ? `'should': [${proxyIDFilters.join(',')}]` : undefined;
}

export function formatHopIPFieldFilter(clause: QueryFilterClause) {
  let filterType;

  if (
    clause.comparator === QueryFilterComparator.Includes ||
    clause.comparator === QueryFilterComparator.IncludesOneOf
  ) {
    filterType = 'should';
  } else if (clause.comparator === QueryFilterComparator.DoesNotInclude) {
    filterType = 'must_not';
  }

  const formattedValue = formatFilterClauseValue(clause.value);

  const fields = ['ip1', 'ip2'];
  let fieldFilters = fields.map((field: string) => `{'${field}': ${formattedValue}}`);
  fieldFilters.push("{'router1': '@routerAliases.routers'}");
  fieldFilters.push("{'router2': '@routerAliases.routers'}");

  return filterType && clause.value ? `'${filterType}': [${fieldFilters.join(',')}]` : undefined;
}

export function formatFilterClauseValue(value: any) {
  function escapeGQLFilterCharacters(value: string) {
    if (!(typeof value === 'string')) return value;
    return value.replaceAll("'", "\\\\'");
  }
  if (Array.isArray(value)) {
    value = (value as string[])
      .map((item: string) => `'${escapeGQLFilterCharacters(item)}'`)
      .join(',');
    value = `[${value}]`;
  } else if (value) {
    value = `'${escapeGQLFilterCharacters(value)}'`;
  }
  return value;
}

export function normalizeFilterClauseValue(
  filterClauseOption: QueryFilterClauseOption | undefined,
  value: any,
  keepDuplicates?: boolean,
) {
  const assetType = getQueryClauseAssetType(newFilterClause(filterClauseOption));
  const normalizeValue = filterClauseOption?.normalizeValue ?? defaultNormalizeValue;
  // Make note if the clause may have commas in its values
  const isCommaProhibited = NonCommaSeparatedTypes.includes(
    assetType as AssetType | NonPageAssetTypes,
  );
  if (Array.isArray(value)) {
    return multiSelectNormalizeValue(
      value as string[],
      (val) => normalizeValue(val, assetType),
      isCommaProhibited,
      keepDuplicates,
    );
  } else if (value) {
    return normalizeValue(value, assetType);
  }
}

export async function normalizeFilterClauseLabel(
  id: string,
  value: any,
  label: any,
  filterClauseOption?: QueryFilterClauseOption,
) {
  if (!value) return undefined;
  const assetType = getTemplateAssetTypeFromId(id);
  const normalizeLabel = filterClauseOption?.normalizeLabel ?? defaultNormalizeLabel;
  let newLabel = cloneDeep(label ?? value);
  if (Array.isArray(value)) {
    for (let i = 0; i < value.length; i++) {
      if (value.length > newLabel.length) {
        if (i === newLabel.length - 1) {
          newLabel[i] = await normalizeSingleLabel(value[i], filterClauseOption?.type);
        } else if (i >= newLabel.length) {
          const extraLabel = await normalizeSingleLabel(value[i], filterClauseOption?.type);
          newLabel.push(extraLabel);
        } else {
          newLabel[i] = await normalizeSingleLabel(value[i], filterClauseOption?.type, newLabel[i]);
        }
      } else {
        newLabel[i] = await normalizeSingleLabel(value[i], filterClauseOption?.type, newLabel[i]);
      }
      if (i === value.length - 1 && value.length < newLabel.length) {
        newLabel.splice(value.length);
      }
    }
  } else {
    newLabel = await normalizeSingleLabel(value, filterClauseOption?.type, newLabel);
  }
  return newLabel;

  async function normalizeSingleLabel(value, clauseType, label?: string) {
    let newLabel = undefined;
    if ((label && label === value) || !label) {
      newLabel = await loadTemplateParamLabel(id, value, clauseType);
    }
    if (normalizeLabel) {
      newLabel = normalizeLabel(newLabel ?? label, assetType);
    }
    return newLabel ?? label;
  }
}

function defaultNormalizeValue(value: string, assetType: string) {
  return normalizeValueByAssetType(value.trim(), assetType);
}

function defaultNormalizeLabel(label: string, assetType: string) {
  return normalizeLabelByAssetType(label, assetType);
}

function multiSelectNormalizeValue(
  values: string[],
  normalizer: any,
  isCommaProhibited: boolean,
  keepDuplicates?: boolean,
) {
  if (isCommaProhibited) {
    return values.map(normalizer);
  }
  let retArray: string[] = [];
  values.forEach((val) => {
    retArray = retArray.concat(val.split(','));
  });
  const trimmedResult = retArray.map((val) => val.trim()).map(normalizer);
  return keepDuplicates ? trimmedResult : Array.from(new Set(trimmedResult));
}

export async function getFilterClauseSelections(
  value: any,
  loadValueOptions: Function,
  isMultiSelect: boolean,
) {
  const values = Array.isArray(value) ? value : [value];
  const selections = [];
  for (let i = 0; i < values.length; i++) {
    const value = values[i];
    let options: QueryValueOption[] = [];
    if (loadValueOptions) {
      options = await loadValueOptions(value);
    }
    const selection = options.find((opt: QueryValueOption) => opt.value === value);
    selections.push(selection || { label: value, value });
  }
  return isMultiSelect ? selections : selections[0];
}

export function pushDatasetAlert(messages: string[], datasetId: string, isUnlocked?: boolean) {
  if (!datasetId) return;
  const datasets = { ...userDatasetsVar(), ...userSharedDatasetsVar() };
  const selectedDataset = Object.values(datasets).find((dataset) => dataset.id === datasetId);
  if ((isViewOnlyTemplate() || isTemplate()) && !isUnlocked) {
    if (!userDatasetsVar()[datasetId]) {
      // temporary until Elastic Backend updates are made. Don't allow queries using shared
      // datasets in a template
      messages.push(
        'A selected dataset is not available. You must own any datasets you wish to use in a template.',
      );
    }
  } else if (isViewOnly()) {
    if (!selectedDataset) {
      messages.push(
        'A selected dataset is not available. Contact the mosaic owner for more information about this tile.',
      );
    }
  } else {
    if (!selectedDataset) {
      messages.push(
        'A selected dataset is not available. Ensure all the datasets referenced in this tile exist.',
      );
    }
  }
}

export const rangeComparators = [QueryFilterComparator.IsBefore, QueryFilterComparator.IsAfter];

export async function validateQuery(advancedQuery: AdvancedQuery, isUnlocked?: boolean) {
  const filterClauseSet = new Set();
  const messages: string[] = [];
  const corruptFilters: string[] = [];

  if (!advancedQuery || !advancedQuery.view) {
    messages.push('Click Update to view search results.');
    return messages;
  }

  if (isDatasetQuery(advancedQuery)) {
    if (!advancedQuery.selection.value)
      messages.push('Please select a dataset and update your query');
    if (advancedQuery.resolution === 'pcap' && advancedQuery.selection.typename !== 'pcap') {
      messages.push('PCAP tiles must be supplied with a .pcap dataset');
    }
    const selectionVal = advancedQuery?.selection?.value as any;
    pushDatasetAlert(messages, selectionVal, isUnlocked);
  }

  for (let i = 0; i < advancedQuery.filterClauses?.length && !advancedQuery.disableFilters; i++) {
    const clause = advancedQuery.filterClauses[i];
    const clauseVal = clause?.value as any;
    const filterClauseOption = getFilterClauseOption(clause);
    const label = filterClauseOption?.label;
    const filterClauseKey = `${filterClauseOption?.type}:${filterClauseOption?.field}`;
    if (label == null) {
      corruptFilters.push(clause?.type?.split(':')[1]);
    } else {
      if (
        filterClauseSet.has(filterClauseKey) &&
        !(clause.comparator && rangeComparators.includes(clause.comparator))
      ) {
        messages.push(`Specify just one ${label} filter.`);
      } else if (!clauseVal && clause.comparator !== QueryFilterComparator.Exists) {
        messages.push(`Specify a value for your ${label} filter.`);
      }
      filterClauseSet.add(filterClauseKey);
    }
    // Validate IncludesBothOf comparator
    if (clause.comparator === QueryFilterComparator.IncludesBothOf) {
      if (!Array.isArray(clauseVal) || clauseVal.length !== 2) {
        messages.push('Filters containing "includes both of" must specify exactly 2 values.');
      }
    }
    if (
      clause.comparator === QueryFilterComparator.IsInDataset &&
      typeof clause.value === 'string'
    ) {
      pushDatasetAlert(messages, clauseVal, clause.isUnlocked);
    }
    // Edge case. For traceroute edges, we need to be sure the dataset does not blow up the query.
    if (
      clause.comparator === QueryFilterComparator.IsInDataset &&
      clause.type.startsWith('TracerouteEdge') &&
      typeof clause.value === 'string'
    ) {
      const datasetId: string = clauseVal;
      const validatedDatasets = await loadValidTracerouteEdgeIPDatasetOptions();
      if (!validatedDatasets.map((dataset) => dataset.value).includes(datasetId)) {
        messages.push('Dataset is too large to be compatible with this filter.');
      }
    }
  }

  if (corruptFilters.length > 0) {
    const corruptFilterMessage = `
    Error: A previously selected filter is no longer available.
    Please select a replacement filter or remove the empty filter field to update your search. 
    Affected Filters: ${corruptFilters.join(',')}
    `;
    messages.push(corruptFilterMessage);
  }

  return messages.length > 0 ? messages : undefined;
}

export function getQuerySearchParam() {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('q') || undefined;
}

export function searchParamToNormalParams(searchParam: string | undefined) {
  try {
    const newParams = searchParam ? JSON.parse(decodeURIComponent(atob(searchParam))) : undefined;
    let version;
    if (newParams?.metadata?.platformVersion) {
      version = newParams?.metadata?.platformVersion;
      delete newParams?.metadata?.platformVersion;
    }
    return { params: newParams, metadata: { platformVersion: version } };
  } catch (e) {
    return { params: undefined, metadata: { platformVersion: undefined } };
  }
}

export function paramsToSearchParams(params: Record<string, any>) {
  let searchString = '';
  if (params)
    searchString = searchString.concat(`q=${btoa(encodeURIComponent(JSON.stringify(params)))}`);
  return searchString;
}

export function navigateToQueryResults(advancedQuery: AdvancedQuery) {
  advancedQueryPageVar({ advancedQuery, config: { keystone: { expanded: true } } });
  getHistory().push(`/advanced-search`);
}

export function updateParamsFromSearchParams() {
  const querySearchParams = getQuerySearchParam();
  let { params, metadata } = searchParamToNormalParams(querySearchParams);
  const platformVersion = metadata?.platformVersion;
  if (!params) {
    params = getDefaultSearchParams();
  }
  if (querySearchParams && hasMinorVersionDifference(platformVersion)) {
    createVersionNotification(platformVersion);
  }
  advancedQueryPageVar(params);
}

export function createVersionNotification(version: string) {
  createGenericNotification('version-difference', {
    alertType: ALERT_TYPES.warning,
    message: getPlatformVersionNotificationMessage(version),
    title: 'Version Notification',
    onClear: () => acknowledgeNotification('version-difference'),
  });
}

export function getDefaultAdvancedQuery() {
  return {
    // Default bare bones advanced query selection for no url search params
    resolution: undefined,
    selection: undefined,
    view: undefined,
  };
}

export function getDefaultSearchParams() {
  return {
    advancedQuery: getDefaultAdvancedQuery(),
    config: { keystone: { expanded: true } },
  };
}

export function updateQueryResultsUrl(history: History) {
  history.replace('/advanced-search');
}

export function isViewOnlyTemplate() {
  return (
    isViewOnly() &&
    Object.values(userSharedInvestigationsVar()).find(
      (investigation) => investigation?.sharing?.shared_key === getSearchParams().get('key'),
    )?.isTemplate
  );
}

// this is useful for components that should allow interaction with templates outside the scope of template params, but should generally behave the same as isViewOnly() otherwise. this currently applies to components that support "selection", as that is an action we want to allow users to have despite the template being in a shared state. it should be noted that any action that a user can perform here should still be ephemeral.
export function isViewOnlyNonTemplate() {
  return isViewOnly() && !isViewOnlyTemplate();
}

function getBaseDatasetOptions() {
  const viewOnly = isViewOnly();
  let userDatasets = userDatasetsVar();
  if (viewOnly) {
    userDatasets = { ...userDatasetsVar(), ...userSharedDatasetsVar() };
  }
  return userDatasets;
}

export function getBooleanValueOption() {
  return [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
  ];
}
export async function getIPDatasetValueOptions(value?: string) {
  const userDatasets = getBaseDatasetOptions();
  const template = isTemplate() || isViewOnlyTemplate();
  const showAllDatasets = isViewOnly() && !template;
  return Object.values(userDatasets)
    .filter((dataset) =>
      showAllDatasets || dataset?.id === value ? true : !dataset?.system?.hidden,
    )
    .map((userDataset: UserDataset) => ({
      label: userDataset.name,
      value: userDataset.id as string,
    }));
}

export async function loadValidTracerouteEdgeIPDatasetOptions(value?: string) {
  let options: any[] = [];
  try {
    const client = getGraphQLClient();
    const datasetSizeQuery = `query { datasetDataStats { datasets { key, data { dataId, docCount } } } }`;
    const result = await client.query({ query: gql(datasetSizeQuery), fetchPolicy: 'no-cache' });

    // Determine what IP datasets meet covering prefix pipeline cap
    const usableDatasets = new Set();
    result.data.datasetDataStats.datasets.forEach((dataset: any) => {
      let isDatasetUsable = false;
      dataset.data.forEach((dataObj: any) => {
        if (dataObj.docCount <= COVERING_PREFIX_PIPELINE_CAP) {
          isDatasetUsable = true;
        }
        isDatasetUsable && usableDatasets.add(dataset.key);
      });
    });
    let userDatasets = getBaseDatasetOptions();

    options = Object.values(userDatasets)
      .filter((dataset) =>
        isViewOnly() || dataset?.id === value ? true : usableDatasets.has(dataset.id),
      )
      .map((userDataset: UserDataset) => ({
        label: userDataset.name,
        value: userDataset.id as string,
      }));
  } catch (e) {
    console.error(e);
  }
  return options;
}

export async function loadTracerouteEdgeIPDatasetValueOptions(value?: string) {
  let userDatasets = getBaseDatasetOptions();
  const template = isTemplate() || isViewOnlyTemplate();
  const showAllDatasets = isViewOnly() && !template;
  const validDatasets = await loadValidTracerouteEdgeIPDatasetOptions(value);
  return validDatasets?.filter((dataset) =>
    showAllDatasets || dataset?.value === value
      ? true
      : !userDatasets[dataset.value]?.system?.hidden,
  );
}

export async function loadExchangeValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  let options: QueryViewOption[] = [];
  if (value.length > 2) {
    try {
      const client = getGraphQLClient();
      const suggestionsQuery = `query { suggestions(name: "${value}") { exchanges }}`;
      const result = await client.query({
        query: gql(suggestionsQuery),
        fetchPolicy: 'cache-first',
      });
      options =
        result.data.suggestions?.exchanges?.map((suggestion: any) => ({
          label: suggestion.label,
          value: suggestion.value,
        })) || [];
    } catch (e) {
      console.error(e);
    }
  }
  callback && callback(options);
  return options;
}

export async function loadFacilityValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  let options: QueryViewOption[] = [];
  if (value.length > 2) {
    try {
      const client = getGraphQLClient();
      const suggestionsQuery = `query { suggestions(name: "${value}") { facilities }}`;
      const result = await client.query({
        query: gql(suggestionsQuery),
        fetchPolicy: 'cache-first',
      });
      options =
        result.data.suggestions?.facilities?.map((suggestion: any) => ({
          label: suggestion.label,
          value: suggestion.value,
        })) || [];
    } catch (e) {
      console.error(e);
    }
  }
  callback && callback(options);
  return options;
}

export async function loadASValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  let options: QueryViewOption[] = [];
  const intValue = parseInt(value);
  if (!isNaN(intValue) || value.length > 2) {
    try {
      const client = getGraphQLClient();
      const suggestionsQuery = `query { suggestions(name: "${value}") { asns }}`;
      const result = await client.query({
        query: gql(suggestionsQuery),
        fetchPolicy: 'cache-first',
      });
      options =
        result.data.suggestions?.asns?.map((suggestion: any) => ({
          label: `AS${suggestion.value} ${suggestion.label}`,
          value: suggestion.value.toString(),
        })) || [];
    } catch (e) {
      console.error(e);
    }
  }
  callback && callback(options);
  return options;
}

export async function loadCityValueOptions(
  indexName: string,
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  let options: QueryViewOption[] = [];
  if (value.length > 2) {
    const parts = value.split(' ');
    parts[0] = capitalizeFirstLetter(parts[0]);
    const newVal = parts.reduce((str, part) => {
      return str + (str ? ' ' : '') + capitalizeFirstLetter(part);
    });
    const cityQuery = `query CityOptionsQuery{ ${indexName}(filter: "[{'regexp': {'geo.city': '${newVal}(.*)?'}}]", aggregation: "['geo.city']") {aggregation}}`;
    options = await loadValueOptions(value, cityQuery, indexName, 'geo.city');
  }
  callback && callback(options);
  return options;
}

export async function loadMaltrailBehaviorValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  const maltrailQuery = `query { attributesMaltrailRecords(aggregation: "['maltrailBehaviors']") {aggregation}}`;
  const options = await loadValueOptions(
    value,
    maltrailQuery,
    'attributesMaltrailRecords',
    'maltrailBehaviors',
  );
  callback && callback(options);
  return options;
}

export async function loadMaltrailMalwareValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  const malwareQuery = `query { attributesMaltrailRecords(aggregation: "['maltrailMalwares']") {aggregation}}`;
  const options = await loadValueOptions(
    value,
    malwareQuery,
    'attributesMaltrailRecords',
    'maltrailMalwares',
  );
  callback && callback(options);
  return options;
}

export async function loadPortCipherSuiteValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  const applicationTypeQuery = `query { portRecords(aggregation: "['probes.applicationData.tls.cipherSuite']") {aggregation}}`;
  const options = await loadValueOptions(
    value,
    applicationTypeQuery,
    'portRecords',
    'probes.applicationData.tls.cipherSuite',
  );
  callback && callback(options);
  return options;
}

export async function loadPortCurveIdValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  const applicationTypeQuery = `query { portRecords(aggregation: "['probes.applicationData.tls.curveId']") {aggregation}}`;
  const options = await loadValueOptions(
    value,
    applicationTypeQuery,
    'portRecords',
    'probes.applicationData.tls.curveId',
  );
  callback && callback(options);
  return options;
}

export async function loadPortHTTPServerValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  const applicationTypeQuery = `query { portRecords(aggregation: "['probes.applicationData.result.http.response.headers.server.raw']") {aggregation}}`;
  const options = await loadValueOptions(
    value,
    applicationTypeQuery,
    'portRecords',
    'probes.applicationData.result.http.response.headers.server.raw',
  );
  callback && callback(options);
  return options;
}

export async function loadPortJA3SValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  const applicationTypeQuery = `query { portRecords(aggregation: "['probes.applicationData.tls.ja3s']") {aggregation}}`;
  const options = await loadValueOptions(
    value,
    applicationTypeQuery,
    'portRecords',
    'probes.applicationData.tls.ja3s',
  );
  callback && callback(options);
  return options;
}

export async function loadPortSSHServerValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  const applicationTypeQuery = `query { portRecords(aggregation: "['probes.applicationData.result.ssh.serverId.software.raw']") {aggregation}}`;
  const options = await loadValueOptions(
    value,
    applicationTypeQuery,
    'portRecords',
    'probes.applicationData.result.ssh.serverId.software.raw',
  );
  callback && callback(options);
  return options;
}

export async function loadRouterVendorValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
  filter?: string,
) {
  const vendorQuery = `query { routerAliases(aggregation: "['vendor.name']"${filter ? `, filter: "${filter}"` : ''}) {aggregation}}`;
  const options = await loadValueOptions(value, vendorQuery, 'routerAliases', 'vendor.name');
  callback && callback(options);
  return options;
}

export async function loadRouterOSValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  const osQuery = `query { routerAliases(aggregation: "['vendor.os']") {aggregation}}`;
  const options = await loadValueOptions(value, osQuery, 'routerAliases', 'vendor.os');
  callback && callback(options);
  return options;
}

export async function loadServiceNameValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  const serviceNameQuery = `query { attributesServicesRecords(aggregation: "['services.name']") {aggregation}}`;
  let options = await loadValueOptions(
    value,
    serviceNameQuery,
    'attributesServicesRecords',
    'services.name',
  );
  callback && callback(options);
  return options;
}

export async function loadServiceRegionValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  const serviceNameQuery = `query { attributesServicesRecords(aggregation:"[{'field': 'services.name', 'aggs': {'regions': {'terms': {'field': 'services.region', 'size': 10000}}}}]") {aggregation}}`;
  let options: QueryViewOption[] = [];
  try {
    const client = getGraphQLClient();
    const result = await client.query({ query: gql(serviceNameQuery), fetchPolicy: 'cache-first' });
    options =
      result.data['attributesServicesRecords']?.aggregation?.['services.name']?.buckets.map(
        (bucket: any) => {
          const regionOptions = bucket?.['regions']?.buckets
            .map((region: any) => ({ label: region?.key, value: region?.key }))
            .filter((option: any) => option?.label?.toLowerCase().includes(value?.toLowerCase()));
          return { label: bucket?.key, options: regionOptions };
        },
      ) || [];
  } catch (e) {
    console.error(e);
  }
  callback && callback(options);
  return options;
}

export async function loadServiceTagsValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  const serviceTagsQuery = `query { attributesServicesRecords(aggregation:"[{'field': 'services.name', 'aggs': {'tags': {'terms': {'field': 'services.tags', 'size': 10000}}}}]") {aggregation}}`;
  let options: QueryViewOption[] = [];
  try {
    const client = getGraphQLClient();
    const result = await client.query({ query: gql(serviceTagsQuery), fetchPolicy: 'cache-first' });
    options =
      result.data['attributesServicesRecords']?.aggregation?.['services.name']?.buckets.map(
        (bucket: any) => {
          const tagOptions = bucket?.['tags']?.buckets
            .map((tag: any) => ({ label: tag?.key, value: tag?.key }))
            .filter((option: any) => option?.label?.toLowerCase().includes(value?.toLowerCase()));

          return { label: bucket?.key, options: tagOptions };
        },
      ) || [];
  } catch (e) {
    console.error(e);
  }
  callback && callback(options);
  return options;
}

export async function loadSuffixValueOptions(
  value: string,
  callback?: (options: QueryViewOption[]) => void,
) {
  const suffixQuery = `query {domainHostRecords(aggregation: "[{'field': 'suffix', 'size': 2000}]") {aggregation}}`;
  let options = await loadValueOptions(
    normalizeDomain(value),
    suffixQuery,
    'domainHostRecords',
    'suffix',
  );
  const formattedOptions = options.map((option) => ({
    ...option,
    label: normalizeDomainLabel(option.label),
    value: normalizeDomain(option.value),
  }));
  callback && callback(formattedOptions);
  return formattedOptions;
}

async function loadValueOptions(
  value: string,
  query: string,
  accessor: string,
  field: string,
  toString?: boolean,
) {
  let options: QueryViewOption[] = [];
  try {
    const client = getGraphQLClient();
    const result = await client.query({ query: gql(query), fetchPolicy: 'cache-first' });
    options =
      result.data[accessor]?.aggregation?.[field]?.buckets
        .map((bucket: any) => {
          const key = toString ? bucket?.key?.toString() : bucket?.key;
          const count = bucket?.docCount;
          return { label: key, value: key, count };
        })
        .filter((option: any) => option?.label?.toLowerCase().includes(value?.toLowerCase())) || [];
  } catch (e) {
    console.error(e);
  }
  return options;
}

export async function makeGraphQLQuery(
  advancedQuery: AdvancedQuery,
  variables: Record<string, any>,
) {
  if (!advancedQuery) {
    return gql('query { dummy }');
  }

  const resolutionOption = getResolutionOption(advancedQuery);
  const queries = getQueries(advancedQuery);
  const mainQuery = queries.pop();
  const gqlQueries: any[] = [];

  // Possible pipeline queries.
  queries.forEach((query: any, index: number) => {
    const dataIds = query.clauseWrappers
      .map((clause: any) => clause.clause.dataId)
      .filter((dataId: any) => dataId);
    const filters = query.clauseWrappers
      .map((clause: any) => clause.filter)
      .filter((filter: any) => filter);
    const parts = query.key.split('.');
    const queryName = parts.shift();
    const pipelineBinName = parts.join('.');
    const alias = `pipelineQuery${index + 1}`;
    const params = [`pipeline: "${pipelineBinName}"`];

    const advancedQueryTemplate = tryGetAdvancedQueryTemplate(queryName);
    if (advancedQueryTemplate?.getPipelineParams) {
      params.push(...advancedQueryTemplate.getPipelineParams(advancedQuery));
    }
    if (filters.length > 0 && !advancedQuery.disableFilters) {
      params.push(`filter: ${formatFiltersParam(filters)}`);
    }

    if (dataIds.length > 0) {
      const dataset = userDatasetsVar()[dataIds[0]];
      const sharedDataset = userSharedDatasetsVar()[dataIds[0]];
      const dataId = (dataset || sharedDataset)?.system?.ipsDataId;
      const isSharedDataPipeline = !dataset && sharedDataset;

      params.push(`dataId: "${dataId}"`);

      if (isSharedDataPipeline) {
        const sharedKey = getSearchParams().get('key');
        params.push(`sharedKey: "${sharedKey}"`);
      }
    }

    if (variables.sharedKey) {
      params.push(`sharedKey: $sharedKey`);
    }

    const pipelineQuery = `
      ${alias}: ${queryName}(${params.join(', ')}) {
        totalCount
      }
    `;

    gqlQueries.push(pipelineQuery);
  });

  // Main query.
  const primaryFilterClauses = mainQuery.clauseWrappers.filter(
    (clause: any) => !clause.isSecondaryFilter,
  );
  const secondaryFilterClauses = mainQuery.clauseWrappers.filter(
    (clause: any) => clause.isSecondaryFilter,
  );
  const primaryFilters = primaryFilterClauses.map((clause: any) => clause.filter);
  const secondaryFilters = secondaryFilterClauses.map((clause: any) => clause.filter);
  const params: Record<string, string> = {};
  const variableDefs: Record<string, string> = {};

  if (primaryFilters.length > 0 && !advancedQuery.disableFilters) {
    params.filter = formatFiltersParam(primaryFilters);
  }

  if (secondaryFilters.length > 0 && !advancedQuery.disableFilters) {
    params.postFilter = formatFiltersParam(secondaryFilters);
  }

  if (variables.sharedKey) {
    params.sharedKey = '$sharedKey';
    variableDefs.sharedKey = 'String';
  }

  const gqlQuery = Promise.resolve(
    resolutionOption?.makeGraphQLQuery(advancedQuery, { params, variables, variableDefs }),
  );
  gqlQueries.push(await gqlQuery);

  // Combine any pipeline queries with the main query.
  let queryBody;
  if (queries.length > 0) {
    queryBody = `
      pipelineQuery {
        ${gqlQueries.join('\n')}
      }
    `;
  } else {
    queryBody = gqlQueries[0];
  }

  if (params.cursor) {
    if (!variables.cursor) {
      variables.cursor = null;
    }

    if (variables.size == null) {
      variables.size = 10;
    }
    variableDefs.cursor = 'String';
    variableDefs.size = 'Int';
  }

  const varDefs = Object.keys(variables).map((name: string) => `$${name}: ${variableDefs[name]}`);
  const varParams = varDefs.length > 0 ? `(${varDefs.join(',')})` : '';

  const query = `
    query ${capitalizeFirstLetter(resolutionOption?.value as string)}Query${varParams} {
      ${queryBody}
    }
  `;

  console.log(query);
  return gql(query);
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function formatFiltersParam(filters: string[]) {
  filters = filters.map((f: string) => `{${f}}`);
  return `"[${filters.join(',')}]"`;
}

export function formatGraphQLQueryParams(params: Record<string, string>) {
  return Object.entries(params)
    .map(([name, value]) => `${name}: ${value}`)
    .join(',');
}

export function formatPagedGraphQLQueryParams(params: Record<string, string>) {
  params.size = '$size';
  params.cursor = '$cursor';
  return Object.entries(params)
    .map(([name, value]) => `${name}: ${value}`)
    .join(',');
}

export function getMissingDatasetError() {
  return `Please select a dataset and update your query.`;
}

export function isDatasetSelection(selection: string | undefined) {
  return selection?.startsWith('dataset');
}

export function getDatasetQueryDataset(advancedQuery: AdvancedQuery) {
  const dataset = tryGetDatasetQueryDataset(advancedQuery);
  if (!dataset) {
    throw new Error(getMissingDatasetError());
  }
  return dataset;
}

export function tryGetDatasetQueryDataset(advancedQuery: AdvancedQuery) {
  const userDatasets = userDatasetsVar();
  const sharedUserDatasets = userSharedDatasetsVar();
  const aqIdParts = advancedQuery?.selection?.value?.split(',');
  const datasetId = aqIdParts != null ? aqIdParts[1] ?? aqIdParts[0] : '';
  return userDatasets[datasetId] || sharedUserDatasets[datasetId];
}

export const unevaluatedOutputError: string =
  'Output variable does not have an evaluated value, query will not be sent.';

enum resolutionToPipelineKey {
  routers = 'routerAliases.routers',
  dnsHosts = 'domainHostRecords.hosts',
  facilities = 'facilityRecords.facilities',
  peeringExchanges = 'exchangeRecords.exchanges',
}

function getQueries(
  advancedQuery: AdvancedQuery,
  topLevelQueries?: Record<string, Record<string, any>>,
  lastClauseWrapper?: any,
) {
  const queries: Record<string, Record<string, any>> = topLevelQueries || {};

  let newAq = topLevelQueries ? advancedQuery : cloneDeep(advancedQuery);

  const toFilterClauseWrapper = (
    clause: QueryFilterClause,
    key?: String,
    pipelineLastClauseWrapper?: any,
  ) => {
    const clauseOption = getFilterClauseOption(clause);
    // The parts of the key are: <queryName>#<pipelineID>, where queryName is <index>.<pipelineTerm>
    const queryName = key ? key.split('#')[0] : clauseOption?.field.split(':')[0];
    const pipelineID = key
      ? key.split('#')[1]
      : pipelineLastClauseWrapper?.key.includes('output')
        ? pipelineLastClauseWrapper.key.split('#')[1]
        : pipelineLastClauseWrapper?.clauseOption?.field;
    if (!key) {
      key = pipelineLastClauseWrapper || pipelineID ? `${queryName}#${pipelineID}` : queryName;
    }

    let filter;
    if (Array.isArray(clause.value)) {
      let rawValues = [];
      let pipelineValues = [];
      const pipelineRegex = /@(\w+\.\w+#\w+)/g;
      clause.value.forEach((clauseValue) => {
        if (clauseValue.match(pipelineRegex)) {
          pipelineValues.push(clauseValue);
        } else if (clauseValue.startsWith('$')) {
          // the template params may not have been evaluated, so prevent he query from going out. it is expected that once the template params are available, this will kick off the process to execute a query again, with the evaluation being complete that time
          throw new Error(unevaluatedOutputError);
        } else {
          rawValues.push(clauseValue);
        }
      });
      // we only assign a "should" modifier if we know we an output variable
      if (pipelineValues.length > 0) {
        let shouldCandidates = [];
        if (rawValues.length > 0) {
          clause.value = rawValues;
          shouldCandidates.push(
            `{${clauseOption.formatFilter ? clauseOption.formatFilter(clause) : formatFilter(clause)}}`,
          );
        }
        pipelineValues.forEach((pipelineValue) => {
          clause.value = pipelineValue;
          shouldCandidates.push(
            `{${clauseOption.formatFilter ? clauseOption.formatFilter(clause) : formatFilter(clause)}}`,
          );
        });
        filter = `'should': [${shouldCandidates.join(',')}]`;
      }
    }
    if (!filter) {
      // Get the formatted filter, and append the clause option's field to any pipeline reference in the filter.
      filter = clauseOption?.formatFilter
        ? clauseOption.formatFilter(clause)
        : formatFilter(clause);
      const formattedField = clauseOption?.formatField && clauseOption?.formatField(clause);
      // this regex looks for a string that has a period in the middle of it, but does not have a hashtag following the period, in order to add the hashtag to it. e.g. routerAliases.routers --> routerAliases.routers#something
      filter = filter?.replace(
        /@(\w+\.(?!\w*#)\w+)/g,
        (match: string) => `${match}#${pipelineID ?? formattedField ?? clauseOption?.field}`,
      );
    }

    return {
      key,
      queryName,
      clause,
      clauseOption,
      isSecondaryFilter: clauseOption.isSecondaryFilter,
      filter,
    };
  };

  function addFilterClauseWrapperToQuery(clauseWrapper: any, isOutput?: boolean) {
    const key = isOutput ? (newAq as any).pipelineID : clauseWrapper.key;
    let query = queries[key];
    if (!query) {
      query = queries[key] = {
        key,
        queryName: clauseWrapper.queryName,
        clauseWrappers: [],
        dependencies: new Set(),
      };
    }
    if (isOutput) {
      queries[lastClauseWrapper.key].dependencies.add(clauseWrapper.key);
    }
    query.clauseWrappers.push(clauseWrapper);
  }

  function addFilterClauseToQuery(clause: QueryFilterClause, isOutput?: boolean) {
    const clauseWrapper = toFilterClauseWrapper(clause, (newAq as any).pipelineID);
    if (isOutput) {
      clauseWrapper.key = (newAq as any).pipelineID;
    }
    addFilterClauseWrapperToQuery(clauseWrapper, isOutput);
    return clauseWrapper;
  }

  function addPipelineClauseToQuery(pipelineClause: QueryFilterClause, lastClauseWrapper: any) {
    const clauseWrapper = toFilterClauseWrapper(pipelineClause, undefined, lastClauseWrapper);
    addFilterClauseWrapperToQuery(clauseWrapper);
    queries[lastClauseWrapper.key].dependencies.add(clauseWrapper.key);
    return clauseWrapper;
  }

  // Process outputs
  const outputs = {};

  newAq.filterClauses?.forEach((clause: QueryFilterClause) => {
    if (Array.isArray(clause.value)) {
      clause.value.forEach((clauseValue) => {
        if (clauseValue.filterClauses) {
          const id = JSON.stringify(clauseValue);
          outputs[id] = cloneDeep(clauseValue);
        }
      });
    } else if (clause.value?.filterClauses) {
      const id = JSON.stringify(clause.value);
      outputs[id] = cloneDeep(clause.value);
    }
  });

  // We build unique keys here based on the index of the output (for scenarios where we have multiple outputs in the same filter clause) and on the ply of the recursion of this current function. The latter portion allows for ensuring that tiles such as domain->domain->domain...->domain can work.
  const recursionKey = topLevelQueries ? Object.keys(topLevelQueries)?.length : 0;
  Object.keys(outputs).forEach((key: string, index: number) => {
    outputs[key].pipelineID =
      `${resolutionToPipelineKey[outputs[key].resolution]}#output${index + 1}:${recursionKey}`;
  });

  newAq.filterClauses?.forEach((clause: QueryFilterClause) => {
    if (Array.isArray(clause.value)) {
      clause.value = clause.value.map((value) => {
        const id = JSON.stringify(value);
        return value?.filterClauses ? '@' + outputs[id].pipelineID : value;
      });
    }
  });

  let currentClauseWrapper;
  newAq.filterClauses?.forEach((clause: QueryFilterClause) => {
    const clauseOption = getFilterClauseOption(clause);
    if (!clauseOption) {
      throw new Error(`
        A previously selected filter is no longer available. 
        Please select a replacement filter or remove the empty filter field above to update your search.
        Affected filter: ${clause?.type.split(':')[1]}
      `);
    }
    const pipelineFilterClauses = clauseOption.pipelineFilterClauses;
    if (pipelineFilterClauses) {
      const pipelineClauses = [...pipelineFilterClauses(clause)];
      const lastClause = pipelineClauses.pop() as QueryFilterClause;
      currentClauseWrapper = addFilterClauseToQuery(lastClause, topLevelQueries !== undefined);

      pipelineClauses.forEach((clause: QueryFilterClause) =>
        addPipelineClauseToQuery(clause, currentClauseWrapper),
      );
    } else {
      currentClauseWrapper = addFilterClauseToQuery(clause, topLevelQueries !== undefined);
    }
  });

  Object.values(outputs)?.forEach((outputQuery: any) => {
    getQueries(outputQuery, queries, currentClauseWrapper);
  });

  // Order the queries based on dependencies (Kahn's algorithm).
  const keys: string[] = Object.keys(queries);
  const orderedQueries: Record<string, any> = {};
  let retries = 100;
  while (keys.length > 0 && --retries > 0) {
    const key = keys.shift() as string;
    const query = queries[key];
    const deps: string[] = Array.from(query.dependencies.values());
    if (!deps.find((dep: string) => !(dep in orderedQueries))) {
      orderedQueries[key] = query;
    } else {
      keys.push(key);
    }
  }
  if (retries === 0) {
    throw new Error('Pipeline queries have a cyclic dependency.');
  }
  if (isEmpty(orderedQueries)) {
    const queryName = newAq.selection?.typename;
    return [{ key: queryName, queryName, clauseWrappers: [] }];
  } else {
    return Object.values(orderedQueries);
  }
}

export function getConfigAdvancedQuery(params: any) {
  return params?.config?.advancedQuery ?? params?.advancedQuery;
}

export function evaluateOutputVariables(filterClause: QueryFilterClause, templateParams: any[]) {
  if (Array.isArray(filterClause.value)) {
    const newValue = filterClause.value.map((value) => {
      if (typeof value === 'string' && value.startsWith('$')) {
        const templateParam = templateParams.find((param) => {
          return value === `$${getTemplateParamId(param)}`;
        });
        if (templateParam?.value) {
          return templateParam.value;
        }
      } else if (value.filterClauses) {
        // daisy chained outputs need to recursively evaluate themselves
        value.filterClauses = value.filterClauses.map((nfc: QueryFilterClause) => {
          return evaluateOutputVariables(nfc, templateParams);
        });
      }
      return value;
    });
    return {
      ...filterClause,
      value: newValue,
    };
  } else {
    return filterClause;
  }
}

// This function is to help normalize a filter clause value. This is useful in the case where we need to compare if anything in the value has changed as a result of user action. This function will evaluate output variables (i.e. replace references to variables with the actual values). Then flatten the results, de-dupe, and sort.
export function evaluateFilterClauseForOutputVariables(
  filterClause: QueryFilterClause,
  templateParams: TemplateParam[],
) {
  const evaluatedFilter = evaluateOutputVariables(filterClause, templateParams);
  return !Array.isArray(evaluatedFilter.value)
    ? evaluatedFilter
    : {
        ...evaluatedFilter,
        value: [...new Set(flatten(evaluatedFilter.value))].sort(),
      };
}

// aq should be cloned before passing into this function as this update aq with information that should not be saved to the investigation
export function evaluateQueryVariables(
  aq: AdvancedQuery,
  activeTemplateParams: TemplateParam[],
  datasetUnlocked: boolean,
) {
  const template = isTemplate() || isViewOnlyTemplate();
  aq.filterClauses.forEach((clause, i) => {
    if (
      template ||
      [
        QueryFilterComparator.IsInDataset,
        QueryFilterComparator.IncludesOneOf,
        QueryFilterComparator.IsOneOf,
      ].includes(clause.comparator)
    ) {
      // The clause values could be an array, and each array element will be either a reference (e.g. $router-set1) or a raw value (e.g. urn:rtr:123), or it could be strictly a singlet.
      if (Array.isArray(clause?.value)) {
        // For each element in that array, we want to check if it's a reference value. If it is, we replace that specific element with the array of values it represents (creating a nested array).
        clause.value = uniq(flatten(evaluateOutputVariables(clause, activeTemplateParams).value));
      } else if (!clause.isUnlocked) {
        clause.value = activeTemplateParams.find((param) => {
          return `$${getTemplateParamId(param)}` === (clause ? clause.value : undefined);
        })?.value;
      }

      if (
        clause.comparator === QueryFilterComparator.IsInDataset &&
        typeof clause.value !== 'string'
      ) {
        const parsedValue: any = clause.value ?? {};
        if (parsedValue) {
          if (parsedValue.ips) {
            // Add any linked query ip set as a new filter clause
            aq.filterClauses[i] = {
              type: clause.type,
              comparator: QueryFilterComparator.IsOneOf,
              value: parsedValue.ips,
            };
          }
          clause.value = parsedValue.dataId;
        }
      }
    }
  });

  if (isDatasetQuery(aq)) {
    const datasetParam = activeTemplateParams?.find(
      (param) => `$${getTemplateParamId(param)}` === aq.selection.value,
    );
    if (!datasetUnlocked || isOutputVariable(datasetParam)) {
      const selectionValue = datasetParam?.value;
      const updatedTypename = Object.values(userDatasetsVar())?.find(
        (val) => val.id === aq.selection.value,
      )?.typename;
      if (updatedTypename) aq.selection.typename = updatedTypename;
      if (!selectionValue || typeof selectionValue === 'string') {
        aq.selection.value = selectionValue;
      } else {
        const parsedValue = selectionValue ?? {};
        if (parsedValue?.ips) {
          // Add any linked query ip set as a new filter clause
          const newFilterClause: QueryFilterClause = {
            type: 'IpData:IP',
            comparator: QueryFilterComparator.IsOneOf,
            value: parsedValue.ips,
          };
          aq = { ...aq, filterClauses: [...aq?.filterClauses, newFilterClause] };
        }
        aq.selection.value = parsedValue.dataId;
      }
    }
  }
  return aq;
}

export function pruneEmptyFilters(advancedQuery: AdvancedQuery) {
  if (!advancedQuery.filterClauses) return advancedQuery;
  const prunedFilters = advancedQuery.filterClauses?.filter((clause) => {
    return !!clause?.value || clause.comparator === QueryFilterComparator.Exists;
  });
  return { ...advancedQuery, filterClauses: prunedFilters };
}

export function getSortableFields(advancedQuery: AdvancedQuery, viewOption?: QueryViewOption) {
  if (viewOption?.getFields) {
    return viewOption.getFields(advancedQuery).filter((f: QueryViewField) => f.sortable);
  } else {
    return [];
  }
}

export function getGroupableFields(advancedQuery: AdvancedQuery, viewOption?: QueryViewOption) {
  if (!viewOption) {
    viewOption = getViewOption(advancedQuery);
  }
  if (viewOption?.getFields) {
    return viewOption.getFields(advancedQuery).filter((f: QueryViewField) => f.groupable);
  } else {
    return [];
  }
}

export function convertJSONObjectToGQLString(object: any) {
  return JSON.stringify(object).replace(RegExp(/'/g), '').replace(RegExp(/"/g), "'");
}

export function convertGQLStringToJSONObject(curr: string) {
  if (!curr) return undefined;
  return JSON.parse(curr.replaceAll("'", '"'));
}

/**
  Returns true if aq has a selection typename in the designated set of import types
 */
export function isDatasetQuery(aq: AdvancedQuery) {
  return (
    aq?.selection?.typename &&
    IMPORT_OPTIONS.map((datasetType) => datasetType.value).includes(aq?.selection?.typename)
  );
}

export function queryHasDatasetFilter(
  aq: AdvancedQuery,
  isTemplate?: boolean,
  datasets?: UserDatasets,
) {
  if (!aq?.filterClauses?.length) return false;
  return aq.filterClauses.some((filter) => {
    return (
      filter.comparator === QueryFilterComparator.IsInDataset &&
      !!filter.value &&
      (isTemplate ? filter?.isUnlocked : true) &&
      (datasets
        ? !datasets[aq?.selection?.value] || datasets[aq?.selection?.value]?.system?.hidden
        : true)
    );
  });
}

export function sectionContainsDatasetReference(
  params: any,
  isTemplate?: boolean,
  sharedOnly?: boolean,
) {
  const aq = getConfigAdvancedQuery(params);
  const datasets = { ...userDatasetsVar(), ...userSharedDatasetsVar() };
  return (
    (isDatasetQuery(aq) &&
      aq?.selection?.value?.startsWith('dataset') &&
      (isTemplate ? params.isUnlocked : true) &&
      (sharedOnly
        ? !datasets[aq?.selection?.value] || datasets[aq?.selection?.value]?.system?.hidden
        : true)) ||
    queryHasDatasetFilter(aq, isTemplate, sharedOnly ? datasets : undefined)
  );
}

export function sectionContainsDatasetParam(params: any, templateParams: TemplateParams) {
  const aq = getConfigAdvancedQuery(params);
  const selectionReferencesDatasetParam = templateParams?.some((templateParam) => {
    return `$${getTemplateParamId(templateParam)}` === aq?.selection?.value;
  });
  if (isDatasetQuery(aq) && selectionReferencesDatasetParam) return true;

  // Check if any filter clauses reference a dataset param.
  return aq?.filterClauses?.some((clause: QueryFilterClause) => {
    // Looks through all the template params to see if the current clause references it
    const filterReferencesDatasetParam = templateParams?.some(
      (templateParam) => `$${getTemplateParamId(templateParam)}` === clause.value,
    );
    if (clause.comparator === QueryFilterComparator.IsInDataset && !!clause.value) {
      return filterReferencesDatasetParam;
    }
    return false;
  });
}

export function isMultiSelectComparator(comparator: QueryFilterComparator) {
  return [
    QueryFilterComparator.IsOneOf,
    QueryFilterComparator.IncludesOneOf,
    QueryFilterComparator.IncludesBothOf,
    QueryFilterComparator.IncludesAllOf,
  ].includes(comparator);
}

export function getHistogramFieldLabel(advancedQuery: AdvancedQuery, plural?: boolean): string {
  const histogramFields = getViewOption(advancedQuery).getFields(advancedQuery);
  const candidate =
    histogramFields.find((f: QueryViewField) => f.identifier === advancedQuery.group) ??
    histogramFields?.find((f: QueryViewField) => f.oldRef === advancedQuery.group);
  if (plural && candidate) {
    return candidate.pluralLabel || candidate.label;
  }
  return candidate?.label;
}

export function getHistogramField(advancedQuery: AdvancedQuery): QueryViewField {
  const histogramFields = getViewOption(advancedQuery).getFields(advancedQuery);
  return (
    histogramFields?.find((f: QueryViewField) => f.identifier === advancedQuery.group) ??
    histogramFields?.find((f: QueryViewField) => f.oldRef === advancedQuery.group)
  );
}

export function getSortField(advancedQuery: AdvancedQuery): QueryViewField {
  const sortFields = getSortableFields(advancedQuery, getViewOption(advancedQuery));
  return (
    sortFields?.find((f: QueryViewField) => f.identifier === formatSort(advancedQuery.sort)) ??
    sortFields?.find((f: QueryViewField) => f.oldRef === formatSort(advancedQuery.sort))
  );
}

// Handle cases where sort identifiers might have hyphens, i.e. ttl-to-target
export function formatSort(sort: string) {
  if (sort.startsWith('-')) {
    return sort.substring(1);
  }
  return sort;
}

export function comparatorSupportsOutputVariables(comparator: QueryFilterComparator) {
  return [
    QueryFilterComparator.IncludesOneOf,
    QueryFilterComparator.IsOneOf,
    QueryFilterComparator.IsInDataset,
  ].includes(comparator);
}
