import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import {
  findLargestIdNumber,
  getAssetTypeFromPlugin,
  getCurrentInvestigation,
  isViewOnly,
  propagateTemplateVarUpdate,
  renameTemplateParam,
  updateParams,
  getTemplateParamId,
  isAdvancedSearch,
  getAdvancedQueryOutputName,
  getOutputVariable,
  getOutputVariableIndex,
  parseInvestigationSectionId,
} from 'utils';
import {
  TemplateParameterNameOption,
  TemplateParameterNameSingleValue,
  TemplateParameterValueContainer,
  reactSelectSearchStyle,
  DropdownIndicator,
  reactSelectSearchErrorStyle,
} from 'components/index';
import { ComponentPluginContext, DataSlicesContext, SidebarContext } from 'appContexts';
import { ReactComponent as Warning } from 'svg/actions/exclamation-default.svg';
import { StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { UserInvestigation } from 'model';
import { ReactComponent as AddOutput } from 'svg/mosaic/tile-add.svg';
import { ReactComponent as RemoveOutput } from 'svg/mosaic/tile-delete.svg';

export default function OutputTile({ params, renderedQuery }: any) {
  const creatableRef = useRef<any>();
  const { pluginName, templateParams } = useContext(ComponentPluginContext);
  const { setLocalTemplateParams } = useContext(SidebarContext);
  const { maxPipelineCount } = useContext(DataSlicesContext);
  const investigation: UserInvestigation | undefined = getCurrentInvestigation();

  const assetType = useMemo(() => {
    return getAssetTypeFromPlugin(pluginName);
  }, [pluginName]);

  const templateParam = useMemo(() => {
    return getOutputVariable(templateParams, params.uuid);
  }, [templateParams, params]);

  const index = useMemo(() => {
    return getOutputVariableIndex(templateParams, params.uuid);
  }, [templateParams, params]);

  const outputName = useMemo(() => {
    return getAdvancedQueryOutputName(renderedQuery);
  }, [renderedQuery]);

  const templateParamIsUsed = useMemo(() => {
    return (
      templateParam &&
      investigation?.sections.find((section) => {
        const aq = parseInvestigationSectionId(section.id)[1].advancedQuery;
        return (
          aq &&
          aq.filterClauses.find((filterClause) => {
            if (Array.isArray(filterClause.value)) {
              return filterClause.value.includes(`$${getTemplateParamId(templateParam)}`);
            } else {
              return filterClause.value === `$${getTemplateParamId(templateParam)}`;
            }
          })
        );
      })
    );
  }, [templateParam, investigation?.sections]);

  // Histogram outputs are guaranteed to be used in a pipeline. Non-histogram outputs are dependent on the filter clause option that uses them, but we will show them the error either way. It's unlikely for the latter situation to arise due to the UX allowing them to select one item at a time.
  const outputWarning = useMemo(() => {
    if (!templateParam?.value) return false;
    if (
      (renderedQuery?.view === 'histogram' && templateParam.value.count > maxPipelineCount) ||
      (renderedQuery?.view !== 'ipPatternDiscovery' &&
        templateParam.value.length > maxPipelineCount)
    ) {
      return 'Output variables cannot exceed 65k members. The first 65k results will be used with this variable.';
    } else if (
      templateParamIsUsed &&
      ((renderedQuery?.view === 'histogram' && templateParam.value.count === 0) ||
        (renderedQuery?.view !== 'ipPatternDiscovery' && templateParam.value.length === 0))
    ) {
      return 'Output variable has no members and is in use in another tile.';
    }
    return undefined;
  }, [renderedQuery, templateParam, maxPipelineCount, templateParamIsUsed]);

  const label = params.assetName ?? params.assetValue;

  const handleFocus = () => {
    if (label) {
      creatableRef.current.state.inputValue = label;
    }
  };
  const onModifyOutput = useCallback(() => {
    // remove output tile case
    if (templateParam) {
      let update = { value: undefined, label: undefined };
      investigation.sections = propagateTemplateVarUpdate(
        investigation.sections,
        templateParam,
        update,
      );
      templateParams.splice(index, 1);
      updateParams(setLocalTemplateParams, templateParams, investigation);
    } else {
      const newParam = {
        investigation,
        name: `${outputName}${findLargestIdNumber(templateParams, outputName)}`,
        assetType,
        index,
        templateParams: templateParams ?? [],
        setLocalTemplateParams,
        uuid: params.uuid,
      };
      renameTemplateParam(newParam);
    }
  }, [
    templateParam,
    index,
    setLocalTemplateParams,
    templateParams,
    investigation,
    assetType,
    params,
    outputName,
  ]);

  // If the view changes, remove the template param. This is necessary as the histogram view uses the templateParam.value as an object of QueryFilterClauses, and other views use templateParam.value as an array of values.
  useEffect(() => {
    if (templateParam && params.advancedQuery?.view !== renderedQuery?.view) {
      onModifyOutput();
    }
  }, [templateParam, onModifyOutput, params.advancedQuery?.view, renderedQuery?.view]);

  return (
    <Fragment>
      {templateParam && (
        <div className="keystone-subsection output-tile">
          <span className="search-step-title">ASSIGN</span>
          <div>
            <label className="input-label">selection variable name</label>
            <CreatableSelect
              {...{ templateParams }}
              ref={(ref) => {
                creatableRef.current = ref;
              }}
              isDisabled={isViewOnly()}
              value={
                templateParam
                  ? {
                      value: `$${getTemplateParamId(templateParam)}`,
                      label: templateParam.name ?? '',
                    }
                  : null
              }
              placeholder="Variable Name"
              onChange={(val: any) => {
                const retVal = {
                  investigation,
                  name: val.label,
                  assetType,
                  index,
                  templateParams: templateParams ?? [],
                  setLocalTemplateParams,
                  uuid: params.uuid,
                };
                renameTemplateParam(retVal);
              }}
              onFocus={handleFocus}
              styles={
                (outputWarning
                  ? reactSelectSearchErrorStyle()
                  : reactSelectSearchStyle()) as StylesConfig
              }
              components={{
                Option: TemplateParameterNameOption,
                SingleValue: TemplateParameterNameSingleValue,
                ValueContainer: TemplateParameterValueContainer,
                DropdownIndicator,
              }}
              blurInputOnSelect={true}
            />
          </div>
          {outputWarning && (
            <div className="output-error">
              <Warning className="warning-svg" />
              <div>{outputWarning}</div>
            </div>
          )}
        </div>
      )}
      {!isViewOnly() && !isAdvancedSearch() && (
        <div
          className="hide-in-pdf output-modifier uk-flex uk-flex-center uk-margin-top"
          onClick={onModifyOutput}
        >
          {templateParam ? <RemoveOutput /> : <AddOutput />}
          {templateParam ? 'Remove Output' : 'Add Output'}
        </div>
      )}
    </Fragment>
  );
}
