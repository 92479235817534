import { gql } from '@apollo/client';
import { useComponentPluginQuery } from 'hooks';

const portsQuery = gql`
query IPPortsData($filter: String!) {
  portRecords(filter: $filter, size: 1000) {
    totalCount
    items {
      probes {
        timestamp
        port
        transportProtocol
        collectorId
        vendorInfo {
          cpe
          part
          vendor
          product
          version
        }
        applicationData {
          type
          status
          protocol
          parameters
          result
          tls {
            version
            cipherSuite
            curveId
            ja3s
            certificate {
            	certificate {
                subjectKey
                hosts
                notBefore
                notAfter
                issuerOrg
                issuerCountry
                subjectOrg
                subjectCountry
              }
            }
          }
        }
        failedApplicationTypes
      }
    }
  }
}
`;

export function useIPPortsQuery(assetValue: string) {
  const { data, loading, error } = useComponentPluginQuery(portsQuery, {
    variables: {
      filter: JSON.stringify([{ ip: assetValue }]),
    },
  });

  return { data, loading, error };
}
