import { SidebarContext } from 'appContexts';
import { Banner } from 'components/banners';
import { ALERT_TYPES, UserNotification } from 'model';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  FindAndReplaceTerm,
  countTermInInvestigation,
  findAndReplaceBySection,
  parseInvestigationSectionId,
  toggleMainIsFocused,
} from 'utils';
import { ReactComponent as WarningIcon } from 'svg/actions/exclamation-fill.svg';
import { FindAndReplace } from '../FindAndReplace';

export function FindAndReplacePrompt({ selected, updatePromptHeight }) {
  const {
    focusedTile,
    investigation,
    isMainFocused,
    setFocusedTile,
    setIsMainFocused,
    setLocalAnnotations,
  } = useContext(SidebarContext);

  const [findAndReplaceTerm, setFindAndReplaceTerm] = useState<FindAndReplaceTerm>({
    find: '',
    replace: '',
  });
  const [replaceInMain, setReplaceInMain] = useState<boolean>(true);
  const [message, setMessage] = useState<UserNotification | undefined>();

  const focusedUUID = useMemo(() => {
    if (!focusedTile) return '';
    const [, focusedParams] = parseInvestigationSectionId(focusedTile.id);
    return focusedParams.uuid;
  }, [focusedTile]);

  const findAndReplace = useCallback(() => {
    const foundCount = countTermInInvestigation(
      findAndReplaceTerm.find,
      replaceInMain,
      investigation,
      selected,
    );
    findAndReplaceBySection(
      findAndReplaceTerm,
      investigation,
      selected,
      setLocalAnnotations,
      replaceInMain,
      focusedUUID,
    );
    let frMessage = `${foundCount} instance${foundCount !== 1 ? 's' : ''} of "${findAndReplaceTerm.find}" ${foundCount !== 1 ? 'were' : 'was'} replaced with "${findAndReplaceTerm.replace}" `;
    if (selected.size > 0) {
      frMessage += `in ${selected.size} tile${selected.size > 1 ? 's' : ''}`;
      if (replaceInMain) {
        frMessage += ', and in the main title and description';
      }
    } else {
      if (replaceInMain) {
        frMessage += 'in the mosaic title and description';
      }
    }
    frMessage += '.';
    const findAndReplaceBanner: UserNotification = {
      title: 'Success!',
      message: frMessage,
      alertType: ALERT_TYPES.success,
      onClear: () => {
        setMessage(undefined);
      },
    };
    if (isMainFocused) {
      toggleMainIsFocused(
        isMainFocused,
        setIsMainFocused,
        setFocusedTile,
        setLocalAnnotations,
        investigation,
      );
    }
    setMessage(findAndReplaceBanner);
    setFindAndReplaceTerm({ find: '', replace: '' });
  }, [
    findAndReplaceTerm,
    focusedUUID,
    investigation,
    isMainFocused,
    replaceInMain,
    selected,
    setFocusedTile,
    setIsMainFocused,
    setLocalAnnotations,
  ]);

  useEffect(() => {
    updatePromptHeight();
  }, [findAndReplaceTerm, message, updatePromptHeight]);

  return message ? (
    <Banner {...message} style={{ marginBottom: '0' }} />
  ) : !selected.size ? (
    <div className="uk-flex sidebar-menu-alert" style={{ height: '24px', padding: '24px 16px' }}>
      <WarningIcon className="uk-margin-right" />
      <div style={{ lineHeight: '24px' }}>Select content to find and replace text in</div>
    </div>
  ) : (
    <div>
      <FindAndReplace
        findAndReplaceTerm={findAndReplaceTerm}
        updateFindAndReplaceTerm={(type: string, val: string) => {
          setFindAndReplaceTerm((curr) => ({ ...curr, [type]: val }));
          if (message) setMessage(undefined);
        }}
        replaceInMain={replaceInMain}
        investigation={investigation}
        selected={selected}
        showBanner={!message}
      />
      <div className="uk-flex">
        <label className="uk-margin-medium-top">
          <input
            type="checkbox"
            checked={replaceInMain}
            className="uk-checkbox uk-margin-right"
            onChange={() => setReplaceInMain((curr) => !curr)}
          />
          Include title/description
        </label>
        <div className="uk-margin-auto-left" style={{ width: 'fit-content' }}>
          <button
            className="uk-button uk-button-default uk-margin-top"
            onClick={() => findAndReplace()}
          >
            Replace
          </button>
        </div>
      </div>
    </div>
  );
}
