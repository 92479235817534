import { ALERT_TYPES } from 'model';
import React, { useMemo } from 'react';
import { Banner } from 'components';
import { ERROR_CODES, isAssetReport } from 'utils';
import { ApolloError } from '@apollo/client';

export function ErrorBanner({ error }: { error: ApolloError }) {
  const overwriteMessage = useMemo(() => {
    if (error?.graphQLErrors?.[0].extensions.code === ERROR_CODES.timeout && !isAssetReport()) {
      return {
        message: 'The search could not be completed. Adding more filters may help.',
        title: 'Search timed out',
      };
    }
    return undefined;
  }, [error]);

  return (
    <Banner
      style={{ width: '576px', marginBottom: '0px', boxSizing: 'border-box' }}
      message={`Error: ${error.message}`}
      alertType={ALERT_TYPES.error}
      noClose={true}
      {...overwriteMessage}
    />
  );
}
