import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import { KebabContextValue, KebabContext } from 'appContexts';
import { get } from 'lodash';
import UIkit from 'uikit';
import { TitleInput } from 'components';

export type KebabOption = {
  label: string;
  callback?: Function;
  icon?: JSX.Element;
  selector?: string;
  expandComponent?: JSX.Element;
  disabled?: boolean;
  menuCloseDelay?: number;
};

export type ExpandableKebabComponent = {
  data?: any;
  backButton?: boolean;
};

type KebabProps = {
  options: Record<any, KebabOption[]>;
  data: any;
  className?: string;
  style?: Record<string, any>;
  allDisabled?: boolean;
  includeTitle?: boolean;
  editMode?: boolean;
};

export function Kebab({
  options,
  data,
  className,
  style,
  includeTitle,
  editMode,
  allDisabled,
}: KebabProps) {
  const { id, section, title } = data;
  const [kebabContent, setKebabContent] = useState<any>('Main');
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [listenersPlaced, setListenersPlaced] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const reactSelectClickedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!listenersPlaced) {
      UIkit.util.on(document.getElementById(id), 'beforehide', function (e) {
        if (reactSelectClickedRef?.current) {
          e.preventDefault();
          return;
        }
        setMenuOpen(false);
        setKebabContent('Main');
      });
      UIkit.util.on(document.getElementById(id), 'beforeshow', function () {
        setMenuOpen(true);
      });
      setListenersPlaced(true);
    }
  }, [listenersPlaced, id, reactSelectClickedRef]);

  function closeKebabDropdown() {
    UIkit.dropdown(dropdownRef.current).hide(false);
  }

  const contextValue = useMemo<KebabContextValue>(
    () => ({
      returnKebabMain: () => setKebabContent('Main'),
      closeKebabDropdown: () => closeKebabDropdown(),
      reactSelectClicked: reactSelectClickedRef,
    }),
    [],
  );

  const hasOptions = Object.keys(options).length > 0;

  return (
    <KebabContext.Provider value={contextValue}>
      <button
        className={`uk-button ${className ?? ''} ${hasOptions ? '' : 'kebab-no-rollover'} ${menuOpen ? 'app-plugin-title-active' : 'hide-unless-hovered app-plugin-title'}`}
        style={{ padding: 0, cursor: hasOptions ? 'pointer' : 'default', ...style }}
        disabled={!hasOptions}
        onClick={(event) => event.stopPropagation()}
      >
        <span
          className={`app-kebab-icon ${hasOptions ? '' : 'uk-invisible'}`}
          uk-icon="more-vertical"
        />
        {title && includeTitle && !editMode && (
          <div className="uk-flex">
            <div className="app-kebab-title">{title}</div>
          </div>
        )}
      </button>
      {editMode && includeTitle && <TitleInput section={section} />}
      <div
        id={id}
        ref={dropdownRef}
        data-uk-dropdown="mode: click; pos: bottom-left"
        className="tile-menu"
        style={{ cursor: 'default' }}
        onClick={(event) => event.stopPropagation()}
      >
        {kebabContent === 'Main' ? (
          <ul className="uk-nav uk-dropdown-nav">
            {Object.keys(options).map((category) => {
              return (
                <Fragment key={category}>
                  <li className="tile-menu-title">{category}</li>
                  {options[category].map((option) => {
                    const {
                      label,
                      icon,
                      expandComponent,
                      disabled,
                      callback,
                      selector,
                      menuCloseDelay,
                    } = option;
                    return (
                      <li
                        className={`tile-menu-item ${disabled || allDisabled ? 'tile-menu-item-inactive' : ''}`}
                        key={label}
                        onClick={() => {
                          if (disabled || allDisabled) return;
                          if (callback && !expandComponent) {
                            callback(selector ? get(data, selector) : data.id);
                            setTimeout(closeKebabDropdown, menuCloseDelay);
                          }
                          expandComponent && setKebabContent(expandComponent);
                        }}
                      >
                        {icon}
                        {label}
                        {expandComponent && (
                          <span className="tile-menu-chevron" uk-icon="icon: chevron-right;" />
                        )}
                      </li>
                    );
                  })}
                </Fragment>
              );
            })}
          </ul>
        ) : (
          <div className="uk-modal-body uk-form-stacked app-kebab-width">
            {React.cloneElement(kebabContent, { data })}
          </div>
        )}
      </div>
    </KebabContext.Provider>
  );
}
