import React, { useCallback, useEffect, useRef, useState } from 'react';
import { drawHistogram, getHistoWidth } from './drawHistogram';
import { debounce } from 'lodash';
import { AssetLink, EmptyState } from 'components';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { calculateMaxWidth, DEBOUNCE_TIMEOUT, histogramColors } from 'utils';

export type HistogramItem = {
  key?: string;
  label: string;
  labelLink?: string;
  subLabel?: string;
  subLabelLink?: string;
  description?: string;
  color?: string;
  values: Record<string, number>;
  valuesFormatted: Record<string, any>;
};

export type HistogramSettings = {
  activeMetrics: string[];
  width: number;
  margin: Record<string, number>;
  valueMarginX: number;
  valueMarginY: number;
  itemHeight: number;
  unfocusedOpacity: number;
  noColor?: boolean;
  tooltip?: HistogramTooltip;
};

export type HistogramTooltip = {
  index?: number; // Which item to apply tooltip
  content?: any;
};

export type HistogramText = {
  title?: string;
  subtitle?: string;
  description?: string;
  footnote?: string;
};

export type HistogramOptions = {
  text?: HistogramText;
  items: HistogramItem[];
  settings: any;
};

function getDefaultSettings() {
  return {
    activeMetrics: [],
    width: 576,
    margin: {
      top: 5,
      right: 0,
      bottom: 0,
      left: 0,
    },
    valueMarginX: 30,
    valueMarginY: 20,
    itemHeight: 40,
    unfocusedOpacity: 0.25,
    noColor: false,
  };
}

function getTooltipDefaults() {
  return {
    height: 190,
    width: 160,
  };
}

export default function Histogram({ text, items, settings, userCanFix }: any) {
  const histogramRef = useRef<HTMLDivElement | null>(null);
  const id = useRef(uuidv4());
  const [appWidth, setAppWidth] = useState(() => calculateAppWidth());
  const updateHistoSettings = useCallback(() => {
    let retVal = getDefaultSettings();
    Object.entries(settings).forEach((setting) => {
      const key: string = setting[0];
      const val: any = setting[1];
      if (val == null) return;
      const mergeObj: Record<string, string> = {};
      mergeObj[key as string] = val;
      Object.assign(retVal, mergeObj);
    });
    return retVal;
  }, [settings]);
  const [histoSettings, setHistoSettings] = useState<HistogramSettings>(() =>
    updateHistoSettings(),
  );
  const [histoLabels, setHistoLabels] = useState<Array<any>>([]);
  const [tooltipContent, setTooltipContent] = useState<Array<any>>([]);
  const [histoLabelText, setHistoLabelText] = useState<Array<Record<string, number>>>([]);
  const fullLabelWidths = useRef<Array<Record<string, number>>>([]);
  const [width, setWidth] = useState<number>(576);
  const [hideLabels, setHideLabels] = useState<boolean>(true);
  const title = text?.title;
  const subtitle = text?.subtitle?.toLocaleLowerCase();
  const description = text?.description;
  const footnote = text?.footnote?.toLocaleLowerCase();
  const timerId = useRef<number | undefined>(undefined);

  useEffect(() => {
    setWidth(getHistoWidth(histoSettings, appWidth));
  }, [appWidth, histoSettings]);

  useEffect(() => {
    if (!histogramRef.current || !items) {
      return;
    }

    function getHistogramLabel(item: any, index: number) {
      const labelWidth = `${histoLabelText[index]?.label}px` ?? 'initial';
      const sublabelWidth = `${histoLabelText[index]?.sublabel}` ?? 'initial';
      // const color = getItemColor(item, histoSettings, index);
      const opacity = getItemOpacity(item, histoSettings, index);

      // We use Google Chrome print preview generate pdfs which overrides certain colors
      // if they're not printer friendly. Here we're creating a custom style component so
      // that we can add the !important tag to the color attribute.
      const LabelItem = styled.span`
        &&&&& {
          width: ${labelWidth};
          opacity: ${opacity};
        }
      `;

      let label = (
        <LabelItem className={'histogram-label-link'}>
          {item.labelLink != null ? (
            <AssetLink url={item.labelLink} value={item.label} />
          ) : (
            item.label
          )}
        </LabelItem>
      );
      let sublabel = (
        <span className={'histogram-sublabel-link'} style={{ opacity, width: sublabelWidth }}>
          {item.subLabelLink != null ? (
            <AssetLink value={item.subLabel ?? ''} url={item.subLabelLink} />
          ) : (
            item.subLabel ?? ''
          )}
        </span>
      );
      const visibility = hideLabels ? 'hidden' : 'visible';
      return (
        <span
          id={id.current + '-' + index.toString()}
          key={id.current + '-' + index.toString()}
          style={{
            visibility,
            transform: `translateY(${histoSettings?.itemHeight * index + (histoSettings?.valueMarginY - 8)}px)`,
          }}
          className={'histogram-label'}
        >
          {label}
          {sublabel}
        </span>
      );
    }

    const labels: Array<any> = [];
    items.forEach((item: HistogramItem, index: number) => {
      const label = getHistogramLabel(item, index);
      labels.push(label);
    });
    setHistoLabels(labels);
    items?.length > 0 && drawHistogram(histogramRef.current, items, histoSettings, appWidth);
  }, [items, histogramRef, histoSettings, appWidth, histoLabelText, hideLabels]);

  // Hook for calculating the item label lengths
  useEffect(() => {
    if (!items) return;
    if (histoLabels?.length > 0 && histoLabelText?.length === 0) {
      const retArray: Array<Record<string, number>> = [];
      const allocatedHeaderSpace =
        width - histoSettings.activeMetrics?.length * histoSettings?.valueMarginX;
      items.forEach((item: HistogramItem, index: number) => {
        retArray[index] = {};
        if (fullLabelWidths.current.length <= index) {
          const header = document.getElementById(id.current + '-' + index.toString());
          const labelWidth = header?.children[0]?.getBoundingClientRect().width ?? 0;
          const sublabelWidth = header?.children[1]?.getBoundingClientRect().width ?? 0;
          fullLabelWidths.current[index] = { label: labelWidth, sublabel: sublabelWidth };
        }
        const labelWidth = fullLabelWidths.current[index].label;
        const sublabelWidth = fullLabelWidths.current[index].sublabel;

        if (item.label == null) item.label = 'Unspecified';
        if (item.subLabel == null) {
          if (labelWidth > allocatedHeaderSpace) {
            retArray[index].label = allocatedHeaderSpace;
          }
          return;
        }
        const labelPercent = labelWidth / allocatedHeaderSpace;
        const sublabelPercent = sublabelWidth / allocatedHeaderSpace;
        // If both labels are long, give the main label up to about 2/3 of the space.
        if (labelWidth + sublabelWidth > allocatedHeaderSpace) {
          if (sublabelPercent < 0.3) {
            retArray[index].label = (1 - sublabelPercent) * allocatedHeaderSpace;
          } else if (labelPercent < 0.7) {
            retArray[index].sublabel = (1 - labelPercent) * allocatedHeaderSpace;
          } else {
            retArray[index].label = 0.7 * allocatedHeaderSpace;
            retArray[index].sublabel = 0.3 * allocatedHeaderSpace;
          }
        }
      });
      setHistoLabelText(retArray);
    }
    setHideLabels(false);
  }, [histoLabels, width, histoSettings, items, histoLabelText]);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setAppWidth(calculateAppWidth());
      setHistoLabelText([]);
      setHideLabels(true);
    }, DEBOUNCE_TIMEOUT);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    let newSettings = updateHistoSettings();
    setHistoSettings(newSettings);
  }, [updateHistoSettings]);

  // Hook for showing tooltip on hover
  useEffect(() => {
    if (!items) return;
    const histoRef = histogramRef.current;
    const tooltipElement = document.querySelector('div.histogram-tooltip');
    let isMounted = true;
    function handleShowTooltip(event: any) {
      if (timerId.current != null) {
        clearTimeout(timerId.current);
      }
      const timer = () =>
        window.setTimeout(function () {
          if (!isMounted) return;
          let newTooltip: HistogramTooltip = { ...histoSettings?.tooltip, index: undefined };
          if (histoRef?.contains(event.target)) {
            const offsetY = event.offsetY;
            let index: number | undefined = Math.floor(
              (offsetY - histoSettings.valueMarginY) / histoSettings.itemHeight,
            );
            if (index < 0 || index >= items?.length || !histoRef?.contains(event.target))
              index = undefined;
            if (index !== histoSettings?.tooltip?.index) {
              newTooltip.index = index;
              setHistoSettings({ ...histoSettings, tooltip: newTooltip });
            }
            if (index != null) {
              const content = newTooltip?.content[items[index].label] ?? [];
              setTooltipContent(content);
            }
          }
        }, 300);
      timerId.current = timer();
    }
    function handleClearTooltip() {
      if (timerId.current != null) {
        clearTimeout(timerId.current);
      }
      const timer = () =>
        window.setTimeout(function () {
          if (!isMounted) return;
          let newTooltip: HistogramTooltip = { ...histoSettings?.tooltip, index: undefined };
          setHistoSettings({ ...histoSettings, tooltip: newTooltip });
          setHistoSettings({ ...histoSettings, tooltip: newTooltip });
        }, 300);
      timerId.current = timer();
    }

    if (histoRef && !!histoSettings.tooltip) {
      histoRef.addEventListener('mousemove', handleShowTooltip);
      histoRef.addEventListener('mouseleave', handleClearTooltip);
      tooltipElement?.addEventListener('mouseenter', handleShowTooltip);
      tooltipElement?.addEventListener('mouseleave', handleClearTooltip);
    }
    return () => {
      histoRef?.removeEventListener('mousemove', handleShowTooltip);
      histoRef?.removeEventListener('mouseleave', handleClearTooltip);
      tooltipElement?.removeEventListener('mouseenter', handleShowTooltip);
      tooltipElement?.removeEventListener('mouseleave', handleClearTooltip);
      isMounted = false;
    };
  });

  function calculateAppWidth() {
    const windowWidth = calculateMaxWidth();
    const tooltipWidth = settings.tooltip != null ? getTooltipDefaults().width : 0;
    return (windowWidth || window.innerWidth) - tooltipWidth;
  }

  function constructTooltip(content: any[]) {
    const { width: tooltipWidth, height: tooltipHeight } = getTooltipDefaults();
    const triangleSpacing = 16;
    const index = histoSettings.tooltip?.index ?? 0;
    const isTopStyle = index < items?.length - 4;
    let yTranslation = histoSettings?.itemHeight * index + (histoSettings?.valueMarginY - 8);
    if (!isTopStyle) {
      yTranslation = yTranslation - tooltipHeight + triangleSpacing;
    }
    const item = items[index];
    const squareColor = getItemColor(item, histoSettings, index);
    return (
      <div
        style={{
          transform: `translate(${width + histoSettings.valueMarginX}px, ${yTranslation}px)`,
          width: tooltipWidth,
          height: tooltipHeight,
        }}
        className="uk-card-default histogram-tooltip asset-tooltip"
      >
        <div
          style={{
            transform: `translate(-35px, ${isTopStyle ? 0 : tooltipHeight - triangleSpacing}px)`,
          }}
          className="uk-position-relative uk-align-left histogram-triangle-left"
        />
        <div className="histogram-tooltip-body">
          <div className="histogram-tooltip-title">
            <div
              style={{
                height: '14px',
                width: '14px',
                background: squareColor,
                borderRadius: '3px',
              }}
            />
            &nbsp;Item Details
          </div>
          <span className="histogram-tooltip-subtitle">REPRESENTATIVE MEMBERS</span>
          <ul className="uk-list uk-text-secondary uk-margin-remove">
            {content.map((item, i) => {
              return (
                <li className="uk-margin-remove" key={`${item}-${i.toString()}`}>
                  <AssetLink value={item} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
  if (!items || items.length === 0)
    return (
      <div className="uk-margin-medium-top">
        <EmptyState userCanFix={userCanFix || false} />
      </div>
    );

  return (
    <div className="uk-flex histogram">
      <div style={{ width }}>
        <div className="uk-text-left">
          {title && <div className="histogram-title">{title}</div>}
          {subtitle && <div className="uk-text-muted uk-text-small">{subtitle}</div>}
          {description && (
            <div className="uk-text-small">
              <p style={{ width: histoSettings?.width }}>{description}</p>
            </div>
          )}
        </div>
        {histoLabels}
        <div ref={histogramRef} />
        {footnote && (
          <div className="uk-text-muted histogram-footnote uk-text-uppercase">{footnote}</div>
        )}
      </div>
      {histoSettings?.tooltip?.index != null && constructTooltip(tooltipContent)}
    </div>
  );
}

export function getItemColor(item: HistogramItem, settings: HistogramSettings, index?: number) {
  return (
    item.color ??
    (settings?.noColor ? 'black' : histogramColors[index ?? 0 % histogramColors.length])
  );
}

export function getItemOpacity(item: HistogramItem, settings: HistogramSettings, index?: number) {
  return settings.tooltip?.index != null && settings.tooltip?.index !== index
    ? settings.unfocusedOpacity
    : 1;
}
