export const pjlCodeMap = {
  '10001': 'READY (online)',
  '10002': 'READY (offline)',
  '10003': 'WARMING UP',
  '10004': 'SELF TEST or INTERNAL TEST',
  '10005': 'RESET, CLEARING MEMORY',
  '10006': 'TONER LOW',
  '10007': 'CANCELING JOB',
  '10010': 'STATUS BUFFER OVERFLOW',
  '10011': 'AUX IO, MIO, MIO1, or MIO2 INITIALIZNG or NOT READY',
  '10013': 'SELF TEST',
  '10014': 'PRINTING TEST',
  '10015': 'TYPEFACE LIST or PRINTING FONT LIST',
  '10016': 'ENGINE TEST',
  '10017': 'DEMO PAGE or PRINTING DEMO PAGE',
  '10018': 'MENU RESET or RESETTING MENUS TO DEFAULTS',
  '10019': 'RST ACTIVE IO or RESETTING ACTIVE IO',
  '10020': 'RESET ALL I/O',
  '10021': 'COLD RESET or RESTORING FACTORY SETTINGS',
  '10022': 'CONFIG PAGE or PRINTING CONFIGURATION PAGE',
  '10023': 'PROCESSING JOB',
  '10024': 'PRESS SELECT TO FORMFEED or DATA RECEIVED',
  '10025': 'ACCESS DENIED',
  '10026': 'NO JOB TO CANCEL',
  '10027': 'CLEARING PAPER PATH',
  '10028': 'PRINTING ERROR LOG',
  '10029': 'FORMFEEDING',
  '10030': 'Print job contains the optional DISPLAY=”message”',
  '10031': 'ENGINE CLEANING',
  '10032': 'Unable to process internal page request',
  '10033': 'INVALID PIN',
  '10034': 'INTERRUPTING JOB',
  '10035': 'RESUMING JOB',
  '10036': 'WARMING UP (or initializing-Designjet)',
  '10200': 'ORDER BLACK TONER <n> PAGES LEFT',
  '10201': 'ORDER CYAN TONER <n> PAGES LEFT',
  '10202': 'ORDER MAGENTA TONER <n> PAGES LEFT',
  '10203': 'ORDER YELLOW TONER <n> PAGES LEFT',
  '10204': 'ORDER IMAGE DRUM',
  '10205': 'ORDER BLACK DRUM',
  '10206': 'ORDER CYAN DRUM',
  '10207': 'ORDER MAGENTA DRUM',
  '10208': 'ORDER YELLOW DRUM',
  '10209': 'ORDER BLACK TONER',
  '10210': 'ORDER CYAN TONER',
  '10211': 'ORDER MAGENTA TONER',
  '10212': 'ORDER YELLOW TONER',
  '10213': 'ORDER TRANSPORT KIT',
  '10214': 'ORDER CLEANING KIT',
  '10215': 'ORDER TRANSFER KIT',
  '10216': 'ORDER FUSER KIT',
  '10217': 'PERFORM PRINTER MAINTENANCE',
  '10218': 'ORDER SUPPLIES <n> PAGES LEFT',
  '10300': 'REPLACE BLACK TONER',
  '10301': 'REPLACE CYAN TONER',
  '10302': 'REPLACE MAGENTA TONER',
  '10303': 'REPLACE YELLOW TONER',
  '10304': 'REPLACE IMAGE DRUM',
  '10305': 'REPLACE BLACK DRUM',
  '10306': 'REPLACE CYAN DRUM',
  '10307': 'REPLACE MAGENTA DRUM',
  '10308': 'REPLACE YELLOW DRUM',
  '10309': 'REPLACE BLACK CARTRIDGE',
  '10310': 'REPLACE CYAN CARTRIDGE',
  '10311': 'REPLACE MAGENTA CARTRIDGE',
  '10312': 'REPLACE YELLOW CARTRIDGE',
  '10313': 'REPLACE TRANSPORT KIT',
  '10314': 'REPLACE CLEANING KIT',
  '10315': 'REPLACE TRANSFER KIT',
  '10316': 'REPLACE FUSER KIT',
  '10317': 'PERFORM PRINTER MAINTENANCE',
  '10318': 'REPLACE SUPPLIES',
  '10400': 'NON-HP SUPPLIES IN USE',
  '10401': 'Genuine HP Supplies',
  '10402': 'Toner charge needs to be cleared.',
  '10403': 'Unsupported genuine HP supplies',
  '20001': 'Generic syntax error (entire PJL command ignored)',
  '20002': 'Unsupported command',
  '20004': 'Unsupported personality, system, or I/O port',
  '20005': 'PJL command buffer overflow',
  '20006':
    'Illegal character or line has been terminated by the universal exit language (UEL) command',
  '20007': '<WS> or [<CR>]<LF> missing after closing quotes',
  '20008': 'Invalid character appears in an alphanumeric value',
  '20009': 'Invalid character appears in a numeric value',
  '20010': 'Invalid character at the start of a string, alphanumeric value, or numeric value',
  '20011': 'String is missing closing double-quote character',
  '20012': 'Numeric value starts with a decimal point',
  '20013': 'Numeric value does not contain any digits',
  '20014': 'No alphanumeric value follows the command modifier',
  '20015': 'Option name and equal sign are encountered, but the value field is missing',
  '20016': 'More than one command modifier exists',
  '20017': 'Command modifier is encountered after an option',
  '20018': 'Command is not an alphanumeric value',
  '20019': 'Numeric value is encountered when an alphanumeric value expected',
  '20020': 'String is encountered when an alphanumeric value is expected',
  '20021': 'Unsupported command modifier',
  '20022': 'Command modifier missing',
  '20023': 'Option missing',
  '20024': 'Extra data is received after the option name',
  '20025': 'Two decimal points in a numeric value',
  '20026': 'Out of memory',
  '20027': 'White-space before data stashed',
  '20028': 'White-space and a UEL command precede the data',
  '25001': 'Generic warning error (part of the PJL command ignored)',
  '25002': 'PJL prefix is missing',
  '25003': 'Alphanumeric value is too long',
  '25004': 'String is too long',
  '25005': 'Numeric value is too long',
  '25006': 'Unsupported option name',
  '25007': 'Option name requires a value that is missing',
  '25008': 'Option name requires a value of a different type',
  '25009': 'Option name is received with a value, but this option does not support values',
  '25010': 'Same option name is received more than once',
  '25011': 'Option name has been ignored in response to value underflow or overflow',
  '25012':
    'Value for the option experienced data loss as a result of data conversion (value was truncated or rounded)',
  '25013':
    'Value for option experienced data loss as a result of the value being out of range; the value that was used was the closest supported limit',
  '25014': 'Value is of the correct type, but is out of range (value was ignored)',
  '25015': 'Integer was expected (obsolete)',
  '25016': 'Option name is received with an alphanumeric value, but this value is not supported',
  '25017': 'String empty, option ignored',
  '25018': 'A UEL command was expected but not found.',
  '27001': 'Generic semantic error.',
  '27002': 'EOJ command is encountered without a previously matching JOB command.',
  '27003':
    'Password protected—attempted to change NVRAM value when a password is set and the job is not a secure PJL job.',
  '27004': 'Cannot modify the value of a read-only variable.',
  '27005': 'Can only use DEFAULT with this variable; cannot use SET.',
  '27006':
    'Attempted to pass a NULL string to a command or command option that requires the string to contain at least one character.',
  '27007': 'Attempted to DEFAULT a variable which can only be SET.',
  '30010': 'STATUS BUFFER OVERFLOW',
  '30016': 'MEM OVERFLOW',
  '30017': 'PRINT OVERRUN',
  '30018': 'ERROR',
  '30027': 'I/O CONFIG ERROR',
  '30034': 'ERROR',
  '30035': 'ERROR CHECK CONFIGURATION or VRAM ERROR, SETTINGS NOT SAVED',
  '30036': 'SERVICE or NVRAM FULL CHECK CONFIGURATION or NVRAM ERROR, SETTINGS NOT SAVED',
  '30072': 'REMOVE PAGE',
  '30076': 'PERSONALITY MEMORY OVERFLOW/ OUT OF MEMORY',
  '30094': 'GENERAL MISPRINT ERROR',
  '30095': 'BEAM DETECT MALFUNCTION',
  '30096': 'IMPROPER PAPER SIZE or UNEXPECTED PAPER SIZE',
  '30097': 'NO VIDEO SYNC',
  '30098': 'NOISY VIDEO SYNC REQ SIGNAL',
  '30099': 'INVALID INPUT SOURCE',
  '30100': 'INVALID OUTPUT DESTINATION',
  '30101': 'BINDING AGENT OUT OF SUPPLIES',
  '30102': 'BINDING AGENT MEDIA MISALIGNMENT',
  '30103': 'BINDING AGENT MEDIA CAPACITY EXCEEDED',
  '30104': 'EXTERNAL INPUT DEVICE PAPER PATH ERROR',
  '30105': 'EXTERNAL OUTPUT DEVICE PAPER PATH ERROR',
  '30106': 'EXTERNAL INPUT DEVICE OPERATIONAL ERROR',
  '30107': 'EXTERNAL OUTPUT DEVICE OPERATIONAL ERROR',
  '30108': 'HP MIO ERROR, PRESS GO KEY',
  '30109': 'BAD SERIAL DATA FORMAT, PRESS GO KEY',
  '30110': 'HP MIO ERROR, PRESS GO KEY',
  '30111': 'PARALLEL IO ERROR, PRESS GO KEY',
  '30112': 'SERIAL IO ERROR, PRESS GO KEY',
  '30113': 'STOPPING CHECK LAST PAGE',
  '30114': 'PRESS SELECT TO CANCEL JOB',
  '30119': 'MEDIA FEED ERROR',
  '32000': 'General error',
  '32001': 'Volume not available',
  '32002': 'Disk full',
  '32003': 'File not found',
  '32004': 'No free file descriptors',
  '32005': 'Invalid number of bytes',
  '32006': 'File already exists',
  '32007': 'Illegal name',
  '32008': 'Cannot delete root',
  '32009': 'File operation attempted on a directory',
  '32010': 'Directory operation attempted on a file',
  '32011': 'Not the same volume',
  '32012': 'Read only',
  '32013': 'Directory full',
  '32014': 'Directory not empty',
  '32015': 'Bad disk',
  '32016': 'No label',
  '32017': 'Invalid parameter',
  '32018': 'No contiguous space',
  '32019': 'Cannot change root',
  '32020': 'File Descriptor obsolete',
  '32021': 'Deleted',
  '32022': 'No block device',
  '32023': 'Bad seek',
  '32024': 'Internal error',
  '32025': 'Write only',
  '32026': 'Write protected',
  '32027': 'No filename',
  '32051': 'End of directory',
  '32052': 'No file system',
  '32053': 'No memory',
  '32054': 'Volume name out of range',
  '32055': 'Bad FS',
  '32056': 'Hardware failure',
  '32064': 'Access denied',
  '35028': 'EIO DISK NOT FUNCTIONAL',
  '35029': 'W1 IMAGE ADAPT or IMAGE ASSIST or MEMORY LOW, PAGE SIMPLIFIED',
  '35030': 'WA JOB ALTERED',
  '35031': 'W2 INVALID PERS or REQUESTED LANGUAGE NOT AVAILABLE',
  '35032': 'WL WARNINGS LOST',
  '35037': 'W3 JOB ABORTED or CANCELING JOB or INSUFFICIENT MEMORY, JOB CLEARED',
  '35039': 'W9 JOB 600/LTR',
  '35040': 'W0 JOB 600/A4',
  '35041': 'W8 JOB 600/OFF',
  '35042': 'W7 JOB 300/LGL',
  '35043': 'W5 JOB 300/LTR',
  '35044': 'W6 JOB 300/A4',
  '35045': 'W4 JOB 300/OFF',
  '35073': 'WM CHK MEM CNFIG or MEMORY SETTINGS CHANGED',
  '35074': 'WM MEM CNFIG N/A',
  '35075': 'USER MAINTENANCE REQUESTED',
  '35076': 'WM personality MEM FULL or XXXX MEMORY FULL, STORED DATA LOST',
  '35078': 'Entered powersave mode or POWERSAVE ON',
  '35081': 'WM JOB 300',
  '35082': 'WD DISK NOT INIT or Volume 0 NOT INIT',
  '35083': 'Volume 0 FAILURE',
  '35084': 'WM JOB 600/LGL',
  '35085': 'WM JOB 600/A3',
  '35086': 'WM JOB 600/11x17',
  '35087': 'WM JOB 300/A3',
  '35088': 'WM JOB 300/11x17',
  '35115': 'Volume 1 NOT INIT',
  '35116': 'Volume 1 FAILURE',
  '35117': 'Volume 2 NOT INIT',
  '35118': 'Volume 2 FAILURE',
  '35138': 'Unable to mopy job',
  '35139': 'Unable to mopy job',
  '35140': 'Unable to mopy job',
  '35141': 'Unable to store job',
  '40000': 'SLEEP MODE',
  '40005': 'Cartridge error during align cartridges',
  '40010':
    'NO EP CART or INSTALL TONER CARTRIDGE or no electrical contact with one or more ink cartridges',
  '40011': 'Using ink cartridges',
  '40013': 'SUPPLY MEMORY ERROR',
  '40019': 'REMOVE PAPER FROM [bin name]',
  '40020': 'NO MICR TONER or INSTALL MICR TONER CARTRIDGE',
  '40021': 'PRINTER OPEN or NO EP CART or CLOSE PRINTER COVER',
  '40022': 'PAPER JAM or REMOVE PAPER JAM',
  '40024': 'FE CARTRIDGE',
  '40025': 'Device needs attention',
  '40026': 'PC INSTALL or INSTALL TRAY 2',
  '40038': 'LOW TONER',
  '40039': 'Cartridge authorization status',
  '40046': 'FI INSERT CART',
  '40047': 'FR REMOVE CART',
  '40048': '[PJL OPMSG]',
  '40049': '[PJL STMSG]',
  '40050': 'SERVICE or FUSER ERROR, CYCLE POWER',
  '40051': 'ERROR or PRINTER ERROR, CYCLE POWER',
  '40052': 'ERROR or PRINTER ERROR, CYCLE POWER',
  '40053': '53-xy-zz ERROR',
  '40054': 'ERROR',
  '40055': 'ERROR',
  '40056': 'ERROR',
  '40057': 'SERVICE or MOTOR FAILURE, CALL SERVICE',
  '40058': 'SERVICE or FAN MOTOR FAILURE, CALL SERVICE',
  '40059': 'ERROR',
  '40060': 'ERROR',
  '40061': 'SERVICE',
  '40062': 'SERVICE',
  '40063': 'SERVICE',
  '40064': 'SERVICE or PRINTER ERROR, CYCLE POWER',
  '40065': 'SERVICE',
  '40066': 'External paper-handling device failure',
  '40067': 'SERVICE',
  '40068': 'SERVICE',
  '40069': 'ERROR',
  '40070': 'ERROR',
  '40071': 'SERVICE',
  '40079': 'PRINTER MANUALLY TAKEN OFFLINE or OFFLINE or FORMFEEDING or SERVICE MODE',
  '40080': 'EE INCOMPATIBLE or LC INCOMPATIBLE',
  '40083': 'DISK FAILURE',
  '40089': 'BAD OPTIONAL TRAY CONNECTION',
  '40090': 'INCOMPATIBLE ENVELOPE FEEDER INSTALLED',
  '40091': 'Duplexer cord error',
  '40092': 'SERVICE (XXX)',
  '40093': 'REMOVE DUPLEX JAM',
  '40096': 'UNEXPECTED PAPER SIZE CHECK PAPER IN TRAY',
  '40099': 'ERROR PRESS SELECT KEY',
  '40100': 'ERROR PRESS SELECT KEY',
  '40101': 'FINISHER OUT OF BINDING AGENT',
  '40102': 'FINISHER ALIGN ERROR [BIN NAME]',
  '40103': 'FINISH LIMIT REACHED [BIN NAME]',
  '40104': 'INPUT DEVICE FEED PATH OPEN',
  '40105': 'OUTPUT DEVICE DELIVERY PATH OPEN',
  '40106': 'INPUT OPERATION ERROR X.YY',
  '40107': 'OUTPUT OPERATION ERROR X.YY',
  '40108': '66.xx.yy EXTERNAL DEVICE FAILURE',
  '40116': 'Volume 1 FAILURE',
  '40118': 'Volume 2 FAILURE',
  '40119': 'PAPER MISFEED',
  '40120': 'Open face-up output bin',
  '40121': 'Cannot duplex closer rear bin',
  '40122': 'Duplexer must be installed',
  '40123': 'Duplexer error, remove duplexer',
  '40124': 'Bad duplexer connection',
  '40128': 'DRUM ERROR REPLACE DRUM KIT',
  '40129': 'DRUM LIFE OUT REPLACE DRUM KIT',
  '40130': 'DRUM LIFE LOW REPLACE DRUM KIT',
  '40131': 'TRANSFER KIT OUT REPLACE KIT',
  '40132': 'TRANSFER KIT LOW REPLACE KIT',
  '40137': 'BAD ENV FEEDER CONNECTION',
  '40141': 'WASTE TONER FULL REPLACE DRUM KIT',
  '40142': 'INSTALL DRUM KIT',
  '40143': 'REINSTALL TRANSFER BELT',
  '40144': 'PRESS GO TO PRINT PRESS SELECT TO CHANGE TONER',
  '40145': 'service error',
  '40146': 'UNEXPECTED PAPER TYPE CHECK PAPER IN TRAY',
  '40150': 'Cartridge low',
  '40151': 'Toner cartridge out',
  '40152': 'Drum cartridge out',
  '40158': '10.xx.yy SUPPLY MEMORY ERROR',
  '40200': 'ORDER BLACK TONER',
  '40201': 'ORDER CYAN TONER',
  '40202': 'ORDER MAGENTA TONER',
  '40203': 'ORDER YELLOW TONER',
  '40204': 'ORDER IMAGE DRUM',
  '40205': 'ORDER BLACK DRUM',
  '40206': 'ORDER CYAN DRUM',
  '40207': 'ORDER MAGENTA DRUM',
  '40208': 'ORDER YELLOW DRUM',
  '40209': 'ORDER BLACK TONER',
  '40210': 'ORDER CYAN TONER',
  '40211': 'ORDER MAGENTA TONER',
  '40212': 'ORDER YELLOW TONER',
  '40213': 'ORDER TRANSPORT KIT',
  '40214': 'ORDER CLEANING KIT',
  '40215': 'ORDER TRANFER KIT',
  '40216': 'ORDER FUSER KIT',
  '40217': 'PERFORM PRINTER MAINTENANCE',
  '40218': 'ORDER SUPPLIES <n> PAGES LEFT',
  '40300': 'REPLACE BLACK TONER',
  '40301': 'REPLACE CYAN TONER',
  '40302': 'REPLACE MAGENTA TONER',
  '40303': 'REPLACE YELLOW TONER',
  '40304': 'REPLACE IMAGE DRUM',
  '40305': 'REPLACE BLACK DRUM',
  '40306': 'REPLACE CYAN DRUM',
  '40307': 'REPLACE MAGENTA DRUM',
  '40308': 'REPLACE YELLOW DRUM',
  '40309': 'REPLACE BLACK CARTRIDGE',
  '40310': 'REPLACE CYAN CARTRIDGE',
  '40311': 'REPLACE MAGENTA CARTRIDGE',
  '40312': 'REPLACE YELLOW CARTRIDGE',
  '40313': 'REPLACE TRANSPORT KIT',
  '40314': 'REPLACE CLEANING KIT',
  '40315': 'REPLACE TRANFER KIT',
  '40316': 'REPLACE FUSER KIT',
  '40317': 'PERFORM PRINTER MAINTENANCE',
  '40318': 'REPLACE SUPPLIES',
  '40400': 'INSTALL BLACK TONER',
  '40401': 'INSTALL CYAN TONER',
  '40402': 'INSTALL MAGENTA TONER',
  '40403': 'INSTALL YELLOW TONER',
  '40404': 'INSTALL IMAGE DRUM',
  '40405': 'INSTALL BLACK DRUM',
  '40406': 'INSTALL CYAN DRUM',
  '40407': 'INSTALL MAGENTA DRUM',
  '40408': 'INSTALL YELLOW DRUM',
  '40409': 'INSTALL BLACK CARTRIDGE',
  '40410': 'INSTALL CYAN CARTRIDGE',
  '40411': 'INSTALL MAGENTA CARTRIDGE',
  '40412': 'INSTALL YELLOW CARTRIDGE',
  '40413': 'INSTALL TRANSPORT KIT',
  '40414': 'INSTALL CLEANING KIT',
  '40415': 'INSTALL TRANFER KIT',
  '40416': 'INSTALL FUSER KIT',
  '40417': 'PERFORM PRINTER MAINTENANCE',
  '40418': 'INSTALL SUPPLIES',
  '40500': 'INSTALL BLACK TONER',
  '40501': 'INSTALL CYAN TONER',
  '40502': 'INSTALL MAGENTA TONER',
  '40503': 'INSTALL YELLOW TONER',
  '40504': 'INSTALL IMAGE DRUM',
  '40505': 'INSTALL BLACK DRUM',
  '40506': 'INSTALL CYAN DRUM',
  '40507': 'INSTALL MAGENTA DRUM',
  '40508': 'INSTALL YELLOW DRUM',
  '40509': 'INSTALL BLACK CARTRIDGE',
  '40510': 'INSTALL CYAN CARTRIDGE',
  '40511': 'INSTALL MAGENTA CARTRIDGE',
  '40512': 'INSTALL YELLOW CARTRIDGE',
  '40513': 'INSTALL TRANSPORT KIT',
  '40514': 'INSTALL CLEANING KIT',
  '40515': 'INSTALL TRANFER KIT',
  '40516': 'INSTALL FUSER KIT',
  '40517': 'PERFORM PRINTER MAINTENANCE',
  '40518': 'INSTALL SUPPLIES',
  '40600': 'INSTALL BLACK TONER',
  '40601': 'INSTALL CYAN TONER',
  '40602': 'INSTALL MAGENTA TONER',
  '40603': 'INSTALL YELLOW TONER',
  '40604': 'INSTALL IMAGE DRUM',
  '40605': 'INSTALL BLACK DRUM',
  '40606': 'INSTALL CYAN DRUM',
  '40607': 'INSTALL MAGENTA DRUM',
  '40608': 'INSTALL YELLOW DRUM',
  '40609': 'INSTALL BLACK CARTRIDGE',
  '40610': 'INSTALL CYAN CARTRIDGE',
  '40611': 'INSTALL MAGENTA CARTRIDGE',
  '40612': 'INSTALL YELLOW CARTRIDGE',
  '40613': 'INSTALL TRANSPORT KIT',
  '40614': 'INSTALL CLEANING KIT',
  '40615': 'INSTALL TRANFER KIT',
  '40616': 'INSTALL FUSER KIT',
  '40617': 'PERFORM PRINTER MAINTENANCE',
  '40618': 'INSTALL SUPPLIES',
  '40700': 'NON-HP Cartridge Installed',
  '40701': 'T2 Roller missing',
  '40702': 'Croller out',
  '40703': 'Croller missing',
  '40704': 'REMOVE SEALING TAPE',
  '40705': 'E-label cartridge error',
  '40800': 'ORDER BLACK TONER <n> DAYS LEFT',
  '40801': 'ORDER CYAN TONER <n> DAYS LEFT',
  '40802': 'ORDER MAGENTA TONER <n> DAYS LEFT',
  '40803': 'ORDER YELLOW TONER <n> DAYS LEFT',
  '40804': 'ORDER BLACK CARTRIDGE <n> DAYS LEFT',
  '40805': 'ORDER CYAN CARTRIDGE <n> DAYS LEFT',
  '40806': 'ORDER MAGENTA CARTRIDGE <n> DAYS LEFT',
  '40807': 'ORDER YELLOW CARTRIDGE <n> DAYS LEFT',
  '40808': 'ORDER SUPPLIES <n> DAYS LEFT',
  '40900': 'REPLACE BLACK TONER',
  '40901': 'REPLACE CYAN TONER',
  '40902': 'REPLACE MAGENTA TONER',
  '40903': 'REPLACE YELLOW TONER',
  '40904': 'REPLACE BLACK CARTRIDGE',
  '40905': 'REPLACE CYAN CARTRIDGE',
  '40906': 'REPLACE MAGENTA CARTRIDGE',
  '40907': 'REPLACE YELLOW CARTRIDGE',
  '40908': 'REPLACE SUPPLIES',
  '50000': 'General hardware failure',
  '50001': 'ROM or RAM error, ROM Checksum failed (or interface error [Designjet])',
  '50002': 'RAM error, RAM test failed (or part malfunction [Designjet])',
  '50003': 'Engine fuser error',
  '50004': 'Engine beam detect error',
  '50005': 'Engine scanner error',
  '50006': 'Engine fan error',
  '50007': 'Engine communications error',
  '50008': 'FUSER ERROR CYCLE POWER or LOW FUSER TEMPERATURE',
  '50009': 'FUSER ERROR CYCLE POWER or FUSER TOOK TOO LONG TO WARM UP',
  '50010': 'FUSER ERROR CYCLE POWER or FUSER TOO HOT',
  '50011': 'FUSER ERROR CYCLE POWER or BAD FUSER',
  '50012': 'ERROR PRESS SELECT KEY or BEAM DETECT MALFUNCTION',
  '50013': 'ERROR PRESS SELECT KEY or LASER MALFUNCTION',
  '50014': 'ERROR PRESS SELECT KEY or SCANNER STARTUP FAILURE',
  '50015': 'ERROR PRESS SELECT KEY or SCANNER ROTATION FAILURE',
  '50016': 'FAN FAILURE CALL SERVICE or FAN MOTOR 1 FAILURE',
  '50017': 'FAN FAILURE CALL SERVICE or FAN MOTOR 2 FAILURE',
  '50018': 'FAN FAILURE CALL SERVICE or FAN MOTOR 3 FAILURE',
  '50019': 'FAN FAILURE CALL SERVICE or FAN MOTOR 4 FAILURE',
  '50020': 'UPPER INPUT TRAY LIFTER MALFUNCTION',
  '50021': 'LOWER INPUT TRAY LIFTER MALFUNCTION',
  '50022': 'PRINTER ERROR CYCLE POWER or MULTIPURPOSE TRAY LIFTER MALFUNCTION',
  '50023': 'PRINTER ERROR CYCLE POWER or MAIN MOTOR STARTUP FAILURE',
  '50024': 'PRINTER ERROR CYCLE POWER or MAIN MOTOR ROTATION FAILURE',
  '50025':
    'FINISHER MALFUNCTION [BIN NAME] or EXTERNAL BINDING DEVICE HAS A MALFUNCTION 50026 DEVICE ERROR X.YY CYCLE POWER or AN EXTERNAL DEVICE HAS REPORTED A MALFUNCTION',
  '50027': 'DUPLEX ERROR CHECK DUPLEX UNIT or DUPLEX UNIT GUIDE FAILED AND REQUIRES SERVICE',
  '50028': '55.XX ERROR PRESS SELECT KEY or COMMUNICATION FAILURE BETWEEN FORMATTER AND ENGINE',
  '50029': 'PRINTER ERROR CYCLE POWER or VIDEO DMA TRANSFER TIMED OUT',
  '50030': 'FAN MOTOR 5 FAILURE',
  '50031': 'FAN MOTOR 6 FAILURE',
  '50032': 'FAN MOTOR 7 FAILURE',
  '50033': 'FAN MOTOR 8 FAILURE',
  '50034': 'Bad fuser type',
  '50035': 'FAN MOTOR 9 FAILURE',
  '50036': '57.A0 FAN FAILURE or FAN MOTOR 10 FAILURE',
  '50048': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50049': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50050': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50051': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50052': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50053': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50054': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50055': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50056': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50057': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50058': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50059': 'ETB speed control sensor out of range',
  '50060': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50061': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50062': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50063': 'XX.XX PRINTER ERROR, CYCLE POWER',
  '50064': 'Yellow misregistration out of range; no message',
  '50065': 'Magenta misregistration out of range; no message',
  '50066': 'Cyan misregistration out of range; no message',
  '50067': 'Black misregistration out of range; no message',
  '50068': 'Yellow density out of range',
  '50069': 'Magenta density out of range',
  '50070': 'Cyan density out of range',
  '50071': 'Black density out of range',
  '50072': 'Sensor out of range, drum phase yellow; no message',
  '50073': 'Sensor out of range, drum phase magenta; no message',
  '50074': 'Sensor out of range, drum phase cyan; no message',
  '50075': 'Sensor out of range, drum phase black; no message',
  '505xx': 'Firmware error, power cycle',
  '50599': 'Processor error, power cycle',
  '55001': 'Generic language failure',
};
