import React, { useCallback, useMemo } from 'react';
import { UserInvestigation } from 'model';
import { ReactComponent as MosaicIcon } from 'svg/mosaic/mosaic-default-filled.svg';
import { ReactComponent as TemplateIcon } from 'svg/mosaic/mosaic-default-outline.svg';
import { useFlexLayout, useTable } from 'react-table';
import { useHistory } from 'react-router-dom';
import { InvestigationTypes } from 'utils';

export function ReferencesTable({
  referencedInvestigations,
}: { referencedInvestigations: UserInvestigation[] }) {
  const history = useHistory();

  const columns: any = useMemo(
    () => [
      {
        Header: 'References',
        accessor: 'references',
        Cell: ({ row }: any) => {
          const item = row?.original;
          return (
            <div className="uk-text-truncate">
              {item?.isTemplate ? <TemplateIcon /> : <MosaicIcon />}&nbsp;&nbsp;{item?.title}
            </div>
          );
        },
      },
    ],
    [],
  );

  const onRowClick = useCallback(
    (row) => {
      const id = row?.original?.id;
      const shortID = id.split(':')[1];
      history.push(
        `/${(row?.original?.isTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic).toLowerCase()}?id=${shortID}`,
      );
    },
    [history],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data: referencedInvestigations,
    },
    useFlexLayout,
  );

  return (
    <div className="uk-flex uk-flex-column reference-table">
      <table {...getTableProps()} className="manager-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                return (
                  <th {...column.getHeaderProps(headerProps({}, { column }))}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{ ...row.getRowProps().style, cursor: 'pointer' }}
                onClick={() => onRowClick && onRowClick(row)}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      onClick={(event) => {
                        cell.column.disableRowClick && event.stopPropagation();
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {rows.length === 0 && (
            <div className="uk-padding-medium uk-text-center">No References</div>
          )}
        </tbody>
      </table>
    </div>
  );
}

function getStyles(props: any, align = 'left') {
  return [
    props,
    {
      style: {
        justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
      },
    },
  ];
}

const headerProps = (props: any, { column }: any) => getStyles(props, column.align);
