import { DocumentNode } from 'graphql';
import { useDataTable } from 'hooks';
import { AdvancedQuery } from 'model';
import { formatRouterData, NewTableColumn } from 'utils';

type RouterDataTableProps = {
  routerQuery: DocumentNode | AdvancedQuery;
  dataPath: string;
  queryVariables: any;
  columnOptions: NewTableColumn[];
};

export function useRouterDataTable({
  routerQuery,
  dataPath,
  queryVariables,
  columnOptions,
}: RouterDataTableProps) {
  const routerTableData = useDataTable({
    queryOrAdvancedQuery: routerQuery,
    dataPath,
    formatFunction: formatRouterData,
    queryVariables,
    columnOptions,
    tabId: 'Routers',
    isAssetPageConfig: true,
  });

  return {
    ...routerTableData,
    name: 'Routers',
    paginationProps: { ...routerTableData.paginationProps, rowsToLoad: 10 },
  };
}
