import { getCountryName } from 'utils';

export function buildHistogramItems(data: any, total?: number) {
  const totalScore = total
    ? total
    : data.map((dataObj: any) => dataObj.score).reduce((sum: number, score: number) => sum + score);
  const histogramItems: any = [];
  data.forEach((dataObj: any, index: number) => {
    const percentage = Math.floor((100 * dataObj.score) / totalScore);
    if (index < 5) {
      const newHistObj: Record<string, any> = {
        label: dataObj.label,
        values: { pct: Math.max(percentage, 1) },
        valuesFormatted: { pct: percentage < 1 ? '<1' : Math.floor(percentage) },
        subLabel: dataObj.subLabel,
        description: dataObj.description,
        labelLink: dataObj.labelLink,
        subLabelLink: dataObj.subLabelLink,
      };
      histogramItems.push(newHistObj);
    }
  });

  return histogramItems;
}

export function toHistogramItems(countries: any[]) {
  return countries
    .map((c: any) => ({
      label: getCountryName(c.country),
      values: { count: c.count },
      valuesFormatted: {},
    }))
    .slice(0, 5);
}
