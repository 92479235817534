import { lazy } from 'react';

export * from './Login';
export * from './Header';
export * from './Main';
export * from './ErrorBoundary';
export * from './ErrorInfo';
export * from './AssetReport';
export * from './AssetInfo';
export * from './AssetSearch';
export * from './advancedquery';
export * from './Modal';
export * from './ContextMenu';
export * from './ModalSpinner';
export * from './FileSelector';
export * from './componentPluginNames';
export * from './legacyPlugins';
export * from './AS';
export * from './UserTags';
export * from './reactSelectTheme';
export * from './TabbedContent';
export * from './Tree';
export * from './DataExporter';
export * from './datamanagers';
export * from './AuthorizedElement';
export * from './PrivateRoute';
export * from './NewTable';
export * from './DatasetCappingBanner';
export * from './cardcomponents';
export * from './banners';
export * from './viz';
export * from './investigations';
export * from './tablecomponents';
export * from './markdown';
export * from './viewconfig';
export * from './reactselect/MultiValueRemove';
export * from './Shared';
export * from './kebabmenus';
export * from './Kebab';
export * from './TooltipAttributes';
export * from './Popup';
export * from './AssetInfoPopup';
export * from './EmbeddedWindow';
export * from './DatePicker';
export * from './AttributeTable';
export * from './AssetAttributesHistory';
export * from './AssetAttributesHistoryRedux';
export * from './Gallery';
export * from './Collections';
export * from './AssetList';
export * from './AdvancedSearchLink';
export * from './ImportPage';
export * from './TableCell';
export * from './TableItems';
export * from './RadioCard';
export * from './FlexAttributes';
export * from './filtercomponents';
export * from './ShortCutTooltip';
export * from './TagContainer';
export * from './MPLSIcon';
export * from './tooltips';
export * from './LoadingDots';
export { default as StartPage } from './startpage/StartPage';
export { default as Spinner } from './Spinner';
export { default as SeeMore } from './SeeMore';
export { default as NewTable } from './NewTable';
export { default as AssetLink } from './AssetLink';
export { default as AssetGeo } from './AssetGeo';
export { default as AS } from './AS';
export { default as Prefix } from './Prefix';
export { default as IP } from './IP';
export { default as TabbedContent } from './TabbedContent';
export { default as ReportTitle } from './ReportTitle';
export * from './PatternDiscovery';
export { default as EmptyMessage } from './EmptyMessage';
export * from './reactselect';

export const Investigation = lazy(() => import('./investigations/Investigation'));
export const GraphiQLView = lazy(() => import('./GraphiQLView'));
