import React from 'react';
import { ReactComponent as ShutEye } from 'svg/actions/eye-shut.svg';

export type EmptyStateProps = {
  userCanFix?: boolean;
};

export function EmptyState({ userCanFix = false }: EmptyStateProps) {
  const containerClass = 'empty-state-container' + (!userCanFix ? ' no-body' : '');
  return (
    <div className={containerClass}>
      <div className="empty-state-title">
        <ShutEye />
        {userCanFix ? 'No Data in View' : 'No Data to Display'}
      </div>
      {userCanFix && (
        <div className="empty-state-body">Adjust the filter criteria to see more results</div>
      )}
    </div>
  );
}
