import { makeVar } from '@apollo/client';
import { ComponentPluginName } from 'components';
import { TemplateAssetType } from './assetTypes';
import { ViewConfigName } from 'components/viewconfig';
import {
  DnsHostFilterClause,
  FacilityFilterClause,
  PeeringExchangeFilterClause,
  RouterFilterClause,
} from './datasets';
import { Tooltips } from 'components/tooltips';

export enum QueryFilterComparator {
  Is = 'is',
  IsOneOf = 'is one of',
  Includes = 'includes',
  IncludesAllOf = 'includes all of',
  IncludesOneOf = 'includes one of',
  IncludesBothOf = 'includes both of',
  DoesNotInclude = 'does not include',
  IsNot = 'is not',
  Exists = 'exists',
  IsInDataset = 'is in dataset',
  IsSharedWith = 'is shared with',
  IsBefore = 'is before',
  IsAfter = 'is after',
  EndsWith = 'ends with',
  AreBoth = 'are both',
  CrossBoundaryOf = 'crosses boundary of',
  GTE = 'gte',
  LTE = 'lte',
  LT = 'lt',
  GT = 'gt',
}

export type QueryFilterClause = {
  type: string;
  comparator?: QueryFilterComparator;
  value?: any;
  isUnlocked?: boolean;
};

export type QueryValueOption = {
  value: string;
  label: string;
};

export type QueryFilterClauseOption = {
  type: string;
  label: string;
  field: string;
  comparatorOptions: QueryFilterComparator[];
  valueOptions?: QueryValueOption[];
  isSecondaryFilter?: boolean;
  pipelineFilterClauses?: (clause: QueryFilterClause) => QueryFilterClause[];
  formatFilter?: (clause: QueryFilterClause) => string | undefined;
  // This loads in value options that will requery the backend upon having new information (e.g. user types in more characters)
  loadValueOptions?: (
    value: string,
    callback?: (options: QueryValueOption[]) => void,
  ) => Promise<QueryValueOption[]>;
  // This loads in a fixed list of options that only queries the backend once for all the options
  loadFixedValueOptions?: (
    value: string,
    callback?: (options: QueryValueOption[]) => void,
    filter?: string,
  ) => Promise<QueryValueOption[]>;
  normalizeValue?: (value: string) => string;
  normalizeLabel?: (label: string) => string;
  formatField?: (clause: QueryFilterClause) => string | undefined;
  assetType?: TemplateAssetType;
};

export type GraphQLQueryOptions = {
  params: Record<string, string>;
  variables: Record<string, any>;
  variableDefs: Record<string, string>;
};

export type QueryResolutionOption = QueryValueOption & {
  makeGraphQLQuery: (
    advancedQuery: AdvancedQuery,
    options: GraphQLQueryOptions,
  ) => Promise<string> | string;
};

/**
 * oldRef:
 */
export type QueryViewField = {
  oldRef?: string; // previously "name", was used as the API field, needed for backwards compatibility. treat as immutable. Brand new sorts/groups do not need this to be defined.
  field: string; // the field key on the API to do sorting/grouping
  label: string; // what we show on the UI
  type?: TemplateAssetType;
  identifier: string; // what is stored on the investigation. treat as immutable. if this doesn't match what an investigation has stored, it will check oldRef next
  sortable?: boolean;
  groupable?: boolean;
  maxWidth?: string; // TBD on if needed
  hidden?: boolean; // TBD on if needed
  pluralLabel?: string;
  // Whether or not to create a histogram bucket for records which appear in the total count of the query
  // but are not returned in the aggregation.
  includeUncategorizedBucket?: boolean;
  filterClauseType?:
    | DnsHostFilterClause
    | FacilityFilterClause
    | PeeringExchangeFilterClause
    | RouterFilterClause; // this is to support histogram outputs that can be used in a downstream with the same resolution as the upstream.
};

export type QueryViewOption = QueryValueOption & {
  getFields: (advancedQuery: AdvancedQuery) => QueryViewField[];
  mapResults?: (advancedQuery: AdvancedQuery, items: any[]) => any;
  tableColumns?: Object[];
  componentPluginName: ComponentPluginName;
  configOptions?: Record<string, any>;
  configComponent?: ViewConfigName;
  tooltipComponent?: Tooltips;
  disableConfigMenu?: boolean;
  defaultSort?: string;
  defaultGroup?: string;
};

export type QuerySelection = {
  value?: string;
  label?: string;
  typename?: string;
  __isNew__?: boolean;
};

// This type needs to be JSON serializable so that it can be encoded as a query param in a url.
export type AdvancedQuery = {
  selection: QuerySelection;
  resolution: string;
  view: string;
  filterClauses?: QueryFilterClause[];
  disableFilters?: boolean;
  sort?: string;
  group?: string;
  viewConfig?: Record<string, any>;
};

export type AdvancedQueryTemplate = {
  name: string;
  resolutionOptions: QueryResolutionOption[];
  filterClauseOptions: Record<string, QueryFilterClauseOption[]>;
  viewOptions: Record<string, QueryViewOption[]>;
  newAdvancedQuery?: (selection: QuerySelection, resolution?: string) => AdvancedQuery;
  getPipelineParams?: (advancedQuery: AdvancedQuery) => string[];
};

export const advancedQueryPageVar = makeVar<Record<string, any> | undefined>(undefined);
export const advancedQueryResultsVar = makeVar<Record<string, any> | undefined>(undefined);
