import React from 'react';
import { components } from 'react-select';
import { TemplateParam, UserInvestigationSection } from 'model';
import { getTemplateParamId, isOutputVariable, parseInvestigationSectionId } from 'utils';

function findMatchingTemplateParam(
  templateParams: TemplateParam[],
  matchingData: { value: any; id: string },
): TemplateParam {
  return templateParams?.find((param: TemplateParam) => {
    const paramId = getTemplateParamId(param);
    return `$${paramId}` === matchingData.value || paramId === matchingData.id;
  });
}

export function TemplateParameterNameSingleValue({ children, ...props }: any) {
  const paramValue = props?.selectProps?.value?.value;

  if (typeof paramValue === 'string' && !paramValue?.startsWith('$'))
    return <components.SingleValue {...props}>{children}</components.SingleValue>;

  const sections = props?.selectProps?.sections;
  const hideParamValue = props?.selectProps?.hideParamValue;
  const matchingTemplateParam = findMatchingTemplateParam(
    props?.selectProps?.templateParams,
    props?.selectProps?.value,
  );
  const paramLabel = matchingTemplateParam?.label ?? matchingTemplateParam?.value;
  const paramName = matchingTemplateParam?.name;
  const isOv = isOutputVariable(matchingTemplateParam);
  const originSectionNumber = isOv
    ? sections?.findIndex(
        (section: UserInvestigationSection) =>
          parseInvestigationSectionId(section.id)[1]?.uuid === matchingTemplateParam.origin,
      ) + 1
    : 0;

  return (
    <components.SingleValue {...props} className="template-single-value">
      {paramName && (
        <div
          className={`template-param-name ${props.isDisabled && 'template-param-name-disabled'}`}
        >
          {paramName}
        </div>
      )}
      {paramLabel == null || hideParamValue ? null : Array.isArray(paramLabel) ? (
        paramLabel.map((val: string, index: number) => {
          if (index === paramLabel.length - 1) {
            return (
              <div
                key={index}
                className={`${isOv ? 'output-param-end-value' : 'template-param-end-value'}`}
              >
                {val}
              </div>
            );
          }
          return (
            <div key={index} className={`${isOv ? 'output-param-value' : 'template-param-value'}`}>
              {val}
            </div>
          );
        })
      ) : (
        <div className={`uk-flex ${isOv ? 'output-param-end-value' : 'template-param-end-value'}`}>
          {typeof paramLabel === 'string' ? paramLabel : null}
          {originSectionNumber > 0 ? (
            <div className="faded-output-param uk-margin-small-left">{`(Tile ${originSectionNumber})`}</div>
          ) : null}
        </div>
      )}
    </components.SingleValue>
  );
}

export function TemplateParameterNameMultiValue({ children, ...props }: any) {
  const data = props.data;
  const matchingTemplateParam = findMatchingTemplateParam(props?.selectProps?.templateParams, data);

  return matchingTemplateParam ? (
    <components.MultiValue
      {...props}
      className={`output-param-multi-value ${matchingTemplateParam.value?.count === 0 ? 'output-param-multi-value-error' : ''}`}
    >
      {matchingTemplateParam.name}
    </components.MultiValue>
  ) : (
    <components.MultiValue {...props}>{data.label}</components.MultiValue>
  );
}

export function TemplateParameterNameSidebarSingleValue({ children, ...props }: any) {
  const value = props.selectProps?.value;

  return (
    <components.SingleValue {...props} className="template-single-value">
      {!children ? null : Array.isArray(value) ? (
        value.map((obj: any, index: number) => {
          if (index === value.length - 1) {
            return (
              <div key={index} className="template-param-end-value">
                {obj.label}
              </div>
            );
          }
          return (
            <div key={index} className="template-param-value">
              {obj.label}
            </div>
          );
        })
      ) : (
        <div className="template-param-end-value">{children}</div>
      )}
    </components.SingleValue>
  );
}

export function TemplateParameterNameOption({ children, ...props }: any) {
  const paramValue = props?.data?.value;
  if (typeof paramValue === 'string' && !paramValue?.startsWith('$')) {
    return <components.Option {...props}>{children}</components.Option>;
  }

  const matchingTemplateParam = findMatchingTemplateParam(
    props?.selectProps?.templateParams,
    props?.data,
  );
  const isOv = isOutputVariable(matchingTemplateParam);
  const paramLabel = matchingTemplateParam?.label;
  const paramName = matchingTemplateParam?.name;

  return (
    <components.Option {...props} className="template-option-wrapper">
      {paramName && (
        <div className={`template-param-name template-param-name-option template-param-option`}>
          {paramName}
        </div>
      )}
      {paramLabel == null ? null : Array.isArray(paramLabel) ? (
        paramLabel.map((val: string, index: number) => {
          if (index === paramLabel.length - 1) {
            return (
              <div
                key={index}
                className={`${isOv ? 'output-param-end-value' : 'template-param-end-value template-param-option-end-value'} template-param-option`}
              >
                {val}
              </div>
            );
          }
          return (
            <div
              key={index}
              className={`${isOv ? 'output-param-value' : 'template-param-value'} template-param-option`}
            >
              {val}
            </div>
          );
        })
      ) : (
        <div
          className={`${isOv ? 'output-param-end-value' : 'template-param-end-value template-param-option-end-value'} template-param-option`}
        >
          {paramLabel}
        </div>
      )}
    </components.Option>
  );
}

export function TemplateParameterValueContainer({ children, ...props }: any) {
  const isMulti = props?.isMulti;
  return (
    <components.ValueContainer
      {...props}
      className={isMulti ? 'template-multi-value-container' : 'template-value-container'}
    >
      {children}
    </components.ValueContainer>
  );
}
