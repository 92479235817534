import React, { ReactNode } from 'react';
import { ComponentPluginName } from 'components';
import {
  UserInvestigation,
  UserInvestigationSection,
  TemplateParams,
  QueryFilterClause,
} from 'model';
import { SidebarOptions } from 'components/investigations/sidebar/Sidebar';

export type ComponentPluginContextValueOnToggleLock = (props?: {
  clause: QueryFilterClause;
  index?: number;
}) => void;

export type ComponentPluginContextValue = {
  title: string;
  description?: string;
  tooltip?: ReactNode;
  pluginName: ComponentPluginName;
  params: Record<string, any>;
  getPluginConfig: () => Record<string, any>;
  updatePluginConfig: (config: Record<string, any>, forceRender?: boolean) => void;
  graphQLQueries: Record<string, any>;
  setGraphQLQueries?: Function;
  isPrinterFriendly?: boolean;
  setMetadata?: Function;
  isTemplate?: boolean;
  templateParams?: TemplateParams;
  onToggleLock?: ComponentPluginContextValueOnToggleLock;
  menuOpenRef?: any;
  setLocalConfig?: Function;
  configOptions?: any;
  setConfigOptions?: Function;
};

export const ComponentPluginContext = React.createContext<ComponentPluginContextValue>({
  title: '',
  pluginName: '' as ComponentPluginName,
  params: {},
  getPluginConfig: () => ({}),
  updatePluginConfig: (_: Record<string, any>) => {},
  graphQLQueries: {},
});

export type DataSlicesContextValue = {
  hasPorts: boolean;
  hasPortRecords: boolean;
  metadata: Record<string, any>;
  maxPipelineCount: Number;
};

export const DataSlicesContext = React.createContext<DataSlicesContextValue>({
  hasPorts: false,
  hasPortRecords: false,
  metadata: {},
  maxPipelineCount: 65535, // default max pipeline
});

export type InvestigationContextValue = {
  investigation?: UserInvestigation;
  pluginContexts?: Record<string, ComponentPluginContextValue>;
};

export const InvestigationContext = React.createContext<InvestigationContextValue>({});

export type AssetInfoContextValue = {
  infoMode: boolean;
  displayElements: string[];
};

export const AssetInfoContext = React.createContext<AssetInfoContextValue>({
  infoMode: false,
  displayElements: [],
});

export type SharedContextValue = {
  sharedKey?: string;
  sharedUserDataId?: string;
};

export type LocalAnnotations = {
  mainDesc?: string;
  mainTitle?: string;
  focusedDesc?: string;
  focusedTitle?: string;
};

export const SharedContext = React.createContext<SharedContextValue>({});

export type SidebarContextValue = {
  pdfGenerating?: boolean;
  investigation?: UserInvestigation;
  focusedTile?: UserInvestigationSection;
  setFocusedTile: Function;
  downloadArchive?: Function;
  setDownloading?: Function;
  archiveData?: Array<any>;
  setArchiveData?: Function;
  isTemplate?: boolean;
  setLocalTemplateParams?: Function;
  localTemplateParams?: TemplateParams;
  setLocalAnnotations?: Function;
  localAnnotations?: LocalAnnotations;
  setIsMainFocused?: Function;
  isMainFocused?: boolean;
  activeOption?: SidebarOptions;
  setActiveOption?: Function;
};

export const SidebarContext = React.createContext<SidebarContextValue>({
  pdfGenerating: false,
  investigation: undefined,
  focusedTile: undefined,
  isTemplate: undefined,
  setArchiveData: () => ({}),
  downloadArchive: () => ({}),
  archiveData: undefined,
  setFocusedTile: () => ({}),
  activeOption: undefined,
});

export type KebabContextValue = {
  returnKebabMain: Function;
  closeKebabDropdown: Function;
  reactSelectClicked?: any; // Ref to assist with handling react select in kebab menus
  // Because of the portal nature of react-select menus, a click
  // inside is considered a click outside of the kebab menu, and
  // therefore closes it.
};

export const KebabContext = React.createContext<KebabContextValue>({
  returnKebabMain: () => ({}),
  closeKebabDropdown: () => ({}),
  reactSelectClicked: undefined,
});

export type GalleryContextValue = {
  refetchGallery: Function;
};

export const GalleryContext = React.createContext<GalleryContextValue>({
  refetchGallery: () => ({}),
});
