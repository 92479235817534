import React, { Fragment, useContext, useMemo } from 'react';
import { Banner, EmptyState, FlexAttributes, Spinner, formatDiffsAttributes } from 'components';
import { ComponentPluginContext } from 'appContexts';
import { buildDataAttributeDiffs, formatDate } from 'utils';
import { ALERT_TYPES } from 'model';
import { ReactComponent as DiffLeftIcon } from 'svg/system/diff-left.svg';
import { ReactComponent as DiffRightIcon } from 'svg/system/diff-right.svg';
import useResizeObserver from 'use-resize-observer';
import { max } from 'lodash';

type AssetAttributesHistoryProps = {
  data: any;
  historyLoading: boolean;
};

const fontPixelWidth = 7;

export function getWrappedPercentage(comparedAttrs: any, containerWidth: number) {
  const containerMaxWidth = containerWidth / 2 - 16;

  const attributes = formatDiffsAttributes(comparedAttrs);
  const maxLabelLength = max(attributes.map((attr) => attr?.label?.length || 0));
  const maxSubLabelLength = max(attributes.map((attr) => attr?.sublabel?.length || 0));

  const wrappedCount = attributes
    .map((attr) => {
      const valueLength = (attr.value?.props?.value || attr.value)?.length;
      const totalLength =
        (maxLabelLength + maxSubLabelLength + valueLength) * fontPixelWidth +
        16 +
        (maxSubLabelLength > 0 ? 16 : 0) +
        16;
      return totalLength;
    })
    .filter((width) => width > containerMaxWidth).length;

  return wrappedCount / attributes.length;
}

export function AssetAttributesHistoryRedux({ data, historyLoading }: AssetAttributesHistoryProps) {
  const { getPluginConfig, isPrinterFriendly } = useContext(ComponentPluginContext);
  const { dateA, dateB } = getPluginConfig()?.history || {};
  const { ref, width } = useResizeObserver<HTMLDivElement>();
  const aAttrs = data?.find((obj) => obj.timestamp === dateA)?.attributes;
  const bAttrs = data?.find((obj) => obj.timestamp === dateB)?.attributes;

  const [aComparedAttrs, bComparedAttrs] = useMemo(() => {
    return buildDataAttributeDiffs(aAttrs, bAttrs);
  }, [aAttrs, bAttrs]);

  const shouldWrap = useMemo(() => {
    if (isPrinterFriendly) return false;
    const aWrappedPercentage = getWrappedPercentage(aComparedAttrs, width);
    const bWrappedPercentage = getWrappedPercentage(bComparedAttrs, width);

    return aWrappedPercentage > 0.1 || bWrappedPercentage > 0.1;
  }, [aComparedAttrs, bComparedAttrs, width, isPrinterFriendly]);

  if (historyLoading) {
    return <Spinner ratio={3} />;
  }

  if (!dateA && !dateB) {
    return <EmptyState />;
  }

  const historyMessage = `Data no longer available for ${!aAttrs ? formatDate(dateA) : ''}${!aAttrs && !bAttrs ? ' and ' : ''}${!bAttrs ? formatDate(dateB) : ''}. Please select another date.`;

  return (
    <Fragment>
      {(!aAttrs || !bAttrs) && (
        <Banner
          style={{ width: '576px', boxSizing: 'border-box' }}
          title={'History Warning'}
          message={historyMessage}
          alertType={ALERT_TYPES.warning}
        />
      )}
      <div
        className={`app-history-comparer ${shouldWrap ? 'app-history-comparer-wrap' : ''}`}
        ref={ref}
      >
        <div className="before-section">
          <div className="app-history-date app-comparer-date">
            <DiffLeftIcon />
            {formatDate(dateA)}
          </div>
          <FlexAttributes attributes={aComparedAttrs} />
        </div>
        <div className="after-section">
          <div className="app-history-date app-reference-date">
            <DiffRightIcon />
            {formatDate(dateB)}
          </div>
          <FlexAttributes attributes={bComparedAttrs} />
        </div>
      </div>
    </Fragment>
  );
}
