import React, { useEffect, useMemo, useState, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { useInView } from 'react-intersection-observer';
import { ALERT_TYPES, Asset, AssetType } from 'model';
import AS from './AS';
import Prefix from './Prefix';
import SeeMore from './SeeMore';
import { Banner, Spinner } from 'components';

const orgQuery = gql`
  query OrgQuery($name: String!) {
    orgSearch(name: $name) {
      orgName
      prefixCount
      asCount
    }
  }
`;

export function AssetSearch({ location }: RouteComponentProps) {
  const [results, setResults] = useState(null);
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const name = searchParams.get('q');

  useQuery(orgQuery, {
    variables: { name },
    onCompleted: (data: any) => setResults(data.orgSearch),
    skip: !name,
  });

  useEffect(() => setResults(null), [location]);

  return (
    <div className="app-results">
      <h2>Results for "{name}"</h2>
      <SearchResults results={results} name={name} />
    </div>
  );
}

export function SearchResults({ results, name }: { results: any; name: string }) {
  if (results && results.length === 0)
    return (
      <Banner
        style={{ width: '576px', marginBottom: '0px', boxSizing: 'border-box' }}
        title={'No Results'}
        alertType={ALERT_TYPES.placeholder}
        noClose={true}
        message={`The search returned no organizations containing "${name}".`}
      />
    );

  return (
    <ul
      className="uk-list uk-margin-remove uk-display-inline-block"
      data-uk-accordion="multiple: true"
    >
      {results &&
        results.map((item: any) => (
          <li key={item.orgName}>
            <OrgItem item={item} />
          </li>
        ))}
    </ul>
  );
}

function OrgItem({ item }: { item: any }) {
  const names = item.orgName.split('|');
  const label = names.length > 1 ? `${names[0]} (${names.slice(1).join(', ')})` : names[0];

  return (
    <Fragment>
      <div className="uk-accordion-title uk-margin-small-bottom cursor-pointer">
        <span>{label}</span>
        <span className="uk-margin-small-left">
          (ases: {item.asCount}, prefixes: {item.prefixCount})
        </span>
      </div>
      <OrgAssets item={item} />
    </Fragment>
  );
}

const orgAssetsQuery = gql`
  query OrgQuery($name: String!) {
    orgAssets(name: $name) {
      orgName
      ases {
        asn
      }
      prefixes {
        prefix
      }
    }
  }
`;

function OrgAssets({ item }: { item: any }) {
  const { ref, inView } = useInView();
  const [assets, setAssets] = useState<Record<string, any> | null>(null);
  const [fetchOrgAssets] = useLazyQuery(orgAssetsQuery, {
    onCompleted: (data: any) => {
      const ases = data.orgAssets.ases.map((as: Asset) => {
        as.type = AssetType.AS;
        return as;
      });
      const prefixes = data.orgAssets.prefixes.map((prefix: Asset) => {
        prefix.type = AssetType.Prefix;
        return prefix;
      });
      setAssets({ ases, prefixes });
    },
  });

  useEffect(() => {
    setAssets(null);
  }, [item]);

  useEffect(() => {
    if (!assets && inView) {
      fetchOrgAssets({ variables: { name: item.orgName } });
    }
  }, [item, assets, inView, fetchOrgAssets]);

  const asItems = assets?.ases.map((asset: any) => (
    <div>
      <AS asset={{ asn: asset.asn }} />
    </div>
  ));
  const prefixItems = assets?.prefixes.map((asset: any) => (
    <div>
      <Prefix asset={{ prefix: asset.prefix }} />
    </div>
  ));
  return (
    <div ref={ref} className="uk-accordion-content" style={{ minHeight: '2rem' }}>
      {asItems && <SeeMore items={asItems} topCount={10} />}
      {prefixItems && <SeeMore items={prefixItems} topCount={10} />}
      {!assets && <Spinner />}
    </div>
  );
}

export default AssetSearch;
