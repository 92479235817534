import { countTermInInvestigation, FindAndReplaceTerm } from 'utils';
import { ALERT_TYPES, UserInvestigation } from 'model';
import React, { useCallback, useMemo } from 'react';
import { Banner } from 'components';
import { ReactComponent as Search } from 'svg/system/magnifying-glass.svg';

export function FindAndReplace({
  replaceInMain,
  findAndReplaceTerm,
  updateFindAndReplaceTerm,
  investigation,
  selected,
  showBanner,
}: {
  replaceInMain: boolean;
  findAndReplaceTerm: FindAndReplaceTerm;
  updateFindAndReplaceTerm: Function;
  investigation: UserInvestigation;
  selected: Set<string>;
  showBanner: boolean;
}) {
  const onUpdateTerm = useCallback(
    (val: string, type: string) => {
      updateFindAndReplaceTerm(type, val);
    },
    [updateFindAndReplaceTerm],
  );

  const foundCount = useMemo(() => {
    return countTermInInvestigation(
      findAndReplaceTerm?.find,
      replaceInMain,
      investigation,
      selected,
    );
  }, [findAndReplaceTerm.find, replaceInMain, investigation, selected]);

  const message = useMemo(() => {
    if (!findAndReplaceTerm?.find) return '';
    let str = '';
    if (selected.size > 0) {
      str += `in the ${selected.size} selected tile${selected.size > 1 ? 's' : ''}`;
      if (replaceInMain) {
        str += ', and in the main title and description';
      }
    } else {
      if (replaceInMain) {
        str += 'in the mosaic title and description';
      }
    }
    return (
      <p>
        Found <span className="uk-text-bold">{foundCount}</span> instance
        {foundCount !== 1 ? 's' : ''} of "
        <span className="uk-text-bold">{findAndReplaceTerm?.find}</span>" {str}.
      </p>
    );
  }, [findAndReplaceTerm.find, foundCount, selected, replaceInMain]);

  return (
    <div className="uk-flex uk-flex-column">
      <div className="uk-flex uk-flex-column">
        <div className="input-label">find</div>
        <input
          className="uk-input kebab-clause-input uk-margin-right"
          type="text"
          value={findAndReplaceTerm.find}
          placeholder={'Enter a value to find'}
          onChange={(e) => {
            const val = e.target.value;
            onUpdateTerm(val, 'find');
          }}
        />
        <div className="input-label uk-margin-top">replace</div>
        <input
          className="uk-input kebab-clause-input"
          type="text"
          value={findAndReplaceTerm.replace}
          placeholder={'Enter a value to replace'}
          onChange={(e) => {
            const val = e.target.value;
            onUpdateTerm(val, 'replace');
          }}
        />
      </div>
      {message && showBanner ? (
        <div className="uk-margin-medium-top">
          <Banner
            style={{ marginTop: '0', marginBottom: '16px', maxHeight: '300px' }}
            contentClass={'uk-overflow-auto'}
            alertType={ALERT_TYPES.placeholder}
            noClose={true}
            message={message}
            icon={<Search />}
          />
        </div>
      ) : null}
    </div>
  );
}
