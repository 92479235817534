import React from 'react';
import { Spinner, Modal } from 'components';

let showDelayId: any = null;
let hideDelayId: any = null;
let isBusy: boolean = false;
let isShown: boolean = false;

const showDelay = 300;
const hideDelay = 500;

export class ModalSpinner {
  static async blockWhileExecuting(func: () => any) {
    return await ModalSpinner.wrapFunction(func)();
  }

  static wrapFunction(func: () => any) {
    return async () => {
      try {
        ModalSpinner.setBusy(true);
        return await Promise.resolve(func());
      } finally {
        ModalSpinner.setBusy(false);
      }
    };
  }

  static setBusy(busy: boolean) {
    if (busy === isBusy) {
      return;
    }
    isBusy = busy;

    clearTimeout(showDelayId);
    clearTimeout(hideDelayId);
    showDelayId = hideDelayId = null;

    if (busy) {
      if (!isShown) {
        showDelayId = window.setTimeout(() => {
          ModalSpinner.showModalSpinner();
          showDelayId = null;
          isShown = true;
        }, showDelay);
      }
    } else if (isShown) {
      hideDelayId = window.setTimeout(() => {
        ModalSpinner.hideModalSpinner();
        hideDelayId = null;
        isShown = false;
      }, hideDelay);
    }
  }

  private static showModalSpinner() {
    if (!Modal.isShowing()) {
      Modal.show({
        component: <Spinner ratio={3} position="center" />,
        background: 'rgb(0, 0, 0, 0)',
      });
    }
  }

  private static hideModalSpinner() {
    Modal.hide();
  }
}
