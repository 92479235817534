import { SidebarContext } from 'appContexts';
import Spinner from 'components/Spinner';
import React, { useContext } from 'react';
import { ReactComponent as Warning } from 'svg/actions/exclamation-fill.svg';

export function DownloadArchivePrompt({ selected }: { selected: Set<string> }) {
  const { downloadArchive, pdfGenerating } = useContext(SidebarContext);

  return !selected.size ? (
    <div className="uk-flex" style={{ padding: '14px 16px' }}>
      <div className="uk-margin-small-right">Select an archive or download the current version</div>
      <button
        onClick={() => downloadArchive('current')}
        disabled={pdfGenerating}
        className="uk-button-default uk-margin-auto-left uk-button"
      >
        {pdfGenerating ? (
          <Spinner ratio={0.55} style={{ margin: '-8px auto 0 auto', width: '36px' }} />
        ) : (
          'Download'
        )}
      </button>
    </div>
  ) : selected.size > 1 ? (
    <div className="archive-download-warning">
      <Warning className="uk-margin-right" />
      Select a single archive to download
    </div>
  ) : (
    <div className="uk-flex">
      <div>Download selected archive</div>
      <button
        onClick={() => downloadArchive(Array.from(selected)[0])}
        disabled={pdfGenerating}
        className="uk-button-default uk-margin-auto-left uk-button"
      >
        Download
      </button>
    </div>
  );
}
