import {
  acknowledgeNotification,
  ALERT_TYPES,
  AssetType,
  createGenericNotification,
  QuerySelection,
} from 'model';
import {
  addToUserSearchHistory,
  getHistory,
  guessAssetType,
  matchCountryOptions,
  matchUserSearchHistory,
} from 'utils';
import { flatten } from 'lodash';
import { getGraphQLClient } from 'gql';
import { gql } from '@apollo/client';

export async function invokeSearch(selection: QuerySelection) {
  if (!selection) {
    return;
  }
  const history = getHistory();
  const value = selection.value;
  if (!selection.typename) {
    const assetType = guessAssetType(value);
    if (assetType !== AssetType.Org) {
      delete selection.__isNew__;
      addToUserSearchHistory(selection);
      history.push(`/assetreport?${assetType}=${value}`);
    } else {
      const suggestions = await querySuggestions(selection.value);
      const flatSuggestions = flatten(
        suggestions
          .filter((suggestion) => suggestion?.label !== 'History')
          .map((suggestion) => suggestion.options ?? []),
      );
      if (flatSuggestions.length) {
        // loop through the suggestions array. If we find an option that matches exactly, go with that one.
        const match: QuerySelection = flatSuggestions.find((suggestion: QuerySelection) =>
          [suggestion.value.toLowerCase(), suggestion.label.toLowerCase()].includes(
            selection.value.toLowerCase(),
          ),
        );
        if (match) {
          addToUserSearchHistory(match);
          history.push(`/assetreport?${match.typename}=${match.value}`);
          return;
        }
        // If there are some suggestions, but no exact matches, take the user to the search results page to browse
        // fuzzy matches
        history.push(`/assetsearch?q=${value}`);
        return;
      }
      // If there are no suggestions nor exact matches, reroute to the home page with a notification
      createGenericNotification('invalid-asset', {
        alertType: ALERT_TYPES.placeholder,
        icon: null,
        message: `${selection.value} did not match an asset`,
        title: 'No Results Found',
        onClear: () => acknowledgeNotification('invalid-asset'),
      });
      history.push('/');
    }
  } else if (Object.values(AssetType).includes(selection.typename as AssetType)) {
    addToUserSearchHistory(selection);
    history.push(`/assetreport?${selection.typename}=${value}`);
  } else {
    addToUserSearchHistory(selection);
    history.push(`/assetreport?org=${value}`);
  }
}

export async function querySuggestions(name: string) {
  const suggestionsQuery = `query { suggestions(name: "${name}") { orgs asns exchanges facilities }}`;
  const promise = getGraphQLClient().query({
    query: gql(suggestionsQuery),
    fetchPolicy: 'cache-first',
  });
  const countryOptions = matchCountryOptions(name);
  const searchHistoryOptions = matchUserSearchHistory(name);
  const result = await promise;
  const orgOptions: QuerySelection[] =
    result.data.suggestions?.orgs?.map((value: string) => ({
      label: value,
      value,
      typename: AssetType.Org,
    })) || [];
  const asnOptions: QuerySelection[] =
    result.data.suggestions?.asns?.map((suggestion: any) => ({
      label: `AS${suggestion.value} ${suggestion.label}`,
      value: suggestion.value.toString(),
      typename: AssetType.AS,
    })) || [];
  const ixOptions: QuerySelection[] =
    result.data.suggestions?.exchanges?.map((suggestion: any) => ({
      ...suggestion,
      typename: AssetType.IX,
    })) || [];
  const facOptions: QuerySelection[] =
    result.data.suggestions?.facilities?.map((suggestion: any) => ({
      ...suggestion,
      typename: AssetType.Facility,
    })) || [];
  const enteredOption: QuerySelection = { label: name, value: name, __isNew__: true };
  const options: any[] = [];

  if (orgOptions.length > 0) {
    options.push({ label: 'Organizations', options: orgOptions, column: 1 });
  }

  if (countryOptions.length > 0) {
    options.push({ label: 'Countries', options: countryOptions, column: 2 });
  }

  if (asnOptions.length > 0) {
    options.push({ label: 'ASes', options: asnOptions, column: 2 });
  }

  if (ixOptions.length > 0) {
    options.push({ label: 'Internet Exchanges', options: ixOptions, column: 2 });
  }

  if (facOptions.length > 0) {
    options.push({ label: 'Facilities', options: facOptions, column: 2 });
  }

  options.sort((a: any, b: any) => (a.options?.length ?? 0) - (b.options?.length ?? 0));

  if (searchHistoryOptions.length > 0) {
    options.unshift({ label: 'History', options: searchHistoryOptions, column: 1 });
  }

  options.unshift(enteredOption);
  return options;
}
