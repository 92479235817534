import { ReactiveVar } from '@apollo/client';
import { AdvancedQuery, QuerySelection } from './advancedQuery';

export enum UserDataSection {
  Tags = 'tags',
  AdvancedQueries = 'advancedQueries',
  Datasets = 'datasets',
  Investigations = 'investigations',
  SearchHistory = 'searchHistory',
}

export type UserMetadata = {
  id?: string;
  saved?: Date;
  system?: Record<string, any>;
};

export type UserTag = UserMetadata & {
  name: string;
};

export type UserTags = Record<string, UserTag>;

export type UserAdvancedQuery = UserMetadata & {
  query: AdvancedQuery;
  notes?: string;
  tags?: string[];
};
export type UserAdvancedQueries = Record<string, UserAdvancedQuery>;

export type UserDataset = UserMetadata & {
  name: string;
  description: string;
  filename: string;
  typename: string;
  tags?: string[];
  dataId?: string;
};

export type UserDatasets = Record<string, UserDataset>;

export type UserDatasetData = UserMetadata & {
  data: string;
};

export type UserInvestigationSection = {
  id: string;
  title: string;
  description: string;
  saved?: Date;
};

export enum PinTag {
  Pinned = 'pinned',
  PriorityPinned = 'priority-pinned',
}

type UserInvestigationBase = UserMetadata & {
  title: string;
  subtitle: string;
  description: string;
  sections: UserInvestigationSection[];
  tags?: UserTag[];
  images?: Images;
  sharing?: any;
  pinTag?: PinTag | null;
};

type UserInvestigationTemplate = UserInvestigationBase & {
  isTemplate: true;
  templateParams: TemplateParams;
  removeTemplateParamValues?: boolean;
};

type UserInvestigationNonTemplate = UserInvestigationBase & {
  isTemplate?: false;
  templateParams?: TemplateParams;
  removeTemplateParamValues?: undefined;
};

export type UserInvestigation = UserInvestigationTemplate | UserInvestigationNonTemplate;

export function isInvestigationTemplate(
  investigation: UserInvestigation,
): investigation is UserInvestigationTemplate {
  return investigation.isTemplate;
}

export type TemplateParam = {
  name?: string;
  expression?: string;
  id: string;
  origin?: string;
  value?: any;
  label?: any;
  clauseType?: string;
};

export type Image = {
  type: string;
  name: string;
  id: string;
  data?: string;
};

export type Images = Image[];

export type TemplateParams = Array<TemplateParam>;

export type UserInvestigations = Record<string, UserInvestigation>;

export type UserSearchHistory = QuerySelection[];

export const reactiveVarsBySection: Record<string, ReactiveVar<any>> = {};
