import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  AssetLink,
  AssetGeo,
  AttributeTable,
  GeoMap,
  Spinner,
  EmptyState,
  TagContainer,
} from 'components';
import { useAssetQuery } from 'hooks';
import { AssetInfoContext } from 'appContexts';
import {
  flattenServicesData,
  getCountryName,
  isValidIp,
  parseBehaviors,
  removeUrnPrefix,
} from 'utils';
import { ComponentProps, AssetType } from 'model';
import { gql } from '@apollo/client';

const assetQuery = gql`
  query IPAboutData($ipSet: [String]!) {
    ipData(ipSet: $ipSet) {
      id
      ip
      exchange {
        id
        name
      }
      geo {
        city
        countryDivision
        country
        anycast
        latLon
      }
      rdns
      routerId
      behaviors {
        names
        behavior
      }
      domainHostsPaged {
        totalCount
      }
      attributesServices {
        prefix 
        services {
          name
          region
          type
          tags
          publishedPrefix
        }
      } 
    }
  }
`;

export default function IPAbout({ params }: ComponentProps) {
  const { asset, loading } = useAssetQuery(AssetType.IP, params.assetValue, assetQuery);
  const { infoMode } = useContext(AssetInfoContext);
  const noMap = params.noMap;

  if (loading) {
    return <Spinner ratio={3} />;
  }

  if ((!asset && !loading) || !isValidIp(params.assetValue)) {
    return (
      <div className="ip-about">
        <EmptyState />
      </div>
    );
  }

  const aboutAttributes = [];
  const tags = [];
  asset.routerId && tags.push('Router Interface');
  const { behaviors, vpnNames, proxyNames } = parseBehaviors(asset?.behaviors);
  tags.push(...behaviors);
  const { serviceTags, serviceRegions, serviceTypes, serviceNames } = flattenServicesData(
    asset?.attributesServices?.services,
  );
  tags.push(...serviceTypes);

  params.includeIp &&
    aboutAttributes.push({
      label: 'IP Address',
      value: <AssetLink value={params.assetValue} />,
    });
  if (asset.geo) {
    if (params.splitGeo) {
      asset.geo.country &&
        aboutAttributes.push({
          label: 'Country',
          value: <AssetLink value={getCountryName(asset.geo.country)} />,
        });
      asset.geo.city &&
        aboutAttributes.push({
          label: 'City',
          value: asset.geo.city,
        });
    } else {
      aboutAttributes.push({
        label: 'Geolocation',
        value: <AssetGeo asset={asset} />,
      });
    }
  }
  if (params.includeReputation && asset.attributesMaltrail) {
    const reputation = asset?.attributesMaltrail;
    reputation.maltrailBehaviors &&
      aboutAttributes.push({
        label: 'Reputation',
        value: reputation.maltrailBehaviors,
      });
  }
  asset.exchange &&
    aboutAttributes.push({
      label: 'Exchange',
      value: <AssetLink linkValue={asset.exchange.id} value={asset.exchange.name} />,
    });
  asset.rdns &&
    aboutAttributes.push({
      label: 'rdns',
      value: asset.rdns,
    });
  asset.routerId &&
    aboutAttributes.push({
      label: 'router id',
      value: (
        <Link to={`/assetreport?router=${asset.routerId}`}>{removeUrnPrefix(asset.routerId)}</Link>
      ),
    });
  tags.length > 0 &&
    aboutAttributes.push({
      label: 'observed behaviors',
      value: tags.join(', '),
    });
  asset?.domainHostsPaged?.totalCount > 0 &&
    aboutAttributes.push({
      label: 'hostnames',
      value: asset?.domainHostsPaged?.totalCount,
    });
  vpnNames?.length > 0 &&
    aboutAttributes.push({
      label: vpnNames.length > 1 ? 'vpns' : 'vpn',
      value: vpnNames.join(', '),
    });
  proxyNames?.length > 0 &&
    aboutAttributes.push({
      label: proxyNames.length > 1 ? 'proxies' : 'proxy',
      value: proxyNames.join(', '),
    });
  serviceNames?.length > 0 &&
    aboutAttributes.push({
      label: 'Services',
      value: serviceNames.join(', '),
    });
  serviceRegions?.length > 0 &&
    aboutAttributes.push({
      label: 'Service Regions',
      value: serviceRegions.join(', '),
    });
  serviceTags?.length > 0 &&
    aboutAttributes.push({
      label: 'Service Tags',
      value: <TagContainer tags={serviceTags} />,
    });

  if (aboutAttributes.length === 0) {
    return (
      <div className="ip-about">
        <EmptyState />
      </div>
    );
  }

  return (
    <div className="ip-about">
      <AttributeTable attributes={aboutAttributes} />
      {!noMap && <GeoMap geo={asset.geo} small={infoMode} />}
    </div>
  );
}
