import React from 'react';
import AssetLink from './AssetLink';
import { formatGeoLabel } from 'utils';

type AssetGeoProps = {
  asset: Record<string, any>;
};

export default function AssetGeo({ asset }: AssetGeoProps) {
  // const confidenceLevel = asset.geo?.source;
  const formattedLabel = formatGeoLabel(asset.geo);
  return asset.geo?.anycast ? (
    <span>Anycast</span>
  ) : (
    <span>
      {formattedLabel ? (
        <AssetLink value={formattedLabel} linkValue={asset.geo.country} />
      ) : (
        'unknown'
      )}
    </span>
  );
}
