import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  AdvancedQuery,
  advancedQueryPageVar,
  UserInvestigation,
  UserInvestigationSection,
} from 'model';
import {
  getDefaultAdvancedQuery,
  getViewOption,
  toInvestigationSectionId,
  updateParamsFromSearchParams,
  updateQueryResultsUrl,
} from 'utils';
import Investigation from '../investigations/Investigation';
import { ComponentPluginName } from 'components';
import { useReactiveVar } from '@apollo/client';

export function QueryResults({ history }: RouteComponentProps) {
  const searchPageParams = useReactiveVar(advancedQueryPageVar);
  const searchPageQuery: AdvancedQuery =
    searchPageParams?.advancedQuery ?? getDefaultAdvancedQuery();

  useEffect(() => {
    if (!advancedQueryPageVar()) {
      updateParamsFromSearchParams();
    }
    updateQueryResultsUrl(history);
  }, [history]);

  const investigation = useMemo(() => {
    const newInvestigation: UserInvestigation = {
      description: '',
      subtitle:
        'Find internet assets by defining an input, then refine results using filters and transforms.' +
        ' Results can be visualized through views or downloaded as data. Save searches as Mosaics and then convert' +
        ' them into templates to repeat automated tasks. Share searches by creating shareable links.',
      title: '',
      sections: [],
    };
    const newSection: UserInvestigationSection = {
      description: '',
      id: toInvestigationSectionId(
        getViewOption(searchPageQuery)?.componentPluginName ??
          ComponentPluginName.QueryResultsDataTableView,
        { advancedQuery: searchPageQuery, config: { ...searchPageParams?.config } },
      ),
      title: 'Search',
    };
    newInvestigation.sections.push(newSection);
    return newInvestigation;
  }, [searchPageParams, searchPageQuery]);
  return searchPageParams ? <Investigation investigation={investigation} /> : null; // TODO: maybe put undefined page here in the future
}
