import React, { forwardRef, Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { useFlexLayout, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { ReactComponent as AscIcon } from 'svg/direction/arrow-north.svg';
import { ReactComponent as DescIcon } from 'svg/direction/arrow-south.svg';
import { ReactComponent as ChevronRightIcon } from 'svg/direction/chevron-east.svg';
import { ReactComponent as ChevronDoubleRightIcon } from 'svg/direction/last.svg';
import { ReactComponent as ChevronLeftIcon } from 'svg/direction/chevron-west.svg';
import { ReactComponent as ChevronDoubleLeftIcon } from 'svg/direction/first.svg';
import { Modal, Spinner } from 'components';
import { APP_CONTENT_WIDTH, getSearchParams } from 'utils';
import { ManagerTypes } from 'model';

type TableProps = {
  data: any;
  columns: any;
  sortBy?: any;
  getRowProps?: any;
  isDisabled?: Function;
  onRowClick?: any;
  onDelete?: any;
  onDeleteAll?: Function;
  toDeleteText?: string;
  loading?: boolean;
  hasSelectableRows?: boolean;
  forcePageReset?: any;
  setForcePageReset?: any;
  hiddenColumns?: string[];
  minWidth?: number;
  pageNumber?: number;
  onUpdatePageURL?: Function;
};

const defaultPropGetter = () => ({});

export function ManagerTable({
  data,
  columns,
  sortBy,
  getRowProps = defaultPropGetter,
  isDisabled,
  onRowClick,
  onDelete,
  onDeleteAll,
  toDeleteText,
  loading,
  hasSelectableRows,
  forcePageReset,
  setForcePageReset,
  hiddenColumns,
  minWidth,
  pageNumber,
  onUpdatePageURL,
}: TableProps) {
  const initialState = useMemo(() => {
    return { sortBy, hiddenColumns: hiddenColumns || [] };
  }, [sortBy, hiddenColumns]);
  // Remove reset flag
  useEffect(() => {
    if (forcePageReset) {
      setForcePageReset && setForcePageReset(false);
    }
  }, [forcePageReset, setForcePageReset]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setHiddenColumns,
    // Pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // Selection
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState,
      disableSortRemove: true,
      // Only reset pages and selected rows when a filter updates
      autoResetPage: forcePageReset != null ? forcePageReset : false,
      autoResetSortBy: false,
      autoResetSelectedRows: forcePageReset != null ? forcePageReset : false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hasSelectableRows &&
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            minWidth: 32,
            width: 32,
            maxWidth: 32,
            disableRowClick: true,
            Header: ({
              getToggleAllPageRowsSelectedProps,
              toggleRowSelected,
              isAllPageRowsSelected,
              page,
            }) => {
              const modifiedOnChange = (event: any) => {
                page.forEach((row) => {
                  // check each row if it is not disabled
                  !isDisabled(row) && toggleRowSelected(row.id, event.currentTarget.checked);
                });
              };

              // Count number of selectable and selected rows in the current page to determine the state of select all checkbox
              let selectableRowsInCurrentPage = 0;
              let selectedRowsInCurrentPage = 0;
              page.forEach((row) => {
                row.isSelected && selectedRowsInCurrentPage++;
                !isDisabled(row) && selectableRowsInCurrentPage++;
              });

              // If there are no selectable rows in the current page select all checkbox will be disabled
              const disabled = selectableRowsInCurrentPage === 0;
              const checked =
                (isAllPageRowsSelected ||
                  selectableRowsInCurrentPage === selectedRowsInCurrentPage) &&
                !disabled;

              return (
                <div>
                  <IndeterminateCheckbox
                    {...getToggleAllPageRowsSelectedProps()}
                    onChange={modifiedOnChange}
                    checked={checked}
                    disabled={disabled}
                  />
                </div>
              );
            },
            Cell: ({ row }) => {
              return (
                <div>
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    disabled={isDisabled(row)}
                  />
                </div>
              );
            },
          },
          ...columns,
        ]);
    },
  );

  // Update hidden columns
  useEffect(() => {
    setHiddenColumns(hiddenColumns || []);
  }, [hiddenColumns, setHiddenColumns]);

  // Set the initial page number
  useEffect(() => {
    if (pageNumber != null && !loading) {
      gotoPage(pageNumber > pageCount ? pageCount - 1 : pageNumber - 1);
    }
  }, [pageNumber, gotoPage, loading, pageCount]);

  const deleteAllCallback = useCallback(() => {
    if (onDelete != null) {
      // Only delete items that are visible with search filter
      const toDeleteCount = selectedFlatRows.length;
      Modal.show({
        title: `Delete ${toDeleteCount} ${toDeleteText ?? 'Items'}?`,
        body: (
          <span>
            {`Are you sure you want to delete ${toDeleteCount} ${toDeleteText ?? 'Items'} from your account?`}
          </span>
        ),
        action: {
          label: 'Delete',
          onClick: () => {
            selectedFlatRows.forEach((row: any) => {
              onDelete(row.values.id, true);
            });
            toggleAllRowsSelected(false);
          },
          cancelable: true,
        },
      });
    }
  }, [onDelete, selectedFlatRows, toDeleteText, toggleAllRowsSelected]);

  return (
    <Fragment>
      <div
        className="uk-flex uk-flex-column"
        style={{ minWidth: `${minWidth}px` ?? `${APP_CONTENT_WIDTH}px` }}
      >
        <table {...getTableProps()} className="manager-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  return (
                    <th
                      {...column.getHeaderProps(
                        headerProps({ ...column.getSortByToggleProps() }, { column }),
                      )}
                    >
                      {column?.align === 'right' &&
                        (column.isSorted ? column.isSortedDesc ? <DescIcon /> : <AscIcon /> : '')}
                      {column.render('Header')}
                      {column?.align !== 'right' &&
                        (column.isSorted ? column.isSortedDesc ? <DescIcon /> : <AscIcon /> : '')}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps(getRowProps(row))}
                  style={{ ...row.getRowProps(getRowProps(row)).style, cursor: 'pointer' }}
                  onClick={() => onRowClick && onRowClick(row)}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        {...cell.getCellProps(cellProps)}
                        onClick={(event) => {
                          cell.column.disableRowClick && event.stopPropagation();
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {loading && (
          <div className="uk-padding-medium uk-text-center">
            <Spinner ratio={3} />
          </div>
        )}
        {!loading && page.length === 0 && (
          <div className="uk-padding-medium uk-text-center">There are no records to display</div>
        )}
        {pageOptions.length > 0 && (
          <div className="pagination">
            <span>
              {pageIndex + 1} of {Math.max(pageOptions.length, pageIndex + 1)} Pages
            </span>
            <button
              onClick={() => {
                gotoPage(0);
                toggleAllRowsSelected(false);
                let urlSearchParams = handlePaginationURL();
                urlSearchParams.set('page', '1');
                onUpdatePageURL && onUpdatePageURL(urlSearchParams.toString());
              }}
              disabled={!canPreviousPage}
            >
              <ChevronDoubleLeftIcon />
            </button>
            <button
              onClick={() => {
                previousPage();
                toggleAllRowsSelected(false);
                toggleAllRowsSelected(false);
                let urlSearchParams = handlePaginationURL();
                urlSearchParams.set('page', pageIndex.toString());
                onUpdatePageURL && onUpdatePageURL(urlSearchParams.toString());
              }}
              disabled={!canPreviousPage}
            >
              <ChevronLeftIcon />
            </button>
            <button
              onClick={() => {
                nextPage();
                toggleAllRowsSelected(false);
                let urlSearchParams = handlePaginationURL();
                urlSearchParams.set('page', (pageIndex + 2).toString());
                onUpdatePageURL && onUpdatePageURL(urlSearchParams.toString());
              }}
              disabled={!canNextPage}
            >
              <ChevronRightIcon />
            </button>
            <button
              onClick={() => {
                gotoPage(pageCount - 1);
                toggleAllRowsSelected(false);
                let urlSearchParams = handlePaginationURL();
                urlSearchParams.set('page', pageCount.toString());
                onUpdatePageURL && onUpdatePageURL(urlSearchParams.toString());
              }}
              disabled={!canNextPage}
            >
              <ChevronDoubleRightIcon />
            </button>
          </div>
        )}
      </div>
      {selectedFlatRows.length > 0 ? (
        <div className="uk-margin-medium">
          <button
            type="button"
            className={`uk-button uk-modal-close uk-align-right uk-button-default`}
            onClick={() => {
              if (onDeleteAll) {
                toggleAllRowsSelected(false);
                onDeleteAll(selectedFlatRows.map((row) => row.values.id));
              } else {
                deleteAllCallback();
              }
            }}
          >
            Delete
          </button>
        </div>
      ) : null}
    </Fragment>
  );
}

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <input
      type="checkbox"
      className="uk-checkbox"
      ref={resolvedRef}
      onClick={(event) => event.stopPropagation()}
      {...rest}
    />
  );
});

// Custom props for column alignment
const headerProps = (props: any, { column }: any) => getStyles(props, column.align);

const cellProps = (props: any, { cell }: any) => getStyles(props, cell.column.align);

function getStyles(props: any, align = 'left') {
  return [
    props,
    {
      style: {
        justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
        alignItems: 'flex-start',
        display: 'flex',
      },
    },
  ];
}

function handlePaginationURL() {
  const url = getSearchParams();
  if (
    !url.get('type') &&
    [ManagerTypes.Collections as string, ManagerTypes.Gallery as string].includes(
      window.location.pathname.substring(1),
    )
  ) {
    url.set('type', 'mosaics');
  }
  return url;
}
