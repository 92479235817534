import { ComponentPluginName, FilterComponent, TabbedContentOption } from 'components';
import { ViewConfigName } from 'components/viewconfig';

export const TabbedOptionsByPlugin: Record<string, TabbedContentOption[]> = {
  [ComponentPluginName.ASNRouting]: [
    { label: 'Geolocation', name: 'geolocation', content: undefined },
    { name: 'as-relationships', label: 'AS Relationships', content: undefined },
  ],
  [ComponentPluginName.CountrySummary]: [
    { label: 'Top Providers', name: 'providers', content: undefined },
    { name: 'border-providers', label: 'Border Providers', content: undefined },
    { name: 'border-countries', label: 'Border Countries', content: undefined },
  ],
};

export function getActiveTab(
  options: TabbedContentOption[],
  pluginConfig: Record<string, any>,
  configUpdateDisabled: boolean | undefined,
) {
  if (options.length === 0) return undefined;
  if (!configUpdateDisabled && pluginConfig.tab) {
    const o = options.find((o: TabbedContentOption) => o.name === pluginConfig.tab);
    return o?.name ?? options[0]?.name;
  } else {
    return options[0]?.name;
  }
}

export const configComponentNamesByPlugin: Record<string, string> = {
  [ComponentPluginName.ASNDetailsTable]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.ASNRouting]: ViewConfigName.TabbedContentConfig,
  [ComponentPluginName.DomainDNSRecords]: ViewConfigName.HistoryConfig,
  [ComponentPluginName.DomainRegistry]: ViewConfigName.HistoryConfig,
  [ComponentPluginName.RouterIPInterfaces]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.HostnameResolution]: ViewConfigName.HistoryConfig,
  [ComponentPluginName.FacilityConnections]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.CountryDetailsTable]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.PrefixReputation]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.OrgDetailsTable]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.IPDomainHosts]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.IPInfrastructure]: ViewConfigName.HistoryConfig,
  [ComponentPluginName.DomainCerts]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.DomainDNSHosts]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.ExchangeConnections]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.PrefixTracerouteEdges]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.IPTracerouteEdges]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.IPPorts]: ViewConfigName.PortsConfig,
  [ComponentPluginName.HostnameCerts]: ViewConfigName.TableColumnConfig,
  [ComponentPluginName.CountrySummary]: ViewConfigName.TabbedContentConfig,
  [ComponentPluginName.QueryResultsHistogramView]: ViewConfigName.HideUnselected,
  [ComponentPluginName.QueryResultsCardsView]: ViewConfigName.HideUnselected,
};

export const filterComponentNamesByPlugin: Record<string, string> = {
  [ComponentPluginName.IPPorts]: FilterComponent.PortsFilters,
};

export const linkableComponentPlugins: ComponentPluginName[] = [
  ComponentPluginName.QueryResultsIPPatternDiscoveryView,
  ComponentPluginName.QueryResultsHistogramView,
  ComponentPluginName.QueryResultsCardsView,
];
