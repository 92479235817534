import { find, isEqual, sortBy } from 'lodash';
import { humanizeDateTime } from 'utils';

export type DataAttributeValue = {
  value: string;
  ignoreDiff?: boolean;
};

export type DataAttribute = {
  label: string;
  value: string[] | DataAttributeValue[] | DataAttribute[];
  index?: number;
};

export type DataAttributeDiff = {
  label: string;
  value: string | DataAttributeDiff[];
  diff: boolean;
};

export function sortAndPruneDataHistory(dataHistory: any[]) {
  const seen = new Set();
  return sortBy(dataHistory, 'firstSeenTime').filter((d: any) => {
    if (seen.has(d.firstSeenTime)) {
      return false;
    } else {
      seen.add(d.firstSeenTime);
      return true;
    }
  });
}

export function buildDataAttributeDiffs(a: DataAttribute[], b: DataAttribute[]) {
  function isSubAttributes(a: any[], b: any[]) {
    return a[0]?.label || b[0]?.label;
  }
  const aDiffs: DataAttributeDiff[] = [];
  const bDiffs: DataAttributeDiff[] = [];
  getOrderedLabels(a, b).forEach((label) => {
    const aAttr =
      find(a, (item) => item.label === label) || ({ label, value: [] } as DataAttribute);
    const bAttr =
      find(b, (item) => item.label === label) || ({ label, value: [] } as DataAttribute);
    if (isSubAttributes(aAttr.value, bAttr.value)) {
      const [aSubDiffs, bSubDiffs] = buildDataAttributeDiffs(
        aAttr.value as DataAttribute[],
        bAttr.value as DataAttribute[],
      );
      aDiffs.push({ label: aAttr.label, value: aSubDiffs } as DataAttributeDiff);
      bDiffs.push({ label: bAttr.label, value: bSubDiffs } as DataAttributeDiff);
    } else {
      for (const [aRow, bRow] of compareAttrs(aAttr, bAttr)) {
        aDiffs.push(aRow);
        bDiffs.push(bRow);
      }
    }
  });

  return [aDiffs, bDiffs];
}

function getOrderedLabels(a: DataAttribute[], b: DataAttribute[]) {
  let labelMap: Record<string, any> = {};
  function addToLabelMap(label: string, index: number) {
    const obj = labelMap[label];
    if (!obj) {
      labelMap[label] = { label, index };
    } else {
      obj.index = Math.max(obj.index, index);
    }
  }
  a?.forEach((item, i) => addToLabelMap(item.label, item.index ?? i));
  b?.forEach((item, i) => addToLabelMap(item.label, item.index ?? i));
  return sortBy(Object.values(labelMap), 'index').map((obj) => obj.label);
}

function* compareAttrs(
  aAttr: DataAttribute,
  bAttr: DataAttribute,
): Generator<[DataAttributeDiff, DataAttributeDiff]> {
  function toRow(i: number, attr: DataAttribute, value: DataAttributeValue, diff: boolean) {
    return {
      ...attr,
      diff,
      label: i === 0 ? attr.label : '',
      value: value.value || '',
    } as DataAttributeDiff;
  }
  const aValue = Array.isArray(aAttr.value) ? aAttr.value : [aAttr.value];
  const bValue = Array.isArray(bAttr.value) ? bAttr.value : [bAttr.value];
  for (let i = 0; i < Math.max(aValue.length, bValue.length); i++) {
    let aVal = toDataAttributeValue(aValue[i]);
    let bVal = toDataAttributeValue(bValue[i]);
    const diff = !aVal.ignoreDiff && !isEqual(aVal.value, bVal.value);
    yield [toRow(i, aAttr, aVal, diff), toRow(i, bAttr, bVal, diff)];
  }
}

function toDataAttributeValue(value: any): DataAttributeValue {
  if (value?.value) {
    return value;
  } else {
    return { value };
  }
}

export function formatDate(date: string) {
  if (Date.parse(date)) {
    return humanizeDateTime(new Date(date));
  }
  return date;
}
