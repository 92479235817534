import React, { useContext, useMemo } from 'react';
import { EmptyState, FlexAttributes, Spinner, getWrappedPercentage } from 'components';
import { ComponentPluginContext } from 'appContexts';
import { buildDataAttributeDiffs, DataAttribute, DataAttributeDiff, formatDate } from 'utils';
import { ReactComponent as DiffLeftIcon } from 'svg/system/diff-left.svg';
import { ReactComponent as DiffRightIcon } from 'svg/system/diff-right.svg';
import useResizeObserver from 'use-resize-observer';

type AssetAttributesHistoryProps = {
  aAttrs: DataAttribute[];
  bAttrs: DataAttribute[];
  historyLoading: boolean;
  historyLabels?: string[];
};

export function AssetAttributesHistory({
  aAttrs,
  bAttrs,
  historyLoading,
  historyLabels,
}: AssetAttributesHistoryProps) {
  const { getPluginConfig, isPrinterFriendly } = useContext(ComponentPluginContext);
  const { dateA, dateB } = getPluginConfig()?.history || {};

  // If both dates have no data create mocked empty fields
  const emptyDiff: DataAttributeDiff[] = useMemo(() => {
    return (historyLabels || [])?.map((label: string) => {
      return { label, value: '', diff: false };
    });
  }, [historyLabels]);

  const { ref, width } = useResizeObserver<HTMLDivElement>();

  const [aComparedAttrs, bComparedAttrs] = useMemo(() => {
    return buildDataAttributeDiffs(aAttrs, bAttrs);
  }, [aAttrs, bAttrs]);

  const shouldWrap = useMemo(() => {
    if (isPrinterFriendly) return false;
    const aWrappedPercentage = getWrappedPercentage(aComparedAttrs, width);
    const bWrappedPercentage = getWrappedPercentage(bComparedAttrs, width);

    return aWrappedPercentage > 0.1 || bWrappedPercentage > 0.1;
  }, [aComparedAttrs, bComparedAttrs, width, isPrinterFriendly]);

  if (historyLoading) {
    return <Spinner ratio={3} />;
  }

  if (!dateA && !dateB) {
    return <EmptyState />;
  }

  const emptyComparison = aComparedAttrs.length === 0 && bComparedAttrs.length === 0;

  return (
    <div
      className={`app-history-comparer ${shouldWrap ? 'app-history-comparer-wrap' : ''}`}
      ref={ref}
    >
      <div className="before-section">
        <div className="app-history-date app-comparer-date">
          <DiffLeftIcon />
          {formatDate(dateA)}
        </div>
        <FlexAttributes attributes={emptyComparison ? emptyDiff : aComparedAttrs} />
      </div>
      <div className="after-section">
        <div className="app-history-date app-reference-date">
          <DiffRightIcon />
          {formatDate(dateB)}
        </div>
        <FlexAttributes attributes={emptyComparison ? emptyDiff : bComparedAttrs} />
      </div>
    </div>
  );
}
