import { capitalize, flatten, groupBy, sortBy, uniq } from 'lodash';
import psl from './psl';
import hash from 'object-hash';
import punycode from 'punycode/punycode.es6';

export function isValidDomain(value: string) {
  return psl.isValid(value);
}

export function isHostname(value: string) {
  if (isValidHostname(value)) {
    let wildCardVal = value.startsWith('*.') ? value.replace('*.', 'test.') : value;
    const parsed: any = psl.parse(wildCardVal);
    return parsed.subdomain !== null;
  }
}

export function isValidHostname(value: string) {
  // allow special wildcard formatting present in our data
  return (
    isValidDomain(value) || (value.startsWith('*.') && isValidDomain(value.replace('*.', 'test.')))
  );
}

export function getDomain(hostname: string) {
  const parsed: any = psl.parse(hostname);
  return parsed.domain;
}

export type GroupedAddresses = Record<string, any>;
export type GroupedAddressesList = GroupedAddresses[];

function sortAddresses(items: string[]) {
  const ipv4 = items.filter((item) => item?.includes('.'));
  const ipv6 = items.filter((item) => item?.includes(':'));
  return [...sortBy(ipv4), ...sortBy(ipv6)];
}

export function getGroupedAddresses(answers: any[]): GroupedAddressesList {
  const addresses = flatten(answers?.map((answer: any) => answer.addresses));
  if (addresses.length === 0) {
    return [];
  }
  const grouped = groupBy(
    addresses,
    (address) =>
      (address?.cdn && `${capitalize(address?.cdn)} (CDN)`) ||
      address?.prefix?.orgName ||
      address?.ip.orgName,
  );
  return Object.entries(grouped).map(([key, groupedAddresses]) => {
    const org = key;
    const countries = uniq(
      groupedAddresses?.map((address) => (address?.anycast ? 'Anycast' : address?.country)),
    ).sort() as string[];
    const countryAddresses: Record<string, string[]> = {};
    countries?.forEach((country: string) => (countryAddresses[country] = []));
    groupedAddresses.forEach((address) =>
      countryAddresses[address?.anycast ? 'Anycast' : address?.country].push(
        address?.ip?.ip || address?.prefix?.prefix,
      ),
    );
    const addresses = sortAddresses(
      uniq(
        groupedAddresses.map((address) => address?.ip?.ip || address?.prefix?.prefix),
      ) as string[],
    );
    return { org, countries, addresses, countryAddresses };
  });
}

export function getHostGroups(hosts: any[]): Record<string, any>[] {
  const domain = hosts.length > 0 && hosts[0]?.domain;
  const hostRecords: any[] = hosts.map((host) => {
    const groupedAddresses = getGroupedAddresses(host.answers);
    const cnames: string[] = [];
    host.answers.forEach((answer: any) => {
      answer.cnames?.forEach((cname: any) => cnames.push(punycode.toUnicode(cname.host)));
    });
    return {
      domain,
      hostHeader: host.host,
      hostLabel: host.host,
      cnames,
      groupedAddresses,
    };
  });

  const groups = groupBy(hostRecords, (record) => hash([record.groupedAddresses]));
  const hostGroups = Object.values(groups).map((group) => {
    group.sort((a, b) => {
      if (a.hostName === domain) {
        return -1;
      } else if (b.hostName === domain) {
        return 1;
      }
      return a.hostName < b.hostName ? -1 : 1;
    });

    const addresses = uniq(
      flatten(
        group.map(
          (record) => flatten(record.groupedAddresses.map((group: any) => group.addresses)) || [],
        ),
      ),
    );

    return {
      ...group[0],
      cnames: uniq(flatten(group.map((record) => record.cnames || []))),
      hostLabels: group.map((item) => punycode.toUnicode(item.hostLabel)),
      orgs: uniq(
        flatten(
          group.map(
            (record) => flatten(record.groupedAddresses.map((group: any) => group.org)) || [],
          ),
        ),
      ),
      countries: uniq(
        flatten(
          group.map(
            (record) => flatten(record.groupedAddresses.map((group: any) => group.countries)) || [],
          ),
        ),
      ),
      addresses,
      addressCount: addresses.length,
    };
  });

  hostGroups.sort((a, _) => (a.hostLabels[0] === domain ? -1 : 0));
  return hostGroups;
}

export function getHostGroupsRedux(hosts: any[]): Record<string, any>[] {
  const hostRecords: any[] = hosts.map((host) => {
    const cnames: string[] = [];
    const orgs: string[] = [];
    const countries: string[] = [];
    host.answers.forEach((answer: any) => {
      answer.cnames?.forEach((cname: any) => cnames.push(punycode.toUnicode(cname.host)));
      answer.addresses?.forEach((address: any) => {
        const { prefix, ip, anycast, country } = address;
        prefix?.orgName && orgs.push(prefix?.orgName);
        ip?.orgName && orgs.push(ip?.orgName);
        anycast ? countries.push('Anycast') : countries.push(country);
      });
    });
    return {
      host: host.host,
      hostTypes: host.hostTypes,
      cnames: uniq(cnames).sort(),
      orgs: uniq(orgs).sort(),
      countries: uniq(countries).sort(),
    };
  });

  return hostRecords.sort((a: any, b: any) => b.host - a.host);
}

export function normalizeDomain(domain: string) {
  domain = domain.trim();
  if (domain.endsWith('.')) {
    domain = domain.slice(0, -1);
  }
  return punycode.toASCII(domain);
}

export function normalizeDomainLabel(domain: string) {
  return punycode.toUnicode(domain);
}

// Parse domain from potentially wildcarded hostname
export function parseHostname(hostname: string) {
  const parsed: any = psl.parse(hostname.replace('*.', ''));
  const domain = parsed.domain;
  return domain;
}
