import {
  formatTimeIntervalString,
  transformRelativeTsToAbsolute,
  transformToRelativeTimestamp,
  NewTableColumn,
  formatTimeStringFromUnit,
  getColumnIdentifier,
} from 'utils';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { QueryFilterClause, QueryFilterComparator } from 'model';
import { drawHistogramFilter } from 'components';
import { get } from 'lodash';
import { v4 } from 'uuid';

export function HistogramFilter({
  column,
  aggregation,
  onAddFilter,
  localFilters,
}: {
  column: NewTableColumn;
  aggregation: any;
  onAddFilter: any;
  localFilters?: any;
}) {
  type HistogramBucket = {
    x0: number;
    x1: number;
    length: number;
  };

  const aggAccessor = column?.aggAccessor ?? 'data.pcap.aggregation.timestamp';
  const interval = get(aggregation, `${aggAccessor}.interval`);
  // TODO: Refactor timestamp formatting functions
  const formattedInterval =
    interval && !column?.aggAccessor ? formatTimeIntervalString(interval) : interval ?? 1;
  const buckets = get(aggregation, `${aggAccessor}.buckets`);
  let referenceVal = buckets ? buckets[0]?.key : 0;

  const parseClause = useCallback(
    (clause: QueryFilterClause) => {
      let value: any = clause.value;
      if (Array.isArray(value)) value = value[0];
      if (clause.type === 'PcapData:Time') {
        let normalizedVal;
        if (typeof value === 'number') normalizedVal = value;
        if (typeof value === 'string') {
          if (!isNaN(+value)) {
            normalizedVal = +value;
          } else {
            if (!value.endsWith('Z')) normalizedVal = value.concat('Z');
            normalizedVal = new Date(value).getTime();
          }
        }
        if (normalizedVal) return (normalizedVal - referenceVal) / 1000;
      }
      return value;
    },
    [referenceVal],
  );

  const initBound = useCallback(
    (isUpper: boolean) => {
      const clauses = column.columnFilterClauses;
      let bound = undefined;
      if (localFilters) {
        bound = localFilters[isUpper ? 1 : 0];
      } else {
        clauses?.forEach((clause: any) => {
          let value = parseClause(clause);
          if (
            clause.comparator ===
            (isUpper ? QueryFilterComparator.IsBefore : QueryFilterComparator.IsAfter)
          ) {
            bound = value;
          }
        });
      }
      return bound;
    },
    [column.columnFilterClauses, parseClause, localFilters],
  );

  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [upperBound, setUpperBoundState] = useState<number | undefined>(() => initBound(true));
  const [lowerBound, setLowerBoundState] = useState<number | undefined>(() => initBound(false));
  const tempUpperBoundRef = useRef<number | undefined>(initBound(true));
  const tempLowerBoundRef = useRef<number | undefined>(initBound(false));
  const [id] = useState<string>(() => v4());
  const formattedBuckets = useMemo(() => {
    let retVal: HistogramBucket[] = [];
    buckets?.forEach((bucket: any, i: number) => {
      let x1;
      if (column.aggAccessor) {
        if (buckets.length <= i + 1) {
          x1 = Math.ceil(+(bucket.key + formattedInterval).toFixed(2));
        } else {
          x1 = buckets[i + 1].key;
        }
        retVal.push({ x0: bucket.key, x1, length: bucket.docCount });
      } else {
        if (buckets.length <= i + 1) {
          x1 = transformToRelativeTimestamp(bucket.key, referenceVal) + formattedInterval;
        } else {
          x1 = transformToRelativeTimestamp(buckets[i + 1].key, referenceVal);
        }
        retVal.push({
          x0: transformToRelativeTimestamp(bucket.key, referenceVal),
          x1,
          length: bucket.docCount,
        });
      }
    });
    return retVal;
  }, [formattedInterval, referenceVal, buckets, column.aggAccessor]);

  const histogramRef = useRef<HTMLDivElement | null>(null);
  const smallHistoRef = useRef<HTMLDivElement | null>(null);
  const histoWidth = 300;
  const histoHeight = 230;
  const smallWidth = +(column?.width ?? 133) * 0.75 ?? 100;
  const smallHeight = 120;

  const titleRef = useRef<HTMLSpanElement | null>();
  const tableRef = useRef<HTMLDivElement | null>();
  const [titleLength, setTitleLength] = useState<number | undefined>();
  const [titleX, setTitleX] = useState<number | undefined>();
  const [tableWidth, setTableWidth] = useState<number | undefined>();
  const menuRef = useRef<HTMLDivElement>(null);

  // Calculate widths for expand button placement and tooltip placement
  useEffect(() => {
    titleRef.current = smallHistoRef.current
      ?.closest('#table-header')
      ?.querySelector(`#column-${getColumnIdentifier(column).toLowerCase()}`)
      ?.querySelector('span');
    if (titleRef.current) {
      setTitleLength(titleRef.current?.getBoundingClientRect()?.width);
      setTitleX(titleRef.current?.getBoundingClientRect()?.x);
    }
    tableRef.current = smallHistoRef.current?.closest('.marginal-table');
    if (tableRef.current) {
      setTableWidth(tableRef.current?.getBoundingClientRect()?.width);
    }
  }, [smallHistoRef, column]);

  // Close menu on click outside
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setExpanded(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Update bounds when filter clause changes, if necessary
  useEffect(() => {
    let clauseUpper = initBound(true);
    let clauseLower = initBound(false);
    if (clauseUpper && clauseUpper !== upperBound) {
      setUpperBoundState(clauseUpper);
    }
    if (clauseLower && clauseLower !== lowerBound) {
      setLowerBoundState(clauseLower);
    }
  }, [initBound, lowerBound, upperBound]);

  // Draw the histogram
  useLayoutEffect(() => {
    function setUpperBound(newBound: any) {
      if (newBound !== upperBound) {
        setUpperBoundState(newBound);
        if (column.localFilter) {
          onAddFilter && onAddFilter(newBound, undefined, column, true);
        } else {
          const newFilterClause = {
            comparator: QueryFilterComparator.IsBefore,
            type: column.clauseType,
            value:
              newBound != null ? transformRelativeTsToAbsolute(newBound, referenceVal) : undefined,
          };
          onAddFilter && onAddFilter(column.clauseType, newFilterClause);
        }
      }
    }

    function setLowerBound(newBound: any) {
      if (newBound !== lowerBound) {
        setLowerBoundState(newBound);
        if (column.localFilter) {
          onAddFilter && onAddFilter(newBound, undefined, column, false);
        } else {
          const newFilterClause = {
            comparator: QueryFilterComparator.IsAfter,
            type: column.clauseType,
            value:
              newBound != null ? transformRelativeTsToAbsolute(newBound, referenceVal) : undefined,
          };
          onAddFilter && onAddFilter(column.clauseType, newFilterClause);
        }
      }
    }

    if ((!smallHistoRef.current && !histogramRef.current) || !formattedBuckets?.length) return;

    // TODO: Refactor timestamp formatting functions
    const intervalFormatFunction = column.localFilter ? undefined : formatTimeStringFromUnit;

    if (isExpanded && histogramRef.current) {
      drawHistogramFilter(
        histogramRef.current,
        formattedBuckets,
        interval,
        histoWidth,
        histoHeight,
        { left: 50, top: 40 },
        {
          upperBound,
          lowerBound,
          setUpperBound,
          setLowerBound,
          tempUpperBoundRef,
          tempLowerBoundRef,
        },
        intervalFormatFunction,
        id,
      );
    } else if (!isExpanded && smallHistoRef.current) {
      drawHistogramFilter(
        smallHistoRef.current,
        formattedBuckets,
        interval,
        smallWidth,
        smallHeight,
        { left: 20, top: 5 },
        {
          upperBound,
          lowerBound,
          setUpperBound,
          setLowerBound,
          tempUpperBoundRef,
          tempLowerBoundRef,
        },
        intervalFormatFunction,
        id,
      );
    }
  }, [
    onAddFilter,
    lowerBound,
    upperBound,
    formattedBuckets,
    isExpanded,
    column,
    referenceVal,
    interval,
    smallWidth,
    id,
  ]);
  const distToRight = (tableWidth ?? 0) - (titleX ?? 0);
  const cardWidth = histoWidth + 130;
  return (
    <Fragment>
      {!isExpanded && <div ref={smallHistoRef} />}
      <button
        className="uk-position-absolute expand-histo-button"
        style={{ top: '-26px', left: `${(titleLength ?? 0) + 4}px`, zIndex: 500 }}
        onClick={() => {
          setExpanded((curr) => !curr);
        }}
      >
        {
          <div
            className="app-icon-neutral"
            data-uk-icon={`icon: ${isExpanded ? 'shrink' : 'expand'}; ratio: 0.75`}
          />
        }
      </button>
      {isExpanded && (
        <div
          ref={menuRef}
          className={`asset-tooltip ${distToRight < histoWidth ? 'histo-filter-menu-left' : 'histo-filter-menu-right'}`}
        >
          <div
            style={{ width: `${cardWidth}px`, height: `${histoHeight + 100}px`, padding: '16px' }}
            className="uk-card uk-card-default"
          >
            <div ref={histogramRef} />
          </div>
        </div>
      )}
    </Fragment>
  );
}
