export function isASN(value: string) {
  let retVal = /^([Aa][Ss])?(\s)?\d+$/.test(value);
  if (!retVal) return false;
  const num = parseInt(parseASN(value));
  return num <= MAX_ASN;
}

export function parseASN(value: string) {
  return value.replace(/^([Aa][Ss])?\s*/, '');
}

export const MAX_ASN = 4294967294;
