import React from 'react';

export function ErrorInfo({ error }: { error: Error }) {
  const info = (error as any).info;
  return (
    <div>
      <div className="uk-margin-top">
        {info && (
          <pre id="error-content" className="uk-margin-small-top" style={{ maxHeight: 300 }}>
            <code>{JSON.stringify(info, null, 2)}</code>
          </pre>
        )}
      </div>
    </div>
  );
}
