import { useState, useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { getConfigAdvancedQuery } from 'utils';
import { useLazyComponentPluginQuery } from './gql';
import { ApolloQueryResult } from '@apollo/client';
import { AdvancedQuery } from 'model';

export function useAdvancedQuery(
  params: any,
): [AdvancedQuery, React.MutableRefObject<any>, ApolloQueryResult<any>] {
  const [advancedQuery, setAdvancedQuery] = useState(getConfigAdvancedQuery(params));
  const [runQuery, result] = useLazyComponentPluginQuery(advancedQuery);
  const runQueryRef = useRef(runQuery);

  useEffect(() => {
    runQueryRef.current = runQuery;
  }, [runQuery]);

  // Only run query when advanced query changes
  useEffect(() => {
    if (advancedQuery) {
      runQueryRef.current();
    }
  }, [advancedQuery]);

  useEffect(() => {
    const newAQ = getConfigAdvancedQuery(params);
    // don't update the query if it's just a viewconfig change
    setAdvancedQuery((current) => {
      if (!isEqual({ ...current, viewConfig: undefined }, { ...newAQ, viewConfig: undefined })) {
        return newAQ;
      }
      return current;
    });
  }, [params]);

  return [advancedQuery, runQueryRef, result];
}
