import { useState } from 'react';

export function useFilterToggle(defaultToggle?: boolean) {
  const [filtersToggled, setFiltersToggled] = useState(defaultToggle ?? true);

  return {
    filtersToggled,
    setFiltersToggled,
  };
}
