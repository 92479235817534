import { keycloak, setAuthCreds } from 'keycloak';
import { getHistory, navigate } from './navigationUtils';
import appEventEmitter from 'appEventEmitter';

export async function initializeEmbedded() {
  if (isEmbedded()) {
    await initEmbedded();
  } else {
    initParent();
  }
}

export function isEmbedded() {
  return window.frameElement !== null && window.parent.location.origin === window.location.origin;
}

export function getEmbeddedId() {
  return window.frameElement?.id;
}

export function setEmbeddedUrl(id: string, url: string) {
  const iframe = document.getElementById(id) as HTMLIFrameElement | null;
  iframe?.contentWindow?.postMessage({ name: 'navigate', id, url }, window.location.origin);
}

function initParent() {
  appEventEmitter.addListener('authorizationRefreshed', () => {
    const embeddedWindows = document.getElementsByClassName(
      'embedded-window',
    ) as HTMLCollectionOf<HTMLIFrameElement>;
    Array.from(embeddedWindows).forEach(sendAuthCreds);
  });

  window.addEventListener('message', (event) => {
    if (event.origin === window.location.origin) {
      if (event.data?.name === 'embeddedLoaded') {
        sendAuthCreds(event.data.id);
      }
    }
  });
}

function initEmbedded() {
  const promise = new Promise<void>((resolve) => {
    window.addEventListener('message', (event) => {
      if (event.origin === window.location.origin) {
        if (event.data?.name === 'authorization') {
          setAuthCreds(event.data.authCreds as Record<string, string>);
          resolve();
        } else if (event.data?.name === 'navigate') {
          handleNavigateMessage(event);
        }
      }
    });
  });
  window.parent.postMessage(
    { name: 'embeddedLoaded', id: getEmbeddedId() },
    window.location.origin,
  );
  return promise;
}

function handleNavigateMessage(event: MessageEvent) {
  const url = new URL(event.data.url, window.location.origin);
  function navigateWhenReady() {
    if (!getHistory()) {
      window.setTimeout(navigateWhenReady, 200);
    } else {
      navigate({ url });
    }
  }
  navigateWhenReady();
}

function sendAuthCreds(embedded: string | HTMLIFrameElement | null) {
  if (typeof embedded === 'string') {
    embedded = document.getElementById(embedded) as HTMLIFrameElement | null;
  }
  if (embedded?.contentWindow) {
    const authCreds = {
      token: keycloak.token,
      idToken: keycloak.idToken,
      refreshToken: keycloak.refreshToken,
    };
    embedded.contentWindow.postMessage(
      { name: 'authorization', authCreds },
      window.location.origin,
    );
  }
}
