import React from 'react';
import AssetLink from './AssetLink';
import { abbreviatedCount, formatGeoLabel } from 'utils';
import { TargetPrefix, EdgeIP, AssetLinkProps } from 'model';

export function CellPrefix({ prefix }: { prefix: TargetPrefix }) {
  return prefix.prefix ? (
    <CellBasic title={<AssetLink value={prefix.prefix} />} subtitle={formatGeoLabel(prefix.geo)} />
  ) : null;
}

export function CellBasicPrefix({ prefix }: { prefix: TargetPrefix }) {
  return prefix.prefix ? <CellBasic title={<AssetLink value={prefix.prefix} />} /> : null;
}

export function CellASN({ queryObj }: { queryObj: any }) {
  const asn = queryObj.asn?.hasOwnProperty('asn') ? queryObj.asn.asn : queryObj.asn;
  const orgName = queryObj.asn?.orgName ? queryObj.asn.orgName : queryObj.orgName;
  return asn ? (
    <CellBasic title={<AssetLink linkValue={asn} value={`AS${asn}`} />} subtitle={orgName} />
  ) : null;
}

export function CellIPOrRouter({ asset }: { asset: any }) {
  return asset.ip ? <CellIP ip={asset} /> : <CellRouter router={asset} />;
}

export function CellIP({ ip }: { ip: EdgeIP }) {
  const geo = formatGeoLabel(ip?.geo);
  return ip?.ip ? <CellBasic title={<AssetLink value={ip?.ip} />} subtitle={geo} /> : null;
}

// function getIPTooltipText(ip: EdgeIP) {
//   const geo = formatGeoLabel(ip?.geo);
//   const anycast = geo === 'Anycast';
//   const routingOrigin = ip?.routingOrigin?.origins[0];
//   let routingOriginName = '';
//   let routingOriginAs = null;
//   let routerId = ip?.routerId != null ? `${routingOrigin?.orgName != null ? ' and' : ' It'} is associated with router ${ip?.routerId.split(':')[2]}.` : "";
//   if (routingOrigin != null) {
//     if (routingOrigin?.orgName != null) {
//       routingOriginName = ` It has a routing origin of ${routingOrigin?.orgName}${routingOrigin?.asn ? '' : routerId ? '' : '.'}`;
//       routingOriginAs = `(AS${routingOrigin?.asn})${routerId ? '' : '.'}`;
//     }
//   }
//   const title = ip?.ip;
//   const description = `${ip?.ip} is an ${anycast ? 'anycast ' : ''}ip address${anycast ? '' : ` located in ${geo ==
//     null || geo === '' ? "an unknown country" : geo}`}. ${routingOriginName} ${routingOrigin?.asn ? routingOriginAs
//     : ''}${routerId}`
//   return { title, description, geo };
// }

export function cellRouterId(id: string) {
  // id = removeUrnPrefix(id);
  if (id?.includes('-')) {
    id = `${id.slice(0, 13)}...`;
  }
  return id;
}

export function CellRouter({ router }: { router: any }) {
  return (
    <CellBasic
      title={
        <AssetLink
          value={router.asn?.orgName ?? router.orgName ?? 'Ownership Pending'}
          linkValue={router.routerId}
        />
      }
      subtitle={`${router.geo?.country ? formatGeoLabel(router.geo) + ', ' : ''} \n
      ${cellRouterId(router.routerId)}`}
    />
  );
}

export function CellBasicRouter({ router }: { router: any }) {
  return (
    <CellBasic
      title={
        <AssetLink
          value={router.asn?.orgName ?? router.orgName ?? 'Ownership Pending'}
          linkValue={router.routerId}
        />
      }
      subtitle={cellRouterId(router.routerId)}
    />
  );
}

export function CellRouterVendor({ router }: { router: any }) {
  const { name, os, osVersion } = router?.vendor || {};
  return <CellBasic title={name} subtitle={os ? `${os}${osVersion ? ` ${osVersion}` : ''}` : ''} />;
}

export function CellEdgeStats({ edge }: { edge: any }) {
  return (
    <CellBasic
      title={abbreviatedCount(edge.edgeTpfxTraceCount)}
      subtitle={`${edge.countryCount} ${edge.countryCount > 1 ? 'countries' : 'country'}`}
    />
  );
}

export function CellBasic({
  title,
  subtitle,
  width,
}: { title: any; subtitle?: any; width?: number | string }) {
  const style = { width: width || 'none' };
  return (
    <ul className="uk-list uk-list-collapse uk-margin-remove table-cell-basic" style={style}>
      {title && <li>{title}</li>}
      {subtitle && <li>{subtitle}</li>}
    </ul>
  );
}

export function CellSingleLinked(assetLinkProps: AssetLinkProps) {
  return assetLinkProps.value != null ? (
    <ul className="uk-list uk-list-collapse uk-margin-remove table-cell-basic">
      <li>
        <AssetLink {...assetLinkProps} />
      </li>
    </ul>
  ) : null;
}
