import { gql } from '@apollo/client';
import { getGraphQLClient } from 'gql';
import { isEqual, sortBy } from 'lodash';
import { isUserDataChanged, processUserDataUpdates } from './userDataCommon';
import { getCacheLastModified, setCacheLastModified } from './userDataLocal';
import { reactiveVarsBySection, UserDataSection } from './userDataTypes';

type RemoteUserDataChangeListener = (section: UserDataSection, userData: any) => void;

const storeRemote = true;
let initialRemoteUserData: Record<string, any> | undefined = undefined;
const forceInitFromRemoteUserData = true;
let abortController: AbortController | undefined = undefined;

type RemoteUserDataResponse = {
  userData?: Record<string, any>;
  lastModified?: Date;
};

const userDataQuery = gql`
  query ($modifiedAfter: DateTime, $wait: Boolean) {
    userData(modifiedAfter: $modifiedAfter, wait: $wait) {
      objects {
        section
        key
        value
        lastModified
        deleted
      }
    }
  }
`;

const cleanDeletedUserDataMutation = gql`
  mutation {
    cleanDeletedUserData {
      lastRemoved
    }
  }
`;

const setUserDataMutation = gql`
  mutation ($section: String!, $objects: [UserDataObjectInput]!) {
    setUserData(input: {section: $section, objects: $objects}) {
      lastModified
    }
  }
`;

export async function initRemoteUserData() {
  const lastRemoved = (await cleanDeletedUserData()) as Date;
  const cacheLastModified = await getCacheLastModified();
  const cacheIsOld = cacheLastModified && lastRemoved && lastRemoved > cacheLastModified;
  const initFromRemoteUserData = forceInitFromRemoteUserData || !cacheLastModified || cacheIsOld;

  if (initFromRemoteUserData) {
    const { userData, lastModified } = await getRemoteUserData();
    if (userData) {
      initialRemoteUserData = userData;
    }
    await setCacheLastModified(lastModified || new Date());
  }
}

export function initRemoteUserDataSection(section: string) {
  if (initialRemoteUserData) {
    const reactiveVar = reactiveVarsBySection[section];
    const data = initialRemoteUserData[section] || reactiveVar();
    // Free the in-memory cache for this section.
    delete initialRemoteUserData[section];
    return data;
  } else {
    return undefined;
  }
}

export async function updateRemoteUserData(section: string, oldData: any, newData: any) {
  if (!storeRemote) {
    return;
  }

  let objects: any[] = [];

  if (Array.isArray(newData)) {
    if (!isEqual(newData, oldData)) {
      objects = [{ section, key: section, value: JSON.stringify(newData) }];
    }
  } else {
    if (!oldData) {
      oldData = {};
    }
    const ids = new Set([...Object.keys(newData), ...Object.keys(oldData)]);
    objects = [];
    ids.forEach((id) => {
      const oldObj = oldData[id];
      const newObj = newData[id];
      if (!newObj) {
        objects.push({ section, key: id, value: JSON.stringify(oldObj), deleted: true });
      } else if (!oldObj || isUserDataChanged(oldObj, newObj)) {
        objects.push({ section, key: id, value: JSON.stringify(newObj) });
      }
    });
  }

  if (objects.length === 0) {
    return;
  }

  try {
    const { errors } = await getGraphQLClient().mutate({
      mutation: setUserDataMutation,
      variables: { section, objects },
    });

    if (errors) {
      throw new Error(errors[0].message);
    }
  } catch (e) {
    console.error(`Error updating remote user data: ${e}`);
  }
}

export async function subscribeToRemoteUserDataChanges(listener: RemoteUserDataChangeListener) {
  if (!storeRemote) {
    return;
  }
  let modifiedAfter = await getCacheLastModified();
  while (true) {
    const { userData, lastModified } = await getRemoteUserData({
      modifiedAfter,
      includeDeleted: true,
      wait: true,
    });
    if (userData) {
      for (const [section, newData] of processUserDataUpdates(userData)) {
        listener(section as UserDataSection, newData);
      }
    }
    if (lastModified) {
      modifiedAfter = lastModified;
      await setCacheLastModified(lastModified);
    }
  }
}

export function refreshRemoteUserDataSubscription() {
  abortController && abortController.abort();
}

async function getRemoteUserData({
  modifiedAfter,
  includeDeleted,
  wait,
}: {
  modifiedAfter?: Date;
  includeDeleted?: boolean;
  wait?: boolean;
} = {}): Promise<RemoteUserDataResponse> {
  if (!storeRemote) {
    return {};
  }

  const signal = getAbortControllerSignal();
  const context = wait ? { fetchOptions: { signal } } : undefined;

  try {
    const { data, errors } = await getGraphQLClient().query({
      query: userDataQuery,
      variables: { modifiedAfter, wait },
      context,
    });

    if (errors) {
      throw new Error(errors[0].message);
    }

    let objects = data.userData?.objects || [];
    if (!includeDeleted) {
      objects = objects.filter((object: any) => !object.deleted);
    }

    objects = sortBy(objects, 'lastModified');
    const lastModified =
      objects.length > 0 ? new Date(objects.slice(-1)[0].lastModified) : undefined;
    const userData: Record<string, any> = {};

    objects.forEach((object: any) => {
      const value = object.value;
      if (Array.isArray(value)) {
        userData[object.section] = value;
      } else {
        parseDates(value);
        let sectionMap = userData[object.section];
        if (!sectionMap) {
          sectionMap = userData[object.section] = {};
        }
        if (object.deleted) {
          value.deleted = true;
        }
        sectionMap[object.key] = value;
      }
    });

    return { userData, lastModified };
  } catch (e) {
    if (!signal.aborted) {
      console.error(`Error fetching user data: ${e}`);
    }
    return {};
  }
}

function getAbortControllerSignal() {
  if (!abortController || abortController.signal.aborted) {
    abortController = new AbortController();
  }
  return abortController.signal;
}

async function cleanDeletedUserData(): Promise<Date | undefined> {
  try {
    const { data } = await getGraphQLClient().mutate({
      mutation: cleanDeletedUserDataMutation,
    });
    const lastRemoved = data.cleanDeletedUserData.lastRemoved;
    return lastRemoved ? new Date(lastRemoved) : undefined;
  } catch (e) {
    console.error(`Error cleaning deleted user data: ${e}`);
    return undefined;
  }
}

export function parseDates(obj: any) {
  for (let key in obj) {
    if (key === 'saved') {
      obj[key] = new Date(obj[key]);
    }
    if (typeof obj[key] === 'object') {
      parseDates(obj[key]);
    }
  }
}
