import { AdvancedQueryTemplate, QueryFilterClauseOption } from './advancedQuery';

export type Dataset = {
  name: string;
  label: string;
};

export const datasetRegistry: Record<string, Dataset> = {};
export const advancedQueryTemplateRegistry: Record<string, AdvancedQueryTemplate> = {};
export const filterClauseOptionRegistry: Record<string, QueryFilterClauseOption> = {};

export function registerDataset(dataset: Dataset) {
  datasetRegistry[dataset.name] = dataset;
}

export function registerAdvancedQueryTemplate(advancedQueryTemplate: AdvancedQueryTemplate) {
  advancedQueryTemplateRegistry[advancedQueryTemplate.name] = advancedQueryTemplate;
}

export function registerFilterClauseOptions(filterClauseOptions: QueryFilterClauseOption[]) {
  filterClauseOptions.forEach(
    (clauseOption: QueryFilterClauseOption) =>
      (filterClauseOptionRegistry[clauseOption.type] = clauseOption),
  );
}
