import {
  ApolloError,
  ApolloQueryResult,
  DocumentNode,
  NetworkStatus,
  OperationVariables,
  QueryHookOptions,
  QueryLazyOptions,
  useApolloClient,
} from '@apollo/client';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  addMetadataToPluginContext,
  addQueryToPluginContext,
  isDatasetSelection,
  makeGraphQLQuery,
  unevaluatedOutputError,
} from 'utils';
import { ComponentPluginContext, DataSlicesContext, SharedContext } from 'appContexts';
import { AdvancedQuery } from 'model';

const defaultQueryResult = {
  data: undefined,
  loading: false,
  networkStatus: NetworkStatus.ready,
};

export function useLazyComponentPluginQuery(
  queryOrAdvancedQuery: DocumentNode | AdvancedQuery,
  hookOptions?: QueryHookOptions,
  avoidPluginContextAppend?: boolean,
): [
  (options?: QueryLazyOptions<OperationVariables>) => Promise<ApolloQueryResult<any>>,
  ApolloQueryResult<any>,
] {
  const componentPluginContext = useContext(ComponentPluginContext);
  const { sharedKey } = useContext(SharedContext);
  const client = useApolloClient();
  const mountedRef = useRef(true);
  const [result, setResult] = useState<ApolloQueryResult<any>>(defaultQueryResult);
  const { metadata } = useContext(DataSlicesContext);
  // keep track of the time that the most recent query was fired
  const latestTimestamp = useRef<number>();

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, [mountedRef]);

  const runQuery = useCallback(
    async (options: QueryLazyOptions<OperationVariables> = {}) => {
      if (!mountedRef.current) {
        return defaultQueryResult;
      }

      let query = queryOrAdvancedQuery;
      if (hookOptions) {
        options = { ...hookOptions, ...options };
      }

      let result: ApolloQueryResult<any> = { ...defaultQueryResult, loading: true };
      setResult(result);

      try {
        const variables = { ...(options.variables || {}) };
        if ((query as AdvancedQuery)?.selection) {
          const variables = { ...(options.variables || {}) };
          // Only add a sharedKey variable if we're dealing with a dataset query
          if (sharedKey && isDatasetSelection((query as AdvancedQuery)?.selection?.value)) {
            variables.sharedKey = sharedKey;
          }
          query = await makeGraphQLQuery(query as AdvancedQuery, variables);
          !avoidPluginContextAppend &&
            addQueryToPluginContext(componentPluginContext, query, variables);
          options = { ...options, variables };
        } else {
          !avoidPluginContextAppend &&
            addQueryToPluginContext(componentPluginContext, query as DocumentNode, variables);
        }
        addMetadataToPluginContext(componentPluginContext, result, metadata, query as DocumentNode);
        const timestamp = new Date().getTime();
        latestTimestamp.current = timestamp;
        // TODO: Add an abort controller to abort any outstanding queries when the latest one returns
        result = await client.query({
          query: query as DocumentNode,
          fetchPolicy: 'cache-first',
          ...options,
        });
        // Don't return query results from previously fired queries
        if (timestamp !== latestTimestamp.current) {
          return;
        }
        const error = result.errors ? new ApolloError({ graphQLErrors: result.errors }) : undefined;
        const data = error ? undefined : result.data;
        result = { ...result, loading: false, error, data };
        if (mountedRef.current) {
          setResult(result);
        }
      } catch (error) {
        const apolloError = error as ApolloError;
        // if the error is due to unevaluated outputs, keeping "loading" set to true
        result = {
          ...defaultQueryResult,
          error: apolloError,
          loading: apolloError.message === unevaluatedOutputError,
        };
        if (mountedRef.current) {
          setResult(result);
        }
      }
      // For dynamic queries, we want to wait until we have the results to add the metadata. For static
      // queries, this is a no-op since the metadata should have set above. We add it immediately if possible
      // to avoid waiting for load.
      addMetadataToPluginContext(componentPluginContext, result, metadata, query as DocumentNode);
      return result;
    },
    [
      queryOrAdvancedQuery,
      hookOptions,
      componentPluginContext,
      metadata,
      client,
      sharedKey,
      avoidPluginContextAppend,
    ],
  );
  return [runQuery, result];
}
