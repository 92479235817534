import { gql } from '@apollo/client';
import { useAssetQuery } from 'hooks';
import { AssetType } from 'model';

const providerQuery = gql`
query CountryProvidersData($countrySet: [String]!) {
  countryData(countrySet: $countrySet) {
    id
    country
    countryRecords {
      id
      name
      internetOriginsScore
    }
    ripA(filter: "{'ipversion': 4, 'origin.rank': {'lte': 20}}") {
      asn {
        asn
        orgName
        attributesStateownedases {
          stateownedasesCountryOfOwnership
        }
      }
      origin {
        score
        rank
      } 
    }
  }
}
`;

export function useCountryTopProvidersQuery(assetValue: string) {
  const { asset, loading, error } = useAssetQuery(AssetType.Country, assetValue, providerQuery);
  return { asset, loading, error };
}
