import { EventEmitter } from 'fbemitter';

export type AppEvent = Record<string, any>;

export type AppEventListener = (event: AppEvent) => void;

class AppEventEmitter extends EventEmitter {
  async asyncEmit(eventName: string, event: AppEvent) {
    await new Promise<void>((resolve) => {
      window.setTimeout(() => {
        this.emit(eventName, event);
        resolve();
      }, 0);
    });
  }
}

export default new AppEventEmitter();
