import { useQuery, useMutation } from '@apollo/client';
import {
  AuthorizedElement,
  DeleteGalleryMosaicKebabMenu,
  Kebab,
  KebabOption,
  ManagerTableFilter,
  ManagerTable,
  Spinner,
  PUBLISHER_ROLE,
} from 'components';
import { gql } from 'graphql.macro';
import { keycloak } from 'keycloak';
import {
  acknowledgeNotification,
  ALERT_TYPES,
  createGenericNotification,
  PinTag,
  refreshRemoteUserDataSubscription,
} from 'model';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  asyncSleep,
  curateMosaicTableData,
  generatePdf,
  getImageIds,
  havePdfService,
  humanizeDateTime,
  InvestigationTypes,
  loadMosaicTableOptions,
} from 'utils';
import { ReactComponent as DownloadIcon } from 'svg/system/transfer-down.svg';
import { ReactComponent as TrashIcon } from 'svg/actions/trash.svg';
import { ReactComponent as EyeIcon } from 'svg/actions/eye-open.svg';
import { ReactComponent as CopyIcon } from 'svg/mosaic/mosaic-copy.svg';
import { ReactComponent as PlatformIcon } from 'svg/brand/logomark-soft-positive.svg';
import { GalleryContext, GalleryContextValue } from 'appContexts';
import { ReactComponent as GalleryIcon } from 'svg/experimental/museum-negative.svg';
import { countBy, flatten, orderBy } from 'lodash';
import { ReactComponent as StarFilledIcon } from 'svg/misc/star-filled.svg';
import { ReactComponent as StarEmptyIcon } from 'svg/misc/star-empty.svg';
import useResizeObserver from 'use-resize-observer';
import {
  kebabItem,
  mosaicPinItem,
  mosaicTagsItem,
  mosaicTitleItem,
  templateTitleItem,
} from 'components/TableItems';
import { useMosaicSavedSort, useMosaicTitleSort } from 'hooks';
import { ReactComponent as MosaicIcon } from 'svg/mosaic/mosaic-default-filled.svg';
import { ReactComponent as TemplateIcon } from 'svg/mosaic/mosaic-default-outline.svg';
import { Row } from 'react-table';
import { getGraphQLClient } from '../gql';

const galleryQuery = gql`
  query GetGalleryMosaics {
    publishedUserData {
      objects {
        userId
        section
        key
        value
        lastModified
        deleted
      }
    }
  }
`;

const copyMutation = gql`
  mutation($sharedKey: String!) {
    copySharedUserData(input:{sharedKey: $sharedKey}) {
      key
      value
    }
  }
`;

const pinMutation = gql`
  mutation($pinTag: String, $key: String!) {
    pinToGallery(input: {pinTag: $pinTag, key: $key}) {
      key
      pinTag
    }
  }
`;

const collectionTypes: Record<string, any> = {
  mosaics: { label: 'Mosaics', icon: <MosaicIcon style={{ width: '20px', height: '20px' }} /> },
  templates: {
    label: 'Templates',
    icon: <TemplateIcon style={{ width: '20px', height: '20px' }} />,
  },
};

export function Gallery() {
  const { data: galleryData, loading, refetch } = useQuery(galleryQuery);
  const publishedData = useMemo(
    () => galleryData?.publishedUserData?.objects || [],
    [galleryData?.publishedUserData?.objects],
  );
  const history = useHistory();
  const [copyGalleryMosaic, { data: copyData, error: copyError }] = useMutation(copyMutation);
  const [downloading, setDownloading] = useState<Set<string>>(new Set());
  const [filterOption, setFilterOption] = useState<any>();
  const [forcePageReset, setForcePageReset] = useState(false);
  const tabsRef = useRef<HTMLUListElement>(null);
  const [pinGalleryMosaic] = useMutation(pinMutation, { refetchQueries: ['GetGalleryMosaics'] });
  const clickTimeout = useRef<any>();
  const { ref, width } = useResizeObserver<HTMLDivElement>();
  const onMosaicTitleSort = useMosaicTitleSort();
  const onMosaicSavedSort = useMosaicSavedSort();
  const { search } = useLocation();
  const searchParams = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
  const searchParamPage = searchParams.get('page');
  const searchParamType = searchParams.get('type');
  const [collection, setCollection] = useState(searchParamType ?? 'mosaics');
  const [pageNumber, setPageNumber] = useState<number>(Number(searchParamPage));
  const [switcherID] = useState<string>(() => searchParamType ?? 'mosaics');
  const collectionString: InvestigationTypes =
    collection === 'mosaics' ? InvestigationTypes.Mosaic : InvestigationTypes.Template;

  // Update page number
  useEffect(() => {
    setPageNumber(searchParamPage != null ? Number(searchParamPage) : 1);
  }, [searchParamPage]);

  // Update type
  useEffect(() => {
    setCollection(searchParamType ?? 'mosaics');
  }, [searchParamType]);

  // Handle copy success or errors
  useEffect(() => {
    async function handleCopySuccessOrError() {
      if (copyError) {
        acknowledgeNotification('gallery-copy-error'); // Acknowledge previous copy error notifications
        createGenericNotification('gallery-copy-error', {
          alertType: ALERT_TYPES.error,
          message: 'Failed to copy the mosaic. Please try again.',
          title: 'Copy Failed',
          onClear: () => acknowledgeNotification('gallery-copy-error'),
        });
        // Cleanup notification on unmount
        return () => acknowledgeNotification('gallery-copy-error');
      } else if (copyData?.copySharedUserData?.key) {
        const isTemplate = copyData?.copySharedUserData?.value?.isTemplate;
        await copySharedInvestigation(copyData, history, isTemplate);
      }
    }
    handleCopySuccessOrError();
  }, [copyData, copyError, history]);

  const galleryViewCallback = useCallback(
    (sharedKey: string) => {
      sharedKey && history.push(`/shared?key=${sharedKey}`);
    },
    [history],
  );

  const galleryCopyCallback = useCallback(
    (sharedKey: string) => {
      copyGalleryMosaic({ variables: { sharedKey } });
    },
    [copyGalleryMosaic],
  );

  const galleryDownloadCallback = useCallback((investigation: any) => {
    generatePdf({
      investigation,
      setDownloading: (isDownloading: boolean) =>
        setDownloading((current) => {
          const newDownloading = new Set(current);
          isDownloading
            ? newDownloading.add(investigation.id)
            : newDownloading.delete(investigation.id);
          return newDownloading;
        }),
    });
  }, []);

  const onPinClick = useCallback(
    (event, galleryMosaic) => {
      // Clear Click Timeout
      if (clickTimeout) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }

      // Single Click - Pin or Un-Pin Mosaic
      if (event.detail === 1) {
        clickTimeout.current = window.setTimeout(() => {
          let newPinTag: PinTag | null = PinTag.Pinned;
          if (galleryMosaic?.value?.pinTag) {
            newPinTag = null;
          }
          pinGalleryMosaic({ variables: { key: galleryMosaic.key, pinTag: newPinTag } });
        }, 250);
      }
      // Double Click - Priority Pin Mosaic
      if (event.detail % 2 === 0) {
        pinGalleryMosaic({ variables: { key: galleryMosaic.key, pinTag: PinTag.PriorityPinned } });
      }
    },
    [pinGalleryMosaic],
  );

  const contextValue = useMemo<GalleryContextValue>(
    () => ({
      refetchGallery: () => refetch(),
    }),
    [refetch],
  );

  const columns: any = useMemo(() => {
    const ellipsisOptions: KebabOption[] = [
      {
        label: 'View',
        callback: galleryViewCallback,
        icon: <EyeIcon />,
        selector: 'value.sharing.shared_key',
      },
      {
        label: 'Copy',
        callback: galleryCopyCallback,
        icon: <CopyIcon />,
        selector: 'value.sharing.shared_key',
      },
      { label: 'Delete', icon: <TrashIcon />, expandComponent: <DeleteGalleryMosaicKebabMenu /> },
    ];

    if (havePdfService() && collection === 'mosaic') {
      ellipsisOptions.push({
        label: 'Download PDF',
        icon: <DownloadIcon />,
        callback: galleryDownloadCallback,
        selector: 'value',
      });
    }

    const myUserId = keycloak?.subject;

    return [
      {
        ...mosaicPinItem,
        Cell: ({ row }: any) => {
          const { pinTag } = row?.original?.value;

          if (pinTag) {
            return (
              <span
                className="manager-table-pin"
                onClick={(event) => {
                  event.stopPropagation();
                  onPinClick(event, row?.original);
                }}
              >
                <StarFilledIcon />
              </span>
            );
          }

          return (
            <AuthorizedElement roles={[PUBLISHER_ROLE]}>
              <span
                className="manager-table-pin manager-table-pin-empty"
                onClick={(event) => {
                  event.stopPropagation();
                  onPinClick(event, row?.original);
                }}
              >
                <StarEmptyIcon />
              </span>
            </AuthorizedElement>
          );
        },
      },
      {
        ...(collection === 'mosaics' ? mosaicTitleItem : templateTitleItem),
        accessor: 'value.title',
        sortType: onMosaicTitleSort,
      },
      {
        ...mosaicTagsItem,
        accessor: 'value.tags',
      },
      {
        Header: 'Owner',
        id: 'owner',
        align: 'right',
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        Cell: () => <PlatformIcon />,
      },
      {
        Header: 'Published',
        accessor: 'lastModified',
        sortDescFirst: true,
        align: 'right',
        width: 150,
        Cell: ({ cell }: any) => {
          return humanizeDateTime(new Date(cell?.value), true);
        },
        sortType: onMosaicSavedSort,
      },
      {
        ...kebabItem,
        accessor: 'value.id',
        Cell: ({ cell, row }: any) =>
          downloading.has(cell?.value) ? (
            <Spinner ratio={0.5} style={{ padding: '2px' }} />
          ) : (
            <Kebab
              options={{
                Actions: ellipsisOptions.filter(
                  (option) =>
                    // Disable Delete ellipsis option for Gallery mosaics that weren't uploaded by user
                    option.label !== 'Delete' ||
                    (option.label === 'Delete' && row?.original?.userId === myUserId),
                ),
              }}
              data={row?.original}
              className="manager-table-button"
            />
          ),
      },
    ];
  }, [
    downloading,
    galleryCopyCallback,
    galleryDownloadCallback,
    galleryViewCallback,
    onMosaicSavedSort,
    onMosaicTitleSort,
    onPinClick,
    collection,
  ]);

  const formatRowCallback = useCallback(
    (row: any) => {
      return {
        className: `cursor-pointer ${downloading.has(row.original.value.id) ? 'row-disabled' : ''}`,
      };
    },
    [downloading],
  );

  const onTabClick = useCallback(
    (newTab: string) => {
      if (!(collection === newTab)) {
        setCollection(newTab);
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set('type', newTab);
        urlSearchParams.set('page', '1');
        history.push(`gallery?${urlSearchParams.toString()}`);
      }
    },
    [collection, history],
  );

  const updateFilter = useCallback((newFilter: any) => {
    setForcePageReset(true);
    setFilterOption(newFilter);
  }, []);

  const tagOptions = useMemo(() => {
    const tags = flatten(
      Object.values(publishedData)
        ?.filter((investigation: any) =>
          collection === 'mosaics'
            ? !investigation?.value?.isTemplate
            : investigation?.value?.isTemplate,
        )
        ?.map((investigation: any) => investigation?.value?.tags),
    ).filter((tag) => tag);
    const tagBuckets = countBy(tags, 'name');
    return Object.keys(tagBuckets).map((key: string) => {
      return { label: key, value: key, count: tagBuckets[key] };
    });
  }, [publishedData, collection]);

  const loadGalleryOptions = useCallback(
    async (newFilter) => {
      return loadMosaicTableOptions(
        newFilter,
        tagOptions,
        publishedData.map((mosaicObj) => mosaicObj.value),
        collectionString,
        true,
      );
    },
    [tagOptions, publishedData, collectionString],
  );

  const filterOptions = useMemo(() => {
    const titleOptions = flatten(
      Object.values(publishedData)
        ?.filter((investigation: any) =>
          collection === 'mosaics'
            ? !investigation?.value?.isTemplate
            : investigation?.value?.isTemplate,
        )
        ?.map((investigation: any) => investigation?.value),
    )
      .filter((investigation) => investigation.title)
      .map((investigation) => {
        return {
          label: investigation.title,
          value: investigation.title.toLowerCase(),
          url: investigation?.sharing?.shared_key
            ? `/shared?key=${investigation?.sharing?.sharedKey}`
            : undefined,
        };
      });
    return [
      { label: 'Tags', options: orderBy(tagOptions, 'count', 'desc') },
      { label: collectionString, options: orderBy(titleOptions, 'value', 'asc') },
    ];
  }, [publishedData, collectionString, collection, tagOptions]);

  const data = useMemo(() => {
    return curateMosaicTableData(filterOption, publishedData, collection, true);
  }, [filterOption, publishedData, collection]);

  const sortBy = [{ id: 'lastModified', desc: true }];

  const hiddenColumns = useMemo(() => {
    return width && width < 1024 ? ['value.tags'] : [];
  }, [width]);

  const onUpdatePageURL = useCallback(
    (searchString: string) => {
      history.push(`gallery?${searchString}`);
    },
    [history],
  );

  const tabOptions = Object.keys(collectionTypes).map((type) => ({
    icon: collectionTypes[type].icon,
    label: collectionTypes[type].label,
    value: type,
  }));

  return (
    <GalleryContext.Provider value={contextValue}>
      <div ref={ref} className="uk-align-center data-manager">
        <div className="app-mosaic-table-header app-text-bold uk-flex uk-flex-middle">
          <GalleryIcon />
          <span className="uk-margin-left">Gallery</span>
        </div>
        <div className="uk-margin-small-top uk-margin-medium-bottom uk-text-muted app-width-tablet">
          Contexts and updates
        </div>
        <div style={{ width: '100%' }} className="uk-margin-medium-bottom uk-flex uk-flex-between">
          <ul
            ref={tabsRef}
            data-uk-switcher={`connect: #${switcherID}`}
            className="uk-tab uk-margin-remove-bottom uk-margin-remove-top disabled-tabs"
            style={{ display: 'inline-flex', flexWrap: 'nowrap', justifyContent: 'space-evenly' }}
          >
            {tabOptions.map((contentObj: any, index) => {
              return (
                <li
                  key={contentObj.value}
                  className={contentObj.value === collection ? 'uk-active' : ''}
                >
                  <a onClick={() => onTabClick(contentObj.value)} className="uk-text-capitalize">
                    <div className="uk-flex">
                      {contentObj.icon}
                      <div className="uk-margin-small-left">{contentObj.label}</div>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="uk-float-right uk-flex">
            <ManagerTableFilter
              placeholder={`Search for a ${collectionString} or tag`}
              selected={filterOption}
              options={filterOptions}
              onChange={updateFilter}
              loadOptions={loadGalleryOptions}
            />
          </div>
        </div>
        <ManagerTable
          data={data}
          columns={columns}
          sortBy={sortBy}
          getRowProps={formatRowCallback}
          isDisabled={(row: Row<object>) => downloading.has(row.values.id)}
          onRowClick={(row: any) => galleryViewCallback(row?.original?.value?.sharing?.shared_key)}
          loading={loading}
          forcePageReset={forcePageReset}
          setForcePageReset={setForcePageReset}
          hiddenColumns={hiddenColumns}
          pageNumber={pageNumber}
          onUpdatePageURL={onUpdatePageURL}
        />
      </div>
    </GalleryContext.Provider>
  );
}

export async function copySharedInvestigation(copyData: any, history: any, isTemplate?: boolean) {
  refreshRemoteUserDataSubscription();
  const copiedId = copyData?.copySharedUserData?.key.split(':')[1];
  const copiedTitle = copyData?.copySharedUserData?.value?.title;
  acknowledgeNotification('gallery-copy-success'); // Acknowledge previous copy success notifications
  // If there is some image which should have backend data (no data field and no timestamp)
  // check to make sure that the backend data has been transferred before navigating to the new mosaic
  if (
    copyData?.copySharedUserData?.value?.images?.some((image) => !image.data && !image.timestamp)
  ) {
    // wait a second to ensure images have fully transferred in the image data index.
    const client = getGraphQLClient();
    let imagesTransferred = false;
    let retries = 3;
    while (!imagesTransferred && retries >= 0) {
      await asyncSleep(500);
      const imageResult = await client.query({
        query: getImageIds,
        variables: { investigationId: copyData?.copySharedUserData?.key },
      });
      imagesTransferred = imageResult?.data?.imageData?.dataObjects?.length;
      retries--;
    }
    if (retries === 0) {
      createGenericNotification('image-copy-error', {
        alertType: ALERT_TYPES.warning,
        title: 'Image Copy Warning',
        message: 'Some image data may not have successfully copied.',
      });
    } else {
      createGenericNotification('copy-success', {
        alertType: ALERT_TYPES.success,
        title: 'Copy Success',
        message: (
          <div>
            <i>{copiedTitle}</i> has been copied to your private space.
          </div>
        ),
      });
    }
  }
  history.push(
    `/${(isTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic).toLowerCase()}?id=${copiedId}`,
  );
}
