import { isEqual, reduce } from 'lodash';

export function getDifferingProperties(a: any, b: any) {
  return reduce(
    a,
    (result: string[], value: any, key: string) =>
      isEqual(value, b[key]) ? result : result.concat(key),
    [],
  );
}

export function hex2bin(hex: string) {
  return parseInt(hex, 16).toString(2).padStart(8, '0');
}

export const APP_CONTENT_WIDTH = 768;
