import { useKeycloak } from '@react-keycloak/web';
import { hasAuthentication, isAuthorized } from 'utils';

export function AuthorizedElement({ roles, children }: { roles: string[]; children: any }) {
  const { keycloak } = useKeycloak();
  return (isAuthorized(keycloak, roles) || !hasAuthentication()) && children;
}

export const PUBLISHER_ROLE = 'public-gallery-admin';
export const ON_DEMAND_ROLE = 'platform-on-demand';
export const API_ROLE = 'platform-api';
