import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { hasAuthentication, isEmbedded } from 'utils';
import appEventEmitter from 'appEventEmitter';

var authCreds: Record<string, string> = {};

export const keycloak = Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_ENDPOINT,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
});

export function setAuthCreds(creds: Record<string, string>) {
  authCreds = creds;
  if (keycloak.authenticated) {
    keycloak.init(keycloakInitOptions());
  }
}

export function keycloakInitOptions(): Keycloak.KeycloakInitOptions {
  return {
    enableLogging: true,
    checkLoginIframe: !isEmbedded(),
    ...authCreds,
  };
}

export class KeycloakProvider extends ReactKeycloakProvider {
  tokenRefreshTimeoutId: number | null = null;
  minValidity = 300;

  init() {
    if (hasAuthentication()) {
      super.init();
      this.handleOnAuthSuccess();
      this.handleOnAuthRefeshSuccess();
    }
  }

  handleOnAuthSuccess() {
    const updateState = this.updateState('onAuthSuccess');
    keycloak.onAuthSuccess = () => {
      updateState();
      if (!isEmbedded()) {
        this.scheduleTokenRefresh();
      }
      appEventEmitter.emit('authorized');
    };
  }

  handleOnAuthRefeshSuccess() {
    keycloak.onAuthRefreshSuccess = () => {
      appEventEmitter.emit('authorizationRefreshed');
    };
  }

  scheduleTokenRefresh() {
    this.tokenRefreshTimeoutId && clearTimeout(this.tokenRefreshTimeoutId);
    this.tokenRefreshTimeoutId = window.setTimeout(() => {
      if (keycloak.refreshToken) {
        keycloak
          .updateToken(this.minValidity)
          .then(() => {
            this.scheduleTokenRefresh();
          })
          .catch(() => {
            keycloak.logout();
          });
      } else {
        this.scheduleTokenRefresh();
      }
    }, 30000);
  }
}
