import latinize from 'latinize';
import countryLookup, { countries } from 'country-code-lookup';
import { latLng } from 'leaflet';

export function formatGeoLabel(geo: any) {
  if (!geo) {
    return '';
  }
  if (geo.anycast) return 'Anycast';
  const includeParts = ['US', 'CA'].includes(geo.country)
    ? ['city', 'countryDivision', 'country']
    : ['city', 'country'];
  const parts: string[] = [];
  includeParts.forEach((partName: string) => {
    if (geo[partName]) {
      const partGeo = partName === 'country' ? getCountryName(geo[partName]) : geo[partName];
      parts.push(latinize(partGeo));
    }
  });
  return parts.join(', ');
}

export function getCountryCode(name: string) {
  try {
    return countryLookup.byIso(name).iso2;
  } catch {}

  try {
    return countryLookup.countries.find(
      (country) => country.country.toLowerCase() === name.toLowerCase(),
    ).iso2;
  } catch {}

  return '';
}

export function getCountryName(cc: string) {
  if (cc) {
    if (cc.toLowerCase() === 'anycast') return 'Anycast';
    return lookupByCountryCode(cc)?.country ?? 'Unknown';
  } else {
    return 'Unknown';
  }
}

export function getCountryRegion(cc: string) {
  if (cc) {
    return lookupByCountryCode(cc)?.region ?? '';
  } else {
    return '';
  }
}

function lookupByCountryCode(cc: string) {
  try {
    return countryLookup.byIso(cc);
  } catch (e) {
    console.error('ISO code error:', cc);
    return undefined;
  }
}

export function getCountryOptions() {
  return countries.map((c: any) => ({ label: c.country, value: c.iso2, typename: 'country' }));
}

export function matchCountryOptions(name: string) {
  name = name.toLowerCase();
  return getCountryOptions().filter((option: any) => option.label.toLowerCase().startsWith(name));
}

export function isEqualLatLng(a: any, b: any) {
  return latLng(a).equals(latLng(b));
}

export function getCollectorCountryOptions() {
  return collectorCountryCodes
    .map((cc: string) => ({ label: getCountryName(cc), value: cc }))
    .sort((a: any, b: any) => a.label.localeCompare(b.label));
}

// Manual list for now until we can use some sort of collector index, traceroute edges aggregation too slow
const collectorCountryCodes = [
  'US',
  'NL',
  'SG',
  'RU',
  'GB',
  'AU',
  'CA',
  'DE',
  'JP',
  'FR',
  'HK',
  'UA',
  'BR',
  'IN',
  'ES',
  'PL',
  'IL',
  'CH',
  'IR',
  'CN',
  'KR',
  'TR',
  'RO',
  'BG',
  'SE',
  'IT',
  'LU',
  'AE',
  'ID',
  'AT',
  'IS',
  'BE',
  'FI',
  'MY',
  'IE',
  'ZA',
  'LV',
  'MX',
  'CY',
  'SA',
  'KZ',
  'BH',
  'PS',
  'CL',
  'NO',
  'CO',
  'VN',
  'CZ',
  'BO',
  'AZ',
  'MA',
  'AR',
  'MD',
  'JO',
  'TH',
  'PK',
  'TW',
  'GH',
  'GR',
  'IQ',
  'SK',
  'NZ',
  'PE',
  'PH',
  'RS',
  'GE',
  'MK',
  'PT',
  'DK',
  'MZ',
  'PF',
  'HU',
  'MM',
  'KG',
  'PA',
  'LT',
  'SI',
  'BY',
  'RW',
  'EG',
  'IM',
  'AM',
  'EE',
  'NG',
  'KH',
  'HR',
  'BA',
  'TN',
  'TZ',
  'LA',
  'UZ',
  'MN',
  'VE',
  'CW',
  'CG',
  'SN',
  'UY',
  'MT',
  'NP',
  'KE',
  'AL',
  'ML',
  'EC',
  'BD',
  'PY',
  'AD',
  'DZ',
  'BW',
  'KW',
  'LY',
];
