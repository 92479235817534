import { useState } from 'react';
import { NewTableColumn } from 'utils';
import { useNewTableColumns } from './useNewTableColumns';

export function useStaticTableColumns(columnOptions: NewTableColumn[], tabId?: string) {
  const [rowsVisible, setRowsVisible] = useState<number>(10);

  columnOptions.forEach((column: NewTableColumn) => {
    column.localFilter = true;
  });

  const { onUpdateColumnConfig, columns, corruptColumns } = useNewTableColumns(
    columnOptions,
    tabId,
  );

  return {
    columns,
    corruptColumns,
    onUpdateColumnConfig,
    rowsVisible: rowsVisible,
    loadRows: () => {
      setRowsVisible((curr) => curr + 10);
    },
  };
}
