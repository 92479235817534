import React, { useState } from 'react';
import { DataImport, DatasetEditor } from 'components';
import { useReactiveVar } from '@apollo/client';
import { UserDatasets, userDatasetsVar } from 'model';
import { getVersion, hasMinorVersionDifference } from 'utils';
import { ReactComponent as ImportIcon } from 'svg/system/transfer-up.svg';

export function ImportPage() {
  const [importingDataset, setImportingDataset] = useState(undefined);
  const datasets: UserDatasets = useReactiveVar(userDatasetsVar);

  return (
    <div className="import-page">
      <div
        className="uk-flex app-auto-margins uk-margin-medium-bottom"
        style={{ lineHeight: '30px' }}
      >
        <ImportIcon style={{ color: '#333333' }} />
        <div className="uk-margin-left app-page-title">Import</div>
      </div>
      <div className="app-auto-margins">
        <div className="uk-text-muted uk-margin-medium-bottom">
          Import a file below. Valid files correspond to surfaces (IPs or PCAP) or shared content
          such as a mosaic, template, or advanced search (JSON). IP files should be formatted in
          plain text with one IP per line.
        </div>
        {importingDataset ? (
          <DatasetEditor
            isEditMode={false}
            dataset={
              Object.values(datasets)?.find((d) => importingDataset?.id === d.id) ??
              importingDataset
            }
            disableEditingOptions={true}
          />
        ) : (
          <DataImport onNewDataset={(dataset) => setImportingDataset(dataset)} />
        )}
      </div>
    </div>
  );
}

export function getPlatformVersionNotificationMessage(importVersion) {
  const currentVersion = getVersion();
  if (importVersion === currentVersion || !hasMinorVersionDifference(importVersion))
    return undefined;
  return (
    <div>
      <div>{`Current Version: ${currentVersion}; Import Version: ${importVersion ?? '< 0.10.0'}.`}</div>
      <div>This difference may affect tile format, available filters, or other functionality. </div>
    </div>
  );
}

export function getDataVersionNotificationMessage(savedMetadata: any, currentMetadata: any) {
  let hasVersionDifference;
  if ((!!savedMetadata && !currentMetadata) || (!savedMetadata && !!currentMetadata)) {
    hasVersionDifference = true;
  } else {
    hasVersionDifference = Object.keys({ ...savedMetadata, ...currentMetadata }).some((key) => {
      if (
        !currentMetadata[key] ||
        savedMetadata[key]?.lastUpdated !== currentMetadata[key].lastUpdated
      ) {
        console.log('Import Metadata Difference:', key, savedMetadata[key], currentMetadata[key]);
        return true;
      }
      return false;
    });
  }
  return hasVersionDifference
    ? 'The underlying data has changed, so results may differ from the original shared version.'
    : undefined;
}
