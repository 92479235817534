import { isEqual } from 'lodash';
import { Image, reactiveVarsBySection } from './userDataTypes';

export const disabledSectionChangeHandlerSet = new Set();

export function* processUserDataUpdates(userDataUpdates: Record<string, any>) {
  // sort user data updates so that investigations are processed last, after everything
  // which supplies them.
  const sections = Object.keys(userDataUpdates).sort((a, b) => {
    if (a === 'investigations') return 1;
    if (b === 'investigations') return -1;
    return 0;
  });
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const reactiveVar = reactiveVarsBySection[section];
    if (!reactiveVar) {
      return;
    }
    const updates = userDataUpdates[section];
    const currentData = reactiveVar();

    if (Array.isArray(updates)) {
      if (!isEqual(updates, currentData)) {
        yield [section, updates];
      }
    } else {
      const newData: Record<string, any> = {};
      const ids = new Set([...Object.keys(updates), ...Object.keys(currentData)]);
      let changed = false;
      ids.forEach((id) => {
        const newObj = updates[id];
        const currentObj = currentData[id];
        if (newObj?.deleted) {
          changed = true;
        } else if (!newObj || (currentObj && !isUserDataChanged(currentObj, newObj))) {
          newData[id] = currentObj;
        } else if (!currentObj || isUserDataChanged(currentObj, newObj)) {
          newData[id] = newObj;
          changed = true;
        }
      });
      if (changed) {
        yield [section, newData];
      }
    }
  }
}

export function isUserDataChanged(currentObj: any, newObj: any) {
  return (
    newObj.saved > currentObj.saved ||
    !isEqual(newObj.system, currentObj.system) ||
    !isEqual(newObj.sharing, currentObj.sharing) ||
    !isEqual(newObj.pinTag, currentObj.pinTag) ||
    // Image migrations will not update the saved time, but we should still update the backend
    (currentObj?.images?.some((image: Image) => !!image.data) &&
      newObj?.images?.every((image: Image) => !image.data))
  );
}
