// Magic numbers, one of which must appear at the beginning of any pcap file
const BIG_ENDIAN_MN = [0xa1b2c3d4, 0xa1b23c4d];
const LITTLE_ENDIAN_MN = [0x4d3cb2a1, 0xd4c3b2a1];
const VALID_MN = BIG_ENDIAN_MN.concat(LITTLE_ENDIAN_MN);

const HEADER_LENGTH = 24; // bytes

// Ethernet Type numbers
const IP_TYPE = 0x0800;
const IP_V6 = 0x86dd;
const ETH_802_1Q = 0x8100;
const ETH_Q_HEADER_LENGTH = 4;

// We should be able to support more link types if there are any others which could contain
// IP headers, but I there are many types and we would need to investigate sample files.
const LINK_TYPE_BY_LENGTH: Record<string, number> = {
  '113': 16, // SSL
  '1': 14, // ethernet
};

export async function parsePcapChunk(chunk: Blob, linkType: number, littleEndian: boolean) {
  let buffer = await chunk.arrayBuffer();
  let uniqueIps = new Set<string>();
  let leftovers;
  const data = new DataView(buffer);
  let i = 0;
  // Loop over packets
  while (i < data.byteLength) {
    // Check that we have enough data in the buffer to get packet length
    // If not, break early and save any leftover data at the end of the buffer
    // for the next loop.
    if (i + 12 >= data.byteLength) {
      leftovers = buffer.slice(i);
      break;
    }
    const packetLength = data.getUint32(i + 8, littleEndian);

    // One more check to ensure we can get the whole packet,
    // now that we have packet length.
    if (i + 16 + packetLength >= data.byteLength) {
      leftovers = buffer.slice(i);
      break;
    }

    // Skip to the packet payload
    i += 16;
    const packet = buffer.slice(i, i + packetLength);

    // Ensure we have an IP header
    const ipStart = findIpHeaderStart(data, i, linkType);
    if (ipStart != null) {
      // Extract the ips if we find any, and add them to a Set
      const ips = extractIps(new Uint8Array(packet), ipStart);
      if (ips != null) {
        for (let i = 0; i < ips.length; i++) {
          uniqueIps.add(ips[i]);
        }
      }
    }
    // Move on to the next packet
    i += packetLength;
  }
  return { ips: uniqueIps, leftovers: leftovers };
}

export async function parsePcapHeader(chunk: Blob) {
  const header = new DataView(await chunk.slice(0, HEADER_LENGTH).arrayBuffer());
  // Confirm it's actually a pcap file we're working with
  if (header.byteLength < HEADER_LENGTH || !VALID_MN.includes(header.getUint32(0))) {
    return { error: 'File provided is not recognizable as pcap' };
  }
  const littleEndian = LITTLE_ENDIAN_MN.includes(header.getUint32(0));
  const linkTypeOffset = littleEndian ? 20 : 22;
  let linkType: number | undefined = header.getUint16(linkTypeOffset, littleEndian);
  if (!Object.keys(LINK_TYPE_BY_LENGTH).includes(linkType.toString())) {
    return { error: `Pcap file is formatted with unsupported link type, ${linkType.toString()}` };
  }

  return { packet: chunk.slice(HEADER_LENGTH), linkType, littleEndian, error: undefined };
}

export async function validatePcapFile(file: File) {
  const header = new DataView(await file.slice(0, HEADER_LENGTH).arrayBuffer());
  if (header.byteLength < HEADER_LENGTH || !VALID_MN.includes(header.getUint32(0))) {
    return 'File provided does not have a valid pcap header';
  }
  return undefined;
}

function extractIps(packet: Uint8Array, start: number) {
  const ipHeader = packet.slice(start);
  const version = (ipHeader[0] & 0xf0) >> 4;
  if (version === 4) {
    return parseIpV4Header(ipHeader);
  } else if (version === 6) {
    return parseIpV6Header(ipHeader);
  } else {
    return null;
  }
}

function parseIpV4Header(header: Uint8Array) {
  let sourceStr = '';
  let destStr = '';
  const sourceAddress = header.slice(12, 16);
  const destAddress = header.slice(16, 20);
  for (let i = 0; i < sourceAddress.length; i++) {
    sourceStr += `${sourceAddress[i]}.`;
    destStr += `${destAddress[i]}.`;
  }
  sourceStr = sourceStr.slice(0, -1);
  destStr = destStr.slice(0, -1);

  return [sourceStr, destStr];
}

function parseIpV6Header(header: Uint8Array) {
  let sourceStr = '';
  let destStr = '';
  const sourceAddress = header.slice(8, 24);
  const destAddress = header.slice(24, 40);
  for (let i = 0; i < 8; i++) {
    const sourceNum = sourceAddress.slice(i * 2, i * 2 + 2);
    const destNum = destAddress.slice(i * 2, i * 2 + 2);

    const sourceArr = new Uint16Array(sourceNum);
    const destArr = new Uint16Array(destNum);
    sourceStr += formatIpV6Piece(sourceArr);
    destStr += formatIpV6Piece(destArr);
  }

  sourceStr = sourceStr.slice(0, -1);
  destStr = destStr.slice(0, -1);
  return [sourceStr, destStr];
}

function formatIpV6Piece(array: Uint16Array) {
  let runningStr = '';
  array.forEach((value) => {
    if (value.toString(16).length === 1) {
      runningStr += '0';
    }
    runningStr += value.toString(16);
  });
  return `${runningStr}:`;
}

function findIpHeaderStart(data: DataView, i: number, linkType: number) {
  const start = LINK_TYPE_BY_LENGTH[linkType.toString()];
  const etherTypeOffset = i + start - 2;
  const etherType = data.getUint16(etherTypeOffset);
  if (etherType === IP_TYPE || etherType === IP_V6) {
    return start;
  } else if (etherType === ETH_802_1Q) {
    const skip1QHeader = data.getUint16(etherTypeOffset + ETH_Q_HEADER_LENGTH); // Skip the 802.1q header
    if (skip1QHeader === IP_TYPE || skip1QHeader === IP_V6) {
      return start + 4;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}
