import { uniqBy } from 'lodash';
import { flattenServicesData, getCountryName } from 'utils';
import { Address4, Address6 } from 'ip-address';

export const IP_DATA_GQL = `
            ip
            exchange {
              id
              name
            }
            orgName
            geo {
              city
              countryDivision
              country
              anycast
              latLon
            }
            rdns
            routerId
            routerAlias {
              vendor {
                name
                os
                osVersion
              }
            }
            routingOrigin {
              prefix
              peerCount
              origins {
                asn
                fpet
                orgName
              }
            }
            attributesMaltrail {
              maltrailBehaviors
              maltrailMalwares
            }
            attributesServices {
              services {
                name
                region
                type
                tags
              }
            }  
            behaviors {
              names
              behavior
            }
`;

export function getIPData(data: any): any[] | undefined {
  if (data?.ipData) return data?.ipData;
  const existingIps = new Set();
  // Filter out duplicates
  return data?.ips?.items?.filter((item) => {
    if (existingIps.has(item.ip)) return false;
    if (item.ip) {
      existingIps.add(item.ip);
      return true;
    }
    return false;
  });
}

// Build IP annotation structure for pattern discovery tree
export function toPatternTreeInput(ipData: Array<Record<string, any>>) {
  return ipData.map((ip: any) => {
    const { behaviors, vpnNames, proxyNames } = parseBehaviors(ip?.behaviors);
    const anycast = ip?.geo?.anycast;
    const deviceOS = `${ip?.routerAlias?.vendor?.os}${ip?.routerAlias?.vendor?.osVersion ? ` ${ip?.routerAlias?.vendor?.osVersion}` : ''}`;
    const reputation = ip?.attributesMaltrail?.maltrailBehaviors;
    const malware = ip?.attributesMaltrail?.maltrailMalwares;
    const { serviceNames, serviceRegions, serviceTypes, serviceTags } = flattenServicesData(
      ip?.attributesServices?.services,
    );

    return {
      ip: ip?.ip,
      org: ip?.orgName,
      rdns: ip?.rdns,
      country: !anycast && getCountryName(ip?.geo?.country),
      city: !anycast && ip?.geo?.city,
      router: ip?.routerId,
      vendor: ip?.routerAlias?.vendor?.name,
      software: ip?.routerAlias?.vendor?.os ? deviceOS : null,
      prefix: ip?.routingOrigin?.prefix,
      origin: ip?.routingOrigin?.origins
        .map((origin: any) => formatOriginString(origin))
        ?.join('|'),
      'maltrail behavior': reputation?.length > 0 ? reputation.join(', ') : null,
      'maltrail malware': malware?.length > 0 ? malware.join(', ') : null,
      behaviors: behaviors.length > 0 ? behaviors.join(', ') : null,
      vpns: vpnNames?.length > 0 ? vpnNames.join(', ') : null,
      proxies: proxyNames?.length > 0 ? proxyNames.join(', ') : null,
      services: serviceNames?.length > 0 ? serviceNames.join(', ') : null,
      'service regions': serviceRegions?.length > 0 ? serviceRegions.join(', ') : null,
      'service types': serviceTypes?.length > 0 ? serviceTypes.join(', ') : null,
      'service tags': serviceTags?.length > 0 ? serviceTags.join(', ') : null,
    };
  });
}

function formatOriginString(origin: any) {
  if (origin?.orgName && origin?.asn) {
    return `${origin.orgName} (AS${origin?.asn})`;
  } else if (origin?.asn) {
    return `AS${origin?.asn}`;
  } else {
    return origin?.orgName;
  }
}

// Build IP annotation structure for pattern histogram
// Capitalize pattern name if pattern name does not require special format (ex: rDNS not RDNS)
export function toPatternHistogramInput(ipData: Array<Record<string, any>>) {
  const patternArray = toPatternTreeInput(ipData);
  const specialKeys: Record<string, string> = { rdns: 'rDNS', vpns: 'VPNs' };

  // Loop to either capitalize or use special format
  return patternArray.map((patternObj: Record<string, any>) => {
    const patternKeys = Object.keys(patternObj);
    patternKeys.forEach((key: string) => {
      const upperKey = specialKeys[key] || key.toUpperCase();
      if (upperKey !== key) {
        patternObj[upperKey] = patternObj[key];
        delete patternObj[key];
      }
    });
    return patternObj;
  });
}

// Take array of objects and convert to string based on specified nested field
export function objArrayStringify(objArray: Array<Record<string, any>>, field: string) {
  const filteredObjArray = objArray.filter((obj: Record<string, any>) => obj[field] != null);
  return uniqBy(filteredObjArray, field)
    .map((obj: Record<string, any>) => obj[field])
    .join(',');
}

export function parseBehaviors(behaviorsObj: Record<string, any>) {
  const behaviors: string[] = [];
  let vpnNames: string[] = [];
  let proxyNames: string[] = [];

  behaviorsObj?.forEach((behaviorObj: Record<string, any>) => {
    const { names, behavior } = behaviorObj || {};
    if (behavior === 'vpn') {
      behaviors.push('VPN');
      vpnNames = names;
    } else if (behavior === 'proxy') {
      behaviors.push('Proxy');
      proxyNames = names;
    }
  });

  return {
    behaviors,
    vpnNames,
    proxyNames,
  };
}

// return whether or not a given IP looks like it might be
// misappropriated (based on known patterns in misappropriated IPs)
export function checkForMisappropriatedIP(ip: string) {
  const [a, b, c] = ip.split('.').map((quad: string) => parseInt(quad));

  // check for DoD space, which is commonly misappropriated:
  // 7/8, 11/8, 21/8 22/8 26/8 28/8 29/8 30/8 33/8 55/8 214/8
  // 128.19.0.0/16, 128.25.0.0/16
  // 140.{1-74}/16
  if (
    [7, 11, 21, 22, 26, 28, 29, 30, 33, 55, 214].includes(a) ||
    (a === 128 && b in [19, 25]) ||
    (a === 140 && 1 <= b && b <= 74)
  ) {
    return true;
  }

  // check for commonly abused IP patterns
  // x.0.0.y for any x and for low-value y's, at least
  // x.1.1.y
  // x.x.x.y
  // x.x+1.x+2.y
  return (
    (b === c && c === 0) ||
    (b === c && c === 1) ||
    (a === b && b === c) ||
    (a + 1 === b && b + 1 === c)
  );
}

export function validateIPSet(ips: string): Record<string, Set<string>> {
  const invalidIps = new Set<string>();
  const duplicateIps = new Set<string>();
  const seenIps = new Set<string>();
  // Split the file by new line
  const ipSet = ips.match(/[^\r\n]+/g)?.map((line: string) => line.trim());
  const validIpSet = ipSet?.filter((ip) => {
    if (!isValidIp(ip)) {
      invalidIps.add(ip);
      seenIps.add(ip);
      return false;
    } else if (seenIps.has(ip)) {
      duplicateIps.add(ip);
      return true;
    } else {
      seenIps.add(ip);
      return true;
    }
  });
  const validIps = new Set(validIpSet);
  return { invalidIps, duplicateIps, seenIps, validIps };
}

export function createInvalidIpsWarning(invalidIps: Set<string>, duplicateIps, totalCount: number) {
  let warning = '';
  if (invalidIps.size > 0) {
    let invalidArray = Array.from(invalidIps);
    invalidArray = invalidArray.slice(0, 5);
    // An IPV6 address cannot contain more than 39 characters, including colons
    invalidArray = invalidArray.map(
      (candidate) => `${candidate.slice(0, 39)}${candidate.length > 39 ? '...' : ''}`,
    );
    warning += `Of the ${totalCount} candidate IPs, ${invalidIps.size} ${invalidIps.size === 1 ? 'is' : 'are'} invalid, including:
        ${invalidArray.join(', ')}${invalidArray.length > 5 ? `and ${invalidArray.length - 5} more. ` : '. '}`;
  }
  if (duplicateIps.size > 0) {
    warning += `Duplicates were detected for ${duplicateIps.size} IP${duplicateIps.size === 1 ? '' : 's'}. `;
  }
  if (invalidIps.size > 0 && duplicateIps.size === 0) {
    warning += 'Invalid IPs will be excluded.';
  } else if (invalidIps.size > 0 && duplicateIps.size > 0) {
    warning += 'Invalid and duplicate IPs will be excluded.';
  } else if (duplicateIps.size > 0 && invalidIps.size === 0) {
    warning += 'Duplicate IPs will be excluded.';
  }
  return warning;
}

export function isValidIp(ip: string) {
  // Ipv4 or Ipv6
  if (ip.length > 39) return false;
  if (!ip.match(/^[a-fA-F0-9:.]*$/)) return false;
  try {
    try {
      let address4 = new Address4(ip);
      return address4.parsedSubnet.length === 0;
    } catch {}
    let address6 = new Address6(ip);
    return address6.parsedSubnet.length === 0;
  } catch {
    return false;
  }
}

export function isValidPrefix(ip: string) {
  // Ipv4 or Ipv6
  try {
    try {
      let address4 = new Address4(ip);
      return address4.parsedSubnet.length > 0;
    } catch {}
    let address6 = new Address6(ip);
    return address6.parsedSubnet.length > 0;
  } catch {
    return false;
  }
}

export function getPatternASNLinks(asnString: string) {
  if (asnString.includes('|') || !asnString.includes('AS')) {
    return undefined;
  }

  let asn = asnString.replace('AS', '');
  if (asn.includes('(')) {
    asn = asn.split('(')[1].replace(')', '');
  }

  return `/assetreport?asn=${asn}`;
}
