import { makeVar, ReactiveVar } from '@apollo/client';
import {
  initRemoteUserData,
  initRemoteUserDataSection,
  updateRemoteUserData,
  subscribeToRemoteUserDataChanges,
} from './userDataRemote';
import { isEmbedded, withAsyncLock } from 'utils';
import {
  reactiveVarsBySection,
  UserAdvancedQueries,
  UserDataSection,
  UserDatasets,
  UserInvestigations,
  UserSearchHistory,
  UserTags,
} from './userDataTypes';
import { getLocalUserData, monitorLocalUserDataChanges, storeLocalUserData } from './userDataLocal';
import { disabledSectionChangeHandlerSet } from './userDataCommon';

export const userTagsVar = makeVar<UserTags>({});
export const userAdvancedQueriesVar = makeVar<UserAdvancedQueries>({});
export const userDatasetsVar = makeVar<UserDatasets>({});
export const userInvestigationsVar = makeVar<UserInvestigations>({});
export const userSearchHistoryVar = makeVar<UserSearchHistory>([]);

let initialized = false;

export async function initializeUserData() {
  if (initialized) {
    return;
  }
  initialized = true;

  if (!isEmbedded()) {
    await initRemoteUserData();
  }

  await initSection<UserTags>(UserDataSection.Tags, userTagsVar);
  await initSection<UserAdvancedQueries>(UserDataSection.AdvancedQueries, userAdvancedQueriesVar);
  await initSection<UserDatasets>(UserDataSection.Datasets, userDatasetsVar);
  await initSection<UserInvestigations>(UserDataSection.Investigations, userInvestigationsVar);
  await initSection<UserSearchHistory>(UserDataSection.SearchHistory, userSearchHistoryVar);

  // Monitor more quickly local changes made by another tab in the same browser.
  monitorLocalUserDataChanges();

  if (!isEmbedded()) {
    subscribeToRemoteUserDataChanges(handleRemoteSectionChange);
  }
}

async function initSection<T>(section: UserDataSection, reactiveVar: ReactiveVar<T>) {
  function onVarChange(value: T) {
    handleSectionChange<T>(section, value);
    reactiveVar.onNextChange(onVarChange);
  }

  reactiveVarsBySection[section] = reactiveVar;

  const remoteUserData = isEmbedded() ? undefined : initRemoteUserDataSection(section);
  if (remoteUserData) {
    reactiveVar(remoteUserData);
    await storeLocalUserData(section, remoteUserData);
  } else {
    const localUserData = await getLocalUserData<T>(section);
    if (localUserData) {
      reactiveVar(localUserData);
    }
  }

  reactiveVar.onNextChange(onVarChange);
}

function retrySectionChangeHandler(section, retries = 3, err = null) {
  function disabledSectionTest() {
    if (disabledSectionChangeHandlerSet.has(section)) {
      return Promise.reject(
        `disable section change handler has section. Retries left: ${retries - 1}`,
      );
    }
    return Promise.resolve();
  }
  if (retries <= 0) {
    return Promise.reject(err);
  }
  return disabledSectionTest().catch(async (err) => {
    await setTimeout(() => {}, 1000);
    return retrySectionChangeHandler(section, retries - 1, err);
  });
}

async function handleSectionChange<T>(section: UserDataSection, value: T) {
  withAsyncLock(section, async () => {
    let retriesExceeded = false;
    // If we are currently processing an update from remote that has not taken effect,
    // we don't want to update remote and overwrite it. However, if a deliberate config change was made
    // on the front end, we don't want that change to be completely lost just because we were updating
    // from remote at the moment. So, use a retry system until the update from remote has
    // been processed. Then, push the update to remote as normal. There is less than 100ms from when
    // a section is added to disabledSectionChangeHandlerSet and removed from it after processing
    // a remote update. So, this retry method will only be necessary for automatic updates triggered
    // by the reactive var change from remote, which means nothing will be overwritten. It is
    // theoretically possible for a user to overwrite a change from remote, but it is extraordinarily
    // unlikely.
    await retrySectionChangeHandler(section).catch((err) => {
      retriesExceeded = true;
      console.log(err);
    });
    if (retriesExceeded) {
      return;
    }
    const oldData = await getLocalUserData(section);
    await storeLocalUserData(section, value);
    await updateRemoteUserData(section, oldData, value);
  });
}

async function handleRemoteSectionChange(section: UserDataSection, newData: any) {
  disabledSectionChangeHandlerSet.add(section);
  try {
    await withAsyncLock(section, async () => {
      await storeLocalUserData(section, newData);
      const reactiveVar = reactiveVarsBySection[section];
      if (reactiveVar) {
        reactiveVar(newData);
      }
    });
  } finally {
    disabledSectionChangeHandlerSet.delete(section);
  }
}
