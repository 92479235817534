import {
  ALERT_TYPES,
  createGenericNotification,
  UserDataset,
  userDatasetsVar,
  UserInvestigation,
} from 'model';
import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  finishedDatasetStatuses,
  getConfigAdvancedQuery,
  handleCrossInstanceImages,
  humanizeFileSize,
  InvestigationTypes,
  makeUniqueId,
  navigateToQueryResults,
  saveInvestigation,
  saveUserDataset,
  uploadCrossInstanceDatasets,
} from 'utils';
import { ReactComponent as Attachment } from 'svg/system/document.svg';
import { ReactComponent as DeleteIcon } from 'svg/actions/x-default.svg';
import {
  Banner,
  FileSelector,
  getDataVersionNotificationMessage,
  getPlatformVersionNotificationMessage,
  Spinner,
} from 'components';
import { useHistory } from 'react-router-dom';
import { validateFile } from 'model/parsers/fileProcessing';
import { DataSlicesContext } from 'appContexts';
import { useReactiveVar } from '@apollo/client';

export function DataImport({
  onNewDataset,
}: { dataset?: UserDataset; onNewDataset: (dataset: UserDataset) => void }) {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [validationError, setValidationError] = useState<any>();
  const [alertType, setAlertType] = useState<ALERT_TYPES>();
  const [importType, setImportType] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [importTriggered, setImportTriggered] = useState<boolean>(false);
  const [crossInstanceParams, setCrossInstanceParams] = useState<Record<string, any>>();
  const { metadata } = useContext(DataSlicesContext);
  const userDatasets = useReactiveVar(userDatasetsVar);
  const crossInstanceDatasetRef = useRef({});
  const history = useHistory();

  // Reset the validation error when the file changes
  useEffect(() => {
    if (!file) setValidationError(undefined);
  }, [file]);

  // If we're doing a cross instance import with datasets, we need to keep track of the unfinished
  // uploads, and set loading to false when all is done
  useEffect(() => {
    if (!loading || importType !== 'cross-instance' || !importTriggered) return;
    // loop through datasets
    Object.values(userDatasets).forEach((dataset) => {
      if (crossInstanceDatasetRef.current[dataset.id] != null) {
        if (
          finishedDatasetStatuses.includes(dataset?.system?.status) &&
          !crossInstanceDatasetRef.current[dataset.id]
        ) {
          crossInstanceDatasetRef.current[dataset.id] = true;
        }
      }
    });
    if (Object.values(crossInstanceDatasetRef.current).every((uploadComplete) => uploadComplete)) {
      setLoading(false);
    }
  }, [userDatasets, loading, importType, importTriggered]);

  const completeSuccessfulCrossInstanceImport = useCallback(async () => {
    // having the advanced query on the top level indicates that this json was exported from the advanced search page.
    // In a normal mosaic, it would appear within specific sections' params.
    if (crossInstanceParams.advancedQuery) {
      navigateToQueryResults(getConfigAdvancedQuery(crossInstanceParams));
    } else {
      const strippedImageParams = await handleCrossInstanceImages(crossInstanceParams);
      const investigation = {
        ...strippedImageParams,
      };
      delete investigation.metadata;
      delete investigation.datasets;
      delete investigation.sharing; // Delete for now until we have conflict detection
      createGenericNotification('import-success', {
        alertType: ALERT_TYPES.success,
        title: 'Import Success',
        message: '',
      });
      saveInvestigation(investigation as UserInvestigation);
      const shortID = (investigation.id as string).split(':')[1];
      setLoading(false);
      history.push(
        `/${(investigation.isTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic).toLowerCase()}?id=${shortID}`,
      );
    }
  }, [crossInstanceParams, history]);

  // When loading is over, direct the cross instance import to the appropriate page
  useEffect(() => {
    async function onLoadEnd() {
      if (!loading && importType === 'cross-instance' && importTriggered) {
        setLoading(true);
        setImportTriggered(false);
        await completeSuccessfulCrossInstanceImport();
      }
    }
    onLoadEnd();
  }, [loading, importTriggered, importType, completeSuccessfulCrossInstanceImport]);

  function onNameChange(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value.trim());
  }

  function onDescriptionChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setDescription(event.target.value.trim());
  }

  async function onFileSelect(file: File | undefined) {
    const filename = file?.name;
    // All the files exported from the platform will end with .json
    // So this should be enough to detect them for now. If a user modifies the file extension
    // they are out of luck...
    if (filename?.toLowerCase().endsWith('.json')) {
      setImportType('cross-instance');
      const text = await file.text();
      try {
        const importObj = JSON.parse(text);
        const { sections, title, metadata: importMetadata, advancedQuery } = importObj;
        const platformVersion = importMetadata?.platformVersion;
        const dataSlices = importMetadata?.dataSlices;
        if (!((sections && title) || advancedQuery) || !platformVersion)
          throw Error('Badly formatted JSON');
        const platformVersionMessage = getPlatformVersionNotificationMessage(platformVersion);
        const dataVersionMessage = !!advancedQuery
          ? undefined
          : getDataVersionNotificationMessage(dataSlices, metadata);
        const versionMessage =
          platformVersionMessage || dataVersionMessage ? (
            <div>
              {platformVersionMessage}
              {dataVersionMessage}
            </div>
          ) : undefined;
        if (versionMessage) {
          setAlertType(ALERT_TYPES.warning);
          setValidationError(versionMessage);
        }
        setCrossInstanceParams({ ...importObj, id: makeUniqueId('investigation') });
      } catch (e) {
        setAlertType(ALERT_TYPES.error);
        setValidationError(
          'Unable to import, because the JSON does not appear to be valid.' +
            " Please create the file using the 'share' option on an advanced search, mosaic, or template.",
        );
        console.error(e);
        return;
      }
    } else if (file) {
      // Perform some file validation; check that the user has not reached an upload limit
      const { error, warning, typename, newFile } = await validateFile(file);
      setAlertType(error ? ALERT_TYPES.error : ALERT_TYPES.warning);
      setValidationError(error ?? warning);
      setImportType(typename);
      setFile(newFile ?? file);
      if (!name) setName(filename);
      return;
    }
    setFile(file);
    if (file && !name) {
      setName(filename);
    }
    if (!file) {
      setName(undefined);
      setCrossInstanceParams(undefined);
      setAlertType(undefined);
    }
  }

  async function onImport() {
    if (!file) {
      return;
    }
    if (importType === 'cross-instance') {
      setLoading(true);
      if (crossInstanceParams.datasets) {
        await uploadCrossInstanceDatasets(crossInstanceParams);
        crossInstanceDatasetRef.current = {};
        Object.keys(crossInstanceParams.datasets).forEach((dataset) => {
          crossInstanceDatasetRef.current[dataset] = false;
        });
        setImportTriggered(true);
      } else {
        await completeSuccessfulCrossInstanceImport();
      }
    } else {
      const filename = file?.name;
      const newDataset: UserDataset = {
        name,
        description,
        typename: importType,
        filename,
      };
      const dataset = await saveUserDataset(newDataset, file);
      if (dataset?.system?.status === 'error') {
        setValidationError(dataset?.system?.error);
        setFile(undefined);
        setAlertType(ALERT_TYPES.error);
      } else {
        onNewDataset(dataset);
      }
    }
  }
  if (loading || importTriggered) {
    return <Spinner ratio={3} />;
  }

  return (
    <div className="uk-margin-small-top uk-form">
      {file && importType !== 'cross-instance' && alertType !== ALERT_TYPES.error && (
        <Fragment>
          <label className="input-label">Data Set Name</label>
          <input
            className="uk-input uk-margin-small-bottom"
            defaultValue={name}
            onChange={onNameChange}
            placeholder="Enter a name for this dataset"
          />
          <label className="input-label">Description</label>
          <label className="input-sublabel"> (optional)</label>
          <textarea
            className="uk-textarea"
            placeholder="Add a description for this Dataset."
            rows={4}
            defaultValue={description}
            onChange={onDescriptionChange}
          />
        </Fragment>
      )}
      <Fragment>
        <div className="uk-margin-medium upload-confirmation">
          {file ? (
            <div className="uk-padding-small file-selection">
              <Attachment style={{ width: '40px', height: '40px' }} />
              <div
                className="uk-margin-small-left"
                style={{ width: '380px', overflowWrap: 'anywhere' }}
              >
                {file?.name}
                <div className="file-size">{humanizeFileSize(file.size)}</div>
              </div>
              <button
                style={{ transform: 'translate(0, -7px)', marginLeft: 'auto' }}
                className="uk-button uk-padding-remove uk-float-right"
                onClick={() => onFileSelect(undefined)}
              >
                <DeleteIcon width={20} height={20} style={{ marginLeft: 'auto' }} />
              </button>
            </div>
          ) : (
            <FileSelector
              onSelect={(file) => {
                setLoading(true);
                onFileSelect(file).then(() => {
                  setLoading(false);
                });
              }}
              dropZone={true}
            />
          )}
        </div>
        {validationError && (
          <Banner
            message={validationError}
            alertType={alertType ?? ALERT_TYPES.error}
            title={alertType === ALERT_TYPES.error ? 'Import Error' : 'Import Warning'}
            style={{ marginTop: '16px' }}
            noClose={true}
          />
        )}
        {file && name && alertType !== ALERT_TYPES.error && (
          <div className="uk-text-right uk-margin-top">
            <button className="uk-button uk-button-default uk-float-right" onClick={onImport}>
              Import
            </button>
          </div>
        )}
      </Fragment>
    </div>
  );
}
