import { ReactComponent as ToggleOn } from 'svg/selection/toggle-on.svg';
import { ReactComponent as ToggleOff } from 'svg/selection/toggle-off.svg';
import React from 'react';
import { components } from 'react-select';

export function MenuListFooterToggle({ children, ...props }: any) {
  const title = props?.selectProps?.footerTitle;
  const onChange = props?.selectProps?.setFooterToggled;
  const isToggled = props?.selectProps?.footerToggled;
  return (
    <div>
      <components.MenuList {...props}>{children}</components.MenuList>
      <div className="uk-flex uk-flex-between asset-type-select-footer">
        <div>{title}</div>
        <button onClick={onChange}>{isToggled ? <ToggleOn /> : <ToggleOff />}</button>
      </div>
    </div>
  );
}
