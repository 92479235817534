import {
  constructPrunedColumnConfig,
  getColumnIdentifier,
  NewTableColumn,
  repopulateColumnConfig,
} from 'utils';
import { useCallback, useContext, useMemo } from 'react';
import { ComponentPluginContext } from 'appContexts';

export function useNewTableColumns(
  columnOptions: NewTableColumn[],
  tabId?: string,
  isAssetPageConfig?: boolean,
) {
  const { getPluginConfig, updatePluginConfig } = useContext(ComponentPluginContext);

  const onUpdateColumnConfig = useCallback(
    (columns: NewTableColumn[]) => {
      let columnConfig = constructPrunedColumnConfig(columns, isAssetPageConfig);
      let newConfig;
      if (tabId) {
        newConfig = { ...getPluginConfig(), [tabId]: { columns: columnConfig } };
      } else {
        newConfig = { ...getPluginConfig(), columns: columnConfig };
      }
      updatePluginConfig(newConfig, true);
    },
    [getPluginConfig, updatePluginConfig, tabId, isAssetPageConfig],
  );

  // Refresh the columns with any config info not saved in the url/serialization
  const columns = useMemo(() => {
    const configColumns = tabId ? getPluginConfig()[tabId]?.columns : getPluginConfig()?.columns;
    return repopulateColumnConfig(configColumns, columnOptions);
  }, [columnOptions, getPluginConfig, tabId]);

  const corruptColumns = useMemo(() => {
    if (columnOptions.length > 0) {
      const columnOptionsIDs = columnOptions.map((column: any) => getColumnIdentifier(column));
      return columns
        .filter((column: any) => !columnOptionsIDs.includes(getColumnIdentifier(column)))
        .map((column: any) => column.Header);
    }
    return [];
  }, [columnOptions, columns]);

  return { onUpdateColumnConfig, columns, corruptColumns };
}
