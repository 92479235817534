import { ReactNode } from 'react';
import { ComponentPluginName } from 'components';
import { AdvancedQuery, AssetType } from 'model';

export type ComponentProps = {
  title: string;
  tooltip?: ReactNode;
  description?: string;
  pluginName: ComponentPluginName;
  params: Record<string, any>;
};

export type QueryComponentProps = ComponentPluginProps & {
  setRenderedQuery?: Function;
  renderedQuery?: AdvancedQuery;
};

export type ComponentPluginProps = ComponentProps & {
  className?: string;
  style?: any;
};

export type Attribute = {
  label: string;
  value: any;
  advancedQuery?: any;
  forcedType?: AssetType;
  sublabel?: string;
  description?: string;
  rowClass?: string;
  valueClass?: string;
  diff?: boolean;
  expanded?: boolean;
};

const loadedPlugins: Record<string, any> = {};

export async function loadComponentPlugin(path: string) {
  let component = loadedPlugins[path];
  if (component) {
    return component;
  }

  component = await import(
    /* webpackChunkName: "component-plugins" */ `components/plugins/${path}`
  );

  loadedPlugins[path] = component;
  return component;
}
