import React, { ChangeEvent, useState, Fragment, useContext } from 'react';
import { ComponentPluginContextValue, KebabContext } from 'appContexts';
import { getGraphQLClient } from 'gql';
import { ModalSpinner } from 'components';
import { print } from 'graphql/language/printer';
import { UserProfile } from 'hooks';
import { CAPPED_QUERIES, exportJsonToFile, MAX_EXPORT_QUERY_SIZE } from 'utils';

type ExportedData = {
  user: UserProfile | undefined;
  timestamp: string;
  sections: any[];
};

type DataExportFormProps = {
  pluginContexts: Record<string, ComponentPluginContextValue>;
  defaultFilename: string;
  userProfile: UserProfile | undefined;
  setMenu?: Function;
  onMenuCancel?: Function;
};

export function DataExportForm({
  pluginContexts,
  defaultFilename,
  userProfile,
  setMenu,
  onMenuCancel,
}: DataExportFormProps) {
  const [filename, setFilename] = useState<string>(`${defaultFilename}.json`);

  const { returnKebabMain, closeKebabDropdown } = useContext(KebabContext);

  function onExport() {
    ModalSpinner.blockWhileExecuting(
      async () => await performExport(pluginContexts, filename, userProfile),
    );
  }

  function onFilenameChange(event: ChangeEvent<HTMLInputElement>) {
    setFilename(event.target.value);
  }

  return (
    <Fragment>
      {(setMenu || returnKebabMain) && (
        <div className="uk-flex uk-modal-title uk-flex-middle uk-margin-medium-bottom">
          <button
            onClick={() => (setMenu ? setMenu('Main') : returnKebabMain())}
            className="tile-menu-back"
          >
            <div uk-icon="icon: chevron-left;" />
            Tile Menu
          </button>
          <span className="uk-text-bold menu-header">Download Data</span>
        </div>
      )}
      <input
        className="uk-input"
        placeholder="Enter filename"
        value={filename}
        spellCheck="false"
        onChange={onFilenameChange}
      />
      <div className="uk-margin-top uk-text-right">
        {(onMenuCancel || closeKebabDropdown) && (
          <button
            type="button"
            className="uk-button"
            onClick={() => (onMenuCancel ? onMenuCancel() : closeKebabDropdown())}
          >
            Cancel
          </button>
        )}
        <button
          type="button"
          className={'uk-button uk-margin-left uk-button-default'}
          onClick={onExport}
        >
          Download
        </button>
      </div>
    </Fragment>
  );
}

async function performExport(
  pluginContexts: Record<string, ComponentPluginContextValue>,
  filename: string,
  userProfile: UserProfile | undefined,
) {
  const client = getGraphQLClient();
  const pluginContextList = Object.values(pluginContexts);
  const exportedData: ExportedData = {
    user: userProfile,
    timestamp: new Date().toISOString(),
    sections: [],
  };

  for (let i = 0; i < pluginContextList.length; i++) {
    const graphQLQueryList = Object.values(pluginContextList[i].graphQLQueries);
    const pluginContext = pluginContextList[i];

    for (let j = 0; j < graphQLQueryList.length; j++) {
      const graphQLQuery = graphQLQueryList[j];
      const query = graphQLQuery.query;
      let variables = graphQLQuery.variables;
      const definition = query.definitions[0];
      const queryName = definition.name.value;
      const dataName = definition.selectionSet.selections[0].name.value;

      const limitQuerySize = CAPPED_QUERIES.includes(queryName);

      const fetchPolicy = variables.cursor ? 'no-cache' : 'cache-first';
      if (variables.size !== undefined || variables.cursor !== undefined) {
        variables = {
          ...variables,
          size: limitQuerySize ? 25 : MAX_EXPORT_QUERY_SIZE,
          cursor: null,
        };
      }
      const result = await client.query({ query, variables, fetchPolicy });
      const data = result.data ? result.data[dataName] : {};

      exportedData.sections.push({
        sectionTitle: pluginContext.title,
        sectionDescription: pluginContext.description,
        pluginName: pluginContext.pluginName,
        queryName,
        query: print(query).split('\n'),
        variables,
        data,
      });
    }
  }
  const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
  exportJsonToFile(exportedData, filename, omitTypename, 2);
}
