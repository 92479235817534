import React, {
  ChangeEvent,
  Children,
  Fragment,
  FunctionComponent,
  ReactNode,
  Suspense,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import {
  ALERT_TYPES,
  ComponentProps,
  UserInvestigation,
  UserInvestigationSection,
  userInvestigationsVar,
} from 'model';
import {
  InvestigationTypes,
  copyTilesToMosaic,
  getDefaultSectionTitle,
  getDefaultTitle,
  isTemplate,
  isViewOnlyTemplate,
  sectionContainsDatasetReference,
  getTemplateSectionTitle,
  toInvestigationSectionId,
  makeUniqueId,
  prepareImagesForCopy,
} from 'utils';
import { Banner, InvestigationSelector, Spinner } from 'components';
import { KebabContext, SharedContext } from 'appContexts';
import MarkdownEditor from '../markdown/MarkdownEditor';
import { ReactComponent as ToggleOn } from 'svg/selection/toggle-on.svg';
import { ReactComponent as ToggleOff } from 'svg/selection/toggle-off.svg';

export function AddToInvestigationKebabMenu({
  pluginName,
  params,
  title,
  description,
}: ComponentProps) {
  const history = useHistory();

  const investigations = useReactiveVar(userInvestigationsVar);

  const isAnyTemplate = useMemo(() => {
    return isTemplate() || isViewOnlyTemplate();
  }, []);

  const sortInvestigationByDate = useCallback((a, b) => {
    const dateA = (a.saved as Date).getTime();
    const dateB = (b.saved as Date).getTime();
    return dateB - dateA;
  }, []);

  const mosaicOptions = useMemo(() => {
    return Object.values(investigations)
      .filter((investigation) => !investigation.isTemplate)
      .sort(sortInvestigationByDate);
  }, [investigations, sortInvestigationByDate]);

  const templateOptions = useMemo(() => {
    return Object.values(investigations)
      .filter((investigation) => investigation.isTemplate)
      .sort(sortInvestigationByDate);
  }, [investigations, sortInvestigationByDate]);
  const { sharedKey } = useContext(SharedContext);
  const { returnKebabMain, closeKebabDropdown, reactSelectClicked } = useContext(KebabContext);
  const [selectedInvestigation, setSelectedInvestigation] = useState<UserInvestigation | undefined>(
    isAnyTemplate ? templateOptions[0] : mosaicOptions[0],
  );
  const [selectedName, setSelectedName] = useState<string | undefined>(
    selectedInvestigation?.title ?? 'newMosaic',
  );
  const [goToInvestigation, setGoToInvestigation] = useState<boolean>(true);
  const [investigationTitle, setInvestigationTitle] = useState<string>(() =>
    getDefaultTitle(isAnyTemplate),
  );
  const [sectionTitle, setSectionTitle] = useState<string>(() =>
    isAnyTemplate ? getTemplateSectionTitle(pluginName) : getDefaultSectionTitle(title, params),
  );
  const [sectionDescription, setSectionDescription] = useState<string>(description);

  function onInvestigationTitleChange(event: ChangeEvent<HTMLInputElement>) {
    setInvestigationTitle(event.target.value);
  }

  async function onAdd() {
    const mainSection: UserInvestigationSection = {
      id: toInvestigationSectionId(pluginName, params),
      title: sectionTitle,
      description: sectionDescription,
      saved: new Date(),
    };
    const isNew = selectedName === 'newMosaic';
    const newInvestigationId = isNew ? makeUniqueId('investigation') : undefined;
    const newImages = await prepareImagesForCopy(
      isNew ? { id: newInvestigationId, images: [] } : selectedInvestigation,
      sectionDescription,
      sharedKey,
    );
    const investigation = copyTilesToMosaic(
      [mainSection],
      isNew
        ? { value: 'newMosaic', label: 'newMosaic' }
        : { value: selectedInvestigation.id, label: selectedInvestigation.title },
      investigationTitle,
      newImages,
      newInvestigationId,
    );
    if (investigation) {
      closeKebabDropdown();
      if (goToInvestigation) {
        const shortID = (investigation.id as string).split(':')[1];
        history.push(`/${isAnyTemplate ? 'template' : 'mosaic'}?id=${shortID}`);
      }
    }
  }

  function onSelectInvestigation(investigation: any) {
    if (investigation.value === 'newMosaic') {
      setSelectedName('newMosaic');
    } else {
      setSelectedInvestigation(investigations[investigation.value]);
      setSelectedName(investigation.value);
    }
  }

  function onMenuOpen() {
    if (reactSelectClicked) {
      reactSelectClicked.current = true;
    }
  }

  function onMenuClose() {
    if (reactSelectClicked) {
      reactSelectClicked.current = false;
    }
  }

  const isDatasetSection = useMemo(() => {
    return sectionContainsDatasetReference(params, isAnyTemplate, true);
  }, [params, isAnyTemplate]);

  return (
    <Fragment>
      <div className="uk-flex uk-flex-middle uk-margin-bottom">
        <button onClick={() => returnKebabMain()} className="tile-menu-back">
          <div uk-icon="icon: chevron-left;" />
          Tile Menu
        </button>
        <span className="uk-text-bold menu-header">
          {`Copy to ${isAnyTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic}`}
        </span>
      </div>
      <InvestigationSelector
        onChange={onSelectInvestigation}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        copyTarget={isAnyTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic}
      />
      {selectedName === 'newMosaic' && (
        <Fragment>
          <label className="input-label">{`${isAnyTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic} Title`}</label>
          <input
            className="uk-input uk-margin-bottom"
            value={investigationTitle}
            onChange={onInvestigationTitleChange}
          />
        </Fragment>
      )}
      <Fragment>
        <label className="input-label">Title</label>
        <input
          className="uk-input uk-margin-bottom"
          value={sectionTitle}
          onChange={(ev) => setSectionTitle(ev.target.value)}
        />
        <PopupMenuStep title="Add notes to this tile?">
          <Suspense fallback={<Spinner />}>
            <span className="input-label">
              {' '}
              <label>Notes</label>
              <label className="input-sublabel"> (optional)</label>
            </span>
            <MarkdownEditor
              value={sectionDescription}
              onChange={(newDesc) => setSectionDescription(newDesc)}
            />
          </Suspense>
        </PopupMenuStep>
      </Fragment>
      {isDatasetSection && (
        <Banner
          message={
            'Datasets which you do not own cannot currently be copied in individual tiles. Copy the whole mosaic to view this dataset.'
          }
          title={'Copy Warning'}
          alertType={ALERT_TYPES.warning}
          noClose={true}
        />
      )}
      <div className="uk-flex uk-flex-middle uk-margin-large-top">
        <label className="uk-margin-top app-cursor-pointer">
          <input
            type="checkbox"
            className="uk-checkbox uk-margin-right"
            onChange={() => setGoToInvestigation(!goToInvestigation)}
            checked={goToInvestigation}
          />
          {`Go To ${isAnyTemplate ? InvestigationTypes.Template : InvestigationTypes.Mosaic}`}
        </label>
        <div className="uk-margin-auto-left">
          <button type="button" className="uk-button" onClick={() => closeKebabDropdown()}>
            Cancel
          </button>
          <button
            type="button"
            className={'uk-button uk-modal-close uk-margin-small-left uk-button-default'}
            onClick={onAdd}
          >
            Add
          </button>
        </div>
      </div>
    </Fragment>
  );
}

type PopupMenuProps = {
  title: string;
  onToggle?: Function;
  children?: ReactNode;
};

const PopupMenuStep: FunctionComponent<PopupMenuProps> = ({ title, onToggle, children }) => {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [expanded, setExpanded] = useState(true);
  return (
    <div className="uk-margin-top">
      <div className="uk-flex-inline" style={{ width: '100%' }}>
        {isToggled && children != null ? (
          <button
            className={'uk-margin-right'}
            data-uk-icon={`icon: chevron-${expanded ? 'up' : 'down'}`}
            onClick={() => setExpanded((current) => !current)}
          />
        ) : null}
        <span className={isToggled ? '' : 'uk-text-muted'}>{title}</span>
        {
          <div className="uk-flex-inline" style={{ marginLeft: 'auto' }}>
            <button
              onClick={() => {
                onToggle && onToggle(!isToggled);
                setIsToggled(!isToggled);
              }}
              style={{ paddingRight: 0 }}
            >
              {isToggled ? <ToggleOn /> : <ToggleOff />}
            </button>
          </div>
        }
      </div>
      {isToggled && expanded && children != null ? (
        <div className="uk-flex uk-flex-column uk-margin-top uk-margin-medium-bottom">
          <Fragment>
            {Children.map(children, (child: ReactNode) => (
              <div>{child}</div>
            ))}
          </Fragment>
        </div>
      ) : null}
    </div>
  );
};
