import React, {
  ReactNode,
  MouseEvent,
  useEffect,
  useState,
  useRef,
  useMemo,
  FunctionComponent,
} from 'react';
import UIkit from 'uikit';

const horizMargin = '20px';
const zIndex = 600;

export type PopupProps = {
  event?: MouseEvent;
  children?: ReactNode;
};

export const Popup: FunctionComponent<PopupProps> = ({ event, children }) => {
  const offcanvasRef = useRef<HTMLDivElement | null>(null);
  const [showContents, setShowContents] = useState(false);
  const timeoutIdRef = useRef<number>();
  const style: Record<string, any> = useMemo(() => {
    const docStyle = getComputedStyle(document.documentElement);
    const top = parseInt(docStyle.getPropertyValue('--app-header-height')) + 10;
    const style = {
      top,
      left: 'auto',
      right: horizMargin,
      position: 'fixed',
      display: 'block',
      width: '300px',
      zIndex,
      height: 'fit-content',
    };
    if (event) {
      const browserWidth = document.body.clientWidth;
      if (browserWidth && event.target) {
        const x = (event.target as any).getBoundingClientRect().right;
        if (x > browserWidth / 1.7) {
          style.left = horizMargin;
          style.right = 'auto';
        }
      }
    }
    return style;
  }, [event]);

  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [timeoutIdRef]);

  useEffect(() => {
    if (offcanvasRef?.current) {
      const offcanvas = UIkit.offcanvas(offcanvasRef.current);
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = undefined;
      }

      offcanvas.hide();
      setShowContents(false);

      if (children) {
        timeoutIdRef.current = window.setTimeout(() => {
          offcanvas.show();
          setShowContents(true);
        }, 300);
      }
    }
  }, [offcanvasRef, children, timeoutIdRef]);

  return (
    <div
      ref={offcanvasRef}
      id="app-popup"
      uk-offcanvas="mode: none; esc-close: false; bg-close: false"
      style={style}
    >
      {showContents && (
        <div
          className="uk-offcanvas-bar app-fit-content app-glass-card uk-padding-small"
          style={{ minWidth: '20rem' }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
