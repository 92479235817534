export enum ComponentPluginName {
  IPAbout = 'ip/IPAbout',
  IPReputation = 'ip/IPReputation',
  IPRouting = 'ip/IPRouting',
  IPPorts = 'ip/IPPorts',
  IPDeviceInformation = 'ip/IPDeviceInformation',
  IPInfrastructure = 'ip/IPInfrastructure',
  IPDomainHosts = 'ip/IPDomainHosts',
  IPTracerouteSummary = 'ip/IPTracerouteSummary',
  IPTracerouteEdges = 'ip/IPTracerouteEdges',
  ASNAbout = 'asn/ASNAbout',
  ASNRouting = 'asn/ASNRouting',
  ASNDetailsTable = 'asn/ASNDetailsTable',
  CountryMetrics = 'country/CountryMetrics',
  CountrySummary = 'country/CountrySummary',
  CountryDetailsTable = 'country/CountryDetailsTable',
  DomainRegistry = 'domain/DomainRegistry',
  DomainDNSRecords = 'domain/DomainDNSRecords',
  DomainDNSHosts = 'domain/DomainDNSHosts',
  DomainCerts = 'domain/DomainCerts',
  HostnameAbout = 'hostname/HostnameAbout',
  HostnameResolution = 'hostname/HostnameResolution',
  HostnameSharedHosts = 'hostname/HostnameSharedHosts',
  HostnameCerts = 'hostname/HostnameCerts',
  RouterAbout = 'router/RouterAbout',
  RouterDeviceInformation = 'router/RouterDeviceInformation',
  RouterTracerouteSummary = 'router/RouterTracerouteSummary',
  RouterIPInterfaces = 'router/RouterIPInterfaces',
  FacilityAbout = 'facility/FacilityAbout',
  FacilityConnections = 'facility/FacilityConnections',
  ExchangeAbout = 'exchange/ExchangeAbout',
  ExchangePrefixes = 'exchange/ExchangePrefixes',
  ExchangeFacilities = 'exchange/ExchangeFacilities',
  ExchangeConnections = 'exchange/ExchangeConnections',
  OrgAbout = 'org/OrgAbout',
  OrgDetailsTable = 'org/OrgDetailsTable',
  PrefixAbout = 'prefix/PrefixAbout',
  PrefixRouting = 'prefix/PrefixRouting',
  PrefixReputation = 'prefix/PrefixReputation',
  PrefixTracerouteEdges = 'prefix/PrefixTracerouteEdges',
  QueryResultsMapView = 'advancedquery/QueryResultsMapView',
  QueryResultsTableView = 'advancedquery/QueryResultsTableView', // legacy
  QueryResultsDataTableView = 'advancedquery/QueryResultsDataTableView',
  QueryViewConfigDataTable = 'advancedquery/QueryViewConfigDataTable', // legacy deprecated
  QueryResultsIPPatternDiscoveryView = 'advancedquery/QueryResultsIPPatternDiscoveryView',
  QueryResultsIPPatternHistogramView = 'advancedquery/QueryResultsIPPatternHistogramView',
  QueryResultsCardsView = 'advancedquery/QueryResultsCardsView',
  QueryResultsHistogramView = 'advancedquery/QueryResultsHistogramView',
  QueryResultsGraphView = 'advancedquery/QueryResultsGraphView',
  QueryResultsPortsView = 'advancedquery/QueryResultsPortsView',
  QueryViewConfigGraph = 'advancedquery/QueryViewConfigGraph',
  QueryKeystoneTile = 'advancedquery/QueryKeystoneTile',
  KeystoneTile = 'KeystoneTile',
  OutputTile = 'OutputTile',
  PrimitiveTile = 'PrimitiveTile',
  UserMarkdown = 'UserMarkdown',
}

export const NonDataPlugins = [ComponentPluginName.UserMarkdown, ComponentPluginName.PrimitiveTile];

export function isNonDataPlugin(name: ComponentPluginName): boolean {
  return NonDataPlugins.includes(name);
}

export const advancedQueryViewToPluginName: Record<string, ComponentPluginName> = {
  ipPatternDiscovery: ComponentPluginName.QueryResultsIPPatternDiscoveryView,
  dataTable: ComponentPluginName.QueryResultsDataTableView,
  ipPatternHistogram: ComponentPluginName.QueryResultsIPPatternHistogramView,
  graph: ComponentPluginName.QueryResultsGraphView,
  map: ComponentPluginName.QueryResultsMapView,
  histogram: ComponentPluginName.QueryResultsHistogramView,
  portsViewer: ComponentPluginName.QueryResultsPortsView,
  cards: ComponentPluginName.QueryResultsCardsView,
};
