import React from 'react';
//import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import 'intersection-observer';
import { initializeModel } from 'model';
import { initializeEmbedded } from 'utils';
import initializeLocalForage from 'localForageSetup';
import { createGraphQLClient } from 'gql';

async function run() {
  initializeLocalForage();
  await initializeEmbedded();
  await initializeModel();
  await createGraphQLClient();
  ReactDOM.render(<App />, document.getElementById('root'));
  //const root = createRoot(document.getElementById('root'));
  //root.render(<App />);
}

run();
