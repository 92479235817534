import React, { Fragment, useContext, useMemo } from 'react';
import ReportTitle from '../ReportTitle';
import { useOrgQuery } from 'hooks';
import { ComponentPluginName, DisplayElement } from 'components';
import { AssetInfoContext } from 'appContexts';
import { UserInvestigationSection } from 'model';
import { toInvestigationSectionId } from 'utils';
import InvestigationSection from '../investigations/InvestigationSection';

export default function OrgInfo({ assetValue }: { assetValue: string }) {
  const { asset } = useOrgQuery(assetValue);
  const { displayElements } = useContext(AssetInfoContext);
  const section: UserInvestigationSection = useMemo(
    () => ({
      id: toInvestigationSectionId(ComponentPluginName.OrgAbout, { assetValue }),
      title: 'About',
      description: '',
    }),
    [assetValue],
  );

  return (
    <Fragment>
      {asset && (
        <Fragment>
          {displayElements?.includes(DisplayElement.Title) && (
            <ReportTitle title={assetValue} label="Organization" />
          )}
          {displayElements?.includes(DisplayElement.About) && (
            <InvestigationSection section={section} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
