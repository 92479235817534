import { gql } from '@apollo/client';
import { useAssetQuery } from 'hooks';
import { AssetType } from 'model';

const countryQuery = gql`
  query CountryAboutData($countrySet: [String]!) {
    countryData(countrySet: $countrySet) {
      id
      country
      countryRecords {
        id
        name
        internetRank
        internetOriginsRank
        internetMarketRelevanceRank
        internetRoutingDiversityByasRank
      }
    }
  }
`;

export function useCountryQuery(assetValue: string) {
  const { asset, loading, error } = useAssetQuery(AssetType.Country, assetValue, countryQuery);
  return { asset, loading, error };
}
