import { select } from 'd3';
import { margin } from './Icicle';

const docStyle = getComputedStyle(document.documentElement);
const highlightColor = docStyle.getPropertyValue('--app-primary-color');
const fadedColor = '#BCBEC0'; // French Gray

type IcicleProps = {
  container: HTMLElement;
  root: any;
  setSequence: any;
  filterMatch: any;
  colors: any;
  icicleSizing: any;
  setClicked: any;
  initialSequence?: any;
};

export default function drawIcicle({
  container,
  root,
  setSequence,
  filterMatch,
  colors,
  icicleSizing,
  setClicked,
  initialSequence,
}: IcicleProps) {
  const containerSel = select(container);
  containerSel.select('svg').remove();
  const { height, width } = icicleSizing;

  const svg = containerSel
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .style('overflow', 'visible');

  // Segment functions
  const segmentWidth = (d: any) => d.y1 - d.y0;
  const segmentX = (d: any) => d.y0;
  const segmentY = (d: any) => d.x0;
  const segmentHeight = (d: any) => d.x1 - d.x0;
  const color = (node: any, sequence: any) => {
    const isHighlighted = sequence.find(
      (d: any) =>
        d.data.name === node.data.name && d.depth === node.depth && d.height === node.height,
    );
    if (isHighlighted) {
      return highlightColor;
    }
    // Handle fields that might share same name
    // ex: country|Hong Kong vs. city|Hong Kong
    const nodeName = node.data.name.join('|');
    return colors[nodeName] ? colors[nodeName].color : fadedColor;
  };
  const opacity = (node: any, sequence: any) => {
    if (sequence.length === 0) {
      return 1;
    }
    return sequence.find(
      (d: any) =>
        d.data.name === node.data.name && d.depth === node.depth && d.height === node.height,
    )
      ? 1
      : 0.3;
  };

  const hasFilterMatch = filterMatch.length > 0;

  // Pattern Segments
  const pattern = svg.append('g');

  // Don't draw the root node, and for efficiency, filter out nodes that would be too small to see
  const filteredData = root.descendants().filter((d: any) => {
    return d.depth && segmentWidth(d) >= 0.1;
  });

  pattern
    .attr('transform', `translate(${-root.y1 + margin.left}, ${margin.top})`)
    .selectAll('rect')
    .data(filteredData)
    .join('rect')
    .attr('fill', (d: any) => color(d, hasFilterMatch ? filterMatch : initialSequence))
    .attr('fill-opacity', (d: any) => opacity(d, hasFilterMatch ? filterMatch : initialSequence))
    .attr('x', segmentX)
    .attr('y', segmentY)
    .attr('rx', 2)
    .attr('ry', 2)
    .attr('width', segmentWidth)
    .attr('height', segmentHeight)
    .attr('class', 'pattern-segment');

  // Y-Axis
  svg
    .append('line')
    .style('stroke', 'black')
    .attr('x1', margin.left - 20)
    .attr('y1', margin.top)
    .attr('x2', margin.left - 10)
    .attr('y2', margin.top);
  svg
    .append('line')
    .style('stroke', 'black')
    .attr('x1', margin.left - 20)
    .attr('y1', margin.top)
    .attr('x2', margin.left - 20)
    .attr('y2', height - margin.bottom);
  svg
    .append('line')
    .style('stroke', 'black')
    .attr('x1', margin.left - 20)
    .attr('y1', height - margin.bottom)
    .attr('x2', margin.left - 10)
    .attr('y2', height - margin.bottom);
  svg
    .append('text')
    .attr('text-anchor', 'middle')
    .text('Metadata')
    .attr('transform', 'rotate(-90)')
    .attr('x', -height / 2)
    .attr('y', margin.left - 30);

  // X-Axis
  svg
    .append('line')
    .style('stroke', 'black')
    .attr('x1', margin.left)
    .attr('y1', margin.top - 20)
    .attr('x2', margin.left)
    .attr('y2', margin.top - 10);
  svg
    .append('line')
    .style('stroke', 'black')
    .attr('x1', margin.left)
    .attr('y1', margin.top - 20)
    .attr('x2', width - margin.right - root.y1)
    .attr('y2', margin.top - 20);
  svg
    .append('line')
    .style('stroke', 'black')
    .attr('x1', width - margin.right - root.y1)
    .attr('y1', margin.top - 20)
    .attr('x2', width - margin.right - root.y1)
    .attr('y2', margin.top - 10);
  svg
    .append('text')
    .attr('text-anchor', 'middle')
    .text('Fingerprints')
    .attr('x', (width - margin.right) / 2 + root.y1 / 2)
    .attr('y', margin.top - 30);

  // Legend
  const legendX = width - margin.right + 20;
  const legendItems = Object.entries(colors)
    .map((entry: any) => {
      return { ...entry[1], name: entry[0].split(/\|(.*)/s)[1] };
    })
    .sort((a, b) => b.value - a.value);
  const legendItemSize = 20;

  const legend = svg.append('g').attr('transform', `translate(${legendX}, ${margin.top})`);

  //Legend Header
  svg
    .append('g')
    .attr('transform', `translate(${legendX}, ${margin.top})`)
    .append('text')
    .attr('x', 0)
    .attr('y', 16)
    .attr('class', 'uk-text-bold')
    .text('Item Frequency (%)');

  // Legend Items Color Squares
  legend
    .selectAll('rect')
    .data(legendItems)
    .join('rect')
    .attr('fill', (d: any) => d.color)
    .attr('x', 0)
    .attr('y', (d: any, i) => i * (legendItemSize + 10) + (legendItemSize + 10))
    .attr('rx', 2)
    .attr('ry', 2)
    .attr('width', legendItemSize)
    .attr('height', legendItemSize);

  // Legend Items Percentage
  const legendText = legend.selectAll('text').data(legendItems).enter();
  legendText
    .append('text')
    .attr('fill', 'white')
    .attr('font-size', 12)
    .attr('x', legendItemSize / 2)
    .attr('y', (d: any, i) => i * (legendItemSize + 10) + (legendItemSize + 25))
    .attr('text-anchor', 'middle')
    .text((d: any) => d.value);

  // Legend Items Labels
  legendText
    .append('text')
    .attr('fill', '#777777')
    .attr('x', 25)
    .attr('y', (d: any, i) => i * (legendItemSize + 10) + (legendItemSize + 25))
    .text((d: any) => d.name);

  const updateSequence = (sequence: any[]) => {
    pattern
      .selectAll('rect')
      .attr('fill', (d: any) => color(d, sequence))
      .attr('fill-opacity', (d: any) => opacity(d, sequence));
    setSequence(sequence);
  };

  const segments = svg.selectAll('.pattern-segment');

  let isClicked = initialSequence.length > 0;

  if (isClicked) {
    updateSequence(initialSequence);
  }

  // Pattern segment mouseover highlight
  segments.on('mouseenter', (event: any, d: any) => {
    // Get the ancestors of the current segment, minus the root
    const newSequence = d.ancestors().reverse().slice(1);
    filterMatch.length === 0 && !isClicked && updateSequence(newSequence);
  });

  //Click segment to persist tooltip
  segments.on('click', (event: any, d: any) => {
    const newSequence = d.ancestors().reverse().slice(1);

    if (filterMatch.length === 0) {
      updateSequence(newSequence);
      isClicked = true;
      setClicked(true);
    }
  });

  //Click to remove segment click
  containerSel.on('click', (event: any, d: any) => {
    if (filterMatch.length === 0 && event.target.className.baseVal !== 'pattern-segment') {
      updateSequence([]);
      isClicked = false;
      setClicked(false);
    }
  });

  //Remove highlight when icicle not moused over anymore
  containerSel.on('mouseleave', () => {
    filterMatch.length === 0 && !isClicked && updateSequence([]);
  });
}
