import React, { Fragment, useState } from 'react';
import { AdvancedSearchLink } from './AdvancedSearchLink';
import { AssetLink } from 'components';
import { Attribute } from 'model';

export function AttributeTable({
  attributes,
  diffsMode,
}: { attributes: Attribute[]; diffsMode?: boolean }) {
  const tableClass = diffsMode ? 'app-info-table-small' : '';
  attributes = diffsMode ? formatDiffsAttributes(attributes) : flattenNestedAttributes(attributes);
  const hasSublabels = attributes.filter((attribute) => attribute.sublabel).length > 0;

  return (
    <div className={`app-info-table ${tableClass}`}>
      <table className="uk-table">
        <tbody>
          {attributes.map((attribute: Attribute, i) => {
            const colspan = attribute.expanded ? 100 : 1;
            const valueClass = attribute.valueClass ?? '';
            return (
              <Fragment key={`${attribute.label}-${attribute.sublabel}-${i}`}>
                <tr className={attribute.rowClass}>
                  {!attribute.expanded && (
                    <Fragment>
                      <td>{attribute.label}</td>
                      {hasSublabels &&
                        (attribute.sublabel ? (
                          <td className="app-info-table-sublabel">{attribute.sublabel}</td>
                        ) : (
                          <td></td>
                        ))}
                    </Fragment>
                  )}
                  <td
                    className={`${valueClass}${!attribute.value ? 'app-attribute-value-empty' : ''}`}
                    colSpan={colspan}
                  >
                    {attribute?.advancedQuery ? (
                      <AdvancedSearchLink
                        aq={attribute.advancedQuery}
                        linkValue={attribute.value}
                      />
                    ) : attribute?.forcedType ? (
                      <AssetLink value={attribute.value} forcedType={attribute?.forcedType} />
                    ) : (
                      valueCell(attribute.value)
                    )}
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AttributeTable;

export function formatDiffsAttributes(attributes: Attribute[]): Attribute[] {
  function rowClasses(row: Attribute) {
    const rowDiffClass = row.diff ? 'app-row-highlight' : 'app-row-faded';
    const marginClass = row.label ? 'app-info-table-section' : '';
    return `${rowDiffClass} ${marginClass}`;
  }
  return flattenNestedAttributes(attributes).map((a) => ({
    ...a,
    rowClass: rowClasses(a),
  }));
}

export function flattenNestedAttributes(attributes: Attribute[]) {
  const flattened: Attribute[] = [];
  attributes.forEach((a) => {
    if (isNestedAttributes(a.value)) {
      const subAttrs = a.value as Attribute[];
      subAttrs.forEach((sa, i) => {
        sa = { ...sa }; // Shallow copy to prevent modifying original sub-attribute
        sa.sublabel = sa.label;
        sa.label = i === 0 ? a.label : '';
        flattened.push(sa);
      });
    } else {
      flattened.push(a);
    }
  });
  return flattened;
}

export function isNestedAttributes(value: any) {
  return Array.isArray(value) && value[0]?.label;
}

function valueCell(value: any) {
  if (Array.isArray(value)) {
    return <AttributeValueList value={value} />;
  } else {
    const len = value?.length;
    if (len > 300) {
      return <ExpandableValue value={value} />;
    } else if (len === 0) {
      return <span>&nbsp;</span>;
    } else {
      return value;
    }
  }
}

function AttributeValueList({ value }: { value: any[] }) {
  return (
    <ul className="uk-list uk-list-collapse uk-margin-remove">
      {value.map((v: any, i: number) => (
        <li key={i}>{v}</li>
      ))}
    </ul>
  );
}

function ExpandableValue({ value }: { value: string }) {
  const [isExpanded, setIsExpanded] = useState(false);
  return value.length > 300 ? (
    <span>
      {isExpanded ? value : value.substring(0, 300)};
      <button onClick={() => setIsExpanded((curr) => !curr)} className="uk-link">
        {isExpanded ? 'See Less' : 'See More'}
      </button>
    </span>
  ) : (
    <span>value</span>
  );
}
