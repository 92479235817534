import {
  capitalizeFirstLetter,
  convertGQLStringToJSONObject,
  convertJSONObjectToGQLString,
  formatGeoLabel,
} from 'utils';
import { ComponentPluginName, StoredColumnFields } from 'components';
import { cloneDeep, get, isObject } from 'lodash';
import { Column } from 'react-table';
import { filterClauseOptionRegistry, QueryFilterClause } from 'model';
import {
  asnIpV4DownstreamsOptions,
  asnIpV4UpstreamsOptions,
  asnItem,
  asnV4PrefixOptions,
  asnV6PrefixOptions,
  countryRouterOptions,
  exchangeOptions,
  facilityOptions,
  certsOptions,
  hostnameSharedHostsOptions,
  interfaceItem,
  providersOptions,
  queriedInterfaceItem,
  routerDeviceItem,
  routerIdItem,
  routerInterfacesOptions,
  asnRouterOptions,
  dnsHostsOptions,
  peersOptions,
  exchangeFacilitiesOptions,
  routerOptions,
  facilityExchangeOptions,
  facilityPeersOptions,
  ipDomainHostsOptions,
  ipTracerouteEdgesOptions,
  orgASNOptions,
  orgPrefixOptions,
  orgRouterOptions,
  prefixReputationOption,
} from 'components/TableItems';

export type TableColumn = {
  name: string;
  selector: any;
  cell?: any;
  maxWidth?: string;
  minWidth?: string;
  width?: string;
};

export type NewTableColumn = Column & {
  sortable?: boolean;
  active?: boolean;
  filterType?: string;
  clauseType?: string;
  columnFilterClauses?: QueryFilterClause[];
  formatFunction?: any; // For formatting data
  aggField?: string;
  comparator?: string;
  localFilter?: boolean;
  partialMatchFilter?: boolean; // Whether the local filter should accept partial matches,
  filterEnabled?: boolean;
  formatLabel?: any;
  subAccessor?: any; // Accessor for cell subtitles
  aggAccessor?: string; // Accessor for agg data
  showCount?: boolean; // Whether the local filter should show count in dropdown options
};

export const ASSET_REPORT_BUCKET_COUNT = 15;

export const WIDE_PRINT_COMPONENTS = [
  ComponentPluginName.IPDomainHosts,
  ComponentPluginName.IPTracerouteEdges,
  ComponentPluginName.ASNDetailsTable,
  ComponentPluginName.CountryDetailsTable,
  ComponentPluginName.DomainCerts,
  ComponentPluginName.DomainDNSHosts,
  ComponentPluginName.HostnameSharedHosts,
  ComponentPluginName.HostnameCerts,
  ComponentPluginName.RouterIPInterfaces,
  ComponentPluginName.FacilityConnections,
  ComponentPluginName.ExchangeConnections,
  ComponentPluginName.OrgDetailsTable,
  ComponentPluginName.PrefixReputation,
  ComponentPluginName.PrefixTracerouteEdges,
  ComponentPluginName.QueryResultsDataTableView,
  ComponentPluginName.QueryResultsTableView,
  ComponentPluginName.DomainDNSRecords,
  ComponentPluginName.DomainRegistry,
  ComponentPluginName.HostnameResolution,
  ComponentPluginName.IPInfrastructure,
];

export const filterRouterColumnOptions = transformToNewColumns([
  { ...routerIdItem, filterType: 'string', clauseType: 'routerId' },
  asnItem,
  { ...routerDeviceItem, filterType: 'string', clauseType: 'vendor.name' },
  interfaceItem,
  queriedInterfaceItem,
]);

type TableConfigOption = {
  tabId?: string;
  columns: NewTableColumn[];
};

export const TableColumnConfigOptionsByPlugin: Record<string, Record<string, TableConfigOption>> = {
  [ComponentPluginName.ASNDetailsTable]: {
    'IPv4 Upstreams': {
      tabId: 'ipv4-upstreams',
      columns: transformToNewColumns(asnIpV4UpstreamsOptions),
    },
    'IPv4 Downstreams': {
      tabId: 'ipv4-downstreams',
      columns: transformToNewColumns(asnIpV4DownstreamsOptions),
    },
    'IPv4 Prefixes': { tabId: 'ipv4-prefixes', columns: transformToNewColumns(asnV4PrefixOptions) },
    'IPv6 Prefixes': { tabId: 'ipv6-prefixes', columns: transformToNewColumns(asnV6PrefixOptions) },
    Routers: { tabId: 'Routers', columns: transformToNewColumns(asnRouterOptions) },
  },
  [ComponentPluginName.CountryDetailsTable]: {
    Routers: { tabId: 'Routers', columns: transformToNewColumns(countryRouterOptions) },
    Providers: { tabId: 'Providers', columns: transformToNewColumns(providersOptions) },
    Exchanges: { tabId: 'Exchanges', columns: transformToNewColumns(exchangeOptions) },
    Facilities: { tabId: 'Facilities', columns: transformToNewColumns(facilityOptions) },
  },
  [ComponentPluginName.DomainCerts]: { certs: { columns: transformToNewColumns(certsOptions) } },
  [ComponentPluginName.DomainDNSHosts]: { hosts: { tabId: 'hosts', columns: dnsHostsOptions } },
  [ComponentPluginName.ExchangeConnections]: {
    peers: { tabId: 'peers', columns: transformToNewColumns(peersOptions) },
    facilities: { tabId: 'facilities', columns: transformToNewColumns(exchangeFacilitiesOptions) },
    Routers: { tabId: 'Routers', columns: transformToNewColumns(routerOptions) },
  },
  [ComponentPluginName.FacilityConnections]: {
    exchanges: { tabId: 'exchanges', columns: transformToNewColumns(facilityExchangeOptions) },
    peers: { tabId: 'peers', columns: transformToNewColumns(facilityPeersOptions) },
    Routers: { tabId: 'Routers', columns: transformToNewColumns(routerOptions) },
  },
  [ComponentPluginName.HostnameCerts]: { certs: { columns: transformToNewColumns(certsOptions) } },
  [ComponentPluginName.HostnameSharedHosts]: {
    hosts: { columns: transformToNewColumns(hostnameSharedHostsOptions) },
  },
  [ComponentPluginName.IPDomainHosts]: {
    hosts: { columns: transformToNewColumns(ipDomainHostsOptions) },
  },
  [ComponentPluginName.IPTracerouteEdges]: {
    'traceroute-edges': { columns: transformToNewColumns(ipTracerouteEdgesOptions) },
  },
  [ComponentPluginName.OrgDetailsTable]: {
    ASNs: { tabId: 'asns', columns: transformToNewColumns(orgASNOptions) },
    prefixes: { tabId: 'prefixes', columns: transformToNewColumns(orgPrefixOptions) },
    routers: { tabId: 'routers', columns: transformToNewColumns(orgRouterOptions) },
  },
  [ComponentPluginName.PrefixReputation]: {
    'bad-ips': { columns: transformToNewColumns(prefixReputationOption) },
  },
  [ComponentPluginName.PrefixTracerouteEdges]: {
    'traceroute-edges': { columns: transformToNewColumns(ipTracerouteEdgesOptions) },
  },
  [ComponentPluginName.RouterIPInterfaces]: {
    Interfaces: { tabId: 'Interfaces', columns: transformToNewColumns(routerInterfacesOptions) },
  },
};

export function formatOriginatedPrefixData(prefixes: object[]) {
  return prefixes.map((prefixObj: any) => {
    if (!prefixObj.prefix) return { ...prefixObj };
    const { prefix, orgName, geo, attributesReputation, attributesServices } = prefixObj.prefix;

    return {
      ...prefixObj,
      prefix: prefix,
      orgName: orgName,
      location: geo ? formatGeoLabel(geo) : null,
      country: geo?.country,
      geo: geo,
      attributesReputation,
      attributesServices,
    };
  });
}

export function formatRouterData(routerData: any) {
  const routers: any = {};
  routerData.forEach((routerObj: any) => {
    const { routerId, ipCount, responsiveIpCount, asn } = routerObj;
    routers[routerId] = { ...routerObj, ipCount, responsiveIpCount, asn: asn };
  });
  return Object.keys(routers).map((routerId: string) => {
    return {
      ...routers[routerId],
      routerId: routerId,
    };
  });
}

export function formatFacilityExchanges(ixData: any) {
  return ixData
    .map((exchangeObj: any) => {
      return {
        ...exchangeObj.id.exchange,
        peerCount: exchangeObj.peerCount || 0,
      };
    })
    .sort((a: any, b: any) => b.peerCount - a.peerCount);
}

export function formatCertData(certData: any) {
  return certData.map((certObj: any) => {
    return {
      ...certObj,
      isValid: new Date(certObj.notAfter).getTime() >= Date.now(),
    };
  });
}

// Format data for percentage histogram filters in asset report tables
export function getScoreAggregation(data: any) {
  const scores = data[0]?.map((item: any) => {
    if (item?.score?.startsWith('<')) {
      return +item?.score?.split('<')[0];
    }
    return +item?.score ?? 0;
  });
  const scoresDefined = !!scores && scores.length !== 0;
  let scoreInterval: any;
  if (scoresDefined) {
    scoreInterval =
      (scores[0] - (scores.length > 1 ? scores[scores.length - 1] : 0)) / ASSET_REPORT_BUCKET_COUNT;
  }

  let scoreBuckets = undefined;
  if (scoresDefined && !!scoreInterval) {
    const minScore = scores.length > 1 ? scores[scores.length - 1] : 0;
    let buckets: any[] = Array.apply(null, Array(ASSET_REPORT_BUCKET_COUNT)).map((item, i) => ({
      key: +(i * scoreInterval + minScore).toFixed(2),
      docCount: 0,
    }));

    scores.forEach((score: number) => {
      let numIntervals = Math.floor((score - minScore) / scoreInterval);
      if (numIntervals >= buckets.length) numIntervals = buckets.length - 1;
      buckets[numIntervals].docCount += 1;
    });
    scoreBuckets = buckets;
  }

  return { interval: scoreInterval, buckets: scoreBuckets };
}

// Format data for count histogram filters in asset report tables
export function getCountAggregation(data: any, accessor: string) {
  const counts = data[0]
    ?.map((item: any) => +item[accessor])
    .sort((a: any, b: any) => {
      return b - a;
    });
  const countsDefined = !!counts && counts.length !== 0;
  let interval: any;
  if (countsDefined) {
    interval =
      (counts[0] - (counts.length > 1 ? counts[counts.length - 1] : 0)) / ASSET_REPORT_BUCKET_COUNT;
  }

  let returnBuckets = undefined;
  if (countsDefined && !!interval) {
    const minScore = counts.length > 1 ? counts[counts.length - 1] : 0;
    let buckets: any[] = Array.apply(null, Array(ASSET_REPORT_BUCKET_COUNT)).map((item, i) => ({
      key: +(i * interval + minScore).toFixed(0),
      docCount: 0,
    }));
    counts.forEach((score: number) => {
      let numIntervals = Math.floor((score - minScore) / interval);
      if (numIntervals >= buckets.length) numIntervals = buckets.length - 1;
      buckets[numIntervals].docCount += 1;
    });
    returnBuckets = buckets;
  }
  return { interval, buckets: returnBuckets };
}

export function sortScoreRows(rowA: any, rowB: any) {
  if (typeof rowA.score === 'number' && typeof rowB.score === 'number')
    return rowA.score - rowB.score;
  if (rowB.score.startsWith('<')) {
    return 1;
  } else if (rowA.score.startsWith('<')) {
    return -1;
  } else {
    return rowA.score - rowB.score;
  }
}

export function getColumnIdentifier(col: NewTableColumn) {
  return typeof col.accessor === 'string' && col.accessor.length > 0
    ? col.accessor
    : (col.Header as string);
}

// Transform legacy table items to the NewTableColumn format
export function transformToNewColumns(columns: any[]) {
  return columns.map((col, i) => {
    let retVal: any = {};
    retVal['Header'] = col.name;
    retVal['accessor'] = col.selector ?? col.name;
    retVal['active'] = col.active ?? i < 4;
    if (col.cell) {
      retVal['Cell'] = (item: any) => col.cell(item.data[item.row.index]);
    }
    if (col.minWidth?.endsWith('px')) {
      retVal['minWidth'] = +col.minWidth?.substring(0, col.minWidth?.length - 2);
    }
    if (col.maxWidth?.endsWith('px')) {
      retVal['maxWidth'] = +col.maxWidth?.substring(0, col.maxWidth?.length - 2);
    }
    if (col.width?.endsWith('px')) {
      retVal['width'] = +col.width?.substring(0, col.width?.length - 2);
    }
    if (col.filterType) {
      retVal['filterType'] = col.filterType;
    }
    if (col.clauseType) {
      retVal['clauseType'] = col.clauseType;
    }
    if (col.formatLabel) {
      retVal['formatLabel'] = col.formatLabel;
    }
    if (col.subAccessor) {
      retVal['subAccessor'] = col.subAccessor;
    }
    if (col.aggAccessor) {
      retVal['aggAccessor'] = col.aggAccessor;
    }
    if (col.hideValueOptions != null) {
      retVal['hideValueOptions'] = col.hideValueOptions;
    }
    if (col.partialMatchFilter != null) {
      retVal['partialMatchFilter'] = col.partialMatchFilter;
    }
    if (col.filterEnabled != null) {
      retVal['filterEnabled'] = col.filterEnabled;
    }
    if (col.showCount) {
      retVal['showCount'] = col.showCount;
    }
    return retVal;
  });
}

export function getNestedData(data: Record<string, any>) {
  if (data.pipelineQuery) {
    data = data.pipelineQuery;
  }
  data = Object.values(data)
    .filter((value: any) => isObject(value))
    .slice(-1)[0];
  if (Array.isArray(data)) {
    data = {
      totalCount: data.length,
      nextCursor: null,
      items: data,
    };
  }
  return data;
}

// Create a column config to be stored in the url/plugin config. We want to make this as small as possible
// while still retaining necessary info, so only include StoredColumnFields
export function constructPrunedColumnConfig(
  newColumns: NewTableColumn[],
  isAssetPageConfig?: boolean,
) {
  let columnConfig = newColumns.map((col: NewTableColumn) => {
    let retVal: Record<string, any> = {};
    Object.keys(col).forEach((field) => {
      // for local filters, column filter clauses are not stored in an advanced query object, hold on to them
      // in the column config
      if (
        (StoredColumnFields.includes(field) ||
          ((col.localFilter || isAssetPageConfig) && field === 'columnFilterClauses')) &&
        typeof get(col, field) !== 'function'
      ) {
        if (field === 'columnFilterClauses') {
          retVal[field] = get(col, field)?.filter((clause) => {
            return clause?.value != null;
          });
        } else {
          retVal[field] = get(col, field);
        }
      }
    });
    return retVal;
  });
  // Remove inactive columns
  columnConfig = columnConfig.filter((col, i) => {
    return newColumns[i].active;
  });
  return columnConfig;
}

export function repopulateColumnConfig(bareColumns: any, columnOptions: NewTableColumn[]) {
  if (!bareColumns) {
    return columnOptions;
  }
  // Don't modify any original memory
  let newColumns = cloneDeep(bareColumns);
  // Loop through columns and decorate each with additional info omitted from config
  bareColumns.forEach((col: any, i: number) => {
    const fullColumn = columnOptions.find(
      (fc: NewTableColumn) => getColumnIdentifier(fc) === getColumnIdentifier(col),
    );
    newColumns[i] = { ...fullColumn, ...col, active: true };
  });
  // Loop through all columns and add back inactive ones (for the config menu)
  columnOptions.forEach((col: NewTableColumn) => {
    if (!newColumns.find((newC: any) => getColumnIdentifier(newC) === getColumnIdentifier(col))) {
      newColumns.push({ ...col, active: false });
    }
  });
  return newColumns;
}

export function getAccessor(activeColumn: any, isSub: boolean, row: any, i: number) {
  const accessor = isSub ? activeColumn?.subAccessor : activeColumn.accessor;
  return typeof accessor === 'function'
    ? accessor(row, i, { subRows: [], depth: 0, data: [] })
    : accessor;
}

export function transformTableAccessorToLabel(accessor: string) {
  const lowerCase = accessor.toLowerCase();
  if (lowerCase.includes('org')) {
    return 'Org';
  }
  if (lowerCase.includes('asn')) {
    return 'ASN';
  }
  if (lowerCase.includes('countr')) {
    return 'Country';
  }
  if (lowerCase.includes('facility')) {
    return 'Facility';
  }
  if (lowerCase.includes('prefix')) {
    return 'Prefix';
  }
  if (lowerCase.includes('policy')) {
    return 'Policy';
  }
  if (lowerCase.includes('ix')) {
    return 'IX';
  }
  if (lowerCase.includes('ip')) {
    return 'IP';
  }
  if (lowerCase.includes('rdns')) {
    return 'rDNS';
  }
  if (lowerCase.includes('host')) {
    if (lowerCase.includes('types')) {
      return 'Hostname Types';
    }
    return 'Hostname';
  }
  if (lowerCase.includes('router')) {
    return 'Router ID';
  }
  if (lowerCase.includes('vendor')) {
    return 'Vendor';
  }
  if (lowerCase.includes('services[0].name')) {
    return 'Service';
  }
  if (lowerCase.includes('region')) {
    return 'Region';
  }

  return capitalizeFirstLetter(accessor);
}

export function hideValueOptions(accessor: string | undefined) {
  if (accessor === undefined) return true;
  return accessor.toLowerCase().includes('prefix') || accessor.toLowerCase().includes('ip');
}

export function onAddServerPageFilter(
  clause: string,
  filter: QueryFilterClause | undefined,
  setFilter: any,
  tableData: any,
) {
  // If we're removing the filter (undefined), delete it from the filter string
  if (!filter) {
    setFilter &&
      setFilter((curr: string) => {
        let updatedFilters = convertGQLStringToJSONObject(curr);
        let accessor = clause;
        if (filterClauseOptionRegistry[clause]) {
          accessor = filterClauseOptionRegistry[clause].field.split(':')[1];
        }
        delete updatedFilters[accessor];
        return convertJSONObjectToGQLString(updatedFilters);
      });
  } else {
    // Otherwise, add or update the filter
    let accessor = clause;
    // We want to use the field for filtering, rather than the clause
    // (clause types in the registry are of the form 'Router:Vendor',
    // fields are of the form 'routerAliases:vendor.name')
    if (filterClauseOptionRegistry[clause]) {
      accessor = filterClauseOptionRegistry[clause].field.split(':')[1];
    }
    setFilter &&
      setFilter((curr: string) => {
        let updatedFilters = convertGQLStringToJSONObject(curr);
        updatedFilters[accessor] = filter?.value;
        return convertJSONObjectToGQLString(updatedFilters);
      });
  }
  // Create the new column config. First find the relevant column in the table data
  let newColumnIndex = tableData.columns.findIndex((col: NewTableColumn) => {
    if (!col.clauseType) return false;
    return col.clauseType === clause;
  });
  let newColumns = [...tableData.columns];
  if (newColumnIndex >= 0) {
    let newFilters: QueryFilterClause[] = newColumns[newColumnIndex].columnFilterClauses ?? [];
    // Search to see if this new filter already existed in the column config
    const oldIndex = newFilters.findIndex((old) => old.type === clause);
    if (!filter) {
      newFilters.splice(oldIndex, 1);
    } else {
      // Delete the old filter if necessary, and concat the new one.
      if (oldIndex >= 0 && filter.type === clause) delete newFilters[oldIndex];
      newFilters = newFilters.concat([filter]);
    }
    newColumns[newColumnIndex] = { ...newColumns[newColumnIndex], columnFilterClauses: newFilters };
    newFilters.length === 0 && delete newColumns[newColumnIndex].columnFilterClauses;
    tableData.onUpdateColumnConfig(newColumns, true);
  }
}

export function initFilterString(existing: string, config: any, columnOptions: NewTableColumn[]) {
  const columnConfig = repopulateColumnConfig(config?.columns, columnOptions);
  if (!columnConfig) return existing;
  let obj = convertGQLStringToJSONObject(existing) ?? {};
  columnConfig.forEach((col: NewTableColumn) => {
    if (col.columnFilterClauses && col.columnFilterClauses.length > 0) {
      if (!col.clauseType) return;
      if (filterClauseOptionRegistry[col.clauseType]) {
        obj[filterClauseOptionRegistry[col.clauseType].field.split(':')[1]] =
          col.columnFilterClauses[0]?.value;
      } else {
        obj[col.clauseType] = col.columnFilterClauses[0]?.value;
      }
    }
  });
  return convertJSONObjectToGQLString(obj);
}
