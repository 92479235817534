import { gql } from '@apollo/client';
import { useAssetQuery } from 'hooks';
import { AssetType } from 'model';

const assetQuery = gql`
query ExchangeAboutData($exchangeSet: [String]!) {
  exchangeData(exchangeSet: $exchangeSet) {
    exchangeId
    exchange {
      id
      name
      nameLong
      ownerName
      url
      peerCount
      facCount
      geo {
        city
        country
      }
      prefixesV4 {
        prefix
      }
      prefixesV6 {
        prefix
      }
    }
  }
}
`;

export function useExchangeQuery(assetValue: string) {
  const { asset, loading, error } = useAssetQuery(AssetType.IX, assetValue, assetQuery);
  return { asset, loading, error };
}
