import React from 'react';
import { ReactComponent as RouteCount } from 'svg/experimental/flow.svg';

export function TracerouteCardsTooltip() {
  return (
    <p className="uk-margin-remove-top uk-margin-remove-bottom uk-text-muted">
      Edges are pairs of consecutive IPs, though they are represented by their associated routers
      whenever possible. The count of traces <RouteCount /> and percentage of overall traceroutes to
      the given target prefix are shown on the edge. The average TTL from the DST to the last
      responding IP in a traceroute with a destination in the target prefix is in the upper right
      hand corner.
    </p>
  );
}
