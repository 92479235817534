import React, { useEffect, useState } from 'react';
import { components } from 'react-select';
import { KnowledgeSearch } from './KnowledgeSearch';
import { ReactComponent as Logo } from 'svg/brand/logotype.svg';
import { ReactComponent as ChevronRightIcon } from 'svg/direction/chevron-east.svg';
import { platformSearchStyle, ClearIndicator, Banner } from 'components';
import { useReactiveVar } from '@apollo/client';
import { acknowledgeNotification, userNotifications } from 'model';
import { isEmbedded } from 'utils';
import { ReactComponent as Search } from 'svg/system/magnifying-glass.svg';
import { useHistory } from 'react-router-dom';

export default function StartPage() {
  const brandingEnabled = process.env.REACT_APP_BRANDED?.toLowerCase() === 'true';
  const selectProps = {
    components: { Control, ClearIndicator },
    styles: { ...platformSearchStyle() },
    autoFocus: true,
  };

  const notifications = useReactiveVar(userNotifications);
  const [currentBanner, setCurrentBanner] = useState<any>(undefined);

  // Clear invalid asset notification after first load
  useEffect(() => {
    const invalidAssetNotif = Object.entries(notifications).filter(
      (notification) => notification[0] === 'invalid-asset',
    )[0];
    if (invalidAssetNotif) {
      setCurrentBanner(invalidAssetNotif);
      acknowledgeNotification('invalid-asset');
    }
  }, [notifications]);

  const history = useHistory();

  if (isEmbedded()) {
    return null;
  }

  return (
    <div className="app-search-box">
      <div className="app-logo-container">
        {brandingEnabled ? (
          <Logo className="start-page-logo" />
        ) : (
          <h1 className="unbranded-title">{process.env.REACT_APP_TITLE}</h1>
        )}
      </div>
      {currentBanner && currentBanner[1] && (
        <div className="header-banner">
          <Banner {...currentBanner[1]} />
        </div>
      )}
      <KnowledgeSearch selectProps={selectProps} dataTestId="start-page-knowledge-search" />
      <button
        className="see-more-button uk-margin-small-top uk-margin-auto-left"
        onClick={() => history.push('/advanced-search')}
      >
        ADVANCED SEARCH
        <ChevronRightIcon
          style={{ height: '16px', width: '16px', transform: 'translateY(-1px)' }}
        />
      </button>
    </div>
  );
}

function Control({ children, ...props }: any) {
  return (
    <components.Control {...props}>
      <Search className="search-icon" />
      {children}
    </components.Control>
  );
}
