import React, { Fragment, useContext, useMemo } from 'react';
import { ReportTitle, ComponentPluginName, DisplayElement } from 'components';
import { useAssetQuery } from 'hooks';
import { AssetType, UserInvestigationSection } from 'model';
import { gql } from '@apollo/client';
import { AssetInfoContext } from 'appContexts';
import { removeUrnPrefix, toInvestigationSectionId } from 'utils';
import InvestigationSection from '../investigations/InvestigationSection';

const assetQuery = gql`
  query RouterTitleData($routerIdSet: [String]!) {
    routerData(routerIdSet: $routerIdSet) {
      routerId
      routerAlias {
        asn {
          asn
          orgName
        }
      }
    }
  }
`;

export default function RouterInfo({ assetValue }: { assetValue: string }) {
  const { asset } = useAssetQuery(AssetType.Router, assetValue, assetQuery);
  const assetTitle = asset?.routerAlias?.asn?.orgName;
  const assetName = asset?.routerId ?? 'Router';
  const { displayElements } = useContext(AssetInfoContext);
  const section: UserInvestigationSection = useMemo(
    () => ({
      id: toInvestigationSectionId(ComponentPluginName.RouterAbout, { assetValue, assetName }),
      title: 'About',
      description: '',
    }),
    [assetValue, assetName],
  );

  return (
    <Fragment>
      {asset && (
        <Fragment>
          {displayElements?.includes(DisplayElement.Title) && (
            <ReportTitle
              title={assetTitle || 'Ownership Pending'}
              subtitle={removeUrnPrefix(asset?.routerId)}
              label="Router"
            />
          )}
          {displayElements?.includes(DisplayElement.About) && (
            <InvestigationSection section={section} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
