import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { ComponentPluginName, Spinner } from 'components';
import {
  ALERT_TYPES,
  createGenericNotification,
  UserInvestigation,
  UserInvestigationSection,
  userInvestigationsVar,
  userSharedInvestigationsVar,
} from 'model';
import {
  createUniqueInvestigationSectionId,
  handleImagesOnFirstLoad,
  focusTile,
  generatePdf,
  getArchivedPdfQuery,
  getFilterClauseOption,
  getFullAnchorLinkIds,
  getInvestigation,
  getSearchParams,
  getTemplateAssetTypeFromId,
  getTemplateParamId,
  isAdvancedSearch,
  isAssetReport,
  isOutputVariable,
  isViewOnly,
  normalizeFilterClauseLabel,
  parseInvestigationSectionId,
  saveInvestigation,
  saveTileState,
  setCurrentInvestigation,
  toggleMainIsFocused,
  preventNavigationInterrupt,
} from 'utils';
import { cloneDeep } from 'lodash';
import { LocalAnnotations, SharedContext, SidebarContext, SidebarContextValue } from 'appContexts';
import InvestigationSection from './InvestigationSection';
import { InvestigationHeader } from './InvestigationHeader';
import { Sidebar, SidebarOptions } from './sidebar/Sidebar';

export default function Investigation({ investigation }: { investigation?: UserInvestigation }) {
  const { sharedUserDataId } = useContext(SharedContext);

  // reactive vars
  const investigations = useReactiveVar(userInvestigationsVar);
  const sharedInvestigations = useReactiveVar(userSharedInvestigationsVar);

  // state
  const [focusedTile, setFocusedTile] = useState<UserInvestigationSection | undefined>();
  const [isMainFocused, setIsMainFocused] = useState<boolean>(false);
  const [isPdfGenerating, setIsPdfGenerating] = useState<boolean>(false);
  const [initializationSpinner, setInitializationSpinner] = useState<boolean>(true);
  const [archiveData, setArchiveData] = useState<Array<any>>();
  const [unreferencedImagesChecked, setUnreferencedImagesChecked] = useState<boolean>(false);
  const [templateParamLoading, setTemplateParamLoading] = useState<boolean>(true);
  const [imagesLoading, setImagesLoading] = useState<boolean>(false);
  const [activeSidebarOption, setActiveSidebarOption] = useState<SidebarOptions>(undefined);
  const [localTemplateParams, setLocalTemplateParams] = useState([]);

  // memos/variables
  const searchParams = getSearchParams();
  const shortID = searchParams.get('id') || undefined;
  const focusedId = useMemo(() => {
    if (!focusedTile) return undefined;
    const [, params] = parseInvestigationSectionId(focusedTile.id);
    return params.uuid;
  }, [focusedTile]);
  const sortedArchiveData = useMemo(() => {
    return archiveData?.sort((a, b) => {
      const timeA = a.timestamp;
      const timeB = b.timestamp;
      return new Date(timeB).getTime() - new Date(timeA).getTime();
    });
  }, [archiveData]);

  const investigationObj = useMemo(() => {
    if (investigation) {
      setCurrentInvestigation(investigation);
      return investigation;
    }
    if (shortID) {
      setCurrentInvestigation(getInvestigation(shortID, investigations));
      return getInvestigation(shortID, investigations);
    } else if (sharedUserDataId) {
      setCurrentInvestigation(getInvestigation(sharedUserDataId, sharedInvestigations));
      return getInvestigation(sharedUserDataId, sharedInvestigations);
    }
  }, [investigations, sharedUserDataId, sharedInvestigations, shortID, investigation]);

  const sections = useMemo(() => {
    return investigationObj?.sections ?? [];
  }, [investigationObj?.sections]);

  const focusedIndex = useMemo(() => {
    return sections?.findIndex((section) => {
      const [, params] = parseInvestigationSectionId(section.id);
      return params.uuid === focusedId;
    });
  }, [focusedId, sections]);

  const initializeAnnotations = useCallback(() => {
    if (!investigationObj) return undefined;
    const initialAnnotations: LocalAnnotations = {
      mainDesc: investigationObj?.description,
      mainTitle: investigationObj?.title,
    };
    const focusedSection = sections?.find((section) => {
      const [, params] = parseInvestigationSectionId(section.id);
      return params.uuid === focusedId;
    });
    if (focusedSection) {
      initialAnnotations.focusedDesc = focusedSection.description;
      initialAnnotations.focusedTitle = focusedSection.title;
    }
    return initialAnnotations;
  }, [investigationObj, focusedId, sections]);
  const [localAnnotations, setLocalAnnotations] = useState(initializeAnnotations);

  const isTemplate = investigationObj?.isTemplate;

  const anchorLinks = useMemo(() => {
    if (!investigationObj) return [];
    return getFullAnchorLinkIds(investigationObj?.sections, investigationObj?.description);
  }, [investigationObj]);

  const [getArchives, getArchivesResult] = useLazyQuery(getArchivedPdfQuery, {
    variables: { investigationId: investigationObj?.id },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!data?.supportingData?.dataObjects.length) return;
      setArchiveData(data?.supportingData?.dataObjects);
    },
  });

  // refs
  const isMounted = useRef<boolean>(true);

  // scroll focused tile into view
  useEffect(() => {
    const tileElement = document.querySelector(`div.uuid-${focusedId}`);
    tileElement?.scrollIntoView(true);
  }, [focusedId]);

  // scroll main section into view
  useEffect(() => {
    if (isMainFocused) {
      window.scroll(0, 0);
    }
  }, [isMainFocused]);

  // Initialize local annotations if investigationObj was not initially defined.
  // When copying a mosaic, investigationObj may not be defined because the backend
  // hasn't updated yet.
  // Logic is only run once when localAnnotations is undefined.
  useEffect(() => {
    if (!localAnnotations && investigationObj) {
      setLocalAnnotations(initializeAnnotations());
    }
  }, [localAnnotations, initializeAnnotations, investigationObj]);

  // Initialize archived pdf data
  useEffect(() => {
    if (
      !!investigationObj &&
      !getArchivesResult.loading &&
      !getArchivesResult.called &&
      !isAssetReport() &&
      !isAdvancedSearch()
    ) {
      try {
        let investigationId = investigationObj?.id;
        getArchives({ variables: { investigationId } });
      } finally {
        // Just pass here. No special error handling for this query
      }
    }
  }, [getArchives, investigationObj, getArchivesResult]);

  // On first render, delete any unreferenced images in the investigation
  useEffect(() => {
    const preventImageMigrationInterrupt = (e: BeforeUnloadEvent) => preventNavigationInterrupt(e);
    if (!unreferencedImagesChecked && !isViewOnly() && investigationObj && !isAdvancedSearch()) {
      setUnreferencedImagesChecked(true);
      setImagesLoading(true);
      handleImagesOnFirstLoad(investigationObj, setImagesLoading, preventImageMigrationInterrupt);
    }
    return () => window.removeEventListener('beforeunload', preventImageMigrationInterrupt);
  }, [unreferencedImagesChecked, investigationObj]);

  // Initialize local template params if not already
  useEffect(() => {
    async function initializeLocalParams() {
      // Fill in all the local template param labels
      const newParams = cloneDeep(investigationObj.templateParams);
      for (let i = 0; i < newParams.length; i++) {
        const param = newParams[i];
        // output variables evaluate their labels in OutputTile.tsx
        if (isOutputVariable(param)) {
          continue;
        }
        const value = param.value;
        const id = getTemplateParamId(param);
        let clauseOption = param.clauseType ? getFilterClauseOption(param.clauseType) : undefined;
        if (getTemplateAssetTypeFromId(id) === 'dataset') {
          clauseOption = getFilterClauseOption('DnsHost:IP');
        }
        const label = await normalizeFilterClauseLabel(id, value, undefined, clauseOption);
        if (label) {
          newParams[i] = { ...param, label };
        }
      }
      setLocalTemplateParams(newParams);
      setTemplateParamLoading(false);
    }

    if (
      investigationObj?.templateParams &&
      (!localTemplateParams ||
        investigationObj.templateParams.length !== localTemplateParams.length)
    ) {
      // initialize with some data as the async function churns
      setLocalTemplateParams(investigationObj.templateParams);
      initializeLocalParams();
    } else {
      setTemplateParamLoading(false);
    }
  }, [investigationObj, localTemplateParams]);

  useEffect(() => {
    window.setTimeout(() => {
      setInitializationSpinner(false);
    }, 2500);
  }, []);

  // Indicate unmounted component for async downloading
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  const onTileFocus = useCallback(
    (section) => {
      if (!section) {
        return;
      }
      focusTile(section, focusedTile, setLocalAnnotations, setFocusedTile, setIsMainFocused);
    },
    [focusedTile],
  );

  const onAddNewTile = useCallback(
    (to: number) => {
      const id = createUniqueInvestigationSectionId(ComponentPluginName.PrimitiveTile, {});
      const newSections = cloneDeep(sections);
      newSections.splice(to, 0, { title: 'New Tile', description: '', saved: new Date(), id });
      const newInvestigation = { ...investigationObj, sections: newSections };
      saveInvestigation(newInvestigation);
      onTileFocus(newSections[to]);
    },
    [sections, investigationObj, onTileFocus],
  );

  /* ***********************KEYBOARD SHORTCUTS****************************** */
  useEffect(() => {
    function handleHotKey(e: any) {
      if (e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA') return;
      if (e.target.isContentEditable) return;
      const key = e.key;
      const shift = e.shiftKey;
      if (key === 'j') {
        // move down focus tile
        if (focusedIndex >= 0) {
          // the focused tile is the last tile
          if (focusedIndex === sections.length - 1 && sections.length > 1) {
            toggleMainIsFocused(
              isMainFocused,
              setIsMainFocused,
              setFocusedTile,
              setLocalAnnotations,
              investigationObj,
            );
            // the focused tile is some other tile
          } else if (sections[focusedIndex + 1]) {
            onTileFocus(sections[focusedIndex + 1]);
          }
        } else if (isMainFocused) {
          onTileFocus(sections[0]);
          setIsMainFocused(false);
        } else {
          toggleMainIsFocused(
            isMainFocused,
            setIsMainFocused,
            setFocusedTile,
            setLocalAnnotations,
            investigationObj,
          );
        }
      } else if (key === 'k') {
        // move up focus tile
        if (focusedIndex >= 0) {
          // the focused tile is the first tile
          if (focusedIndex === 0 && sections.length > 1) {
            toggleMainIsFocused(
              isMainFocused,
              setIsMainFocused,
              setFocusedTile,
              setLocalAnnotations,
              investigationObj,
            );
          } else if (sections[focusedIndex - 1]) {
            onTileFocus(sections[focusedIndex - 1]);
          }
        } else if (sections.length) {
          onTileFocus(sections[sections.length - 1]);
          if (isMainFocused) setIsMainFocused(false);
        } else {
          toggleMainIsFocused(
            isMainFocused,
            setIsMainFocused,
            setFocusedTile,
            setLocalAnnotations,
            investigationObj,
          );
        }
      } else if (key === ' ') {
        // toggle keystone
        e.preventDefault();
        if (focusedIndex >= 0) {
          const section = sections[focusedIndex];
          const [pluginName, params] = parseInvestigationSectionId(section.id);
          saveTileState({
            newParams: {
              ...params,
              config: {
                ...params.config,
                keystone: {
                  ...params?.config?.keystone,
                  expanded: !params?.config?.keystone?.expanded,
                },
              },
            },
            title: section.title,
            description: section.description,
            investigation: investigationObj,
            pluginName,
          });
        }
      } else if (key === 'e') {
        // show tile list
        setActiveSidebarOption(
          activeSidebarOption === SidebarOptions.TileList ? undefined : SidebarOptions.TileList,
        );
      } else if (key === 'v' && isTemplate) {
        // show template variables
        setActiveSidebarOption(
          activeSidebarOption === SidebarOptions.TemplateVariables
            ? undefined
            : SidebarOptions.TemplateVariables,
        );
      } else if (key === 's') {
        // show share menu
        setActiveSidebarOption(
          activeSidebarOption === SidebarOptions.Share ? undefined : SidebarOptions.Share,
        );
      } else if (key === 'Escape') {
        // close any open sidebar menus
        setActiveSidebarOption(undefined);
      }
      if (isViewOnly()) return;
      if (key === 'ArrowUp' && shift) {
        // insert new tile above
        if (focusedIndex >= 0) {
          onAddNewTile(focusedIndex);
        } else {
          onAddNewTile(0);
          window.scrollTo(0, 0);
        }
      } else if (key === 'ArrowDown' && shift) {
        // insert new tile below
        if (focusedIndex >= 0) {
          onAddNewTile(focusedIndex + 1);
        } else {
          onAddNewTile(sections.length);
        }
      }
    }
    if (!isAdvancedSearch()) {
      document.addEventListener('keydown', handleHotKey);
    }
    return () => {
      if (!isAdvancedSearch()) {
        document.removeEventListener('keydown', handleHotKey);
      }
    };
  }, [
    focusedIndex,
    sections,
    onAddNewTile,
    onTileFocus,
    investigationObj,
    isMainFocused,
    activeSidebarOption,
    isTemplate,
  ]);

  function setDownloadingIfMounted(downloading: boolean) {
    if (isMounted.current) {
      setIsPdfGenerating(downloading);
    }
  }

  const onDownload = useCallback(
    (archiveSelection?: string) => {
      if (archiveSelection === 'current' || !archiveSelection) {
        generatePdf({ investigation: investigationObj, setDownloading: setDownloadingIfMounted });
      } else {
        const relevantPdf = archiveData?.find((pdf) => pdf.timestamp === archiveSelection);
        if (!relevantPdf) {
          createGenericNotification('pdf-archive-error', {
            title: 'Unable to find archived PDF',
            alertType: ALERT_TYPES.error,
            message: '',
          });
        } else {
          generatePdf({
            investigation: investigationObj,
            setDownloading: setDownloadingIfMounted,
            pdfData: relevantPdf.data,
          });
        }
      }
    },
    [investigationObj, archiveData],
  );

  const onSidebarOptionChange = useCallback(
    (newOption: SidebarOptions) => {
      if (activeSidebarOption === newOption) {
        setActiveSidebarOption(undefined);
      } else {
        setActiveSidebarOption(newOption);
      }
      const resizeEvent = new CustomEvent('resize');
      window.dispatchEvent(resizeEvent);
    },
    [activeSidebarOption],
  );

  const sidebarContext = useMemo<SidebarContextValue>(
    () => ({
      pdfGenerating: isPdfGenerating,
      investigation: investigationObj,
      downloadArchive: onDownload,
      setDownloading: setDownloadingIfMounted,
      archiveData: sortedArchiveData,
      setArchiveData,
      setFocusedTile,
      focusedTile,
      isTemplate,
      setLocalTemplateParams,
      localTemplateParams,
      setLocalAnnotations,
      localAnnotations,
      setIsMainFocused,
      isMainFocused: isMainFocused,
      setActiveOption: onSidebarOptionChange,
      activeOption: activeSidebarOption,
    }),
    [
      isPdfGenerating,
      onDownload,
      investigationObj,
      focusedTile,
      isTemplate,
      localTemplateParams,
      sortedArchiveData,
      setLocalAnnotations,
      localAnnotations,
      setIsMainFocused,
      isMainFocused,
      onSidebarOptionChange,
      activeSidebarOption,
    ],
  );

  if (!investigationObj) {
    return initializationSpinner ? (
      <Spinner position={'center'} ratio={3} />
    ) : (
      <div className="app-results">Unable to find the specified investigation</div>
    );
  }

  return (
    <SidebarContext.Provider value={sidebarContext}>
      {templateParamLoading || imagesLoading ? (
        <Spinner position={'center'} ratio={3} />
      ) : (
        <Fragment>
          {!!investigationObj && !isAdvancedSearch() && (
            <Sidebar key={`sidebar-${investigationObj.id}`} />
          )}
          <div
            key={investigationObj.id}
            className={`investigation-wrapper ${activeSidebarOption ? 'sidebar-open' : ''}`}
          >
            <InvestigationHeader
              isMainFocused={isMainFocused}
              toggleMainIsFocused={() =>
                toggleMainIsFocused(
                  isMainFocused,
                  setIsMainFocused,
                  setFocusedTile,
                  setLocalAnnotations,
                  investigationObj,
                )
              }
            />
            <div
              data-uk-grid
              className="uk-margin-remove-left uk-grid-collapse section-grid-wrapper"
              style={{ display: 'block' }}
            >
              {sections.map((section: UserInvestigationSection, index) => {
                const [, params] = parseInvestigationSectionId(section.id);
                return (
                  <InvestigationSection
                    key={params.uuid ?? index}
                    section={section}
                    isFocused={params.uuid && focusedId === params.uuid}
                    onTileFocus={onTileFocus}
                    setLocalTemplateParams={setLocalTemplateParams}
                    templateParams={localTemplateParams}
                    anchorLinkId={anchorLinks[index]}
                    isTemplate={isTemplate}
                  />
                );
              })}
            </div>
          </div>
        </Fragment>
      )}
    </SidebarContext.Provider>
  );
}
