import { SidebarContext } from 'appContexts';
import { UserInvestigation, UserInvestigationSection } from 'model';
import React, { useCallback, useContext } from 'react';
import {
  getTemplateParamId,
  parseInvestigationSectionId,
  propagateTemplateVarUpdate,
  saveInvestigation,
} from 'utils';
import { ReactComponent as WarningIcon } from 'svg/actions/exclamation-fill.svg';

export function DeleteTilePrompt({ selected, setSelected, updateSelectedPrompt }) {
  const { investigation, setFocusedTile, focusedTile, setLocalTemplateParams } =
    useContext(SidebarContext);

  const deleteTiles = useCallback(() => {
    let newSections: UserInvestigationSection[] = [];
    let templateParamsToRemove = [];
    // loop through the sections
    for (let i = 0; i < investigation.sections.length; i++) {
      const section = investigation.sections[i];
      // If the section isn't being deleted, push into the return value
      const [, params] = parseInvestigationSectionId(section.id);
      if (!selected.has(params.uuid)) {
        newSections.push(section);
      } else {
        // If we're deleting the focused tile, remove the focus
        if (focusedTile && focusedTile.id === section.id) {
          setFocusedTile(undefined);
        }
        // identify if the section created a template param, and if so, track it to be removed
        const [, parsedSection] = parseInvestigationSectionId(section.id);
        const templateParamToRemove = investigation.templateParams?.find((param) => {
          return param.origin === parsedSection.uuid;
        });
        if (templateParamToRemove) {
          templateParamsToRemove.push(templateParamToRemove);
        }
      }
    }
    // we remove template params after we know which sections will remain and need to be updated
    let newTemplateParams = investigation.templateParams;
    if (templateParamsToRemove.length > 0) {
      templateParamsToRemove.forEach((paramToRemove) => {
        newSections = propagateTemplateVarUpdate(newSections, paramToRemove, {
          label: undefined,
          value: undefined,
        });
        newTemplateParams = newTemplateParams.filter((templateParam) => {
          return getTemplateParamId(templateParam) !== getTemplateParamId(paramToRemove);
        });
      });
      setLocalTemplateParams(newTemplateParams);
    }
    const newInvestigation: UserInvestigation = {
      ...investigation,
      templateParams: newTemplateParams,
      sections: newSections,
    };
    setSelected(new Set());
    saveInvestigation(newInvestigation);
    updateSelectedPrompt(undefined);
  }, [
    focusedTile,
    investigation,
    selected,
    setFocusedTile,
    setLocalTemplateParams,
    setSelected,
    updateSelectedPrompt,
  ]);

  return (
    <div
      className={`uk-flex ${selected.size < 1 ? 'sidebar-menu-alert' : 'sidebar-menu-delete-warning'} uk-flex-between`}
    >
      <div className="uk-flex" style={{ height: '24px' }}>
        <WarningIcon className="uk-margin-right" />
        <div style={{ lineHeight: '24px' }}>
          {selected.size < 1 ? 'Select content to be deleted' : 'Delete the selected content'}
        </div>
      </div>
      {selected.size > 0 && (
        <button
          className="uk-button"
          onClick={() => deleteTiles()}
          data-testid="delete-tile-prompt-button"
        >
          {`Delete (${selected.size})`}
        </button>
      )}
    </div>
  );
}
