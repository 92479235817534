import UIkit from 'uikit';

let offlineNotification: any = undefined;
let networkErrorNotification: any = undefined;

export function isOffline() {
  return !navigator.onLine;
}

export function showNetworkErrorNotification() {
  if (!isOffline() && !networkErrorNotification) {
    networkErrorNotification = UIkit.notification(
      '⚠ A network error occurred contacting the server. Please check your network connection.',
      { status: 'danger', timeout: 0 },
    );
  }
}

export function hideNetworkErrorNotification() {
  networkErrorNotification?.close();
  networkErrorNotification = undefined;
}

window.addEventListener(
  'offline',
  () => {
    offlineNotification = UIkit.notification("⚠ You currently don't have a network connection.", {
      status: 'danger',
      timeout: 0,
    });
  },
  false,
);

window.addEventListener(
  'online',
  () => {
    offlineNotification?.close();
    offlineNotification = undefined;
  },
  false,
);

UIkit.util.on(document, 'close', function (event: UIEvent) {
  if (event.target === offlineNotification?.$el) {
    offlineNotification = undefined;
  } else if (event.target === networkErrorNotification?.$el) {
    networkErrorNotification = undefined;
  }
});
