import { useMemo } from 'react';
import { get } from 'lodash';

export function useMosaicSavedSort() {
  return useMemo(
    () => (rowA: any, rowB: any, columnId: string, desc: boolean) => {
      const aSaved = new Date(get(rowA.original, columnId)).getTime();
      const bSaved = new Date(get(rowB.original, columnId)).getTime();

      // Get pinTag regardless of gallery/my-mosaics
      const aPin = rowA.original?.pinTag || rowA.original?.value?.pinTag || '';
      const bPin = rowB.original?.pinTag || rowB.original?.value?.pinTag || '';
      // First sort by pinned
      if (aPin > bPin) return desc ? 1 : -1;
      if (aPin < bPin) return desc ? -1 : 1;
      // Always sort pinned by saved desc
      if (aPin !== '' && bPin !== '' && aPin === bPin) {
        if (aSaved > bSaved) return desc ? 1 : -1;
        if (aSaved < bSaved) return desc ? -1 : 1;
      }
      // Secondary sort by saved time
      if (aSaved > bSaved) return 1;
      if (aSaved < bSaved) return -1;
    },
    [],
  );
}
