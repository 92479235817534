import React, { Children, useContext, useMemo, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { ghcolors as highlightStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  addAllHeadingReferencesInDescription,
  authenticatedFetch,
  getAnchorLinkId,
  getCurrentInvestigation,
  parseInvestigationSectionId,
} from 'utils';
import { AssetLink, AuthenticatedImage } from 'components';
import {
  createGenericNotification,
  ALERT_TYPES,
  acknowledgeNotification,
  AssetType,
  UserInvestigationSection,
} from 'model';
import { Link } from 'react-router-dom';
import { ComponentPluginContext } from 'appContexts';

type ViewHeaderProps = {
  section: UserInvestigationSection;
  className?: string;
};

export const flattenReactComponents: any = (text: string, child: any) => {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flattenReactComponents, text);
};

function formatHeading(props: any, headingCountRef: any, section?: UserInvestigationSection) {
  const children = React.Children.toArray(props.children);
  const text: any = children.reduce(flattenReactComponents, '');
  const anchoredText = getAnchorLinkId(text);
  // Look at the line number in the markdown where this anchor text appears
  const line = props?.node?.position?.start?.line;
  if (!anchoredText) return;
  if (line != null) {
    // Check if we've seen this anchor link already in the markdown block
    if (!headingCountRef.current[anchoredText]) {
      // If not, record a new entry for this anchor link in our ref, noting the line number
      headingCountRef.current[anchoredText] = [line];
    } else if (!headingCountRef.current[anchoredText].includes(line)) {
      // If so, add the current line number to the existing entry.
      // Make sure they are sorted in order of appearance in the markdown.
      headingCountRef.current[anchoredText].push(line);
      headingCountRef.current[anchoredText].sort();
    }
  }
  // The headingIndex will keep track of what number we need to append to the end
  // of our anchor link. For example, the 1st-3rd instances of a # New Tile heading
  // should have anchor ids newtile, newtile-1, and newtile-2.
  // The first place we will have to increment this index is if there are occurrences
  // of the anchor link within the same markdown block.
  let headingIndex = headingCountRef.current[anchoredText]?.findIndex(
    (lineNum) => lineNum === line,
  );
  // Next, if it's a section, we should add all the earlier appearances in the main description
  // as well as sections that come earlier in the mosaic.
  if (section) {
    const [, params] = parseInvestigationSectionId(section.id);
    const index = getCurrentInvestigation()?.sections?.findIndex((s) => {
      const [, compareParams] = parseInvestigationSectionId(s.id);
      return params.uuid === compareParams.uuid;
    });
    if (getAnchorLinkId(section.title) === anchoredText) headingIndex++;
    // Find all the heading references in the main description
    headingIndex = addAllHeadingReferencesInDescription(
      anchoredText,
      getCurrentInvestigation().description,
      headingIndex,
    );
    // loop through sections up to the current index. Look for any uses of text, and add to the heading index
    for (let i = 0; i < index; i++) {
      const earlySection = getCurrentInvestigation().sections[i];
      if (getAnchorLinkId(earlySection.title) === anchoredText) headingIndex++;
      headingIndex = addAllHeadingReferencesInDescription(
        anchoredText,
        earlySection.description,
        headingIndex,
      );
    }
  }
  const id = getAnchorLinkId(text, headingIndex);
  return React.createElement('h' + props.level, { id, className: 'app-anchor' }, props.children);
}

export function getMarkdownRenderers(headingCountRef: any, section?: UserInvestigationSection) {
  return {
    code: (props: any) => {
      const { children, className, node, inline, ...rest } = props;
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter
          {...rest}
          PreTag="div"
          children={String(children).replace(/\n$/, '')}
          language={match[1]}
          style={highlightStyle}
        />
      ) : (
        <code {...rest} className={className}>
          {children}
        </code>
      );
    },
    h1: (props: any) => {
      return formatHeading(props, headingCountRef, section);
    },
    h2: (props: any) => {
      return formatHeading(props, headingCountRef, section);
    },
    h3: (props: any) => {
      return formatHeading(props, headingCountRef, section);
    },
    h4: (props: any) => {
      return formatHeading(props, headingCountRef, section);
    },
    h5: (props: any) => {
      return formatHeading(props, headingCountRef, section);
    },
    h6: (props: any) => {
      return formatHeading(props, headingCountRef, section);
    },
    img: (props: any) => {
      return <AuthenticatedImage {...props} />;
    },
    p: (props: any) => {
      const children = Children.toArray(props?.children) ?? [];
      // Images cannot be descendents of <p> tag. Reduce margin around images.
      return children?.some((child: any) => {
        return child?.props?.node?.tagName === 'img';
      }) ? (
        <div className="uk-margin-small-top uk-margin-small-bottom">{children}</div>
      ) : (
        <p>{children}</p>
      );
    },
    a: (props: any) => {
      let href = props.href;
      const children = props.children;
      if (!href) return <span>{children}</span>;
      const isDocsServerEndpoint = href.includes(process.env.REACT_APP_DOCS_HOST);
      if (isDocsServerEndpoint && href.endsWith('.pdf')) {
        return (
          <span className="uk-link" onClick={() => downloadDocsServerPDF(href)}>
            {props.children}
          </span>
        );
      }
      const matches = decodeURI(href)?.match(/^{([^|]+)(\|[^|]*)?}$/);
      const isAssetLinkCandidate = !!matches;
      if (isAssetLinkCandidate) {
        // ASSET LINK SYNTAX: [LINK_TEXT]({ASSET_VALUE|ASSET_TYPE})
        // ASSET_TYPE and LINK_TEXT are optional. ASSET_TYPE must exist in AssetType enum
        const assetValue = matches[1].trim();
        let assetType = matches[2] ? matches[2].substring(1).trim() : undefined;
        if (!assetType || !Object.values<string>(AssetType).includes(assetType)) {
          assetType = undefined;
        }
        return (
          <AssetLink value={assetValue} children={children} forcedType={assetType as AssetType} />
        );
      }
      const isPlatformLink =
        href.includes(process.env.REACT_APP_PLATFORM_ENDPOINT) || props.href.startsWith('/');
      if (isPlatformLink) {
        if (href.includes(process.env.REACT_APP_PLATFORM_ENDPOINT)) {
          href = href.split(process.env.REACT_APP_PLATFORM_ENDPOINT)[1]; // only grab relative path
        }
        return <PlatformLink href={href}>{children}</PlatformLink>;
      }
      return <a href={href}>{children}</a>;
    },
    td: (props: any) => {
      return <td style={props?.style}>{props?.children}</td>;
    },
    th: (props: any) => {
      if (props?.isHeader) {
        return (
          <th
            className={`${props?.children?.length > 0 ? 'ib-section-th-content' : ''}`}
            style={props?.style}
          >
            {props?.children}
          </th>
        );
      }
    },
  };
}

export function ViewHeader({ section, className }: ViewHeaderProps) {
  const headingCountRef = useRef<Record<string, number[]>>({});
  const markdownComponents = useMemo(
    () => getMarkdownRenderers(headingCountRef, section),
    [section],
  );
  const description = section?.description;
  return (
    <div className="uk-position-relative">
      {description && (
        <ReactMarkdown
          className={className ?? 'ib-section-notes'}
          remarkPlugins={[gfm]}
          components={markdownComponents}
          children={description}
        />
      )}
    </div>
  );
}
function downloadDocsServerPDF(pdfURL: string) {
  authenticatedFetch(pdfURL)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`An error occurred during the pdf fetch, status ${response.status} `);
      }
      return response.blob();
    })
    .then(function (pdfBlob) {
      if (!pdfBlob) {
        throw new Error('An occurred while generating the pdf blob');
      }
      const fileURL = URL.createObjectURL(pdfBlob);
      let link = document.createElement('a');
      link.href = fileURL;
      link.download = pdfURL.split('/').pop() || 'whitepaper';
      link.click();
      // For Firefox it is necessary to delay revoking the ObjectURL.
      window.setTimeout(() => {
        window.URL.revokeObjectURL(fileURL);
      }, 250);
    })
    .catch((e) => {
      console.log('pdf fetch failed', e);
      createGenericNotification('pdf-generation-error', {
        alertType: ALERT_TYPES.error,
        message: 'There was an error retrieving the requested white paper.',
        title: 'PDF Download Failed',
        onClear: () => acknowledgeNotification('pdf-generation-error'),
      });
    });
}

function PlatformLink({ href, children }) {
  const { isPrinterFriendly } = useContext(ComponentPluginContext);

  if (isPrinterFriendly) {
    return <span>{children}</span>;
  }

  return <Link to={href}>{children}</Link>;
}
