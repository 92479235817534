import React from 'react';
import { ComponentPluginName } from 'components';
import punycode from 'punycode/punycode.es6';
import { useHostnameQuery } from 'hooks';
import { ALERT_TYPES, createGenericNotification, UserInvestigation } from 'model';
import Investigation from '../investigations/Investigation';
import { addSectionToInvestigation, isValidHostname } from 'utils';
import { Redirect } from 'react-router-dom';

export default function HostnameReport({ assetValue }: { assetValue: string }) {
  const asciiHostname = punycode.toASCII(assetValue);
  const unicodeHostname = punycode.toUnicode(assetValue);

  const { asset } = useHostnameQuery(asciiHostname);

  const params = { assetValue: asciiHostname };

  const newInvestigation: UserInvestigation = {
    title: unicodeHostname,
    subtitle: '',
    description: '',
    sections: [],
  };

  if (!isValidHostname(assetValue)) {
    createGenericNotification('invalid-asset', {
      alertType: ALERT_TYPES.error,
      message: `${assetValue} is not a valid hostname.`,
      title: 'Invalid Hostname',
    });
    return <Redirect to="/" />;
  }

  addSectionToInvestigation(newInvestigation, ComponentPluginName.HostnameAbout, params, 'About');
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.HostnameResolution,
    params,
    'DNS Resolution',
  );
  if (asset?.allCerts?.totalCount > 0) {
    addSectionToInvestigation(
      newInvestigation,
      ComponentPluginName.HostnameCerts,
      params,
      'Certificates',
    );
  }
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.HostnameSharedHosts,
    params,
    'Shared Hostnames',
  );

  return <Investigation investigation={newInvestigation} />;
}
