import React, { useContext } from 'react';
import { ComponentPluginName } from 'components';
import { useIPQuery } from 'hooks';
import { ALERT_TYPES, createGenericNotification, UserInvestigation } from 'model';
import { addSectionToInvestigation, isValidIp } from 'utils';
import Investigation from '../investigations/Investigation';
import { DataSlicesContext } from 'appContexts';
import { Redirect } from 'react-router-dom';

export default function IPReport({ assetValue }: { assetValue: string }) {
  const { hasPortRecords } = useContext(DataSlicesContext);
  const { asset } = useIPQuery(assetValue);

  const params = { assetValue };

  const newInvestigation: UserInvestigation = {
    title: assetValue,
    subtitle: asset?.org?.orgName,
    description: '',
    sections: [],
  };

  if (!isValidIp(assetValue)) {
    createGenericNotification('invalid-asset', {
      alertType: ALERT_TYPES.error,
      message: `${assetValue} is not a valid IP Address.`,
      title: 'Invalid IP',
    });
    return <Redirect to="/" />;
  }

  if (asset) {
    addSectionToInvestigation(newInvestigation, ComponentPluginName.IPAbout, params, 'About');
    addSectionToInvestigation(newInvestigation, ComponentPluginName.IPRouting, params, 'Routing');
    if (hasPortRecords && asset?.ports?.ip) {
      addSectionToInvestigation(newInvestigation, ComponentPluginName.IPPorts, params, 'Ports');
    }
    if (asset?.routerAlias?.vendor) {
      addSectionToInvestigation(
        newInvestigation,
        ComponentPluginName.IPDeviceInformation,
        params,
        'Device Information',
      );
    }
    if (asset?.attributesPublicdns) {
      addSectionToInvestigation(
        newInvestigation,
        ComponentPluginName.IPInfrastructure,
        params,
        'Infrastructure',
      );
    }
    if (asset?.attributesMaltrail || asset?.attributesIpsum) {
      addSectionToInvestigation(
        newInvestigation,
        ComponentPluginName.IPReputation,
        params,
        'Reputation',
      );
    }
    if (asset?.domainHostsPaged?.totalCount > 0) {
      addSectionToInvestigation(
        newInvestigation,
        ComponentPluginName.IPDomainHosts,
        params,
        'Domain Hosts',
      );
    }
    addSectionToInvestigation(
      newInvestigation,
      ComponentPluginName.IPTracerouteSummary,
      params,
      'Traceroute Summary',
    );
    addSectionToInvestigation(
      newInvestigation,
      ComponentPluginName.IPTracerouteEdges,
      params,
      'Traceroute Edges',
    );
  }

  return <Investigation investigation={newInvestigation} />;
}
