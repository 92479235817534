import React from 'react';
import { AssetLink } from 'components';
import { AssetType } from 'model';

type AssetListProps = {
  assets: string[];
  forcedTyped?: AssetType;
};

export function AssetList({ assets, forcedTyped }: AssetListProps) {
  return (
    <ul className="uk-list uk-list-collapse uk-margin-remove">
      {assets.map((asset: string, index) => {
        return (
          <li key={index}>
            <AssetLink value={asset} forcedType={forcedTyped} />
          </li>
        );
      })}
    </ul>
  );
}
