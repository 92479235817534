import React, { useContext } from 'react';
import { useReactiveVar } from '@apollo/client';
import { userInvestigationsVar } from 'model';
import { deleteInvestigation } from 'utils';
import { KebabContext } from 'appContexts';
import { ExpandableKebabComponent } from 'components';

export function DeleteMosaicKebabMenu({ data }: ExpandableKebabComponent) {
  const { returnKebabMain } = useContext(KebabContext);
  const investigations = useReactiveVar(userInvestigationsVar);
  const investigation = investigations[data.id];

  return (
    <div>
      <div className="uk-flex uk-flex-middle uk-margin-large-bottom">
        <button onClick={() => returnKebabMain()} className="tile-menu-back">
          <div uk-icon="icon: chevron-left;" />
          Menu
        </button>
        <span className="uk-text-bold menu-header">Delete Mosaic</span>
      </div>
      <div className="uk-margin-medium-bottom">
        Are you sure you want to delete
        <span className="uk-text-bold"> {` ${investigation?.title ?? 'mosaic'}`}</span>?
      </div>
      <div className="uk-flex uk-flex-right">
        <button
          className="uk-button uk-button-default"
          onClick={() => deleteInvestigation(investigation)}
        >
          Delete
        </button>
      </div>
    </div>
  );
}
