import React from 'react';
import { ComponentPluginName } from 'components';
import punycode from 'punycode/punycode.es6';
import { ALERT_TYPES, createGenericNotification, UserInvestigation } from 'model';
import Investigation from '../investigations/Investigation';
import { addSectionToInvestigation, isValidDomain } from 'utils';
import { Redirect } from 'react-router-dom';

export default function DomainReport({ assetValue }: { assetValue: string }) {
  const asciiDomain = punycode.toASCII(assetValue);
  const unicodeDomain = punycode.toUnicode(assetValue);

  const params = { assetValue: asciiDomain };

  const newInvestigation: UserInvestigation = {
    title: unicodeDomain,
    subtitle: '',
    description: '',
    sections: [],
  };

  if (!isValidDomain(assetValue)) {
    createGenericNotification('invalid-asset', {
      alertType: ALERT_TYPES.error,
      message: `${assetValue} is not a valid domain.`,
      title: 'Invalid Domain',
    });
    return <Redirect to="/" />;
  }

  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.DomainRegistry,
    params,
    'Registry',
  );
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.DomainCerts,
    params,
    'Certificates',
  );
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.DomainDNSRecords,
    params,
    'DNS Authoritative Records',
  );
  addSectionToInvestigation(
    newInvestigation,
    ComponentPluginName.DomainDNSHosts,
    params,
    'DNS Hosts',
  );

  return <Investigation investigation={newInvestigation} />;
}
