import { ComponentPluginContext } from 'appContexts';
import { isEqual } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { DataAttributeDiff, buildDataAttributeDiffs } from 'utils';

export function useLogHistory(data: any, historyLoading: boolean) {
  const { updatePluginConfig, getPluginConfig, setConfigOptions } =
    useContext(ComponentPluginContext);
  const { historyEnabled, dateA, dateB } = getPluginConfig()?.history || {};
  const [dates, setDates] = useState();
  const datesRef = useRef<string[] | undefined>();

  useEffect(() => {
    if (!historyLoading) {
      const newDates = data.map((obj) => obj.timestamp);
      setDates((current) => {
        if (!isEqual(current, newDates)) {
          return newDates;
        }
        return current;
      });
    }
  }, [data, historyLoading]);

  // Initialize or reset history config when history is toggled or dates changes (likely due to assetValue changing)
  useEffect(() => {
    if (dates) {
      if (
        historyEnabled &&
        ((!dateA && !dateB) || (datesRef.current && datesRef.current !== dates))
      ) {
        const newConfig = {
          ...getPluginConfig(),
          history: {
            ...getPluginConfig()?.history,
            dateA: getPluginConfig()?.dateA || dates[1] || dates[0],
            dateB: getPluginConfig()?.dateB || dates[0],
          },
        };
        !isEqual(newConfig, getPluginConfig()) && updatePluginConfig(newConfig);
      } else if (!historyEnabled) {
        const myConfig = { ...getPluginConfig() };
        delete myConfig?.history?.dateA;
        delete myConfig?.history?.dateB;
        delete myConfig?.history?.historyEnabled;
        !isEqual(myConfig, getPluginConfig()) && updatePluginConfig(myConfig);
      }
      datesRef.current = dates;
    }
  }, [dateA, dateB, dates, getPluginConfig, historyEnabled, updatePluginConfig]);

  useEffect(() => {
    const aAttrs = data.find((obj) => obj.timestamp === dateA)?.attributes;
    const bAttrs = data.find((obj) => obj.timestamp === dateB)?.attributes;

    const datesA = data
      .map((obj) => {
        const [aAttrsDiff] = buildDataAttributeDiffs(obj.attributes, bAttrs);
        const magnitude = getDiffMagnitude(aAttrsDiff);
        return { date: obj.timestamp, magnitude: magnitude };
      })
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    const datesB = data
      .map((obj) => {
        const [, bAttrsDiff] = buildDataAttributeDiffs(obj.attributes, aAttrs);
        const magnitude = getDiffMagnitude(bAttrsDiff);
        return { date: obj.timestamp, magnitude: magnitude };
      })
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    const newOptions = {
      reference: datesA,
      comparer: datesB,
    };

    setConfigOptions((current) => {
      if (!isEqual(current, newOptions)) {
        return newOptions;
      }
      return current;
    });
  }, [data, dateA, dateB, setConfigOptions]);
}

function getDiffMagnitude(diffArray: DataAttributeDiff[]): number {
  let count = 0;
  for (const obj of diffArray) {
    if (obj.diff) {
      count++;
    }
    if (Array.isArray(obj.value)) {
      count += getDiffMagnitude(obj.value);
    }
  }
  return count;
}
