import { gql } from '@apollo/client';
import { DataSlicesContext } from 'appContexts';
import { useAssetQuery } from 'hooks';
import { AssetType } from 'model';
import { useContext, useMemo } from 'react';

export function useIPQuery(assetValue: string) {
  const { hasPorts } = useContext(DataSlicesContext);

  const assetQuery = useMemo(() => {
    return gql`
        query IPReportData($ipSet: [String]!) {
          ipData(ipSet: $ipSet) {
            id
            ip
            exchange {
              id
              name
            }
            org {
              orgName
              prefix
              source
            }
            geo {
              city
              countryDivision
              country
              anycast
              latLon
            }
            rdns
            routerId
            attributesMaltrail {
              maltrailBehaviors
              maltrailMalwares
              maltrailUpstreamSources
            }
            attributesIpsum {
              ipsumBlacklistCount
            }
            routerAlias {
              vendor {
                name
                os
                osVersion
              }
            }
            behaviors {
              names
              behavior
            }
            domainHostsPaged {
              totalCount
            }
            attributesPublicdns(historical: true) {
              ip
              latestData
            }
            attributesServices {
              prefix 
              services {
                name
                region
                type
                tags
                publishedPrefix
              }
            }  
            ${hasPorts ? 'ports { ip { ip } }' : ''}
          }
        }
      `;
  }, [hasPorts]);

  const { asset, loading, error } = useAssetQuery(AssetType.IP, assetValue, assetQuery);
  return { asset, loading, error };
}
