import countryLookup from 'country-code-lookup';
import { makeVar } from '@apollo/client';
import { AssetType } from 'model';
import { isASN, isRouterId, isValidDomain, isValidIp, isValidPrefix, isHostname } from 'utils';

export const asnOrgNamesVar = makeVar<Record<number, string>>({});

// Asset Set Query Caps
export const IP_QUERY_CAP = process.env.REACT_APP_IP_LIMIT ? +process.env.REACT_APP_IP_LIMIT : 5000;
export const AGG_CAP = 2000;
export const ROUTER_QUERY_CAP = 2000;
export const TRACEROUTE_EDGE_QUERY_CAP = 3000;
export const TRACEROUTE_EDGE_AGG_CAP = 1000;

// Mapping of AQ resolutions to caps:
export const QUERY_CAP_MAPPING: Record<string, number> = {
  ips: IP_QUERY_CAP,
  routers: ROUTER_QUERY_CAP,
  tracerouteEdges: TRACEROUTE_EDGE_QUERY_CAP,
};

export function guessAssetType(value: string | number): AssetType | undefined {
  if (value == null) return undefined;
  value = value.toString();

  if (isASN(value.toString())) {
    return AssetType.AS;
  }

  if (isRouterId(value)) {
    return AssetType.Router;
  }

  if (value.toLowerCase().startsWith('urn:fac:')) {
    return AssetType.Facility;
  }

  if (value.toLowerCase().startsWith('urn:ix:')) {
    return AssetType.IX;
  }

  if (isValidIp(value)) {
    return AssetType.IP;
  }

  if (isValidPrefix(value)) {
    return AssetType.Prefix;
  }

  // Check for 2 and 3 char ISO codes where no part of it are numbers
  if (value.length >= 2 && value.length <= 3 && !/\d/.test(value)) {
    try {
      if (countryLookup.byIso(value)) return AssetType.Country;
    } catch {}
  }
  // countryLookup.byCountry is case sensitive. We want to be a bit more flexible with out searches, allowing any casing
  if (
    typeof value === 'string' &&
    countryLookup.countries.find(
      (country) => country.country.toLowerCase() === (value as string).toLowerCase(),
    )
  ) {
    return AssetType.Country;
  }

  if (isHostname(value)) {
    return AssetType.Hostname;
  }
  if (isValidDomain(value)) {
    return AssetType.Domain;
  }

  return AssetType.Org;
}

export function removeUrnPrefix(id: string) {
  return id && id.replace(/urn:\w+:/, '');
}

export function addAttributeToColumnOptions(
  options: any[],
  column: string,
  key: string,
  value: any,
  selector?: string,
) {
  const newOptions = [...options];
  let i = newOptions.findIndex((obj) => obj[selector ?? 'selector'] === column);
  if (i < 0) return options;
  newOptions[i][key] = value;
  return newOptions;
}
