import { userInvestigationsVar } from 'model';
import Select, { StylesConfig } from 'react-select';
import { reactSelectDropdownStyle } from 'components';
import React, { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { InvestigationTypes, sortInvestigationByDate } from 'utils';
import { DropdownIndicator } from './DropdownIndicator';

export function InvestigationSelector({
  onChange,
  copyTarget,
  onMenuOpen,
  onMenuClose,
}: { onChange: any; copyTarget?: string; onMenuOpen?; onMenuClose? }) {
  const investigations = useReactiveVar(userInvestigationsVar);

  const newInvestigationSelect = useMemo(() => {
    return { value: 'newMosaic', label: `Create New ${copyTarget}` };
  }, [copyTarget]);

  const mosaicOptions = useMemo(() => {
    return Object.values(investigations)
      .filter((investigation) => !investigation.isTemplate)
      .map((investigation) => {
        return { label: investigation.title, value: investigation.id };
      })
      .sort((a, b) => sortInvestigationByDate(investigations, a, b));
  }, [investigations]);

  const templateOptions = useMemo(() => {
    return Object.values(investigations)
      .filter((investigation) => investigation.isTemplate)
      .map((investigation) => {
        return { label: investigation.title, value: investigation.id };
      })
      .sort((a, b) => sortInvestigationByDate(investigations, a, b));
  }, [investigations]);

  const allOptions = useMemo(() => {
    const newOptions = [];
    if (copyTarget === InvestigationTypes.Mosaic) {
      newOptions.push({ label: '', options: [newInvestigationSelect] });
      newOptions.push({ label: 'Mosaics', options: mosaicOptions });
    } else if (copyTarget === InvestigationTypes.Template) {
      newOptions.push({ label: '', options: [newInvestigationSelect] });
      newOptions.push({ label: 'Templates', options: templateOptions });
    }
    return newOptions;
  }, [copyTarget, newInvestigationSelect, mosaicOptions, templateOptions]);

  return (
    <div className="uk-margin-small-bottom" data-testid="investigation-selector">
      <label className="input-label">
        {`${copyTarget === 'template' ? 'Template' : copyTarget === 'mosaic' ? 'Mosaic' : 'Selection'}`}
      </label>
      <Select
        options={allOptions}
        onChange={onChange}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        blurInputOnSelect={true}
        defaultValue={
          copyTarget === InvestigationTypes.Template
            ? templateOptions[0] ?? newInvestigationSelect
            : mosaicOptions[0] ?? newInvestigationSelect
        }
        styles={reactSelectDropdownStyle() as StylesConfig}
        menuPortalTarget={document.body}
        components={{ DropdownIndicator }}
      />
    </div>
  );
}
