import { UserInvestigationSection } from 'model';
import { SidebarContext } from 'appContexts';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  parseInvestigationSectionId,
  saveInvestigation,
  toggleMainIsFocused,
  createUniqueInvestigationSectionId,
  focusTile,
  findExistingSectionIndex,
  isViewOnly,
  getTemplateParamId,
  getConfigAdvancedQuery,
  isOutputVariable,
} from 'utils';
import {
  ClearIndicator,
  ComponentPluginName,
  CopyTilePrompt,
  DeleteTilePrompt,
  DropdownIndicator,
  FindAndReplacePrompt,
  reactSelectDropdownStyleBorderless,
  TemplateParameterNameOption,
  TemplateParameterNameSingleValue,
  TemplateParameterValueContainer,
} from 'components';
import Select, { components, StylesConfig } from 'react-select';
import { cloneDeep } from 'lodash';
import remark from 'remark';
import UIkit from 'uikit';
import strip from 'strip-markdown';
import { ReactComponent as DeleteIcon } from 'svg/actions/trash.svg';
import { ReactComponent as CopyIcon } from 'svg/mosaic/mosaic-copy.svg';
import { ReactComponent as Dot } from 'svg/experimental/dot.svg';
import { ReactComponent as Search } from 'svg/system/magnifying-glass.svg';
import { ReactComponent as FindAndReplaceIcon } from 'svg/experimental/substitution-text.svg';
import { ReactComponent as AddIcon } from 'svg/actions/plus-outline.svg';
import { ReactComponent as IndeterminateCheck } from 'svg/selection/checkbox-indeterminate.svg';
import { ReactComponent as Checked } from 'svg/selection/checkbox-checked.svg';
import { ReactComponent as Unchecked } from 'svg/selection/checkbox-unchecked.svg';
import { ReactComponent as DragIcon } from 'svg/actions/drag-default.svg';
import { ReactComponent as Hexagon } from 'svg/experimental/hexagon-fill.svg';

enum TilePromptTypes {
  Delete = 'delete',
  Copy = 'copy',
  FindAndReplace = 'f&r',
}

export function SidebarTileEditor({ maxHeightPx }) {
  const {
    focusedTile,
    investigation,
    isMainFocused,
    localTemplateParams,
    setFocusedTile,
    setLocalAnnotations,
    setIsMainFocused,
  } = useContext(SidebarContext);
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [selectedPrompt, setSelectedPrompt] = useState<TilePromptTypes | undefined>();
  const [promptHeight, setPromptHeight] = useState<number>(0);
  const viewOnly = isViewOnly();
  const [templateVariable, setTemplateVariable] = useState<any>(undefined);
  const [templateVariableDependencies, setTemplateVariableDependencies] = useState<any>(undefined);

  const sortableRef = useRef<HTMLUListElement>(null);
  const tileListEndRef = useRef(null);
  const promptRef = useRef(null);

  const checkAllState = useMemo(() => {
    if (selected.size === 0) return 'unchecked';
    if (selected.size === investigation?.sections?.length) return 'checked';
    return 'indeterminate';
  }, [selected, investigation]);

  const focusedUUID = useMemo(() => {
    if (!focusedTile) return '';
    const [, focusedParams] = parseInvestigationSectionId(focusedTile.id);
    return focusedParams.uuid;
  }, [focusedTile]);

  // Given the max height the parent says this menu can use, this removes any height from the tile list to match the height needed by the prompt to ensure everything still stays within the view port
  const maxHeightWithPrompt = useMemo(() => {
    // (allowed max height) - (output variable input height) - (prompt toolbar height)
    const maxHeightWithOutputVariables =
      maxHeightPx - (localTemplateParams.length > 0 ? 32 : 0) - 32;
    if (!selectedPrompt) return maxHeightWithOutputVariables;
    // (remaining height) - (prompt height)
    return maxHeightWithOutputVariables - promptHeight;
  }, [localTemplateParams, maxHeightPx, promptHeight, selectedPrompt]);

  const strippedInvestigationDescription = useMemo(() => {
    return remark().use(strip).processSync(investigation.description).toString();
  }, [investigation.description]);

  const templateParams = useMemo(() => {
    return localTemplateParams.map((ltp) => {
      return {
        ...ltp,
        value: `$${getTemplateParamId(ltp)}`,
      };
    });
  }, [localTemplateParams]);

  const tileCount = useMemo(() => {
    if (
      templateVariable &&
      !isOutputVariable(templateVariable) &&
      templateVariableDependencies?.uuids
    ) {
      return templateVariableDependencies.uuids.length;
    }
    return investigation?.sections?.length ?? 0;
  }, [investigation?.sections, templateVariable, templateVariableDependencies?.uuids]);

  const scrollToBottomOfTileList = () => {
    setTimeout(function () {
      tileListEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100); // short delay to allow the new tile to mount before scrolling to it
  };

  const updateSelectedPrompt = useCallback(
    (newPrompt: TilePromptTypes) => {
      if (newPrompt === selectedPrompt) {
        setPromptHeight(0);
        setTimeout(() => {
          setSelectedPrompt(undefined);
        }, 300); // to match the transition speed defined in the scss file
      } else {
        setSelectedPrompt(newPrompt);
      }
    },
    [selectedPrompt],
  );

  const addTile = useCallback(() => {
    if (!viewOnly) {
      const sections: UserInvestigationSection[] = investigation?.sections ?? [];
      const newSections = cloneDeep(sections);
      let index = isMainFocused
        ? 0
        : focusedUUID
          ? findExistingSectionIndex(investigation, focusedUUID) + 1
          : newSections.length;
      const id = createUniqueInvestigationSectionId(ComponentPluginName.PrimitiveTile, {});
      newSections.splice(index, 0, { title: 'New Tile', description: '', saved: new Date(), id });
      const newInvestigation = { ...investigation, sections: newSections };
      saveInvestigation(newInvestigation);
      if (!focusedUUID) {
        scrollToBottomOfTileList();
      }
    }
  }, [focusedUUID, investigation, isMainFocused, viewOnly]);

  const toggleAllTiles = useCallback(
    (e) => {
      e.stopPropagation();
      if (['unchecked', 'indeterminate'].includes(checkAllState)) {
        setSelected(
          new Set(
            investigation.sections.map((section) => {
              const [, params] = parseInvestigationSectionId(section.id);
              return params.uuid;
            }),
          ),
        );
      } else {
        setSelected(new Set());
      }
    },
    [investigation.sections, setSelected, checkAllState],
  );

  const toggleTile = useCallback(
    (id: string) => {
      let retVal = new Set(selected);
      if (selected.has(id)) {
        retVal.delete(id);
      } else {
        retVal.add(id);
      }
      setSelected(retVal);
    },
    [selected, setSelected],
  );

  const onTileFocus = useCallback(
    (section: UserInvestigationSection) => {
      focusTile(section, focusedTile, setLocalAnnotations, setFocusedTile, setIsMainFocused);
    },
    [setFocusedTile, focusedTile, setLocalAnnotations, setIsMainFocused],
  );

  const updatePromptHeight = useCallback(() => {
    setPromptHeight(selectedPrompt ? promptRef.current?.clientHeight : 0);
  }, [selectedPrompt]);

  // when the selected template variable is changed, update the dependency information
  useEffect(() => {
    // sometimes the template variable gets deleted, this does a validation check
    const validOutputIds = localTemplateParams.map((ov) => {
      return ov.id;
    });
    if (templateVariable && validOutputIds.includes(templateVariable.id)) {
      let firstIndex = investigation?.sections.length - 1;
      let lastIndex = 0;
      // if it's not the origin section, then see if the section uses the template variable, and return its UUID if it does
      const templateParamIdentifier = templateVariable.id
        ? `$${templateVariable.id}`
        : templateVariable.expression;
      const UUIDs = investigation?.sections
        .filter((section, index) => {
          const parsedSection = parseInvestigationSectionId(section.id)[1];
          const aq = getConfigAdvancedQuery(parsedSection);
          // backwards compatibility for template variables that do not have IDs, but have expressions
          // This checks several different locations on the section that could reference the variable
          const sectionUsesSelectedOutputVariable =
            (aq &&
              (aq.selection?.value === templateParamIdentifier || // check for output variable in dataset selection
                aq.filterClauses.find((filterClause) => {
                  // check for template/output variable in filter clauses
                  if (Array.isArray(filterClause.value)) {
                    return filterClause.value.includes(templateParamIdentifier);
                  } else {
                    return filterClause.value === templateParamIdentifier;
                  }
                }))) ||
            parsedSection.assetValue === templateParamIdentifier; // check for template variable in dataset selection from tiles derived from an asset page
          if (sectionUsesSelectedOutputVariable || parsedSection.uuid === templateVariable.origin) {
            firstIndex = Math.min(index, firstIndex);
            lastIndex = Math.max(index, lastIndex);
          }
          return sectionUsesSelectedOutputVariable;
        })
        .map((section) => {
          return parseInvestigationSectionId(section.id)[1].uuid;
        });
      // edge case where no tiles use the output, and both values are equal due to them only being by the index of the tile that created them, then set to undefined so we know to ignore
      if (firstIndex === lastIndex || !isOutputVariable(templateVariable)) {
        firstIndex = undefined;
        lastIndex = undefined;
      }
      setTemplateVariableDependencies({
        uuids: UUIDs,
        firstIndex,
        lastIndex,
        origin: templateVariable.origin, // only output variables have origin defined, this will dictate styling
      });
    } else {
      setTemplateVariableDependencies(undefined);
      setTemplateVariable(undefined);
    }
  }, [investigation, localTemplateParams, templateVariable]);

  // Update investigation section order on drag
  useEffect(() => {
    if (!sortableRef.current || viewOnly) {
      return;
    }
    return UIkit.util.on(
      sortableRef.current,
      'moved',
      '.uk-sortable',
      (event: any, sortable: any) => {
        if (!investigation) return;
        const movedId = event.detail[1].getAttribute('data-id');
        const to = sortable.items.findIndex(
          (item: any) => item.getAttribute('data-id') === movedId,
        );
        const sections: UserInvestigationSection[] = investigation?.sections ?? [];
        const from = sections.findIndex((item) => item.id === movedId);
        const newSections = cloneDeep(sections);
        newSections.splice(to, 0, ...newSections.splice(from, 1));
        const newInvestigation = { ...investigation, sections: newSections };
        saveInvestigation(newInvestigation);
      },
    );
  });

  useEffect(() => {
    updatePromptHeight();
  }, [selectedPrompt, promptRef.current?.clientHeight, selected, updatePromptHeight]);

  if (!investigation) return null;

  return (
    <Fragment>
      {templateParams.length > 0 && (
        <Select
          {...{ templateParams: localTemplateParams }}
          key={`template-variable-${templateVariable?.id}`} // special key to "reset" the dropdown if the output is deleted
          options={templateParams}
          styles={tileListSearchTheme() as StylesConfig}
          onChange={(param) => setTemplateVariable(param)}
          value={templateVariable}
          blurInputOnSelect={true}
          placeholder=""
          isClearable={true}
          components={{
            Option: TemplateParameterNameOption,
            SingleValue: TemplateParameterNameSingleValue,
            ValueContainer: TemplateParameterValueContainer,
            DropdownIndicator,
            ClearIndicator,
            Control,
          }}
        />
      )}
      <div className="tiles-list-header">
        Tiles
        <button onClick={toggleAllTiles}>
          {checkAllState === 'indeterminate' ? (
            <IndeterminateCheck />
          ) : checkAllState === 'checked' ? (
            <Checked />
          ) : (
            <Unchecked />
          )}
        </button>
      </div>
      <div
        className="sidebar-menu-content edit-tiles-list"
        style={{ maxHeight: `${maxHeightWithPrompt}px`, marginTop: '0' }}
      >
        <div className="tile-sorting" style={{ overflow: 'auto' }}>
          <div
            className={`draggable-tile uk-card uk-flex ${isMainFocused && 'focused-tile-item'}`}
            onClick={() =>
              toggleMainIsFocused(
                isMainFocused,
                setIsMainFocused,
                setFocusedTile,
                setLocalAnnotations,
                investigation,
              )
            }
          >
            <div
              className="title-tile"
              title={
                investigation.title?.length > 0
                  ? investigation.title
                  : strippedInvestigationDescription
              }
              data-testid="title-tile"
            >
              {investigation.title?.length > 0
                ? investigation.title
                : strippedInvestigationDescription}
            </div>
          </div>
          {investigation?.sections?.length > 0 && (
            <ul
              ref={sortableRef}
              className="uk-grid uk-sortable uk-margin-remove"
              uk-sortable="cls-custom: ib-section-dragging uk-box-shadow-small; handle: .uk-sortable-handle"
            >
              {investigation?.sections?.map((section: UserInvestigationSection, index) => {
                const tileKey = `${section.id}`;
                const strippedMdDescription = remark()
                  .use(strip)
                  .processSync(section.description)
                  .toString();
                const title = section.title.length > 0 ? section.title : strippedMdDescription;
                const [, sectionParams] = parseInvestigationSectionId(section.id);
                const uuid = sectionParams.uuid;
                const isFocused = focusedUUID === uuid;
                return (
                  <li
                    key={tileKey}
                    data-id={tileKey}
                    className={`draggable-tile ${viewOnly ? '' : 'column-tile-handle'} uk-padding-remove ${isFocused ? 'focused-tile-item' : ''}`}
                  >
                    <div
                      className={`uk-card uk-flex ${viewOnly ? '' : 'uk-sortable-handle'} reorder-tile-item`}
                      onClick={() => onTileFocus(section)}
                    >
                      {templateVariableDependencies ? (
                        <div className="tile-output-state">
                          {/*Top cap*/}
                          {templateVariableDependencies.firstIndex === index && (
                            <div className="v-line top-cap" />
                          )}
                          {/*Bottom cap*/}
                          {templateVariableDependencies.lastIndex === index && (
                            <div className="v-line bottom-cap" />
                          )}
                          {/*Vertical line*/}
                          {templateVariableDependencies.firstIndex < index &&
                            templateVariableDependencies.lastIndex > index && (
                              <div className="v-line" />
                            )}
                          {/*Uses output*/}
                          {templateVariableDependencies.uuids?.includes(uuid) ? (
                            templateVariableDependencies.origin ? (
                              <Fragment>
                                <div className="h-line" />
                                <div className="circle" />
                              </Fragment>
                            ) : (
                              <Hexagon className={`hexagon ${isFocused ? 'focused' : ''}`} />
                            )
                          ) : (
                            <div />
                          )}
                          {/*Produces output*/}
                          {templateVariableDependencies?.origin === uuid && (
                            <div className="circle filled" />
                          )}
                        </div>
                      ) : !viewOnly ? (
                        <DragIcon
                          className="uk-text-center column-tile-handle"
                          style={{
                            alignSelf: 'flex-start',
                            minWidth: '12px',
                            marginTop: '4px',
                            marginLeft: '10px',
                          }}
                        />
                      ) : (
                        <div className="uk-margin-medium-left" />
                      )}
                      <div className="sidebar-tile-section" title={title}>
                        {title}
                      </div>
                      <button
                        className="uk-float-right uk-margin-auto-left uk-margin-right"
                        onClick={(e) => {
                          e.stopPropagation();
                          const [, params] = parseInvestigationSectionId(section.id);
                          toggleTile(params.uuid);
                        }}
                      >
                        {selected.has(uuid) ? <Checked /> : <Unchecked />}
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          <div ref={tileListEndRef} />
        </div>
      </div>
      <div className="sidebar-menu-toolbar">
        <div className="uk-flex uk-margin-auto-right">
          {templateVariableDependencies && !templateVariableDependencies.origin
            ? // TEMPLATE variables specifically
              `${tileCount} Match${tileCount === 1 ? '' : 'es'}`
            : `${tileCount} Tile${tileCount === 1 ? '' : 's'}`}
          {selected?.size > 0 ? (
            <div className="uk-flex">
              <Dot style={{ height: '12px', marginTop: '5px', color: '#D7D8D8' }} />
              <div style={{ color: '#6659C0' }}>{selected.size} Selected</div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="uk-flex sidebar-menu-prompt-buttons" id={'tiles-prompt-buttons'}>
          {!viewOnly && (
            <Fragment>
              <button
                onClick={() => addTile()}
                className="sidebar-menu-button"
                data-testid="add-tile-button"
              >
                <AddIcon />
              </button>
              <button
                onClick={() => updateSelectedPrompt(TilePromptTypes.Delete)}
                className={
                  selectedPrompt === TilePromptTypes.Delete
                    ? 'selected-sidebar-menu-button'
                    : 'sidebar-menu-button'
                }
                data-testid="delete-tile-button"
              >
                <DeleteIcon />
              </button>
            </Fragment>
          )}
          <button
            onClick={() => updateSelectedPrompt(TilePromptTypes.Copy)}
            className={
              selectedPrompt === TilePromptTypes.Copy
                ? 'selected-sidebar-menu-button'
                : 'sidebar-menu-button'
            }
            data-testid="copy-tile-button"
          >
            <CopyIcon />
          </button>
          {!viewOnly && (
            <button
              onClick={() => updateSelectedPrompt(TilePromptTypes.FindAndReplace)}
              className={
                selectedPrompt === TilePromptTypes.FindAndReplace
                  ? 'selected-sidebar-menu-button'
                  : 'sidebar-menu-button'
              }
              data-testid="f&r-tile-button"
            >
              <FindAndReplaceIcon />
            </button>
          )}
        </div>
      </div>
      <div
        className={`sidebar-menu-prompt ${selectedPrompt ? 'prompt-selected' : ''}`}
        style={{ maxHeight: selectedPrompt ? `${promptHeight}px` : 0 }}
      >
        <div ref={promptRef}>
          {selectedPrompt === TilePromptTypes.Delete ? (
            <DeleteTilePrompt
              selected={selected}
              setSelected={setSelected}
              updateSelectedPrompt={updateSelectedPrompt}
            />
          ) : selectedPrompt === TilePromptTypes.Copy ? (
            <CopyTilePrompt
              selected={selected}
              setSelected={setSelected}
              updatePromptHeight={updatePromptHeight}
            />
          ) : (
            selectedPrompt === TilePromptTypes.FindAndReplace && (
              <FindAndReplacePrompt selected={selected} updatePromptHeight={updatePromptHeight} />
            )
          )}
        </div>
      </div>
    </Fragment>
  );
}

function Control({ children, ...props }: any) {
  return (
    <components.Control {...props}>
      <Search className={'search-icon'} />
      {children}
    </components.Control>
  );
}

function tileListSearchTheme() {
  return {
    ...reactSelectDropdownStyleBorderless(true, false, false, false),
    control: (base: Record<string, any>) => ({
      ...reactSelectDropdownStyleBorderless(true, false, false, false).control(base, {
        isDisabled: false,
      }),
      padding: '0 14px 0 16px',
    }),
  };
}
