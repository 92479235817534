import React from 'react';

type ReportTitleProps = {
  title: string;
  subtitle?: string;
  label?: string;
  icon?: JSX.Element;
};

export default function ReportTitle({ title, subtitle, label, icon }: ReportTitleProps) {
  return (
    <div className="uk-margin-large-bottom report-title">
      {label && (
        <div className="uk-margin-top-remove report-title-label uk-text-uppercase uk-flex uk-flex-middle">
          {icon}
          {label}
        </div>
      )}
      <h1 className="uk-margin-remove">{title}</h1>
      {subtitle && <div className="uk-margin-remove report-title-subtitle">{subtitle}</div>}
    </div>
  );
}
