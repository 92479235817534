import { useMemo } from 'react';
import { get } from 'lodash';

export function useMosaicTitleSort() {
  return useMemo(
    () => (rowA: any, rowB: any, columnId: string, desc: boolean) => {
      const aTitle = get(rowA.original, columnId)?.toLowerCase() || '';
      const bTitle = get(rowB.original, columnId)?.toLowerCase() || '';

      // Get pinTag regardless of gallery/my-mosaics
      const aPin = rowA.original?.pinTag || rowA.original?.value?.pinTag || '';
      const bPin = rowB.original?.pinTag || rowB.original?.value?.pinTag || '';

      // First sort by pinned
      if (aPin > bPin) return desc ? 1 : -1;
      if (aPin < bPin) return desc ? -1 : 1;
      // Always sort pinned by title
      if (aPin !== '' && bPin !== '' && aPin === bPin) {
        if (aTitle < bTitle) return desc ? 1 : -1;
        if (aTitle > bTitle) return desc ? -1 : 1;
      }
      // Secondary sort by title
      if (aTitle > bTitle) return 1;
      if (aTitle < bTitle) return -1;
    },
    [],
  );
}
