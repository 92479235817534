import { ComponentPluginName } from 'components';
import { cloneDeep, flatten, omit, sortBy, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  AdvancedQuery,
  deleteUserDatasetData,
  getUserDatasetData,
  isInvestigationTemplate,
  QueryFilterComparator,
  QuerySelection,
  saveUserDatasetData,
  userAdvancedQueriesVar,
  UserAdvancedQuery,
  UserDataSection,
  UserDataset,
  UserDatasetData,
  userDatasetsVar,
  UserInvestigation,
  UserInvestigations,
  userInvestigationsVar,
  userSearchHistoryVar,
  UserTag,
  UserTags,
  userTagsVar,
} from 'model';
import { getConfigAdvancedQuery, withAsyncLock } from 'utils';
import { gql } from '@apollo/client';
import { getGraphQLClient } from 'gql';

export enum USER_DATASET_STATUS {
  FileUploading = 'fileUploading',
  FileImporting = 'fileImporting',
  Error = 'error',
  FileImported = 'fileImported',
}

export const loadingDatasetStatuses = [
  USER_DATASET_STATUS.FileUploading,
  USER_DATASET_STATUS.FileImporting,
];
export const finishedDatasetStatuses = [
  USER_DATASET_STATUS.Error,
  USER_DATASET_STATUS.FileImported,
];

export function makeUniqueId(name?: string) {
  const uuid = uuidv4().replaceAll('-', '');
  return name ? `${name}:${uuid}` : uuid;
}

//--------------------------------------------------------
// User Tags
//--------------------------------------------------------
export function getUserTags() {
  const investigations = userInvestigationsVar();

  const investigationTags = flatten(
    Object.values(investigations).map(
      (investigation: UserInvestigation) => investigation?.tags || [],
    ),
  );

  const userTags: UserTag[] = uniqBy(investigationTags, 'name');
  return userTags;
}

export function saveUserTags(userTags: UserTag[]): UserTag[] {
  const savedTags: UserTags = {};
  userTags.forEach((tag: UserTag) => {
    const id = tag.id || makeUniqueId('tag');
    savedTags[id] = { ...tag, id, saved: new Date() };
  });
  userTagsVar({ ...userTagsVar(), ...savedTags });
  return Object.values(savedTags);
}

export function userTagsToIds(userTags: UserTag[]) {
  return userTags.map((tag: UserTag) => tag.id as string);
}

export function getUserTagsForIds(ids: string[]) {
  return Object.values(userTagsVar()).filter((tag: UserTag) => ids.includes(tag.id as string));
}

//--------------------------------------------------------
// User Advanced Queries
//--------------------------------------------------------
export type SaveUserAdvancedQueryOptions = {
  name?: string;
  notes?: string;
  tags?: string[];
  replace?: boolean;
};

export function getUserAdvancedQuery(selection: QuerySelection) {
  const id = selection.value;
  return userAdvancedQueriesVar()[id];
}

export function saveUserAdvancedQuery(
  advancedQuery: AdvancedQuery,
  options?: SaveUserAdvancedQueryOptions,
) {
  const name = options?.name ?? advancedQuery.selection.label;
  const value = advancedQuery.selection.value;
  const id = options?.replace ? value : `${makeUniqueId('user')},${value}`;
  const selection = { ...advancedQuery.selection, value: id, label: name };

  advancedQuery = { ...advancedQuery, selection };

  const userAdvancedQuery: UserAdvancedQuery = {
    id,
    notes: options?.notes,
    tags: options?.tags,
    saved: new Date(),
    query: advancedQuery,
  };

  userAdvancedQueriesVar({ ...userAdvancedQueriesVar(), [id]: userAdvancedQuery });
  return advancedQuery;
}

export function deleteUserAdvancedQuery(advancedQuery: AdvancedQuery) {
  let userAdvancedQueries = omit(userAdvancedQueriesVar(), advancedQuery.selection.value as string);
  userAdvancedQueriesVar(userAdvancedQueries);
  deleteUnreferencedUserDatasets();
}

export function isUserAdvancedQuerySelection(selection: QuerySelection) {
  return selection.value && selection.value.toString().startsWith('user:');
}

export function getAdvancedQueryDatasetId(advancedQuery: AdvancedQuery) {
  const value = advancedQuery.selection.value;
  return isUserAdvancedQuerySelection(advancedQuery.selection) ? value.split(',')[1] : value;
}

//--------------------------------------------------------
// User Investigations
//--------------------------------------------------------

let currentInvestigation: UserInvestigation | undefined = undefined;

export function setCurrentInvestigation(newCurrentInvestigation: UserInvestigation) {
  currentInvestigation = newCurrentInvestigation;
}

export function getCurrentInvestigation() {
  return currentInvestigation;
}

export function getInvestigation(id: string, userInvestigations?: UserInvestigations) {
  if (!userInvestigations) {
    userInvestigations = userInvestigationsVar();
  }

  if (!id?.startsWith('investigation:')) {
    id = `investigation:${id}`;
  }

  return userInvestigations[id];
}

export function createUniqueInvestigationSectionId(
  pluginName: ComponentPluginName,
  params: Record<string, any>,
) {
  // Make section id unique.
  params = { ...params, uuid: makeUniqueId() };
  return toInvestigationSectionId(pluginName, params);
}

export function toInvestigationSectionId(
  pluginName: ComponentPluginName,
  params: Record<string, any>,
) {
  const encodedParams = btoa(encodeURIComponent(JSON.stringify(params)));
  return `${pluginName}:${encodedParams}`;
}

export function parseInvestigationSectionId(id: string) {
  const [pluginName, encodedParams] = id.split(':');
  const params = JSON.parse(decodeURIComponent(atob(encodedParams)));
  return [pluginName, params];
}

export function saveInvestigation(investigation: UserInvestigation, skipSavedUpdate?: boolean) {
  const existing = investigation.id && userInvestigationsVar()[investigation.id];
  if (existing) {
    investigation = isInvestigationTemplate(investigation)
      ? {
          ...existing,
          ...investigation,
        }
      : {
          ...existing,
          ...investigation,
          // because existing could be a template, we have to remove this flag if it exists to match the non-template type
          removeTemplateParamValues: undefined,
        };
  } else if (!investigation.id) {
    investigation.id = makeUniqueId('investigation');
  }

  if (!skipSavedUpdate) {
    investigation.saved = new Date();
  }
  currentInvestigation = investigation;
  userInvestigationsVar({
    ...userInvestigationsVar(),
    [investigation.id as string]: investigation,
  });
  deleteUnreferencedUserDatasets();
  return investigation;
}

export function deleteInvestigation(investigation: UserInvestigation) {
  if (currentInvestigation === investigation) {
    currentInvestigation = undefined;
  }
  let investigations = omit(
    userInvestigationsVar(),
    investigation.id as string,
  ) as UserInvestigations;
  userInvestigationsVar(investigations);
  deleteUnreferencedUserDatasets();
}

//--------------------------------------------------------
// User Search History
//--------------------------------------------------------
const HISTORY_SIZE = 100;

export function addToUserSearchHistory(selection: QuerySelection) {
  const searchHistory = userSearchHistoryVar().filter(
    (s: QuerySelection) => s.label !== selection.label,
  );
  const newHistory = [selection, ...searchHistory];
  if (newHistory.length > HISTORY_SIZE) {
    newHistory.pop();
  }
  userSearchHistoryVar(newHistory);
}

export function matchUserSearchHistory(name: string) {
  const regex = RegExp(`(.*)?${name}(.*)?`);
  return userSearchHistoryVar().filter(
    (selection: QuerySelection) => regex.test(selection.value) || regex.test(selection.label),
  );
}

//--------------------------------------------------------
// User Datasets
//--------------------------------------------------------
export async function saveUserDataset(
  dataset: UserDataset,
  data?: string | File,
  isBulkImport?: boolean,
): Promise<UserDataset> {
  const id = dataset.id || makeNewUserDatasetId();
  dataset = { ...dataset, id, saved: new Date() };
  if (typeof data === 'string') {
    if (dataset.dataId) {
      await deleteUserDatasetData(dataset.dataId);
    }
    dataset.dataId = await importUserDatasetDataString(data);
  }

  if (data instanceof File) {
    dataset.system = { status: USER_DATASET_STATUS.FileUploading, hidden: true };
  }
  // Indicate the dataset was created in this session (see userNotifications.ts)
  window.sessionStorage.setItem(id, `${id}${isBulkImport ? '-bulk' : ''}`);

  userDatasetsVar({ ...userDatasetsVar(), [dataset.id as string]: dataset });

  // Import the data file.
  if (data instanceof File) {
    // Wait for the async lock for datasets to ensure the new dataset has been pushed to the server.
    if (isBulkImport) {
      await withAsyncLock(UserDataSection.Datasets, () => importDataFile(dataset, data));
    } else {
      // In case of normal upload, we want the user to be able to do other things while this runs in
      // the background, so don't include await
      withAsyncLock(UserDataSection.Datasets, () => importDataFile(dataset, data));
    }
  }

  return dataset;
}

export function makeNewUserDatasetId() {
  return makeUniqueId('dataset');
}

export function deleteUserDataset(dataset: UserDataset) {
  const datasets = omit(userDatasetsVar(), dataset.id as string);
  userDatasetsVar(datasets);
  const newMosaics = {};
  const investigations = userInvestigationsVar();
  const referencedMosaics = getUserDatasetReferences(dataset);
  // Loop through the referenced mosaics and remove this dataset.
  referencedMosaics?.forEach((mosaic) => {
    const newMosaic = cloneDeep(mosaic);
    const sections = newMosaic.sections;
    const templateParams = newMosaic.templateParams;
    let mosaicChanged = false;
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const [pluginName, params] = parseInvestigationSectionId(section.id);
      const aq = getConfigAdvancedQuery(params);
      if (aq?.selection?.value && aq.selection.value === dataset.id) {
        aq.selection.value = undefined;
        aq.selection.label = undefined;
        mosaicChanged = true;
      }
      for (let i = 0; i < aq?.filterClauses?.length; i++) {
        const clause = aq.filterClauses[i];
        if (
          clause.comparator === QueryFilterComparator.IsInDataset &&
          clause.value === dataset.id
        ) {
          clause.value = undefined;
          mosaicChanged = true;
        }
      }
      section.id = toInvestigationSectionId(pluginName, params);
    }
    templateParams?.forEach((param) => {
      if (param.value === dataset.id) {
        param.value = undefined;
        param.label = undefined;
        mosaicChanged = true;
      }
    });
    if (mosaicChanged) {
      newMosaic.saved = new Date();
      newMosaics[newMosaic.id] = newMosaic;
    }
  });
  if (referencedMosaics?.length) {
    userInvestigationsVar({ ...investigations, ...newMosaics });
  }
}

function deleteUnreferencedUserDatasets() {
  const unreferencedDatasets = Object.values(userDatasetsVar()).filter(
    (dataset: UserDataset) => dataset.system?.hidden && !isReferencedUserDataset(dataset),
  );
  if (unreferencedDatasets.length > 0) {
    userDatasetsVar(
      omit(
        userDatasetsVar(),
        unreferencedDatasets.map((dataset: UserDataset) => dataset.id as string),
      ),
    );
  }
}

function isReferencedUserDataset(dataset: UserDataset) {
  const datasetId = dataset.id as string;
  if (!datasetId) return false;
  // Check for investigation reference to this dataset.
  for (const investigation of Object.values(userInvestigationsVar())) {
    for (const section of investigation.sections) {
      const [, params] = parseInvestigationSectionId(section.id);
      const aq = getConfigAdvancedQuery(params);
      if (aq?.selection?.value?.includes(datasetId)) {
        return true;
      }
      // Check for reference to this dataset in Advanced Query filter clauses
      if (aq?.filterClauses) {
        for (const filterClause of aq?.filterClauses) {
          if (filterClause.value === datasetId) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

export function getUserDatasetReferences(
  dataset: UserDataset,
  propInvestigations?: UserInvestigations,
) {
  const referencedMosaics = new Set<UserInvestigation>();
  const datasetId = dataset.id as string;
  let investigations = propInvestigations ?? userInvestigationsVar();
  if (!datasetId) return Array.from(referencedMosaics);
  // Check for template param reference to this dataset.
  for (const investigation of Object.values(investigations)) {
    if (
      investigation.isTemplate &&
      investigation.templateParams?.find((param) => param.value === datasetId)
    ) {
      referencedMosaics.add(investigation);
      continue;
    }
    sectionLoop: for (const section of investigation.sections) {
      const [, params] = parseInvestigationSectionId(section.id);
      const aq = getConfigAdvancedQuery(params);
      // advanced query selections
      if (aq?.selection?.value?.includes(datasetId)) {
        referencedMosaics.add(investigation);
        break;
      }
      // Check for reference to this dataset in Advanced Query filter clauses
      if (aq?.filterClauses) {
        for (const filterClause of aq?.filterClauses) {
          if (filterClause.value === datasetId) {
            referencedMosaics.add(investigation);
            break sectionLoop;
          }
        }
      }
    }
  }
  return Array.from(referencedMosaics).sort((a, b) => {
    return b.saved.getTime() - a.saved.getTime();
  });
}

async function importUserDatasetDataString(data: string) {
  const datasetData: UserDatasetData = {
    id: makeUniqueId('data'),
    saved: new Date(),
    data,
  };
  await saveUserDatasetData(datasetData.id as string, datasetData);
  return datasetData.id as string;
}

export async function loadDataForUserDataset(id: string) {
  const userDataset = userDatasetsVar()[id];
  if (!userDataset) {
    throw new Error(`Cannot find user dataset ${id}`);
  }

  const datasetData = await getUserDatasetData(userDataset.dataId as string);
  if (!datasetData) {
    throw new Error(`Cannot find data for user dataset ${userDataset.name}`);
  }

  return parseUserDatasetData(datasetData);
}

export function parseUserDatasetData(datasetData: UserDatasetData) {
  return Array.from(new Set<string>(datasetData.data.match(/[^\r\n]+/g))).map((line: string) =>
    line.trim(),
  );
}

const importDatasetDataMutation = gql`
  mutation ($datasetId: String!, $file: Upload!) {
    importDatasetData(input: {datasetId: $datasetId, file: $file}) {
      status
    }
  }
`;

async function importDataFile(userDataset: UserDataset, file: File) {
  const result = await getGraphQLClient().mutate({
    mutation: importDatasetDataMutation,
    variables: { datasetId: userDataset.id, file },
  });

  const data: Record<string, any> = result.data;
  return Object.values(data)[0]?.status as string;
}

export function getImportSuccessfulMessage(dataset: UserDataset) {
  const displayName =
    dataset.filename === dataset.name ? dataset.filename : `${dataset.name} (${dataset.filename})`;
  return `${displayName} has been successfully imported to your profile.`;
}

export function getDatasetReadyMessage(dataset: UserDataset) {
  const displayName =
    dataset.filename === dataset.name ? dataset.filename : `${dataset.name} (${dataset.filename})`;
  return `${displayName} is ready to view.`;
}

export function getUserDatasetsOptions(
  userDatasets: Record<string, UserDataset>,
  excludeErrors?: boolean,
  templateExpression?: string,
) {
  return [
    ...sortBy(
      Object.values(userDatasets)
        .filter((dataset) => {
          if (dataset?.system?.hidden) return false;
          if (
            templateExpression &&
            templateExpression.startsWith('$pcap') &&
            dataset.typename !== 'pcap'
          )
            return false;
          return excludeErrors ? !dataset?.system?.error : true;
        })
        .map((userDataset: UserDataset) => ({
          label: userDataset.name,
          value: userDataset.id as string,
          typename: userDataset.typename,
        })),
      'label',
    ),
  ];
}
