import { useEffect, useMemo } from 'react';
import L, { latLng } from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { useMap } from 'react-leaflet';
import { GeoPoint } from 'model';
import { uniqWith } from 'lodash';
import { isEqualLatLng } from 'utils';

type ClusterBreakpoints = {
  medium: number;
  large: number;
};

type MarkerClusterGroupProps = {
  geoPoints: GeoPoint[];
  onClusterClick: any;
  onMarkerClicked: any;
  breakpoints: ClusterBreakpoints;
};

export default function MarkerClusterGroup({
  geoPoints,
  onClusterClick,
  onMarkerClicked,
  breakpoints,
}: MarkerClusterGroupProps) {
  const map = useMap();

  const markers = useMemo(() => {
    return geoPoints.map((geoPoint: GeoPoint) => {
      return L.marker(latLng(geoPoint.latLon));
    });
  }, [geoPoints]);

  const mcg = useMemo(() => {
    return (L as any).markerClusterGroup({
      chunkedLoading: true,
      // chunkInterval: 50,
      // chunkDelay: 200,
      singleMarkerMode: true,
      zoomToBoundsOnClick: false, // Disable for custom onClick
      spiderfyOnMaxZoom: false, // Disable for custom onClick
      showCoverageOnHover: false,
      iconCreateFunction: (cluster: any) =>
        createClusterCustomIcon(cluster, { medium: breakpoints.medium, large: breakpoints.large }),
    });
  }, [breakpoints.medium, breakpoints.large]);

  // Add/update markers that are part of cluster group
  useEffect(() => {
    mcg.clearLayers();
    mcg.addLayers(markers);
  }, [markers, mcg]);

  // Update marker click event listener
  useEffect(() => {
    markers.forEach((marker: any) => {
      marker.off();
      marker.on('click', onMarkerClicked);
    });
  }, [onMarkerClicked, mcg, markers]);

  // Update cluster click event listener
  useEffect(() => {
    mcg.off();
    mcg.on('clusterclick', (cluster: any) => {
      onClusterClick(cluster);
    });
  }, [onClusterClick, mcg]);

  // Add the marker cluster group to the map
  useEffect(() => {
    if (!map?.hasLayer(mcg)) {
      map?.addLayer(mcg);
    }
  }, [map, mcg]);

  return null;
}

function createClusterCustomIcon(cluster: any, breakpoints: ClusterBreakpoints) {
  const clusterSize = cluster.getAllChildMarkers().length;
  const childMarkerPositions = cluster.getAllChildMarkers().map((marker: any) => marker._latlng);
  const isFullyExpanded = uniqWith(childMarkerPositions, isEqualLatLng).length === 1;

  let markerClass = 'marker-cluster-small';
  let width = 25;
  let height = 25;
  if (clusterSize >= breakpoints.large) {
    markerClass = 'marker-cluster-large';
    width = 45;
    height = 45;
  } else if (clusterSize >= breakpoints.medium) {
    markerClass = 'marker-cluster-medium';
    width = 35;
    height = 35;
  }
  return L.divIcon({
    html: `<div>${cluster.getChildCount()}</div>`,
    className: `custom-pin ${markerClass} ${isFullyExpanded ? 'custom-pin-single' : ''}`,
    iconSize: L.point(width, height, true),
  });
}
