import { without } from 'lodash';
import { select, Selection } from 'd3-selection';
import { zoom } from 'd3-zoom';
import { drag } from 'd3-drag';
import { traverseGraph } from 'utils';
import { ContextMenu, ContextMenuItem } from 'components';
import { D3Node, SvgDatum } from './graphTypes';

const removeNodesMenuItem: ContextMenuItem = {
  label: 'Remove Selected',
  onClick: ({ svg }: Record<string, any>) => {
    const svgDatum = svg.datum() as SvgDatum;
    const selectedNodeIds = svgDatum.selectedNodeIds || [];
    const removedNodes = [...(svgDatum.viewConfig?.removedNodes || []), ...selectedNodeIds];
    svgDatum.selectedNodeIds = undefined;
    svgDatum.viewConfig = { ...svgDatum.viewConfig, removedNodes };
    svgDatum.onViewConfigChange && svgDatum.onViewConfigChange(svgDatum.viewConfig);
    svgDatum.updateGraphLayout(svg);
  },
};

export function graphSvgInteraction(svg: Selection<SVGSVGElement, unknown, null, undefined>) {
  const svgDatum = svg.datum() as SvgDatum;

  function onSvgClick(event: any) {
    if (event.target.tagName === 'svg') {
      svgDatum.highlightedNodeId = svgDatum.selectedNodeIds = undefined;
      svgDatum.onNodeHighlight && svgDatum.onNodeHighlight(undefined, event);
      svgDatum.updateGraphVisibility(svg);
    }
  }

  function onSvgContextMenu(event: any) {
    showContextMenu(event, svg);
  }

  function onSvgZoom(event: any) {
    svg.select('g').attr('transform', event.transform);
  }

  const zoomBehavior = zoom().clickDistance(5).on('zoom', onSvgZoom);

  svg
    .call(zoomBehavior as any)
    .on('dblclick.zoom', null)
    .on('click', onSvgClick)
    .on('contextmenu', onSvgContextMenu);

  return svg;
}

export function graphNodeInteraction(
  selection: Selection<SVGGElement, unknown, null, undefined>,
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
) {
  const svgDatum = svg.datum() as SvgDatum;

  function onNodeMouseover(event: any) {
    if (svgDatum.dragging || svgDatum.selectedNodeIds) {
      return;
    }
    const node = select(event.target).datum() as D3Node;
    if (node) {
      svgDatum.highlightedNodeId = node.data.id;
    }
    svgDatum.onNodeHighlight && svgDatum.onNodeHighlight(node.data, event);
    svgDatum.updateGraphVisibility(svg);
  }

  function onNodeMouseleave(event: any) {
    if (svgDatum.dragging) {
      return;
    }
    svgDatum.highlightedNodeId = undefined;
    if (!svgDatum.selectedNodeIds) {
      svgDatum.onNodeHighlight && svgDatum.onNodeHighlight(undefined, event);
    }
    svgDatum.updateGraphVisibility(svg);
  }

  function onNodeMouseDown(event: any) {
    event = event.sourceEvent || event;
    const node = select(event.target).datum() as D3Node;
    if (!node) {
      return;
    }

    const nodeId = node.data.id;
    let selectedNodeIds = svgDatum.selectedNodeIds || [];
    const ctrlKey = event.ctrlKey || event.metaKey;
    const shiftKey = event.shiftKey;
    const found = selectedNodeIds.includes(nodeId);
    if (found) {
      selectedNodeIds = without(selectedNodeIds, nodeId);
    }
    if (!shiftKey && !ctrlKey) {
      selectedNodeIds = [nodeId];
    } else if (!found || !ctrlKey) {
      selectedNodeIds.unshift(nodeId);
    }
    svgDatum.highlightedNodeId = undefined;
    svgDatum.selectedNodeIds = selectedNodeIds.length > 0 ? selectedNodeIds : undefined;
    if (svgDatum.onNodeHighlight) {
      const highlightedNode =
        svgDatum.selectedNodeIds && svgDatum.nodeMap[svgDatum.selectedNodeIds[0]];
      svgDatum.onNodeHighlight(highlightedNode?.data, event);
    }
    svgDatum.updateGraphVisibility(svg);
  }

  function onNodeDoubleClick(event: any) {
    if (svgDatum.dragging) {
      return;
    }
    const elem = event.target;
    const node = select(elem).datum() as D3Node;
    if (!node?.data) {
      return;
    }

    const collapsed = !node.data.state.collapsed;
    let expandedNodes = without(svgDatum.viewConfig?.expandedNodes || [], node.data.id);
    if (collapsed) {
      // Collapse all descendants.
      if (expandedNodes.length > 0) {
        for (const descendant of traverseGraph(node.data)) {
          if (expandedNodes.includes(descendant.id)) {
            expandedNodes = without(expandedNodes, descendant.id);
            if (expandedNodes.length === 0) {
              break;
            }
          }
        }
      }
    } else {
      expandedNodes.push(node.data.id);
    }
    svgDatum.highlightedNodeId = svgDatum.selectedNodeIds = undefined;
    svgDatum.viewConfig = { ...svgDatum.viewConfig, expandedNodes };
    svgDatum.onViewConfigChange && svgDatum.onViewConfigChange(svgDatum.viewConfig);
    svgDatum.updateGraphLayout(svg);
  }

  function onNodeContextMenu(event: any) {
    const node = select(event.target).datum() as D3Node;
    if (node) {
      if (!svgDatum.selectedNodeIds?.includes(node.data.id)) {
        onNodeMouseDown(event);
      }
      showContextMenu(event, svg, node);
    }
  }

  let dragNode: D3Node | undefined;
  let preDragSelectedNodeIds: string[] | undefined;

  const dragBehavior = drag()
    .clickDistance(10)
    .on('start', (event: any) => {
      onNodeMouseDown(event);
      preDragSelectedNodeIds = svgDatum.selectedNodeIds;
      dragNode = select(event.sourceEvent.target).datum() as D3Node;
    })
    .on('end', () => {
      dragNode = undefined;
      if (svgDatum.dragging) {
        svgDatum.selectedNodeIds = preDragSelectedNodeIds;
        svgDatum.updateGraphVisibility(svg);
        svgDatum.dragging = false;
      }
    })
    .on('drag', (event: any) => {
      if (dragNode) {
        svgDatum.dragging = true;
        dragNode.x += event.dx;
        dragNode.y += event.dy;
        dragNode.fx = dragNode.x;
        dragNode.fy = dragNode.y;
        svgDatum.runGraphForceSimulation(svg, true);
      }
    });

  selection
    .call(dragBehavior as any)
    .on('mouseover', onNodeMouseover)
    .on('mouseleave', onNodeMouseleave)
    .on('dblclick', onNodeDoubleClick)
    .on('contextmenu', onNodeContextMenu);

  return selection;
}

function showContextMenu(
  event: any,
  svg: Selection<SVGSVGElement, unknown, null, undefined>,
  node?: D3Node,
) {
  const svgDatum = svg.datum() as SvgDatum;
  const items: ContextMenuItem[] = [];
  if (svgDatum.selectedNodeIds) {
    const label = `${removeNodesMenuItem.label} ${svgDatum.selectedNodeIds.length > 1 ? 'Nodes' : 'Node'}`;
    items.push({ ...removeNodesMenuItem, label });
  }
  if (items.length > 0) {
    ContextMenu.show(items, event, { svg });
  }
}
